/* eslint-disable consistent-return */
/* globals resx, mockRVData */
import * as helpers from './helpers';

const shouldLoadFromStorage = () => {
  return !(helpers.getProductPageStatus(document?.referrer)); // checks to see if user came from PIP
};

export {
  shouldLoadFromStorage
};
