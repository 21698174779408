/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { EVENTS } from '../util/AnalyticsHelper';

const getAllSelectedAddOns = (addons) => {
  addons = addons.filter((addon) => (addon.selected));
  return addons.map((addon) => {
    return getSelectedAddOns(addon)[0];
  });
};

/**
 * Modifies the selected addon category to work with backend
 * @param {*} addon
 * @returns
 */
const getSelectedAddOns = (addon) => {
  // TODO: temporary until backend fixes this
  // must be 'protection_plan' for update
  const PROTECTION_PLAN_KEY = localStorage.getItem('__protectionPlanKey') || 'protectionPlan';
  let selectedAddons = [];
  const { category, configAttr, selected } = addon;
  selectedAddons.push({
    category: (category === 'protectionPlan' ? PROTECTION_PLAN_KEY : category),
    configAttr,
    selected
  });
  return selectedAddons;
};

export const getItemDetails = (item, variables, addonDetails) => {
  let itemDetails = {};
  try {
    const { id, store = '' } = item;
    const { product, quantity, selectedFulfillment, fulfillmentLocation } = item;
    const { itemId } = product;

    variables.fulfillmentLocation = variables.fulfillmentLocation || fulfillmentLocation;
    variables.fulfillmentMethod = variables.fulfillmentMethod || selectedFulfillment;

    itemDetails = {
      lineItemId: id,
      itemId,
      quantity,
      ...variables
    };

    // to update storeId with appliance zipcode update
    if (store) {
      itemDetails.storeId = store;
    }

    // to update addons price when quantity update.
    if (variables.event === EVENTS.QUANTITY_CHANGE) {
      const addonsSelected = getAllSelectedAddOns(product.addons);
      if (addonsSelected && addonsSelected.length > 0) {
        itemDetails.addOns = addonsSelected;
      }
    }

    if (addonDetails) {
      let existingAddOns = null;
      if (product.addons?.length) {
        existingAddOns = getAllSelectedAddOns(product.addons);
      }

      const selectedAddons = (Array.isArray(addonDetails))
        ? getAllSelectedAddOns(addonDetails)
        : getSelectedAddOns(addonDetails);

      itemDetails.addOns = existingAddOns ? existingAddOns.concat(selectedAddons) : selectedAddons;

    }
  } catch (err) {
    console.error(err);
  }
  return itemDetails;
};

let DELAY = 2000;
let TIMER = null;
let REJECT = null;

const getDelay = (delay) => {
  try {
    if (typeof window !== 'undefined' && window.isUnitTest) {
      delay = 0;
    }
  } catch (err) {
    // console.error(err);
  }
  return delay;
};

export const debounce = (fx, delay = DELAY) => {
  if (REJECT) {
    REJECT = null;
  }
  const updatePromise = new Promise((resolve, reject) => {
    REJECT = reject;
    clearTimeout(TIMER);
    TIMER = setTimeout(() => {
      fx()
        .then(resolve)
        .catch(REJECT)
        .finally(() => {
          REJECT = null;
        });
    }, getDelay(delay));

  });

  return updatePromise;
};