import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleSecondaryDarkOrange = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleSecondaryDarkOrange.palette['--sui-palette-background-primary'] = '191 77 2';

export { HouseStyleSecondaryDarkOrange };