import React from 'react';
import { instanceOf } from 'prop-types';

import { Typography } from '@one-thd/sui-atomic-components';
import { getDay, getMonth, getTwoDigitDate } from '../../../../utils';

export const EstimatedDeliveryDate = ({
  estimatedDeliveryDate
}) => {

  if (!estimatedDeliveryDate) {
    return <div data-testid="estimatedDeliveryDate" />;
  }

  const dateInfo = {
    day: getDay(estimatedDeliveryDate?.getUTCDay()),
    date: getTwoDigitDate(estimatedDeliveryDate?.getUTCDate()),
    month: getMonth(estimatedDeliveryDate?.getUTCMonth()),
  };

  const formattedEstimatedDeliveryDate = `Ready for Pickup ${dateInfo.day} ${dateInfo.month}, ${dateInfo.date}`;

  return (
    <Typography variant="body-base" weight="bold" data-testid="estimatedDeliveryDate">
      {formattedEstimatedDeliveryDate}
    </Typography>
  );
};

EstimatedDeliveryDate.propTypes = {
  estimatedDeliveryDate: instanceOf(Date)
};

EstimatedDeliveryDate.defaultProps = {
  estimatedDeliveryDate: ''
};