import React, { useContext, useRef } from 'react';
import {
  bool, func, number, string, oneOf
} from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { getDomPath } from '@thd-olt-functional/utils';
import './Pagination.style.scss';
import { publish } from '../publish';

export const range = (start, end = 1) => {
  const newEnd = (typeof end !== 'number' ? start : end) + 1;
  return [...Array(newEnd).keys()].slice(start, newEnd);
};

const Pagination = (props) => {
  // eslint-disable-next-line react/static-property-placement
  const { channel } = useContext(ExperienceContext);
  const ref = useRef(null);
  const gotoPage = (page, url) => {
    const { onPageChanged, totalPages } = props;
    const newPage = Math.max(0, Math.min(page, totalPages));
    onPageChanged(newPage, url);
  };

  const handleClick = (page, evt) => {
    let { nao } = props;
    if (nao) {
      evt.preventDefault();
      gotoPage(page.index, page.url);
    }
  };

  const updateNao = (index) => {
    const { pageSize, href, totalPages } = props;
    let [path, queryString = ''] = href.split('?');
    const isFirstPage = pageSize / index === pageSize;
    const multiplier = pageSize * (index - 1);
    const naoRegex = /Nao=[\w]+/;
    if (queryString.includes('Nao')) {
      queryString = (isFirstPage || index > totalPages)
        ? queryString.replace(`&${naoRegex}`, '').replace(`${naoRegex}&`, '').replace(naoRegex, '')
        : queryString.replace(naoRegex, `Nao=${multiplier}`);
    } else if (!isFirstPage) {
      const ampersand = queryString.length && multiplier ? '&' : '';
      queryString = queryString.concat(`${ampersand}Nao=${multiplier}`);
    }

    if (queryString) {
      queryString = `?${queryString}`;
    }

    return `${path}${queryString}`;
  };

  const fetchPageArrows = (pageRange) => {
    const { totalPages, currentPage, pageSize, href } = props;
    const hasFinalRightArrow = currentPage === totalPages;
    let pages;
    const leftArrow = {
      index: currentPage - 1,
      type: 'leftArrow'
    };
    const rightArrow = {
      index: currentPage + 1,
      type: 'rightArrow'
    };

    if (pageSize && href) {
      leftArrow.url = updateNao(currentPage - 1);
      rightArrow.url = updateNao(currentPage + 1);
    }

    if (currentPage !== 1 && hasFinalRightArrow) {
      pages = [leftArrow, ...pageRange, {
        index: 1,
        type: 'rightArrow',
        url: (pageSize && href) ? updateNao(1) : null
      }];
    } else if (currentPage === 1 && currentPage !== totalPages) {
      pages = [...pageRange, rightArrow];
    } else if (currentPage !== 1 && currentPage !== totalPages) {
      pages = [leftArrow, ...pageRange, rightArrow];
    }

    return pages;
  };

  const fetchMultiplePages = () => {
    const {
      currentPage,
      href,
      leftNeighbors,
      maxNumericBlocks,
      pagesBeforeDotsShow,
      pageSize,
      rightNeighbors,
      totalPages
    } = props;

    const leftDots = {
      index: currentPage - leftNeighbors,
      type: 'left'
    };
    const rightDots = {
      index: 1,
      type: 'right'
    };

    let pages = [];

    if (currentPage <= pagesBeforeDotsShow) {
      pages = range(1, maxNumericBlocks);
    } else if (currentPage <= totalPages - rightNeighbors) {
      const extraPages = range(
        currentPage - leftNeighbors,
        currentPage + rightNeighbors
      );
      pages = [1, leftDots, ...extraPages];
    } else if (currentPage === totalPages - 1) {
      const extraPages = range(
        currentPage - leftNeighbors,
        totalPages
      );
      pages = [1, 2, leftDots, ...extraPages];
    } else if (currentPage === totalPages) {
      pages = [totalPages - 1, totalPages, rightDots, 1, 2, 3];
    }

    return pages.map((page) => {
      let pageStructure;
      if (typeof page !== 'number') {
        pageStructure = page;
      } else if (typeof page === 'number' && pageSize) {
        const url = updateNao(page);
        pageStructure = {
          index: page,
          url,
          type: 'number'
        };
      } else {
        pageStructure = {
          index: page,
          type: 'number'
        };
      }
      return pageStructure;
    });

  };

  const fetchPageNumbers = () => {
    const { maxNumericBlocks, totalPages, pageSize } = props;

    if (totalPages > maxNumericBlocks) {
      return fetchMultiplePages();
    }

    const ranges = range(1, totalPages).map((index) => {
      return {
        index,
        type: 'number',
        url: pageSize ? updateNao(index) : null
      };
    });
    return ranges;
  };

  const pageList = () => {
    const { showArrows } = props;
    let pages = fetchPageNumbers();

    if (showArrows && pages.length !== 0) {
      pages = fetchPageArrows(pages);
    }

    let {
      queryHandler,
      href = '',
      currentPage,
      className = '',
      noQueryParams,
      totalReviewPages,
      pageReviewLimit
    } = props;

    let [path, querystring = ''] = href.split('?');
    let name = 'startindex';

    if (querystring) {
      querystring = '&' + querystring;
    }

    return pages.map((page, index) => {
      let value = page;
      if (queryHandler) {
        const result = queryHandler(name, page.index);
        name = result.name;// eslint-disable-line
        value = result.value;// eslint-disable-line
      }
      if (totalReviewPages && page.index > totalReviewPages) {
        href = '';
      }
      if (totalReviewPages && pageReviewLimit
          && page.type === 'rightArrow' && pageReviewLimit >= page.index) {
        href = '';
      }
      if (page.type === 'left' || page.type === 'right') {
        return (
          <li key={index} className="hd-pagination__item">
            <span className="hd-pagination__dots">...</span>
          </li>
        );
      }
      if ((page.type === 'leftArrow' || page.type === 'rightArrow') && href === '') {
        const arialabel = page.type === 'leftArrow' ? 'Previous' : 'Next';

        return (
          <li key={index} className="hd-pagination__item hd-pagination__button">
            <button
              ref={ref}
              className="hd-pagination__link"
              aria-label={arialabel}
              type="button"
              onClick={(evt) => {
                handleClick(page, evt);
                const _path = getDomPath(ref);
                publish('click', { ...page, path: _path });
              }}
            />
          </li>
        );
      }
      if (page.type === 'number' && href === '') {
        return (
          <li
            key={index}
            className="hd-pagination__item"
          >
            <button
              ref={ref}
              className={`hd-pagination__link${currentPage === page.index ? ' hd-pagination__current' : ''}`}
              onClick={(evt) => {
                handleClick(page, evt);
                const _path = getDomPath(ref);
                publish('click', { ...page, path: _path });
              }}
              type="button"
            >
              {page.index}
            </button>
          </li>
        );
      }
      if (page.type === 'leftArrow' || page.type === 'rightArrow') {
        const arialabel = page.type === 'leftArrow' ? 'Previous' : 'Next';
        return (
          <li key={index} className="hd-pagination__item hd-pagination__button">
            <a
              ref={ref}
              className={`hd-pagination__link ${className}`}
              href={page.url || (noQueryParams ? `${value}` : `${path}?${name}=${value}${querystring}`)}
              aria-label={arialabel}
              onClick={(evt) => {
                handleClick(page, evt);
                const _path = getDomPath(ref);
                publish('click', { ...page, path: _path });
              }}
            >
              {/* content goes here */}
            </a>
          </li>
        );
      }
      if (page.type === 'number') {
        return (
          <li
            key={index}
            className="hd-pagination__item"
          >
            <a
              ref={ref}
              className={`hd-pagination__link ${className}${
                currentPage === page.index ? ' hd-pagination__current' : ''
              }`}
              href={page.url || (noQueryParams ? `${value}` : `${path}?${name}=${value}${querystring}`)}
              onClick={(evt) => {
                handleClick(page, evt);
                const _path = getDomPath(ref);
                publish('click', { ...page, path: _path });
              }}
            >
              {page.index}
            </a>
          </li>
        );
      }
      return null;
    });
  };

  const { classNameWrapper, ssr, totalPages } = props;
  if (totalPages <= 1) return null;
  const paginationClasses = classNames(
    'hd-pagination',
    classNameWrapper, {
      'hd-pagination--mobile': channel === 'mobile'
    }
  );

  if (typeof window === 'undefined' && !ssr) {
    return null;
  }

  return (
    <nav className={paginationClasses} role="navigation" data-component="Pagination">
      <ul className="hd-pagination__wrapper">
        {pageList()}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  className: string,
  classNameWrapper: string,
  currentPage: number.isRequired,
  href: string,
  /** Number of numeric blocks left of current page */
  leftNeighbors: number,
  /** Maximum number of numeric blocks */
  maxNumericBlocks: number,
  nao: bool,
  noQueryParams: bool,
  onPageChanged: func.isRequired,
  /** Page before 'dots' begin to appear */
  pagesBeforeDotsShow: number,
  queryHandler: func,
  /** Number of numeric blocks right of current page */
  rightNeighbors: number,
  showArrows: bool,
  ssr: bool,
  totalPages: number.isRequired,
  totalReviewPages: number,
  pageSize: number,
  pageReviewLimit: number
};

Pagination.defaultProps = {
  className: '',
  classNameWrapper: null,
  href: '',
  leftNeighbors: 1,
  maxNumericBlocks: 5,
  nao: true,
  noQueryParams: false,
  pagesBeforeDotsShow: 4,
  queryHandler: null,
  rightNeighbors: 2,
  showArrows: false,
  ssr: true,
  totalReviewPages: null,
  pageSize: null,
  pageReviewLimit: null
};

Pagination.dataModel = {};

export { Pagination };
