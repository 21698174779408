import React, { Fragment, useContext } from 'react';
import {
  arrayOf, func, node, string, shape
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { PromoPod } from '../PromoPod/PromoPod';
import { PromoPodPlaceholder } from '../PromoPod/subcomponents/PromoPodPlaceholder';
import { ListObserver } from './subcomponents/ListObserver';
import { usePromoModel } from '../../hooks/usePromoModel';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';
import { noop } from '../../utils/promo-utils';
import { PromoListDataModel } from '../../models/PromoListDataModel';

export const PromoList = ({
  itemIds,
  observer,
  listText,
  onProductPodClick,
  placeholderItemIds
}) => {
  const { channel } = useContext(ExperienceContext);
  const { type } = usePromoPresentation();
  const {
    anchorItem,
    isAwaitingData,
    isBmsm,
    isMsb,
    isDollarThresholdBogo,
    isAnchorAppliance
  } = usePromoModel();
  const isAnchorItemInItemIds = itemIds?.includes(anchorItem?.itemId);
  const showAnchorAtTop = (
    type === 'card' || isBmsm || isMsb || isDollarThresholdBogo
  ) && !isAnchorItemInItemIds;
  const promoPodLimit = isAnchorItemInItemIds ? 6 : 5;
  const maxNumPlaceholdersToShow = channel === 'mobile' ? 1 : 3;
  const numPlaceholdersToShow = Math.min(placeholderItemIds.length, maxNumPlaceholdersToShow);

  return (
    <article
      className="sui-flex sui-flex-col sui-gap-1 sui-w-auto sui-overflow-y-auto"
      data-testid="promo-list"
    >
      {showAnchorAtTop
        && (
          <PromoPod
            displayPosition={0}
            itemId={anchorItem?.itemId}
            onProductPodClick={onProductPodClick}
            hideCta={type === 'card'}
          />
        )}
      {listText && (
        <div data-testid="promo-list-text">
          {listText}
        </div>
      )}
      <div className="sui-flex sui-flex-col sui-gap-1 sui--ml-2">
        {itemIds.map((itemId, i) => {
          const displayPosition = showAnchorAtTop ? (i + 1) : i;

          return (isAnchorAppliance ? (i < promoPodLimit) : true) && (
            <Fragment key={itemId}>
              <PromoPod
                displayPosition={displayPosition}
                itemId={itemId}
                key={itemId}
                onProductPodClick={onProductPodClick}
              />
            </Fragment>
          );
        })}
        <ListObserver pause={isAwaitingData} observer={observer}>
          {placeholderItemIds.slice(0, numPlaceholdersToShow).map((itemId) => (
            isAwaitingData && itemIds.length === 0 && <PromoPodPlaceholder key={itemId} />
          ))}
        </ListObserver>
      </div>
    </article>
  );
};

PromoList.displayName = 'PromoList';

PromoList.propTypes = {
  itemIds: arrayOf(string),
  observer: shape({}),
  listText: node,
  onProductPodClick: func,
  placeholderItemIds: arrayOf(string),
};

PromoList.defaultProps = {
  itemIds: [],
  observer: null,
  listText: null,
  onProductPodClick: noop,
  placeholderItemIds: [],
};

PromoList.dataModel = PromoListDataModel;
