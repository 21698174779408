import React, { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { ArrowBack, ArrowForward } from '@one-thd/sui-icons';
import { Typography, IconButton } from '@one-thd/sui-atomic-components';

const getSwiperPaginationData = (swiper) => {
  if (!swiper || swiper.destroyed) {
    return { current: 0, total: 0 };
  }
  // See: https://github.com/nolimits4web/swiper/blob/master/src/modules/pagination/pagination.js#L70
  const slidesLength = swiper.virtual && swiper.params.virtual.enabled
    ? swiper.virtual.slides.length
    : swiper.slides.length;
  // Current/Total
  let current;
  const total = swiper.params.loop
    ? Math.ceil((slidesLength - swiper.loopedSlides * 2) / swiper.params.slidesPerGroup)
    : swiper.snapGrid.length;
  if (swiper.params.loop) {
    current = Math.ceil(
      (swiper.activeIndex - swiper.loopedSlides) / swiper.params.slidesPerGroup,
    );
    if (current > slidesLength - 1 - swiper.loopedSlides * 2) {
      current -= slidesLength - swiper.loopedSlides * 2;
    }
    if (current > total - 1) current -= total;
    if (current < 0 && swiper.params.paginationType !== 'bullets') current = total + current;
  } else if (typeof swiper.snapIndex !== 'undefined') {
    current = swiper.snapIndex;
  } else {
    current = swiper.activeIndex || 0;
  }
  return { current: current + 1, total };
};

const Paginator = (props) => {
  const {
    hidePaginationOnOneOfOne,
    loop
  } = props;
  const swiper = useSwiper();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);
  const updateBoundaries = (updatedSwiper) => {
    setIsBeginning(updatedSwiper.isBeginning);
    setIsEnd(updatedSwiper.isEnd);
    const paginationData = getSwiperPaginationData(updatedSwiper);
    setCurrent(paginationData.current);
    setTotal(paginationData.total);
  };

  const resetCurrentIndex = (updatedSwiper) => {
    updatedSwiper.slideTo(0);
  };

  const isRendered = !(hidePaginationOnOneOfOne && (current === 1 && total === 1));

  useEffect(() => {
    swiper.on('progress', updateBoundaries);
    swiper.on('activeIndexChange', updateBoundaries);
    swiper.on('update', resetCurrentIndex);
    updateBoundaries(swiper);
    return () => {
      swiper.off('progress', updateBoundaries);
      swiper.off('activeIndexChange', updateBoundaries);
      swiper.off('update', resetCurrentIndex);
    };
  }, [swiper]);

  return isRendered && (
    <div className="sui-flex sui-items-center sui-gap-1 sui-m-1 sui-ml-4">
      <IconButton
        icon={ArrowBack}
        disabled={isBeginning && !loop}
        aria-label={isBeginning ? 'This is the first slide' : 'Previous slide'}
        onClick={() => swiper.slidePrev()}
        buttonSize="relaxed"
      />
      <Typography component="div" color="primary" variant="body-lg">{current}/{total}</Typography>
      <IconButton
        icon={ArrowForward}
        disabled={isEnd && !loop}
        aria-label={isEnd ? 'This is the last slide' : 'Next slide'}
        onClick={() => swiper.slideNext()}
        buttonSize="relaxed"
      />
    </div>
  );
};

export { Paginator };
