import { string, number, customType, shape } from '@thd-nucleus/data-sources';

export const authParams = {
  channelId: number().isRequired(),
  customerAccountId: string().isRequired(),
  userId: string().isRequired(),
};

export const acceptChallengeParams = {
  ...authParams,
  challengeId: number().isRequired(),
};

export const activatePerkParams = {
  userId: string().isRequired(),
  custAccountId: string().isRequired(),
  perkActivationRequest: customType('PerkActivationRequest').shape({
    perkId: string().isRequired(),
    tierRewardId: string().isRequired(),
    userId: string().isRequired(),
    programId: string().isRequired(),
    tierId: string().isRequired(),
  }).isRequired(),
};

export const infoPerksParams = {
  custAccountId: string().isRequired(),
  userId: string().isRequired(),
  offerType: string().isRequired(),
};

export const redeemPerkParams = {
  userId: string().isRequired(),
  custAccountId: string().isRequired(),
  perkRedemptionRequest: customType('PerkRedemptionRequest').shape({
    perkId: string().isRequired(),
    paymentId: string().isRequired(),
    perkType: string().isRequired(),
    perkSourceUUID: string().isRequired(),
  }).isRequired(),
};
