import React from 'react';

const Error = () => (
  <p className="tooltip_error">
    We are sorry, we are experiencing technical difficulties. Please try again shortly.
  </p>
);

Error.propTypes = {};

export default Error;
