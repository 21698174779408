export const publicAccessType = 'Public';
export const privateAccessType = 'Private';
export const sharedAccessType = 'Shared';

export const LIST_SETTINGS_SAVE = 'list-forms.settings-save';
export const LIST_CREATE_WO_PRODUCTS = 'list-forms.create-without-products';
export const LIST_DELETE = 'list-forms.list-delete';
export const LIST_COPY = 'list-forms.list-copy';
export const LIST_SETTINGS_FORM_LOAD = 'list-forms.settings-form-load';
export const LIST_SHARED_SAVE_CLICK = 'list-forms.save-a-shared-list';

export const LIST_ALREADY_EXISTS_ERROR = 'List name already exists';

// eslint-disable-next-line max-len
export const INVALID_LIST_NAME_MESSAGE = 'Invalid List Name: Only a-z, 0-9, ! @ # $ % ^ & * ( ) - _ + , . ? " \' : ; \\ are allowed.';
export const EMPTY_LIST_MESSAGE = 'List name cannot be empty';