import { useCallback, useEffect, useState } from 'react';

const minWidthLarge = 1024;
const minWidthMedium = 768;

export function useWindowWidth() {
  const [windowWidthMeasure, setWindowWidthMeasure] = useState('minWidthLarge');

  const handleResize = useCallback(() => {
    const width = window.innerWidth;

    if (width >= minWidthLarge) {
      setWindowWidthMeasure('minWidthLarge');
    } else if (width >= minWidthMedium) {
      setWindowWidthMeasure('minWidthMedium');
    } else {
      setWindowWidthMeasure('maxWidthMedium');
    }
  });

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', handleResize, {
      capture: true,
      passive: true,
    });
  }

  useEffect(() => {
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidthMeasure;
}
