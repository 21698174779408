import React from 'react';
import { string } from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { publishAnalytics } from '../../../../utils';

function publishArrowClickEvent() {
  publishAnalytics('recentPurchases.view-order-arrow-click');
}

export function RecentPurchasesTableButton({ href }) {
  const imageNode = <ArrowForward size="small" />;
  return (
    <div className="sui-flex sui-items-center sui-h-20">
      <a
        data-testid="specificOrderLink"
        href={href}
        onClick={publishArrowClickEvent}
        className="sui-flex sui-items-center sui-justify-center sui-w-full sui-h-full"
      >
        {imageNode}
      </a>
    </div>
  );
}

export const RecentPurchasesCardButton = ({ href }) => {
  return (
    <div className="sui-flex sui-items-center sui-h-9">
      <Link
        data-testid="specificOrderLink"
        href={href}
        underline="none"
        onClick={publishArrowClickEvent}
      >
        View Details
      </Link>
    </div>
  );
};

RecentPurchasesTableButton.propTypes = {
  href: string.isRequired,
};

RecentPurchasesCardButton.propTypes = {
  href: string.isRequired,
};
