/* eslint-disable operator-linebreak */

import { orderDetailsURL, purchaseHistoryURL } from '../../constants';

// Restricted characters for input fields: < > / ( ) { } " " :
export const getValidationError = ({ value = '' }) => {
  const validCharacters = /^[ [\]|a-z|A-Z|!@#$%^&*0-9-_+,.?';\\]+$/;
  const blank = value.replace(/\s/g, '') === '';
  const overTextLimit = value.length >= 60;
  let isValid = validCharacters.test(value);
  let error;
  if (!isValid && !blank) {
    error = 'Invalid: Only a-z, 0-9, ! @ # $ % ^ & * - _ + , . ? \' ; \\ are allowed.';
  } else if (blank) {
    error = 'Invalid: Field cannot be empty';
  } else if (overTextLimit) {
    error = 'Invalid: Reached Text Limit';
  }
  return error;
};

function hasRequiredQueryParameters({ order, storeNumber, isOnline }) {
  const {
    orderNumbers,
    registerNumber,
    salesDate,
    transactionId,
    transactionType,
  } = order;

  const hasSharedRequirements =
    typeof registerNumber !== 'undefined' &&
    registerNumber !== null &&
    typeof salesDate !== 'undefined' &&
    salesDate !== null &&
    typeof storeNumber !== 'undefined' &&
    storeNumber !== null &&
    typeof transactionId !== 'undefined' &&
    transactionId !== null;

  if (isOnline) {
    return (
      hasSharedRequirements &&
      typeof orderNumbers !== 'undefined' &&
      orderNumbers !== null &&
      typeof transactionType !== 'undefined' &&
      transactionType !== null
    );
  }

  return hasSharedRequirements;
}

export const createOrderDetailsURL = ({
  isOnline,
  order,
  customerInfo,
  storeNumber,
}) => {

  if (!hasRequiredQueryParameters({ order, storeNumber, isOnline })) {
    return purchaseHistoryURL;
  }

  let parameters = new URLSearchParams();
  // these query parameters are required for both online and in-store orders
  parameters.append('customerAccountId', customerInfo.customerAccountId);
  parameters.append('registerNumber', order.registerNumber);
  parameters.append('salesDate', order.salesDate);
  parameters.append('storeNumber', storeNumber);
  parameters.append('transactionId', order.transactionId);
  if (isOnline) {
    parameters.append('orderNumber', order.orderNumbers);
    parameters.append('transactionType', order.transactionType);
  }

  return `${orderDetailsURL}?` + parameters.toString();
};

export function getChannelId(channel) {
  const channelIds = {
    desktop: '1',
    mobile: '2',
    tablet: '3',
    prox: '4',
    consumerapp: '5',
    proapp: '6',
  };

  return channelIds[channel?.toLowerCase()] || '1';
}

export function getStoreNumber(order, isOnline) {
  let storeNumber = null;

  if (order.storeNumber) {
    storeNumber = order.storeNumber;
    return storeNumber;
  }

  /**
   * @TODO
   *
   * Think about removing this if we can get confirmation
   * that the `storeNumber` will never be `null`, even for
   * `Online` orders.
   */
  try {
    storeNumber =
      order.orderOrigin === 'Online' || isOnline
        ? 8119
        : /**
           * We want this to throw if there's no match on the
           * `orderOrigin`. If there's no valid match result,
           * then we'll try to access [0] of `null`, which throws.
           *
           * If we optionally chain it, though, like `matchResult?.[0]`
           * then we get `NaN`, which will cause later checks to fail.
           */
        Number(order.orderOrigin.match(/\d+/g)[0]);
  } catch {
    storeNumber = null;
  }

  return storeNumber;
}

export function isOnlineOrder(orderNumber) {
  if (!orderNumber) return false;

  const firstChar = orderNumber.charAt(0).toUpperCase();
  return firstChar === 'W' || firstChar === 'C';
}

export const orderTotalFormatter = (orderTotal) => {
  if (!orderTotal) return null;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(orderTotal);
};