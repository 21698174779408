export const isTemplateToRender = ({ shippingModel }) => {
  let isRenderTemplates = false;
  if ((shippingModel?.shipModes?.length > 0)) {
    isRenderTemplates = true;
  }
  return isRenderTemplates;
};

export default {
  showErrorTemplate: function showErrorTemplate({ shippingModel, isResponseFetched }) {
    let isErrorTemplate = false;
    if ((!shippingModel
        || !shippingModel.shipModes
        || shippingModel.shipModes.length === 0)
        && isResponseFetched
    ) {
      isErrorTemplate = true;
    }
    return isErrorTemplate;
  }
};