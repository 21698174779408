import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import {
  Button,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Alert,
} from '@one-thd/sui-atomic-components';

export const CopyLinkDrawerBody = ({
  onClose,
  menuName,
  setMenuName,
  setCopyLinkDrawer,
  setShareListDrawer
}) => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('CopyLinkDrawerBody.ready', {});
  }, []);

  const PreviousDrawer = () => {
    setCopyLinkDrawer(false);
    setShareListDrawer(true);
    setMenuName('Share List');
  };

  return (
    <>
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        <DrawerHeader title={menuName} onClose={onClose} onBack={PreviousDrawer} />
        <DrawerBody>
          <Alert status="success" data-testid="copy-link-alert">
            Link copied to clipboard
          </Alert>
        </DrawerBody>
        <DrawerFooter>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
            data-testid="close-copy-link-drawer"
          >
            Close
          </Button>
        </DrawerFooter>
      </div>
    </>
  );
};

CopyLinkDrawerBody.propTypes = {
  onClose: func.isRequired,
  menuName: string.isRequired,
  setMenuName: func.isRequired,
  setCopyLinkDrawer: func.isRequired,
  setShareListDrawer: func.isRequired
};

CopyLinkDrawerBody.displayName = 'CopyLinkDrawerBody';