function getState(zipcode) {
  // Ensure we don't parse strings starting with 0 as octal values
  const zip = parseInt(zipcode, 10);
  let st = '';
  // Code blocks alphabetized by state
  if (zip >= 35000 && zip <= 36999) {
    st = 'AL';
  } else if (zip >= 99501 && zip <= 99950) {
    st = 'AK';
  } else if (zip >= 85000 && zip <= 86999) {
    st = 'AZ';
  } else if (zip >= 71600 && zip <= 72999) {
    st = 'AR';
  } else if (zip >= 90000 && zip <= 96699) {
    st = 'CA';
  } else if (zip >= 80000 && zip <= 81999) {
    st = 'CO';
  } else if (zip >= 6000 && zip <= 6999) {
    st = 'CT';
  } else if (zip >= 19700 && zip <= 19999) {
    st = 'DE';
  } else if (zip >= 32000 && zip <= 34999) {
    st = 'FL';
  } else if (zip >= 30000 && zip <= 31999) {
    st = 'GA';
  } else if (zip >= 96910 && zip <= 96932) {
    st = 'GU';
  } else if (zip >= 96701 && zip <= 96898) {
    st = 'HI';
  } else if (zip >= 83200 && zip <= 83999) {
    st = 'ID';
  } else if (zip >= 60000 && zip <= 62999) {
    st = 'IL';
  } else if (zip >= 46000 && zip <= 47999) {
    st = 'IN';
  } else if (zip >= 50000 && zip <= 52999) {
    st = 'IA';
  } else if (zip >= 66000 && zip <= 67999) {
    st = 'KS';
  } else if (zip >= 40000 && zip <= 42999) {
    st = 'KY';
  } else if (zip >= 70000 && zip <= 71599) {
    st = 'LA';
  } else if (zip >= 3900 && zip <= 4999) {
    st = 'ME';
  } else if (zip >= 20600 && zip <= 21999) {
    st = 'MD';
  } else if (zip >= 1000 && zip <= 2799) {
    st = 'MA';
  } else if (zip >= 48000 && zip <= 49999) {
    st = 'MI';
  } else if (zip >= 55000 && zip <= 56999) {
    st = 'MN';
  } else if (zip >= 38600 && zip <= 39999) {
    st = 'MS';
  } else if (zip >= 63000 && zip <= 65999) {
    st = 'MO';
  } else if (zip >= 59000 && zip <= 59999) {
    st = 'MT';
  } else if (zip >= 27000 && zip <= 28999) {
    st = 'NC';
  } else if (zip >= 58000 && zip <= 58999) {
    st = 'ND';
  } else if (zip >= 68000 && zip <= 69999) {
    st = 'NE';
  } else if (zip >= 88900 && zip <= 89999) {
    st = 'NV';
  } else if (zip >= 3000 && zip <= 3899) {
    st = 'NH';
  } else if (zip >= 7000 && zip <= 8999) {
    st = 'NJ';
  } else if (zip >= 87000 && zip <= 88499) {
    st = 'NM';
  } else if (zip >= 10000 && zip <= 14999) {
    st = 'NY';
  } else if (zip >= 43000 && zip <= 45999) {
    st = 'OH';
  } else if (zip >= 73000 && zip <= 74999) {
    st = 'OK';
  } else if (zip >= 97000 && zip <= 97999) {
    st = 'OR';
  } else if (zip >= 15000 && zip <= 19699) {
    st = 'PA';
  } else if ((zip >= 600 && zip <= 799) || (zip >= 900 && zip <= 999)) {
    st = 'PR';
  } else if (zip >= 2800 && zip <= 2999) {
    st = 'RI';
  } else if (zip >= 29000 && zip <= 29999) {
    st = 'SC';
  } else if (zip >= 57000 && zip <= 57999) {
    st = 'SD';
  } else if (zip >= 37000 && zip <= 38599) {
    st = 'TN';
  } else if ((zip >= 75000 && zip <= 79999) || (zip >= 88500 && zip <= 88599)) {
    st = 'TX';
  } else if (zip >= 84000 && zip <= 84999) {
    st = 'UT';
  } else if (zip >= 5000 && zip <= 5999) {
    st = 'VT';
  } else if (zip >= 22000 && zip <= 24699) {
    st = 'VA';
  } else if (zip >= 801 && zip <= 851) {
    st = 'VI';
  } else if (zip >= 20000 && zip <= 20599) {
    st = 'DC';
  } else if (zip >= 98000 && zip <= 99499) {
    st = 'WA';
  } else if (zip >= 24700 && zip <= 26999) {
    st = 'WV';
  } else if (zip >= 53000 && zip <= 54999) {
    st = 'WI';
  } else if (zip >= 82000 && zip <= 83199) {
    st = 'WY';
  }
  return st;
}

export const getStateFromZipcode = (zipcode) => {
  try {
    if (zipcode) {
      return getState(zipcode);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return '';
};
