export const ERROR = {
  // eslint-disable-next-line max-len
  INVALID_LIST_NAME: 'Invalid List Name: Only a-z, 0-9, ! @ # $ % ^ & * ( ) - _ + , . ? " \' : ; \\ are allowed.',
  EMPTY_LIST: 'List name cannot be empty',
  LIST_ALREADY_EXISTS: 'List name already exists',
  GENERAL_API_FAIL: 'Something went wrong, please try after sometime'
};

export const TRACK = {
  OPEN_DRAWER: 'save-to-favorites.track-overlay',
  BUTTON_CLICK: 'save-to-favorites.track-click',
  ADD_ITEM: 'save-to-favorites.track-add-item',
  ADD_PRODUCTS: 'save-to-favorites.track-add-products',
  CREATE_LIST_WITH_ITEM: 'save-to-favorites.create-a-list-with-item',
  CREATE_LIST_WITH_PRODUCTS: 'save-to-favorites.create-a-list-with-products'
};
