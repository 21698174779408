import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '../card/CardContent';

/**
 * Internally `Skeleton` is implemented with the `SkeletonContent` component.
 * Through this component you can change settings for direction, basis, grow, and shrink with the `orientation`,
 * `initialSize`, `grow`, `disableShrink` properties.
 * You can also disable padding and gaps with he `disableGutters` and `disablePadding` properties.
 * You can leverage it to build customized skeleton layouts.
 *
 * Related components: [Skeleton](#skeleton)
 *
 * Usage:
 *
 * ```jsx
 * import { SkeletonContent } from '@one-thd/sui-atomic-components';
 * ```
 */
const SkeletonContent = React.forwardRef((props, ref) => {
  return (
    <CardContent
      ref={ref}
      {...props}
    />
  );
});

SkeletonContent.displayName = 'SkeletonContent';

SkeletonContent.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the gap is removed.
   * @default false
   */
  disableGutters: PropTypes.bool,
  /**
   * If `true`, all the padding is removed.
   * If `'x'` or `'y'`, the respective axis padding will be removed.
   * @default false
   */
  disablePadding: PropTypes.oneOf([true, 'x', 'y', false]),
  /**
   * If `true`, prevents a flex item from shrinking.
   * @default false
   */
  disableShrink: PropTypes.bool,
  /**
   * If true, will set flex items to grow to fill available space.
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * If set, will control the initial size of flex items.
   */
  initialSize: PropTypes.oneOf(['1/4', '2/4', '3/4', '4/4', 'full']),
  /**
   * The component orientation. Sets the direction of flex items.
   * @default 'vertical'
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

export { SkeletonContent };