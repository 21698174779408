import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import helpers from '../../../helpers/price-helper';

export const StrikeThroughPerItem = ({ original, discount }) => {
  const formattedDiscount = helpers.getUSDollarWithCommas(discount) + '/item';
  const formattedOriginal = helpers.getUSDollarWithCommas(original);
  return (
    <Typography>
      ({formattedDiscount} <Typography component="span" decoration="line-through">{formattedOriginal}</Typography>)
    </Typography>
  );
};

StrikeThroughPerItem.propTypes = {
  original: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
};

StrikeThroughPerItem.defaultProps = {
};

StrikeThroughPerItem.displayName = 'StrikeThroughPerItem';
