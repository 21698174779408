import { buildRevJetBanner, buildRevJetBannerMock, getRevJetConfig, getPubRef } from './revjet';
import { getQueryParams } from './requestUrlHelpers';
import { get } from './axios';
import {
  getUrlParams,
  getMockPlaData,
  isBrandFpn,
  haveProductsChanged,
  triggerNewRelicTimeLog,
  isValidRequest,
  triggerNewRelic,
  getAdTypeFromParams,
} from './helpers';
import { newRelicConstants } from '../constants';
import { adServerClients, PRODUCT } from '../constants/constants';
import { injectStaticImageAsSponsoredBanner } from './experiments';

export const shouldMakeApiRequest = ({
  lastRequestScenario, experienceContext, pageContext, customRenderId, browserId, schema
}) => {
  const validSchemas = ['hp_sponsored',
    'hp_sponsored_auth',
    'cat_sponsored',
    'thankyoupage_sponsored',
    'cart_sponsored'];

  if (!browserId) return false;
  // check for homepage and contentful clp page first
  if (validSchemas.includes(schema) || customRenderId) return true;

  if (isBrandFpn({ experienceContext, pageContext })) {

    return false;
  }

  const currentUrl = window.location.pathname + window.location.search;

  if (lastRequestScenario.url !== currentUrl) {

    if (pageContext?.isCategory || pageContext?.isPip || pageContext?.isStorePage) {
      return true;
    }

    if (haveProductsChanged({ lastRequestScenario, pageContext })) {
      return true;
    }
  }

  return false;
};

export const isValidLeaderboardScenario = ({
  browserId, schemaId, lastRequestScenario, experienceContext, pageContext, channel
}) => {

  const validSchemaIds = ['browse_sponsored__leaderboard', 'search_sponsored__leaderboard'];
  if (channel !== 'desktop'
     || !browserId
     || !validSchemaIds.includes(schemaId)
     || isBrandFpn({ experienceContext, pageContext })) {
    return false;
  }

  const currentUrl = window.location.pathname + window.location.search;
  if (lastRequestScenario.url !== currentUrl) {

    if (pageContext?.isPLP) {
      return true;
    }

    if (haveProductsChanged({ lastRequestScenario, pageContext })) {
      return true;
    }
  }
  return false;

};

export const makeApiRequest = async (requestConfig) => {

  // params is only for mock testing
  const params = getUrlParams(window.location.href);
  if (params.mockSponsoredBannerId) {
    if (requestConfig.setHasData) {
      requestConfig.setHasData(true);
    }
    buildRevJetBannerMock(requestConfig);
  } else if (params.mockPlaSku && requestConfig.setAdProducts) {
    requestConfig.setAdProducts(getMockPlaData(params.mockPlaSku));
    if (requestConfig?.setIsAPICallCompleted) {
      requestConfig.setIsAPICallCompleted(true);
    }
  } else {
    // makes valid api call
    const queryParams = getQueryParams(requestConfig);
    if (isValidRequest(queryParams)) {
      try {

        const response = await get({ params: queryParams });

        const { data, status } = response;
        const bannerWinnerReceived = Date.now();
        const adType = getAdTypeFromParams(queryParams);
        if (adType === newRelicConstants.STANDARD_BANNER && status === 200) {
          if (requestConfig.startTime !== undefined) {
            // SEND TIME FROM BANNER INITIATED TO BANNER WINNER RECEIVED
            triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_INIT_TO_WINNER,
              requestConfig.startTime, bannerWinnerReceived, adServerClients.PIQ);
          }
        }

        triggerNewRelic(adType, `API-${status}`);
        if (requestConfig?.setIsAPICallCompleted) {
          requestConfig.setIsAPICallCompleted(true);
        }
        if (data) {
          if (data.customerRecentlyViewedSkus) {
            const skus = JSON.stringify(data.customerRecentlyViewedSkus.skus);
            localStorage.setItem('retailMediaRecentlyViewedSku', skus.slice(1, skus.length - 1));
            let refreshAtDate = new Date().getTime() + data.customerRecentlyViewedSkus.refreshAt;
            localStorage.setItem('retailMediaRecentlyViewedSkuRefreshAt', refreshAtDate);
          }
          if (requestConfig?.show === PRODUCT || requestConfig?.carouselType) {
            if (requestConfig.setHasData) {

              requestConfig.setHasData(true);
            }

            const { adsFiltered = 0, usedDataModelForTargeting = 'none' } = data;

            requestConfig.setAdProducts(data.products);
            if (requestConfig.setCarouselData) {
              requestConfig.setCarouselData({
                adsFiltered,
                targetingSource: usedDataModelForTargeting
              });
            }

            triggerNewRelic(
              `sponsoredBottomCarousel-totalAds-${usedDataModelForTargeting}`,
               data?.adsFiltered + data?.products.length
            );
            if (requestConfig.setPageId) {
              requestConfig.setPageId(data.pageId);
            }
          } else {
            requestConfig.setHasData(true);

            if (requestConfig.startTime !== undefined) {
              triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_API_RESPONSE,
                requestConfig.startTime,
                Date.now(),
                adServerClients.PIQ);
            }

            if (requestConfig.isInnervateGroup) {
              injectStaticImageAsSponsoredBanner(data, requestConfig.startTime, requestConfig.clickNextBtnTimeout);
            } else if (!requestConfig.setTagProperties) {
              buildRevJetBanner({ data, requestConfig, adType });
            } else {
              return data;
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('ERROR: Could not make api request', error);
        if (requestConfig.setServerError) {
          requestConfig.setServerError(true);
        }
        if (requestConfig?.setIsAPICallCompleted) {
          requestConfig.setIsAPICallCompleted(true);
        }
      }
    }
  }
  return requestConfig;
};
