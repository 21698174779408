import { useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const defaultTheme = () => {
  return {};
};

export const useTheme = (Component, componentProps) => {
  const { theme, props } = useContext(ThemeContext);

  const resp = (theme || defaultTheme)(props) || {};
  const { themeProps = {} } = resp[Component.displayName] || {};
  const defaultedAndThemes = {
    ...(Component.defaultThemeProps || {}),
    ...themeProps
  };
  const result = Object.keys(defaultedAndThemes).reduce((acc, cur) => {

    if (typeof defaultedAndThemes[cur] === 'function') {
      const res = defaultedAndThemes[cur](componentProps);
      return {
        ...acc,
        [cur]: res
      };
    }
    return {
      ...acc,
      [cur]: defaultedAndThemes[cur]
    };
  }, {});
  return {
    props: result
  };

};
