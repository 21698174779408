import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { ShopByCategory as ShopByCategoryComponent } from '@thd-olt-component-react/shop-by-category';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function ShopByCategory({ id }) {
  return (
    <ErrorBoundary name="shop-by-category">
      <QueryProvider key={id} cacheKey={id}>
        <div
          id={`shop-by-category-php-${id}`}
          className="sui-h-full"
        >
          <ImpressionProvider
            data={{
              id,
              name: 'ShopByCategory',
              component: 'ShopByCategory',
              type: 'content',
              position: '',
            }}
          >
            <ShopByCategoryComponent
              componentId={id}
              componentClass="ShopByCategory"
            />
          </ImpressionProvider>
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

ShopByCategory.propTypes = {
  id: string,
};

ShopByCategory.defaultProps = {
  id: undefined,
};
