/**
 * @function getContentfulTargetingIds
 * @param {{content: ContentfulContent}} config
 * @description Takes the content key from a typical Contentful response, and loops through the entire
 * object, pulling out any mboxes, campaignIds or innervateTags that are associated with a ComponentSelector.
 * @returns {{
 * mboxes: String[],
 * campaignIds: [{ campaignId: String, channel: String }],
 * innervateTags: [{ tagID: String, adkey: String, csId: String, campaignMetadata: String }]
 * }}
 */
export const getContentfulTargetingIds = ({ content }) => {
  const campaignIds = [];
  const mboxes = [];
  const innervateTags = [];

  if (content) {
    const variations = [];
    Object.keys(content).forEach((key) => {
      const componentData = content[key];
      const isDynamicSection = Array.isArray(componentData) && key !== 'breadCrumbs';
      const isContentfulComponent = !!componentData?.__typename || isDynamicSection;

      if (isContentfulComponent) {
        let components = [];
        if (isDynamicSection) {
          components = componentData.reduce(
            (acc, sections) => [...acc, ...(sections.components || [])],
            []
          );
        } else {
          components = componentData.components || [];
        }
        const variationArr = components.filter((comp) => comp?.__typename === 'ComponentSelector');
        variations.push(...variationArr);
      }
    });

    variations.forEach((comp) => {
      const { targetingType, id, campaignMetadata } = comp;
      if (targetingType?.__typename === 'AdobeTarget') {
        mboxes.push(targetingType.mbox);
      } else if (targetingType?.__typename === 'MultiArmBandit') {
        campaignIds.push({ campaignId: targetingType.campaignId, channel: targetingType.channel });
      } else if (targetingType?.__typename === 'Innervate') {
        innervateTags.push({
          tagID: targetingType.tagID,
          adkey: targetingType.adkey,
          csId: id,
          campaignMetadata,
        });
      }
    });
  }

  return { mboxes, campaignIds, innervateTags };
};
