/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { transformErrorMsg, getOutOfStockMessage } from '../util/errorMapper';

const ffmTypeMap = {
  // legacy
  ShipToStore: 'boss',
  BOPIS: 'bopis',
  ShipToHome: 'sth',
  DeliverFromStore: 'express delivery',
  DirectDelivery: 'direct delivery',
  // new
  'direct delivery': 'direct delivery',
  boss: 'boss',
  bopis: 'bopis',
  sth: 'sth',
  'express delivery': 'express delivery',
  delivery: 'delivery'
};

const currentFfmMap = {
  boss: 'ShipToStore',
  bopis: 'BOPIS',
  sth: 'ShipToHome',
  'express delivery': 'DeliverFromStore',
  'direct delivery': 'DirectDelivery',
  delivery: 'delivery',
  ShipToStore: 'ShipToStore',
  BOPIS: 'BOPIS',
  ShipToHome: 'ShipToHome',
  DeliverFromStore: 'DeliverFromStore',
  DirectDelivery: 'DirectDelivery',
};

let WARNING_DISPLAYED = false;
export const patchResult = (data, error) => {
  try {
    // TODO: add fields to graphql schema
    // item:
    //    selectedFulfillment: stringType(),
    //    availableFulfillments: arrayOf(stringType()),

    // item.product.fulfillment :
    // deliveryAvailability {
    //   zipCode
    //   primaryStrNbr
    //   availability {
    //     itemId
    //     modelNbr
    //     status
    //     __typename
    //   }
    //   earliestAvailabilityDate
    //   hasFreeShipping
    //   __typename
    // }
    //
    if (data && data.cartInfo && data.cartInfo.items) {
      data = JSON.parse(JSON.stringify(data));
      // move errors into messages, and assign item-level errors/messages
      data.cartInfo.messages = (data.cartInfo.messages || []).concat(getErrors(error)) || [];
      data.cartInfo.messages = handlePromoWarning(data.cartInfo.messages, data.cartInfo.promos);
      data.cartInfo.messages = assignMessageType(data.cartInfo.messages);
      data.cartInfo.messages = handleTransformPageLevelErrors(data.cartInfo.messages);
      data.cartInfo.items.forEach((item) => {
        const availableFulfillments = [];
        let selectedFulfillment = item.selectedFulfillment || null;
        try {
          checkForOutOfStock(item);
          if (item.product.fulfillment.fulfillmentOptions) { // appliance does not have ffmOptions
            patchFulfillmentTopLevel(item.product.fulfillment);
            item.product.fulfillment.fulfillmentOptions.forEach((ffmOpt) => {
              if (ffmOpt.fulfillable === null) {
                ffmOpt.fulfillable = true;
                // if (!WARNING_DISPLAYED) {
                //   console.warn('Need these from backend: ffmOpt.fulfillable');
                //   WARNING_DISPLAYED = true;
                // }
              }
              ffmOpt.addressId = null;

              ffmOpt.services.forEach((service) => {
                availableFulfillments.push(service.type);
                if (service.selected === true) {
                  selectedFulfillment = service.type;
                }
                if (item?.selectedFulfillment !== null) {
                  selectedFulfillment = item?.selectedFulfillment;
                }
                service.type = ffmTypeMap[service.type];
                // service.freeDeliveryThreshold = (service.freeDeliveryThreshold ? 1 : 99999);

                // mark anchor location
                if (service.locations && service.locations.length) {
                  service.locations.forEach((location, index) => {
                    location.isAnchor = (index === 0);
                    location.type = location.type || (service.type === 'sth' || service.type === 'boss') ? 'online' : 'store';
                  });
                }

                service = setConsolidatedDelivery(service);
                service = setMaxCartPriceContributor(service, item.id);
                if (service?.type === 'express delivery') {
                  service = setTotalDeliveryCharge(service, data.cartInfo.totals?.deliveryCharge);
                }
              });
            });
          }

          item.product.fulfillment.lineItemId = item.id;

        } catch (err) {
          console.error(err);
        }
        item.availableFulfillments = availableFulfillments.map((ffm) => (currentFfmMap[ffm])) || ['DirectDelivery'];
        item.selectedFulfillment = currentFfmMap[selectedFulfillment] || 'DirectDelivery';
        item.fulfillmentLocation = getFulfillmentLocation(item);
        item.product.fulfillment.deliveryAvailability = item.product.fulfillment.deliveryAvailability || null;

        item.messages = setItemMessages(data.cartInfo.messages, item);

        dedupeAddons(item);
      });
    }
  } catch (err) {
    console.error(err);
  }

  return data;
};

const patchFulfillmentTopLevel = (fulfillment) => {
  // console.warn('Need these from backend:',
  //   'fulfillment.anchorStoreStatus',
  //   'fulfillment.anchorStoreStatusType'
  // );
  fulfillment.anchorStoreStatus = fulfillment.anchorStoreStatus === null ? true : fulfillment.anchorStoreStatus;
  fulfillment.anchorStoreStatusType = fulfillment.anchorStoreStatusType === null ? 'ACTIVE' : fulfillment.anchorStoreStatusType;
};

const checkForOutOfStock = (item) => {
  try {
    const { product, quantity } = item;
    const { fulfillment } = product;
    let hasQuantity = false;
    if (fulfillment?.fulfillmentOptions) {
      fulfillment.fulfillmentOptions.forEach((ffmOpt) => {
        let ffmOptHasQuantity = false;
        ffmOpt.services.forEach((service) => {
          if (service.type === 'direct delivery' || service.type === 'DirectDelivery') {
            hasQuantity = true;
          }
          if (service.locations && service.locations.length) {
            service.locations.forEach((location) => {
              const inventory = location?.inventory;
              if (inventory && (inventory.quantity || inventory.isInStock)) {
                hasQuantity = true;
                ffmOptHasQuantity = true;
                if (inventory.quantity) {
                  inventory.isOutOfStock = false;
                  inventory.isUnavailable = false;
                  inventory.isInStock = true;
                }
                //  else if (product.identifiers.productCategory === 'custom-blinds') {
                //   inventory.quantity = quantity;
                // }
              }
            });
          }
        });
        if (!ffmOptHasQuantity) {
          ffmOpt.fulfillable = false;
        }
      });
      if (!hasQuantity) {
        // mark as out of stock by removing all fulfillmentOptions
        delete fulfillment.fulfillmentOptions;
        fulfillment.outOfStock = true;
        product.addons = null;
      }
    }
  } catch (err) {
    console.error(err);
  }
};

const getErrors = (errorResponse) => {
  let errors = [];
  try {
    if (errorResponse?.graphQLErrors) {
      errors = errorResponse.graphQLErrors[0].extensions.cart_info_errors || [];
    }
  } catch (err) {
    console.error(err);
  }
  return errors;
};

const assignMessageType = (messages) => {
  try {
    messages.forEach((message) => {
      message.type = message.type || (message.errorCode ? 'error' : 'info');
    });
  } catch (err) {
    console.error(err);
  }
  return messages;
};

const handlePromoWarning = (messages, promos) => {
  try {
    messages = messages || [];
    (promos || []).forEach((promo) => {
      if (promo?.message?.longDesc) {
        if (promo.message.longDesc.includes('does not apply')) {
          const message = {
            description: promo.message.longDesc,
            type: 'warning'
          };
          messages.push(message);
        }
      }
    });
  } catch (err) {
    console.error(err);
  }
  return messages;
};

const handleTransformPageLevelErrors = (messages) => {
  return (messages || []).map((message) => {
    if (message?.errorCode && message?.correlationType !== 'cartLineItem') {
      return transformErrorMsg(message);
    }
    return message;
  });
};

const dedupeAddons = (item) => {
  try {
    if (item?.product?.addons) {
      let addOns = {};
      let dedupedAddons = [];
      item.product.addons.forEach((addon) => {
        const key = Object.values(addon).join('-');
        if (!addOns[key]) {
          addOns[key] = true;
          dedupedAddons.push(addon);
        } else {
          console.warn('Deduped addon! Need to have backend fix this', addon);
        }
      });
      item.product.addons = dedupedAddons;
    }

  } catch (err) {
    console.error(err);
  }
};

function setConsolidatedDelivery(service) {
  try {
    service.consolidatedDeliveryCharge = (service.consolidatedDeliveryCharge || service.totalCharge || null);
    if (service.consolidatedDeliveryCharge && service.consolidatedDeliveryCharge.toString) {
      service.consolidatedDeliveryCharge = service.consolidatedDeliveryCharge.toString();
    }
  } catch (err) {
    console.error(err);
  }
  return service;
}

function setMaxCartPriceContributor(service, lineItemId) {
  try {
    service.lineItemId = lineItemId || null;
    service.maxCartPriceContributor = lineItemId || null;
  } catch (err) {
    console.error(err);
  }
  return service;
}

function setTotalDeliveryCharge(service, totalCharge) {
  try {
    service.totalCharge = service.totalCharge || totalCharge;
  } catch (err) {
    console.error(err);
  }
  return service;
}

function setItemMessages(cartMessages = [], item) {
  let messages = null;
  const fulfillmentOptions = item?.product?.fulfillment?.fulfillmentOptions || [];
  const services = [...(fulfillmentOptions?.[0]?.services || []), ...(fulfillmentOptions?.[1]?.services || [])];
  const selected = services.find((service) => service.selected);
  // if no locations then item is out of stock
  const productType = item?.product?.identifiers?.productType;
  let hasAddedInventoryMessage = false;
  try {
    cartMessages.forEach((message) => {
      // ignore message used to trigger rerender (inserted in mutation-helper)
      if (message?.correlationType === 'triggerRender') {
        return;
      }
      if (message?.correlationType === 'cartLineItem' && message.correlationId === item.id) {
        messages = messages || [];
        // inventory error
        if (item.product.fulfillment.outOfStock && message.errorCode) {
          // create inventory error message once
          if (!hasAddedInventoryMessage) {
            messages.push(getOutOfStockMessage(message.correlationId, productType));
            hasAddedInventoryMessage = true;
          }
        } else {
          try {
          // map some error arbitrary codes
            const location = selected?.locations?.[0] || { inventory: { isOutOfStock: true } };
            messages.push(transformErrorMsg(message, { location }, selected));
          } catch (err) {
            console.error(err);
          }
        }
      }
      if (message?.messageCategoryType) {
        message.messageCategoryType = message.messageCategoryType || null;
      }
    });

  } catch (err) {
    console.error(err);
  }
  return messages;
}

function getFulfillmentLocation(item) {
  let ffmLocation = '';
  if (!item || !item.selectedFulfillment || !item.product.fulfillment.fulfillmentOptions) {
    return ffmLocation;
  }
  const ffm = item.selectedFulfillment;
  switch (ffm) {
  case 'BOPIS':
    ffmLocation = getLocation(ffm, item.product.fulfillment).storeId;
    break;
  case 'ShipToStore':
    ffmLocation = getLocation(ffm, item.product.fulfillment).storeId;
    break;
  case 'DeliverFromStore':
    ffmLocation = getLocation(ffm, item.product.fulfillment).zip;
    break;
  case 'ShipToHome':
    // explicitely maintain undef.
    ffmLocation = getLocation(ffm, item.product.fulfillment).zip;
    break;
  case 'DirectDelivery':
    ffmLocation = getLocation(ffm, item.product.fulfillment).zip;
    break;
  default:
  }
  return ffmLocation;
}

function getLocation(ffm, fulfillment) {
  let zip = '';
  let storeId = '';
  fulfillment.fulfillmentOptions.forEach((ffmOpt) => {
    ffmOpt.services.forEach((svc) => {
      if (currentFfmMap[svc.type] === ffm) {
        svc.locations.forEach((loc) => {
          if (loc.isAnchor || svc.locations.length === 1) {
            zip = loc.zipCode;
            storeId = loc.locationId;
          }
        });
      }
    });
  });
  return { storeId, zip };
}