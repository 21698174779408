import React from 'react';
import { bool, number, string } from 'prop-types';
import { Skeleton, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { CarouselHeader } from '../core/CarouselHeader';

const VerticalRecsPodPlaceholder = (props) => {
  const {
    placeholderImageHeight, placeholderLines, placeholderCount, isVertical, hideTitle
  } = props;

  const wrapperPlaceholderGridClass = classNames('sui-grid ', { 'sui-grid-cols-1': isVertical,
    [`sui-grid-cols-${placeholderCount}`]: !isVertical });

  const RenderPlaceholders = () => {
    let placeholder = [];
    for (let i = 0; i < placeholderCount; i += 1) {
      placeholder.push(
        <Skeleton
          orientation="vertical"
          key={i}
        >
          <div className="sui-grid sui-gap-2 sui-grid-cols-3">
            <div className="sui-col-span-1">
              <SkeletonBlock aspect="square" height={placeholderImageHeight} />
            </div>
            <div className="sui-col-span-2">
              {placeholderLines > 0
                && <SkeletonLine variant="multi" fullWidth numberOfLines={placeholderLines} />}
            </div>
          </div>

        </Skeleton>);
    }
    return placeholder;
  };

  return (
    <>
      {!hideTitle && <CarouselHeader title="Loading Recommendations" />}
      <>
        <div className={wrapperPlaceholderGridClass} key="placeholder-row">
          <RenderPlaceholders />
        </div>
      </>
    </>
  );
};

VerticalRecsPodPlaceholder.propTypes = {
  placeholderCount: number,
  placeholderLines: number,
  isVertical: bool,
  placeholderImageHeight: number,
  hideTitle: bool,
};

VerticalRecsPodPlaceholder.defaultProps = {
  placeholderCount: 3,
  placeholderLines: 4,
  isVertical: false,
  placeholderImageHeight: 28,
  hideTitle: false,

};

export { VerticalRecsPodPlaceholder };