import {
  useDataModel,
  params,
  string,
  id,
  number,
  customType,
  bool,
} from '@thd-nucleus/data-sources';

export function OnlineEditPOJobName({ children }) {
  const [mutatePOJobName] = useDataModel(
    'editPOJobName',
    {
      variables: {},
      context: { withAuth: true },
    },
    true
  );

  return children({ mutatePOJobName });
}

OnlineEditPOJobName.dataModel = {
  editPOJobName: params({
    userId: id().isRequired(),
    request: customType('EditPOJobNameRequest!').shape({
      customerAccountId: string(),
      salesDate: string(),
      newPOJobName: string(),
      orderNumber: string(),
      registerNumber: number(),
      storeNumber: string(),
      transactionId: number(),
    }),
  })
    .mutation()
    .shape({
      editPOJobNameStatus: bool(),
    }),
};
