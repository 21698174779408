import React, { useState, useContext } from 'react';
import { string, bool, func } from 'prop-types';
import {
  Button,
  Drawer, DrawerBody, DrawerHeader,
  Link
} from '@one-thd/sui-atomic-components';
import { MOBILE } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classNames from 'classnames';
import { QueryContext } from '@thd-nucleus/data-sources';
import THDStorageUtils, { SESSION_STORAGE } from '@thd-olt-global/thd-storage-utils';

const CtaSection = ({
  buttonText, hideContactInfo, hideLabel, isFooter,
  buttonLink, contactNumber, onClick, openContentInDrawer,
  label, labelLink, target, headerDescription, dataComponent
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLabelDrawerOpen, setIsLabelDrawerOpen] = useState(false);
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const { dataSource } = useContext(QueryContext);
  const isMobile = channel === MOBILE;
  const isPLP = dataSource === 'searchNav';

  const setGCCRedirect = () => {
    const path = window?.location?.pathname;

    THDStorageUtils.set(SESSION_STORAGE, {
      key: 'GCC_PLP_Redirect',
      value: path
    });
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onLabelDrawerClose = () => {
    setIsLabelDrawerOpen(false);
  };

  const handleOpen = (event) => {
    onClick(event);

    if (openContentInDrawer) {
      setIsDrawerOpen(true);
    } else {
      if (dataComponent === 'GccCarpetSamCta') { setGCCRedirect(); }
      window.open(buttonLink, isConsumerApp ? '_self' : target);
    }
  };

  const handleLabelOpen = () => {
    setIsLabelDrawerOpen(true);
  };

  const wrapperClasses = classNames(
    'sui-flex',
    {
      'sui-flex-row-reverse': isFooter,
      'sui-flex-col': !isFooter
    }
  );

  const primaryCTAWrapperClasses = classNames({
    'sui-p-2': isFooter,
    'sui-flex-grow': isFooter,
    'sui-w-full': isFooter
  });

  const secondaryCTAWrapperClasses = classNames(
    'sui-text-sm sui-text-inactive sui-bg-button-secondary sui-w-full sui-text-center',
    {
      'sui-p-2': isFooter,
      'sui-flex-grow': isFooter,
      'sui-mt-2': !isFooter
    }
  );

  return (
    <div data-component={dataComponent} className={wrapperClasses}>
      <div className={primaryCTAWrapperClasses}>
        <Button
          fullWidth
          variant={isPLP ? 'secondary' : 'primary'}
          onClick={handleOpen}
        >
          {buttonText}
        </Button>
      </div>

      {
        !hideContactInfo && contactNumber && (
          <div className="sui-flex sui-justify-center sui-mt-2">
            <p className="sui-text-xs sui-text-subtle"> Or call&nbsp;
            </p>
            <Link variant="body-xs" href={`tel:${contactNumber}`}>
              {contactNumber}
            </Link>
          </div>
        )
      }
      {
        (!hideLabel && label && labelLink) && (
          <div
            className={secondaryCTAWrapperClasses}
          >
            {isFooter
              ? (
                <Button onClick={() => handleLabelOpen()} variant="text" fullWidth>
                  {label}
                </Button>
              )
              : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link component="button" onClick={() => handleLabelOpen()}>
                  {label}
                </Link>
              )}
          </div>
        )
      }
      {isDrawerOpen && (
        <Drawer
          open={isDrawerOpen}
          onClose={onDrawerClose}
          DrawerRootProps={{ 'data-component': `${dataComponent}Drawer` }}
        >
          <DrawerHeader title={headerDescription} onClose={onDrawerClose} />
          <DrawerBody name="product-quote">
            <iframe
              data-testid="home-services-iframe-drawer"
              title="title"
              src={buttonLink}
              width="100%"
              // mobile height is necessary for relative drawer, since the iframe is very small before it loads
              height={isMobile ? '800px' : '99%'}
            />
          </DrawerBody>
        </Drawer>
      )}
      {isLabelDrawerOpen && (
        <Drawer
          open={isLabelDrawerOpen}
          onClose={onLabelDrawerClose}
        >
          <DrawerHeader title={headerDescription} onClose={onLabelDrawerClose} />
          <DrawerBody name="schedule-a-measure-iframe-drawer">
            <iframe
              title="title"
              src={labelLink}
              width="100%"
              height={isMobile ? '800px' : '99%'}
            />
          </DrawerBody>
        </Drawer>
      )}
    </div>
  );
};

CtaSection.displayName = 'HomeServicesCtaSection';

CtaSection.propTypes = {
  hideContactInfo: bool,
  hideLabel: bool,
  buttonLink: string.isRequired,
  buttonText: string.isRequired,
  contactNumber: string,
  isFooter: bool,
  onClick: func,
  openContentInDrawer: bool,
  label: string,
  labelLink: string,
  target: string,
  headerDescription: string,
  dataComponent: string
};

CtaSection.defaultProps = {
  hideContactInfo: false,
  hideLabel: false,
  isFooter: false,
  contactNumber: '',
  onClick: () => { },
  openContentInDrawer: true,
  label: '',
  labelLink: '',
  target: '_blank',
  headerDescription: ' ',
  dataComponent: ''
};

export { CtaSection };