export const useNewRelicLogging = () => {
  const sendBogoCardDataToNewRelic = (props) => {
    const {
      appName, actionName, customerType,
      segment, experience, subExperience, anchorItemId
    } = props;
    const graphqlVariables = {
      mcvisId: typeof window !== 'undefined'
        ? window?.THDCustomer?.default?.mcvisID : null,
      anchorItemSku: anchorItemId,
    };
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, {
      appName,
      customerType,
      experience,
      subExperience,
      segment,
      ...graphqlVariables,
      anchorItemId
      // promotionId, // TODO: promo-expansion
    });
  };
  const sendBogoCardErrorsToNewRelic = (props) => {
    const {
      actionName, errorMessage, appName, customerType,
      segment, experience,
    } = props;
    const graphqlVariables = {
      mcvisId: typeof window !== 'undefined'
        ? window?.THDCustomer?.default?.mcvisID : null,
      anchorItemSku: null
    };
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, {
      error: errorMessage,
      appName,
      customerType,
      experience,
      segment,
      ...graphqlVariables,
      // promotionId, // TODO: promo-expansion
    });
  };

  return { sendBogoCardDataToNewRelic, sendBogoCardErrorsToNewRelic };
};
