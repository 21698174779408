import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { TransitionFade } from '../private/components/fade/TransitionFade';
import { ClickAwayListener } from '../private/components/clickaway/ClickAwayListener';
import { Paper } from '../private/components/paper/Paper';
import { Close } from '../private/icons/Close';
import { IconButton } from '../button/IconButton';
import { Typography } from '../typography/Typography';
import { Popper } from '../private/components/popper/Popper';
import { ModalFocus } from '../modal/ModalFocus';
import useForkRef from '../private/hooks/useForkRef';
import HTMLElementType from '../private/utils/HTMLElementType';

const offsetSizes = {
  bottom: {
    tight: 'sui-translate-y-0',
    snug: 'sui-translate-y-1',
    normal: 'sui-translate-y-2',
    relaxed: 'sui-translate-y-3',
    loose: 'sui-translate-y-4'
  },
  top: {
    tight: 'sui--translate-y-0',
    snug: 'sui--translate-y-1',
    normal: 'sui--translate-y-2',
    relaxed: 'sui--translate-y-3',
    loose: 'sui--translate-y-4'
  },
  right: {
    tight: 'sui-translate-x-0',
    snug: 'sui-translate-x-1',
    normal: 'sui-translate-x-2',
    relaxed: 'sui-translate-x-3',
    loose: 'sui-translate-x-4'
  },
  left: {
    tight: 'sui--translate-x-0',
    snug: 'sui--translate-x-1',
    normal: 'sui--translate-x-2',
    relaxed: 'sui--translate-x-3',
    loose: 'sui--translate-x-4'
  }
};

/**
 * `Popover` is a floating container with a small to medium amount of clarifying information.
 *
 * Related components: [Tooltip](#tooltip)
 *
 * Usage:
 *
 * ```jsx
 * import { Popover } from '@one-thd/sui-atomic-components';
 * ```
 */
const Popover = React.forwardRef((props, ref) => {

  const {
    anchorEl,
    children,
    disableEnforceFocus = false,
    disablePortal = false,
    offset = 'normal',
    open,
    onClose,
    PaperProps,
    placement = 'bottom',
    title,
    ...other
  } = props;

  const handleRef = useRef(null);

  const matches = useBreakpoint('sm');
  const isSmall = matches.lesser;
  const [arrowRef, setArrowRef] = useState('');
  const [arrow, setArrow] = useState(!isSmall);
  const sanitizedPlacement = placement ? placement.split('-')[0] : 'bottom';
  const offsetClasses = matches.lesser ? undefined : offsetSizes[sanitizedPlacement]?.[offset];
  const popoverRef = useForkRef(handleRef, ref);

  useEffect(() => {
    setArrow(!matches.lesser);
  }, [matches]);

  const classes = classNames('sui-bg-primary sui-text-primary sui-rounded-base sui-outline-none sui-flex sui-text-left sui-flex-col sui-gap-2 sui-p-3 sui-z-50', {
    'sui-max-w-[400px] sui-min-w-[228px]': !isSmall,
    'sui-max-w-full sui-w-full sui-max-h-1/2': isSmall,
  }, offsetClasses);

  const arrowClassObj = {
    before: 'before:sui-rotate-45 before:sui-w-2 before:sui-h-2 before:sui-visible before:sui-block before:sui-bg-primary before:sui-shadow-sm',
    top: 'group-data-[popper-placement*=top]:sui-bottom-[-4px] group-data-[popper-placement*=top]:before:[clip-path:polygon(98%_0,_0_98%,_100%_100%)]',
    bottom: 'group-data-[popper-placement*=bottom]:sui-top-[-4px] group-data-[popper-placement*=bottom]:before:[clip-path:polygon(0_0,_2%_100%,_100%_2%)]',
    left: 'group-data-[popper-placement*=left]:sui-right-[-4px] group-data-[popper-placement*=left]:before:[clip-path:polygon(2%_0,_100%_0,_98%_100%)]',
    right: 'group-data-[popper-placement*=right]:sui-left-[-4px] group-data-[popper-placement*=right]:before:[clip-path:polygon(2%_0,_0_100%,_100%_98%)]'
  };

  const arrowClasses = classNames('sui-w-2 sui-h-2 sui-bg-primary sui-shadow-sm sui-invisible sui-absolute',
    [`${arrowClassObj.before}`],
    [`${arrowClassObj.top}`],
    [`${arrowClassObj.bottom}`],
    [`${arrowClassObj.left}`],
    [`${arrowClassObj.right}`]
  );

  const smallViewportPopper = React.useMemo(
    () => ({
      name: 'smallViewportPopper',
      enabled: isSmall,
      phase: 'main',
      fn: ({ state }) => {
        if (isSmall) {
          state.styles.popper = {
            position: 'fixed',
            width: '100%',
            inset: '',
            bottom: 0,
            left: 0,
            right: 0,
          };
        }
      },
    }), [isSmall]);

  const handleOnClose = (event) => {
    if (onClose) {
      onClose(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') onClose(event);
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      placement={placement}
      disablePortal={disablePortal}
      ref={popoverRef}
      onKeyDown={handleKeyDown}
      modifiers={[
        {
          name: 'popperOffsets',
          enabled: !isSmall
        },
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'arrow',
          enabled: arrow && !isSmall,
          options: {
            element: arrowRef
          },
        },
        smallViewportPopper
      ]}
      {...other}
    >
      {({ TransitionProps }) => (
        <TransitionFade {...TransitionProps}>
          <ModalFocus
            open={!disableEnforceFocus && open}
          >
            <Paper className={classes} shadow="sm" {...PaperProps}>
              <ClickAwayListener onClickAway={handleOnClose}>
                <div className="sui-flex-auto sui-text-left">
                  {title ? (
                    <div className="sui-flex sui-relative sui-gap-4">
                      <span className="sui-grow">
                        <Typography variant="body-base" weight="bold">{title}</Typography>
                      </span>
                      <span>
                        <IconButton
                          aria-label="close"
                          onClick={handleOnClose}
                          icon={Close}
                        />
                      </span>
                    </div>
                  ) : null}
                  {children}
                </div>
              </ClickAwayListener>
              {!isSmall ? (
                <div
                  id="arrow"
                  ref={setArrowRef}
                  data-popper-arrow
                  className={arrowClasses}
                />
              ) : null}
            </Paper>
          </ModalFocus>
        </TransitionFade>
      )}
    </Popper>
  );
});

Popover.propTypes = {
  /**
   * An HTML element used to set the position of the popover.
   */
  anchorEl: PropTypes.oneOfType([HTMLElementType, PropTypes.func]),
  /**
   * The Popover content
   */
  children: PropTypes.node,
  /**
   * If set to `true`, focus will not be prevented from leaving the Popover when it is open.
   * For most use cases, this should remain false as setting it otherwise will make the component less accesible for
   * assistive technologies.
   * @default false
   */
  disableEnforceFocus: PropTypes.bool,
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: PropTypes.bool,
  /**
   * Specify how far the popover should be from the bounding box of the trigger object
   */
  offset: PropTypes.oneOf([
    'tight',
    'snug',
    'normal',
    'relaxed',
    'loose',
  ]),
  /**
   * If `true`, the Popover is visible
   */
  open: PropTypes.bool.isRequired,
  /**
   * An event handler to be executed once the Popover is closed.
   */
  onClose: PropTypes.func,
  /**
   * @ignore
   */
  PaperProps: PropTypes.object,
  /**
   * The prefered placement of the Popover component relative to the trigger object.
   * Note that the final placement could be different depending on available space and trigger location.
   */
  placement: PropTypes.oneOf([
    'bottom',
    'bottom-start',
    'bottom-end',
    'top',
    'top-start',
    'top-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end'
  ]),
  /**
   * The Popover title
   */
  title: PropTypes.string,
};

Popover.defaultProps = {
};

export { Popover };
