import React from 'react';
import { Skeleton, SkeletonBlock, SkeletonLine } from '@one-thd/sui-atomic-components';

export const OrderDetailsPlaceholder = () => {
  return (
    <div className="sui-mb-2">
      <Skeleton disablePadding>
        <SkeletonBlock height={11} width={60} aspect="wide" />
        <SkeletonLine />
      </Skeleton>
    </div>
  );
};
