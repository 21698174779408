import React from 'react';

const defaultContext = {
  hydrationOptions: {},
  store: {},
  windowWidthMeasure: 'minWidthLarge',
  customerType: null,
  guestUserId: null,
  mcvisID: null,
  userAuthStatus: null,
  urlTmxId: null,
  storefront: null
};

export const HomepageContext = React.createContext(defaultContext);
