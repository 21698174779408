import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useTableHead from '../private/components/table/useTableHead';
import useTable from '../private/components/table/useTable';
import { Typography } from '../typography/Typography';

/**
 * Defines a cell in a [`Table`](#table) component.
 *
 * Use `TableCell` with custom content or one of the following sub-components:
 *
 * [TableSortLabel](#tablesortlabel), [TableRowLabel](#tablerowlabel)
 *
 * If a string or number is provided as `children`, it will be wrapped in a `Typography` component.
 *
 * Usage:
 *
 * ```jsx
 * import { TableCell } from '@one-thd/sui-atomic-components';
 * ```
 */
const TableCell = React.forwardRef((props, ref) => {
  const {
    children,
    component: componentProp,
    disableShadow = false,
    scope: scopeProp,
    variant: variantProp,
    sortDirection,
    padding,
    align,
    valign,
    sticky,
    ...other
  } = props;

  const tableHead = useTableHead();
  const isHeadCell = tableHead?.variant === 'head' || componentProp === 'th';
  let RootComponent;
  if (componentProp) {
    RootComponent = componentProp;
  } else {
    RootComponent = isHeadCell ? 'th' : 'td';
  }

  let scope = scopeProp;
  // scope is not a valid attribute for <td/> elements.
  // source: https://html.spec.whatwg.org/multipage/tables.html#the-td-element
  if (RootComponent === 'td') {
    scope = undefined;
  } else if (componentProp === 'th' && !tableHead) {
    scope = 'row';
  } else if (!scope && isHeadCell) {
    scope = 'col';
  }

  const table = useTable();
  const stickyVertical = sticky === 'vertical' || (isHeadCell && table?.stickyHeader);
  const density = tableHead?.density || table?.density || 'normal';
  const unlinedCell = table?.unlined;

  const paddingClasses = padding ? classNames({
    'sui-p-0': padding === 'none',
    'sui-pl-4 sui-p-0': padding === 'control',
  }) : classNames('sui-px-4', {
    'sui-py-1': density === 'tight',
    'sui-py-2': density === 'normal' || !density,
    'sui-py-4': density === 'loose'
  });

  const cellClasses = classNames('sui-table-cell-base', {
    'sui-bg-subtle': isHeadCell,
    'sui-bg-primary': !isHeadCell,
    'sui-border-b-1': !unlinedCell,
    'sui-text-left': align === 'left' || !align,
    'sui-text-right': align === 'right',
    'sui-text-center': align === 'center',
    'sui-text-justify': align === 'justify',
    'sui-align-top': valign === 'top',
    'sui-align-middle': valign === 'middle',
    'sui-align-bottom': valign === 'bottom',
    'sui-sticky sui-top-0 sui-z-10': stickyVertical,
    'sui-shadow-md': stickyVertical && !disableShadow,
    'sui-sticky sui-left-0 sui-z-10': sticky === 'horizontal',
    'sui-shadow-[2px_0px_4px_rgba(0,0,0,0.1),6px_0px_4px_rgba(0,0,0,0.1)]': sticky === 'horizontal' && !disableShadow
  }, paddingClasses);

  let cellContent = children;
  if (typeof children === 'string' || typeof children === 'number') {
    cellContent = (
      <Typography
        variant="body-base"
        height="tight"
        weight={isHeadCell ? 'bold' : 'regular'}
      >
        {children}
      </Typography>
    );
  }

  let ariaSort = null;
  if (sortDirection) {
    ariaSort = sortDirection === 'asc' ? 'ascending' : 'descending';
  }

  return (
    <RootComponent
      ref={ref}
      className={cellClasses}
      aria-sort={ariaSort}
      scope={scope}
      {...other}
    >
      {cellContent}
    </RootComponent>
  );
});

TableCell.displayName = 'TableCell';

TableCell.propTypes = {
  /**
   * Set the text-align on the table cell content.
   */
  align: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override the native element being used.
   * @default 'td'
   */
  component: PropTypes.elementType,
  /**
   * If true removes the shadow from sticky cell.
   */
  disableShadow: PropTypes.bool,
  /**
   * Set the padding applied to the cell.
   */
  padding: PropTypes.oneOf(['control', 'none']),
  /**
   * Set scope attribute.
   */
  scope: PropTypes.string,
  /**
   * Set the sticky attribute to the cell.
   */
  sticky: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Set the vertical-align on the table cell content.
   */
  valign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  /**
   * Set aria-sort direction.
   */
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  /**
   * Specify the cell type.
   * The prop defaults to the value inherited from the parent TableHead, TableBody, or TableFooter components.
   */
  variant: PropTypes.oneOf(['body', 'footer', 'head']),
};

export { TableCell };
