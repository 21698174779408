import * as React from 'react';
import * as PropTypes from 'prop-types';

const RoundShape = (props) => {
  const { className, style, color } = props;

  const defaultStyles = {
    backgroundColor: color,
    borderRadius: '500rem',
    width: '100%',
    height: '100%'
  };

  const classes = ['round-shape', className].filter((clss) => clss).join(' ');

  return (
    <div className={classes} style={{ ...defaultStyles, ...style }} />
  );
};

RoundShape.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

RoundShape.defaultProps = {
  className: '',
  style: {}
};

export { RoundShape };
