import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleLavender = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleLavender.palette['--sui-palette-background-primary'] = '133 122 171';

export { HouseStyleLavender };