import React from 'react';
import PropTypes from 'prop-types';

const ReactTooltipContent = React.forwardRef(({
  children, className, onClose, showClose
}, ref) => {
  return (
    <div ref={ref}>
      {onClose && showClose && (
        <button
          type="button"
          className="thd-react-tooltip__close-button"
          onClick={onClose}
        >
          <img
            height="16px"
            width="16px"
            src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
            alt="Close"
          />
        </button>
      )}
      <div className={`thd-react-tooltip__content ${className}`}>{children}</div>
    </div>
  );
});

ReactTooltipContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  showClose: PropTypes.bool
};

ReactTooltipContent.defaultProps = {
  className: '',
  onClose: undefined,
  showClose: true
};

export { ReactTooltipContent };
