/* eslint-disable no-console */
export const extractCartErrors = (rawErrors) => {
  let cartErrors = [];
  try {
    (rawErrors || []).forEach((error) => {
      const cartInfoErrors = error?.extensions?.cart_info_errors;
      if (cartInfoErrors?.length) {
        cartInfoErrors.forEach((cartError) => {
          cartErrors.push(cartError);
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
  return cartErrors;
};