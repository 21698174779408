const parseAdobeCookie = (rawAdobeCookie) => {
  try {
    let adobeCookieStr = decodeURIComponent(rawAdobeCookie);
    let crumbs = adobeCookieStr ? adobeCookieStr.split('|') : [];

    if (crumbs.length > 1) {
      crumbs.shift();
    }

    const parsedCook = {};
    for (let i = 0; i < crumbs.length; i += 2) {
      parsedCook[crumbs[i]] = crumbs[i + 1];
    }

    return parsedCook;
  } catch (error) {
    return {};
  }
};

module.exports = {
  parseAdobeCookie
};
