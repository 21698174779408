import React from 'react';
import classNames from 'classnames';
import {
  bool, number, oneOf, oneOfType, shape, string, func
} from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import styles from './video-player.module.scss';

export const VideoStill = ({ height, width, videoStill, onPlayButtonClick }) => {
  const videoStillWrapperStyle = classNames(
    styles['video-player__wrapper'],
    styles['video-player__wrapper--still']
  );
  const playButtonStyles = classNames(styles['video-player__play-button']);

  return (
    <div
      className={styles['video-player__container']}
      style={{
        width,
        maxWidth: width,
      }}
      data-component="VideoStill"
    >
      <div className={videoStillWrapperStyle}>
        <Image
          alt="thumbnail"
          className={styles['video-player__still']}
          height={height}
          src={videoStill}
          width={width}
        />
        <button
          alt="Play Button"
          aria-label="play video"
          className={playButtonStyles}
          onClick={onPlayButtonClick}
          type="button"
        />
      </div>
    </div>
  );
};

VideoStill.propTypes = {
  videoStill: string.isRequired,
  height: number,
  width: number,
  onPlayButtonClick: func.isRequired
};

VideoStill.defaultProps = {
  height: null,
  width: null,
};