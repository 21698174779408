import React from 'react';
import { shape, string, bool, arrayOf } from 'prop-types';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import { VisualNavigationCard } from '@thd-olt-component-react/visual-navigation';

export function HomeDecorCard({
  id, title, link, cta, showCTA, categoryComponents
}) {
  const { visualNavigationList } = categoryComponents?.[0] || {};
  const componentClass = 'VisualNavigation';
  const componentPosition = 1;
  const cardCount = visualNavigationList?.length;

  const getMobileColumns = (count) => {
    if (count > 5) {
      return '6';
    }
    return '12';
  };
  const getDesktopColumns = (count) => {
    if (count <= 4) {
      return '3';
    }
    return '2';
  };

  const mobileColumns = getMobileColumns(cardCount);
  const tabletColumns = '3';
  const desktopColumns = getDesktopColumns(cardCount);

  return (
    <div>
      <div className="sui-flex sui-justify-between sui-py-0 sui-px-1">
        {title && (
          <Typography
            className="sui-pb-10 sui-leading-7 md:sui-leading-none"
            variant="h2"
            height="snug"
            color="primary"
            uppercase
          >
            {title}
          </Typography>
        )}
        {
          showCTA
          && (
            <Link
              className="sui-text-xs sui-leading-tight"
              href={link}
              underline="always"
            > {cta}
            </Link>
          )
        }
      </div>
      <div
        className={`
          sui-py-1 sui-px-0 
          sui-grid
          lg:sui-grid-cols-${mobileColumns}
          md:sui-grid-cols-${tabletColumns}
          sui-grid-cols-${desktopColumns}
        `}
        id={`${componentClass}-${componentPosition}-${id}`}
        data-testid="visual-navigation"
        data-component-id={id}
        data-component-name={componentClass}
        data-component-position={componentPosition}
      >
        {
          (visualNavigationList || []).map((item, idx) => {
            return (
              <VisualNavigationCard
                item={item}
                key={idx + 1}
                position={idx + 1}
              />
            );
          })
        }
      </div>
    </div>
  );
}

HomeDecorCard.propTypes = {
  id: string,
  title: string,
  link: string,
  cta: string,
  showCTA: bool,
  categoryComponents: arrayOf({
    id: string,
    title: string,
    visualNavigationList: arrayOf(shape({
      id: string,
      title: string,
      link: string,
      previewImage: shape({
        damDownloadedContent: shape({
          url: string
        })
      })
    }))
  })
};

HomeDecorCard.defaultProps = {
  id: '',
  title: '',
  link: '',
  cta: '',
  showCTA: true,
  categoryComponents: arrayOf({
    id: '',
    title: '',
    visualNavigationList: arrayOf(shape({
      id: '',
      title: '',
      link: '',
      previewImage: shape({
        damDownloadedContent: shape({
          url: ''
        })
      })
    }))
  })
};