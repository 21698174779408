import React from 'react';
import { string, number } from 'prop-types';
import { EventCountdownTimer as EventCountdownTimerComponent } from '@thd-olt-component-react/event-countdown-timer';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function EventCountdownTimer({ id }) {
  const data = {
    id,
    name: 'EventCountdownTimer',
    component: 'EventCountdownTimer',
    type: 'content',
    position: 1,
  };

  return (
    <ErrorBoundary name="event-countdown-timer">
      <QueryProvider key={id} cacheKey={id}>
        <ImpressionProvider data={data}>
          <EventCountdownTimerComponent
            componentId={id}
            componentClass="EventCountdownTimer"
          />
        </ImpressionProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

EventCountdownTimer.propTypes = {
  id: string
};

EventCountdownTimer.defaultProps = {
  id: undefined
};
