/* eslint-disable no-console */
import React, { Children } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * The main goal of `SelectionControlLabel` is to provide the `label` prop for its child components:
 * `Checkbox`, `Toggle`, `Radio`. It also can provide the values for the `value`, `disabled` or `checked`
 * props.
 *
 * See related components: [FormGroup](#fromgroup), [Checkbox](#checkbox), [Toggle](#toggle), [Radio](#radio)
 *
 * Usage:
 *
 * ```jsx
 * import { SelectionControlLabel } from '@one-thd/sui-atomic-components';
 * ```
 */
const SelectionControlLabel = React.forwardRef((props, ref) => {

  const {
    alignment = 'center',
    children,
    label,
    className,
    disabled: disabledProp,
    required = false,
    ...other
  } = props;
  const control = Children.only(children);

  let disabled = disabledProp;
  if (typeof disabled === 'undefined' && typeof control.props.disabled !== 'undefined') {
    disabled = control.props.disabled;
  }

  let controlProps = { disabled, alignment };
  let labelProps = props;
  ['checked', 'name', 'onChange', 'value', 'inputRef'].forEach((forwardProp) => {
    if (typeof control.props[forwardProp] === 'undefined' && typeof labelProps[forwardProp] !== 'undefined') {
      controlProps[forwardProp] = labelProps[forwardProp];
    }
  });

  const cls = classNames('sui-inline-flex sui-align-middle sui-ml-[-7px] sui-mr-[16px]', {
    'sui-text-primary sui-cursor-pointer sui-tap-highlight-transparent': !disabled,
    'sui-text-inactive sui-pointer-events-none': disabled,
    'sui-items-start': alignment === 'top',
    'sui-items-center': alignment === 'center'
  }, className);

  const requiredClasses = classNames('sui-font-regular sui-text-base sui-leading-tight sui-text-left', {
    'sui-text-primary': !disabled,
    'sui-text-inactive': disabled
  });

  const spanClasses = classNames('sui-font-regular sui-text-base sui-leading-tight sui-m-0', {
    'sui-mt-1': alignment === 'top',
  });

  const LabelRoot = 'label';

  return (
    <LabelRoot
      className={cls}
      ref={ref}
      {...other}
    >
      {control && React.cloneElement(control, controlProps)}
      <span className={spanClasses}>{label}</span>
      {required && (
        <span aria-hidden className={requiredClasses}>
            &thinsp;*
        </span>
      )}
    </LabelRoot>
  );
});

SelectionControlLabel.displayName = 'SelectionControlLabel';

SelectionControlLabel.propTypes = {
  /**
   * Controls the vertical alignment of the Label and its child.
   */
  alignment: PropTypes.oneOf(['top', 'center']),
  /**
   * @ignore
   */
  children: PropTypes.node.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * label after the associated control
   */
  label: PropTypes.string,
  /**
   * If `true`, control and label disabled
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the label will indicate that a selection is required.
   */
  required: PropTypes.bool
};

SelectionControlLabel.defaultProps = {
  alignment: 'center'
};

export { SelectionControlLabel };
