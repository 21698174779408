import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { NOOP } from '../../utils';
import styles from './tab.module.scss';

const cx = classNames.bind(styles);

const Tab = ({
  ariaControls,
  captureRef,
  className,
  label,
  onClick,
  onMouseEnterTab,
  onMouseLeaveTab,
  selected,
  disabled,
  value
}) => {

  const titleClasses = cx('tabs__item', {
    'tabs__item--selected': selected,
    'tabs__item--disabled': disabled
  }, className);

  return (
    <button
      aria-controls={ariaControls}
      className={titleClasses}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={onMouseEnterTab}
      onMouseLeave={onMouseLeaveTab}
      ref={captureRef}
      type="button"
      value={value}
    >
      {label}
    </button>
  );
};

Tab.displayName = 'Tab';

Tab.propTypes = {
  ariaControls: PropTypes.string,
  captureRef: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnterTab: PropTypes.func,
  onMouseLeaveTab: PropTypes.func,
  selected: PropTypes.bool,
  value: PropTypes.number
};

Tab.defaultProps = {
  ariaControls: '',
  captureRef: NOOP,
  className: null,
  disabled: false,
  label: '',
  onClick: NOOP,
  onMouseEnterTab: NOOP,
  onMouseLeaveTab: NOOP,
  selected: false,
  value: 0
};

export { Tab };