import { createTheme } from '@one-thd/sui-atomic-components';

const PrimaryHolidayGreen = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

PrimaryHolidayGreen.palette['--sui-palette-background-primary'] = '13 80 46';

export { PrimaryHolidayGreen };