import React from 'react';
import {
  shape,
  string,
  arrayOf,
  number
} from 'prop-types';
import classNames from 'classnames/bind';
import { useImpression } from '@thd-olt-component-react/impression';

import { Image } from '@thd-olt-component-react/core-ui';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import styles from './MobileCategoryTab.module.scss';

const cNames = classNames.bind(styles);

const MobileCategoryTab = ({ category, entryId, index }) => {
  const { specialBuyEndpoint } = useSpecialBuy();
  const { categoryId, name, image, itemIds } = category;

  const { ref, clickID } = useImpression({
    data: {
      id: entryId,
      name: 'SpecialBuyBanner',
      component: 'SpecialBuyBanner',
      position: index,
      type: 'product',
      category: categoryId
    },
  });

  return (
    <a
      href={`${specialBuyEndpoint}#${categoryId}`}
      className={cNames('category-tab')}
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <div className={cNames('category-tab__images')}>
        <Image
          alt={`${name} product image`}
          src={image.url}
          className={cNames('category-tab__image')}
          width={1}
          height={1}
        />
      </div>
      <span className={cNames('category-tab__text')}>{name} ({itemIds.length})</span>
    </a>
  );
};

MobileCategoryTab.propTypes = {
  category: shape({
    name: string.isRequired,
    itemIds: arrayOf(string.isRequired).isRequired,
    categoryId: string.isRequired,
    image: shape({
      url: string.isRequired
    }).isRequired
  }).isRequired,
  entryId: string,
  index: number.isRequired,
};

MobileCategoryTab.defaultProps = {
  entryId: '',
};

export { MobileCategoryTab };
