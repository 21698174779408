import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Online = createSvgIcon(
  <>
    <path d="M4.875 6a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM9.75 4.875a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM12.375 6a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm-.42 9.796L9.75 18l-1.5-8.25 7.5 2.25-2.204 2.204 2.67 2.67-1.591 1.592-2.67-2.67Z" />
    <path d="M22.875 0C23.496 0 24 .504 24 1.125v21.75c0 .621-.504 1.125-1.125 1.125H1.125A1.125 1.125 0 0 1 0 22.875V1.125A1.125 1.125 0 0 1 1.125 0h21.75ZM21.75 2.25H2.25v19.5h19.5V2.25Z" />
  </>,
  'Online'
);

export { Online };