import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import { shipping } from '../helpers/model';
import { getDeliveryZip } from '../helpers/utils';
import { isTemplateToRender } from '../helpers/template';
import ParcelLayout from './partials/ParcelLayout';
import NonParcelLayout from './partials/NonParcelLayout';
import Footer from './partials/Footer';
import Error from './partials/Error';
import './delivery-options.style.scss';
import DeliveryContentLoader from './DeliveryContentLoader';

const DeliveryContent = (props) => {
  let {
    zipCode,
    storeId,
    itemId,
    quantity
  } = props;

  const storeDetails = useStore();
  const { storeZip } = storeDetails;
  if (!storeId) {
    storeId = storeDetails.storeId;
  }
  const productQueryProps = {
    variables: { itemId, storeId },
    ssr: false
  };
  const { data } = useDataModel('clientOnlyProduct', productQueryProps);
  const { value } = data?.product?.pricing || {};
  const deliveryZip = getDeliveryZip();
  zipCode = zipCode || deliveryZip || storeZip;

  const shippingQueryProps = {
    variables: {
      itemId,
      storeId,
      zipCode,
      quantity,
      price: parseInt((value * quantity).toFixed(2), 10)
    }
  };
  const [getShipping, shippingResponse] = useDataModel('shipping', shippingQueryProps, true);

  useEffect(() => {
    // adding data check to make sure price is valid
    if (storeId !== '8119' && data) {
      getShipping();
    }
  }, [data, quantity, storeId, zipCode]);

  if ((shippingResponse.error && !shippingResponse.data) || !shippingResponse.data || shippingResponse.loading) {

    return (
      <DeliveryContentLoader
        zipCode={zipCode}
      />
    );
  }

  const shippingModel = shipping(shippingResponse.data.shipping);

  const templateToRender = isTemplateToRender({
    shippingModel
  });

  return (
    <>
      <div className="delivery-options-content" data-component="DeliveryContent">
        {templateToRender
        && (
          <div className="delivery-options-inner">
            <div className="delivery-options-header">
              <div className="header-title">
                Delivery options for {zipCode}
              </div>
            </div>
            <div className="delivery-options-templates">
              {shippingModel?.group?.toLowerCase() === 'parcel'
              && (
                <ParcelLayout
                  shipModes={shippingModel.shipModes}
                  arrivalDate={shippingModel.arrivalDate}
                  dynamicETA={shippingModel.dynamicETA}
                />
              )}
              {shippingModel?.group?.toLowerCase() !== 'parcel'
              && (
                <NonParcelLayout
                  shipModes={shippingModel.shipModes}
                  arrivalDate={shippingModel.arrivalDate}
                  dynamicETA={shippingModel.dynamicETA}
                />
              )}
            </div>
            <div>
              <Footer />
            </div>
          </div>
        )}
        {!templateToRender
        && (
          <Error />
        )}
      </div>
    </>
  );
};

DeliveryContent.propTypes = {
  zipCode: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  // shippingModel: PropTypes.shape({
  //   group: PropTypes.string.isRequired,
  //   shipModes: PropTypes.arrayOf(
  //     PropTypes.shape({})
  //   ).isRequired,
  //   arrivalDate: PropTypes.string.isRequired,
  //   dynamicETA: PropTypes.PropTypes.shape({}).isRequired
  // }).isRequired
};

DeliveryContent.defaultProps = {};

export default DeliveryContent;
