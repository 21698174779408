import { createTheme } from '@one-thd/sui-atomic-components';

const ThdOrangeTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand-500'
    }
  }
});

export { ThdOrangeTheme };