/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { element, object, oneOfType } from 'prop-types';
import { DynamicComponent } from './DynamicComponent';

export const withDynamicComponent = (WrappedComponent, opts) => {

  const Component = ({
    // eslint-disable-next-line react/prop-types
    children, dynamic, ...props
  }) => {

    if (!dynamic) {
      return <WrappedComponent {...props}>{children}</WrappedComponent>;
    }
    let dynamicProps = {};
    if (opts) {
      dynamicProps = { ...opts };
    }
    if (typeof dynamic === 'object') {
      dynamicProps = { ...dynamicProps, ...dynamic };
    } else if (typeof dynamic === 'boolean') {
      dynamicProps = {
        all: dynamic
      };
    }
    return (
      <DynamicComponent {...dynamicProps}>
        <WrappedComponent {...props}>
          {children}
        </WrappedComponent>
      </DynamicComponent>
    );
  };

  Component.dataModel = WrappedComponent?.dataModel || {};
  Component.displayName = WrappedComponent.displayName || 'DynamicComponent';
  Component.wraps = {
    ...(WrappedComponent.wraps || {}),
    dynamicComponent: true
  };
  return Component;
};

withDynamicComponent.propTypes = {
  WrappedComponent: element.isRequired,
  opts: oneOfType([object])
};
