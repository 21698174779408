import { formatDate } from '@thd-olt-functional/utils';

const helpers = {
  initializeEndDate: (endDate, hours, minutes) => {
    if (endDate) {
      return new Date(endDate);
    }
    if (hours || minutes) {
      const tempDate = new Date();
      if (hours) {
        tempDate.setHours(tempDate.getHours() + +hours);
      }
      if (minutes) {
        tempDate.setMinutes(tempDate.getMinutes() + +minutes);
      }
      tempDate.setSeconds(59);
      return tempDate;
    }
    return null;
  },
  setAlaskaOffset: (date) => {
    let offSet;
    let alaska = date.toLocaleTimeString('en-US',
      { timeZone: 'America/Anchorage', timeZoneName: 'short' }).split(' ')[2];
    if (alaska === 'AKST') {
      offSet = '-09:00';
    } else { // AKDT
      offSet = '-08:00';
    }
    return offSet;
  },
  calculatePromotionTimeLeft: (promotionEndDate) => {
    const sanitizedPromotionEndDate = promotionEndDate.toISOString().split('T')[0];
    const alaskaEndOfDay = '22:59:59.999';
    const date = new Date();
    const offSet = helpers.setAlaskaOffset(date);
    // Example: 2080-01-27T22:59:59.999-09:00
    const fullEndDateString = `${sanitizedPromotionEndDate}T${alaskaEndOfDay}${offSet}`;
    const timeToExpiration = +new Date(fullEndDateString) - Date.now();

    if (!timeToExpiration || timeToExpiration <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalMilliseconds: 0
      };
    }

    return {
      days: helpers.convertToDays(timeToExpiration),
      hours: helpers.convertToHours(timeToExpiration),
      minutes: helpers.convertToMinutes(timeToExpiration),
      seconds: helpers.convertToSeconds(timeToExpiration),
      totalMilliseconds: timeToExpiration
    };
  },
  convertToDays: (timeToExpiration) => {
    return Math.floor(timeToExpiration / (1000 * 60 * 60 * 24));
  },
  convertToHours: (timeToExpiration) => {
    return Math.floor((timeToExpiration / (1000 * 60 * 60)) % 24);
  },
  convertToMinutes: (timeToExpiration) => {
    return Math.floor((timeToExpiration / 1000 / 60) % 60);
  },
  convertToSeconds: (timeToExpiration) => {
    return Math.floor((timeToExpiration / 1000) % 60);
  },
  getTimeStringDate: (timerEndDate) => {
    return formatDate({ date: timerEndDate, shortMonth: true, dayMonthDateFormat: true });
  },
  getTimeStringDaysHoursMinutes: (timeLeft, shouldShowSecondsRemaining) => {
    if (!timeLeft) return '';
    const daySuffix = timeLeft.days === 1 ? ' day ' : ' days ';
    const hourSuffix = timeLeft.hours === 1 ? ' hr ' : ' hrs ';
    const minuteSuffix = timeLeft.minutes === 1 ? ' min ' : ' mins ';
    const secondSuffix = timeLeft.seconds === 1 ? ' sec' : ' secs';
    const days = timeLeft.days === 0 ? '' : timeLeft.days + daySuffix;
    const hrs = timeLeft.hours > 0 ? timeLeft.hours.toString().padStart(2, '0') + hourSuffix : '';
    const mins = timeLeft.minutes > 0 ? timeLeft.minutes.toString().padStart(2, '0') + minuteSuffix : '';
    let secs = '';
    if (timeLeft.seconds && shouldShowSecondsRemaining) {
      secs = timeLeft.seconds.toString().padStart(2, '0') + secondSuffix;
    }
    return days + hrs + mins + secs;
  },
  getTimeStringDigitalClock: (timeLeft) => {
    let newTimeString = '';
    const { days, hours, minutes, seconds } = timeLeft;
    newTimeString += String(days * 24 + hours).padStart(2, '0');
    newTimeString += ':' + String(minutes).padStart(2, '0');
    newTimeString += ':' + String(seconds).padStart(2, '0');
    return newTimeString;
  },
  getTimeStringDaysLeft: (timeLeft) => {
    return `${timeLeft.days}${(timeLeft.days === 1 ? ' day' : ' days')}`;
  },

  getTextBelow: (timeLeft) => {
    const timerDays = timeLeft.days === 0 ? '' : timeLeft.days.toString().padStart(2, '0');
    const hours = timeLeft.hours
      .toString()
      .padStart(timeLeft.days > 0 ? 2 : 1, '0');
    const minutes = timeLeft.minutes.toString().padStart(2, '0');
    const seconds = timeLeft.seconds.toString().padStart(2, '0');

    const daysText = timerDays === 1 ? 'Day' : 'Days';
    const hoursText = timeLeft.hours === 1 ? 'Hr' : 'Hrs';
    const minuteText = timeLeft.minutes === 1 ? 'Min' : 'Mins';
    const secondsText = 'Secs';
    return {
      timerDays,
      hours,
      minutes,
      seconds,
      daysText,
      hoursText,
      minuteText,
      secondsText,
    };
  },
  getCondensedTimer: (timeLeft = {}) => {
    if (timeLeft.days >= 1) {
      const daySuffix = timeLeft.days === 1 ? ' day' : ' days';
      return ` ${timeLeft.days}${daySuffix}`;
    }

    if (timeLeft.hours >= 1 && timeLeft.minutes >= 1) {
      const hourSuffix = timeLeft.hours === 1 ? ' hr' : ' hrs';
      const minuteSuffix = timeLeft.minutes === 1 ? ' min' : ' mins';
      return ` ${timeLeft.hours}${hourSuffix} ${timeLeft.minutes}${minuteSuffix}`;
    }

    if (timeLeft.hours >= 1) {
      const hourSuffix = timeLeft.hours === 1 ? ' hr' : ' hrs';
      return ` ${timeLeft.hours}${hourSuffix}`;
    }

    if (timeLeft.minutes >= 1) {
      const minuteSuffix = timeLeft.minutes === 1 ? ' min' : ' mins';
      return ` ${timeLeft.minutes}${minuteSuffix}`;
    }
    return null;
  }
};

export default helpers;
