import React from 'react';
import badgeDefinitions from './badge-definitions';
import styles from './BadgeDefinitions.module.scss';

const BadgeDefinitions = () => {
  return (
    <div
      className={`${styles['overlay-page-content']} ${styles['badge-definitions']}`}
      data-component="BadgeDefinitions"
    >
      <p>
        The Home Depot offers the opportunity to earn many different badges. Below is a list of our
        badges and what they mean.
      </p>
      <hr />
      <div className={styles['badges-container']}>
        {badgeDefinitions.map((badge, i) => (
          <div className={styles['badge-container']} key={i}>
            <div className={`${styles['badge-icon']} ${styles[badge.iconClass]}`} />
            <div className={styles['badge-text']}>
              <span>{badge.name}</span>
              <p>{badge.definition}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { BadgeDefinitions };
