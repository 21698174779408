/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { callService } from './legacy-support/ServiceAdapter';
import { transformLegacyCart } from '../transformers/cartTransformer';

const CACHE = {};
const URL = '/mcc-cart/v2/Cart';

const getCart = async () => {
  if (!CACHE.cartInfo) {
    const mccResponse = await callService(URL);
    const cartInfo = transformLegacyCart(mccResponse.CartModel);
    CACHE.cartInfo = cartInfo;
  }
  // data.cartInfo.itemCount
  return { cartInfo: CACHE.cartInfo };
};

export const useMccGetCart = (updateResponse) => {
  const [status, setStatus] = useState({
    data: CACHE.cartInfo ? { cartInfo: CACHE.cartInfo } : null,
    loading: !CACHE.cartInfo,
    error: false
  });

  useEffect(() => {
    if (!CACHE.cartInfo) {
      console.warn('\n\n+++++++++++ useMccGetCart INITIALIZED');
      getCart().then((cartInfo) => {
        setStatus({
          data: cartInfo,
          loading: false,
          error: false
        });
      }).catch((error) => {
        setStatus({
          data: null,
          loading: false,
          error
        });
      });
    } else {
      console.warn('\n\n+++++++++++ useMccGetCart INITIALIZED using CACHE');
    }

  }, []);

  useEffect(() => {
    if (updateResponse
    // && JSON.stringify(status.data) !== JSON.stringify(updateResponse)
    ) {
      console.warn('\n\n+++++++++++ useMccGetCart setting data to updateResponse');
      setStatus({
        data: updateResponse,
        loading: false,
        error: false
      });
    }
  }, [updateResponse]);

  return status;
};

export const updateCart = async ({ variables, optimisticResponse }, setResponse) => {
  if (optimisticResponse) {
    setResponse({ cartInfo: optimisticResponse.updateCart });
  }
  const {
    itemDetails
  } = variables;
  const mccResponse = await callService(
    `${URL}/update`,
    'POST',
    wrapCartRequest(CACHE.cartInfo.localization.primaryStoreId + '', itemDetails)
  );
  const cartInfo = transformLegacyCart(mccResponse.CartModel);
  if (cartInfo && cartInfo.itemCount) {
    CACHE.cartInfo = cartInfo;
  } else {
    console.warn('\n\n+++++++++++ useMccGetCart update returned 0 items????');
  }
  const data = JSON.parse(JSON.stringify(CACHE));
  setResponse(data);

  return { cartInfo: CACHE.cartInfo };
};

function wrapCartRequest(localStoreId, itemDetails) {
  const {
    lineItemId,
    fulfillmentMethod,
    fulfillmentLocation,
    quantity,
    itemId
  } = itemDetails;
  // const item = getItemById(lineItemId);
  const req = {
    CartRequest: {
      itemDetails: [{
        itemId, // : item.product.itemId,
        lineItemId,
        quantity: quantity.toString(),
        fulfillmentMethod,
        fulfillmentLocation
      }],
      essentialAccessories: false,
      localStoreId
    }
  };

  return req;
}

export const shouldUseMcc = () => {
  let should = false;
  try {
    if (typeof window !== 'undefined'
    && (
      window.location.href.includes('useMcc=true')
      || window.__useMccData
    )) {
      should = true;
    }
    // should = true;
  } catch (err) {
    console.error(err);
  }
  return should;
};