import React, {
  createContext,
  useMemo,
  useState
} from 'react';
import { arrayOf, bool, node, oneOf } from 'prop-types';

import { DRAWER_TYPES } from '../utils/constants';
import { noop } from '../utils/promo-utils';

const defaultModelContext = Object.freeze({
  drawerView: null,
  open: false,
  quickviewItemId: null,
  setQuickviewItemId: noop
});

export const PromoDrawerModelContext = createContext(defaultModelContext);

export const PromoDrawerModelProvider = ({ children, drawerView, drawerViewStack, open }) => {
  const [quickviewItemId, setQuickviewItemId] = useState(null);
  const [quickviewItemDisplayPosition, setQuickviewItemDisplayPosition] = useState(null);

  const contextValue = useMemo(() => {
    return Object.freeze({
      drawerView,
      drawerViewStack,
      open,
      quickviewItemDisplayPosition,
      quickviewItemId,
      setQuickviewItemDisplayPosition,
      setQuickviewItemId
    });
  }, [
    drawerView,
    drawerViewStack,
    open,
    quickviewItemDisplayPosition,
    quickviewItemId,
    setQuickviewItemDisplayPosition,
    setQuickviewItemId
  ]);

  return (
    <PromoDrawerModelContext.Provider value={contextValue}>
      {children}
    </PromoDrawerModelContext.Provider>
  );
};

PromoDrawerModelProvider.displayName = 'PromoDrawerModelProvider';

PromoDrawerModelProvider.propTypes = {
  children: node.isRequired,
  drawerView: oneOf(Object.values(DRAWER_TYPES)).isRequired,
  drawerViewStack: arrayOf(oneOf(Object.values(DRAWER_TYPES))).isRequired,
  open: bool.isRequired
};
