import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Subscription = createSvgIcon(
  <>
    <path d="M21.75 0H2.25v7.5H4.5V2.25h15v3h-2.25l3.375 4.5L24 5.25h-2.25V0Z" />
    <path d="M13.5 6H8.25v2.25h5.25V6Zm3 7.5H8.25v-2.25h8.25v2.25Z" />
    <path d="m18.066 20.687 3.684 3.262V12H19.5v6.95l-1.566-1.387L15 20.643l-3-3.15-3 3.15-2.934-3.08L4.5 18.951V14.25h2.25l-3.375-4.5L0 14.25h2.25v9.7l3.684-3.263L9 23.907l3-3.15 3 3.15 3.066-3.22Z" />
  </>,
  'Subscription'
);

export { Subscription };