import { TextRow } from './TextRow';
import { RoundShape } from './RoundShape';
import { RectShape } from './RectShape';
import { TextBlock } from './TextBlock';
import { MediaBlock } from './MediaBlock';
import { MediaGalleryPlaceholder } from './MediaGalleryPlaceholder';

export {
  TextRow,
  RoundShape,
  RectShape,
  TextBlock,
  MediaBlock
};

export const textRow = TextRow;
export const round = RoundShape;
export const rect = RectShape;
export const text = TextBlock;
export const media = MediaBlock;
export const mediaGallery = MediaGalleryPlaceholder;
