import { shape, string, number } from '@thd-nucleus/data-sources';

const taskParams = shape({
  paramId: string(),
  name: string(),
  value: string(),
  type: string(),
});

const taskRewards = shape({
  challengeRewardId: string(),
  rewardId: string(),
  rewardDetailId: string(),
  name: string(),
  description: string(),
  activeImageUrl: string(),
  inactiveImageUrl: string(),
  type: string(),
  availability: string(),
  activationType: string(),
  amount: number(),
  expiryPeriod: string(),
});

const challengeTasks = shape({
  customerTaskId: string(),
  name: string(),
  description: string(),
  sequenceNumber: number(),
  type: string(),
  subType: string(),
  status: string(),
  params: taskParams,
});

const challenges = shape({
  challengeId: string(),
  svocId: string(),
  name: string(),
  description: string(),
  instructionsOverview: string(),
  imageUrl: string(),
  termsAndConditions: string(),
  challengeStartDate: string(),
  challengeEndDate: string(),
  challengeStatus: string(),
  optedStatus: string(),
  optedTimeStamp: string(),
  activityStatus: string(),
  daysLeft: number(),
  tasks: challengeTasks,
  rewards: taskRewards,
});

export const offerChallengesInfoModel = {
  challenges,
};

export const COMMON_CHALLENGE_MODELS = {
  taskParams,
  taskRewards,
  challengeTasks,
  challenges,
};
