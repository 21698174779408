import React from 'react';
import { nanoid } from 'nanoid';

export default function useId(idOverride) {
  const [defaultId, setDefaultId] = React.useState(idOverride);
  const id = idOverride || defaultId;
  React.useEffect(() => {
    if (defaultId === null || defaultId === undefined) {
      setDefaultId(`sui-${nanoid()}`);
    }
  }, [defaultId]);
  return id;
}