/* eslint-disable react/forbid-prop-types */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@thd-olt-component-react/carousel';
import {
  useDataModel, params, string, shape,
  arrayOf, bool
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { AR } from '@one-thd/sui-icons';
import { useImpression } from '@thd-olt-component-react/impression';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { getCustomUrlWithAnalytics, isBuildAndBuyProduct } from '../../productPodUtils';
import { BUILD_AND_BUY, POD_IMAGE_SIZE } from '../../constants';

const strechyClass = 'sui-w-full sui-h-auto sui-top-0 sui-left-0';
const transitionOpacity = 'sm:sui-transition-opacity sm:sui-duration-[0.3s]';

const hoverSecondaryImageClass = `sm:sui-absolute sm:sui-opacity-100 sm:${transitionOpacity}`;
const secondayImageClass = `
  sui-hidden sm:sui-block sm:sui-absolute
  sm:sui-opacity-0 sm:hover:sui-opacity-100
  ${transitionOpacity}
`;

const overlayClass = `
  before:sui-absolute before:sui-top-0 before:sui-left-0 before:overflow-hidden
  before:sui-w-full before:sui-h-full
  before:sui-flex before:sui-items-center before:sui-justify-center
  before:sui-bg-medium/80 before:sui-text-inverse
  before:sui-text-5xl before:sm:sui-text-8xl
  before:sui-content-[attr(data-quantity)]
`;

const AugmentedReality = () => (
  <div
    data-testid="augemented-reality-wrapper"
    className={`
      sui-hidden
      supports-[-webkit-touch-callout:_none]:sui-block
      [&_svg]:sui-block
      sui-absolute
      sui-right-2
      sui-bottom-2
    `}
  >
    <AR size="regular" color="medium" backgroundColor="inactive" />
  </div>
);

export const ProductImage = ({
  itemId,
  showSecondaryImage,
  dualImageSwipe,
  inCartQuantity,
  onClick,
  previewImgUrl,
  target = '_self',
  children,
  isIframe,
  iframeAnalyticsData,
  fetchPriority,
  merchQueryParam
}) => {

  const { channel, hosts } = useContext(ExperienceContext);
  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductImage',
      name: 'ProductImage',
      position: 0
    }
  });
  const { parent } = additionalData || {};

  const [sponsoredValues, setSponsoredValues] = useState({});
  const [productState, setProductState] = useState({
    images: [],
    canonicalUrl: '',
    productType: '',
    productLabel: '',
    globalCustomConfigurator: {},
    augmentedReality: false
  });
  const [mounted, setMounted] = useState(false);

  const { data, loading } = useDataModel('product', {

    variables: {
      itemId
    }
  });

  const { product } = data || {};

  const {
    media,
    info,
    identifiers
  } = product || {};
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (media?.images?.length) {
      setProductState({
        images: media?.images,
        canonicalUrl: identifiers?.canonicalUrl,
        productType: identifiers?.canonicalUrl,
        productLabel: identifiers?.canonicalUrl,
        globalCustomConfigurator: info?.canonicalUrl,
        augmentedReality: info?.canonicalUrl
      });
    }
  }, [media, info, identifiers]);

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredBeacon: sponsoredValues?.sponsoredBeacon || info?.sponsoredBeacon,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata
      });
    }
  }, [info?.isSponsored, info?.sponsoredBeacon, info?.sponsoredMetadata]);

  const canonicalUrl = identifiers?.canonicalUrl || productState.canonicalUrl;
  const productType = identifiers?.productType || productState.productType;
  const productLabel = identifiers?.productLabel || productState.productLabel;
  const globalCustomConfigurator = info?.globalCustomConfigurator || productState.globalCustomConfigurator;
  const augmentedReality = info?.augmentedReality || productState.augmentedReality;
  const images = media?.images || productState.images;

  const handleClick = (event, action = null) => {

    const customExperience = !!globalCustomConfigurator?.customExperience;
    if (!customExperience && onClick && action !== BUILD_AND_BUY) {
      onClick(event, product, action, sponsoredValues);
    }
    const productImageEventData = {
      podAction: action || 'product image',
      podAnchorSku: itemId,
      target,
      parent
    };
    if (isIframe) {
      window.top.LIFE_CYCLE_EVENT_BUS.trigger('cartItem.DD_EVENT', iframeAnalyticsData);
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productImageEventData);
    }
  };
  const productURL = getCustomUrlWithAnalytics({
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    sponsoredValues,
    merchQueryParam
  });
  const imageUrl = (images?.find((img) => img.subType === 'PRIMARY') || {}).url;
  const secondaryImageUrl = (images?.find((img) => img.subType === 'SECONDARY') || {}).url;

  const noProductImage = 'https://images.thdstatic.com/catalog/productImages/No_Image_145.jpg';
  const isMobile = channel === 'mobile';
  const imageTarget = isMobile && !isIframe ? '' : target;
  const showARIcon = isMobile && augmentedReality;
  const onBuildAndBuyImageClick = (event) => handleClick(event, BUILD_AND_BUY);
  const checkOnClick = isBuildAndBuyProduct(product) ? onBuildAndBuyImageClick : handleClick;

  const placeholderMessage = `${itemId} of product in the product pod`;

  const altLabel = productLabel || placeholderMessage;

  if (loading) {
    return (
      <Skeleton>
        <SkeletonBlock aspect="square" height={60} />
      </Skeleton>
    );
  }

  if (dualImageSwipe && imageUrl && secondaryImageUrl) {
    const childImages = [
      <img
        key={imageUrl}
        className={strechyClass}
        src={imageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
        width={POD_IMAGE_SIZE}
        height={POD_IMAGE_SIZE}
        alt={altLabel}
        loading="lazy"
      />,
      <img
        key={secondaryImageUrl}
        className={strechyClass}
        src={secondaryImageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
        width={POD_IMAGE_SIZE}
        height={POD_IMAGE_SIZE}
        alt={altLabel}
        loading="lazy"
      />
    ];
    if (showARIcon) {
      childImages.push(<AugmentedReality />);
    }

    return (
      <a
        href={productURL}
        onClick={checkOnClick}
        target={imageTarget}
      >
        <div className="sui-relative">
          <Carousel
            showArrows={false}
            showDots
            dotBelow
            itemWidthMobile="101%"
            itemClass="sui-relative sui-p-0"
          >
            {childImages}
          </Carousel>
          {children}
        </div>
      </a>
    );
  }

  const productHasSecondaryImageClass = 'sm:sui-opacity-100 sm:hover:sui-opacity-0' + transitionOpacity;
  const imageCardClass = `${strechyClass} ${secondaryImageUrl && productHasSecondaryImageClass}`;

  return (
    <a
      href={productURL}
      onClick={checkOnClick}
      target={imageTarget}
    >
      <div
        className={`
          sui-relative
          ${inCartQuantity > 0 && overlayClass}
        `}
        data-quantity={inCartQuantity}
        data-testid="product-image__wrapper"
      >
        {imageUrl && (
          <img
            src={(previewImgUrl || imageUrl).replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
            width={POD_IMAGE_SIZE}
            height={POD_IMAGE_SIZE}
            alt={altLabel}
            loading="lazy"
            className={imageCardClass}
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority={fetchPriority}
          />
        )}
        {secondaryImageUrl && (
          <img
            className={`
              ${strechyClass}
              ${showSecondaryImage && hoverSecondaryImageClass}
              ${!showSecondaryImage && secondayImageClass}
            `}
            src={secondaryImageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
            width={POD_IMAGE_SIZE}
            height={POD_IMAGE_SIZE}
            alt={altLabel}
            loading="lazy"
          />
        )}
        {(!imageUrl && !secondaryImageUrl && mounted) && (
          <img
            className={imageCardClass}
            src={noProductImage}
            width={145}
            height={145}
            alt={altLabel}
            title={productLabel}
            loading="lazy"
          />
        )}
        {showARIcon && <AugmentedReality />}
        {children}
      </div>
    </a>
  );
};

ProductImage.propTypes = {
  showSecondaryImage: PropTypes.bool,
  dualImageSwipe: PropTypes.bool,
  inCartQuantity: PropTypes.number,
  itemId: PropTypes.string,
  onClick: PropTypes.func,
  previewImgUrl: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  iframeAnalyticsData: PropTypes.object,
  isIframe: PropTypes.bool,
  fetchPriority: PropTypes.string,
  merchQueryParam: PropTypes.string
};

ProductImage.defaultProps = {
  showSecondaryImage: false,
  dualImageSwipe: false,
  inCartQuantity: 0,
  itemId: null,
  onClick: () => null,
  previewImgUrl: '',
  target: '',
  children: null,
  isIframe: false,
  iframeAnalyticsData: null,
  fetchPriority: 'auto',
  merchQueryParam: null
};

ProductImage.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      augmentedReality: bool(),
      isSponsored: bool(),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
      }),
      globalCustomConfigurator: shape({
        customExperience: string()
      })
    }),
    identifiers: shape({
      canonicalUrl: string(),
      productType: string(),
      productLabel: string(),
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string(),
        sizes: arrayOf(string())
      }))
    }),
  }),
};

ProductImage.displayName = 'ProductImage';
