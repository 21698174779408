/* eslint-disable max-len */
import React from 'react';

export const pauseIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.9593 0H1.81897C1.24516 0 0.77832 0.466839 0.77832 1.04065V14.9593C0.77832 15.5332 1.24516 16 1.81897 16H4.9593C5.53313 16 5.99995 15.5332 5.99995 14.9593V1.04065C5.99995 0.466839 5.53313 0 4.9593 0Z" fill="white" />
    <path d="M14.181 0H11.0407C10.4668 0 10 0.466839 10 1.04065V14.9593C10 15.5332 10.4668 16 11.0407 16H14.181C14.7547 16 15.2216 15.5332 15.2216 14.9593V1.04065C15.2216 0.466839 14.7547 0 14.181 0Z" fill="white" />
  </svg>
);

export const playIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.79557 16C1.65618 16 1.51568 15.9642 1.39033 15.8919C1.13962 15.7473 0.985107 15.4791 0.985107 15.1896V0.810601C0.985107 0.520994 1.13962 0.253005 1.39033 0.108202C1.64106 -0.036602 1.95012 -0.0355315 2.20082 0.108202L14.6108 7.29765C14.8604 7.44246 15.015 7.70937 15.015 7.99898C15.015 8.28859 14.8604 8.55551 14.6108 8.70034L2.20082 15.8898C2.07547 15.9621 1.93497 15.999 1.7945 15.999L1.79557 16Z" fill="white" />
  </svg>
);