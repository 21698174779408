import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Overlay } from '@thd-olt-component-react/core-ui';

const ReactMobileOverlay = ({
  children, content, portal, mobileHeader
}) => {
  const [showForMobile, setShowForMobile] = useState(false);
  const overlayContent = (
    <Overlay
      onClose={() => setShowForMobile(false)}
      open={showForMobile}
      closeButton
      header={mobileHeader}
    >
      {content}
    </Overlay>
  );

  const getPortal = () => {
    return typeof document !== 'undefined'
      ? ReactDOM.createPortal(overlayContent, document.body)
      : overlayContent;
  };

  if (showForMobile) {
    return (portal ? getPortal() : overlayContent);
  }

  return React.cloneElement(children, {
    onClick: (event) => {
      if (children.props.onClick) {
        children.props.onClick(event);
      }
      setShowForMobile(true);
    },
  });
};

ReactMobileOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  mobileHeader: PropTypes.string,
  portal: PropTypes.bool
};

ReactMobileOverlay.defaultProps = {
  mobileHeader: null,
  portal: false
};

export { ReactMobileOverlay };
