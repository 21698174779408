import { useCallback, useMemo } from 'react';

import { MESSAGE_PLURAL } from '../utils/constants';
import { usePromoMessage } from './usePromoMessage';
import { usePromoModel } from './usePromoModel';

/**
 * Replaces any values specified by `{{objKey}}` in the message. Uses a map as the second param
 * to determine what to replace and with what.
 *
 * Example:
 * ```js
 * const message = 'Sally picked {{fromOrchard}} apples from the orchard and gave {{toFred}} to Fred.';
 * const formattedMessage = formateMessage(message, { fromOrchard: 5, toFred: 2 });
 * console.log(formattedMessage);
 * // output: Sally picked 5 apples from the orchard and gave 2 to Fred.
 * ```
 *
 * @param {string} message
 * @param {{ [key: string]: string | number }} obj
 * @returns {string | null}
 */
const formatMessage = (message, obj) => {
  return message.replace(/{{([a-zA-Z0-9]+)}}/g, (match, key) => obj[key]);
};

const getMessage = ({
  key,
  messages,
  messageState,
  pluralCount,
  replaceValues = {},
  subExperience,
}) => {
  if (key === null) {
    return null;
  }

  let message = messages[subExperience]?.state?.[messageState]?.[key]
    || messages[subExperience]?.[key]
    || messages.all?.state?.[messageState]?.[key]
    || messages.all?.[key]
    || null;

  if (typeof message === 'object' && message !== null) {
    if (pluralCount === 0 && message[MESSAGE_PLURAL.none]) {
      message = message[MESSAGE_PLURAL.none];
    } else if (pluralCount === 1 && message[MESSAGE_PLURAL.one]) {
      message = message[MESSAGE_PLURAL.one];
    } else if (pluralCount === 2 && message[MESSAGE_PLURAL.two]) {
      message = message[MESSAGE_PLURAL.two];
    } else if (pluralCount > 2 && message[MESSAGE_PLURAL.many]) {
      message = message[MESSAGE_PLURAL.many];
    } else {
      message = message[MESSAGE_PLURAL.other];
    }
  }

  if (message === null) {
    return null;
  }

  return formatMessage(message, replaceValues);
};

/**
 *
 * @param {string} key
 * @param {number} pluralCount
 * @returns {(replaceValues: object) => string | null}
 */
export const useMessageFn = (key, pluralCount) => {
  const {
    subExperience
  } = usePromoModel();
  const {
    messageReplaceValues,
    messages,
    messageState,
  } = usePromoMessage();

  return useCallback((replaceValues) => getMessage({
    key,
    messages,
    messageState,
    pluralCount,
    replaceValues: { ...messageReplaceValues, ...replaceValues },
    subExperience,
  }), [
    key,
    messageReplaceValues,
    messages,
    messageState,
    pluralCount,
    subExperience,
  ]);
};

/**
 * Replaces any values specified by `$objKey` in the message. Uses a map as the second param
 * to determine what to replace and with what.
 *
 * Example:
 * ```js
 * const message = 'Sally picked $fromOrchard apples from the orchard and gave $toFred to Fred.';
 * const formattedMessage = formateMessage(message, { fromOrchard: 5, toFred: 2 });
 * console.log(formattedMessage);
 * // output: Sally picked 5 apples from the orchard and gave 2 to Fred.
 * ```
 *
 * @param {string} message
 * @param {{ [key: string]: string | number }} obj
 * @param {object} replaceValues
 * @returns {string | null}
 */
export const useMessage = (
  key,
  pluralCount
) => {
  const {
    subExperience
  } = usePromoModel();
  const {
    messageReplaceValues,
    messages,
    messageState,
  } = usePromoMessage();

  return useMemo(() => getMessage({
    key,
    messages,
    messageState,
    pluralCount,
    replaceValues: messageReplaceValues,
    subExperience,
  }), [
    key,
    messageReplaceValues,
    messages,
    messageState,
    pluralCount,
    subExperience,
  ]);
};
