import React, { useState, useEffect } from 'react';
import PropTypes, { string, number } from 'prop-types';
import { Price } from '@thd-olt-component-react/price';
import { QueryProvider } from '@thd-nucleus/data-sources';
import {
  CardBody, Typography, Link, Button, CardContent, CardTitle
} from '@one-thd/sui-atomic-components';
import { clickEvent, impressionEvent, productClickEvent } from '../../utils/publisher';
import { CARD_IMAGE } from '../../utils/constants';
import { getPaymentEstimatorDetails, loadEstimatorScript } from '../../utils/payment-estimator-utils';
import { getUserName } from '../../utils/general';
import TermsAndConditionsDrawer from '../../common/terms-and-conditions-drawer.component';

const ProductSpotlight = ({ paymentEstimatorVersion, isMobile, product }) => {
  const [paymentEstimatorDetails, setPaymentEstimatorDetails] = useState(null);
  const [paymentEstimatorLoaded, setPaymentEstimatorLoaded] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const price = product.price;
  const productPricing = product;
  productPricing.pricing = { value: product.price };
  const productImage = product.imageURL.replace('<SIZE>', '300');
  const isAppliance = product.itemType === 'MAJOR_APPLIANCE';
  const productName = product.brand + ' ' + product.productName;
  const userName = getUserName()
    ? getUserName().charAt(0).toUpperCase() + getUserName().slice(1).toLowerCase() + ', ' : '';
  const cardTitle = userName + 'More Ways to Pay';

  const redirectToProduct = () => {
    productClickEvent();
    window.location.href = window.location.origin + product.canonicalURL;
  };

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const analyticsInteraction = () => {
    clickEvent('plcc-apply now-personalized', 'card');
  };

  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion,
      () => {
        setPaymentEstimatorDetails(getPaymentEstimatorDetails(price, isMobile, isAppliance, false, 'homepage'));
        setPaymentEstimatorLoaded(true);
      }
    );
    impressionEvent('plcc-apply now-personalized');
  }, []);
  const {
    applyNowUrl, displayPrice, promotionTerm
  } = paymentEstimatorDetails || {};

  const applyNowMessage = () => {
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <>
        <div className="sui-pb-3">
          <div className="sui-flex sui-pb-3">
            <input
              type="image"
              src={productImage}
              alt={productName}
              className="sui-mr-3 sui-self-start"
              width="80"
              height="80"
              onClick={redirectToProduct}
            />
            <div className="sui-cursor-pointer" onClick={redirectToProduct} role="link" tabIndex={0}>
              <div className="sui-overflow-hidden sui-text-lg">
                {productName}
              </div>
              <QueryProvider cacheKey="innerQuery">
                <Price
                  product={productPricing}
                  large={false}
                  displayEachUom={false}
                  itemId="123456789"
                />
              </QueryProvider>
            </div>
          </div>
          <div className="sui-flex">
            <img
              src={CARD_IMAGE.PLCN_HOMEDEPOT}
              alt="Credit Card Icon"
              className="sui-mr-3 sui-self-start sui-mt-2"
              height="50"
              width="80"
            />
            <div className="sui-text-lg">
              <span className="sui-font-bold">${displayPrice}.00 per mo.** </span>suggested
              payments with <span className="sui-font-bold">{promotionTerm} months** </span>financing
              on this <span className="sui-font-bold">${price} </span>purchase
            </div>
          </div>
        </div>
        <Typography variant="body-xs" color="subtle">
          See{' '}
          <span className="sui-align-text-bottom">
            <Link
              component="button"
              onClick={openDrawer}
              variant="body-xs"
            >
              terms & conditions
            </Link>
          </span>{' '}
          for offer details.
        </Typography>
      </>
    );
  };

  return (
    <CardContent
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
    >
      <CardTitle
        disableTypography
        header={(
          <Typography variant="h3">{cardTitle}</Typography>
        )}
      />
      {showDrawer && (
        <TermsAndConditionsDrawer
          closeDrawer={closeDrawer}
          open={showDrawer}
          showFinancing
          applyNowClick={() => clickEvent('plcc-est-bundle')}
          price={price}
        />
      )}
      <CardBody>
        {applyNowMessage()}
      </CardBody>
      <div>
        <Button variant="secondary" href={applyNowUrl} fullWidth onClick={analyticsInteraction}>Apply Now</Button>
      </div>
    </CardContent>
  );
};

ProductSpotlight.propTypes = {
  paymentEstimatorVersion: PropTypes.string,
  isMobile: PropTypes.bool,
  product: PropTypes.shape({
    productName: string.isRequired,
    brand: string.isRequired,
    canonicalURL: string.isRequired,
    imageURL: string.isRequired,
    itemType: string.isRequired,
    price: number.isRequired,
  }).isRequired
};
ProductSpotlight.defaultProps = {
  paymentEstimatorVersion: 'v1.0.40',
  isMobile: false,
};

export default ProductSpotlight;
