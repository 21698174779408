import {
  params,
  string,
  arrayOf,
  shape,
  bool,
  number,
} from '@thd-nucleus/data-sources';

export const phpDataModel = {
  uds: params({
    userId: string(),
    appId: string().isRequired(),
    apiName: string().isRequired(),
    key: string().isRequired(),
  }).shape({
    metadata: shape({
      apiName: string(),
      title: string(),
      version: string(),
      modelName: string(),
      fallbackModelName: string(),
    }),
    services: arrayOf({
      id: string()
    }),
  }),
};
