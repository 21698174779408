import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Certificate = createSvgIcon(
  <>
    <path d="M24 0H0v18.75h12V16.5H2.25V2.25h19.5V7.5H24V0Z" />
    <path d="M18 5.25H6V7.5h12V5.25Zm-12 6h6v2.25H6v-2.25Zm18-.75h-9.75v13.449l4.875-2.78L24 23.95V10.5Zm-7.5 9.576V12.75h5.25v7.326l-2.625-1.496-2.625 1.496Z" />
  </>,
  'Certificate'
);

export { Certificate };