import React from 'react';
import {
  arrayOf, func, shape, string, bool
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { Add } from '@one-thd/sui-icons';

import { ProductImageList } from '../ProductImageList/ProductImageList';

export const BogoProductImageList = ({
  onImageClick,
  rewardImages,
  size,
  qualifyingImages,
  inDrawer1,
  inDrawer2
}) => {
  return (
    <div className="sui-flex sui-gap-1" data-testid="bogo-product-image-list">
      <ProductImageList
        images={qualifyingImages}
        onImageClick={onImageClick}
        size={size}
        textFactory={(text) => (
          <Typography
            align="center"
            component="span"
            variant="body-xs"
          >
            {text}
          </Typography>
        )}
        inDrawer1={inDrawer1}
        inDrawer2={inDrawer2}
      />
      <div className="sui-flex sui-items-center" style={{ height: size }}>
        <Add size="small" />
      </div>
      <ProductImageList
        images={rewardImages}
        onImageClick={onImageClick}
        size={size}
        textFactory={(text) => (
          <Typography
            align="center"
            color="success"
            component="span"
            variant="body-xs"
            weight="bold"
          >
            {text}
          </Typography>
        )}
        inDrawer1={inDrawer1}
        inDrawer2={inDrawer2}
      />
    </div>
  );
};

BogoProductImageList.displayName = 'BogoImageList';

BogoProductImageList.propTypes = {
  onImageClick: func,
  rewardImages: arrayOf(shape({
    innerText: string,
    itemId: string,
    text: string,
    url: string,
  })).isRequired,
  size: string,
  qualifyingImages: arrayOf(shape({
    innerText: string,
    itemId: string,
    text: string,
    url: string,
  })).isRequired,
  inDrawer1: bool,
  inDrawer2: bool
};

BogoProductImageList.defaultProps = {
  onImageClick: null,
  size: '5rem',
  inDrawer1: false,
  inDrawer2: false
};
