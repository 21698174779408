import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Shed = createSvgIcon(
  <>
    <path d="M15.75 6h-7.5v2.25h7.5V6Z" />
    <path d="M18.986 2.395 12 .01 5.014 2.395 0 7.409V24h24V7.409l-5.014-5.014ZM16.5 21.75h-3.375v-9H16.5v9Zm-5.625 0H7.5v-9h3.375v9ZM5.25 10.5v11.25h-3V8.34l3.986-3.985L12 2.39l5.764 1.966 3.986 3.986V21.75h-3V10.5H5.25Z" />
  </>,
  'Shed'
);

export { Shed };