// Intersection Observer Settings
export const ROOT_MARGIN = '0px';
export const ROOT = null;

// Below is set to 0.5 for 50% of item to be in-view before firing callback
export const THRESHOLD = 0.5;

export const IMPRESSION_WAIT_TIME = 1000;

// Below is Intersection Observer V2 Only (check for browser support)
// BUG IN VERSION 2 THAT Intersects automatically on initial page load/ignores delay
// export const DELAY = 1000;

export const IMPRESSED = 'impressed';
export const READY = 'ready';