/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardTitle,
  CardMedia,
  CardBody,
  CardActions,
  Typography,
  Link,
  Alert,
} from '@one-thd/sui-atomic-components';
import { clickEvent, impressionEvent } from '../../utils/publisher';
import { CARD_IMAGE } from '../../utils/constants';

const Error = ({ redirectToCiti }) => {
  useEffect(() => {
    impressionEvent('plcc-unable to display');
  }, []);

  return (
    <CardContent
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
      data-component="Error"
    >
      <CardTitle
        disableTypography
        header={(
          <Typography variant="h3">My Home Depot Credit Card</Typography>
        )}
      />
      <div>
        <Alert status="warning">
          <Typography variant="body-base">Cannot Display Information <br /><br />
            We’re temporarily having trouble accessing your account information.
          </Typography>
        </Alert>
        <Typography variant="body-lg" weight="bold">Still Need to Pay & Manage Your Card?</Typography>
      </div>
      <CardBody>
        <div className="sui-flex sui-flex-col sui-gap-3">
          <div className="sui-grow sui-border-b-1 sui-border-primary sui-border-solid">
            <Typography variant="body-base" weight="bold">Consumer Credit</Typography>
            <div className="sui-flex sui-py-3 sui-px-1 sui-gap-4">
              <CardMedia
                className="sui-object-fill"
                src={CARD_IMAGE.PLCN_HOMEDEPOT}
                alt="Credit Card Icon"
                aspect="wide"
                width="100px"
              />
              <CardActions className="sui-flex sui-flex-col sui-justify-between sui-items-start">
                <Link href="tel:1-866-875-5488" underline="none">
                  1-866-875-5488
                </Link>
                <Link
                  component="button"
                  onClick={() => {
                    clickEvent('plcc-unable to display go to citibank');
                    redirectToCiti('PLCN_HOMEDEPOT');
                  }}
                >
                  Pay & Manage
                </Link>
              </CardActions>
            </div>
          </div>
          <div className="sui-grow sui-border-b-1 sui-border-primary sui-border-solid">
            <Typography variant="body-base" weight="bold">Commercial Credit</Typography>
            <div className="sui-flex sui-py-3 sui-px-1 sui-gap-4">
              <CardMedia
                className="sui-object-fill"
                src={CARD_IMAGE.PLNP_HOMEDEPOT}
                alt="Credit Card Icon"
                aspect="wide"
                width="100px"
              />
              <CardActions className="sui-flex sui-flex-col sui-justify-between sui-items-start">
                <Link href="tel:1-866-875-5490" underline="none">
                  1-866-875-5490
                </Link>
                <Link
                  component="button"
                  onClick={() => {
                    clickEvent('plcc-unable to display go to citibank');
                    redirectToCiti('PLNP_HOMEDEPOT');
                  }}
                >
                  Pay & Manage
                </Link>
              </CardActions>
            </div>
          </div>
          <div className="sui-grow">
            <Typography variant="body-base" weight="bold">Commercial Revolving Credit</Typography>
            <div className="sui-flex sui-py-3 sui-px-1 sui-gap-4">
              <CardMedia
                className="sui-object-fill"
                src={CARD_IMAGE.PLCR_HOMEDEPOT}
                alt="Credit Card Icon"
                aspect="wide"
                width="100px"
              />
              <CardActions className="sui-flex sui-flex-col sui-justify-between sui-items-start">
                <Link href="tel:1-866-875-5489" underline="none">
                  1-866-875-5489
                </Link>
                <Link
                  component="button"
                  onClick={() => {
                    clickEvent('plcc-unable to display go to citibank');
                    redirectToCiti('PLCR_HOMEDEPOT');
                  }}
                >
                  Pay & Manage
                </Link>
              </CardActions>
            </div>
          </div>
        </div>
      </CardBody>
    </CardContent>
  );
};

Error.propTypes = {
  redirectToCiti: PropTypes.func.isRequired,
};

export default Error;
