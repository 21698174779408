import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { playIcon, pauseIcon } from '../utils/VideoIcons';

export const HeroFlattenVideo = ({
  videoURL, altText
}) => {

  const [videoPaused, setVideoPaused] = useState(false);

  const videoButton = (
    <button
      type="button"
      /* eslint-disable-next-line max-len */
      className="hero-video-button sui-absolute sui-bottom-0 sui-right-0 sui-flex sui-justify-end sui-items-end sui-h-full sui-w-full"
      onClick={() => {
        const videoElement = document.querySelector(`source[src="${videoURL}"]`)?.parentNode;
        setVideoPaused(!videoPaused);
        /* eslint-disable-next-line no-unused-expressions */
        videoPaused ? videoElement?.play() : videoElement?.pause();
      }}
    >
      <div
        data-testid="play-button"
        className={`sui-h-8 sui-w-8 ${videoPaused ? 'sui-flex' : 'sui-hidden'}`}
      >
        <div className="hover:sui-opacity-60 sui-opacity-35">{playIcon}</div>
      </div>
      <div
        className={`sui-h-8 sui-w-8 ${videoPaused ? 'sui-hidden' : 'sui-flex'}`}
      >
        <div className="hover:sui-opacity-60 sui-opacity-35">{pauseIcon}</div>
      </div>
    </button>
  );

  const heroImage = (
    <div className="sui-overflow-hidden sui-relative sui-w-full sui-h-auto sui-top-0 sui-left-0">
      <video
        className="sui-w-full"
        aria-label={`${altText}`}
        autoPlay
        muted
        playsInline
        loop
        controls=""
      >
        <source src={videoURL} type="video/mp4" />
      </video>
      {videoButton}
    </div>
  );

  return heroImage;
};

HeroFlattenVideo.propTypes = {
  videoURL: string.isRequired,
  altText: string,
};

HeroFlattenVideo.defaultProps = {
  altText: '',
};

HeroFlattenVideo.displayName = 'HeroFlattenVideo';