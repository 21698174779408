import React from 'react';
import { string, bool, shape, oneOf } from 'prop-types';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Metadata, HomepageMetadata, NoIndexNoFollowMetadata } from '@thd-olt-component-react/metadata';
import { staticMetadata } from './metadata-utils';

const HomepageMetadataContainer = ({
  isPreviewPage,
  preloadImageUrl,
  preloadImageId,
  metadata
}) => {
  const hpMetadata = { ...staticMetadata, ...metadata };

  return (
    <ErrorBoundary name="metadata">
      <Metadata>
        {isPreviewPage && (
          <NoIndexNoFollowMetadata />
        )}
        <HomepageMetadata
          metadata={hpMetadata}
          preloadImageUrl={preloadImageUrl}
          preloadImageId={preloadImageId}
        />
      </Metadata>
    </ErrorBoundary>
  );
};

HomepageMetadataContainer.propTypes = {
  isPreviewPage: bool.isRequired,
  preloadImageUrl: string.isRequired,
  preloadImageId: oneOf(['preloadHeroLargeImage', 'preloadHeroSmallImage']).isRequired,
  metadata: shape({}).isRequired
};

HomepageMetadataContainer.defaultProps = {};

export { HomepageMetadataContainer };
