import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleSecondaryDarkYellow = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleSecondaryDarkYellow.palette['--sui-palette-background-primary'] = '251 168 39';

export { HouseStyleSecondaryDarkYellow };