import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CounterTop = createSvgIcon(
  <>
    <path d="M17.25 16.5v-6H15v6h2.25ZM9 10.5v6H6.75v-6H9Z" />
    <path d="M0 0h24v6.75h-1.5V24h-21V6.75H0V0Zm13.125 21.75h7.125v-15h-7.125v15Zm-2.25-15H3.75v15h7.125v-15ZM21.75 4.5V2.25H2.25V4.5h19.5Z" />
  </>,
  'CounterTop'
);

export { CounterTop };