import * as React from 'react';
import * as PropTypes from 'prop-types';

const RectShape = (props) => {
  const { className, style, color } = props;

  const defaultStyle = {
    backgroundColor: color,
    width: '100%',
    height: '100%',
    marginRight: 10
  };

  const classes = ['rect-shape', className].filter((clss) => clss).join(' ');

  return (
    <div className={classes} style={{ ...defaultStyle, ...style }} />
  );
};

RectShape.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

RectShape.defaultProps = {
  className: '',
  style: {}
};

export { RectShape };
