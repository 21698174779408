import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const HowTo = createSvgIcon(
  <>
    <path d="M14.25 7.875a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm0 9.375V10.5H12v6.75h2.25Z" />
    <path d="M22.5 0H3.75v5.25H1.5V7.5h2.25v3H1.5v2.25h2.25v3H1.5V18h2.25v6H22.5V0ZM6 18h1.5v-2.25H6v-3h1.5V10.5H6v-3h1.5V5.25H6v-3h14.25v19.5H6V18Z" />
  </>,
  'HowTo'
);

export { HowTo };