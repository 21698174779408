import React from 'react';
import { bool, number, string } from 'prop-types';
import classnames from 'classnames';

export const SegmentedProgressBarSegment = ({
  'data-testid': dataTestId,
  hasAnimation,
  first,
  last,
  value
}) => {
  const promoProgressSegmentBarClasses = classnames(
    'sui-bg-strong sui-relative sui-h-2 sui-w-full',
    {
      'sui-rounded-l-full': first,
      'sui-rounded-r-full': last,
    }
  );

  // we animate current tier. In border cases, we animate both above and below.
  const promoProgressSegmentLevelClasses = classnames(
    'sui-absolute sui-h-full sui-left-0',
    {
      'sui-ease-linear sui-delay-0 sui-duration-500': hasAnimation,
      'sui-rounded-l-full': first && value > 0,
      'sui-rounded-r-full': last && value >= 100,
      'sui-bg-inverse': value < 100,
      'sui-bg-success': value >= 100
    }
  );

  return (
    <div className={promoProgressSegmentBarClasses}>
      <span
        className={promoProgressSegmentLevelClasses}
        data-testid={dataTestId}
        style={{ width: `${Math.min(value, 100)}%` }}
      />
    </div>
  );
};

SegmentedProgressBarSegment.displayName = 'SegmentedProgressBarSegment';

SegmentedProgressBarSegment.propTypes = {
  'data-testid': string,
  hasAnimation: bool,
  first: bool,
  last: bool,
  value: number.isRequired
};

SegmentedProgressBarSegment.defaultProps = {
  'data-testid': null,
  hasAnimation: false,
  first: false,
  last: false,
};
