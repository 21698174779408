import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Basic } from './Basic';
import { StrikeThrough } from './StrikeThrough';
import { PercentWithMessage } from './PercentWithMessage';

export const StrikeThroughPrice = ({
  totalPrice, unitPrice, percentOffValue, percentOffMessage, align
}) => {
  const strikeThroughClassName = classNames({ 'sui-text-right': align === 'end' });
  return (
    <div className={strikeThroughClassName} data-automation-id="pricingScenariosText">
      <Basic price={totalPrice} />
      <StrikeThrough price={unitPrice} />
      <PercentWithMessage
        percent={percentOffValue}
        message={percentOffMessage}
      />
    </div>
  );
};

StrikeThroughPrice.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  unitPrice: PropTypes.number.isRequired,
  percentOffValue: PropTypes.string.isRequired,
  percentOffMessage: PropTypes.string.isRequired,
  align: PropTypes.string
};

StrikeThroughPrice.defaultProps = {
  align: ''
};

StrikeThroughPrice.displayName = 'StrikeThroughPrice';
