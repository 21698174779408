import thdCustomer from '@thd-olt-global/thd-customer';

const getUserDetails = () => {
  const mcvisid = thdCustomer.mcvisID;
  const svocid = thdCustomer.svocID;
  const isB2B = thdCustomer.isB2BCustomer;
  const isB2C = thdCustomer.isB2CCustomer;
  const isB2X = thdCustomer.isB2XCustomer;

  let userType = 'unknown';

  if (isB2B) {
    userType = 'B2B';
  } else if (isB2C) {
    userType = 'B2C';
  } else if (isB2X) {
    userType = 'B2X';
  }

  return {
    mcvisid,
    svocid,
    user_type: userType
  };
};

export default getUserDetails;
