import React from 'react';
import { SkeletonLine } from '@one-thd/sui-atomic-components';

export function CardStatusPlaceholder() {
  return (
    <div className="sui-mb-2">
      <SkeletonLine variant="single" TypographyProps={{ height: 'loose' }} />
    </div>
  );
}
