import React from 'react';
import PropTypes from 'prop-types';
import { BasicPrice } from './subcomponents/BasicPrice';
import { StrikeThroughPrice } from './subcomponents/StrikeThroughPrice';
import { BasicPerItemPrice } from './subcomponents/BasicPerItemPrice';
import { StrikeThrougPerItemPrice } from './subcomponents/StrikeThroughPerItemPrice';
import { isBasicPerItemPrice, isStrikeThroughPerItemPrice, isStrikeThroughPrice } from '../../utils/utils';

export const PriceMinimal = ({ skuModel, quantity, align }) => {
  const skuModelProduct = skuModel?._product;
  const skuModelPricing = skuModelProduct?.pricing ?? {};
  const skuModelPromotion = skuModelProduct?.pricing?.promotion ?? {};

  const totalPrice = skuModelPricing?.cartItem?.total ?? 0.00;
  if (isBasicPerItemPrice(skuModel, quantity)) {
    const unitPrice = skuModelPricing.value
      ? skuModelPricing.value
      : skuModelPricing.original;
    return <BasicPerItemPrice totalPrice={totalPrice} unitPrice={unitPrice} align={align} />;
  }
  if (isStrikeThroughPrice(skuModel, quantity)) {
    const unitPrice = skuModelPricing?.original ?? 0.00;
    const percentOffValue = skuModelPromotion?.percentageOff ?? 0;
    const message = skuModelPromotion?.message ?? 'no promotion avaiable';
    return (
      <StrikeThroughPrice
        totalPrice={totalPrice}
        unitPrice={unitPrice}
        percentOffValue={percentOffValue}
        percentOffMessage={message}
        align={align}
      />
    );
  }
  if (isStrikeThroughPerItemPrice(skuModel, quantity)) {
    const originalUnitPrice = skuModelPricing?.original ?? 0.00;
    const discountUnitPrice = skuModelPricing?.value ?? 0.00;
    const percentOffValue = skuModelPromotion?.percentageOff ?? 0;
    const percentOffMessage = skuModelPromotion?.message ?? 'no promotion aviable';
    return (
      <StrikeThrougPerItemPrice
        totalPrice={totalPrice}
        originalUnitPrice={originalUnitPrice}
        discountUnitPrice={discountUnitPrice}
        percentOffValue={percentOffValue}
        percentOffMessage={percentOffMessage}
        align={align}
      />
    );
  }
  return <BasicPrice totalPrice={totalPrice} align={align} />;
};

PriceMinimal.propTypes = {
  /**
   * the transformed price data
   */
  skuModel: PropTypes.shape({
    _product: PropTypes.shape({
      pricing: PropTypes.shape({
        itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // unit price
        original: PropTypes.number.isRequired,

        // discount unit price
        value: PropTypes.number,

        // when true, display exchange pricing badge
        showExchangePricing: PropTypes.bool,

        // when true, display preferred pricing badge
        showPreferredPricingBadge: PropTypes.bool,

        // when true, display preferred pricing badge's tooltip
        showPreferredPricingBadgeToolTip: PropTypes.bool,

        cartItem: PropTypes.shape({

          // total price of this line-item
          total: PropTypes.number.isRequired,
        }),

        promotion: PropTypes.shape({
          // used to display %off message
          message: PropTypes.string,

          // % off when have discount
          percentageOff: PropTypes.string,
        }),
        dates: PropTypes.shape({
          // start date of discount
          start: PropTypes.string,

          // end date of discount
          end: PropTypes.string
        })
      }).isRequired
    }).isRequired,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  align: PropTypes.string
};

PriceMinimal.defaultProps = {
  align: 'start'
};

PriceMinimal.displayName = 'PriceMinimal';
