import axios from 'axios';

const HOST_URL = typeof window !== 'undefined' ? `${window.location.origin}/sponsored/v1` : null;

const get = async (config = null) => {
  return axios.get(HOST_URL, config);
};

const getFromBannerApi = async (host, config = null) => {
  const url = host || 'https://apionline.homedepot.com';
  let bannerHost = url + '/sponsoredbanner/v1';
  return axios.get(bannerHost, config);
};

export { get, getFromBannerApi };
