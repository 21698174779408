import React from 'react';
import classnames from 'classnames';
import { Typography } from '@one-thd/sui-atomic-components';

import { MESSAGE_KEY } from '../../utils/constants';
import { formatPrice } from '../../utils/promo-presentation-utils';
import { useMessageFn } from '../../hooks/useMessage';
import { usePromoModel } from '../../hooks/usePromoModel';

export const BmsmTiers = () => {
  const { rewardTiers } = usePromoModel();
  const qualifyingMessageFn = useMessageFn(MESSAGE_KEY.podCtaTierQualifyingTemplate);
  const rewardMessageFn = useMessageFn(MESSAGE_KEY.podCtaTierRewardTemplate);

  const tierMessages = rewardTiers.map(({
    minPurchaseAmount,
    minPurchaseQuantity,
    rewardPercent,
    rewardAmountPerOrder,
    fixedPriceTotal
  }) => {
    const qualifyingMessage = qualifyingMessageFn({
      qualifyingAmount: minPurchaseQuantity || formatPrice(minPurchaseAmount, true)
    });

    let rewardAmount;
    if (rewardPercent) {
      rewardAmount = `${rewardPercent}%`;
    } else if (fixedPriceTotal) {
      rewardAmount = formatPrice(fixedPriceTotal, true);
    } else {
      rewardAmount = formatPrice(rewardAmountPerOrder, true);
    }
    const rewardMessage = rewardMessageFn({ rewardAmount });

    return ({
      qualifyingMessage,
      rewardMessage
    });
  });

  // eslint-disable-next-line tailwindcss/no-arbitrary-value
  const bmsmTierClasses = classnames(
    'sui-relative sui-my-2 sui-px-3 sui-flex sui-flex-col after:sui-absolute after:sui-content-[""]',
    'after:sui-w-[2px] after:sui-right-0 after:sui-h-full after:sui-bg-[#CCCCCB] last:after:sui-content-none'
  );

  return (
    <ol className="sui-w-full sui-flex sui-flex-wrap" data-testid="bmsm-tiers">
      {tierMessages.map(({ qualifyingMessage, rewardMessage }, i) => (
        <li
          className={bmsmTierClasses}
          key={`promo-bmsm-tier-${i}`}
        >
          <Typography align="center" component="span">
            {qualifyingMessage}
          </Typography>
          <Typography align="center" component="span" variant="h5">
            {rewardMessage}
          </Typography>
        </li>
      ))}
    </ol>
  );
};

BmsmTiers.displayName = 'BmsmTiers';
