const fetchIdsFromSource = async ({ handler, data, shouldMakeRequest, name }) => {
  try {
    if (shouldMakeRequest) {
      const res = await handler({ ...data });

      return { name, res };
    }
    return { name, res: [] };
  } catch (error) {
    /* eslint-disable-next-line no-console, no-unused-expressions */
    window.isDebugMode && console.error(error);

    window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.error', {
      error: { status: error?.errorResponse?.status, message: error?.errorMessage },
    });
    return { name, error };
  }
};

export default fetchIdsFromSource;