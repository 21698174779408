import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { LoyaltyWelcomeCard as LoyaltyBenefitsComponent } from '@thd-olt-component-react/loyalty-benefits';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ImpressionProvider } from '@thd-olt-component-react/impression';

export function LoyaltyBenefits({ id }) {
  const data = {
    id,
    name: 'LoyaltyWelcomeCard',
    component: 'LoyaltyWelcomeCard',
    type: 'content',
    position: 1,
  };
  return (
    <ErrorBoundary name="loyalty-benefits">
      <QueryProvider cacheKey="loyalty-benefits-cacheKey">
        <ImpressionProvider data={data}>
          <LoyaltyBenefitsComponent componentId={id} />
        </ImpressionProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

LoyaltyBenefits.propTypes = {
  id: string,
};

LoyaltyBenefits.defaultProps = {
  id: undefined,
};
