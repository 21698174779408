import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CreditCard = createSvgIcon(
  <>
    <path d="M8.25 15v-3.75H4.5V15h3.75Z" />
    <path d="M18.078.095c.285.126.505.363.608.656l2.117 5.999h2.072c.621 0 1.125.504 1.125 1.125v15c0 .621-.504 1.125-1.125 1.125H1.125A1.125 1.125 0 0 1 0 22.875v-15C0 7.254.504 6.75 1.125 6.75h.389L17.185.09c.286-.122.61-.12.893.005ZM16.963 2.63 7.266 6.75h11.151l-1.454-4.121ZM2.25 9v12.75h19.5V9H2.25Z" />
  </>,
  'CreditCard'
);

export { CreditCard };