import classNames from 'classnames';

const sizeMapping = {
  small: 'sui-text-xs',
  base: 'sui-text-base',
  large: 'sui-text-lg'
};

const variantMapping = {
  subtle: {
    colors: ['primary', 'inverse', 'medium', 'brand', 'info', 'success', 'warning', 'danger']
  },
  regular: {
    colors: ['brand', 'info', 'success', 'warning', 'danger']
  },
  strong: {
    colors: ['primary', 'inverse', 'medium', 'brand', 'info', 'success', 'warning', 'danger']
  }
};

const badgeClasses = {
  subtle: ({ color }) => classNames('sui-font-bold sui-leading-tight sui-border-1 sui-border-solid sui-rounded-full sui-bg-transparent', {
    'sui-text-primary sui-border-strongest': color === 'primary',
    'sui-text-inverse sui-border-inverse': color === 'inverse',
    'sui-text-primary sui-border-strong': color === 'medium',
    'sui-text-primary sui-border-accent': color === 'brand',
    'sui-text-primary sui-border-info': color === 'info',
    'sui-text-primary sui-border-success': color === 'success',
    'sui-text-primary sui-border-warning': color === 'warning',
    'sui-text-primary sui-border-danger': color === 'danger'
  }),
  regular: ({ color }) => classNames('sui-font-bold sui-text-primary sui-leading-tight sui-rounded-full', {
    'sui-bg-brand-subtle': color === 'brand',
    'sui-bg-info-subtle': color === 'info',
    'sui-bg-success-subtle': color === 'success',
    'sui-bg-warning-subtle': color === 'warning',
    'sui-bg-danger-subtle': color === 'danger'
  }),
  strong: ({ color }) => classNames('sui-font-bold sui-leading-tight sui-rounded-full', {
    'sui-text-inverse sui-bg-inverse': color === 'primary',
    'sui-text-primary sui-bg-primary': color === 'inverse',
    'sui-text-primary sui-bg-strong': color === 'medium',
    'sui-text-inverse sui-bg-brand': color === 'brand',
    'sui-text-inverse sui-bg-info-strong': color === 'info',
    'sui-text-inverse sui-bg-success-strong': color === 'success',
    'sui-text-inverse sui-bg-warning-strong': color === 'warning',
    'sui-text-inverse sui-bg-danger-strong': color === 'danger'
  }),
};

export default function createBadge({
  color: colorProp = 'primary',
  variant: variantProp,
  size = 'base',
}) {

  const variant = variantMapping[variantProp] !== undefined ? variantProp : 'strong';
  const { colors } = variantMapping[variant];

  const color = colors.indexOf(colorProp) !== -1 ? colorProp : colors[0];

  const badgeClass = badgeClasses[variant]?.({ color });

  const classes = `${badgeClass} ${sizeMapping[size]}`;

  return {
    classes,
  };
}