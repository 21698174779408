/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useContext } from 'react';
import ReactDOM from 'react-dom';
import {
  bool, oneOfType, string, func, number, node, array, arrayOf, shape
} from 'prop-types';
import { DataProviderClient } from '@thd-nucleus/data-sources';

const emptyContext = { Provider: (props) => props.children };
export const contexts = new Array(8).fill(emptyContext, 0, 8);

// when user navigates back we dont want to run hydator again
let DISABLED_HYDRATOR_ON_BACK = false;
if (typeof window !== 'undefined') {
  // LIFE_CYCLE_EVENT_BUS.on('router.change'...) is too late in the
  // transition and loses the race of hydrator mounting first.
  window.addEventListener('popstate', (event) => {
    DISABLED_HYDRATOR_ON_BACK = true;
  });

  if (window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.on('router.change', ({ output }) => {
      const { action } = output;
      if (action === 'PUSH' || action === 'POP') {
        DISABLED_HYDRATOR_ON_BACK = true;
      }
    });
  } else {
    console.error('life cycle event bus was not found, unable to setup listener for router.change');
  }
}

let contextsFrozen = false;
// Use this to declare the contexts you need applied to this tree.
export const declareContexts = (allContexts = [], { freeze = false } = {}) => {
  if (contextsFrozen) {
    if (!freeze) {
      console.log('warning, unnecessary call to declareContexts after it has been frozen');
    }
    return;
  }
  if (allContexts.length > 8) throw new Error('Hydrator: You cannot declare more than 8 contexts.');

  for (let i = 0; i < 8; i += 1) {
    contexts[i] = allContexts[i] || emptyContext;
  }

  if (freeze) {
    contextsFrozen = true;
  }
};

const ContextWrapper = (props) => {
  const { values, children } = props;
  const [Context0, Context1, Context2, Context3, Context4, Context5, Context6, Context7] = contexts;
  const [val0, val1, val2, val3, val4, val5, val6, val7] = values;

  // We can't loop because state will be lost in the children as we
  // undo preserved context.
  return (
    <Context0.Provider value={val0}>
      <Context1.Provider value={val1}>
        <Context2.Provider value={val2}>
          <Context3.Provider value={val3}>
            <Context4.Provider value={val4}>
              <Context5.Provider value={val5}>
                <Context6.Provider value={val6}>
                  <Context7.Provider value={val7}>
                    {children}
                  </Context7.Provider>
                </Context6.Provider>
              </Context5.Provider>
            </Context4.Provider>
          </Context3.Provider>
        </Context2.Provider>
      </Context1.Provider>
    </Context0.Provider>
  );
};

// Wraps components and allows for progressive hydration.
export const Hydrator = (props) => {
  const ssr = typeof window === 'undefined';
  const disableSSR = !ssr && window.DISABLE_SSR;
  const {
    id = null,
    className = null,
    waitFor,
    timeout,
    children,
    delay,
    placeholder,
    scrollBuffer,
    patch,
    preserveCtxVal,
    afterHydrate,
    immediateRender = false,
    initObserver
  } = props;

  const root = useRef(!ssr && window.document.getElementById(id));
  const completed = useRef(false);
  const [delayed, setDelayed] = useState(!!delay);
  const delayedRef = useRef(!!delay);
  const wasViewed = useRef(false);
  const shouldNotHydrate = useRef(true);
  // @TODO: Need a placeholder for section from spa transition.
  const [section, setSection] = useState(() => () => placeholder);
  const [shouldHydrate, setShouldHydrate] = useState(false);
  const [shouldFallbackRender, setShouldFallbackRender] = useState(false);
  const contextMap = useRef(new Map());
  const preserveVal = useRef(null);
  const newVal = useRef(null);
  const [forceRender, setForceRender] = useState(immediateRender || (DISABLED_HYDRATOR_ON_BACK && !waitFor));
  const [undoPreserve, setUndoPreserve] = useState(false);
  const contextValues = useRef([]);
  // eslint-disable-next-line
  const preserveContextValue = (ctx) => {
    if (undoPreserve) preserveVal.current = newVal.current;
    const value = { ...useContext(ctx) };
    newVal.current = value[preserveCtxVal] || newVal.current;
    preserveVal.current = preserveVal.current || { ...newVal.current };
    // if (typeof newVal.current === 'object') {
    //   preserveVal.current = { ...newVal.current };
    // } else if (typeof newVal.current !== 'undefined') {
    //   preserveVal.current = newVal.current;
    // }

    value[preserveCtxVal] = preserveVal.current;
    return value;
  };

  if (preserveCtxVal) {
    contexts.forEach((ctx, index) => { contextValues.current[index] = preserveContextValue(ctx); });
  } else {
    // eslint-disable-next-line
    contexts.forEach((ctx, index) => { contextValues.current[index] = useContext(ctx); });
  }

  const hydrate = async () => {

    if (completed.current) return;

    // @TODO: Add a prop so a custom loading can be given
    // for during dynamic import, if we don't have ssr.
    if (waitFor) {
      await Promise.all(Array.isArray(waitFor)
        ? waitFor.map((compImport) => compImport())
        : [waitFor()]
      );
    }

    const client = DataProviderClient.getClient();

    ReactDOM.hydrate(
      <DataProviderClient clientOverride={client}>
        <ContextWrapper values={contextValues.current} {...props}>{children}</ContextWrapper>
      </DataProviderClient>,
      root.current
    );

    // @TODO: Offer an option to preserve an entire context, or all contexts.
    // ie: preserveCtx, preserveAllCtx
    // @TODO: Look into race condition that can happen if we render immediately
    // back into original tree (causing markup to show twice).
    // Note: Since the object should be shallow-ish, using JSON.stringify here.
    if (JSON.stringify(preserveVal.current) !== JSON.stringify(newVal.current)) {
      setTimeout(() => setForceRender(true));
    }
    completed.current = true;
    afterHydrate();
  };

  // For when we can't hydrate, just render normally.
  const fallbackRender = async () => {
    if (completed.current) return;

    if (waitFor) {
      await Promise.all(Array.isArray(waitFor)
        ? waitFor.map((compImport) => compImport())
        : [waitFor()]
      );
    }

    completed.current = true;
    setSection(() => () => children);
    afterHydrate();
  };

  // Observer for when users scroll down the fold into an element.
  const observe = (elem, cb) => {
    // Note: We can't use delayed state directly in the observer callback
    // because the hook useState is not invoked to gather the saved state.
    // We'll need to use a ref "delayedRef".
    const observerOptions = {
      rootMargin: `${scrollBuffer}px 0px`
    };
    new IntersectionObserver(async ([entry], obs) => {
      if (!entry.isIntersecting || delayedRef.current) return;
      obs.unobserve(elem);
      cb();
    }, observerOptions).observe(elem);
  };

  const observeAndHydrate = () => {
    shouldNotHydrate.current = false;

    if (delay) {
      setTimeout(() => {
        setDelayed(false);
      }, delay);
    }

    if (timeout) setTimeout(() => setShouldHydrate(true), timeout);

    observe(root.current, () => setShouldHydrate(true));
  };

  const observeAndRender = async () => {
    const elem = window.document.getElementById(id);
    if (delay) setTimeout(() => { setDelayed(false); }, delay);
    if (timeout) setTimeout(() => setShouldFallbackRender(true), timeout);
    observe(elem, () => setShouldFallbackRender(true));
  };

  const isPartiallyInView = () => {
    if (wasViewed.current) return true;
    const elem = window.document.getElementById(id);
    if (!elem) return false;

    const bounding = elem.getBoundingClientRect();
    const elemHeight = elem.offsetHeight;
    const elemWidth = elem.offsetWidth;
    const isInView = bounding.top + scrollBuffer >= -elemHeight
      && bounding.left >= -elemWidth
      && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + elemWidth
      && bounding.bottom - scrollBuffer <= (window.innerHeight || document.documentElement.clientHeight) + elemHeight;
    return isInView;
  };

  useEffect(() => {
    // Trigger one more render cycle without the preserved context.
    if (forceRender && !undoPreserve) setUndoPreserve(true);
  }, [forceRender]);

  useEffect(() => {
    if (shouldHydrate) {
      hydrate();
    }

    if (shouldFallbackRender) {
      fallbackRender();
      setShouldFallbackRender(false);
    }
  }, [shouldHydrate, shouldFallbackRender]);

  // Hydrate when wrapper (root element) is in the viewport.
  useEffect(() => {
    if (initObserver !== false && !immediateRender) {
      if (disableSSR || !root.current || !patch) {
        observeAndRender();
        return;
      }

      if (wasViewed.current) {
        afterHydrate();
        return;
      }

      observeAndHydrate();
    }
  }, [initObserver]);

  // Hydrate after delay if element still in viewport.
  useEffect(() => {
    // So our intersection observer will have the updated delayed state.
    delayedRef.current = delayed;

    if (!delay || delayed || !isPartiallyInView()) return;
    if (disableSSR || !root.current) {
      fallbackRender();
      return;
    }

    hydrate();
  }, [delayed]);

  // For these cases we don't hydrate, so fallback to normal render:
  // 1. When on the server-side render pass.
  // 2. When there is no server side render markup (ie. disableSSR).
  // 3. When no root element found (ie. SPA transition).
  // 4. When root element is already partially in view (and no delay).
  // 5. When we are done hydrating and on next render we move back into
  //    the original tree. (Note: React has optimized for this.)
  if (forceRender && preserveCtxVal) {
    return (
      <section id={id} className={className}>
        <ContextWrapper values={contextValues.current} {...props}>{children}</ContextWrapper>
      </section>
    );
  }

  if (ssr || completed.current || forceRender) {
    return <section id={id} className={className}>{children}</section>;
  }

  if (!ssr && root.current && !delay && !waitFor && shouldNotHydrate.current && isPartiallyInView()) {
    wasViewed.current = true;
    return <section id={id} className={className}>{children}</section>;
  }

  if (disableSSR || !root.current) {
    const Child = section;
    return <section id={id} className={className}><Child /></section>;
  }

  // We can hydrate, but instruct React to not hydrate the children yet.
  return (
    <section
      id={id}
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: '' }}
      suppressHydrationWarning
    />
  );
};

ContextWrapper.propTypes = {
  // Array of context values gathered each time the Hydrator renders.
  values: arrayOf(shape({})).isRequired,
  children: node,
};

ContextWrapper.defaultProps = {
  children: null
};

Hydrator.propTypes = {
  // The element when scrolled to will be hydrated.
  id: string.isRequired,
  className: string,
  children: node,
  placeholder: node,
  // Wait for a promise to complete before hydrating.
  waitFor: oneOfType([func, array]),
  // Delay intersection observer from firing. Useful to give elements time
  // to shift down below the fold as other elements above get loaded.
  delay: number,
  // After a certain amount of time has passed, hydrate regardless of if user
  // has scrolled down to the element.
  timeout: number,
  // Starts Hydrating the component x pixels before it gets into the view port
  scrollBuffer: number,
  // React will try to patch a server/client mismatch. To force it to throw
  // away the ssr markup and just clean re-render, set this to false.
  patch: bool,
  // Preserves a value in context so that client/server mismatches dont happen
  // as a result of context changes before a user scrolls down. The first render
  // will use the preserved context value, then an immediate re-render follows
  // with the new values.
  preserveCtxVal: string,
  // Callback for when hydration is done. Useful for situations where a list
  // has changed its order since, and React is trying to patch it badly.
  afterHydrate: func,
  // allows an experience to conditionally hydrate, this is useful for components
  // in zone A to change the render pattern on desktop vs mobile
  immediateRender: bool,
  // a prop from the experience to let the hydrator know when to start observing
  initObserver: bool
};

Hydrator.defaultProps = {
  className: null,
  children: null,
  placeholder: null,
  waitFor: null,
  delay: null,
  timeout: null,
  scrollBuffer: 0,
  patch: true,
  preserveCtxVal: null,
  afterHydrate: () => { },
  immediateRender: false,
  initObserver: null
};
