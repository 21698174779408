import { useEffect, useState } from 'react';

const ECOREBATES_SCRIPT_URL = '//static.ecorebates.com/ui/widgets/thd/product.js';

const getEcoRebatesScript = () => document.querySelector(`script[src="${ECOREBATES_SCRIPT_URL}"]`);
const hasEcoRebatesScript = () => typeof window !== 'undefined' && Boolean(getEcoRebatesScript());
const loadEcoRebatesScript = () => {
  const ecoRebatesScript = document.createElement('script');
  ecoRebatesScript.type = 'text/javascript';
  ecoRebatesScript.async = true;
  ecoRebatesScript.src = ECOREBATES_SCRIPT_URL;
  document.body.appendChild(ecoRebatesScript);
};

export const useEcoRebates = ({
  itemId,
  large,
  optionalText,
  rebateClasses,
  uniqueId,
  zipCode,
}) => {
  const [isConfigured, setIsConfigured] = useState(false);

  // this one configures the window._ecr and keeps it up to date
  useEffect(() => {
    if (typeof window === 'undefined' || !uniqueId || !zipCode) return;

    const ecoRebatesConfig = window._ecr || {};
    const options = ecoRebatesConfig.options || {};
    const uiContext = large || options.uiContext === 'PDP' ? 'PDP' : 'PLP';
    if (options.headingText !== '') options.headingText = '';
    if (options.zip !== zipCode) options.zip = zipCode;
    if (options.openDetailsText !== optionalText) options.openDetailsText = optionalText;
    if (options.openDetailsClasses !== rebateClasses) options.openDetailsClasses = rebateClasses;
    if (options.uiContext !== uiContext) options.uiContext = uiContext;
    if (ecoRebatesConfig[uniqueId] !== itemId) ecoRebatesConfig[uniqueId] = itemId;

    ecoRebatesConfig.options = options;
    window._ecr = ecoRebatesConfig;

    setIsConfigured(true);
  }, [
    itemId,
    large,
    optionalText,
    rebateClasses,
    uniqueId,
    zipCode,
  ]);

  useEffect(() => {
    if (!isConfigured || hasEcoRebatesScript()) return;
    loadEcoRebatesScript();
  }, [isConfigured]);
};
