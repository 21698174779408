/* eslint-disable no-undef */
import { asyncCallWithTimeout, TIMEOUT_MESSAGE } from './asyncTimeout';

/**
 * @function getVariationsFromAdobe
 * @param {String[]} mboxes
 * @returns {Promise<[{ id: String, mbox: String }]>}
 */
export const getVariationsFromAdobe = async ({ mboxes }) => {
  const ttExist = typeof _tt !== 'undefined';
  const adobeExist = typeof adobe !== 'undefined';

  try {
    if (ttExist) {
      const adobeMboxIds = await asyncCallWithTimeout(
        _tt.utils.getDecisionScopesByName(mboxes),
        5000
      );

      const ids = adobeMboxIds.propositions?.map(({ items, scope }) => {
        const data = items && items.length > 0 ? items[0].data : null;
        const content = data && data.content && data.content?.length > 0 ? data.content[0] : null;
        const id = content && content.variationsIds ? content.variationsIds[0] : null;

        return {
          id,
          mbox: scope,
        };
      });

      return ids;
    }

    if (adobeExist) {
      const adobeMboxIds = await asyncCallWithTimeout(
        adobe.target.getOffers({
          consumerId: mboxes.join(', '),
          request: {
            execute: {
              mboxes: mboxes.map((mbox, index) => ({ name: mbox, index })),
            },
          },
          timeout: 5000,
        }),
        5000
      );

      const ids = adobeMboxIds?.execute?.mboxes?.map(({ options, name }) => {
        const opts = options ? options[0] : null;
        const content = opts && opts.content ? opts.content[0] : null;
        const id = content && content.variationsIds ? content.variationsIds[0] : null;

        return {
          id,
          mbox: name,
        };
      }) || [];

      return ids;
    }

    return [];
  } catch (error) {
    if (error.message === TIMEOUT_MESSAGE) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.timeout', {
        source: 'Adobe Target',
        error: error.message,
        payload: { body: {}, ids: mboxes },
      });
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.adobeError', {
        error: { message: error?.message, stack: error?.stack },
      });
    }
    /* eslint-disable-next-line no-console, no-unused-expressions */
    window.isDebugMode && console.error(error);
    return [];
  }
};
