import React, { useCallback, useContext } from 'react';
import { func } from 'prop-types';
import classnames from 'classnames';
import { SpecialSavings, ConfirmedFilled, InfoFilled } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Alert, IconButton, Popover, Typography } from '@one-thd/sui-atomic-components';
import { useNewRelicLogging } from '../../../../hooks/useNewRelicLogging';
import { useBogoImages } from '../../../../hooks/useBogoImages';
import { useMessage } from '../../../../hooks/useMessage';
import { usePromoModel } from '../../../../hooks/usePromoModel';
import { usePromoPresentation } from '../../../../hooks/usePromoPresentation';
import { PodCallToActionDataModel } from '../../../../models/PodCallToActionDataModel';
import {
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS,
  MESSAGE_KEY, MESSAGE_STATE
} from '../../../../utils/constants';
import { BogoProductImageList } from '../../../BogoProductImageList/BogoProductImageList';
import { InfoContainer } from '../../../InfoContainer/InfoContainer';
import { LinkDisplay } from '../../../LinkDisplay/LinkDisplay';
import { MessagingContainer } from '../../../MessagingContainer/MessagingContainer';
import { usePromoCart } from '../../../../hooks/usePromoCart';
import { usePromoMessage } from '../../../../hooks/usePromoMessage';
import { LinkButton } from '../../../LinkButton/LinkButton';

export const PodCallToAction = ({ onLoad }) => {
  const {
    isTouchDevice,
    setIsDrawerOpen,
    isSummaryPopoverOpen,
    isSummaryDrawerOpen,
    setIsSummaryPopoverOpen,
    setIsSummaryDrawerOpen,
  } = usePromoPresentation();
  const {
    analyticsComponent,
    isAnchorFulfillable,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isAwaitingData,
    isBmsm,
    isDollarThresholdBogo,
    isForwardsBogo,
    isMsb,
    listHasFulfillment,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    isExpiring,
    subExperience,
    isAnchorAppliance,
    experienceTag,
    anchorItemId,
  } = usePromoModel();
  const { qualifyingImages, rewardImages } = useBogoImages();
  const {
    firstListCartQuantity, secondListCartQuantity, firstListCartValue, secondListCartValue, hasAnchorItemInCart
  } = usePromoCart();
  const { messageState } = usePromoMessage();
  const { sendDataToNewRelic } = useNewRelicLogging();

  const { channel } = useContext(ExperienceContext);

  const podCtaShopThisOfferText = useMessage(MESSAGE_KEY.podCtaShopThisOffer);
  const podCtaSuccessMessage = useMessage(MESSAGE_KEY.podCtaSuccessMessage);
  const podCtaWarningMessage = useMessage(MESSAGE_KEY.podCtaWarningMessage);
  const podCtaQualifyWarningMessage = useMessage(MESSAGE_KEY.podCtaQualifyWarningMessage);
  const podCtaRewardWarningMessage = useMessage(MESSAGE_KEY.podCtaRewardWarningMessage);
  const podCtaErrorMessage = useMessage(MESSAGE_KEY.podCtaErrorMessage);
  const podCtaExpDate = useMessage(MESSAGE_KEY.podCtaExpDate);
  const podCtaAlmostWarningMessage = useMessage(MESSAGE_KEY.podCtaAlmostWarningMessage);
  const progressBarInfoText = useMessage(MESSAGE_KEY.progressBarInfoText);
  const productCategoryLimits = useMessage(MESSAGE_KEY.productCategoryLimits);

  const openSummaryDrawer = useCallback(() => {
    onLoad();
    setIsSummaryDrawerOpen(true);
    setIsDrawerOpen(true);
  }, [onLoad, setIsSummaryDrawerOpen, setIsDrawerOpen]);

  const almostQualifyingWarning = firstListCartQuantity > 0
    && firstListCartQuantity < src1EligibilityCriteria?.minPurchaseQuantity;
  const underQualifyingThresholdMet = firstListCartQuantity < src1EligibilityCriteria?.minPurchaseQuantity;
  const qualifyingThresholdMet = firstListCartQuantity >= src1EligibilityCriteria?.minPurchaseQuantity;
  const underRewardThresholdMet = secondListCartQuantity < tgt1EligibilityCriteria?.minPurchaseQuantity;
  const rewardThresholdMet = secondListCartQuantity >= tgt1EligibilityCriteria?.minPurchaseQuantity;
  const qualifyingThresholdForAmountMet = firstListCartValue >= src1EligibilityCriteria?.minPurchaseAmount;
  const underQualifyingThresholdForAmountMet = firstListCartValue < src1EligibilityCriteria?.minPurchaseAmount;
  const rewardThresholdForAmountMet = secondListCartValue >= tgt1EligibilityCriteria?.minPurchaseAmount;
  const underRewardThresholdForAmountMet = secondListCartValue < tgt1EligibilityCriteria?.minPurchaseAmount;

  const bogoPromoMet = (isForwardsBogo || isBackwardsBogo) && hasAnchorItemInCart && qualifyingThresholdMet;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsSummaryPopoverOpen(true);
  }, [setAnchorEl, setIsSummaryPopoverOpen]);

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
    setIsSummaryPopoverOpen(false);
  }, [setAnchorEl, setIsSummaryPopoverOpen]);

  const open = Boolean(isSummaryPopoverOpen);
  const id = open ? 'simple-popover' : undefined;

  let showUnfulfillable = false;
  switch (subExperience) {
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
  case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
  case BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
    showUnfulfillable = messageState === MESSAGE_STATE.anchorItemUnfulfillable
      || messageState === MESSAGE_STATE.src1ListUnfulfillable;
    break;
  default: break;
  }

  const enabled = isAnchorFulfillable || isAwaitingData || listHasFulfillment;
  const canPreload = !isTouchDevice;
  const showIcon = isForwardsBogo
    || isBackwardsBogo
    || isDollarThresholdBogo
    || isMsb
    || isForwardsB1gy
    || isForwardsBxg1;
  const showBogoImageList = isForwardsBogo || isBackwardsBogo || isForwardsB1gy || isForwardsBxg1;
  const showInfoContainer = isMsb || isBmsm;
  const showShopThisOffer = (Boolean(enabled) && !isBmsm) || !isAnchorAppliance;
  const getSuccessMessageThresholdValue = isDollarThresholdBogo ? (
    qualifyingThresholdForAmountMet && rewardThresholdForAmountMet
  ) : (qualifyingThresholdMet && (rewardThresholdMet || bogoPromoMet));
  const showPodCtapodCtaSuccessMessage = (
    isForwardsB1gy || isForwardsBxg1 || isBackwardsBogo || isForwardsBogo || isDollarThresholdBogo
  ) && getSuccessMessageThresholdValue && podCtaSuccessMessage;
  const showPodCtaWarningMessage = (
    isForwardsB1gy || isForwardsBxg1 || isBackwardsBogo || isForwardsBogo
  ) && podCtaWarningMessage && underQualifyingThresholdMet && underRewardThresholdMet;
  const showAlmostPodCtaWarningMessage = isForwardsBxg1 && podCtaAlmostWarningMessage && almostQualifyingWarning;
  const getQualifyWarningThresholdValue = isDollarThresholdBogo ? (
    underQualifyingThresholdForAmountMet && rewardThresholdForAmountMet
  ) : (underQualifyingThresholdMet && rewardThresholdMet);
  const showPodCtaQualifyWarningMessage = (
    isForwardsB1gy || isForwardsBxg1 || isDollarThresholdBogo
  ) && (podCtaQualifyWarningMessage) && getQualifyWarningThresholdValue;
  const getRewardWarningThresholdValue = isDollarThresholdBogo ? (
    qualifyingThresholdForAmountMet && underRewardThresholdForAmountMet
  ) : (qualifyingThresholdMet && underRewardThresholdMet);
  const showPodCtaRewardWarningMessage = (
    isForwardsB1gy || isForwardsBxg1 || isDollarThresholdBogo
  ) && podCtaRewardWarningMessage && getRewardWarningThresholdValue;
  const showPodCtaErrorMessage = (isForwardsB1gy || isForwardsBxg1) && podCtaErrorMessage;
  const showAlert = showPodCtaQualifyWarningMessage
    || showPodCtaRewardWarningMessage
    || showPodCtaWarningMessage
    || showPodCtaErrorMessage
    || showAlmostPodCtaWarningMessage
    || showAlmostPodCtaWarningMessage;
  const showExpDate = !showUnfulfillable && isExpiring && podCtaExpDate;
  const showCategoryAlert = isBmsm && isAnchorAppliance;

  const handleFocus = canPreload ? onLoad : undefined;
  const handleMouseEnter = canPreload ? onLoad : undefined;
  const handleClick = useCallback(() => {
    const props = { actionName: 'PromotionProductsPodCallToActionClick',
      experienceTag,
      subExperience,
      anchorItemId };
    sendDataToNewRelic(props);
    if (isSummaryPopoverOpen && !isSummaryDrawerOpen) {
      return;
    }
    if (!isSummaryPopoverOpen) {
      onLoad();
      setIsDrawerOpen(true);
    }
    // Analytics
    window.LIFE_CYCLE_EVENT_BUS.trigger('promotion-products-cta.click', {
      component: analyticsComponent,
      section: 'buybox',
      target: 'see eligible items',
    });
    // this prevents analytics from over-reporting. this event should only be reported once per page load
    window.LIFE_CYCLE_EVENT_BUS.off('promotion-products-cta.click');
  }, [
    onLoad,
    setIsDrawerOpen,
    isSummaryPopoverOpen,
    isSummaryDrawerOpen,
    analyticsComponent,
    anchorItemId,
    experienceTag,
    subExperience,
  ]);

  const promoItemCallToActionClasses = classnames(
    ' sui-w-full sui-items-center sui-py-1 sui-flex sui-gap-1 sui-box-border sui-bg-primary',
    'focus-visible:sui-outline focus-visible:sui-outline-4 focus-visible:sui-outline-warning',
    {
      'sui-px-4 sui-py-4 sui-mb-4': channel === 'mobile'
    }
  );

  const promoCallToActionClasses = classnames(
    'sui-relative sui-w-full sui-flex  sui-gap-3 sui-box-border sui-bg-primary',
    'focus-visible:sui-outline focus-visible:sui-outline-4 focus-visible:sui-outline-warning',
    {
      'sui-px-4 sui-py-4 sui-mb-4': channel === 'mobile'
    }
  );

  const podContentsJSX = (
    <div className="sui-w-full sui-flex sui-flex-col sui-gap-1">
      {showExpDate && (
        <Typography align="left" component="span" data-testid="promo-exp-date" variant="body-xs">
          <span style={{ paddingLeft: '33px' }}>{podCtaExpDate}</span>
        </Typography>
      )}
      <div className="sui-w-full sui-flex sui-gap-2 sui-pb-2">
        {showIcon && <SpecialSavings />}
        <div className="sui-grow sui-flex sui-flex-col sui-gap-2">
          <div className="sui-grow sui-flex sui-flex-row sui-justify-center sui-items-center sui-gap-2">
            <MessagingContainer />
          </div>
          {showBogoImageList
            && <BogoProductImageList qualifyingImages={qualifyingImages} rewardImages={rewardImages} />}
          {showInfoContainer && <InfoContainer onLoad={onLoad} />}
          {showShopThisOffer && (
            <span className="sui-text-left">
              <LinkDisplay>{podCtaShopThisOfferText}</LinkDisplay>
            </span>
          )}
        </div>
      </div>
      {showAlert && (
        <Alert
          className="sui-border-1 sui-border-solid sui-border-primary sui-rounded-base"
          status={showPodCtaErrorMessage ? 'error' : 'warning'}
        >
          {showPodCtaQualifyWarningMessage && podCtaQualifyWarningMessage}
          {showPodCtaWarningMessage && podCtaWarningMessage}
          {showPodCtaRewardWarningMessage && podCtaRewardWarningMessage}
          {showAlmostPodCtaWarningMessage && podCtaAlmostWarningMessage}
          {showPodCtaErrorMessage && podCtaErrorMessage}
        </Alert>
      )}
      {showPodCtapodCtaSuccessMessage && (
        <div className="sui-text-left">
          <IconButton aria-label="Open Promo Summary Drawer" color="success" icon={ConfirmedFilled} />
          <Typography component="span" color="success">{podCtaSuccessMessage}</Typography>
        </div>
      )}
    </div>
  );

  return enabled ? (
    <>
      <button
        aria-label="Open Promotion Drawer"
        className={promoCallToActionClasses}
        data-testid="pod-call-to-action"
        onClick={handleClick}
        onFocus={handleFocus}
        onMouseEnter={handleMouseEnter}
        type="button"
      >
        {podContentsJSX}
      </button>
      {showCategoryAlert && (
        <>
          <div role="presentation" className={promoItemCallToActionClasses} onClick={handleClick}>
            <div role="presentation" onClick={handleClick} className="sui-flex sui-items-center sui-gap-1 sui-pr-2">
              <IconButton aria-label="Open Promo Summary Drawer" color="info" icon={InfoFilled} />
              <Typography
                align="left"
                variant="body-base"
                aria-describedby={id}
              >
                {progressBarInfoText}
                {' '}
                <LinkButton
                  onClick={handleClickPopover}
                  className="sui-align-baseline sui-underline"
                >
                  Learn more.
                </LinkButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  title="Product Category Limits"
                >
                  <Typography variant="body-xs">
                    {productCategoryLimits}&nbsp;
                    <LinkButton
                      onClick={openSummaryDrawer}
                      className="sui-align-baseline sui-underline"
                    >
                      <Typography variant="body-xs">See promotion details.</Typography>
                    </LinkButton>
                  </Typography>
                </Popover>
              </Typography>
            </div>
          </div>
          <div className={promoItemCallToActionClasses}>
            <LinkButton onClick={handleClick}>{podCtaShopThisOfferText}</LinkButton>
          </div>
        </>
      )}
    </>
  ) : (
    <div
      className={promoCallToActionClasses}
      data-testid="pod-call-to-action"
    >
      {podContentsJSX}
    </div>
  );
};

PodCallToAction.displayName = 'PodCallToAction';

PodCallToAction.propTypes = {
  onLoad: func.isRequired,
};

PodCallToAction.dataModel = PodCallToActionDataModel;
