import React from 'react';
import { Skeleton, SkeletonBlock, useBreakpoint } from '@one-thd/sui-atomic-components';

export function CardItemsPlaceholder() {
  const skeletonContentProps = {
    className: 'sui-flex sui-overflow-hidden sui-w-full sui-gap-6'
  };

  const imagePlaceholder = [...Array(3)].map((key, i) => (
    <SkeletonBlock key={`card-items-image-placeholder-${i}`} height={14} width={14} />
  ));

  return (
    <Skeleton SkeletonContentProps={skeletonContentProps}>
      {imagePlaceholder}
    </Skeleton>
  );
}
