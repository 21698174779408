import React, { useEffect } from 'react';
import { string, bool, func } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { CtaSection } from '../common/CtaSection';
import { buildSamLink } from '../../utils/home-services-cta-utils';
import { SCHEDULE_A_MEASURE_CONTACT_NUMBER } from '../../utils/home-services-cta-constants';
import { dataModel } from './dataModel';

const ScheduleAMeasureCta = ({
  itemId, onClick, hideContactInfo, target, enableNewTab, isFooter
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('schedule-a-measure.ready'); }, []);

  const { data } = useDataModel('product', {
    skip: !itemId,
    ssr: true,
    variables: {
      itemId
    }
  });

  if (!itemId) {
    return null;
  }

  const {
    product: {
      identifiers: {
        specialOrderSku,
        storeSkuNumber
      } = {}
    } = {}
  } = data || {};

  return (
    <CtaSection
      buttonText="Schedule a Measure"
      contactNumber={SCHEDULE_A_MEASURE_CONTACT_NUMBER}
      buttonLink={buildSamLink(specialOrderSku || storeSkuNumber)}
      isFooter={isFooter}
      hideContactInfo={hideContactInfo}
      openContentInDrawer={!enableNewTab}
      onClick={onClick}
      target={target}
      dataComponent="ScheduleAMeasureCta"
    />
  );
};

ScheduleAMeasureCta.displayName = 'ScheduleAMeasureCta';

ScheduleAMeasureCta.dataModel = dataModel;

ScheduleAMeasureCta.propTypes = {
  enableNewTab: bool,
  hideContactInfo: bool,
  isFooter: bool,
  itemId: string.isRequired,
  onClick: func,
  target: string
};

ScheduleAMeasureCta.defaultProps = {
  enableNewTab: false,
  hideContactInfo: false,
  isFooter: false,
  onClick: () => {},
  target: '_blank',
};

export { ScheduleAMeasureCta };