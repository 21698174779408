import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useId from '../private/hooks/useId';
import { FormController } from '../form/FormController';
import { Stepper } from './Stepper';
import { FormLabel } from '../form/FormLabel';
import { FormHelpMessage } from '../form/FormHelpMessage';
import { FormStatusMessage } from '../form/FormStatusMessage';

/**
 * `QuantityPicker` display a numerical input field along with add / subtract buttons that allow
 * the selection of a numeric value.
 *
 * `QuantityPicker` is a useful component that bundles `FormController`, `FormLabel`, `Stepper`, `FormStatusMessage`
 * and `FormHelpMessage` components together for form handling.
 *
 * Related components: [Stepper](#stepper), [FormLabel](#formlabel), [FormController](#formcontroller),
 * [FormHelpMessage](#formhelpmessage), [FormStatusMessage](#formstatusmessage).
 *
 * Usage:
 *
 * ```jsx
 * import { QuantityPicker } from '@one-thd/sui-atomic-components';
 * ```
 */
const QuantityPicker = React.forwardRef((props, ref) => {

  const {
    min,
    max,
    step,
    label,
    id: idProp,
    disableDefaultStatus = false,
    defaultValue,
    FormHelpMessageProps,
    FormLabelProps,
    FormStatusMessageProps,
    statusMessage: statusMessageProp,
    helpMessage: helpMessageProp,
    value,
    onChange,
    disabled = false,
    optional,
    required,
    requiredMessage,
    status: statusProp,
    StepperProps,
    minimumMessage,
    maximumMessage,
    ...other
  } = props;

  const [currentValue, setCurrentValue] = useState(value);
  const [status, setStatus] = useState();
  const [statusMessage, setStatusMessage] = useState(null);

  const stepperChangeHandler = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
    setCurrentValue(newValue);
  };

  useEffect(() => {
    if (disableDefaultStatus) {
      setStatus(statusProp);
      return;
    }

    const currentValueNum = Number(currentValue);

    if (required && currentValue === '') {
      setStatus('error');
      setStatusMessage(requiredMessage);
    } else if (currentValueNum > max) {
      setStatus('error');
      setStatusMessage(maximumMessage);
    } else if (currentValueNum < min) {
      setStatus('warning');
      setStatusMessage(minimumMessage);
    } else {
      setStatus(null);
      setStatusMessage('');
    }
  },
  [
    required,
    currentValue,
    min,
    max,
    minimumMessage,
    maximumMessage,
    requiredMessage,
    disableDefaultStatus,
    statusProp
  ]);

  const id = useId(idProp);
  const formLabelId = label && id ? `${id}-label` : undefined;
  const statusMessageId = (statusMessage || statusMessageProp) && id ? `${id}-status-message` : undefined;
  const helpMessageId = helpMessageProp && id ? `${id}-status-message` : undefined;

  const defaultMessaging = () => {
    return (
      <>
        {status === 'error' && (
          <FormStatusMessage status={status} id={statusMessageId}>
            {statusMessage}
          </FormStatusMessage>
        )}
        {status === 'warning' && (
          <FormHelpMessage id={statusMessageId}>
            {statusMessage}
          </FormHelpMessage>
        )}
      </>
    );
  };

  const nonDefaultMessaging = () => {
    return (
      <>
        {statusMessageProp && (
          <FormStatusMessage id={statusMessageId} {...FormStatusMessageProps}>
            {statusMessageProp}
          </FormStatusMessage>
        )}
        {helpMessageProp && (
          <FormHelpMessage id={helpMessageId} {...FormHelpMessageProps}>
            {helpMessageProp}
          </FormHelpMessage>
        )}
      </>
    );
  };

  return (
    <FormController
      disabled={disabled}
      ref={ref}
      status={status}
      {...other}
    >
      <FormLabel
        htmlFor={id}
        id={formLabelId}
        optional={optional}
        required={required}
        {...FormLabelProps}
      >
        {label}
      </FormLabel>
      <Stepper
        aria-describedby={statusMessageId}
        min={min}
        max={max}
        value={value}
        defaultValue={defaultValue}
        onChange={stepperChangeHandler}
        disabled={disabled}
        id={id}
        step={step}
        {...StepperProps}
      />
      {disableDefaultStatus ? nonDefaultMessaging() : defaultMessaging()}
    </FormController>
  );
});

QuantityPicker.displayName = 'QuantityPicker';

QuantityPicker.propTypes = {
  /**
   * Minimum value allowed using the left button
   */
  min: PropTypes.number.isRequired,
  /**
   * Maximum value allowed using the right button
   */
  max: PropTypes.number.isRequired,
  /**
   * Increment to be applied when using the buttons
   */
  step: PropTypes.number,
  /**
   * The default value for the input element.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If true, the default status messaging is disabled.
   */
  disableDefaultStatus: PropTypes.bool,
  /**
   * If true, the buttons and text input will be inactive.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * Props applied to the [`FormHelpMessage`](#formhelpmessage) component.
   * Only applied when disableDefaultStatus is set to true.
   */
  FormHelpMessageProps: PropTypes.object,
  /**
   * Props applied to the [`FormLabel`](#formlabel) component.
   */
  FormLabelProps: PropTypes.object,
  /**
   * Props applied to the [`FormStatusMessage`](#formstatusmessage) component.
   * Only applied when disableDefaultStatus is set to true.
   */
  FormStatusMessageProps: PropTypes.object,
  /**
   * id given to the text input
   */
  id: PropTypes.string,
  /**
   * Label to use above the buttons
   */
  label: PropTypes.string,
  /**
   * The help message content.
   * Only applied when disableDefaultStatus is set to true.
   */
  helpMessage: PropTypes.string,
  /**
   * If true, the text "(optional)" is displayed next to the label
   */
  optional: PropTypes.bool,
  /**
   * Adds onChange into Stepper component.
   */
  onChange: PropTypes.func,
  /**
   * If true, the * symbol is displayed next to the label
   */
  required: PropTypes.bool,
  /**
   * The states of validation for the TextField component.
   * Only applied when disableDefaultStatus is set to true.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The status message content.
   * Only applied when disableDefaultStatus is set to true.
   */
  statusMessage: PropTypes.string,
  /**
   * Props applied to the [`Stepper`](#stepper) component.
   */
  StepperProps: PropTypes.object,
  /**
   * `QuantityPicker` value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Message to show when `required` is `true` and the user deletes the value in the text input
   */
  requiredMessage: PropTypes.string,
  /**
   * Message to show when the user types in a number less than `min`
   */
  minimumMessage: PropTypes.string,
  /**
   * Message to show when the user types in a number greater than `max`
   */
  maximumMessage: PropTypes.string,
};

QuantityPicker.defaultProps = {
  step: 1,
  required: false,
  optional: false,
  disabled: false,
  requiredMessage: 'Required value',
  minimumMessage: 'Value is below minimum',
  maximumMessage: 'Value is above maximum'
};

export { QuantityPicker };
