import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

const ButtonGroup = ({
  className,
  children
}) => {

  return (
    <div className={cx('bttn--group', className)}>
      {children}
    </div>
  );
};

ButtonGroup.displayName = 'ButtonGroup';

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Extend Styles. */
  className: PropTypes.string
};

ButtonGroup.defaultProps = {
  className: null
};

export { ButtonGroup };
