import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LinearProgress = forwardRef((props, ref) => {

  const {
    components = {},
    color = 'primary',
    disableTransition = false,
    size = 'regular',
    value,
    ...other
  } = props;

  const LinearProgressRoot = components.Root || 'span';
  const LinearProgressTrack = components.Track || 'span';

  const rootProps = {};
  const inlineStyles = { root: {}, track: {} };

  if (value !== undefined) {
    rootProps['aria-valuenow'] = Math.round(value);
    rootProps['aria-valuemin'] = 0;
    rootProps['aria-valuemax'] = 100;
    let transform = value - 100;
    inlineStyles.track.transform = `translateX(${transform}%)`;
  }

  const rootClasses = classNames('sui-relative sui-rounded-lg sui-overflow-hidden sui-block sui-z-0 sui-bg-strong', {
    'sui-h-2': size === 'regular',
    'sui-h-4': size === 'large'
  });

  const trackClasses = classNames('sui-w-full sui-absolute sui-inset-y-0 sui-left-0 sui-origin-left', {
    'sui-duration-500 sui-ease-linear sui-delay-0 [transition-property:transform]': !disableTransition,
    'transition-none': disableTransition,
    'sui-bg-inverse': color === 'primary',
    'sui-bg-success': color === 'success',
    'sui-bg-danger-strong': color === 'danger',
    'sui-bg-warning-strong': color === 'warning'
  });

  return (
    <LinearProgressRoot
      className={rootClasses}
      role="progressbar"
      {...rootProps}
      ref={ref}
      {...other}
    >
      <LinearProgressTrack
        className={trackClasses}
        style={inlineStyles.track}
      />
    </LinearProgressRoot>
  );
});

LinearProgress.displayName = 'LinearProgress';

LinearProgress.propTypes = {
  components: PropTypes.shape({
    Root: PropTypes.elementType,
    Track: PropTypes.elementType,
  }),
  color: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
  size: PropTypes.oneOf(['regular', 'large']),
  disableTransition: PropTypes.bool,
  /**
   * A value between 0 and 100 that represents the percentage of completion.
   */
  value: PropTypes.number,
};

export { LinearProgress };
