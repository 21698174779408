export const akamaiImageResize = (imageUrlToTransform, size) => {
  const resizeParams = `Resize=(${size},${size})`;

  if (!imageUrlToTransform) {
    return imageUrlToTransform;
  }
  const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
  if (!isAkamaiHost) {
    return imageUrlToTransform;
  }
  if (!imageUrlToTransform.includes('?')) {
    return `${imageUrlToTransform}?im=${resizeParams}`;
  }
  if (imageUrlToTransform.includes('im=')) {
    const urlBeforeImParams = imageUrlToTransform.substring(0, imageUrlToTransform.indexOf('im='));
    const imAndRemainderParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
    const hasAdditionalParams = imAndRemainderParams.includes('&');
    if (hasAdditionalParams) {
      const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
      const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
      return `${urlBeforeImParams}${imParams};${resizeParams}${remainderOfParams}`;
    }
    return `${imageUrlToTransform};${resizeParams}`;
  }
  return `${imageUrlToTransform}&im=${resizeParams}`;
};
