import { createTheme } from '@one-thd/sui-atomic-components';

const DefaultTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: ''
    },
    text: {
      primary: ''
    }
  }
});

export { DefaultTheme };