import React, { useEffect, useContext } from 'react';
import {
  bool, number, string, shape
} from 'prop-types';
import { destroyYouTubePlayer, initializeYouTubePlayers, youTubePlayers } from '../../helpers/videoPlayerHelpers';
import { VideoPlayerContext } from '../../contexts/VideoPlayerContext';
import styles from '../video-player.module.scss';

export const YouTubePlayer = React.forwardRef((props, ref) => {
  const {
    autoPlay, className, height, width, videoSrc, videoStill, analyticsData
  } = props;
  const { autoPlayInViewport } = useContext(VideoPlayerContext) || {};
  const videoId = `thd-youtube-iframe-${videoSrc}`;
  const videoSource = `${videoSrc}?autoplay=${!!(autoPlay || videoStill || autoPlayInViewport)}&rel=0&enablejsapi=1`;

  useEffect(() => {
    if (!youTubePlayers[videoId]) {
      initializeYouTubePlayers({ analyticsData, muted: autoPlayInViewport, id: videoId });
    }

    return () => {
      destroyYouTubePlayer({ id: videoId });
    };
  }, []);

  return (
    <div
      className={styles['video-player__container']}
      style={{
        width,
        maxWidth: width
      }}
      data-component="YouTubePlayer"
    >
      <iframe
        id={videoId}
        ref={ref}
        className={className}
        src={videoSource}
        title={videoSrc}
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        height={height}
        width={width}
      />
    </div>
  );
});

YouTubePlayer.propTypes = {
  analyticsData: shape({}),
  autoPlay: bool,
  className: string,
  height: number,
  videoSrc: string,
  videoStill: bool,
  width: number,
};

YouTubePlayer.defaultProps = {
  analyticsData: null,
  autoPlay: false,
  className: null,
  height: null,
  videoSrc: null,
  videoStill: false,
  width: null
};