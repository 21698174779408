import React from 'react';
import PropTypes from 'prop-types';
import './delivery-options-drawer.scss';
import { Drawer, DrawerItem, DrawerNav } from '@thd-olt-component-react/drawer';
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithDayOfWeek,
  getDeliveryDateAvailability,
  isDeliveryTimelineTodayOrTomorrow
} from '../../helpers/utils';
import { DeliveryOptionCard } from './DeliveryOptionCard';
import {
  BODFS_DELIVERY,
  BUY_ONLINE_DELIVER_FROM_STORE,
  DRAWER_FOOTER,
  DRAWER_HEADER,
  SHIP_TO_HOME,
  STH_DELIVERY,
  SIX_HOUR_CAR_BODFS_MESSAGE,
  CHOOSE_BODFS_MESSAGE
} from '../../helpers/constants';

export const DeliveryDrawer = ({
  sthServices,
  bodfsService,
  isQuickViewOpen,
  setIsQuickViewOpen,
  channel,
  showTodayOrTomorrow = null
}) => {

  const checkTodayTomorrowDate = (startDate, endDate, formatDateWithRange = null) => {
    if (startDate === endDate && showTodayOrTomorrow) {
      return showTodayOrTomorrow(endDate, true, '', formatDate(endDate, false));
    }
    return formatDateWithRange ? formatDateWithRange(startDate, endDate) : '';
  };

  const sthDate = isDeliveryTimelineTodayOrTomorrow(sthServices[0]?.deliveryTimeline)
    ? capitalizeFirstLetter(getDeliveryDateAvailability(sthServices[0]?.deliveryTimeline)?.toLowerCase())
    : checkTodayTomorrowDate(sthServices[0]?.deliveryDates?.startDate,
      sthServices[0]?.deliveryDates?.endDate,
      formatDateWithDayOfWeek);

  const bodfsDate = getDeliveryDateAvailability(bodfsService[0]?.deliveryTimeline)?.toLowerCase();
  const hasSameDayCarDelivery = bodfsService[0]?.hasSameDayCarDelivery || false;
  const renderSixHourCarMessage = () => (
    <div className={`DeliveryDrawer__deliveryEstSixHour ${channel}`}>
      <span
        className={`DeliveryDrawer--delivery-express-truck-icon DeliveryDrawer--delivery-express-truck-icon--padding
      ${channel}`}
      />
      <span className={`DeliveryDrawer__sixHour ${channel}`}>
        {SIX_HOUR_CAR_BODFS_MESSAGE}&nbsp;
      </span>
      <span
        className={`DeliveryDrawer__sixHourNext ${channel}`}
      >{CHOOSE_BODFS_MESSAGE}
      </span>
    </div>
  );

  const renderBODFSMessage = () => (
    <div className={`DeliveryDrawer__deliveryEst ${channel}`}>{BODFS_DELIVERY}&nbsp;
      <span className={`DeliveryDrawer__date ${channel}`}>{capitalizeFirstLetter(bodfsDate)}</span>
    </div>
  );

  return (
    <div className={`DeliveryDrawer__drawerWrapper ${channel}`}>
      <Drawer
        open={isQuickViewOpen}
        onClose={() => setIsQuickViewOpen(false)}
        initialItem="delivery-quick-view"
        position="right"
        maxSize={channel === 'Mobile' ? 100 : 500}
        isMobile={channel === 'Mobile'}
      >
        <DrawerItem name="delivery-quick-view">
          <div className={`DeliveryDrawer__wrapper ${channel}`} data-component="DeliveryDrawer">
            <DrawerNav>
              <span className={`DeliveryDrawer__header ${channel}`}>{DRAWER_HEADER}</span>
            </DrawerNav>
            <div className={`DeliveryDrawer__divider ${channel}`} />
            <div className={`DeliveryDrawer__body ${channel}`}>

              {sthServices.length > 0 && (
                <div className={`DeliveryDrawer__deliveryType ${channel}`}>
                  <div className={`DeliveryDrawer__deliveryEst ${channel}`}>{STH_DELIVERY}&nbsp;
                    <span className={`DeliveryDrawer__date ${channel}`}>{sthDate}</span>
                  </div>
                  <div className={`DeliveryDrawer__cardsContainer ${channel}`}>
                    {
                      sthServices.map((deliveryOption, i) => {
                        return (
                          <DeliveryOptionCard
                            channel={channel}
                            deliveryType={SHIP_TO_HOME}
                            deliveryOption={deliveryOption}
                            key={i}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              )}

              {bodfsService.length > 0 && (
                <div className={`DeliveryDrawer__deliveryType ${channel}`}>
                  {hasSameDayCarDelivery ? renderSixHourCarMessage() : renderBODFSMessage()}
                  <div
                    className={hasSameDayCarDelivery ? `DeliveryDrawer__cardsContainerWithImage ${channel}`
                      : `DeliveryDrawer__cardsContainer ${channel}`}
                  >
                    {
                      bodfsService.map((deliveryOption, i) => {

                        const todayOrTomorrow = deliveryOption?.deliveryTimeline
                          ? isDeliveryTimelineTodayOrTomorrow(deliveryOption?.deliveryTimeline)
                          : checkTodayTomorrowDate(deliveryOption?.deliveryDates?.startDate,
                            deliveryOption?.deliveryDates?.endDate);
                        const dateAvailability = getDeliveryDateAvailability(deliveryOption?.deliveryTimeline);

                        return (
                          <DeliveryOptionCard
                            channel={channel}
                            deliveryType={BUY_ONLINE_DELIVER_FROM_STORE}
                            deliveryOption={deliveryOption}
                            isDeliveryTimelineTodayOrTomorrow={todayOrTomorrow}
                            deliveryDateAvailability={dateAvailability}
                            key={i}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              )}

              <div className={`DeliveryDrawer__deliveryFooter ${channel}`}>
                <span className={`DeliveryDrawer__deliveryFooterText ${channel}`}>
                  {DRAWER_FOOTER}
                </span>
              </div>
            </div>
          </div>
        </DrawerItem>
      </Drawer>
    </div>
  );
};

DeliveryDrawer.propTypes = {
  isQuickViewOpen: PropTypes.bool,
  setIsQuickViewOpen: PropTypes.func,
  sthServices: PropTypes.instanceOf(Array).isRequired,
  bodfsService: PropTypes.instanceOf(Array).isRequired,
  channel: PropTypes.string,
  showTodayOrTomorrow: PropTypes.func,
};

DeliveryDrawer.defaultProps = {
  isQuickViewOpen: false,
  setIsQuickViewOpen: () => {
  },
  channel: null,
  showTodayOrTomorrow: null,
};
