import React, { useContext } from 'react';
import { string, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ComponentPlaceholder } from '@thd-olt-component-react/loading-placeholder';

const RatingsAndReviewsPlaceholder = ({ color, showLoadingAnimation }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  return (
    <div data-component="RatingsAndReviewsPlaceholder">
      <ComponentPlaceholder
        height={isMobile ? 1100 : 1800}
        rows={isMobile ? 25 : 35}
        color={color}
        showLoadingAnimation={showLoadingAnimation}
      />
    </div>
  );
};

RatingsAndReviewsPlaceholder.propTypes = {
  color: string,
  showLoadingAnimation: bool
};

RatingsAndReviewsPlaceholder.defaultProps = {
  color: '#E0E0E0',
  showLoadingAnimation: false
};

export { RatingsAndReviewsPlaceholder };