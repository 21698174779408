import React, { useContext } from 'react';
import { string } from 'prop-types';
import { PLCCPod } from '@thd-olt-component-react/consumer-card-messaging';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { HomepageContext } from './context';

export function PrivateLabelCreditCard({ id }) {
  const { urlTmxId } = useContext(HomepageContext);
  return (
    <ErrorBoundary name="consumer-card-messaging">
      <div
        id={`plcc-php-${id}`}
        className="sui-h-full"
      >
        <ImpressionProvider
          data={{
            id,
            name: 'PLCCPod',
            component: 'PLCCPod',
            type: 'content',
            position: ''
          }}
        >
          <PLCCPod urlTmxId={urlTmxId} id={id} />
        </ImpressionProvider>
      </div>
    </ErrorBoundary>
  );
}

PrivateLabelCreditCard.propTypes = {
  id: string,
};

PrivateLabelCreditCard.defaultProps = {
  id: undefined,
};
