import PropTypes, {
  arrayOf, bool, func, shape, string, number, oneOf
} from 'prop-types';

export const EndCapPropType = {
  pageUrl: string.isRequired,
  channel: oneOf(['DESKTOP', 'MOBILE']),
  storeId: string,
  personalizedDealsUserid: string,
  customerType: oneOf(['B2C', 'B2B', 'B2X']),
  categoryGuid: string,
  entryId: string,
  isABTesting: bool,
  narrowBottom: bool,
  runQueryServerSide: bool,
  headerWeight: string,
  isUppercase: bool,
};

export const EndCapDefaultPropType = {
  channel: 'DESKTOP',
  storeId: '121',
  customerType: 'B2C',
  personalizedDealsUserid: '',
  categoryGuid: null,
  entryId: '',
  isABTesting: false,
  narrowBottom: false,
  runQueryServerSide: true,
  headerWeight: 'display',
  isUppercase: true,
};

export const HeaderSectionPropType = {
  header: string,
  start: string,
  end: string,
  bannerImage: string,
  headerWeight: string,
  isUppercase: bool,
  mobileAppBannerImg: string,
  headerLinkText: string,
  headerLinkUrl: string,
  timerDuration: number,
  badgeUrl: string,
  channel: string,
  subHeader: string,
  endCapData: shape({}),
  timer: shape({
    timeBombThreshold: string,
    daysLeftThreshold: string,
    dateDisplayThreshold: string,
    message: string
  })
};

export const HeaderSectionDefaultPropType = {
  header: '',
  start: '',
  end: '',
  bannerImage: '',
  headerWeight: 'display',
  isUppercase: true,
  mobileAppBannerImg: '',
  headerLinkText: '',
  headerLinkUrl: '',
  timerDuration: 0,
  badgeUrl: '',
  channel: '',
  subHeader: '',
  endCapData: null,
  timer: {
    timeBombThreshold: '',
    daysLeftThreshold: '',
    dateDisplayThreshold: '',
    message: ''
  }
};

export const NoProductsMessagePropType = {
  title: string,
  categoryName: string,
  url: string
};

export const NoProductsMessageDefaultPropType = {
  title: '',
  categoryName: '',
  url: ''
};

export const CategoryTabsPropType = {
  categories: arrayOf(shape({
    category: string,
    nValue: string,
    imageUrl: string,
    url: string,
    shopAllLink: string,
    exclude: arrayOf(string),
    displayImageOnMobileWeb: bool,
  })),
  nameOfEventAttribute: string,
  handleTabClick: func
};

export const CategoryTabsDefaultPropType = {
  nameOfEventAttribute: '',
  handleTabClick: func,
};

export const CategoryCarouselPropType = {
  channel: oneOf(['DESKTOP', 'MOBILE']),
  displayImageOnMobileWeb: bool,
  exclude: arrayOf(string),
  title: string,
  categoryName: string,
  categoryImageURL: string,
  categoryURL: string,
  categoryShopAllLink: string,
  componentName: string,
  endCapData: shape({
    products: arrayOf(shape)
  }),
  entryId: string,
  storeId: string
};

export const CategoryCarouselDefaultPropType = {
  channel: 'DESKTOP',
  displayImageOnMobileWeb: false,
  exclude: [],
  title: '',
  categoryName: '',
  categoryImageURL: '',
  categoryShopAllLink: '',
  categoryURL: '',
  componentName: '',
  entryId: '',
  storeId: '121'
};

export const CategoryBlockPropType = {
  categoryName: string.isRequired,
  categoryImageURL: string.isRequired,
  properUrl: string.isRequired
};
