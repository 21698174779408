import React from 'react';
import Responsive from '~/react-responsive';

export const DisplayUtil = ({ mobileView, tabletView, desktopView }) => {
  const DesktopView = ({ children }) => <Responsive minWidth={1024}>{children}</Responsive>;
  const TabletView = ({ children }) => (
    <Responsive minWidth={768} maxWidth={1023}>
      {children}
    </Responsive>
  );
  const MobileView = ({ children }) => <Responsive maxWidth={767}>{children}</Responsive>;

  return (
    <>
      <MobileView>
        {mobileView}
      </MobileView>
      <TabletView>
        {tabletView}
      </TabletView>
      <DesktopView>
        {desktopView}
      </DesktopView>
    </>
  );
};
