import React, { useCallback, useState } from 'react';

export const useNodeRef = () => {
  const [node, setNode] = useState(null); // <HTMLElement | null>

  /**
   * Callback ref to be called by React whenever the ref gets attached
   * to a different node or becomes null.
   * @see https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   */
  const callbackRef = useCallback((value) => {
    setNode(value);
  }, []);

  return [callbackRef, node];
};