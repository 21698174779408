import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Cabinet = createSvgIcon(
  <>
    <path d="M8.25 10.5V15H6v-4.5h2.25Z" />
    <path d="M12 0H0v24h24V6.75H12V0Zm9.75 14.25H12V9h9.75v5.25Zm-12-12v19.5h-7.5V2.25h7.5ZM12 16.5h9.75v5.25H12V16.5Z" />
  </>,
  'Cabinet'
);

export { Cabinet };