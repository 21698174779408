import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SpecialSavings = createSvgIcon(
  <>
    <path d="M18 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
    <path d="M12.704.045 20.092 3H24v10.594L13.528 23.982 3.033 14.288l.88-.892-3.888-7.14L12.704.045Zm.092 2.46L3.125 7.244l2.437 4.477 8.558-8.686-1.324-.53Zm2.3 2.745-8.83 8.962 7.206 6.656 8.278-8.212V5.25h-6.654Z" />
  </>,
  'SpecialSavings'
);

export { SpecialSavings };