import {
  getArrivalDate,
  getBulletPoints,
  getDeliveryDateAvailability,
  isDeliveryTimelineTodayOrTomorrow
} from './utils';

const shipping = (data) => {

  const model = {
    shipModes: [],
    dynamicETA: {},
    arrivalDate: '',
    arrivalDateIsSingleDate: false,
    startDate: '',
    endDate: '',
    state: '',
    hasFreeShipping: false,
    isFreeShippingEligible: false,
    freeShippingThreshold: '',
    locations: []
  };

  if (!data) return model;
  model.state = data?.state;
  const { services = [] } = data;

  if (services.length === 0) {
    return model;
  }
  // This is to move the express deliery servive to bottom of the array
  const sthServices = services.filter((service) => { return service?.type?.toLowerCase() === 'sth'; });
  const bodfsService = services.filter((service) => { return service?.type?.toLowerCase() === 'express delivery'; });
  const allShipModes = sthServices.concat(bodfsService);
  allShipModes.map((service, index) => {
    if (index === 0) {
      model.group = service?.mode?.group;
      model.arrivalDate = getArrivalDate(
        service?.deliveryDates?.startDate,
        service?.deliveryDates?.endDate
      );
      model.arrivalDateIsSingleDate = (service?.deliveryDates?.startDate === service?.deliveryDates?.endDate);
      model.startDate = service?.deliveryDates?.startDate;
      model.endDate = service?.deliveryDates?.endDate;
      model.hasFreeShipping = !service?.totalCharge;
      model.isFreeShippingEligible = !!service?.freeDeliveryThreshold;
      model.freeShippingThreshold = service?.freeDeliveryThreshold;
      if (service?.dynamicEta?.minutes) {
        const { hours, minutes } = service.dynamicEta;
        const hoursInt = parseInt(hours); // eslint-disable-line radix
        const minutesInt = parseInt(minutes); // eslint-disable-line radix
        model.dynamicETA.hasCountdownTimer = true;
        model.dynamicETA.totalMinutes = `${(hoursInt * 60) + minutesInt}`;
        model.dynamicETA.totalHours = '0';
      }
      if (service?.locations?.length > 0) {
        model.locations.push({
          type: service.locations[0]?.type,
          inventory: service.locations[0]?.inventory
        });
      }
    }
    if (service?.type?.toLowerCase() === 'express delivery') {
      model.isDeliveryTimelineTodayOrTomorrow = isDeliveryTimelineTodayOrTomorrow(service?.deliveryTimeline);
      model.deliveryTimeline = service?.deliveryTimeline;
      model.totalCharge = service?.totalCharge;
      model.isBodfsShipMode = true;
      if (service?.locations?.length > 0) {
        model.locations.push({
          type: service.locations[0]?.type,
          inventory: service.locations[0]?.inventory
        });
      }
    }
    return model.shipModes.push({
      modeGroup: service?.mode?.group,
      description: service?.mode?.desc,
      hasBulletPoints: !!service?.mode?.longDesc,
      bulletPoints: getBulletPoints(service?.mode?.longDesc),
      isFreeShippingEligible: !!service?.freeDeliveryThreshold,
      freeShipEligibleMsg: `FREE with $${service?.freeDeliveryThreshold} Order`,
      isFreeShipping: service?.totalCharge === 0,
      shipCharge: `$${service?.totalCharge?.toFixed(2)}`,
      isBodfsShipMode: service?.type?.toLowerCase() === 'express delivery',
      arrivalDate: getArrivalDate(
        service?.deliveryDates?.startDate,
        service?.deliveryDates?.endDate
      ),
      deliveryDateAvailability: getDeliveryDateAvailability(service?.deliveryTimeline),
      isDeliveryTimelineTodayOrTomorrow: isDeliveryTimelineTodayOrTomorrow(service?.deliveryTimeline)
    });
  });
  return model;
};

export {
  shipping
};