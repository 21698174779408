import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const BuyingGuide = createSvgIcon(
  <>
    <path d="M12 1.294A4.114 4.114 0 0 0 9 0H0v19.5h7.875c.667 0 1.476.279 2.117.732.685.483.883.94.883 1.143v.375h2.25c0-.203.115-.522.41-.891.288-.36.633-.623.843-.728l1.006-.503-1.006-2.012-1.006.503a3.4 3.4 0 0 0-.247.136V4.125c0-1.036.84-1.875 1.875-1.875h6.75v1.5H24V0h-9a4.114 4.114 0 0 0-3 1.294ZM7.875 17.25H2.25v-15H9c1.036 0 1.875.84 1.875 1.875v14c-.886-.526-1.96-.875-3-.875Z" />
    <path d="M21 6v2.25h2.25v2.25h-3.375c-.48 0-.977.185-1.342.49-.36.299-.533.658-.533 1.01 0 .532.227.848.645 1.14.228.16.497.298.803.437.086.039.19.083.299.131.215.093.453.197.631.286.133.066.262.122.425.192.12.051.256.11.426.187.35.159.769.365 1.166.643C23.227 15.598 24 16.532 24 18c0 1.187-.654 2.138-1.415 2.751-.462.371-1.01.66-1.585.83V24h-2.25v-2.25H16.5V19.5h3.375c.385 0 .892-.174 1.298-.501.402-.324.577-.686.577-.999 0-.532-.227-.848-.645-1.14a4.993 4.993 0 0 0-.803-.437l-.299-.131a14.123 14.123 0 0 1-.631-.286 8.077 8.077 0 0 0-.425-.192c-.119-.051-.256-.11-.426-.187a7.183 7.183 0 0 1-1.166-.643c-.832-.582-1.605-1.516-1.605-2.984 0-1.148.577-2.101 1.342-2.74a4.407 4.407 0 0 1 1.658-.858V6H21Z" />
  </>,
  'BuyingGuide'
);

export { BuyingGuide };