/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useCallback } from 'react';
import { Typography, Radio, RadioGroup } from '@one-thd/sui-atomic-components';
import { PacDrawerContext } from '../../contexts/PacDrawerContext';
import { analyticsStash } from '../utils/AnalyticsUtils';
import { DRAWER_ANALYTICS, OPTION_TILES_CONSTANTS as CONSTANTS } from '../../core/DrawerConstants';

/* Utils */
const useGenerateProps = (isLocked) => {
  const { isMobile } = React.useContext(PacDrawerContext);
  const STYLES = CONSTANTS.STYLES;
  const determineBreakpoint = isMobile ? 'MW' : 'DTW';
  const determineLock = isLocked ? 'LOCKED' : 'UNLOCKED';
  return {
    isMobile,
    iconSize: STYLES.ICON[determineBreakpoint][determineLock],
    titleVariant: STYLES.TITLE[determineBreakpoint][determineLock],
    containerStyles: STYLES.CONTAINER[determineBreakpoint][determineLock],
    linkStyle: STYLES.LINK[determineBreakpoint][determineLock],
  };
};

/* Components */
const TileIcon = ({ icon, isLocked }) => {
  const { iconSize } = useGenerateProps(isLocked);
  return (
    <div className="sui-flex sui-items-center">
      <img width={iconSize} height={iconSize} src={icon} alt="OptionTile Icon" />
    </div>
  );
};

const TileTitle = ({ title, isLocked }) => {
  const { titleVariant } = useGenerateProps(isLocked);
  const textWeight = isLocked ? undefined : 'bold';
  return (
    <Typography variant={titleVariant} weight={textWeight}>
      {title}
    </Typography>
  );
};

const TileLink = ({ onClick, isInvisible, isLocked }) => {
  const { isMobile } = React.useContext(PacDrawerContext);
  const { linkStyle } = useGenerateProps(isLocked);
  const Ele = (props) => {
    const { children } = props;
    const eleProps = { ...props };
    delete eleProps.children;
    return isInvisible
      ? <span {...eleProps}>{children}</span>
      : <button type="button" {...eleProps}>{children}</button>;
  };
  const bottomStyle = isMobile ? '36px' : '40px';
  const absoluteProps = { position: 'absolute', bottom: bottomStyle, left: `${linkStyle}px` };
  return (
    <Ele
      className={'sui-underline sui-cursor' + (isInvisible ? ' sui-invisible' : '')}
      onClick={onClick}
      disabled={isInvisible}
      style={isInvisible ? {} : absoluteProps}
    >{CONSTANTS.TILE_LINK}
    </Ele>
  );
};

const TileRadio = ({ thisOptionValue }) => {
  const { iconSize } = useGenerateProps(false);
  return (
    <div className="sui-flex sui-items-center" style={{ height: `${iconSize}px` }}>
      <Radio value={thisOptionValue} inputAttributes={{ 'aria-label': thisOptionValue }} />
    </div>
  );
};

const TileContainer = ({ children, isLocked, optionValue }) => {
  const { containerStyles } = useGenerateProps(isLocked);
  const onClick = () => document.querySelector(`input[value='${optionValue}']`)?.click();
  return (
    <button
      type="button"
      onClick={onClick}
      data-testid="options-tile-container"
      className={`sui-flex sui-w-full ${containerStyles} sui-mb-4 sui-bg-white
      sui-border-1 sui-border-solid sui-border-primary sui-rounded-base`}
    >
      {children}
    </button>
  );
};

const OptionsTile = ({
  icon,
  title,
  onClick,
  optionValue,
  isLocked,
}) => (
  <div className="sui-relative">
    <TileContainer isLocked={isLocked} optionValue={optionValue}>
      {!isLocked && <TileRadio thisOptionValue={optionValue} />}
      <div className="sui-flex sui-gap-5">
        <TileIcon icon={icon} isLocked={isLocked} />
        <div className="sui-flex sui-flex-col sui-justify-around sui-items-start">
          <TileTitle title={title} isLocked={isLocked} />
          <TileLink onClick={onClick} isInvisible isLocked={isLocked} />
        </div>
      </div>
    </TileContainer>
    <TileLink onClick={onClick} isLocked={isLocked} />
  </div>
);

/* Exports */
export const usePerkOptionsRetriever = (tierId = '') => {
  const { infoPerks } = useContext(PacDrawerContext);
  const tiersList = infoPerks.infoPerksData.program.tiers;
  const getByCurrentTier = useCallback(() => tiersList.find((tier) => !!tier.currentTier), []);
  const getByTierId = useCallback(() => tiersList.find((tier) => tier.tierId === tierId), []);
  const currentTier = getByTierId() || getByCurrentTier() || {};
  const tierPerks = currentTier?.tierPerks || [{}];
  return { tierPerks: tierPerks[0], options: tierPerks[0].options };
};

export const useOptionsTileGroup = ({ setDetailsDrawerParams, optionsList = [], isLocked = false }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (event) => setSelectedValue(event.target.value);
  const handleClickViewDetails = (option) => {
    setDetailsDrawerParams({ showingDetails: true, optionObj: option });
    analyticsStash('pacDrawer', isLocked
      ? DRAWER_ANALYTICS.perkSelectionDrawer.viewDetailsButtonLocked
      : DRAWER_ANALYTICS.perkSelectionDrawer.viewDetailsButtonUnlocked
    );
  };
  const Options = () => optionsList.map((option) => {
    const title = (option.amount ? `$${option.amount} ` : '') + option.perkTitle;
    return (
      <OptionsTile
        key={option.tierRewardId}
        icon={option.perkImageUrl}
        title={title}
        onClick={() => handleClickViewDetails(option)}
        optionValue={option.tierRewardId}
        isLocked={isLocked}
      />
    );
  });
  const OptionGroup = () => (
    <RadioGroup
      aria-labelledby="perk-options-tile-group"
      name="perk-options-tile-group"
      value={selectedValue}
      onChange={handleChange}
    >
      <Options />
    </RadioGroup>
  );
  return { OptionsTileGroup: OptionGroup, selectedValue, };
};

export const OptionsTileGroup = ({ optionsList = [], isLocked = false }) => {
  const { OptionsTileGroup: OptionGroup } = useOptionsTileGroup({ optionsList, isLocked });
  return <OptionGroup />;
};

/*
  Example implementation
  TODO: Remove before merge to master
*/
// export const OptionTileDemo = () => {
//   const tierId = '153d3233-5135-4531-84ee-8c4d75984d6b';
//   const { tierPerks, options } = usePerkOptionsRetriever(tierId);
//   const { OptionsTileGroup: OptionGroupUnlocked, selectedValue } = useOptionsTileGroup({ optionsList: options });
//   React.useEffect(() => console.log({ selectedValue }), [selectedValue]);
//   const OptionGroupLocked = () => <OptionsTileGroup optionsList={options} isLocked />;
//   return (
//     <>
//       <OptionGroupUnlocked />
//       <OptionGroupLocked />
//     </>
//   );
// };
