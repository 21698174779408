import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const CarouselHeader = ({ title, subtitle }) => (
  <div className="sui-mt-4 sui-mb-2">
    <div className="sui-mb-3">
      <Typography variant="h2">{title}</Typography>
      { subtitle && (
        <div className="sui-mb-3">
          <Typography variant="h3">{subtitle}</Typography>
        </div>
      )}
    </div>
  </div>
);

CarouselHeader.propTypes = {
  title: string,
  subtitle: string,
};

CarouselHeader.defaultProps = {
  title: '',
  subtitle: null,
};

export { CarouselHeader };
