/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, } from '@one-thd/sui-atomic-components';
import helpers from '../../helpers/price-helper';
import '../../styles/price-stencil-override.scss';

export const ClearancePopoverContent = ({
  message, originalPrice, clearanceValue, dollarOff, percentageOff
}) => {
  const isClearanceValueSameOrMoreOrginalPrice = (clearanceValue >= originalPrice);
  const originalPriceComponents = helpers.parsePriceValue(originalPrice);
  const clearancePriceComponents = helpers.parsePriceValue(clearanceValue);
  const dollarOffComponents = helpers.parsePriceValue(dollarOff);
  const saveDollarOffPercentage = percentageOff !== 0 ? `(${percentageOff}%)` : '';
  const saveDollarOffText =
    `Save $${dollarOffComponents.dollars}.${dollarOffComponents.cents} ${saveDollarOffPercentage} `;

  return (
    <Typography
      variant="body-xs"
      data-automation-id="clearancePopoverMessages"
    >
      <span className="sui-text-base">
        {message}
      </span>
      <div className="sui-w-80 sui-pt-4 sui-pb-4">
        <span className="sui-flex">
          <div
            className={'sui-pr-1 sui-h3-bold sui-tracking-normal sui-normal-case'
              + ' sui-line-clamp-unset sui-flex sui-mt-[-8px]'}
          >
            {(clearancePriceComponents !== '')
              && (
                <>
                  <span
                    className="sui-ml-2px, sui-mr-2px sui-font-bold clearance-price-tootip-dollar"
                  >
                    $
                  </span>
                  <span className="sui-text-3xl sui-font-bold clearance-price">{clearancePriceComponents.dollars}</span>
                  <span
                    className="sui-text-[1.1rem] sui-ml-2px, sui-mr-2px sui-font-bold clearance-price-cents"
                  >
                    {clearancePriceComponents.cents}
                  </span>
                </>
              )}
          </div>
          <div>
            <div className="sui-text-subtle sui-pt-1">
              {(originalPriceComponents !== '' && !isClearanceValueSameOrMoreOrginalPrice)
                && (
                  <span className="sui-text-[11px]">
                    <span>Online Price </span>
                    <span className="sui-line-through">
                      ${originalPriceComponents.dollars}.{originalPriceComponents.cents}
                    </span>
                  </span>
                )}
            </div>
            {(dollarOffComponents !== '') && (
              <div className="sui-text-success sui-text-[11px]">
                {saveDollarOffText}
              </div>
            )}
          </div>
        </span>

      </div>
    </Typography>
  );
};

ClearancePopoverContent.propTypes = {
  message: PropTypes.string.isRequired,
  originalPrice: PropTypes.number.isRequired,
  clearanceValue: PropTypes.number.isRequired,
  dollarOff: PropTypes.number.isRequired,
  percentageOff: PropTypes.number.isRequired,
};

ClearancePopoverContent.defaultProps = {};
