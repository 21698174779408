import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Chat = createSvgIcon(
  <>
    <path d="M15 0H0v15h3v3.02L8.537 15H15V0ZM7.963 12.75 5.25 14.23v-1.48h-3V2.25h10.5v10.5H7.963Z" />
    <path data-color="secondary" d="M17.25 7.5H24V21h-3v3.02L15.463 21H10.5v-3.75h2.25v1.5h3.287l2.713 1.48v-1.48h3v-9h-4.5V7.5Z" />
  </>,
  'Chat'
);

export { Chat };