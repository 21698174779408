import React from 'react';
import { TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { Card, CardBody } from '~/@one-thd/sui-atomic-components';

export const ListTilePlaceholder = () => {
  return (
    <div className="sui-w-full" data-component="ListTilePlaceholder">
      <Card className="sui-grid sui-rounded-md sui-shadow-base">
        <TextBlock rows={2} color="#E1E1E1" widths={[50, 35]} />
        <CardBody>
          <div className="sui-grid sui-grid-cols-3 sui-gap-2">
            {
              [1, 2, 3].map((number, index) => {
                return (
                  <div
                    // eslint-disable-next-line tailwindcss/no-arbitrary-value
                    className="sui-w-full"
                    key={`list-tile-placeholder-thumbnail-${number}`}
                  >
                    <div className="sui-aspect-square sui-w-full sui-bg-strong" />
                  </div>
                );
              })
            }
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

ListTilePlaceholder.displayName = 'ListTilePlaceholder';