import { useMemo } from 'react';

import { usePromoModel } from './usePromoModel';
import {
  DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS,
  NUM_PREVIEW_IMAGES
} from '../utils/constants';
import { getProductImage } from '../utils/promo-utils';

export const usePreviewImages = () => {
  const {
    allListProductsAndAnchor,
    displayableSecondListItemIds,
    subExperience
  } = usePromoModel();

  return useMemo(() => {
    const previewImages = [];

    switch (subExperience) {
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff:
    case FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff:
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetOne:
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetDollarOff:
    case DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS.buyMinAmountGetPercentageOff: {
      previewImages.push(
        ...displayableSecondListItemIds.slice(0, NUM_PREVIEW_IMAGES).map((itemId) => {
          return {
            url: getProductImage(allListProductsAndAnchor.get(itemId)),
            itemId
          };
        })
      );
      break;
    }
    default: {
      break;
    }
    }

    return {
      previewImages,
    };
  }, [
    allListProductsAndAnchor,
    displayableSecondListItemIds,
    subExperience,
  ]);
};
