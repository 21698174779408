import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SelectionControlBase } from '../private/components/selection/SelectionControlBase';
import useRadioGroup from './useRadioGroup';
import { RadioButtonChecked } from '../private/icons/RadioButtonChecked';
import { RadioButtonUnchecked } from '../private/icons/RadioButtonUnchecked';
import { areEqualValues, createChainedFunction } from '../utils';

/**
 * `Radio` buttons are use as part of a `RadioGroup` when there is a list of two or more options
 * that are mutually exclusive and the user must selet exactly one choice.
 *
 * Related components: [RadioGroup](#radiogroup), [SelectionControlLabel](#selectioncontrollabel), [Tile](#tile)
 *
 * Usage:
 *
 * ```jsx
 * import { Radio } from '@one-thd/sui-atomic-components';
 * ```
 */
const Radio = React.forwardRef((props, ref) => {

  const {
    alignment = 'center',
    checked: checkedProp,
    name: nameProp,
    onChange: onChangeProp,
    tabIndex: tabIndexProp,
    ...other
  } = props;

  const radioGroup = useRadioGroup();

  let checked = checkedProp;
  const onChange = createChainedFunction(onChangeProp, radioGroup && radioGroup.onChange);
  let name = nameProp;
  let tabIndex = tabIndexProp;

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = areEqualValues(radioGroup.value, props.value);
    }
    if (typeof tabIndex === 'undefined' && radioGroup.value) {
      tabIndex = checked ? '0' : '-1';
    }
    if (typeof name === 'undefined') {
      name = radioGroup.name;
    }
  }

  const spanClasses = classNames('sui-flex sui-justify-center sui-relative sui-box-border sui-bg-transparent sui-align-middle sui-outline-0 sui-border-0 sui-m-0 sui-select-none sui-appearance-none sui-no-underline sui-h-9 sui-pl-1 sui-pr-1', {
    'sui-cursor-pointer sui-tap-highlight-transparent': !props.disabled,
    'sui-cursor-default sui-pointer-events-none': props.disabled,
    'sui-items-center': alignment === 'center',
    'sui-items-start': alignment === 'top',
  });

  const classes = {
    span: spanClasses,
    input: 'sui-peer sui-cursor-inherit sui-absolute sui-opacity-0 sui-w-full sui-h-full sui-top-0 sui-left-0 sui-m-0 sui-p-0 sui-z-10'
  };

  const iconClasses = classNames('sui-relative sui-flex sui-rounded-full sui-p-1 sui-text-xl sui-bg-transparent peer-hover:sui-bg-brand-subtle peer-focus-visible:sui-bg-warning peer-focus-visible:sui-fill-primary', {
    'sui-fill-brand': checked && !props.disabled,
    'sui-fill-brand-subtle': checked && props.disabled,
    'sui-fill-medium peer-hover:sui-fill-unchecked-strong': !checked
  });

  let backgroundColor = '';
  if (checked || (!checked && !props.disabled)) {
    backgroundColor = 'primary';
  } else if (!checked && props.disabled) {
    backgroundColor = 'inactive';
  }

  const checkedIcon = (
    <span className={iconClasses}>
      <RadioButtonChecked size="inherit" color="inherit" rounded backgroundColor={backgroundColor} />
    </span>
  );

  const uncheckedIcon = (
    <span className={iconClasses}>
      <RadioButtonUnchecked size="inherit" color="inherit" rounded backgroundColor={backgroundColor} />
    </span>
  );

  return (
    <SelectionControlBase
      type="radio"
      classes={classes}
      name={name}
      checked={checked}
      onChange={onChange}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      role="radio"
      ref={ref}
      tabIndex={tabIndex}
      {...other}
    />
  );
});

Radio.displayName = 'Radio';

Radio.propTypes = {
  /**
   * Controls the vertical alignment of the Label and RadioButton.
   */
  alignment: PropTypes.oneOf(['top', 'center']),
  /**
   * If `true`, the component is filled.
   */
  checked: PropTypes.bool,
  /**
   * The default checked value for the radio element.
   */
  defaultChecked: PropTypes.bool,
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The id attribute that is added to the radio element
   */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * Attributes that are added to the radio element.
   */
  inputAttributes: PropTypes.object,
  /**
   * The onChange event handler that is added to the radio element.
   */
  onChange: PropTypes.func,
  /**
   * The value attribute that is added to the radio element.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  /**
   * The name attribute that is added to the radio element.  If name is not provided,
   * it will take the RadioGroup name
   */
  name: PropTypes.string,
  /**
   * The tabIndex attribute that is added to the radio element.
   */
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Radio.defaultProps = {
  alignment: 'center',
  defaultChecked: false,
  disabled: false,
};

export { Radio };
