import React, { useContext } from 'react';
import { string, number } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { CapabilitiesTile as CapabilitiesTileComponent } from '@thd-olt-component-react/capabilities-tile';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from './context/HomepageContext';
import { CUSTOMER_TYPES } from '../utils';

export function CapabilitiesTile({
  id,
  layoutRendererPrerenderedChildIndex
}) {
  const { customerType, proCustomerSegment } = useContext(HomepageContext);
  const isB2B = customerType === CUSTOMER_TYPES.B2B;

  let proAnalyticsData = {};
  if (isB2B) {
    proAnalyticsData.isB2B = true;
    proAnalyticsData.templates = proCustomerSegment;
  }

  return (
    <ErrorBoundary name="capabilitiesTile">
      <QueryProvider key={id} cacheKey={id}>
        <CapabilitiesTileComponent
          componentId={id}
          componentClass="CapabilitiesTile"
          componentPosition={layoutRendererPrerenderedChildIndex + 1}
        />
      </QueryProvider>
    </ErrorBoundary>
  );
}

CapabilitiesTile.propTypes = {
  id: string,
  layoutRendererPrerenderedChildIndex: number
};

CapabilitiesTile.defaultProps = {
  id: undefined,
  layoutRendererPrerenderedChildIndex: undefined
};