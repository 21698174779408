import {
  arrayOf, extend, params, shape, string
} from '@thd-nucleus/data-sources';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';

export const dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSources: string(),
    itemId: string(),
    specificationGroup: arrayOf(shape({
      specTitle: string(),
      specifications: arrayOf(shape({
        specName: string(),
        specValue: string()
      }))
    }))
  })
}, KeyProductFeatures);
