import { useConfigService } from '@thd-nucleus/experience-context';

export const getHostName = () => {
  let host = 'https://www.homedepot.com/';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com/' : origin;
  }
  return host;
};

export const getHostCURL = (perkPage) => {
  const hostName = window.location.hostname;
  const host = `https://${hostName}${perkPage}`;
  return host;
};

export const PerksUrl = () => {
  const isNewPerksEnabled = useConfigService('ENABLE_NEW_PERKS_PAGE');
  const perksPrefix = isNewPerksEnabled === 1 ? '/myaccount' : '/b2b/account/view';
  return getHostCURL(`${perksPrefix}/proXtraPerks`);
};

export const getImageURL = (perkPage) => {
  return `https://assets.thdstatic.com/images/v1/loyaltyBenefits/${perkPage}`;
};

export const getAccrualData = (perkSummary) => {
  const responseData = perkSummary && perkSummary?.accrualData;
  const currentYear = new Date().getFullYear();
  const accrualData = Array.isArray(responseData) && responseData.find((data) => data.year === currentYear);
  return accrualData || {};
};

export const isObjectEmpty = (dataObject) => {
  return (
    dataObject
    && Object.keys(dataObject).length === 0
    && Object.getPrototypeOf(dataObject) === Object.prototype
  );
};

export const THD_CUSTOMER = () => {
  return typeof window !== 'undefined' ? window?.THDCustomer?.default : {};
};

export const EXPERIENCE_CONTEXT = () => {
  return typeof window !== 'undefined' ? window.EXPERIENCE_CONTEXT : {};
};

export const getUserRole = () => {
  return THD_CUSTOMER()?.role || 'PURCHASER';
};

export const formatPhoneNumber = (phoneNumberString) => {
  let phoneNumber = phoneNumberString;
  if (phoneNumberString?.startsWith('1-')) phoneNumber = phoneNumberString.slice(2);
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};
