import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TextBlock } from './TextBlock';
import { RoundShape } from './RoundShape';

const MediaBlock = (props) => {
  const { className, style, color, rows } = props;

  const classes = ['media-block', className].filter((clss) => clss).join(' ');

  return (
    <div className={classes} style={{ ...style, display: 'flex' }}>
      <RoundShape
        color={color}
        style={{ minHeight: 55, width: 55, minWidth: 55, marginRight: 10 }}
      />
      <TextBlock color={color} rows={rows} />
    </div>
  );
};

MediaBlock.propTypes = {
  rows: PropTypes
    .number.isRequired,
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string
};

MediaBlock.defaultProps = {
  style: {},
  className: ''
};

export { MediaBlock };
