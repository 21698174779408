function isNullOrUndefined(value) {
  return value == null || value === 'undefined';
}

function assignDefaultId(parent) {
  if (!parent.id) {
    // eslint-disable-next-line no-param-reassign
    parent.id = parent.name ? `DEF_${parent.name.replace(/\W+/g, '')}` : 'na';
  }
}

export function impressionFallbackCheck(data, parent) {
  const dataProperties = ['id', 'component', 'position'];
  const parentProperties = ['id', 'component', 'name'];

  if (
    dataProperties.some((prop) => isNullOrUndefined(data[prop]))
    || parentProperties.some((prop) => isNullOrUndefined(parent[prop]))
  ) {
    throw new Error(
      'ImpressionProvider or useImpression is missing a required value inside its data object'
    );
  }

  assignDefaultId(parent);

  return { data, parent };
}