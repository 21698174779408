import React, { useContext } from 'react';
import { string, number, bool, shape } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Spotlight as SpotlightComponent } from '@thd-olt-component-react/spotlight';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from './context/HomepageContext';
import { CUSTOMER_TYPES } from '../utils';

export function Spotlight({
  id,
  layoutRendererPrerenderedChildIndex,
  useB2bStyles,
}) {
  const { customerType, proCustomerSegment } = useContext(HomepageContext);
  const spotlightPosition = layoutRendererPrerenderedChildIndex + 1;
  const isB2B = customerType === CUSTOMER_TYPES.B2B;

  let proAnalyticsData = {};
  if (isB2B) {
    proAnalyticsData.isB2B = true;
    proAnalyticsData.templates = proCustomerSegment;
  }

  if (!spotlightPosition) {
    return (
      <ErrorBoundary name="spotlight">
        <QueryProvider key={id} cacheKey={id}>
          <ImpressionProvider
            data={{
              id,
              name: 'Spotlight',
              component: 'Spotlight',
              type: 'content',
              position: 1,
            }}
          >
            <SpotlightComponent
              componentId={id}
              componentClass="Spotlight"
              showDescription
              componentPosition={1}
              useB2bStyles={useB2bStyles}
              proAnalyticsData={proAnalyticsData}
            />
          </ImpressionProvider>
        </QueryProvider>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary name="spotlight">
      <QueryProvider key={id} cacheKey={id}>
        <SpotlightComponent
          componentId={id}
          componentClass="Spotlight"
          showDescription
          boldTitle
          componentPosition={spotlightPosition}
          useB2bStyles={useB2bStyles}
          proAnalyticsData={proAnalyticsData}
        />
      </QueryProvider>
    </ErrorBoundary>
  );
}

Spotlight.propTypes = {
  id: string,
  layoutRendererPrerenderedChildIndex: number,
  useB2bStyles: bool,
};

Spotlight.defaultProps = {
  id: undefined,
  layoutRendererPrerenderedChildIndex: undefined,
  useB2bStyles: false,
};
