import React from 'react';
import classNames from 'classnames';
import './tooltip.scss';

export const getTooltipContent = (textToShow, channel) => {
  const tooltipCss = classNames('tooltip-box__' + channel);
  const tooltip = (
    <div className={tooltipCss}>
      <div className="tooltip-box__body tooltip-box__dark">
        <p>{textToShow}</p>
      </div>
    </div>
  );

  return tooltip;
};