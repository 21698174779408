import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingIcon } from '../private/icons/LoadingIcon';
import TileContext from '../private/components/tile/TileContext';
import { ButtonBase } from '../private/components/button/ButtonBase';

/**
 * `Tile` is a selector component used as an alternative to `Radio` button, `Dropdown` menu or `Checkbox`,
 * offering more options of UI customization using pre-built or custom sub-components.
 *
 * Related components: [TileContent](#tilecontent), [TileGroup](#tilegroup), [TileMedia](#tilemedia),
 * [TileText](#tiletext), [Radio](#radio), [Dropdown](#dropdown), [Checkbox](#checkbox).
 *
 * Usage:
 *
 * ```jsx
 * import { Tile } from '@one-thd/sui-atomic-components';
 * ```
 */
const Tile = React.forwardRef((props, ref) => {

  const {
    children,
    disabled = false,
    grow = false,
    loading = false,
    onChange,
    onClick,
    selected,
    unavailable = false,
    value,
    ...other
  } = props;

  const handleChange = (event) => {
    if (onClick) {
      onClick(event, value);
      if (event.defaultPrevented) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  const availableCls = !unavailable && classNames('sui-bg-primary sui-border-solid sui-rounded-base', {
    'sui-text-primary hover:sui-outline hover:sui-outline-2 hover:-sui-outline-offset-2 focus-visible:sui-outline focus-visible:sui-outline-input-focus focus-visible:sui-outline-4 focus-visible:-sui-outline-offset-4': !disabled,
    'sui-text-inactive sui-border-input-inactive': disabled,
    'sui-border-1 sui-p-[7px]': !selected,
    'sui-border-2 sui-p-[6px]': selected,
    'sui-border-input-active hover:sui-outline-input-active hover:sui-bg-subtle focus-visible:sui-bg-subtle': selected && !disabled,
    'sui-border-input-primary hover:sui-outline-button-hover-accent': !selected && !disabled,
    'sui-flex-grow': grow
  });

  const unavailableCls = unavailable && classNames('sui-bg-primary sui-text-subtle sui-tile-dashed-border sui-rounded-base sui-outline-none sui-border-0 sui-p-2', {
    'sui-text-primary hover:[--sui-dashed-width:2px] focus-visible:[--sui-dashed-width:4px] focus-visible:[--sui-dashed-color:theme("borderColor.input.focus")]': !disabled,
    'sui-text-inactive [--sui-dashed-color:theme("borderColor.input.inactive")]': disabled,
    '[--sui-dashed-width:1px]': !selected,
    '[--sui-dashed-width:2px]': selected,
    '[--sui-dashed-color:theme("borderColor.input.active")] hover:[--sui-dashed-color:theme("borderColor.input.active")] hover:sui-bg-subtle focus-visible:sui-bg-subtle': selected && !disabled,
    '[--sui-dashed-color:theme("borderColor.input.primary")] hover:[--sui-dashed-color:theme("borderColor.button.hover-accent")]': !selected && !disabled,
    'sui-flex-grow': grow
  });

  return (
    <ButtonBase
      className={unavailable ? unavailableCls : availableCls}
      disabled={disabled}
      ref={ref}
      onClick={handleChange}
      onChange={onChange}
      value={value}
      aria-pressed={selected}
      {...other}
    >
      <TileContext.Provider value={{ unavailable, disabled, selected, loading }}>
        {loading ? (
          <>
            <span className="sui-absolute sui-self-center sui--translate-x-1/2 sui-left-1/2 sui-fill-primary">
              <LoadingIcon color="inherit" />
            </span>
            <div className="sui-invisible">
              {children}
            </div>
          </>
        ) : (
          <>
            {children}
          </>
        )}
      </TileContext.Provider>
    </ButtonBase>
  );
});

Tile.displayName = 'Tile';

Tile.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the Tile will grow to fill the available space.
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * If `true`, the Tile will be rendered in a loading state.
   * @default false
   */
  loading: PropTypes.bool,
  /**
   * Callback fired when the state changes.
   */
  onChange: PropTypes.func,
  /**
   * Callback fired when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * If `true`, the tile is rendered in an active state.
   */
  selected: PropTypes.bool,
  /**
   * If `true`, the tile is rendered in an unavailable state.
   * @default false
   */
  unavailable: PropTypes.bool,
  /**
   * The value to associate with the tile when selected in a TileGroup.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
};

export { Tile };
