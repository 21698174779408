import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Clock = createSvgIcon(
  <>
    <path d="M13.125 11.46V5.25h-2.25v7.29l5.05 4.041 1.406-1.757-4.206-3.365Z" />
    <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Z" />
  </>,
  'Clock'
);

export { Clock };