import {
  useDataModel,
  params,
  string,
  id,
  shape,
  number,
  customType,
  arrayOf,
} from '@thd-nucleus/data-sources';
import { getStoreNumber } from '../helpers';

export function InstoreOrderDetails({ order, children, customerInfo }) {
  const storeNumber = getStoreNumber(order);
  const variables = {
    customerAccountId: customerInfo.customerAccountId,
    salesDate: order?.salesDate,
    storeNumber,
    transactionId: order?.transactionId,
    registerNumber: order?.registerNumber,
    groupAndSortBy: {
      groupBy: 'fulfillmentType',
    },
    searchType: 'inStore'
  };

  const queryOptions = {
    variables: {
      userId: customerInfo?.userId,
      request: variables,
      headers: {
        tmxProfileId: customerInfo.sessionId,
        channelId: customerInfo.channelId,
      }
    },
    context: { withAuth: true },
  };

  const { data, loading, error } = useDataModel(
    'orderDetails',
    queryOptions
  );

  return children({ data, loading });
}

InstoreOrderDetails.dataModel = {
  orderDetails: params({
    userId: id().isRequired(),
    headers: customType('HeaderExtras').shape({
      tmxProfileId: string(),
      channelId: string()
    }),
    request: customType('OrderDetailsRequest!').shape({
      customerAccountId: string(),
      storeNumber: number(),
      transactionId: number(),
      registerNumber: number(),
      salesDate: string(),
      searchType: string(),
      groupAndSortBy: shape({
        groupBy: string(),
      }),
    }),
  }).shape({
    lineItems: arrayOf(
      shape({
        itemDescFromCatalog: string(),
        quantity: number(),
        imageUrl: string(),
        skuNumber: number(),
      })
    ),
    statusDescription: string(),
  }),
};
