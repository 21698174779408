import {
  arrayOf,
  bool,
  element,
  func,
  oneOfType,
  string,
  number,
} from 'prop-types';
import React, {
  createContext,
  useEffect,
  useState,
} from 'react';
import styles from './drawer.module.scss';
import { DrawerPosition } from './drawer-utils/DrawerPosition';
import { getDrawerPositionStyles, isPositionLeftOrRight } from './drawer-utils/utility';
import { Mask } from './mask/Mask';

export const DrawerContext = createContext({});

const Drawer = ({
  open,
  position,
  onClose,
  maxSize,
  children,
  initialItem,
  hideMask,
  isMobile,
  zIndex,
  enableScroll,
  isFlex,
  persistOnOrientationChange
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(open);
  const [drawerRoute, setDrawerRoute] = useState([initialItem]);
  const [screenWidth, setScreenWidth] = useState(typeof window !== 'undefined'
    ? (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
    : null);
  const [screenHeight, setScreenHeight] = useState(typeof window !== 'undefined'
    ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
    : null);
  const [orientationChanged, setOrientationChanged] = useState(false);

  // TODO: Remove this functionality - sharedDrawer is not exposed and is also not used in this component
  const [sharedDrawer, setSharedDrawer] = useState({});

  /**
   * Used for closing the entire drawer
   * This is also available in useDrawer hook for children to close
   * the drawer based on the business logic
   * @param {*} event
   */
  const closeDrawer = (event, fromCloseButton) => {
    if (event) {
      event.preventDefault();
    }
    if (!enableScroll) {
      document.body.style.overflow = 'auto';
    }
    setIsDrawerOpen(false);
    setDrawerRoute([initialItem]);
    if (onClose) {
      onClose(fromCloseButton);
    }
  };

  /**
   * Enabled navigation between the drawers.
   * Children can invoke this method from the useDrawer hook and navigate.
   * @param {*} drawer - This is the name of the drawer item
   */
  const setCurrentDrawer = (drawer) => {
    // This is to address when the re-render happens
    if (drawerRoute[drawerRoute.length - 1] !== drawer) {
      const newDrawerRoute = [...drawerRoute];
      newDrawerRoute.push(drawer);
      setDrawerRoute(newDrawerRoute);
    }
  };

  // Ignoring orientationChange in test coverage, doesn't seem testable from vanilla js
  const portraitOrientationMatch = typeof window !== 'undefined' && window.matchMedia('(orientation: portrait)');
  const landscapeOrientationMatch = typeof window !== 'undefined' && window.matchMedia('(orientation: landscape)');
  /* istanbul ignore next */
  const adjustScreenSizeIfEventMatches = (event) => {
    if (event.matches) {
      if (isPositionLeftOrRight(position)) {
        setScreenWidth(typeof window !== 'undefined'
          ? (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
          : null);
      } else {
        setScreenHeight(typeof window !== 'undefined'
          ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
          : null);
      }
    }
  };
  /* istanbul ignore next */
  const closeDrawerIfEventMatches = (event) => {
    setOrientationChanged(true);
    if (event.matches && !persistOnOrientationChange) {
      closeDrawer();
    }
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (orientationChanged) {
      setOrientationChanged(false);
    }
  }, [orientationChanged]);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('drawer.close', () => {
      closeDrawer(null, true);
    });
    if (isMobile && typeof window !== 'undefined') {
      portraitOrientationMatch.addEventListener('change', adjustScreenSizeIfEventMatches);
      landscapeOrientationMatch.addEventListener('change', adjustScreenSizeIfEventMatches);
    }
  }, []);

  useEffect(() => {
    setIsDrawerOpen(open);
    setCurrentDrawer(initialItem);
    if (!enableScroll) {
      if (open) {
        // Page Scrollbar is removed for the drawer unless enabled
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }

  }, [open, initialItem]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      portraitOrientationMatch.addEventListener('change', closeDrawerIfEventMatches);
      landscapeOrientationMatch.addEventListener('change', closeDrawerIfEventMatches);
    }
  }, [initialItem]);

  // clear junk on unmount
  useEffect(() => {
    return () => {
      if (typeof window !== 'undefined') {
        if (isMobile) {
          portraitOrientationMatch.removeEventListener('change', adjustScreenSizeIfEventMatches);
          landscapeOrientationMatch.removeEventListener('change', adjustScreenSizeIfEventMatches);
        }
        portraitOrientationMatch.removeEventListener('change', closeDrawerIfEventMatches);
        landscapeOrientationMatch.removeEventListener('change', closeDrawerIfEventMatches);
      }

      if (!enableScroll) {
        document.body.style.overflow = 'auto';
      }
    };
  }, []);

  /**
   * This method will be invoked by the children to pass
   * data between the drawers. This is exposed using
   * the hook useDrawer
   * @param {*} data
   */
  const setSharedState = (data) => {
    const newState = {
      ...sharedDrawer,
      ...data
    };
    setSharedDrawer(newState);
  };

  /**
   * Method to route back to the previous drawer.
   * This will be used by the default DrawerNav/DrawerHeader
   */
  const setPreviousDrawer = () => {
    if (drawerRoute.length === 1) {
      closeDrawer();
    } else {
      const newDrawerRoute = [...drawerRoute];
      newDrawerRoute.pop();
      setDrawerRoute(newDrawerRoute);
    }
  };

  return (
    typeof window !== 'undefined' && (
      <DrawerContext.Provider
        value={{
          closeDrawer,
          currentDrawer: drawerRoute[drawerRoute.length - 1],
          setCurrentDrawer,
          maxSize,
          position,
          isDrawerOpen,
          isMobile,
          setSharedState,
          setPreviousDrawer,
          isFlex
        }}
      >
        <aside
          className={`${styles.drawer} thd-sliding-drawer`}
          style={getDrawerPositionStyles({
            position,
            toggleFlag: isDrawerOpen,
            maxSize,
            isMobile,
            zIndex,
            screenWidth,
            screenHeight,
            orientationChanged
          })}
          data-testid="drawer"
        >
          {children}
        </aside>
        <Mask zIndex={zIndex} hideMask={hideMask} openedDrawer={isDrawerOpen} />
      </DrawerContext.Provider>
    ));
};

Drawer.displayName = 'Drawer';

export { Drawer };

Drawer.propTypes = {
  open: bool,
  position: string,
  onClose: func,
  maxSize: number,
  children: oneOfType([
    element,
    arrayOf(element)
  ]).isRequired,
  initialItem: string.isRequired,
  hideMask: bool,
  isMobile: bool,
  zIndex: number,
  enableScroll: bool,
  isFlex: bool, // When using isFlex, please wrap any non-headers/footers/nav in a single div so it lays out correctly,
  persistOnOrientationChange: bool
};

Drawer.defaultProps = {
  open: false,
  position: DrawerPosition.RIGHT,
  onClose: () => {},
  maxSize: 400,
  hideMask: false,
  isMobile: false,
  zIndex: 10001,
  enableScroll: false,
  isFlex: false,
  persistOnOrientationChange: false
};
