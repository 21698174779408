import React from 'react';
import { Card } from '@one-thd/sui-atomic-components';
import { CardProductLoader } from './CardProductLoader';
import { CardSelectProductLoader } from './CardSelectProductLoader';
import { CardTitleLoader } from './CardTitleLoader';

export const CardCtaLoader = () => {
  return (
    <Card CardContentProps={{ grow: true }} data-component="PromotionProductsCardCtaLoader">
      <CardTitleLoader />
      <CardProductLoader />
      <CardSelectProductLoader />
    </Card>
  );
};

CardCtaLoader.displayName = 'CardCtaLoader';

CardCtaLoader.propTypes = {};

CardCtaLoader.defaultProps = {};
