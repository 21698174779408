import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export function RecentPurchasesTableReceipt({ orderReceiptDetails }) {
  const receiptNumber = orderReceiptDetails || '';

  return (
    <div
      data-testid="receiptDetails"
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden sui-h-20"
    >
      <Typography variant="body-base">
        <span className="sui-break-all">{receiptNumber}</span>
      </Typography>
    </div>
  );
}

export function RecentPurchasesCardReceipt({ orderReceiptDetails }) {
  const receiptNumber = orderReceiptDetails || '';
  return (
    <div data-testid="receiptDetails" className="sui-flex sui-items-center">
      <span className="sui-mr-2">
        <Typography variant="body-base" weight="bold">
          Order/Receipt#
        </Typography>
      </span>
      <span>
        <Typography variant="body-base">{receiptNumber}</Typography>
      </span>
    </div>
  );
}

RecentPurchasesTableReceipt.propTypes = {
  orderReceiptDetails: string,
};

RecentPurchasesTableReceipt.defaultProps = {
  orderReceiptDetails: null,
};

RecentPurchasesCardReceipt.propTypes = {
  orderReceiptDetails: string,
};

RecentPurchasesCardReceipt.defaultProps = {
  orderReceiptDetails: null,
};
