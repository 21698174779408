import {
  useDataModel,
  params,
  string,
  id,
  number,
  customType,
  bool,
} from '@thd-nucleus/data-sources';

export function InstoreEditPOJobName({ children }) {
  const [mutatePOJobName] = useDataModel('editPOJobName', {
    variables: {},
    context: { withAuth: true },
  });

  return children({ mutatePOJobName });
}

InstoreEditPOJobName.dataModel = {
  editPOJobName: params({
    userId: id().isRequired(),
    request: customType('EditPOJobNameRequest!').shape({
      customerAccountId: string(),
      salesDate: string(),
      newPOJobName: string(),
      registerNumber: number(),
      storeNumber: string(),
      transactionId: number(),
    }),
  })
    .mutation()
    .shape({
      editPOJobNameStatus: bool(),
    }),
};
