import React, { useRef, useEffect } from 'react';
import { bool, func, node, shape } from 'prop-types';
import { noop } from '../../../utils/promo-utils';

export const ListObserver = ({ children, observer, pause }) => {
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef || !observer) {
      return noop;
    }

    if (!pause && children?.length) {
      observer.observe(currentRef);
    } else {
      observer.unobserve(currentRef);
    }

    return () => {
      observer.unobserve(currentRef);
    };
  }, [children, observer, pause]);

  return (
    <div ref={ref} className="sui-flex sui-flex-col sui-gap-4">
      {children}
    </div>
  );
};

ListObserver.displayName = 'ListObserver';

ListObserver.propTypes = {
  children: node,
  observer: shape({
    observe: func,
    unobserve: func,
  }),
  pause: bool,
};

ListObserver.defaultProps = {
  children: null,
  observer: null,
  pause: false,
};
