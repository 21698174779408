import React from 'react';

const Footer = () => (
  <>
    <div className="footer">
      <p>
        Arrival dates and shipping costs shown above are estimates.
        See exact dates and costs and select your preferred shipping option during checkout.
      </p>
    </div>
  </>
);

export default Footer;
