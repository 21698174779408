import {
  string, bool, shape, arrayOf, params, extend
} from '@thd-nucleus/data-sources';
import {
  // ProductPod,
  ProductATC,
  ProductRating,
  ProductImage,
  ProductBadge,
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';

/**
 * We define the ProductPod's dataModel explicitly, as part of the solution to the problem found in HD-Home experience.
 * @see https://thdengops.slack.com/archives/C061ED13N5S/p1703134137467689?thread_ts=1702659382.502669&cid=C061ED13N5S
 */
const productPod = {
  dataModel: {
    product: params({
      itemId: string().isRequired(),
      dataSource: string(),
    }).shape({
      itemId: string(),
      dataSources: string(),
      badges: params({ storeId: string() }).arrayOf(
        shape({
          name: string(),
        })
      ),
      info: shape({
        isSponsored: bool(),
        sponsoredMetadata: shape({
          campaignId: string(),
          placementId: string(),
          slotId: string(),
          sponsoredId: string(),
          trackSource: string(),
        }),
        sponsoredBeacon: shape({
          onClickBeacon: string(),
          onViewBeacon: string(),
          onClickBeacons: arrayOf(string()),
          onViewBeacons: arrayOf(string()),
        }),
        productSubType: shape({
          name: string(),
        }),
      }),
      availabilityType: shape({
        buyable: bool(),
        discontinued: bool(),
        status: bool(),
        type: string(),
      }),
    }),
  },
};
// --

export const dataModel = extend(
  ProductImage,
  Price,
  ProductATC,
  ProductRating,
  ProductHeader,
  ProductBadge,
  productPod.dataModel
);