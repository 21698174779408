import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Pause = createSvgIcon(
  <>
    <path d="M8.25 0H6v24h2.25V0ZM18 0h-2.25v24H18V0Z" />
  </>,
  'Pause'
);

export { Pause };