import React from 'react';
import { string as stringType, number } from 'prop-types';
import {
  params,
  string,
  json,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { Typography, Pill } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

import { track } from '../subcomponents/related-search-analytics';

const PopularCategories = ({ contentId, componentId, pageType }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      contentId,
      pageName: pageType
    },
    skip: !contentId || !pageType
  });

  const popCatList = data?.emtContent?.content?.popularCategories?.popularCategories || [];

  if (!data || loading || error || popCatList?.length === 0) {
    return null;
  }

  return (
    <div className="sui-flex sui-flex-col sui-gap-4" data-component="PopularCategories">
      <Typography variant="h2">Popular Categories</Typography>
      <div className="sui-flex sui-flex-wrap sui-leading-tight sui-gap-4">
        {popCatList.map((popCat, index) => (
          <PopularCategory
            key={`popcat-${popCat?.title}${index}`}
            componentId={componentId}
            url={popCat?.paramlink}
            copyText={popCat?.title}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

PopularCategories.displayName = 'PopularCategories';

PopularCategories.propTypes = {
  contentId: stringType.isRequired,
  pageType: stringType.isRequired,
  componentId: stringType
};

PopularCategories.defaultProps = {
  componentId: ''
};

const PopularCategory = ({
  copyText,
  url,
  componentId,
  index
}) => {
  const handlePopularCategoryClick = () => {
    if (window?.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('popular-category.UPDATE', { updatedSearchTerm: '' });
    }
    track(copyText);
  };

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'PopularCategory',
      component: 'PopularCategory',
      position: index + 1,
      type: 'content',
      category: copyText?.replace(/\W+/g, '')
    },
  });

  return (
    <div
      className="sui-bg-primary sui-rounded-full"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <Pill
        label={copyText}
        component="a"
        href={url}
        onClick={() => handlePopularCategoryClick(copyText)}
        clickable
      />
    </div>
  );
};

PopularCategory.propTypes = {
  copyText: stringType.isRequired,
  url: stringType.isRequired,
  componentId: stringType.isRequired,
  index: number.isRequired
};

PopularCategories.dataModel = {
  emtContent: params({
    contentId: string().isRequired(),
    pageName: string().isRequired()
  }).shape({
    content: json()
  })
};

export { PopularCategories };
