import React, { useMemo } from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  PodSection,
  ProductPod,
  ProductImage,
  ProductRating,
  ProductBadge,
  PodSticker,
  PodFooter,
  PodSpacer,
  ProductATC
} from '@thd-olt-component-react/product-pod';
import {
  bool as boolType,
  string as stringType,
  number as numType,
} from 'prop-types';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';

export const VerticalRecsProductPodV7 = (props) => {
  const {
    itemId,
    storeId,
    position,
    preferredPriceFlag,
    parent,
    scheme,
    anchorProduct,
    strategy,
    brandTitleMaxLine,
    hideRating,
    hideBadge,
    hideATC,
    noATCFulfillment
  } = props;

  const anchorId = (anchorProduct.length > 2) ? anchorProduct : 'n/a';

  const merchRec = useMemo(() => {
    return `MERCH=REC-_-${scheme}-_-${anchorId}-_-${position}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  }, [anchorId, position]);

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{
        parent, position, scheme, anchorProduct, strategy
      }}
      render={(pod) => (
        <>
          <PodSticker noPadding position="top-left">
            {!hideBadge && <ProductBadge itemId={pod.itemId} storeId={storeId} />}
          </PodSticker>
          <PodSection>
            <div className="sui-grid sui-grid-cols-3 sui-gap-2">
              <div className="sui-grid-cols-1">
                <ProductImage
                  itemId={pod.itemId}
                  fetchPriority="high"
                  merchQueryParam={merchRec}
                />
              </div>
              <div className="sui-col-span-2">
                <PodSection noPadding columnAlign>
                  <ProductHeader
                    brand="above"
                    itemId={pod.itemId}
                    brandTitleMaxLine={brandTitleMaxLine}
                    disableShopThisCollection
                    merchQueryParam={merchRec}
                  />
                  {!hideRating && <ProductRating itemId={pod.itemId} merchQueryParam={merchRec} />}
                  <PodSpacer padding={['T4']}>
                    <Price
                      itemId={pod.itemId}
                      large={false}
                      storeId={storeId}
                      displayEachUom={false}
                      hideSavingsText={!preferredPriceFlag}
                      showPreferredPricingBadge
                    />
                  </PodSpacer>
                  {!hideATC && (
                    <PodFooter>
                      <PodSpacer minHeight="40px">
                        <ProductATC
                          itemId={pod.itemId}
                          checkGeneric
                          outline
                          noATCFulfillment={noATCFulfillment}
                        />
                      </PodSpacer>
                    </PodFooter>
                  )}
                </PodSection>
              </div>
            </div>
          </PodSection>
        </>
      )}
    />
  );
};

VerticalRecsProductPodV7.dataModel = extend(
  ProductPod,
  ProductBadge,
  ProductImage,
  ProductRating,
  Price,
  ProductHeader,
);

VerticalRecsProductPodV7.propTypes = {
  itemId: stringType.isRequired,
  storeId: stringType,
  position: numType,
  parent: stringType,
  preferredPriceFlag: boolType,
  scheme: stringType.isRequired,
  anchorProduct: stringType,
  strategy: stringType,
  brandTitleMaxLine: numType,
  hideRating: boolType,
  hideBadge: boolType,
  hideATC: boolType,
  noATCFulfillment: boolType
};

VerticalRecsProductPodV7.defaultProps = {
  storeId: '',
  position: null,
  brandTitleMaxLine: 4,
  preferredPriceFlag: false,
  parent: '',
  strategy: 'project-based',
  anchorProduct: '',
  hideRating: false,
  hideBadge: false,
  hideATC: false,
  noATCFulfillment: true
};

VerticalRecsProductPodV7.displayName = 'VerticalRecsProductPodV7';