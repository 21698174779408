import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { string, oneOf } from 'prop-types';

import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { ImpressionProvider } from '@thd-olt-component-react/impression';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import { CategoryTab } from '../CategoryTab/CategoryTab';
import { MobileCategoryTab } from '../MobileCategoryTab/MobileCategoryTab';
import styles from './special-buy-categories.module.scss';

const cNames = classNames.bind(styles);

const generateKey = (categoryId, index) => `category-tab-${categoryId || index}`;

const SpecialBuyCategories = ({ entryId, specialBuyType }) => {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    isCondensedLayout,
    isSpecialBuyDataLoading
  } = useSpecialBuy();

  const categoriesMemo = useMemo(() => {
    if (isCondensedLayout) {
      const impressionData = {
        id: entryId,
        name: specialBuyType === 'sbotd' ? 'SBOTD' : 'SBOTW',
        component: 'SpecialBuyBanner',
        type: 'product',
        position: '',
        category: ''
      };

      const mobileCategoryTab = categories.map((category, index) => (
        <MobileCategoryTab
          category={category}
          entryId={entryId}
          index={index + 1}
          key={generateKey(category.categoryId, index)}
        />
      ));

      return (
        <ImpressionProvider data={impressionData}>
          <>{mobileCategoryTab}</>
        </ImpressionProvider>
      );
    }

    return categories.map((category, index) => (
      <CategoryTab
        category={category}
        isSelected={category === selectedCategory}
        key={generateKey(category.categoryId, index)}
        onClick={() => setSelectedCategory(category)}
      />
    ));

  }, [
    categories,
    entryId,
    isCondensedLayout,
    selectedCategory,
    setSelectedCategory,
    specialBuyType
  ]);

  return (
    <section className={cNames('special-buy-categories')}>
      <LoadingPlaceholder
        type="rect"
        ready={!isSpecialBuyDataLoading}
        showLoadingAnimation
        color="#eeeeee"
        style={{
          height: isCondensedLayout ? '160px' : '50px',
          marginTop: isCondensedLayout ? '10px' : 0,
          marginBottom: '10px',
          marginRight: 0,
          width: '700px',
          maxWidth: '100%',
        }}
      >
        {categoriesMemo}
      </LoadingPlaceholder>
    </section>
  );
};

SpecialBuyCategories.displayName = 'SpecialBuyCategories';

SpecialBuyCategories.propTypes = {
  entryId: string,
  specialBuyType: oneOf(['sbotd', 'pro']).isRequired
};

SpecialBuyCategories.defaultProps = {
  entryId: '',
};

export { SpecialBuyCategories };
