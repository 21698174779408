import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Col = (props) => {
  const {
    fallback,
    xs,
    sm,
    md,
    lg,
    className,
    children,
    nopadding,
    flatten,
    ...attributes
  } = props;
  const classes = classNames(
    className,
    [`col__${fallback}-12`],
    {
      [`col__${xs}-12--xs`]: !!xs,
      [`col__${sm}-12--sm`]: !!sm,
      [`col__${md}-12--md`]: !!md,
      [`col__${lg}-12--lg`]: !!lg,
    }
  );

  const flattenStyle = flatten ? { paddingTop: 0, paddingBottom: 0 } : {};
  const paddingStyle = nopadding ? { padding: 0 } : flattenStyle;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={classes} {...attributes} style={paddingStyle}>
      {children}
    </div>
  );
};

Col.displayName = 'Col';

Col.propTypes = {
  /** @media: 0 - 640px / Default value, will be col__12-12 if not specified in props */
  fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** @media: 640px - 1280px */
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** @media: 1024px - 1280px */
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** @media: 1280px - 1440px */
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** @media: 1440px - infinity */
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Append extra classNames */
  className: PropTypes.string,
  /** Any HTML elements inside the column */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Remove padding around column */
  nopadding: PropTypes.bool,
  /** Remove padding above and below a column */
  flatten: PropTypes.bool
};

Col.defaultProps = {
  fallback: '12',
  xs: null,
  sm: null,
  md: null,
  lg: null,
  className: null,
  children: null,
  nopadding: false,
  flatten: false
};

export { Col };
