import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { PopularCategories as PopularCategoriesComponent } from '@thd-olt-component-react/related-search';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ImpressionProvider } from '@thd-olt-component-react/impression';

export function PopularCategories({ id }) {
  return (
    <ErrorBoundary name="popular-categories">
      <QueryProvider key={id} cacheKey={id}>
        <div
          id={`popular-categories-php-${id}`}
          className="sui-h-full"
        >
          <ImpressionProvider
            data={{
              id,
              name: 'PopularCategories',
              component: 'PopularCategories',
              type: 'content',
              position: 1,
            }}
          >
            <PopularCategoriesComponent
              componentId={id}
              contentId="home"
              pageType="home"
            />
          </ImpressionProvider>
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

PopularCategories.propTypes = {
  id: string,
};

PopularCategories.defaultProps = {
  id: undefined,
};
