import React from 'react';
import { shape, string } from 'prop-types';
import classNames from 'classnames/bind';

import { useSpecialBuy } from '../../../context/SpecialBuyProvider';
import styles from './see-all.module.scss';

const cNames = classNames.bind(styles);

export const SeeAll = ({ category }) => {
  const { specialBuyType, specialBuyEndpoint } = useSpecialBuy();
  return (
    <div
      className={cNames('see-all')}
      // id property is required by analytics
      id={specialBuyType === 'sbotd' ? 'SBOTD' : 'SBOTW'}
      data-testid="see-all-special-buy"
    >
      <a href={`${specialBuyEndpoint}#${category.categoryId}`}>
        See All {category.name} {{
          sbotd: 'Special Buy Of The Day',
          pro: 'Pro Special Buy Of The Week',
        }[specialBuyType]}
      </a>
    </div>
  );
};

SeeAll.displayName = 'SeeAll';

SeeAll.propTypes = {
  category: shape({
    name: string.isRequired,
    categoryId: string.isRequired,
  }).isRequired
};
