const { replace } = '';

/**
 * HTML entities regEx pattern
 */
const charsToEncode = /[<>"]/g;

const encodingMap = {
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;'
};

/**
 * Encode HTML entities: `<` `>` `"`
 *
 * @param {string} input to encode
 * @returns Encoded string
 */
export const encode = (input) => {
  if (input) {
    return replace.call(input, charsToEncode, (tag) => encodingMap[tag]);
  }
  return input;
};

/**
 *
 * @param {object} entry with key values to encode
 * @returns entry object with encoded values
 */
export const encodeKeyValues = (entry) => {
  if (!entry) return entry;

  const encodedEntry = {};
  // eslint-disable-next-line no-return-assign
  Object.keys(entry).forEach((key) => encodedEntry[key] = encode(entry[key]));
  return encodedEntry;
};
