import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton } from '../../../button/IconButton';
import { ArrowBack } from '../../icons/ArrowBack';
import { ArrowForward } from '../../icons/ArrowForward';

const TabScrollButton = React.forwardRef((props, ref) => {

  const {
    className,
    direction,
    orientation,
    disabled,
    ownerState,
    LeftProps = {},
    RightProps = {},
    LeftIconButtonProps = {},
    RightIconButtonProps = {},
    ...other
  } = props;

  const classes = classNames(
    'sui-lab-btn-base',
    'sui-w-10',
    'sui-shrink-0',
    {
      'sui-hidden sm:sui-inline-flex': ownerState.scrollButtonsHideMobile,
      'sui-inline-flex': !ownerState.scrollButtonsHideMobile,
      'sui-opacity-80': !disabled,
      'sui-opacity-40 sui-pointer-events-none sui-cursor-default': disabled,
    },
  );

  const rootProps = {
    ...(direction === 'left' ? LeftProps : RightProps),
    ...other
  };

  return (
    <div
      className={classes}
      ref={ref}
      {...rootProps}
    >
      {direction === 'left' ? (
        <IconButton disabled={disabled} icon={ArrowBack} size="small" {...LeftIconButtonProps} />
      ) : (
        <IconButton disabled={disabled} icon={ArrowForward} size="small" {...RightIconButtonProps} />
      )}
    </div>
  );
});

TabScrollButton.displayName = 'TabScrollButton';

TabScrollButton.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  disabled: PropTypes.bool,
  ownerState: PropTypes.object,
  LeftProps: PropTypes.object,
  RightProps: PropTypes.object,
  LeftIconButtonProps: PropTypes.object,
  RightIconButtonProps: PropTypes.object,
};

TabScrollButton.defaultProps = {};

export { TabScrollButton };