import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * `ModalFooter` contains actions that complete the task at hand or dismiss the modal.
 *
 * Related components: [Modal](#modal), [ModalBody](#modalbody), [ModalHeader](#modalheader),
 * [ButtonGroup](#buttongroup), [Button](#button).
 *
 * Usage:
 *
 * ```jsx
 * import { ModalFooter } from '@one-thd/sui-atomic-components';
 * ```
 */
const ModalFooter = React.forwardRef((props, ref) => {

  const { children, position = 'center', ...other } = props;

  const classes = classNames('sui-flex sui-px-6 sui-pt-4 sui-align-center sui-grow-0 sui-shrink-0 sui-basis-auto', {
    'sui-justify-start': position === 'start',
    'sui-justify-end': position === 'end',
    'sui-justify-center': position === 'center'
  });

  return (
    <div
      className={classes}
      ref={ref}
      {...other}
    >
      {children}
    </div>
  );
});

ModalFooter.displayName = 'ModalFooter';

ModalFooter.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The position of the content
   */
  position: PropTypes.string
};

export { ModalFooter };