import { htmlAttributes, newRelicConstants } from '../constants';
import { triggerNewRelic, triggerNewRelicTimeLog } from './helpers';

/* istanbul ignore next */
export const injectStaticImageAsSponsoredBanner = (data, startTime, clickNextBtnTimeout = 500) => {
  const bannerWrapper = document.querySelector('[id*="sponsored__banner"]');

  if (!bannerWrapper) {
    return;
  }

  let impressionBeaconAddTime;
  bannerWrapper.removeAttribute('data-hl-beacon');
  bannerWrapper.setAttribute(htmlAttributes.DATA_CAMPAIGN_ID, data.campaignId);
  bannerWrapper.setAttribute(htmlAttributes.DATA_PLACEMENT_ID, data.placementId);
  bannerWrapper.setAttribute(htmlAttributes.DATA_SLOT_ID, data.slotId);
  bannerWrapper.setAttribute(htmlAttributes.DATA_CREATIVE_ID, data.creativeId);
  bannerWrapper.setAttribute('data-usenewimpressionlogic', true);

  let viewBeacon = data.banner.onViewBeacon;
  viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';

  bannerWrapper.setAttribute('onview-beacon-nucleus', viewBeacon);
  impressionBeaconAddTime = Date.now();
  window.scrollTo(window.scrollX, window.scrollY - 1);
  window.scrollTo(window.scrollX, window.scrollY + 1);
  const standardBannerLoadTime = Date.now();
  // SEND TIME FROM BANNER INITIATED TO AD LOAD
  triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_LOAD, startTime, standardBannerLoadTime);
  // SET BANNER INIT TIME ON ELEMENT
  bannerWrapper.setAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP, startTime);
  // SET AD LOAD TIMESTAMP ON ELEMENT
  bannerWrapper.setAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP, standardBannerLoadTime);
  // SET BEACON ADD TIME ON ELEMENT
  bannerWrapper.setAttribute(htmlAttributes.DATA_BEACON_ADD_TIME, impressionBeaconAddTime);

  if (window.newrelic && !window.__SponsoredBannerLoaded__) {
    window.__SponsoredBannerLoaded__ = true;
    triggerNewRelic(newRelicConstants.STANDARD_BANNER, newRelicConstants.LOAD);
  }

  const newElement = document.createElement('div');
  newElement.innerHTML = `
        <img
          class="sponsored_banner_image"
          alt="sponsored banner"
          src=${data.banner?.bannerURL}
          style="width: 100%;"
        />`;
  bannerWrapper.replaceChildren(newElement);

  setTimeout(() => {
    const carousel = bannerWrapper.closest('.carousel');
    const next = carousel.querySelector('.carousel__next');
    next.click();
  }, +clickNextBtnTimeout);
};

/* istanbul ignore next */
const isValidISOString = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/;
  return regex.test(dateString) && !Number.isNaN(Date.parse(dateString));
};

export const isCurrentDateInRange = (startDateStr, endDateStr) => {
  if (!isValidISOString(startDateStr) || !isValidISOString(endDateStr)) {
    return false;
  }

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const currentDate = new Date();

  return startDate <= currentDate && currentDate <= endDate;
};
