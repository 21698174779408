import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Generator = createSvgIcon(
  <>
    <path d="M0 0v2.25h2.25v3H0V24h24V5.25h-2.25v16.5H2.25V7.5h8.25V2.25h2.25V0H0Zm4.5 2.25h3.75v3H4.5v-3Z" />
    <path d="M15.75 8.25h3L10.5 18l3-6.75h-2.25l3.75-9h4.5l-3.75 6Z" />
  </>,
  'Generator'
);

export { Generator };