import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Electrical = createSvgIcon(
  <>
    <path d="M5.25 0v3.75H9V0h2.25v3.75h3V6H12v5.625a2.625 2.625 0 0 1-2.625 2.625H8.25v2.625a4.875 4.875 0 1 0 9.75 0V14.92l4.36-4.51 1.618 1.563-3.728 3.857v1.045a7.125 7.125 0 1 1-14.25 0V14.25H4.875a2.625 2.625 0 0 1-2.625-2.625V6H0V3.75h3V0h2.25Zm4.5 6H4.5v5.625c0 .207.168.375.375.375h4.5a.375.375 0 0 0 .375-.375V6Z" />
    <path d="m19.5 6 3.75-6h-4.5L15 9h2.351l-3.101 6.75L22.5 6h-3Z" />
  </>,
  'Electrical'
);

export { Electrical };