import { useContext } from 'react';
import QueryContext from '../QueryContext';
import { getQueryAttributes } from '../QueryProvider';

export const useQueryAttributes = ({ query, ssr = true, dataModel }) => {

  let { queryAttributes, cacheKey, dataSource } = useContext(QueryContext);
  if (dataModel) {
    queryAttributes = getQueryAttributes([dataModel], false);
  }
  if (!queryAttributes) {
    throw new Error('useDataModel not wrapped in <QueryProvider>');
  }
  const allQueries = ssr
    ? queryAttributes.server
    : queryAttributes.client;

  if (Object.keys(allQueries).length === 0) {
    throw new Error(`No attributes were found for query ${query}, dataSource:${dataSource}, cacheKey: ${cacheKey}.
This likely due to one of the following reasons:
  1. The QueryProvider did not have access to the dataModel. Its likely a grandchild component.
  2. You may have misspelled the query name in the dataModel or query itself.
`);
  }

  const attributes = allQueries[query];

  if (!attributes) {
    throw new Error(`Could not find attributes for query '${query}' in data model.`);
  }

  let queryName = query;
  if (attributes._alias) {
    queryName = attributes._alias;
  }

  return { attributes: { [queryName]: attributes }, queryName };

};
