import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useTable from '../private/components/table/useTable';

/**
 * Contains a set of rows that constitute the body of the [`Table`](#table) component.
 *
 * Use `TableBody` with the following sub-components:
 *
 * [TableRow](#tablerow), [TableCell](#tablecell), [TableRowLabel](#tablerowlabel)
 *
 * Usage:
 *
 * ```jsx
 * import { TableBody } from '@one-thd/sui-atomic-components';
 * ```
 */
const TableBody = React.forwardRef((props, ref) => {
  const {
    children,
    ...other
  } = props;

  const table = useTable();
  const hasTableHead = table?.hasTableHead;
  const hasTableFooter = table?.hasTableFooter;
  const unlinedTable = table?.unlined;

  const classes = classNames(
    {
      '[&>tr:last-child>*:first-child]:sui-rounded-bl-base': !hasTableFooter,
      '[&>tr:last-child>*:last-child]:sui-rounded-br-base': !hasTableFooter,
      '[&>tr:last-child>*]:sui-border-b-1': unlinedTable,
      '[&>tr:first-child>*:first-child]:sui-rounded-tl-base': !hasTableHead,
      '[&>tr:first-child>*:last-child]:sui-rounded-tr-base': !hasTableHead,
      '[&>tr:first-child>*]:sui-border-t-1': !hasTableHead
    });

  return (
    <tbody
      ref={ref}
      className={classes}
      {...other}
    >
      {children}
    </tbody>
  );
});

TableBody.displayName = 'TableBody';

TableBody.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
};

export { TableBody };