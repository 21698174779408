import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Basic } from './Basic';
import { StrikeThroughPerItem } from './StrikeThroughPerItem';
import { PercentWithMessage } from './PercentWithMessage';

export const StrikeThrougPerItemPrice = ({
  totalPrice, originalUnitPrice, discountUnitPrice, percentOffValue, percentOffMessage, align
}) => {
  const strikeThroughClassName = classNames({ 'sui-text-right': align === 'end' });
  return (
    <div className={strikeThroughClassName} data-automation-id="pricingScenariosText">
      <Basic price={totalPrice} />
      <StrikeThroughPerItem
        original={originalUnitPrice}
        discount={discountUnitPrice}
      />
      <PercentWithMessage
        percent={percentOffValue}
        message={percentOffMessage}
      />
    </div>
  );
};

StrikeThrougPerItemPrice.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  originalUnitPrice: PropTypes.number.isRequired,
  discountUnitPrice: PropTypes.number.isRequired,
  percentOffValue: PropTypes.string.isRequired,
  percentOffMessage: PropTypes.string.isRequired,
  align: PropTypes.string
};

StrikeThrougPerItemPrice.defaultProps = {
  align: ''
};

StrikeThrougPerItemPrice.displayName = 'StrikeThrougPerItemPrice';
