/* eslint-disable react/prop-types */
import React from 'react';
import { BundleFulfillmentMessageIcon } from '../../helpers/icon-utils';

export const BundleFulfillmentMessage = ({ fulfillmentBundleMessage }) => {
  return (
    <div className="store__message">
      <div className="store__icon">{BundleFulfillmentMessageIcon}</div>
      <div className="store__text-box">
        {fulfillmentBundleMessage}
      </div>
    </div>
  );
};