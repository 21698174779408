/* eslint-disable react/jsx-props-no-spreading */

import React, { forwardRef } from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';
import { ArrowForward } from '@one-thd/sui-icons';

export const QuickviewButton = forwardRef(({ children, className, ...rest }, ref) => {
  const classes = classnames(
    'sui-w-full sui-h-11 sui-flex sui-justify-start sui-items-center sui-px-1 sui-font-bold',
    'focus-visible:sui-outline focus-visible:sui-outline-4 focus-visible:sui-outline-warning',
    className
  );
  return (
    <button
      className={classes}
      ref={ref}
      type="button"
      {...rest}
    >
      <span className="sui-grow sui-flex sui-justify-between sui-items-center">
        <span className="sui-flex sui-items-center">{children}</span>
        <ArrowForward size="small" />
      </span>
    </button>
  );
});

QuickviewButton.displayName = 'QuickviewButton';

QuickviewButton.propTypes = {
  children: node,
  className: string
};

QuickviewButton.defaultProps = {
  children: null,
  className: null
};
