import { useEffect, useState, useCallback } from 'react';

const getCookieValue = (cookieName) => {
  const cookiePairs = document.cookie
    .split(';')
    .map((cookie) => cookie.trim().split('=').map((part) => part.trim()));

  const matchingCookiePair = cookiePairs.find(([name]) => name === cookieName);
  return matchingCookiePair?.[1] || null;
};

const wait = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const waitForCookieInjectionRecursive = async (attempts, maxTries, waitInterval, cookieName) => {
  if (attempts >= maxTries) {
    console.error('Maximum attempts reached. Cookie not injected.');
    return;
  }

  const cookieValue = getCookieValue(cookieName);
  if (cookieValue) {
    return;
  }

  await wait(waitInterval);
  await waitForCookieInjectionRecursive(attempts + 1, maxTries, waitInterval, cookieName);
};

/**
 * Custom React hook for managing and retrieving the value of a cookie.
 *
 * @param {string} cookieName - The name of the cookie to be managed.
 * @param {number} waitInterval - The interval (in milliseconds) between attempts to check for the cookie.
 * @param {number} maxTries - The maximum number of attempts to check for the cookie.
 * @param {boolean} shouldTrigger - Determines whether the hook should trigger the initial check when mounted.
 * @returns {string} - The current value of the specified cookie.
 */
const useCookie = (cookieName, waitInterval = 100, maxTries = 100, shouldTrigger = true) => {
  const [value, setValue] = useState('');

  const checkCookie = useCallback(async () => {
    const initialCookieValue = getCookieValue(cookieName);
    if (initialCookieValue) {
      setValue(initialCookieValue);
    } else {
      await waitForCookieInjectionRecursive(0, maxTries, waitInterval, cookieName);
      setValue(getCookieValue(cookieName));
    }
  }, [cookieName, maxTries, waitInterval]);

  useEffect(() => {
    if (shouldTrigger && document.cookie) {
      checkCookie();
    }
  }, [checkCookie, shouldTrigger]);

  return value;
};

export default useCookie;
