import getDeliveryDetails from './getDeliveryDetails';

export const createInnervateURL = ({
  tagID, adkey, isDebug, csId, slug, campaignMetadata
}) => {
  const { store, zipcode } = getDeliveryDetails();

  const domain = 'https://rna.homedepot.com/';
  const adKeyQuery = `?adkey=${adkey}`;
  const baseURL = `${domain}${tagID}${adKeyQuery}`;

  const trx = document.cookie
    .split('; ')
    .find((row) => row.startsWith('trx='))
    ?.split('=')[1];

  const aamID = document.cookie
    .split('; ')
    .find((row) => row.startsWith('aam_uuid='))
    ?.split('=')[1];

  const params = new URLSearchParams({
    store_id: store,
    zipcode,
    slug,
    personalization_id: csId,
    vid: trx,
    aam_id: aamID,
    ...(isDebug && { debug: 'emulate' }),
    ...(campaignMetadata && JSON.parse(campaignMetadata)),
  });

  return `${baseURL}&${params}`;
};
