export const UUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return 'ss-s-s-s-sss'.replace(/s/g, s4);
};

export const NOOP = () => {};

export const publisher = (name, data) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`${name}`, data);
  }
};

export const determineLineLeftAndWidthForActiveTab = ({ expand, activeTab, tabRefs }) => {
  const SPACE_STANDARD = 10;
  let unnecessaryOffsetWidth = 0;
  let unnecessaryOffsetLeft = 0;
  if (expand) {
    unnecessaryOffsetLeft = SPACE_STANDARD;
  } else {
    unnecessaryOffsetWidth = 2 * SPACE_STANDARD;
  }
  /*
    This is used when the offsetLeft (caused by padding, margins, borders, etc to the left of the element)
    of the first tab is less than/greater than 10px (SPACE_STANDARD)
  */
  const initialTabOffsetLeft = tabRefs[0].offsetLeft;
  if (initialTabOffsetLeft < SPACE_STANDARD) {
    unnecessaryOffsetLeft -= SPACE_STANDARD - initialTabOffsetLeft;
  } else if (initialTabOffsetLeft > SPACE_STANDARD) {
    unnecessaryOffsetLeft += initialTabOffsetLeft - SPACE_STANDARD;
  }
  const { offsetLeft, offsetWidth } = activeTab;
  return {
    lineWidth: offsetWidth - unnecessaryOffsetWidth,
    lineLeft: offsetLeft - unnecessaryOffsetLeft
  };
};

export default {
  NOOP,
  UUID,
  publisher,
  determineLineLeftAndWidthForActiveTab
};