const hasSpecialOffer = (skuModel) => {
  const specialOfferFound = skuModel._product.pricing?.value && skuModel._product.pricing?.value > 0;
  return specialOfferFound;
};

const hasPercentOff = (skuModel) => {
  return (
    skuModel._product.pricing?.promotion?.percentageOff
      && skuModel._product.pricing?.promotion?.percentageOff > 0
  );
};

export const isBasicPerItemPrice = (skuModel, quantity) => {
  return quantity > 1 && !hasPercentOff(skuModel);
};

export const isStrikeThroughPrice = (skuModel, quantity) => {
  const isStrikeThrough = quantity <= 1 && hasSpecialOffer(skuModel) && hasPercentOff(skuModel);
  return isStrikeThrough;
};

export const isStrikeThroughPerItemPrice = (skuModel, quantity) => {
  return quantity > 1 && hasSpecialOffer(skuModel) && hasPercentOff(skuModel);
};

export const LAYOUT_TYPES = Object.freeze({
  MINIMAL: 'minimal',
  DETAILED: 'detailed',
  SIMPLE: 'simple',
  PROJECT: 'project'
});

export const ANCHOR_STORES_STATUS_TYPE_CLEARANCE = 'CLEARANCE';

export const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    return locations.find((location) => !location.isAnchor);
  }
  return null;
};

export const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  return locations.find((location) => location.isAnchor);
};

export const isQuantityAvailable = (fulfillment) => {
  const localStore = getLocalStore(fulfillment);
  return (localStore?.inventory?.quantity > 0 || localStore?.inventory?.isLimitedQuantity);
};

export const isItemOnClearance = (fulfillment) => {
  if (!fulfillment) {
    return false;
  }
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

  return !!((fulfillment?.anchorStoreStatusType?.toUpperCase() === ANCHOR_STORES_STATUS_TYPE_CLEARANCE
          && bopisFulfillment && isQuantityAvailable(bopisFulfillment)));
};