import React from 'react';
import { MedioInline as MedioInlineComponent } from '@thd-olt-component-react/internal-marketing-banner';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { string } from 'prop-types';

export function MedioInline({ id }) {

  return (
    <ErrorBoundary name="internal-marketing-banner-medio-inline">
      <QueryProvider key={id} cacheKey={id}>
        <MedioInlineComponent componentId={id} componentClass="MedioInline" />
      </QueryProvider>
    </ErrorBoundary>
  );
}

MedioInline.propTypes = {
  id: string.isRequired
};

MedioInline.defaultProps = {};
