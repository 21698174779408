import React from 'react';
import { bool as boolType, string as stringType, number as numberType } from 'prop-types';
import {
  useDataModel,
  QueryProvider,
} from '@thd-nucleus/data-sources';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { CategoryCarousel } from '../category-carousel/CategoryCarousel';
import { articleGuidesDataModel } from '../../dataModel';

const ArticleGuides = (props) => {
  const {
    customerID,
    hideTitle,
    pageType,
    requestKey,
    showLoading,
    slidesPer,
    title,
    hideControls
  } = props;
  let custID = customerID;
  const apiName = 'articles';
  const { guestUserID, svocID } = useThdCustomer() || {};

  if (!custID) {
    // Use svocID if it exists or guestUserId
    custID = svocID || guestUserID;
  }

  const udsQueryOptions = {
    variables: {
      apiName,
      key: requestKey,
      userId: custID || '',
    },
    ssr: false,
  };

  const { loading, data, error } = useDataModel('uds', udsQueryOptions);

  if (error || (!data?.uds?.articles?.length && !loading)) return null;

  return (
    <div id={apiName} data-type="container" data-component="ArticleGuides">
      <meta data-prop="name" content={apiName} />
      <QueryProvider cacheKey="user-data-services-article-guides">
        <CategoryCarousel
          categories={data?.uds?.articles}
          hideTitle={hideTitle}
          loading={loading}
          pageType={pageType}
          placeholderSize={96}
          showLoading={showLoading}
          title={title}
          /**
            * Note: The componentName prop is responsible for
            * conditional logic that will break if the value doesn't
            * exactly match the hardcoded value in CategoryCarousel.js
            */
          componentName="article-guide"
          hideTextPlaceholder
          breakpoints={{
            sm: {
              slidesPerView: slidesPer || 2,
              slidesPerGroup: slidesPer || 2
            },
            md: {
              slidesPerView: slidesPer || 2,
              slidesPerGroup: slidesPer || 2
            },
            lg: {
              slidesPerView: slidesPer || 3,
              slidesPerGroup: slidesPer || 3
            },
            xl: {
              slidesPerView: slidesPer || 3,
              slidesPerGroup: slidesPer || 3
            }
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          hideControls={hideControls}
        />
      </QueryProvider>
    </div>
  );
};

ArticleGuides.dataModel = articleGuidesDataModel;

ArticleGuides.displayName = 'ArticleGuides';

ArticleGuides.propTypes = {
  customerID: stringType,
  hideTitle: boolType,
  pageType: stringType,
  requestKey: stringType.isRequired,
  showLoading: boolType,
  slidesPer: numberType,
  title: stringType,
  hideControls: boolType
};

ArticleGuides.defaultProps = {
  customerID: null,
  hideTitle: false,
  pageType: null,
  showLoading: false,
  slidesPer: null,
  title: null,
  hideControls: false
};

export { ArticleGuides };