import React from 'react';
import { func } from 'prop-types';
import { DrawerBody, Typography } from '@one-thd/sui-atomic-components';

import { useMessage } from '../../../hooks/useMessage';
import { usePromoModel } from '../../../hooks/usePromoModel';
import { MESSAGE_KEY } from '../../../utils/constants';
import { BmsmTiers } from '../../BmsmTiers/BmsmTiers';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';

export const PromoSummaryDrawer = ({ onBack, onClose }) => {
  const {
    isBmsm,
    longDesc,
    shortDesc,
  } = usePromoModel();
  const title = useMessage(MESSAGE_KEY.title);
  const promoSummarySubtitle = useMessage(MESSAGE_KEY.promoSummarySubtitle);
  const showBmsmTiers = isBmsm;

  return (
    <>
      <PromoDrawerHeader
        title={title}
        onBack={onBack}
        onClose={onClose}
      />
      <DrawerBody>
        <article
          className="sui-pr-2 sui-flex sui-flex-col sui-gap-2"
          data-testid="promo-summary"
        >
          <Typography component="p" variant="h4">{promoSummarySubtitle}</Typography>
          <div className="sui-flex sui-flex-col sui-gap-1">
            <Typography> {shortDesc} </Typography>
            <Typography> {longDesc} </Typography>
          </div>
          {showBmsmTiers && <BmsmTiers />}
        </article>
      </DrawerBody>
    </>
  );
};

PromoSummaryDrawer.displayName = 'PromoSummaryDrawer';

PromoSummaryDrawer.propTypes = {
  onBack: func.isRequired,
  onClose: func.isRequired
};
