/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import { shouldUseMcc } from './useMccCart';
import { transformLegacyCart } from '../transformers/cartTransformer';
import { getOptimisticCartUpdate } from './optimistic-cart-update';
import { snapshotUi } from '../assertion-framework/snapshot-ui';

const CACHE = {};

export const useAutomationGetCart = (updateResponse) => {
  const [status, setStatus] = useState({
    data: CACHE.cartInfo ? { cartInfo: CACHE.cartInfo } : null,
    loading: !CACHE.cartInfo,
    error: false
  });
  const [automationCart, setAutomationCart] = useState(false);

  let interval = null;
  useEffect(() => {
    if (!automationCart) {
      interval = setInterval(() => {
        if (window.__cart_item_mockData) {
          if (window.__cart_item_mockData.resp.CartModel) {
            CACHE.cartInfo = transformLegacyCart(window.__cart_item_mockData.resp.CartModel);
          } else {
            CACHE.cartInfo = window.__cart_item_mockData.resp.cartInfo;
          }
          // console.warn('\n\n\n&&&&&&& useAutomationGetCart', JSON.stringify(CACHE.cartInfo.descriptiveAttr, null, 2));
          setStatus({
            data: { cartInfo: CACHE.cartInfo },
            loading: false,
            error: false
          });
          clearInterval(interval);
          setAutomationCart(true);
        } else {
          console.warn('Waiting for window.__cart_item_mockData...');
        }
      }, 500);
    }
  }, []);

  // useEffect(() => {
  //   if (updateResponse
  //   // && JSON.stringify(status.data) !== JSON.stringify(updateResponse)
  //   ) {
  //     // console.warn('\n\n+++++++++++ useAutomationGetCart setting data to updateResponse');
  //     setStatus({
  //       data: updateResponse,
  //       loading: false,
  //       error: false
  //     });
  //   }
  // }, [updateResponse]);

  return status;
};

export const updateCartAutomation = async ({ variables }, setResponse) => {
  const {
    itemDetails
  } = variables;

  // console.warn('\n\n::::::::::::::::::::::::::::: automation updateCart');
  // if (mock data has applicable update response) {
  //    get mock update response
  // } else {

  // apply optimistic update calc
  const { updateCart } = getOptimisticCartUpdate(variables, CACHE.cartInfo);
  if (updateCart && updateCart.itemCount) {
    CACHE.cartInfo = updateCart;
    const data = JSON.parse(JSON.stringify(CACHE));
    setResponse(data);
  } else {
    console.warn('\n\n+++++++++++ useMccGetCart update returned 0 items????');
  }

  return { cartInfo: CACHE.cartInfo };
};

const initAutomationTools = () => {
  if (!window.setResponse) {
    window.setResponse = (url, data) => {
      window.__cart_item_mockData = { resp: data };
    };
    window.snapshot = snapshotUi;
  }
};

export const shouldUseAutomation = () => {
  let should = false;
  try {
    if (
      typeof window !== 'undefined'
      && (
        window.location.href.includes('scenarioId=automation')
      || window.location.href.includes('isAutomation=true')
      || window.__cart_item_mockData)
    ) {
      should = true;
      initAutomationTools();
    }
    // else {
    //   console.error('\n\n????????????????????????????? shouldUseAutomation() = false \n',
    //     'typeof window =', typeof window, '\n window.__cart_item_mockData: ', window.__cart_item_mockData,
    //     '\n window.__cart_item_Automation: ', window.__cart_item_Automation);
    // }
  } catch (err) {
    console.error(err);
  }
  return should;
};