import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import {
  extend,
  string as stringType,
  params,
  useLazyDataModel
} from '@thd-nucleus/data-sources';
import {
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  ButtonGroup,
  Alert,
  Typography
} from '@one-thd/sui-atomic-components';
import { triggerAnalytics } from '../../helpers';
import {
  LIST_DELETE,
} from '../../constants';

export const DeleteListDrawerBody = ({
  list,
  onClose,
  onDelete,
  menuName,
  setMenuName,
  setMainMenuDrawer,
  setdeleteListDrawer
}) => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('DeleteListDrawerBody.ready', {});
  }, []);

  const {
    id: listId,
    name: initialName,
    listAccessType: initialListAccessType
  } = list || {};

  const [submitDeleteList, deleteResponse] = useLazyDataModel('deleteList', {
    variables: {
      id: listId
    },
    context: { withAuth: true }
  });

  const handleDelete = async (event) => {
    event.preventDefault();
    await submitDeleteList();
  };

  useEffect(() => {
    if (deleteResponse?.data?.deleteList && deleteResponse?.data?.deleteList.id) {
      onDelete({ listId });
      triggerAnalytics(LIST_DELETE, {});
      onClose();
    }
  }, [deleteResponse?.data, deleteResponse?.error]);

  const PreviousDrawer = () => {
    setdeleteListDrawer(false);
    setMainMenuDrawer(true);
    setMenuName('List Settings');
  };

  return (
    <>
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        <DrawerHeader title={menuName} onClose={onClose} onBack={PreviousDrawer} />
        <DrawerBody>
          <Alert status="warning">
            Delete the list <u>{initialName}</u>?
          </Alert>
        </DrawerBody>
        <DrawerFooter>
          <ButtonGroup orientation="vertical">
            <Button
              fullWidth
              variant="primary"
              onClick={handleDelete}
              data-testid="delete-list-button"
            >
              Delete
            </Button>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-testid="cancel-delete-list-button"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </div>
    </>
  );
};

DeleteListDrawerBody.displayName = 'DeleteListDrawerBody';

DeleteListDrawerBody.propTypes = {
  onClose: func.isRequired,
  onDelete: func.isRequired,
  list: shape({
    id: string,
    name: string,
    public: bool
  }).isRequired,
  menuName: string.isRequired,
  setMenuName: func.isRequired,
  setMainMenuDrawer: func.isRequired,
  setdeleteListDrawer: func.isRequired
};

DeleteListDrawerBody.dataModel = extend({
  deleteList: params({
    id: stringType().isRequired()
  }).mutation().shape({
    id: stringType()
  })
});
