import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '@one-thd/sui-atomic-components';
import { generateKey } from './utils';

const RenderQuote = ({ node, children }) => {
  return (
    <mark className="sui-flex sui-justify-center">
      {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
      <Card className="sui-w-[90%] sui-border-l-4">
        <CardBody>
          {children}
        </CardBody>
      </Card>
    </mark>
  );
};

RenderQuote.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  children: PropTypes.node.isRequired
};

export { RenderQuote };
