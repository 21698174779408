const plccMaskSensitiveFields = (data) => {
  let maskedData = JSON.parse(JSON.stringify(data));
  if (maskedData.accessToken) {
    maskedData.accessToken = maskedData.accessToken.slice(0, 5) + '######';
  }
  if (maskedData.paymentCards) {
    maskedData.paymentCards = '#####';
  }
  return maskedData;
};

export const newRelic = {
  logPlccApiCall(url) {
    if (
      typeof window.newrelic !== 'undefined'
      && typeof window.newrelic.addPageAction === 'function'
      && url
    ) {
      window.newrelic.addPageAction('PLCC API Call', { plccApiCallUrl: url });
    }
  },
  logPlccApiResponse(data, status, apiCallDurationInSeconds) {
    if (
      typeof window.newrelic !== 'undefined'
      && typeof window.newrelic.addPageAction === 'function'
      && data
    ) {
      const maskedData = plccMaskSensitiveFields(data);
      window.newrelic.addPageAction('PLCC API Response', maskedData);
      if (status) {
        window.newrelic.addPageAction('PLCC API Response Code', {
          plccApiResponseCode: status,
        });
      }
      if (apiCallDurationInSeconds) {
        window.newrelic.addPageAction('PLCC API Call Duration', {
          plccApiCallDuration: apiCallDurationInSeconds,
        });
      }
    }
  }
};
