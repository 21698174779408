import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../form/FormGroup';
import useControlled from '../private/hooks/useControlled';
import RadioGroupContext from './RadioGroupContext';

/**
 * `RadioGroup` is a helpful wrapper used to group `Radio` components that provides an easier API,
 * and proper keyboard accessibility to the group.
 *
 * Related components: [Radio](#radio), [SelectionControlLabel](#selectioncontrollabel), [TileGroup](#tilegroup)
 *
 * Usage:
 *
 * ```jsx
 * import { RadioGroup } from '@one-thd/sui-atomic-components';
 * ```
 */
const RadioGroup = React.forwardRef(({
  alignment = 'center',
  children,
  defaultValue,
  name,
  onChange,
  value: valueProp,
  orientation,
  ...rest
}, ref) => {

  const [value, setValue] = useControlled({
    controlled: valueProp,
    defaultValue
  });

  const handleChange = (event) => {
    setValue(event.target.value);

    if (onChange) {
      onChange(event, event.target.value);
    }
  };

  return (
    <RadioGroupContext.Provider value={{ name, onChange: handleChange, value }}>
      <FormGroup alignment={alignment} role="radiogroup" ref={ref} orientation={orientation} gap={false} {...rest}>
        {children}
      </FormGroup>
    </RadioGroupContext.Provider>
  );
});

RadioGroup.displayName = 'RadioGroup';

RadioGroup.propTypes = {
  /**
   * Controls the vertical alignment of the label and and radio button.
   */
  alignment: PropTypes.oneOf(['top', 'center']),
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * The default value of the radio group
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  /**
   * The name attribute passed down to each radio button
   */
  name: PropTypes.string,
  /**
   * The callback for when a radio button is clicked.
   * It passes the event and the event.target.value
   */
  onChange: PropTypes.func,
  /**
   * The current value of the radio group
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  /**
   * The orientation of the children
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

RadioGroup.defaultProps = {
  alignment: 'center',
  children: null,
  defaultValue: undefined,
  name: null,
  onChange: null,
  value: undefined,
  orientation: 'vertical'
};

export { RadioGroup };
