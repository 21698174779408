import {
  arrayOf,
  node,
  oneOfType,
  string
} from 'prop-types';
import React, {
  useContext,
} from 'react';
import classNames from 'classnames';
import { DrawerContext } from '../Drawer';
import styles from './drawer-item.module.scss';
import { getItemPositionStyles } from '../drawer-utils/utility';

/**
 * Drawer Items holds the each drawer content
 * @param {*} param0
 */
const DrawerItem = ({
  name,
  children
}) => {
  const {
    currentDrawer,
    position,
    maxSize,
    isMobile,
    isFlex
  } = useContext(DrawerContext);

  const itemClassName = classNames(styles['drawer-item'], {
    [styles.active]: currentDrawer === name,
    [styles.inactive]: currentDrawer !== name,
    [styles.flex]: isFlex
  });

  return (
    <div
      data-name={name}
      data-testid="drawer-item"
      className={itemClassName}
      style={getItemPositionStyles({
        toggleFlag: (currentDrawer === name),
        position,
        maxSize,
        isMobile,
        screenWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        screenHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      })}
    >
      {children}
    </div>
  );
};

DrawerItem.displayName = 'DrawerItem';

export default DrawerItem;

DrawerItem.propTypes = {
  name: string.isRequired,
  children: oneOfType([
    node,
    arrayOf(node),
  ]).isRequired,
};
