import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const OrderTracking = createSvgIcon(
  <>
    <path d="M0 0h19.5v5.25h-2.25v-3h-15V19.5l1.921-1.441L7.5 20.907l3.498-2.993 1.463 1.71L7.5 23.867 4.079 20.94 0 24V0Zm16.5 13.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z" />
    <path d="M19.408 22.13c3.98-5.3 4.592-6.354 4.592-8.447C24 9.934 21 7.5 18 7.5s-6 2.434-6 6.183c0 2.093.611 3.146 4.592 8.448L18 24c.51-.677.98-1.298 1.408-1.87Zm2.342-8.447c0 .447-.035.724-.112.993-.082.285-.241.668-.604 1.277-.57.959-1.494 2.246-3.034 4.305-1.54-2.059-2.465-3.346-3.034-4.305-.363-.61-.522-.992-.604-1.277-.076-.27-.112-.546-.112-.993 0-2.415 1.9-3.933 3.75-3.933s3.75 1.518 3.75 3.933Z" />
  </>,
  'OrderTracking'
);

export { OrderTracking };