import React from 'react';
import { shape, func } from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import {
  getSellableQuantityYourStore,
  productShape,
  formatSellableQuantity,
  isLimitedStock
} from '../../helpers/pod-fulfillment-utils';
import { BopisOffIcon } from '../../helpers/icon-utils';
import { isFunction, getPickupStoreName } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';

export const Clearance = ({
  product,
  onCheckNearbyStoresClick,
  customRender
}) => {
  const backupStoreName = useStore()?.storeName;
  const storeName = getPickupStoreName(product) || backupStoreName;
  const isLimitedStockProduct = isLimitedStock(product);

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({ pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        value: {} });
    }
    return (<div className="store__message" />);
  };

  if (!(product && storeName)) {
    return emptyComponent();
  }

  const sellableQuantity = getSellableQuantityYourStore(product); //
  // const sellableQuantity = 0;
  if (!sellableQuantity && !isLimitedStockProduct) {
    return emptyComponent();
  }

  const formattedQty = formatSellableQuantity(sellableQuantity);

  const storeNameLink = <CheckNearByStore product={product} onCheckNearbyStoresClick={onCheckNearbyStoresClick} />;

  if (isFunction(customRender)) {
    if (isLimitedStockProduct) {
      return customRender({ pickUpTemplate: PICKUP_TEMPLATES.LIMITED_STOCK_CLEARANCE,
        icon: BopisOffIcon,
        values: { formattedQty, storeNameLink } });
    }

    return customRender({ pickUpTemplate: PICKUP_TEMPLATES.IN_STOCK_CLEARANCE,
      icon: BopisOffIcon,
      values: { formattedQty, storeNameLink } });
  }

  return (
    <div className="store__message">
      <div className="store__icon">{BopisOffIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">On Clearance at {storeNameLink}</div>
        {!isLimitedStockProduct ? (
          <div id="check-nearby-store" className="store__second-line">
            <span className="store__success">{`${formattedQty} in stock, `}</span>
            <span>not eligible for online order Pickup</span>
          </div>
        )
          : (
            <div className="store__second-line">
              <span>Visit store to check availability</span>
            </div>
          )}
      </div>
    </div>
  );
};

Clearance.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func
};
Clearance.defaultProps = {
  onCheckNearbyStoresClick: () => {},
  customRender: null
};