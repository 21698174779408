import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Faucets = createSvgIcon(
  <>
    <path d="M5.25 7.875a5.625 5.625 0 0 1 5.625-5.625h.375c2.9 0 5.25 2.35 5.25 5.25h2.25a7.5 7.5 0 0 0-7.5-7.5h-.375A7.875 7.875 0 0 0 3 7.875V13.5H0V24h8.25V13.5h-3V7.875Zm-3 7.875H6v6H2.25v-6Z" />
    <path d="M17.625 11.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm3 2.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm1.875 2.625a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM22.125 21a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm-7.875-1.125a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM17.625 15a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Zm1.125 2.625a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM17.625 22.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM15.75 12.375a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm-1.875 4.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
  </>,
  'Faucets'
);

export { Faucets };