import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import {
  Button,
  Card,
  CardTitle,
  CardMedia,
  CardActions,
  CardBody,
  Link,
  Typography,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { CreateAccountCardContent } from './CreateAccountCardContent';
import { SignInCardContent } from './SignInCardContent';

function useUserInfo(customerName, customerEmail) {
  const [userInfo, setUserInfo] = useState({
    userName: customerName?.toLowerCase(),
    userEmail: customerEmail?.replace(/(.{2})(.*)(?=@)/, '$1***'),
  });

  useEffect(() => {
    if (!userInfo.userName || !userInfo.userEmail) {
      setUserInfo({
        userName: customerName?.toLowerCase(),
        userEmail: customerEmail?.replace(/(.{2})(.*)(?=@)/, '$1***'),
      });
    }
  }, []);

  return userInfo;
}

function useCardContent(userName, userEmail) {
  const [cardContent, setCardContent] = useState({
    signInTitle: null,
    signInLink: null,
  });

  useEffect(() => {
    if (!userEmail) {
      setCardContent({
        signInTitle: 'Benefits Down Every Aisle',
        signInLink: 'Sign In',
      });
    } else {
      setCardContent({
        signInTitle:
          userName && userName.toLowerCase() !== 'self'
            ? `Is That You, ${userName}?`
            : 'Keep Your Projects Going',
        signInLink:
          userName && userName.toLowerCase() !== 'self'
            ? `Not ${userName}?`
            : 'Not You?',
      });
    }
  }, [userName, userEmail]);

  return cardContent;
}

// eslint-disable-next-line max-len
const imageUrl = 'https://dam.thdstatic.com/content/production/-LmJOfYRgYkIbBZQbnK3hQ/BCslv86A3HxgIETDmewyVQ/downloadImageForWeb/Welcome-to-Home-Depot.Jpg';

const SignIn = ({ customerName, customerEmail, schema, componentId }) => {
  const { userName, userEmail } = useUserInfo(customerName, customerEmail);
  const { signInTitle, signInLink } = useCardContent(userName, userEmail);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('sign-in.ready');
  }, []);

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: userEmail ? 'SignInPersist' : 'SignInCard',
      component: 'SignIn',
      position: 1,
      type: 'content',
    },
  });

  return (
    <Card
      className="sui-flex sui-justify-center sui-h-full"
      data-component="SignIn"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <CardTitle
        header={(
          <Typography variant="h3">
            {/* Typography currently does not have text-transform: capitalize */}
            <span className="sui-capitalize">{signInTitle}</span>
          </Typography>
        )}
        disableActionSpacing
        action={(
          <Link
            variant="body-base"
            href="/auth/view/signin?redirect=/&ref=null"
            underline="always"
          >
            <span className="sui-capitalize">{signInLink}</span>
          </Link>
        )}
      />
      <CardMedia src={imageUrl} alt="Sign In Card Banner" />
      <CardBody>
        {userEmail ? (
          <SignInCardContent userEmail={userEmail} schema={schema} />
        ) : (
          <CreateAccountCardContent />
        )}
      </CardBody>
      <CardActions position="center">
        <Button
          fullWidth
          variant="primary"
          role="button"
          tag="a"
          href="/auth/view/signin?redirect=/&ref=null"
        >
          {userEmail ? 'Sign In' : 'Create an Account'}
        </Button>
      </CardActions>
    </Card>
  );
};

SignIn.displayName = 'SignIn';

SignIn.propTypes = {
  customerName: string,
  customerEmail: string,
  schema: string,
  componentId: string,
};

SignIn.defaultProps = {
  customerName: null,
  customerEmail: null,
  schema: null,
  componentId: '',
};

export { SignIn };
