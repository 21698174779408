import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Image = createSvgIcon(
  <>
    <path d="M6.375 9.75a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z" />
    <path d="M24 1.5H0v21h24v-21ZM2.25 20.25v-1.409l4.15-4.15 3.386 3.182L7.41 20.25H2.25Zm8.341 0 7.784-7.784 3.375 3.375v4.409H10.591Zm11.159-7.591-3.375-3.375-6.997 6.997-5.027-4.723L2.25 15.66V3.75h19.5v8.909Z" />
  </>,
  'Image'
);

export { Image };