import React from 'react';
import { Skeleton, SkeletonLine } from '@one-thd/sui-atomic-components';

export const CardTitleLoader = () => {
  return (
    <Skeleton disablePadding SkeletonContentProps={{ density: 'normal', grow: true }}>
      <SkeletonLine variant="heading" fullWidth />
    </Skeleton>
  );
};

CardTitleLoader.displayName = 'CardTitleLoader';

CardTitleLoader.propTypes = {};

CardTitleLoader.defaultProps = {};
