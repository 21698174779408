import React from 'react';
import { Skeleton, SkeletonLine, Typography } from '@one-thd/sui-atomic-components';

export const CardSelectProductLoader = () => {
  return (
    <div className="sui-pt-1">
      <Skeleton disablePadding disableGutters SkeletonContentProps={{ grow: true }}>
        <SkeletonLine variant="single" fullWidth />
        <div className="sui-py-8 sui-my-7">
          <Typography align="center" variant="body-base" color="subtle" weight="bold">
            Searching the aisles to find deals for you.
          </Typography>
        </div>
        <SkeletonLine variant="single" fullWidth />
        <SkeletonLine variant="single" fullWidth />
        <SkeletonLine variant="single" fullWidth />
      </Skeleton>
    </div>
  );
};

CardSelectProductLoader.displayName = 'CardSelectProductLoader';

CardSelectProductLoader.propTypes = {};

CardSelectProductLoader.defaultProps = {};
