export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';
export const TABLET = 'tablet';

export const CHANNEL = {
  MOBILE,
  DESKTOP,
  TABLET,
};

export const PRODUCT_ADD_ON_IDENTIFIER = {
  ATTACH_LABOR: 'attach-labor',
  ATTACH_LABOR_STORE_ASSEMBLY: 'assembleItemAtStore',
  HDPP: 'hdpp',
  PRO_REFERRAL: 'pro-referral',
  PRO_ASSEMBLY: 'assembly',
  DUAL_PATH: 'dual-path',
  SAM_DUAL_PATH: 'SAM-dual-path',
  VIRTUAL_ASSISTANCE: 'virtual-assistance'
};

export const DUAL_PATH_CATEGORIES = {
  DEFAULT_SERVICE: 'brio_inaisle_sfi_generalfs',
  TANKLESS_WATER_HEATER: 'brio_inaisle_sfi_twht',
  TANK_WATER_HEATER: 'brio_inaisle_sfi_whtr',
  FENCING: 'BRIO_INAISLE_SFI_FENC',
  GENERATORS: 'dualpath_inaisle_sfi_generators',
  WINDOWS: 'dualpath_inaisle_sfi_windows',
  DOORS: 'dreamline-door-installation/44fbb1b75'
};