import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const QuickAdd = createSvgIcon(
  <>
    <path d="M3 1.5h21v21H3v-5.25h2.25v3h16.5V3.75H5.25V6H3V1.5Z" />
    <path d="M6 8.25v2.25H0V8.25h6Zm.75 4.5H1.5V15h5.25v-2.25ZM15 10.5h4.5v2.25H15v4.5h-2.25v-4.5h-4.5V10.5h4.5V6H15v4.5Z" />
  </>,
  'QuickAdd'
);

export { QuickAdd };