import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleRed = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleRed.palette['--sui-palette-background-primary'] = '162 44 41';

export { HouseStyleRed };