import { createTheme } from '@one-thd/sui-atomic-components';

const HolidayShadow = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HolidayShadow.palette['--sui-palette-background-primary'] = '196 196 196';

export { HolidayShadow };