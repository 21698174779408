import React from 'react';
import { SkeletonBlock } from '@one-thd/sui-atomic-components';

// Adds a buffer in Pixels to begin hydrating the component x pixels before the actual component is in view,
// this helps to Hydrate components early so the user doesnt see hydration occur.
export const SCROLL_BUFFER_DESKTOP = 700;
export const SCROLL_BUFFER_MOBILE = 400;

// Determines a delay for the hydrator in milliseconds
export const HOMEPAGE_DELAY_STANDARD = 250;

// Averages were taken of all card components to determine height for mobile/desktop
export const HOMEPAGE_PLACEHOLDER_DESKTOP = (
  <SkeletonBlock disablePadding aspect="wide" height="400px" width="100%" />
);
export const HOMEPAGE_PLACEHOLDER_MOBILE = (
  <SkeletonBlock disablePadding aspect="wide" height="360px" width="100%" />
);

// Section needs it's own placeholders to address its own CWV's
export const SECTION_PLACEHOLDER_DESKTOP = (
  <SkeletonBlock disablePadding aspect="wide" height="390px" width="100%" />
);
export const SECTION_PLACEHOLDER_MOBILE = (
  <SkeletonBlock disablePadding aspect="wide" height="225px" width="100%" />
);

export const SECTION_PLACEHOLDER_NATIVE = (
  <SkeletonBlock disablePadding aspect="wide" height="175px" width="100%" />
);
export function getHydrationOptions(channel, isConsumerApp = false) {
  const PLACEHOLDER = channel === 'mobile'
    ? HOMEPAGE_PLACEHOLDER_MOBILE
    : HOMEPAGE_PLACEHOLDER_DESKTOP;

  let PLACEHOLDER_SECTION;
  if (!isConsumerApp) {
    PLACEHOLDER_SECTION = channel === 'mobile'
      ? SECTION_PLACEHOLDER_MOBILE
      : SECTION_PLACEHOLDER_DESKTOP;
  } else {
    PLACEHOLDER_SECTION = SECTION_PLACEHOLDER_NATIVE;
  }

  const SCROLL_BUFFER = channel === 'mobile' ? SCROLL_BUFFER_MOBILE : SCROLL_BUFFER_DESKTOP;

  return {
    SCROLL_BUFFER,
    HOMEPAGE_DELAY_STANDARD,
    PLACEHOLDER,
    PLACEHOLDER_SECTION,
  };
}
