import React from 'react';
import {
  shape,
  arrayOf,
  number,
  string,
  func
} from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from '@one-thd/sui-atomic-components';
import { TableOrder } from './TableOrder';

export function RecentPurchasesTable({
  orderHistoryData,
  customerInfo,
  onError,
  windowWidth,
}) {
  const maxOrders = orderHistoryData?.purchaseHistory?.orders?.length > 20
    ? 20
    : orderHistoryData?.purchaseHistory?.orders?.length;

  const orders = orderHistoryData?.purchaseHistory?.orders.slice(0, maxOrders);

  let tableRows = orders?.map((order, index) => (
    <TableOrder
      customerInfo={customerInfo}
      index={index}
      key={order.receiptDetails}
      onError={onError}
      order={order}
      windowWidth={windowWidth}
    />
  ));

  return (
    // eslint-disable-next-line
    <div data-testid="recentOrders-content-b2b-table" className="sui-h-[430px] sui-mx-2.5 sui-my-0 sui-overflow-auto">
      <Table>
        <TableHead className="sui-bg-primary sui-z-10 sui-sticky sui-top-0">
          <TableRow className="sui-table sui-w-full sui-table-fixed sui-border-b-2 sui-border-solid sui-border-stronger">
            <TableCell className="sui-w-28 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold">Date</Typography>
            </TableCell>
            <TableCell className="sui-w-28 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold">Status</Typography>
            </TableCell>
            <TableCell className="sui-w-52 sui-font-normal sui-hidden md:sui-table-cell sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold">Items</Typography>
            </TableCell>
            <TableCell className="sui-w-32 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold"><span className="sui-whitespace-nowrap">Order/Receipt #</span></Typography>
            </TableCell>
            <TableCell className="sui-w-32 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold">PO/Job Name</Typography>
            </TableCell>
            <TableCell className="sui-w-28 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold"><span className="sui-whitespace-nowrap">Order Origin</span></Typography>
            </TableCell>
            <TableCell className="sui-w-24 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
              <Typography weight="bold">Total</Typography>
            </TableCell>
            <TableCell aria-label="Placeholder" className="sui-w-16 sui-px-2 sui-py-3 sui-text-left" />
          </TableRow>
        </TableHead>
        <TableBody className="sui-block">{tableRows}</TableBody>
      </Table>
    </div>
  );
}

RecentPurchasesTable.propTypes = {
  orderHistoryData: shape({
    purchaseHistory: shape({
      customerAccountId: string,
      orderCount: number,
      orders: arrayOf(
        shape({
          salesDate: string,
          orderNumber: string,
          totalAmount: number,
          receiptDetails: string,
          registerNumber: number,
          orderOrigin: string,
          detailsSource: string,
          transactionId: number,
          POJobName: string,
          transactionType: string,
        })
      ),
    }),
  }),
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  windowWidth: number,
  onError: func,
};

RecentPurchasesTable.defaultProps = {
  orderHistoryData: null,
  customerInfo: {},
  windowWidth: undefined,
  onError() {},
};
