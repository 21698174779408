/* eslint-disable implicit-arrow-linebreak */
import React, { useContext } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { WelcomeCardContext } from '../../../contexts';
import { SkinnyBarSkeleton } from '../../../common/StatusUnavailableNotifications/StatusUnavailable';
import { SkinnyBar, ChallengeSkinnyBar } from './Notifications';
import { getHostCURL } from '../../../common/utils/cardUtils';
import * as Constants from '../../../core/Constants';

const analyticsMessage = Constants.analytics.ANALYTICS_MESSAGE;
const analyticsLinks = Constants.analytics.ANALYTICS_LINKS;

export const SkinnyBarNotifications = () => {
  const { isLoadingPerks, availablePerksData } = useContext(WelcomeCardContext);

  if (isLoadingPerks) {
    return <SkinnyBarSkeleton />;
  }

  if (availablePerksData?.challengeTitles?.length > 0) {
    const { challengeTitles } = availablePerksData;
    return <ChallengesCard challenge={challengeTitles[0]} />;
  }
  return <RegisterCard />;
};

const RegisterCard = () => (
  <SkinnyBar
    imgUrl="RegisteredTender.svg"
    linkURL={getHostCURL('/myaccount/payments')}
    bodyMessage="Get Easy Purchase Tracking"
    analyticsMessage={analyticsMessage.Register_Tender}
    analyticsLinks={analyticsLinks.REGISTER_CARD}
    small
  />
);

const ChallengesCard = ({ challenge }) => {
  const challengeName = challenge;
  const rhLink = '/myaccount/proXtraRewards?drawerList=challenge';
  return (
    <ChallengeSkinnyBar
      linkURL={rhLink}
      bodyMessage={challengeName}
      analyticsMessage={analyticsMessage.Offer_Challenge}
      analyticsLinks={analyticsLinks.EARN_PROXTRA}
    />
  );
};
