import React from 'react';
import Responsive from 'react-responsive';
import { Typography } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { Image } from '@thd-olt-component-react/core-ui';
import * as Constants from '../../../../core/Constants';
import { analyticsStash } from '../../../../common/utils/AnalyticsUtils';
import { floorNumberWithCommas } from '../../../../common/utils/CurrencyUtils';
import { ProgressTracker } from '../../../../common/ProgressTracker/ProgressTracker';
import { getAccrualData, getHostCURL, getImageURL } from '../../../../common/utils/cardUtils';
import { WelcomeCardTile, WelcomeCardTileDynamicIcon } from './WelcomeCardTile';

/*
Handles State 1 - 4
State 4: Available Perk Count
State 3: New Perk Unlocked
State 2: Perks Expiring Count
State 1: Perks Complete
 */

export const PerkWithStaticIcon = (props) => {
  const {
    analyticsMessage,
    analyticsLinks,
    label = 'Well Done,',
    sublabel = '',
    refLink = '/myaccount/proXtraRewards',
    linkText = 'View Your Perks',
    imgUrl = 'Pro-Xtra_Perk_Icon-whiteFill.svg',
  } = props;
  return (
    <WelcomeCardTile
      imageURL={imgUrl}
      imageClass="notification-offer"
      analyticsMessage={analyticsMessage}
      analyticsLinks={analyticsLinks}
      refLink={refLink}
      altText="Perk Icon"
      linkText={linkText}
    >
      <>
        <Responsive minWidth={1024}>
          <Typography variant="body-base" weight="bold" height="normal">
            {label}
          </Typography>
          {!!sublabel && (
            <Typography variant="body-base" weight="regular" height="normal">
              {sublabel}
            </Typography>
          )}
        </Responsive>
        <Responsive minWidth={768} maxWidth={1023}>
          <Typography variant="body-base" weight="regular" height="normal">
            {label}
          </Typography>
        </Responsive>
        <Responsive maxWidth={767}>
          <Typography variant="body-lg" weight="regular" height="normal">
            {label}
          </Typography>
        </Responsive>
      </>
    </WelcomeCardTile>
  );
};

export const ProgressPerk = ({ perkSummary }) => {
  const perkData = getAccrualData(perkSummary);
  const amountRequired = perkData?.spendForNextPerk ? perkData?.spendForNextPerk : 0;
  const amount = floorNumberWithCommas(amountRequired, 0);
  const percentage = parseInt(perkSummary?.perkCurrentTierProgressPercentage, 10);
  const icon = (
    <div className="notification-image notification-offer">
      <div className="notification-tracker">
        <ProgressTracker percentage={percentage} />
      </div>
    </div>
  );

  return (
    <WelcomeCardTileDynamicIcon
      icon={icon}
      analyticsMessage={Constants.analytics.ANALYTICS_MESSAGE.Progress_Until_Next_Perk}
      analyticsLinks={Constants.analytics.ANALYTICS_LINKS.VIEW_PERKS}
      refLink="/myaccount/proXtraRewards"
      linkText="View Your Perks"
    >
      <Responsive minWidth={1024} data-testid="perk-amount">
        <Typography variant="body-base" weight="bold" height="normal">
          ${amount} Until Next Perk
        </Typography>
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="regular" height="normal">
          ${amount}
        </Typography>
        <Typography variant="body-base" weight="regular" height="normal">
          Until Next Perk
        </Typography>
      </Responsive>
      <Responsive maxWidth={767} data-testid="perk-amount">
        <Typography variant="body-lg" weight="regular" height="normal">
          ${amount} Until Next Perk
        </Typography>
      </Responsive>
    </WelcomeCardTileDynamicIcon>
  );
};

export const ProCreditCard = ({ perkSummary }) => {
  const perkData = perkSummary ? getAccrualData(perkSummary) : null;
  const amountRequired = perkData?.spendForNextPerkWithPxcc
    ? perkData?.spendForNextPerkWithPxcc
    : 0;
  const amount = floorNumberWithCommas(amountRequired, 0);
  const factor = perkSummary?.activePxccFactor ? perkSummary?.activePxccFactor : 4;
  return (
    <div className="sui-w-full">
      <a
        className="link-url sui-flex sui-flex-row sui-justify-between sui-w-full sui-py-3 sui-px-0"
        href={getHostCURL('/myaccount/proXtraRewards')}
        rel="noreferrer"
        onClick={() => {
          analyticsStash(
            'welcomeCard',
            Constants.analytics.ANALYTICS_MESSAGE.ProXtra_Credit_Card,
            Constants.analytics.ANALYTICS_LINKS.VIEW_PERKS
          );
        }}
      >
        <div className="notification-image notification-offer sui-mt-2">
          <Image
            src={getImageURL('WelcomeCard/active_perks_PLCC.svg')}
            width="46px"
            height="28px"
            alt="paintImage"
          />
        </div>
        <div className="sui-flex sui-flex-col sui-justify-center startAlign sui-mx-5 sui-w-full">
          <Responsive minWidth={1024}>
            <Typography variant="body-base" weight="bold" height="normal">
              ${amount} Until Next Perk
            </Typography>
            <Typography variant="body-base" weight="regular" height="normal">
              with Pro Xtra Credit Card
            </Typography>
          </Responsive>
          <Responsive minWidth={768} maxWidth={1023}>
            <Typography variant="body-base" weight="regular" height="normal">
              ${amount} Until Next Perk
            </Typography>
          </Responsive>
          <Responsive maxWidth={767}>
            <Typography variant="body-lg" weight="regular" height="normal">
              ${amount} Until Next Perk
            </Typography>
          </Responsive>
        </div>
        <div className="sui-flex sui-flex-row sui-justify-end sui-items-center sui-pr-0">
          <ArrowForward size="small" />
        </div>
      </a>
    </div>
  );
};
