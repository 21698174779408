import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const BuyAgain = createSvgIcon(
  <>
    <path d="m11.25 0 6 3.75-6 3.75V4.508a8.625 8.625 0 1 0 1.948 16.926l.604 2.167a10.88 10.88 0 0 1-2.927.399C4.869 24 0 19.131 0 13.125S4.869 2.25 10.875 2.25c.126 0 .25.002.375.006V0Z" />
    <path d="M20.25 4.5v2.25h1.124l.001 1.125V6.75H22.5V9h-3.375c-.789 0-1.479.227-1.943.573-.442.33-.682.762-.682 1.302 0 .687.474 1.236 1.292 1.686a6.146 6.146 0 0 0 1.536.582l.019.004h.001l.006.002.014.003a5.866 5.866 0 0 1 .191.044 8.396 8.396 0 0 1 1.983.771C22.6 14.55 24 15.687 24 17.625c0 1.435-.867 2.489-1.8 3.13a5.86 5.86 0 0 1-1.95.858V24H18v-2.25h-2.25V19.5h3.375c.51 0 1.227-.205 1.8-.599.567-.39.825-.836.825-1.276 0-.687-.474-1.236-1.292-1.686a6.146 6.146 0 0 0-1.536-.582l-.019-.004h-.002l-.005-.002-.014-.003a5.866 5.866 0 0 1-.191-.044 8.396 8.396 0 0 1-1.983-.771c-1.057-.582-2.458-1.72-2.458-3.658 0-1.335.646-2.403 1.586-3.104.624-.467 1.374-.772 2.164-.918V4.5h2.25Z" />
  </>,
  'BuyAgain'
);

export { BuyAgain };