import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleGrape = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleGrape.palette['--sui-palette-background-primary'] = '117 67 108';

export { HouseStyleGrape };