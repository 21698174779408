import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Key = createSvgIcon(
  <>
    <path d="M17.625 0a6.375 6.375 0 0 0-5.233 10.017L4.5 17.909 1.59 15 0 16.591l2.909 2.91L.02 22.386l1.591 1.591 12.371-12.37A6.375 6.375 0 1 0 17.625 0ZM13.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0Z" />
    <path d="M4.591 12 3 13.591l4.216 4.216 1.591-1.591L4.591 12Z" />
  </>,
  'Key'
);

export { Key };