import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Download = createSvgIcon(
  <>
    <path d="m12 18.74 7.966-7.854-1.58-1.602-5.261 5.187V0h-2.25v14.471L5.614 9.284l-1.58 1.602L12 18.74Z" />
    <path d="M2.25 21.74v-3.75H0v6h24v-6h-2.25v3.75H2.25Z" />
  </>,
  'Download'
);

export { Download };
