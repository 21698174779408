import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const InstantCheckout = createSvgIcon(
  <>
    <path d="M9.375 0H3.75v2.25h4.086L5.211 10.5H1.5v2.25h2.996L3.302 16.5H1.5v2.25h1.766A3.001 3.001 0 1 0 8.25 21c0-.896-.393-1.7-1.016-2.25h10.282A3.001 3.001 0 1 0 22.5 21c0-.896-.393-1.7-1.016-2.25H24V16.5H5.664l4.783-15.034A1.125 1.125 0 0 0 9.375 0Z" />
    <path d="M4.5 5.25H0V7.5h4.5V5.25Z" />
  </>,
  'InstantCheckout'
);

export { InstantCheckout };