export const carouselTypes = {
  PLA: 'pla',
  SEO: 'seo'
};

export const BANNER = 'banner';
export const PRODUCT = 'product';

export const adServerClients = {
  KEVEL: 'KEVEL',
  PIQ: 'PIQ',
};

export const bannerTypes = {
  HERO: 'hero',
  STANDARD: 'standard',
  LEADERBOARD: 'leaderboard',
  MIDDLE: 'middle', // pip,
  INGRID: 'ingrid',
  SKYSCRAPER: 'skyscraper'
};

export const PIP_BANNER_AB_TESTING_HEADER_KEY = 'use-data-model-for-targeting';
export const schemas = {
  SEARCH_SPONSORED: 'search_sponsored',
  BROWSE_SPONSORED: 'browse_sponsored',
  CAT_SPONSORED: 'cat_sponsored',
  PIP_SPONSORED: 'pip_sponsored',
  LOCALAD_SPONSORED: 'localad_sponsored',
  OC_SPONSORED: 'oc_sponsored',
  NO_RESULTS_FOUND_SPONSORED: 'no_results_found_sponsored',
  HP_SPONSORED: 'hp_sponsored',
  HP_SPONSORED_AUTH: 'hp_sponsored_auth',
  STOREPAGE_SPONSORED: 'storepage_sponsored',
  THANKYOUPAGE_SPONSORED: 'thankyoupage_sponsored',
  INGRID_SPONSORED: 'ingrid_sponsored',
  CART_SPONSORED: 'cart_sponsored',
  EVENT_BROWSE_SPONSORED: 'event_browse_sponsored',
  ATC_SPONSORED: 'atc_sponsored',
};

export const CLICK_CONVERSION_BEACON_TIME_TO_LIVE = 2419200000;
export const VIEW_CONVERSION_BEACON_TIME_TO_LIVE = 1209600000;
export const IS_KEVEL_TESTING_ENABLED = 'fs-prop:sponsored-isKevelTestingEnabled';
export const KEVEL_TEST_CATEGORY_GUIDS = 'fs-prop:sponsored-kevelTestCategoryGuids';
export const KEVEL_TEST_SLUGS = 'fs-prop:sponsored-kevelTestSlugs';
export const FORCE_KEVEL = 'fs-prop:sponsored-force-kevel';
export const SUPPRESSED_NVALUES = 'fs-prop:sponsored-content-suppressed-nvalues';