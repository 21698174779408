import React from 'react';
import { string as stringType, number, shape as shapeType } from 'prop-types';
import {
  params,
  arrayOf,
  string,
  fragment,
  shape,
  useDataModel,
  customType
} from '@thd-nucleus/data-sources';
import {
  Card, CardBody, CardMedia, Typography, Button
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { getImageUrl } from '../utils';

export function CapabilitiesTile({
  componentId,
  componentClass,
  id,
  componentPosition,
  livePreviewData,
}) {
  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  let dataComponent = livePreviewData || data?.component;

  let {
    description,
    link,
    title,
    cta,
    previewImage
  } = dataComponent || {};

  const imageSrcUrl = getImageUrl({
    damImage: previewImage
  });

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'CapabilitiesTile',
      component: 'CapabilitiesTile',
      type: 'content',
      position: componentPosition,
      category: title?.replace(/\W+/g, '')
    },
  });

  return (
    <div
      className="sui-grid sui-h-full"
      key={id}
      data-component="CapabilitiesTile"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <Card>
        <CardMedia
          data-contentful-entry-id={componentId}
          data-contentful-field-id="previewImage"
          aspect="wide"
          src={imageSrcUrl}
          alt={title}
        />
        <Typography
          variant="h4"
          weight="display"
          color="primary"
          data-contentful-entry-id={componentId}
          data-contentful-field-id="title"
        >
          {title}
        </Typography>
        <CardBody>
          <Typography
            variant="body-base"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="description"
          >
            {description}
          </Typography>
        </CardBody>
        {link && cta && (
          <Button
            href={link}
            variant="secondary"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="cta"
          >{cta}
          </Button>
        )}
      </Card>
    </div>
  );
}

CapabilitiesTile.propTypes = {
  componentId: stringType.isRequired,
  componentClass: stringType,
  id: stringType,
  componentPosition: number,
  livePreviewData: shapeType({
    id: stringType,
    title: stringType,
    cta: stringType,
    description: stringType,
    link: stringType,
    previewImage: shapeType({
      damContentSelector: shapeType({
        assetData: arrayOf(shapeType({
          selectedImageUrl: stringType
        }))
      }),
      damDownloadedContent: shapeType({
        url: stringType
      })
    }),
  }),
};

CapabilitiesTile.defaultProps = {
  componentClass: '',
  id: undefined,
  componentPosition: 0,
  livePreviewData: null,
};

CapabilitiesTile.displayName = 'Capabilities Tile';

CapabilitiesTile.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['CapabilitiesTile']).isRequired(),
  }).shape({
    CapabilitiesTile: fragment().shape({
      link: string(),
      id: string(),
      componentName: string(),
      title: string(),
      description: string(),
      cta: string(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      }),
    }),
  }),
};
