import React from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import {
  func, shape, string
} from 'prop-types';
import { SummaryAspects } from './SummaryAspects';
import { dataModel } from '../ReviewDataModel';

const ReviewSummary = ({ reviews }) => {
  return (
    <div>
      <Typography variant="body-base" height="tight">{reviews?.summary}</Typography>
    </div>
  );
};

ReviewSummary.propTypes = {
  reviews: shape({
    summary: string,
    positiveSummary: string,
    negativeSummary: string
  }).isRequired
};

export const MagicApron = ({ itemId, selectedAspect, onAspectClick }) => {

  const { data, loading, error } = useDataModel('reviewSummary', {
    variables: {
      itemId,
      modelName: 'combined_review_summary'
    }
  });

  if (loading || !data || !data?.reviewSummary) { return null; }

  if (!loading && error) { return null; }
  const {
    reviews,
    aspects
  } = data?.reviewSummary;

  return (
    <div
      data-component="magic-apron"
      id="magic-apron-review-summary"
      className="sui-grid sui-grid-cols-1 sui-gap-x-6 sui-p-3"
    >
      <div className="sui-flex sui-flex-col sui-gap-y-1 sui-pb-3">
        <div className="sui-h6-bold sui-flex">What Customers Say</div>
        <div className="sui-text-xs sui-leading-tight sui-text-subtle">
          Summarized by generative AI from text of customer reviews
        </div>
      </div>
      <div className="sui-grid sui-grid-cols-1 sui-gap-4 lg:sui-grid-cols-2 lg:sui-gap-6">
        <ReviewSummary reviews={reviews} />
        <SummaryAspects aspects={aspects} onAspectClick={onAspectClick} selectedAspect={selectedAspect} />
      </div>
    </div>
  );
};

MagicApron.dataModel = dataModel;

MagicApron.propTypes = {
  itemId: string.isRequired,
  selectedAspect: string,
  onAspectClick: func
};

MagicApron.defaultProps = {
  selectedAspect: null,
  onAspectClick: () => { }
};