import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * The main [growable](https://tailwindcss.com/docs/flex-grow) container for `Card`
 *
 * Related components: [Card](#card), [Typography](#typography)
 *
 * Usage:
 *
 * ```jsx
 * import { CardBody } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardBody = React.forwardRef((props, ref) => {

  const {
    children,
    padding = false,
    disableGrow = false,
    lineClamp
  } = props;

  const classes = classNames({
    'sui-grow': !disableGrow,
    'sui-line-clamp-4': lineClamp,
    'sui-p-4': padding
  });

  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  );
});

CardBody.displayName = 'CardBody';

CardBody.propTypes = {
  /**
   * If true, will truncate the text at 4 lines
   */
  lineClamp: PropTypes.bool,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, grow is removed.
   * @default false
   */
  disableGrow: PropTypes.bool,
  /**
   * If `true`, the padding is added.
   * @default false
   */
  padding: PropTypes.bool
};

CardBody.defaultProps = {
};

export { CardBody };