class PropTypes {
  constructor(type, opts = {}) {
    this.type = type;
    this._isRequired = false;
    this._isPropType = type !== 'Object';
    this._isArray = opts.isArray;
    this._client = false;

    if (opts.children) {
      this._children = opts.children;
    }
    if (opts.subType) {
      this._subType = opts.subType;
      if (opts.subType !== 'Object') {
        this._customType = true;
      }
    }

    if (opts.query) {
      this._query = opts.query;
    }
    this._value = this.toString();
    return this;
  }

  isRequired() {
    this._isRequired = true;
    this._value = this.toString();
    return this;
  }

  client() {
    this._clientResolver = true;
    this._value = this.toString();
    return this;
  }

  skip(variableName, defaultValue) {
    this._skip = {
      type: 'Boolean',
      _value: variableName,
      _defaultValue: defaultValue,
    };
    return this;
  }

  include(variableName, defaultValue) {
    this._include = {
      type: 'Boolean',
      _value: variableName,
      _defaultValue: defaultValue,
    };
    return this;
  }

  toString() {
    const type = this._subType || this.type;
    return `${type}${this._isRequired ? '!' : ''}${this._clientResolver ? ' @client' : ''}`;
  }

}

export { PropTypes };
