import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string } from 'prop-types';
import {
  PersonalizedHomeServices as PersonalizedHomeServicesComponent
} from '@thd-olt-component-react/personalized-home-services';
import { Card } from '@one-thd/sui-atomic-components';

import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function PersonalizedHomeServices({ id }) {
  const { channel } = useContext(ExperienceContext);

  return (
    <ErrorBoundary name="personalized-home-services">
      <Card
        id={`personalized-home-services-php-${id}`}
        className="sui-h-full"
        CardContentProps={
          { className: 'sui-flex sui-flex-col sui-w-full sui-h-full sui-gap-4 sui-p-4' }
        }
      >
        <PersonalizedHomeServicesComponent
          hideCarouselArrows={channel === 'mobile'}
          entryID={id}
        />
      </Card>
    </ErrorBoundary>
  );
}

PersonalizedHomeServices.propTypes = {
  id: string,
};

PersonalizedHomeServices.defaultProps = {
  id: undefined,
};
