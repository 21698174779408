import React, { memo } from 'react';

import { string } from 'prop-types';

import './PromoWarningMessage.scss';

export const PromoWarningMessage = memo(({ message }) => (
  <div className="promo-warning-message">
    <span>{message}</span>
  </div>
));

PromoWarningMessage.displayName = 'PromoWarningMessage';

PromoWarningMessage.propTypes = {
  message: string.isRequired
};
