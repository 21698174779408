export const windowExists = () => typeof window !== 'undefined';

const SIGN_IN_HOST_PATH = '/auth/view/signin';

const sessionStorageKeys = {
  recentlyViewedItems: 'rvData',
  recentlyViewedDatestamp: 'RVDateStamp',
};

const clearSessionStorageRVData = () => {
  sessionStorage.removeItem(sessionStorageKeys.recentlyViewedItems);
  sessionStorage.removeItem(sessionStorageKeys.recentlyViewedDatestamp);
};

const gatherAllParamsToString = (prefix = '') => {
  if (!windowExists()) return '';
  const urlParams = new URLSearchParams(window.location.search);
  const entriesListStringified = Array.from(urlParams?.entries())
    ?.map((entry) => `${entry[0]}=${entry[1]}`)
    ?.join('&');
  if (!entriesListStringified) return '';
  return `${prefix}${entriesListStringified}`;
};

const redirectToLogon = (url = '') => {
  if (!windowExists()) return;
  let redirectTo = '';
  const params = gatherAllParamsToString('&');
  if (url) redirectTo = `?redirect=${url}${params}`;
  const href = `${SIGN_IN_HOST_PATH}${redirectTo}`;

  clearSessionStorageRVData();
  window.location.href = href;
};

export const gotoLogonModule = () => {
  const redirectTo = windowExists() ? window.location.pathname : '';
  redirectToLogon(redirectTo);
};
