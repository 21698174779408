import React, { useState, useContext, Suspense } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import { useReviewPhotosDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Carousel } from '@thd-olt-component-react/carousel';
import { ComponentPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import { usePresentation } from '../../context/PresentationProvider';
import './CustomerImageCarousel.scss';
import * as analytics from '../../ratings-reviews-analytics';
import { dataModel } from '../ReviewDataModel';

// eslint-disable-next-line max-len
const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));
// eslint-disable-next-line max-len
const CustomerImageOverlay = React.lazy(() => import(/* webpackChunkName: "customer-image-overlay" */ '@thd-olt-component-react/customer-image-overlay')
  .then((module) => ({ default: module.CustomerImageOverlay })));

export const CustomerImageCarousel = ({ itemId }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);
  const { useCondensedLayout } = usePresentation();
  const { channel } = useContext(ExperienceContext);
  const { data, error, loading } = useReviewPhotosDeprecated({ itemId, ssr: false });

  if (loading) {
    return (
      <ComponentPlaceholder
        height={120}
        rows={4}
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  if ((error && !data) || !data) {
    return null;
  }
  const { reviewPhotos } = data || {};
  const { Results } = reviewPhotos || {};
  if (!(Results || []).length) {
    return null;
  }
  const photos = Results.reduce((images, review) => {
    return images.concat(review.Photos);
  }, []);

  const carouselContainer = classNames('customer-images', {
    'customer-images-condensed': useCondensedLayout
  });

  const carouselImage = classNames({
    'customer-images__carousel-normal': !useCondensedLayout,
    'customer-images__carousel-small': useCondensedLayout
  });

  const carouselHeader = classNames({
    'customer-images__mobile-header': useCondensedLayout
  });

  const carousel = classNames({
    'customer-images__mobile-carousel': useCondensedLayout
  });

  const onOverlayInteraction = () => {
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'media',
      value: 'gallery',
      eventType: 'click'
    });
  };

  const onCarouselClick = (index) => {
    setImageIndex(index);
    setOverlayOpen(true);
    onOverlayInteraction();
  };

  const onCloseHandler = () => setOverlayOpen(false);

  return (
    <div className={carouselContainer}>
      <Row>
        <Col xs={12} className={carouselHeader}>
          <span className="customer-image-title">Customer Images</span>
        </Col>
        <div className="customer-image-carousel-container">
          <Col xs={12} className={carousel} flatten>
            <Carousel
              itemClass={useCondensedLayout ? 'fixed-item-mobile' : 'fixed-item'}
              fixedItem
              multiItem={false}
              showDots={false}
              itemWidthPixelsDesktop={220}
              itemWidthPixelsMobile={160}
              onClickItem={onCarouselClick}
              selectedItem={imageIndex}
            >
              {
                photos.map((photo) => {
                  const thumbNailImg = `url(${photo?.Sizes?.normal?.Url})`;
                  return (
                    <div
                      key={thumbNailImg}
                      className={carouselImage}
                    >
                      <button
                        aria-label="View Image"
                        style={{
                          backgroundImage: (thumbNailImg)
                        }}
                        type="button"
                      />
                    </div>
                  );
                })
              }
            </Carousel>
          </Col>
        </div>
        {overlayOpen && (
          <Suspense fallback={(<div />)}>
            <Overlay medium={!useCondensedLayout} open={overlayOpen} onClose={onCloseHandler}>
              {overlayOpen && imageIndex >= 0 && photos[imageIndex] && (
                <Suspense fallback={(<div />)}>
                  <CustomerImageOverlay
                    channel={channel}
                    photos={reviewPhotos}
                    selectedPhotoID={photos[imageIndex].Id}
                    loopPhotos={photos?.length > 1}
                    onInteraction={onOverlayInteraction}
                    onClose={onCloseHandler}
                  />
                </Suspense>
              )}
            </Overlay>
          </Suspense>
        )}
      </Row>
    </div>
  );
};

CustomerImageCarousel.displayName = 'CustomerImageCarousel';

CustomerImageCarousel.propTypes = {
  itemId: string.isRequired,
};

CustomerImageCarousel.dataModel = dataModel;
