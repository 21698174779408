import React from 'react';
import { SkeletonBlock } from '@one-thd/sui-atomic-components';

export function TableItemsPlaceholder() {
  const imagePlaceholder = [...Array(3)].map((key, index) => (
    <React.Fragment key={`table-items-image-placeholder-${index}`}>
      <span className="sui-mr-2">
        <SkeletonBlock aspect="wide" height={16} width={16} />
      </span>
    </React.Fragment>
  ));
  return (
    <div className="sui-h-20 sui-items-center sui-flex">{imagePlaceholder}</div>
  );
}
