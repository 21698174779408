import { createTheme } from '@one-thd/sui-atomic-components';

const TertiaryHolidayGray = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

TertiaryHolidayGray.palette['--sui-palette-background-primary'] = '196 196 196';

export { TertiaryHolidayGray };