import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '../typography/Typography';
import { LinearProgress } from '../private/components/linear-progress/LinearProgress';
import { ConfirmedFilled } from '../private/icons/ConfirmedFilled';
import { ErrorFilled } from '../private/icons/ErrorFilled';
import { WarningFilled } from '../private/icons/WarningFilled';

const capMin = (min, value) => (value < min ? min : value);
const capMax = (max, value) => (value > max ? max : value);

const normalizeColor = (status) => {
  switch (status) {
    case 'success':
      return 'success';
    case 'warning':
      return 'warning';
    case 'error':
      return 'danger';
    default:
      return 'primary';
  }
};

/**
 * Progress bars show the completion status of a task or process. Progress bars
 * can provide system feedback, requiring no input from the user. They can also guide
 * the user through an interactive journey.
 *
 * Usage:
 *
 * ```jsx
 * import { ProgressBar } from '@one-thd/sui-atomic-components';
 * ```
 */
const ProgressBar = forwardRef((props, ref) => {

  const {
    component,
    disableStatusIcon = false,
    disableTransition = false,
    endLabel: endLabelProp,
    labelPlacement = 'top',
    size = 'regular',
    startLabel: startLabelProp,
    status,
    statusMessage,
    value,
    ...rest
  } = props;

  const ProgressBarRoot = component || 'div';

  const normalizedValue = capMax(100, capMin(0, value));

  const statusIcon = React.useMemo(() => {
    let icon;
    if (status === undefined || disableStatusIcon) return icon;

    if (status === 'success') {
      icon = (
        <ConfirmedFilled size="small" color="success" />
      );
    }
    if (status === 'error') {
      icon = (
        <ErrorFilled size="small" color="danger" />
      );
    }
    if (status === 'warning') {
      icon = (
        <WarningFilled size="small" color="warning" />
      );
    }
    return icon;
  }, [status, disableStatusIcon]);

  const startLabel = typeof startLabelProp === 'string'
    ? <div className="w-auto"><Typography variant="body-base" height="tight" lineClamp={1} truncate>{startLabelProp}</Typography></div>
    : startLabelProp;
  const endLabel = statusIcon || (
    typeof endLabelProp === 'string'
      ? <div className="w-auto"><Typography variant="body-base" height="tight" lineClamp={1} truncate>{endLabelProp}</Typography></div>
      : endLabelProp
  );

  const blockLabels = (startLabelProp || endLabelProp) ? (
    <div className="sui-flex sui-justify-between sui-gap-4">
      <div className="sui-justify-self-start sui sui-line-clamp-1">{startLabel}</div>
      <div className="sui-justify-self-end">{endLabel}</div>
    </div>
  ) : null;

  const statusClasses = classNames('sui-font-regular sui-text-xs sui-leading-none sui-text-left', {
    'sui-mt-1': labelPlacement === 'bottom',
    'sui-text-success': status === 'success',
    'sui-text-danger': status === 'error',
    'sui-text-primary': status === 'warning'
  });

  const trackClasses = classNames('sui-w-full sui-min-w-[88px]', {
    'sui-px-2': labelPlacement === 'inline',
    'sui-py-1': labelPlacement === 'top' || labelPlacement === 'bottom'
  });

  const topLabels = labelPlacement === 'top';
  const bottomLabels = labelPlacement === 'bottom';
  const inlineLabels = labelPlacement === 'inline';

  return (
    <ProgressBarRoot
      className="sui-w-full"
      {...rest}
    >
      {topLabels && blockLabels}
      <div
        className="sui-flex sui-items-center sui-gap-2"
        ref={ref}
      >
        {inlineLabels && startLabel}
        <div className={trackClasses}>
          <LinearProgress
            value={normalizedValue}
            color={normalizeColor(status)}
            disableTransition={disableTransition}
            size={size}
          />
        </div>
        {inlineLabels && endLabel}
      </div>
      {bottomLabels && blockLabels}
      {statusMessage
        && (
          <div className={statusClasses}>
            <Typography variant="body-xs" height="normal">{statusMessage}</Typography>
          </div>
        )}
    </ProgressBarRoot>
  );
});

ProgressBar.displayName = 'ProgressBar';

ProgressBar.propTypes = {
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the status icon is removed from displaying.
   * @default false
   */
  disableStatusIcon: PropTypes.bool,
  /**
   * If `true`, the transition is disabled.
   * This is useful if the parent component updates the value property too quickly.
   * @default false
   */
  disableTransition: PropTypes.bool,
  /**
   * End label for this component.
   * In most cases will simply be a string containing a title for the label.
   */
  endLabel: PropTypes.node,
  /**
   * Define where the start and end labels are placed.
   * @default top
   */
  labelPlacement: PropTypes.oneOf(['top', 'bottom', 'inline']),
  /**
   * The height of the progressbar's track.
   * @default regular
   */
  size: PropTypes.oneOf(['regular', 'large']),
  /**
   * Start label for this component.
   * In most cases will simply be a string containing a title for the label.
   */
  startLabel: PropTypes.node,
  /**
   * The states of validation for the Step component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The status message content.
   */
  statusMessage: PropTypes.string,
  /**
   * A value between 0 and 100 that represents the percentage of completion
   */
  value: PropTypes.number,
};

export { ProgressBar };
