import React, { useEffect, useRef, useReducer } from 'react';
import { func, bool, string } from 'prop-types';
import {
  params,
  string as stringType,
  useLazyDataModel
} from '@thd-nucleus/data-sources';
import {
  Button, Drawer, DrawerBody, DrawerHeader, DrawerFooter, TextField, Typography
} from '@one-thd/sui-atomic-components';
import { Locked } from '@one-thd/sui-icons';
import { LIST_SHAPE, triggerAnalytics, validateListName } from '../helpers';
import { LIST_CREATE_WO_PRODUCTS, LIST_ALREADY_EXISTS_ERROR, LIST_COPY, LIST_SHARED_SAVE_CLICK } from '../constants';

export const CreateAList = (props) => {

  const {
    onCreate,
    CustomCreateListActionElement,
    isCopyList,
    listId,
    isSharedList
  } = props;

  const createListBtnRef = useRef(null);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('CreateAList.ready');
  }, []);

  const initialValues = {
    listName: '',
    listNameError: false,
    listNameValid: false,
  };

  const [formValues, setFormValues] = useReducer(
    (currentFormValues, newFormValues) => ({ ...currentFormValues, ...newFormValues }), initialValues
  );

  const listForm = useRef();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!open && createListBtnRef.current) {
      createListBtnRef.current.blur();
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
    if (isSharedList) {
      triggerAnalytics(LIST_SHARED_SAVE_CLICK, {});
    }
  };
  const handleClose = () => {
    setOpen(false);
    setFormValues(initialValues);
  };

  const {
    listName,
    listNameError,
    listNameErrorMessage,
    listNameValid
  } = formValues;

  const [createList, createListResponse] = useLazyDataModel('createList', {
    variables: {
      name: listName
    },
    context: { withAuth: true }
  });

  const [copyList, copyListResponse] = useLazyDataModel('copyList', {
    variables: {},
    context: { withAuth: true }
  });

  const handleFormSubmit = async (submitEvent) => {
    submitEvent.preventDefault();

    if (!listNameValid) {
      return;
    }

    if (isCopyList) {
      await copyList({
        variables: { name: listName, id: listId }
      });
    } else {
      await createList({
        variables: { name: listName }
      });
    }
  };

  const checkForAndSetAlreadyExistError = (error) => {
    const gqlErrors = error?.graphQLErrors;
    let errorMessage = '';
    gqlErrors.forEach(function (err, index) {
      if (err?.message === LIST_ALREADY_EXISTS_ERROR) {
        errorMessage = LIST_ALREADY_EXISTS_ERROR;
      }
    });
    setFormValues({
      listNameValid: false,
      listNameError: true,
      listNameErrorMessage: errorMessage
    });
  };

  useEffect(() => {
    if (createListResponse?.data?.createList && createListResponse?.data?.createList?.name) {
      triggerAnalytics(LIST_CREATE_WO_PRODUCTS, {});
      onCreate(createListResponse?.data?.createList);
      handleClose();
    } else if (createListResponse?.error) {
      checkForAndSetAlreadyExistError(createListResponse?.error);
    }
  }, [createListResponse?.data, createListResponse?.error]);

  useEffect(() => {
    if (copyListResponse?.data?.copyList && copyListResponse?.data?.copyList?.id) {
      triggerAnalytics(LIST_COPY, {});
      onCreate(copyListResponse?.data?.copyList);
      handleClose();
    } else if (copyListResponse?.error) {
      checkForAndSetAlreadyExistError(copyListResponse?.error);
    }
  }, [copyListResponse?.data, copyListResponse?.error]);

  const handleListNameChange = (event) => {
    const { id, value } = event.target;
    const { isError, errorMessage } = validateListName(value);

    const truncated = value.slice(0, 50);
    setFormValues(
      {
        [id]: truncated,
        listNameValid: !isError,
        listNameError: isError,
        listNameErrorMessage: errorMessage
      }
    );
  };

  return (
    <div className="sui-flex sui-justify-center" data-component="CreateAList">
      {CustomCreateListActionElement
        ? <CustomCreateListActionElement onClick={handleOpen} />
        : (
          <Button
            fullWidth
            variant="primary"
            onClick={handleOpen}
            aria-label="Create New List"
            data-testid="create-new-list"
            ref={createListBtnRef}
          >
            Create New List
          </Button>
        )}
      <Drawer open={open} onClose={handleClose}>
        <DrawerHeader title="Create New List" onClose={handleClose} />
        <DrawerBody data-component="CreateAList">
          <div className="sui-flex sui-gap-2 sui-mb-6">
            <Locked color="primary" />
            <Typography>
              Your list can only be seen by you or people you share it with
            </Typography>
          </div>
          <form
            className="sui-grid sui-gap-6"
            autoComplete="off"
            noValidate
            onSubmit={handleFormSubmit}
            ref={listForm}
          >
            <TextField
              required
              id="listName"
              label="List Name"
              type="text"
              fullWidth
              value={listName}
              onChange={handleListNameChange}
              status={listNameError ? 'error' : null}
              statusMessage={listNameError ? listNameErrorMessage : null}
            />
          </form>
        </DrawerBody>
        <DrawerFooter position="center">
          <Button
            variant="primary"
            fullWidth
            onClick={handleFormSubmit}
            disabled={!listNameValid}
            type="submit"
          > Create List
          </Button>
        </DrawerFooter>
      </Drawer>
    </div>
  );
};

CreateAList.propTypes = {
  // callback when user clicks the form button
  // return parameters: { id, listAccessType, list }
  onCreate: func.isRequired,
  isCopyList: bool,
  CustomCreateListActionElement: func,
  listId: string,
  isSharedList: bool,
};

CreateAList.defaultProps = {
  isCopyList: false,
  CustomCreateListActionElement: null,
  listId: null,
  isSharedList: false,
};

CreateAList.displayName = 'CreateAList';

CreateAList.dataModel = {
  createList: params({
    name: stringType().isRequired()
  }).mutation().shape(LIST_SHAPE),
  copyList: params({
    id: stringType().isRequired(),
    name: stringType().isRequired()
  }).mutation().shape({
    id: stringType()
  })
};
