/* eslint-disable */
import React, { useContext } from 'react';
import { HeroCarousel } from './HeroCarousel';
import { FallbackSection } from './FallbackSection';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from '../context/HomepageContext';
import { CUSTOMER_TYPES } from '../../utils';

export function SectionSelector(props) {
  if (!props.children || props.children.length === 0) return null;
  
  const {
    customRenderId,
  } = props;

  const { customerType, proCustomerSegment } = useContext(HomepageContext);
  const isB2B = customerType === CUSTOMER_TYPES.B2B;
  const proAnalyticsData = {};
  
  if (isB2B) {
    proAnalyticsData.isB2B = true;
    proAnalyticsData.templates = proCustomerSegment;
  }

  switch (customRenderId) {
    case 'Hero Carousel': {
      return (
        <ErrorBoundary name="section-hero-carousel">
            <HeroCarousel {...props} />
        </ErrorBoundary>
      );
    }

    default: {
      return (
        <ErrorBoundary name="section-fallback">
          <FallbackSection {...props} />
        </ErrorBoundary>
      );
    }
  }
}
