/* eslint-disable no-use-before-define */
/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
// import {
//   initEventRecorder,
//   addComparatorHeader,
//   captureRequest
// } from '../../overlay/api/event-recorder/EventRecorder';
import { useState, useEffect } from 'react';
import {
  isRecord,
  isAutomation,
  loadAutomationLibs
} from './AutomationLibsLoader';

// this will be assigned functions when automation-libs loads
// eslint-disable-next-line prefer-const
let loadedAutoLibs = {
  captureRequest: null,
  wrappedFetch: null,
  wrappedResp: null
};

export const getFetchWrapper = (fetchJsonp = null) => {
  try {
    if (loadedAutoLibs.wrappedFetch) {
      if (fetchJsonp) {
        console.warn('Not expecting any fetchJsonp???');
        return fetchJsonp;
      }
      return loadedAutoLibs.wrappedFetch;
    }
  } catch (err) {
    console.error('Failed to fetch wrapper', err);
  }
  // fall back on native fetch
  return fetchJsonp || window.fetch;
};

export const getWrappedResponse = () => {
  try {
    if (isRecord() && loadedAutoLibs.wrappedResp) {
      return loadedAutoLibs.wrappedResp;
    }
  } catch (err) {
    console.error('Failed to initialize automation hooks', err);
  }
  // return orig resp
  return ({ response }) => (response);
};

export const captureRequestEvent = (reqName, variables, resp, type) => {
  try {
    if (typeof window !== 'undefined' && window.isUnitTest) {
      resp.variables = variables;
      resp.reqName = reqName;
      resp.type = type;
      return;
    }
  } catch (err) {
    console.error('Error capturing request event', err);
  }

  try {
    if (isRecord() && loadedAutoLibs.captureRequest) {
      const userAction = {
        // id: UNIQUE_ID,
        eventId: Date.now(),
        type,
        // name,
        // value,
        // elementType
      };

      // loadedAutoLibs.captureRequest(url, body, resp, userAction);
      loadedAutoLibs.captureRequest(reqName, variables, resp);
      // document.addEventListener('captureUserEvent', ({ type, name, value, elementType })
    }
  } catch (err) {
    console.error('Error capturing request event', err);
  }
};

export const useCaptureRequestEvent = (response) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, loading, error } = response;
  useEffect(() => {
    try {
      if (loading !== isLoading) {
        if (isLoading && !loading) {
          captureRequestEvent('getCart', {}, { data, error });
        }
        setIsLoading(loading);
      }
    } catch (err) {
      console.error(err);
    }

  }, [loading]);
};

export const initAutomationHooks = ({ addNewRelicPageAction = () => {} }) => {
  try {
    if (isAutomation() || isRecord()) {
      const dataReadyPromise = new Promise((resolve) => {
        loadAutomationLibs({
          dataReadyResolver: resolve,
          loadedAutoLibs,
          addNewRelicPageAction
        });
      });
      return dataReadyPromise;
    }
  } catch (err) {
    console.error('Failed to initialize automation hooks', err);
  }
  let immediateResolve = null;
  const immediatePromise = new Promise((resolve) => {
    immediateResolve = resolve;
  });
  immediateResolve();
  // fall back on immediate resolution
  return immediatePromise;
};