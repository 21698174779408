/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { handleUpdateCall, useCartMutation } from './helpers/mutation-helper';
import { optimalOverride, getTmxId, getExchangeCartId } from '../util/utils';

export const useDeleteLineItem = () => {
  const [deleteLineItem, deleteItemResponse] = useCartMutation('deleteItem');

  // mutation wrapper
  const deleteItems = async (idList, skipOptimistic, onComplete = null) => {
    // build request variables
    const variables = {
      cartRequest: {
        items: {
          itemReq: [],
        }
      },
      requestContext: {
        ...optimalOverride(),
        ...(getExchangeCartId() && { cartId: getExchangeCartId() }),
        ...(getTmxId() && { tmxProfileId: getTmxId() })
      }
    };
    variables.cartRequest.items.itemReq = idList.map((id) => ({ id }));

    // returns promise
    return handleUpdateCall({
      mutationName: 'deleteItem',
      mutator: deleteLineItem,
      variables,
      optimisticResponse: null,
      onComplete
    });
  };

  return [deleteItems, deleteItemResponse];
};
