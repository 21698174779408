import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
import {
  arrayOf, bool, func, node, string, oneOfType
} from 'prop-types';
import { CloseButton } from './CloseButton';
import styles from './overlay.module.scss';

const cx = classNames.bind(styles);

const ESCAPE_KEYCODE = 27;

const Overlay = ({
  children,
  className,
  closeButton,
  el = 'div',
  fit,
  header,
  medium = false,
  modalClass,
  onClose,
  open,
  placement,
  positionedCloseButton = false,
  scrollable
}) => {

  // eslint-disable-next-line
  const [container] = useState(typeof window !== 'undefined' && document.createElement(el));
  // eslint-disable-next-line
  const [componentDidMount, setComponentDidMount] = useState(false);

  // eslint-disable-next-line
  const handleEscape = useCallback((keyEvent) => {
    const escapeClick = (keyEvent && keyEvent.keyCode === ESCAPE_KEYCODE);
    if (escapeClick && onClose) {
      onClose();
      window.history.back();
    }
  }, []);

  // eslint-disable-next-line
  const enableNoScroll = useCallback((isOpen) => {
    if (isOpen) {
      document.body.classList.add(styles['overlay--noscroll']);
    }
  }, []);

  // eslint-disable-next-line
  useEffect(() => { setComponentDidMount(true); }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (open) {
      window.history.pushState({}, 'Overlay', '#overlay');
      document.addEventListener('keydown', handleEscape, false);
    }
    document.body.appendChild(container);
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', onClose, false);
    }
    enableNoScroll(open);
    return () => {
      document.body.removeChild(container);
      document.removeEventListener('keydown', handleEscape, false);
      window.removeEventListener('popstate', onClose, false);
      document.body.classList.remove(styles['overlay--noscroll']);
    };
  }, [open]);

  // eslint-disable-next-line
  const handleBackdropClick = useCallback((clickEvent) => {
    const backdropClick = (
      clickEvent
      && clickEvent.target
      && clickEvent.target.classList
      && clickEvent.target.classList.contains(styles.overlay)
    );
    if (backdropClick && onClose) {
      onClose();
      window.history.back();
    }
  }, []);

  const overlayClasses = cx('overlay', {
    'overlay--open': open,
    'overlay--closed': !open
  }, className);

  const modalClasses = cx('modal', {
    'modal--scrollable': scrollable,
    'modal--medium': medium,
    'modal--fit': fit
  }, modalClass);

  const modalHeaderClasses = cx({
    // eslint-disable-next-line
    'modal__header': header
  });

  return componentDidMount && ReactDOM.createPortal(
    // needed for backdrop click
    // eslint-disable-next-line
    <div className={overlayClasses} onClick={(event) => handleBackdropClick(event)} role="main">
      <div className={modalClasses}>
        {!positionedCloseButton && (
          <div className={modalHeaderClasses}>
            {header && <h2 className={styles['modal__header--title']}>{header}</h2>}
            {closeButton
            && (
              <CloseButton
                onClose={onClose}
                header={header}
                placement={placement}
              />
            )}
          </div>
        )}
        {closeButton && positionedCloseButton
        && (
          <CloseButton
            onClose={onClose}
            header={header}
            placement={placement}
            positionedCloseButton
          />
        )}
        {children}
      </div>
    </div>, container);
};

Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  className: string,
  closeButton: bool,
  fit: bool,
  header: string,
  medium: bool,
  modalClass: string,
  onClose: func.isRequired,
  open: bool,
  scrollable: bool
};

Overlay.defaultProps = {
  children: null,
  className: null,
  closeButton: false,
  fit: false,
  header: null,
  medium: false,
  modalClass: null,
  open: false,
  scrollable: true
};

export { Overlay };
