import { useMemo } from 'react';

import { usePromoCart } from './usePromoCart';
import { usePromoModel } from './usePromoModel';

// TODO move all item selection logic into here
export const useSelectedItems = () => {
  const {
    bogoSelectedProduct,
    selectedItemsModel,
  } = usePromoCart();
  const {
    anchorItem,
    isForwardsBogo,
    isBackwardsBogo,
    isMultiSelect,
  } = usePromoModel();

  return useMemo(() => {
    const selectedItemIds = [];
    if (isForwardsBogo || isBackwardsBogo) {
      selectedItemIds.push(anchorItem.itemId);
      if (bogoSelectedProduct) selectedItemIds.push(bogoSelectedProduct.itemId);
    }
    if (isMultiSelect) {
      selectedItemIds.push(...selectedItemsModel.map(({ itemId }) => itemId));
    }

    return Object.freeze({
      selectedItemIds: Object.freeze(selectedItemIds)
    });
  }, [
    anchorItem.itemId,
    bogoSelectedProduct,
    isForwardsBogo,
    isBackwardsBogo,
    isMultiSelect,
    selectedItemsModel
  ]);
};
