import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Note = createSvgIcon(
  <>
    <path d="M18 7.5H9v2.25h9V7.5Zm-3 6H9v2.25h6V13.5Z" />
    <path d="M22.5 0H3v4.5H1.5v2.25H3v4.125H1.5v2.25H3v4.125H1.5v2.25H3V24h19.5V0ZM5.25 19.5h1.5v-2.25h-1.5v-4.125h1.5v-2.25h-1.5V6.75h1.5V4.5h-1.5V2.25h15v19.5h-15V19.5Z" />
  </>,
  'Note'
);

export { Note };