import React from 'react';
import { Card, SkeletonBlock, SkeletonLine } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { RecentPurchasesCardHeader } from '../../subcomponents/cardHeader/RecentPurchasesHeader';

export function RecentPurchasesCardPlaceholder() {
  const totalOrders = 2;

  let cardContent = [];

  const imagePlaceholder = (cardNumber) => {

    return [...Array(3)].map((key, index) => (
      <React.Fragment key={`recentPurchasesCard${cardNumber}${index}`}>
        <span className="sui-mr-2">
          <SkeletonBlock aspect="wide" height={16} width={16} />
        </span>
      </React.Fragment>
    ));
  };

  for (let i = 0; i < totalOrders; i += 1) {
    cardContent.push(
      <div
        key={`recentPurchasesCardContent${i}`}
        role="group"
        data-testid="placeholderCardWrapper"
        className="sui-w-full sui-flex sui-flex-col sui-border-1 sui-border-solid sui-border-primary sui-rounded-sm"
      >
        <div
          className="sui-pt-4 sui-px-4 sui-pb-0"
          key={`recentPurchasesCarouselSectionOne${i}`}
        >
          <SkeletonLine numberOfLines={4} />
        </div>

        <hr className="sui-w-full sui-mt-4 sui-mb-3 sui-mx-0 sui-border-t-1 sui-border-solid sui-border-primary" />

        <div className="sui-py-0 sui-px-4">
          <div
            className="sui-flex"
            key={`recentPurchasesCarouselSectionTwo${i}`}
          >
            {imagePlaceholder(i)}
          </div>
        </div>

        <hr className="sui-w-full sui-mt-4 sui-mb-0 sui-mx-0 sui-border-t-1 sui-border-solid sui-border-primary" />

        <div className="sui-h-full sui-py-0 sui-px-4 sui-flex sui-justify-around sui-items-center">
          <div
            className="sui-py-2"
            key={`recentPurchasesCarouselSectionThree${i}`}
          >
            <SkeletonBlock aspect="wide" height={8} width={32} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Card className="sui-h-full sui-w-full placeholderRecentPurchasesCardContainer" data-component="RecentPurchasesCardPlaceholder">
      <RecentPurchasesCardHeader />
      <Carousel disableShadow slidesPerView={1}>
        {cardContent}
      </Carousel>
    </Card>
  );
}
