import classnames from 'classnames';

export const getGridColumnsClassName = (children) => {
  if (!children.length) {
    return 'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-1 md:sui-grid-cols-1';
  }
  return classnames({
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-4':
       children.length >= 4,
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-3':
       children.length === 3,
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-2':
       children.length === 2,
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-1 md:sui-grid-cols-1':
       children.length <= 1,
  });
};

export const publish = (data) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(
      'capabilities-tiles-click',
      data
    );
  }
};

export function getImageUrl({ damImage }) {
  const selectedImgUrl = (
    damImage?.damContentSelector
    && damImage.damContentSelector.assetData?.[0]?.selectedImageUrl
  );

  const downloadedContentUrl = (
    damImage?.damDownloadedContent
    && damImage.damDownloadedContent.url
  );

  return (selectedImgUrl || downloadedContentUrl);
}