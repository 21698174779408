import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, shape, string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { TrendingCategories } from '@thd-olt-component-react/thd-recs-containers';

export function TrendingInYourNeighborhood({ store, slidesPer, id }) {
  const { channel } = useContext(ExperienceContext);

  const { storeId, storeZip: zipCode } = store;

  return (
    <ErrorBoundary name="trending">
      <QueryProvider cacheKey="trending">
        <Card
          id={`trending-in-your-neighborhood-php-${id}`}
          className="sui-h-full"
        >
          <CardTitle header={
            <Typography variant="h3">Trending In Your Neighborhood</Typography>
          }
          />
          <TrendingCategories
            entryId={id}
            slidesPer={slidesPer}
            showLoading
            zipCode={+zipCode}
            storeId={storeId}
            hideTitle
            showFallback
            hideControls={channel === 'mobile'}
          />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

TrendingInYourNeighborhood.propTypes = {
  store: shape({
    storeId: string,
    storeZip: string,
  }),
  slidesPer: number,
  id: string,
};

TrendingInYourNeighborhood.defaultProps = {
  store: {
    storeId: '121',
    storeZip: '30339',
  },

  slidesPer: undefined,
  id: undefined,
};
