import { useEffect } from 'react';
import { useDataModel } from './useDataModel';

export const useLazyDataModel = (queryName, opts) => {

  const [exec, response, cachedVariables] = useDataModel(queryName, opts, true);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS && response.data && !response.loading) {
      const { data, variables } = response;

      window.LIFE_CYCLE_EVENT_BUS.trigger('data-sources.data', {
        type: 'product',
        queryName,
        variables,
        data
      });
    }
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS && response.error && !response.loading) {
      const { data, error, variables } = response;

      window.LIFE_CYCLE_EVENT_BUS.trigger('data-sources.error', {
        type: 'product',
        queryName,
        variables,
        data,
        message: `GQL Error: ${queryName} - ${error?.message}`
      });
    }
  }, [response?.loading]);

  return [exec, response, cachedVariables];
};
