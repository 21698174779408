import { shape, number, bool, string } from '@thd-nucleus/data-sources';

export const availablePerksCountModel = {
  availablePerks: number(),
  expiringPerks: number(),
  daysLeft: number(),
  availableOffers: number(),
  expiringOffers: number(),
  daysLeftForOffers: number(),
  enrolledInChallenges: bool(),
  challengeTitles: string(),
};
