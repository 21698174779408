/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { forwardRef } from 'react';
import { node } from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';

/**
 * A thin wrapper around the Stencil Link component. All props from Link are accessible except for `component`
 * which has been set to `"button"` for accessibility.
 */
export const LinkButton = forwardRef(({ children, ...rest }, ref) => {
  return (
    <Link
      component="button"
      ref={ref}
      {...rest}
    >
      {children}
    </Link>
  );
});

LinkButton.displayName = 'LinkButton';

LinkButton.propTypes = {
  children: node
};

LinkButton.defaultProps = {
  children: null
};
