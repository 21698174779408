import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Catalog = createSvgIcon(
  <>
    <path d="M3.75 6H9v2.25H3.75V6ZM9 11.25H3.75v2.25H9v-2.25Z" />
    <path d="M9 0c1.182 0 2.248.497 3 1.294A4.114 4.114 0 0 1 15 0h9v21h-7.875c-.527 0-1.29.349-1.97 1.03-.681.68-1.03 1.443-1.03 1.97h-2.25c0-.527-.349-1.29-1.03-1.97-.68-.681-1.443-1.03-1.97-1.03H0V0h9Zm1.875 19.937V4.125c0-1.036-.84-1.875-1.875-1.875H2.25v16.5h5.625c1.091 0 2.151.509 3 1.187Zm2.25 0c.849-.678 1.909-1.187 3-1.187h5.625V2.25H15c-1.036 0-1.875.84-1.875 1.875v15.812Z" />
  </>,
  'Catalog'
);

export { Catalog };