import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Settings = createSvgIcon(
  <>
    <path d="M12 6.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" />
    <path d="M14.348 0H9.652L8.064 2.78l-.028.012-2.93-.977-3.29 3.29.976 2.931-.012.028L0 9.652v4.696l2.78 1.588.012.028-.977 2.93 3.29 3.291 2.93-.977.029.012L9.652 24h4.696l1.588-2.78.028-.012 2.93.977 3.29-3.29-.976-2.93.012-.029L24 14.348V9.652l-2.78-1.588-.012-.028.977-2.93-3.29-3.29-2.93.976-.029-.012L14.348 0ZM9.623 4.586l1.335-2.336h2.084l1.335 2.336.377.143c.222.084.44.178.651.282l.413.201 2.47-.822 1.323 1.323-.823 2.469.201.413c.104.212.198.429.282.65l.143.378 2.336 1.335v2.084l-2.336 1.335-.143.377a7.703 7.703 0 0 1-.282.651l-.201.413.823 2.47-1.324 1.323-2.469-.823-.413.201a7.719 7.719 0 0 1-.65.282l-.378.143-1.335 2.336h-2.084l-1.335-2.336-.377-.143a7.71 7.71 0 0 1-.651-.282l-.413-.201-2.469.823-1.324-1.324.823-2.469-.201-.413a7.716 7.716 0 0 1-.282-.65l-.143-.378-2.336-1.335v-2.084l2.336-1.335.143-.377c.084-.222.178-.44.282-.651l.201-.413-.823-2.469 1.324-1.324 2.469.823.413-.201c.212-.104.429-.198.65-.282l.378-.143Z" />
  </>,
  'Settings'
);

export { Settings };