import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Camera = createSvgIcon(
  <>
    <path d="M12 8.375a4.625 4.625 0 1 0 0 9.25 4.625 4.625 0 0 0 0-9.25ZM9.625 13a2.375 2.375 0 1 1 4.75 0 2.375 2.375 0 0 1-4.75 0Z" />
    <path d="M17.262 1.375H6.738l-2 5H1.5C.879 6.375.375 6.879.375 7.5v14c0 .621.504 1.125 1.125 1.125h21c.621 0 1.125-.504 1.125-1.125v-14c0-.621-.504-1.125-1.125-1.125h-3.238l-2-5Zm-11 7.25 2-5h7.476l2 5h3.637v11.75H2.625V8.625h3.637Z" />
  </>,
  'Camera'
);

export { Camera };