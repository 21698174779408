import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../../../components/helper/utils';
import { InStockIcon } from '../../helpers/icon-utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import {
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';

export const BOSS = ({
  displayQuantity,
  customRender
}) => {

  let messagingSuccess = 'Free ';
  let messagingPrimary = 'ship to store';

  if (displayQuantity > 0) {
    const formattedQty = formatSellableQuantity(displayQuantity);
    messagingSuccess = `${formattedQty} `;
    messagingPrimary = 'available for free ship to store';
  }

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.BOSS,
        icon: InStockIcon,
        values: { messagingSuccess, messagingPrimary, displayQuantity } });
  }

  return (
    <div className="store__message">
      <div className="store__icon">{InStockIcon}</div>
      <div className="store__text-box">
        <div>Pickup</div>
        <div className="store__second-line">
          <span className="store__success">{messagingSuccess}</span>
          <span className="store__primary">{messagingPrimary}</span>
        </div>
      </div>
    </div>
  );
};

BOSS.propTypes = {
  displayQuantity: PropTypes.number,
  customRender: PropTypes.func
};

BOSS.defaultProps = {
  displayQuantity: 0,
  customRender: null
};
