import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Returns = createSvgIcon(
  <>
    <path d="M20.25 0v8.25h-6v2.945l-4.125-2.062L6 11.195V8.25H3.75V18h4.5v2.25H1.5v-12H0V0h20.25ZM12 2.25H8.25v5.305l1.875-.938L12 7.555V2.25Zm-6 0H2.25V6H6V2.25Zm8.25 0V6H18V2.25h-3.75Z" />
    <path d="M21.75 15.75a3 3 0 0 0-3-3H16.5V10.5h2.25a5.25 5.25 0 1 1 0 10.5h-3v3l-6-4.125 6-4.125v3h3a3 3 0 0 0 3-3Z" />
  </>,
  'Returns'
);

export { Returns };