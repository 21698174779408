export function impressionAnalytics({ impressionData }) {
  const editedImpressionData = { ...impressionData };
  delete editedImpressionData.ref;
  return new Promise((resolve, reject) => {
    resolve(
      window.top.LIFE_CYCLE_EVENT_BUS.trigger(
        'impression.trigger',
        editedImpressionData
      )
    );
  });
}
