import React from 'react';
import { func, oneOf } from 'prop-types';

const ArrowButtons = (props) => {
  const { direction, onClick } = props;
  const isPrev = direction === 'left';
  return (
    <button
      type="button"
      className={
        isPrev ? 'carousel__prev' : 'carousel__next'
      }
      onClick={onClick}
    >
      <svg
        className={`carousel__${isPrev ? 'prev' : 'next'}-button`}
        viewBox="0 0 12 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssketch="http://www.bohemiancoding.com/sketch/ns"
      >
        <defs />
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          sketchtype="MSPage"
        >
          {isPrev
            ? (
              <path
                d="M3.35738643,4.0642103 L3.35738643,3.09586423 L17.8582152,3.09586423 L17.8582152,5.03255637 L5.28304981,5.03255637 L5.28304981,17.6355953 L3.35738643,17.6355953 L3.35738643,4.0642103 Z"
                id="back-arrow"
                fill="#F96302"
                sketchtype="MSShapeGroup"
                transform="translate(10.607801, 10.365730) rotate(-45.000000) translate(-10.607801, -10.365730) "
              />
            )
            : (
              <path
                d="M-5.88468462,4.30628135 L-5.88468462,3.33793528 L8.61614419,3.33793528 L8.61614419,5.27462742 L-3.95902124,5.27462742 L-3.95902124,17.8776664 L-5.88468462,17.8776664 L-5.88468462,4.30628135 Z"
                id="next-arrow"
                fill="#F96302"
                sketchtype="MSShapeGroup"
                transform="translate(1.365730, 10.607801) rotate(-225.000000) translate(-1.365730, -10.607801) "
              />
            )}
        </g>
      </svg>
    </button>
  );
};

ArrowButtons.propTypes = {
  direction: oneOf(['left', 'right']),
  onClick: func.isRequired
};

ArrowButtons.defaultProps = {
  direction: 'left'
};

export { ArrowButtons };
