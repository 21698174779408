import React from 'react';
import { CardTitle, Typography } from '@one-thd/sui-atomic-components';

export const RecentOrdersCardHeader = () => {
  return (
    <CardTitle
      data-testid="recentOrders-header"
      header={(
        <Typography variant="h3" weight="regular">Your Latest Orders</Typography>
      )}
    />
  );
};
