import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';

import { Typography } from '@one-thd/sui-atomic-components';
import { CardStatusMessage } from './StatusMessage';
import { EstimatedDeliveryDate } from './EstimatedDeliverDate';

export const OrderStatus = ({
  orderStatus,
  shippingDetails,
  showEstimatedDelivery
}) => {

  const estimatedDeliveryDate = shippingDetails?.shipments?.[0]?.shipment?.estimatedDeliveryDate;

  const formattedDate = estimatedDeliveryDate
    ? new Date(estimatedDeliveryDate)
    : null;

  return (
    <div className="sui-pt-4 sui-pb-4">
      <Typography variant="body-base" weight="bold">
        Order Status
      </Typography>
      <CardStatusMessage
        orderStatus={orderStatus}
      />
      {showEstimatedDelivery
        && (
          <EstimatedDeliveryDate
            estimatedDeliveryDate={formattedDate}
          />
        )}
    </div>
  );
};

OrderStatus.propTypes = {
  orderStatus: string,
  shippingDetails: shape({
    shipments: arrayOf(shape({
      shipment: shape({
        estimatedDeliveryDate: string
      })
    }))
  }),
  showEstimatedDelivery: bool
};

OrderStatus.defaultProps = {
  orderStatus: '',
  shippingDetails: {
    shipments: [
      {
        estimatedDeliveryDate: null
      }
    ]
  },
  showEstimatedDelivery: true
};