/* eslint-disable no-console */
export const EVENTS = {
  QUANTITY_CHANGE: 'quantityChange',
  FULFILLMENT_CHANGE: 'fulfillmentChange'
};

let EVENT_LOGGING_ON = true;
export const initAnalytics = (forceOn = false) => {
  try {
    // TODO: allow analytics (and newrelic) to be suppressed when running
    // automation, development, unit-tests etc.
    if (typeof window !== 'undefined') {
      const href = window.location.href;
      if (href.includes('automation=true')
      || href.includes('localhost')
      || window.isUnitTest
      // || other conditions...
      ) {
        EVENT_LOGGING_ON = false;
      }
    }
    if (forceOn) {
      EVENT_LOGGING_ON = true;
    }
  } catch (err) {
    console.error(err);
  }
};

const logAnalyticsForQuantityChange = ({ errors }) => {
  try {
    if (!errors) {
      return;
    }
    const quantityErrors = errors.filter((err) => {
      return err?.errorCode === 'CART_ERR_135';
    });
    if (quantityErrors) {
      const eventObject = {
        primaryCategory: 'error',
        eventInfo: {
          errors: [],
        }
      };
      quantityErrors.forEach((err) => {
        eventObject.eventInfo.errors.push(err?.description);
      });
      if (EVENT_LOGGING_ON) {
        LIFE_CYCLE_EVENT_BUS.trigger('cartItem.DD_EVENT', eventObject);
      } else if (!sessionStorage.getItem('hushAnalytics')) {
        console.log(
          'Analytics suppressed:', 'LIFE_CYCLE_EVENT_BUS.trigger(\'cartItem.DD_EVENT\', eventObject)',
          eventObject, '(run "sessionStorage.setItem(\'hushAnalytics\', true);" in console'
          + ' to turn off this logging in local)');
      }

    }
  } catch (err) {
    console.error(err);
  }
};

const logNewRelicsForQuantityChange = (variables, updateType) => {
  try {
    const { itemId, quantity } = variables;
    const newRelicAnalytics = {
      itemId,
      quantity
    };
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      const eventObject = { actionName: updateType, actionObject: newRelicAnalytics };
      if (EVENT_LOGGING_ON) {
        LIFE_CYCLE_EVENT_BUS.trigger('cart.cartPageAction', eventObject);
      } else if (!sessionStorage.getItem('hushAnalytics')) {
        console.log('NewRelic suppressed:', 'LIFE_CYCLE_EVENT_BUS.trigger(\'cart.cartPageAction\', eventObject)',
          eventObject);
      }

    }
  } catch (err) {
    console.log(err);
  }
};

export const logAnalytics = ({
  mutationName,
  variables,
  updateType,
  resp
}) => {
  if (updateType === EVENTS.QUANTITY_CHANGE) {
    logAnalyticsForQuantityChange(resp);
    logNewRelicsForQuantityChange(updateType, variables);
  }
};

export const logInvalidSessionPageReload = () => {
  try {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      const eventObject = { actionName: 'invalidSessionPageReload', actionObject: { cartId: '' } };
      if (EVENT_LOGGING_ON) {
        LIFE_CYCLE_EVENT_BUS.trigger('cart.cartPageAction', eventObject);
      } else if (!sessionStorage.getItem('hushAnalytics')) {
        console.log('NewRelic suppressed:', 'LIFE_CYCLE_EVENT_BUS.trigger(\'cart.cartPageAction\', eventObject)',
          eventObject);
      }

    }
  } catch (err) {
    console.log(err);
  }
};
export const getLoggingOn = () => (EVENT_LOGGING_ON);
