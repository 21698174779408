export const formatDate = ({ date, shortMonth = false, dayMonthDateFormat = false, correctOffset = false }) => {
  const longMonthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
  ];

  const shortMonthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep',
    'Oct', 'Nov', 'Dec'
  ];

  const dayOfWeekNames = [
    'Sun', 'Mon', 'Tue',
    'Wed', 'Thu', 'Fri',
    'Sat'
  ];

  let dateToUse = date || new Date();

  if (typeof dateToUse === 'string') {
    dateToUse = new Date(date);
  }

  if (correctOffset) {
    const offset = new Date().getTimezoneOffset() * 60000;
    dateToUse = new Date(dateToUse.getTime() + offset);
  }

  const monthNames = shortMonth ? shortMonthNames : longMonthNames;

  let day = dateToUse.getDate();
  let dayIndex = dateToUse.getDay();
  let monthIndex = dateToUse.getMonth();
  let year = dateToUse.getFullYear();

  if (dayMonthDateFormat) {
    return `${dayOfWeekNames[dayIndex]} ${monthNames[monthIndex]} ${day}`;
  }

  return `${monthNames[monthIndex]} ${day}, ${year}`;
};
