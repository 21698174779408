import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const IndependentLiving = createSvgIcon(
  <>
    <path d="M22.951 8.41 12 .087 1.049 8.41 2.25 9.99V24H4.5V8.613l7.5-5.7 7.5 5.7V24h2.25V9.99l1.201-1.58Z" />
    <path d="M12 17.25A3.75 3.75 0 0 0 8.25 21v3H6v-3a6.001 6.001 0 0 1 3.5-5.456 3.74 3.74 0 0 1-1.25-2.794V12a3.75 3.75 0 0 1 7.5 0v.75a3.74 3.74 0 0 1-1.25 2.794A6.001 6.001 0 0 1 18 21v3h-2.25v-3A3.75 3.75 0 0 0 12 17.25ZM10.5 12v.75a1.5 1.5 0 1 0 3 0V12a1.5 1.5 0 0 0-3 0Z" />
  </>,
  'IndependentLiving'
);

export { IndependentLiving };