import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './card.module.scss';

const cx = classNames.bind(styles);

const CardContent = ({ noPadding, overflowHidden, children, className }) => {

  const cardContentClasses = cx('card-content', {
    'card-content--no-padding': noPadding,
    'card-content--overflow-hidden': overflowHidden,
  }, className);

  return (
    <div className={cardContentClasses}>
      { children }
    </div>
  );
};

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
  noPadding: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
};

CardContent.defaultProps = {
  noPadding: false,
  overflowHidden: false,
  children: null,
  className: null,
};

export { CardContent };
