import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, shape, string } from 'prop-types';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { TodaysRecommendations } from '@thd-olt-component-react/thd-recs-containers';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';

export function TodaysRecommendationsForYou({
  id,
  mcvisID,
  store,
  brandTitleMaxLine,
  slidesPer,
}) {
  const { channel } = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);
  const { storeId, storeZip: zipCode } = store;

  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant
      === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <ErrorBoundary name="todays_recs">
      <QueryProvider
        cacheKey="todays_recs"
        defaultVariables={newDefaultVariables}
      >
        <Card
          id={`todays-recommendations-for-you-php-${id}`}
          className="sui-h-full sui-leading-none"
          CardContentProps={{ className: 'sui-flex sui-flex-col sui-w-full sui-h-full sui-gap-4 sui-p-4' }}
        >
          <CardTitle header={
            <Typography variant="h3">Today&apos;s Recommendations for You</Typography>
          }
          />
          <TodaysRecommendations
            brandTitleMaxLine={brandTitleMaxLine}
            customerID={mcvisID}
            hideTitle
            requestKey="2zSR74pj5Rq6zDtHENHkAScT4RWsl0pg"
            showFallback
            showLoading
            noATCFulfillment
            slidesPer={slidesPer}
            entryId={id}
          />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

TodaysRecommendationsForYou.propTypes = {
  mcvisID: string,
  brandTitleMaxLine: number,
  slidesPer: number,
  store: shape({
    storeId: string,
    storeZip: string,
  }),
  id: string,
};

TodaysRecommendationsForYou.defaultProps = {
  mcvisID: undefined,
  brandTitleMaxLine: undefined,
  store: {
    storeId: '121',
    storeZip: '30339',
  },
  slidesPer: undefined,
  id: undefined,
};
