import React, { useCallback, useContext, useRef } from 'react';
import { bool as boolType, string as stringType, number as numberType } from 'prop-types';
import {
  useDataModel,
  QueryProvider,
} from '@thd-nucleus/data-sources';
import { Home } from '@one-thd/sui-icons';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { CategoryCarousel } from '../category-carousel/CategoryCarousel';
import { trendingNearYouDataModel } from '../../dataModel';
import { FallbackCard } from '../../core/FallbackCard';

const TrendingCategories = (props) => {
  const {
    entryId, showLoading, storeId, zipCode, hideTitle, slidesPer, showFallback, hideControls
  } = props;

  const { isLocalized } = useStore();

  const { channel, isServer } = useContext(ExperienceContext) || {};
  const innerQueryPrerequisites = useRef({ storeId, zipCode });

  const isMounted = useCallback(() => {
    return innerQueryPrerequisites.current.mounted;
  }, [innerQueryPrerequisites.current.mounted]);

  const udsQueryOptions = {
    variables: {
      storeId,
      apiName: 'topseller',
      appId: channel === 'mobile' ? 'mobileweb' : 'desktop',
      key: '2zSR74pj5Rq6zDtHENHkAScT4RWsl0pg',
    },
    skip: !isServer && (!isLocalized || storeId === '8119'),
    ssr: isServer,
  };

  const { loading, data, error, variables } = useDataModel('uds', udsQueryOptions);

  if (!isServer && (data && isLocalized)) {
    innerQueryPrerequisites.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true,
    };
  }

  if (error || (!data?.uds?.products?.length && !loading)) {
    if (showFallback) {
      return (
        <FallbackCard
          header="Nothing Trending In Your Neighborhood Right Now"
          subheader="Check back later to see what new items are trending in your neighborhood"
          componentName="TrendingCategories"
          icon={Home}
        />
      );
    }

    return null;
  }
  const catObject = data?.uds?.products.map((product) => {
    return {
      id: product?.product?.itemId,
      title: product?.product?.categoryName,
      catUrl: product?.product?.categoryUrl,
      catImage: product?.product?.media?.images?.[0]?.url
    };
  });

  return (
    <QueryProvider
      cacheKey="trending"
      mounted={isMounted}
      defaultVariables={{
        storeId: innerQueryPrerequisites.current.storeId,
        zipCode: innerQueryPrerequisites.current.zipCode,
      }}
    >
      <ImpressionProvider
        data={{
          id: entryId || 'TrendingCategories',
          name: 'TrendingCategories',
          component: 'TrendingCategories',
          type: 'product',
          position: '',
        }}
      >
        <div data-component="TrendingCategories">
          <CategoryCarousel
            entryId={entryId}
            loading={loading}
            categories={catObject}
            hideTitle={hideTitle}
            showLoading={showLoading}
            title={data?.uds?.metadata?.title}
            componentName="trending-categories"
            breakpoints={{
              sm: {
                slidesPerView: slidesPer || 3,
                slidesPerGroup: slidesPer || 3
              },
              md: {
                slidesPerView: slidesPer || 3,
                slidesPerGroup: slidesPer || 3
              },
              lg: {
                slidesPerView: slidesPer || 6,
                slidesPerGroup: slidesPer || 6
              },
              xl: {
                slidesPerView: slidesPer || 6,
                slidesPerGroup: slidesPer || 6
              }
            }}
            placeholderSize={48}
            hideControls={hideControls}
          />
        </div>
      </ImpressionProvider>
    </QueryProvider>
  );
};

TrendingCategories.dataModel = trendingNearYouDataModel;

TrendingCategories.displayName = 'TrendingCategoriesForYou';

TrendingCategories.propTypes = {
  entryId: stringType,
  showLoading: boolType,
  zipCode: numberType,
  storeId: stringType,
  slidesPer: numberType,
  showFallback: boolType,
  hideTitle: boolType,
  hideControls: boolType
};

TrendingCategories.defaultProps = {
  entryId: '',
  showLoading: false,
  zipCode: null,
  storeId: '121',
  slidesPer: null,
  showFallback: false,
  hideTitle: false,
  hideControls: false
};

export { TrendingCategories };
