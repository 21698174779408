import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { DrawerHeader, DrawerBody, DrawerFooter, Button } from '@one-thd/sui-atomic-components';
import { Edit, ShareWeb, TrashCan } from '@one-thd/sui-icons';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { PrintList } from '../printList/PrintList';
import { DeleteListDrawerBody } from './DeleteListDrawerBody';

export const MainMenuDrawerBody = ({
  list,
  onClose,
  setMenuName,
  setMainMenuDrawer,
  setRenameListDrawer,
  setShareListDrawer,
  setDeleteListDrawer,
  isSharedList
}) => {
  const theme = useTheme(MainMenuDrawerBody);
  const { hideShareList } = theme.props;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('MainMenuDrawerBody.ready', {});
  }, []);

  const {
    id: listId,
    name: initialName,
    listAccessType: initialListAccessType
  } = list || {};

  const handleEditListName = () => {
    setMainMenuDrawer(false);
    setRenameListDrawer(true);
    setMenuName('Rename List');
  };

  const deleteListMenuButtonClick = () => {
    setMainMenuDrawer(false);
    setDeleteListDrawer(true);
    setMenuName('Delete List');
  };

  const shareListMenuButtonClick = () => {
    setMainMenuDrawer(false);
    setShareListDrawer(true);
    setMenuName('Share List');
  };

  return (
    <>
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        <DrawerHeader title="List Settings" onClose={onClose} />
        <DrawerBody>
          <div className="sui-flex sui-grid sui-gap-2">
            <Button
              variant="ghost"
              startIcon={Edit}
              data-testid="rename-list-button"
              onClick={handleEditListName}
            >
              Rename List
            </Button>
            <PrintList
              listId={listId}
              isSharedList={isSharedList}
            />
            {!hideShareList && (
              <Button
                variant="ghost"
                startIcon={ShareWeb}
                data-testid="share-list-button"
                onClick={shareListMenuButtonClick}
              >
                Share List
              </Button>
            )}
            <Button
              variant="ghost"
              startIcon={TrashCan}
              data-testid="delete-list-button"
              onClick={deleteListMenuButtonClick}
            >
              Delete List
            </Button>
          </div>
        </DrawerBody>
        <DrawerFooter>
          <Button
            variant="secondary"
            fullWidth
            onClick={onClose}
            data-testid="close-main-menu-drawer"
          >
            Close
          </Button>
        </DrawerFooter>
      </div>
    </>
  );
};

MainMenuDrawerBody.displayName = 'ListFormsMainMenuDrawerBody';

MainMenuDrawerBody.propTypes = {
  onClose: func.isRequired,
  list: shape({
    id: string,
    name: string,
    public: bool
  }).isRequired,
  setMenuName: func.isRequired,
  setMainMenuDrawer: func.isRequired,
  setRenameListDrawer: func.isRequired,
  setShareListDrawer: func.isRequired,
  setDeleteListDrawer: func.isRequired,
  isSharedList: bool
};

MainMenuDrawerBody.defaultProps = {
  isSharedList: false
};

MainMenuDrawerBody.dataModel = extend(
  DeleteListDrawerBody,
);

MainMenuDrawerBody.defaultThemeProps = {
  hideShareList: false
};
