import {
  arrayOf,
  bool as boolType,
  customType,
  extend,
  number as numberType,
  params,
  shape,
  string as stringType,
} from '@thd-nucleus/data-sources';
import { ReviewPager } from './Paging/ReviewPager.component';

const ReviewProductDataStore = shape({
  Id: stringType(),
  FilteredReviewStatistics: shape({
    AverageOverallRating: stringType(),
    TotalReviewCount: numberType(),
    TotalRecommendedCount: numberType(),
    RecommendedCount: numberType(),
    NotRecommendedCount: numberType(),
    SecondaryRatingsAveragesOrder: arrayOf(stringType()),
    RatingDistribution: arrayOf(shape({
      RatingValue: numberType(),
      Count: numberType(),
    })),
    ContextDataDistribution: shape({
      Age: shape({
        Values: arrayOf(shape({
          Value: stringType(),
          Count: stringType()
        }))
      }),
      Gender: shape({
        Values: arrayOf(shape({
          Value: stringType(),
          Count: stringType()
        }))
      }),
      Expertise: shape({
        Values: arrayOf(shape({
          Value: stringType()
        }))
      }),
      HomeGoodsProfile: shape({
        Values: arrayOf(shape({
          Value: stringType(),
          Count: stringType()
        }))
      }),
      VerifiedPurchaser: shape({
        Values: arrayOf(shape({
          Value: stringType(),
          Count: stringType()
        }))
      })
    })
  }),
});

const ReviewPhoto = shape({
  Id: stringType(),
  Sizes: shape({
    normal: shape({
      Url: stringType()
    }),
    thumbnail: shape({
      Url: stringType()
    })
  })
});

const ReviewVideo = shape({
  VideoId: stringType(),
  VideoThumbnailUrl: stringType(),
  VideoUrl: stringType()
});

const ReviewsBadge = shape({
  BadgeType: stringType()
});

const ReviewsQualityRating = shape({
  Label: stringType(),
  Value: stringType()
});

const ReviewModel = shape({
  AuthorId: stringType(),
  Badges: shape({
    DIY: ReviewsBadge,
    top250Contributor: ReviewsBadge,
    IncentivizedReview: ReviewsBadge,
    EarlyReviewerIncentive: ReviewsBadge,
    top1000Contributor: ReviewsBadge,
    VerifiedPurchaser: ReviewsBadge,
  }),
  BadgesOrder: arrayOf(stringType()),
  CampaignId: stringType(),
  ContextDataValues: shape({
    Age: shape({
      Value: stringType()
    }),
    VerifiedPurchaser: shape({
      Value: stringType()
    })
  }),
  ContextDataValuesOrder: arrayOf(stringType()),
  Id: stringType(),
  IsRecommended: boolType(),
  IsSyndicated: boolType(),
  Photos: arrayOf(ReviewPhoto),
  ProductId: stringType(),
  SubmissionTime: stringType(),
  TagDimensions: shape({
    Pro: shape({
      Values: arrayOf(stringType())
    }),
    Con: shape({
      Values: arrayOf(stringType())
    }),
  }),
  Title: stringType(),
  TotalNegativeFeedbackCount: numberType(),
  TotalPositiveFeedbackCount: numberType(),
  ClientResponses: arrayOf(shape({
    Response: stringType(),
    Date: stringType(),
    Department: stringType(),
  })),
  Rating: numberType(),
  RatingRange: numberType(),
  ReviewText: stringType(),
  SecondaryRatings: shape({
    Quality: ReviewsQualityRating,
    Value: ReviewsQualityRating,
    EnergyEfficiency: ReviewsQualityRating,
    Features: ReviewsQualityRating,
    Appearance: ReviewsQualityRating,
    EaseOfInstallation: ReviewsQualityRating,
    EaseOfUse: ReviewsQualityRating,
  }),
  SecondaryRatingsOrder: arrayOf(stringType()),
  SyndicationSource: shape({
    LogoImageUrl: stringType(),
    Name: stringType(),
  }),
  UserNickname: stringType(),
  UserLocation: stringType(),
  Videos: arrayOf(ReviewVideo)
});

const BVPage = shape({
  label: stringType(),
  isNextPage: boolType(),
  isPreviousPage: boolType(),
  isSelectedPage: boolType()
});

const PhotoResult = shape({
  Photos: arrayOf(shape({
    Id: stringType(),
    Sizes: shape({
      normal: shape({
        Id: stringType(),
        Url: stringType()
      }),
      thumbnail: shape({
        Id: stringType(),
        Url: stringType()
      })
    })
  })),
  Rating: numberType(),
  ReviewText: stringType(),
  SubmissionTime: stringType(),
  Title: stringType(),
  UserNickname: stringType(),
});

export const dataModel = extend({
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shape({
      itemId: stringType(),
      brandName: stringType(),
      productLabel: stringType(),
      canonicalUrl: stringType()
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: stringType(),
        totalReviews: stringType()
      })
    }),
    media: shape({
      image: shape({
        url: stringType()
      }).client(),
      images: arrayOf(shape({
        url: stringType(),
        sizes: arrayOf(stringType()),
        type: stringType(),
        subType: stringType()
      }))
    })
  }),
  storeReviews: params({
    storeId: stringType().isRequired(),
    searchTerm: stringType(),
    sortBy: stringType(),
    startIndex: numberType(),
    pagesize: stringType(),
    recfirstpage: stringType(),
    filters: customType('ReviewsFilterInput')
      .shape({
        isVerifiedPurchase: boolType(),
        prosCons: stringType(),
        starRatings: arrayOf(numberType()),
        ageRange: arrayOf(stringType()),
        profile: arrayOf(stringType()),
        gender: arrayOf(stringType())
      })
  }).shape({
    Results: arrayOf(ReviewModel),
    Includes: shape({
      Products: shape({
        store: ReviewProductDataStore,
        items: arrayOf(ReviewProductDataStore)
      })
    }),
    FilterSelected: shape({
      StarRatings: shape({
        is5Star: boolType(),
        is4Star: boolType(),
        is3Star: boolType(),
        is2Star: boolType(),
        is1Star: boolType(),
      }),
      VerifiedPurchaser: stringType(),
      SearchText: stringType()
    }),
    pagination: shape({
      previousPage: BVPage,
      pages: arrayOf(BVPage),
      nextPage: BVPage
    }),
    SortBy: shape({
      mosthelpfull: boolType(),
      newest: boolType(),
      oldest: boolType(),
      highestrating: boolType(),
      lowestrating: boolType()
    }),
    TotalResults: numberType()
  }),
  reviews: params({
    itemId: stringType().isRequired(),
    searchTerm: stringType(),
    sortBy: stringType(),
    startIndex: numberType(),
    recfirstpage: stringType(),
    pagesize: stringType(),
    filters: customType('ReviewsFilterInput')
      .shape({
        isVerifiedPurchase: boolType(),
        prosCons: stringType(),
        starRatings: arrayOf(numberType())
      })
  }).shape({
    Results: arrayOf(ReviewModel),
    Includes: shape({
      Products: shape({
        store: ReviewProductDataStore,
        items: arrayOf(ReviewProductDataStore)
      })
    }),
    FilterSelected: shape({
      StarRatings: shape({
        is5Star: boolType(),
        is4Star: boolType(),
        is3Star: boolType(),
        is2Star: boolType(),
        is1Star: boolType(),
      }),
      VerifiedPurchaser: stringType(),
      SearchText: stringType()
    }),
    pagination: shape({
      previousPage: BVPage,
      pages: arrayOf(BVPage),
      nextPage: BVPage
    }),
    SortBy: shape({
      mosthelpfull: boolType(),
      newest: boolType(),
      oldest: boolType(),
      highestrating: boolType(),
      lowestrating: boolType(),
      photoreview: boolType(),
    }),
    TotalResults: numberType()
  }),
  reviewPhotos: params({ itemId: stringType().isRequired() }).shape({
    Results: arrayOf(PhotoResult)
  }),
  reviewSentiments: params({ itemId: stringType().isRequired() }).shape(({
    Results: arrayOf(shape({
      Feature: stringType(),
      SentimentType: numberType(),
      TotalResults: numberType(),
      ReviewIds: arrayOf(stringType())
    })),
    BrandName: stringType(),
    AverageBrandRating: stringType(),
    TotalReviewCount: numberType()
  })),
  reviewSummary: params({ itemId: stringType().isRequired(), modelName: stringType().isRequired() }).shape(({
    reviews: shape({
      summary: stringType(),
    }),
    aspects: arrayOf(shape({
      aspect: stringType(),
      sentiment: stringType(),
    })),
    llm: stringType()
  }))
},
ReviewPager);
