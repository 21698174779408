import { useState, useEffect, useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import formatVariations from '../utils/formatVariations';
import handleVariations from '../utils/handleVariations';

/**
 * @function useVariationIds
 * @param {{
 *  mboxes: String[]
 *  campaignIds: [{ campaignId: String }]
 *  innervateTags: [{ tagID: String, adkey: String, csId: String, campaignMetadata: String }]
 *  isReady: Boolean
 *  slug: String
 *  isDebug: Boolean
 *  additionalDetails: { [key: String]: any }
 * }} props
 * @returns {{
 *  rawResponse: [{ endpoint: String, response: any[] }]
 *  variationIds: [{ provider: String, uniqueID: String }]
 *  renderDefault: Boolean
 * }}
 * @description useVariationIds will accept the relevant ids/tags for various targeting systems and handle the
 * request and responses for consumption by your Experiences. It will provide the raw responses as well as a
 * formatted list of IDs as well as a flag to indicate if you should just render the default vs choosing a
 * variation.
 */
export const useVariationIds = (props) => {
  const {
    mboxes = [],
    campaignIds = [],
    innervateTags = [],
    isReady = true,
  } = props;

  const { channel, hosts } = useContext(ExperienceContext);
  const apiEndpoint = hosts.apionline;
  const [variationIds, setVariationIds] = useState(null);
  const [renderDefault, setRenderDefault] = useState(false);
  const [rawResponse, setRawResponse] = useState([]);

  useEffect(() => {
    const getVariationIds = async () => {
      if (!variationIds && isReady) {
        try {
          const {
            adobeVariationIds,
            decisionEngineVariationIds,
            innervateVariationIds,
            urlVariationIds
          } = await handleVariations({ channel, apiEndpoint, props });

          setRawResponse(() => {
            const newState = [
              { endpoint: 'decisionEngine', response: decisionEngineVariationIds },
              { endpoint: 'adobe', response: adobeVariationIds },
              { endpoint: 'innervate', response: innervateVariationIds },
              { endpoint: 'urlParams', response: urlVariationIds }
            ];
            /* eslint-disable-next-line no-console, no-unused-expressions */
            window.isDebugMode && console.log('RAW RESPONSE', newState);
            return newState;
          });

          const allIds = formatVariations({
            adobeVariationIds,
            decisionEngineVariationIds,
            innervateVariationIds,
            urlVariationIds
          });

          setVariationIds(allIds);

          if (allIds.length < 1) {
            setRenderDefault(true);
          }
        } catch (error) {
          /* eslint-disable-next-line no-console, no-unused-expressions */
          window.isDebugMode && console.error(error);

          window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.error', {
            error: error.message,
          });
          // Needed to show the default if some kind of error occurs
          setRenderDefault(true);
        }
      }
    };

    getVariationIds();
  }, [JSON.stringify(mboxes), JSON.stringify(campaignIds), JSON.stringify(innervateTags), isReady]);

  return {
    rawResponse,
    variationIds,
    renderDefault,
  };
};