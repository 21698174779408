import THDStorageUtils, { LOCAL_STORAGE } from '@thd-olt-global/thd-storage-utils';

export const getStorageObject = ({ key }) => {
  try {
    const storageValue = THDStorageUtils.get(LOCAL_STORAGE, { key });
    const storageObject = storageValue !== 'undefined' ? JSON.parse(storageValue) : null;
    return storageObject;
  } catch (error) {
    return null;
  }
};

export const saveStorageObject = ({ key, response, expiration }) => {
  THDStorageUtils.set(LOCAL_STORAGE, {
    key,
    expiration,
    value: JSON.stringify({ response })
  });
};