import React from 'react';
import PropTypes from 'prop-types';
import { Clock } from '@one-thd/sui-icons';
import '../countdown-timer.style.scss';

const TimerBlock = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg
}) => {

  return (
    <div className="sui-font-bold sui-w-60" data-component="TimerBlock">
      {timeStringAndMessaging.messagingString && shouldUseMessagingString && (
        <div className="countdown-timer__clock-block sui-items-center">
          <span className="sui-font-family-regular">
            {shouldIncludeSvg && (
              <span className="sui-mr-1 sui-flex sui-items-center">
                <Clock size="small" />
              </span>
            )}
            {timeStringAndMessaging.messagingString}
          </span>
        </div>
      )}
      {timeStringAndMessaging.timeString && (
        <div className="sui-font-bold sui-text-5xl sui-text-center">
          {timeStringAndMessaging.timeString}
        </div>
      )}
    </div>
  );
};

TimerBlock.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show image clock
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show the message and time
   */
  timeStringAndMessaging: PropTypes.shape({
    messagingString: PropTypes.string,
    timeString: PropTypes.string
  })
};

TimerBlock.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerBlock;
