import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { CloseButtonTooltip } from './ReactCloseButtonTooltip.component';
import { ReactTooltipContent } from './ReactTooltipContent.component';

import './thd-react-tooltip.style.scss';

const ReactTooltip = ({
  appendTo,
  children,
  className,
  closeButton,
  content,
  distance,
  flip,
  interactive,
  maxWidth,
  onShow,
  placement,
  showClose,
  visible,
  zIndex,
  skidding
}) => {
  return closeButton ? (
    <CloseButtonTooltip
      appendTo={appendTo}
      className={className}
      content={content}
      distance={distance}
      maxWidth={maxWidth}
      onShow={onShow}
      placement={placement}
      showClose={showClose}
      visible={visible}
      zIndex={zIndex}
      skidding={skidding}
    >
      {children}
    </CloseButtonTooltip>
  ) : (
    <Tippy
      appendTo={appendTo}
      arrow
      className="thd-tooltip"
      content={<ReactTooltipContent className={className}>{content}</ReactTooltipContent>}
      interactive={interactive}
      maxWidth={maxWidth}
      offset={[skidding, distance]}
      onShow={onShow}
      placement={placement}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom']
            }
          },
          {
            name: 'preventOverflow',
            options: {
              // equivalent to boundary: 'window' in v1, usually NOT necessary in v2
              rootBoundary: 'document',
            },
          }, { name: 'flip', enabled: flip }]
      }}
      zIndex={zIndex}
    >
      {children}
    </Tippy>
  );
};

ReactTooltip.propTypes = {
  appendTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  content: PropTypes.node.isRequired,
  distance: PropTypes.number,
  flip: PropTypes.bool,
  interactive: PropTypes.bool,
  maxWidth: PropTypes.number,
  onShow: PropTypes.func,
  placement: PropTypes.string,
  showClose: PropTypes.bool,
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
  skidding: PropTypes.number
};

ReactTooltip.defaultProps = {
  appendTo: () => document.body,
  className: '',
  closeButton: false,
  distance: 25,
  flip: true,
  interactive: false,
  maxWidth: 500,
  onShow: () => {},
  placement: 'top',
  showClose: true,
  visible: false,
  zIndex: 9999,
  skidding: 0
};

export { ReactTooltip };
