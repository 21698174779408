import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PurchaseHistory = createSvgIcon(
  <>
    <path d="M0 0h19.5v3.75h-2.25v-1.5h-15v19.5h3V24H0V0Z" />
    <path d="M15.75 9.75v4.409l2.716 2.716-1.591 1.591-3.375-3.375V9.75h2.25Z" />
    <path d="M14.625 5.25a9.375 9.375 0 1 0 0 18.75 9.375 9.375 0 0 0 0-18.75ZM7.5 14.625a7.125 7.125 0 1 1 14.25 0 7.125 7.125 0 0 1-14.25 0Z" />
  </>,
  'PurchaseHistory'
);

export { PurchaseHistory };