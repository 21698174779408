import React from 'react';
import {
  arrayOf,
  number,
  shape,
  string
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

import { SegmentedProgressBarSegment } from './subcomponents/SegmentedProgressBarSegment/SegmentedProgressBarSegment';

export const SegmentedProgressBar = ({
  value: segments,
  ...rest
}) => {
  const animationIndex = segments.findIndex(
    ({ value }, index, arr) => (value < 100)
      || (value === 100 && arr[index + 1]?.value === 0)
      || (index === arr.length - 1)
  );
  const currentSegmentIndex = segments.findIndex(({ value }) => value < 100);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="sui-flex sui-flex-col sui-gap-1 sui-pt-1" {...rest}>
      <div className="sui-flex sui-gap-1" data-testid="segmented-progress-bar-segment-container">
        {segments.map(({ endLabel, value }, i) => (
          <span
            className="sui-flex sui-flex-col sui-w-full"
            key={`promo-segment-${i}`}
          >
            <SegmentedProgressBarSegment
              data-testid={`segmented-progress-bar-segment-${i}`}
              first={i === 0}
              hasAnimation={i === animationIndex}
              last={i === segments.length - 1}
              value={value}
            />
            <Typography
              align="right"
              color={value === 100 ? 'success' : null}
              component="span"
              weight={i === currentSegmentIndex ? 'bold' : null}
              variant="body-xs"
            >
              {endLabel}
            </Typography>
          </span>
        ))}
      </div>
    </div>
  );
};

SegmentedProgressBar.displayName = 'SegmentedProgressBar';

SegmentedProgressBar.propTypes = {
  value: arrayOf(shape({
    endLabel: string.isRequired,
    value: number.isRequired
  }))
};

SegmentedProgressBar.defaultProps = {
  value: []
};
