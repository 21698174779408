import { createTheme } from '@one-thd/sui-atomic-components';

const SawDustTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

SawDustTheme.palette['--sui-palette-background-primary'] = '246 243 239';

export { SawDustTheme };