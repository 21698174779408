import { Tag } from './Tag';

class ScriptTag extends Tag {
  constructor({ content, id, scriptType = 'application/ld+json' }) {
    super();
    this.type = 'script';
    this.prefix = 'thd-helmet__script--';
    this.id = id;
    if (!this.id) {
      console.log('warning: you should pass an id to ScriptTag');
      this.id = Math.random().toString(36).substr(2, 5);
    }
    this.id = this.prefix + this.id;
    this.scriptType = scriptType;
    const str = typeof content === 'string'
      ? content
      : JSON.stringify(content);
    this.content = [str];
  }

  addContent(content) {
    this.content.push(content);
  }

  updateDom() {
    let el = document.getElementById(this.id);
    if (!el) {
      el = document.createElement('script');
      el.type = this.scriptType;
      el.id = this.id;
      el.dataset.th = 'client';
      document.head.appendChild(el);
    }
    const content = this.content.join('\n');
    const cleaned = content.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    if (cleaned !== el.innerText) {
      el.innerText = cleaned;
    }
  }

  removeFromDom() {
    if (this.id) {
      const el = document.getElementById(this.id);
      if (el && el.remove) {
        el.remove();
      }
    }

  }

  toString() {
    const type = this.scriptType
      ? `type="${this.scriptType}"`
      : '';
    const str = this.content.join('\n');
    const cleaned = str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return `<script data-th="server" ${type} id="${this.id}">${cleaned}</script>`;
  }
}

export { ScriptTag };
