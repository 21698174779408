import React from 'react';
import PropTypes from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';

export const HotspotBlock = ({
  action, name, style, id, position
}) => {

  const skuSplit = action.split('/');
  const skuId = skuSplit[skuSplit.length - 1];
  const skuIdSplit = skuId?.split('');
  const skuReturn = () => {
    let returnVal = skuId;
    if (skuIdSplit[0] === 'N') {
      returnVal = skuId.toLowerCase();
    } else {
      returnVal = skuId;
    }
    return returnVal;
  };

  const skuValFinal = skuReturn();

  const { ref, clickID } = useImpression({
    data: {
      id,
      category: skuValFinal,
      position,
      component: 'ImageHotspot',
      name: 'ImageHotspot',
      type: 'content'
    }
  });

  return (
    <div
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
      className="sui-absolute"
      style={style}
    >
      <a
        href={action}
        aria-label={`Hotspot Image ${name}`}
        alt={name}
        title={name}
        rel="noreferrer"
        className="sui-block sui-cursor-pointer sui-h-full sui-w-full"
        data-testid="hotspot-block"
      />
    </div>
  );
};

HotspotBlock.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  style: PropTypes.shape({
    width: PropTypes.string,
    left: PropTypes.string,
    top: PropTypes.string,
    border: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
