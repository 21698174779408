/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import {
  Card,
  CardMedia,
  CardTitle,
  ThemeProvider
} from '@one-thd/sui-atomic-components';
import { bool } from 'prop-types';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames/bind';
import { akamaiImageResize } from './helper';
import styles from './visual-navigation.scss';

export const VisualNavigationCard = (itemDetails) => {
  const cx = classNames.bind(styles);

  const { item, position, lazyLoad, theme } = itemDetails;
  let {
    altText,
    id: itemID,
    link,
    title,
    previewImage,
    // sys (only needed for live preview/contentful)
    sys: { id: livePreviewID } = { id: '' }
  } = item || {};

  const { ref, clickID } = useImpression({
    data: {
      id: itemID || livePreviewID,
      component: 'VisualNavigation',
      name: 'VisualNavigation',
      type: 'content',
      position,
    },
  });

  let damDownloadedContentImage = previewImage?.damDownloadedContent;
  let damContentSelectorImage = previewImage?.damContentSelector?.assetData?.[0];
  let imageUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl;

  imageUrl = akamaiImageResize(imageUrl, 703);

  return (
    <ThemeProvider theme={theme}>
      <div
        ref={ref}
        /* eslint-disable-next-line react/no-unknown-property */
        clickid={clickID}
        key={`card-position-${position}`}
        className={`${!imageUrl ? 'sui-align-middle' : ''} sui-flex sui-h-full sui-w-full`}
        data-component="VisualNavigationCard"
        data-contentful-entry-id={livePreviewID}
        data-contentful-field-id="internalName"
      >
        <Card
          key={itemID}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={cx('sui-w-full link--hover-no-underline hover:sui-shadow-lg')}
        >
          {imageUrl && (
            <CardMedia
              alt={`${altText || title}`}
              title={`${altText || title}`}
              src={imageUrl}
              aspect="square"
              loading={lazyLoad ? 'lazy' : ''}
              data-contentful-entry-id={livePreviewID}
              data-contentful-field-id="previewImage"
            />
          )}
          {title && (
            <CardTitle
              actionPosition="center"
              headerWeight="bold"
              header={title}
              headerVariant="h5"
              data-contentful-entry-id={livePreviewID}
              data-contentful-field-id="title"
            />
          )}
        </Card>
      </div>
    </ThemeProvider>
  );
};

const displayName = 'VisualNavigationCard';

const propTypes = {
  lazyLoad: bool
};

const defaultProps = {
  lazyLoad: false
};

VisualNavigationCard.displayName = displayName;
VisualNavigationCard.propTypes = propTypes;
VisualNavigationCard.defaultProps = defaultProps;
