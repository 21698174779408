import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  bool, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import { VideoPlayerContext } from '../contexts/VideoPlayerContext';
import {
  setVolume,
  toggleMuteOnVideo,
  getPlayerType,
  PlayerTypes
} from '../helpers/videoPlayerHelpers';
import { VideoStill } from './VideoStill';
import { S3Player } from './players/S3Player';
import { YouTubePlayer } from './players/YouTubePlayer';
import { BrightCovePlayer } from './players/BrightCovePlayer';

export const VideoPlayer = ({
  analyticsData,
  aspectRatio,
  autoPlay,
  className,
  height,
  videoId,
  videoSrc,
  videoStill,
  width,
  source
}) => {
  const playerRef = useRef();
  const {
    autoPlayInViewport, observer
  } = useContext(VideoPlayerContext) || {};

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('video-player.ready');
  });
  const [enableVideoStill, setEnableVideoStill] = useState(!!videoStill);
  const playerType = getPlayerType({ videoSrc, source, videoId });

  useEffect(() => {
    if (
      autoPlayInViewport
      && !!playerRef.current
      && typeof window !== 'undefined'
      && observer
    ) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (observer) {
        observer.unobserve(playerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleEqualizeVolume = ({ output }) => {
      if (output.id !== playerRef.current?.id) {
        setVolume({ frame: playerRef.current, volume: output.volume });
        toggleMuteOnVideo({ frame: playerRef.current, mute: output.mute });
      }
    };

    if (!enableVideoStill) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('video-player.equalize-volume', handleEqualizeVolume);
    }

    return () => {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off('video-player.equalize-volume', handleEqualizeVolume);
    };
  }, [enableVideoStill]);

  if (enableVideoStill) {
    return (
      <VideoStill
        width={width}
        height={height}
        videoStill={videoStill}
        onPlayButtonClick={() => setEnableVideoStill(false)}
      />
    );
  }

  if (playerType === PlayerTypes.S3) {
    return (
      <S3Player
        className={className}
        height={height}
        width={width}
        videoSrc={videoSrc}
        ref={playerRef}
      />
    );
  }

  if (playerType === PlayerTypes.YouTube) {
    return (
      <YouTubePlayer
        analyticsData={analyticsData}
        autoPlay={autoPlay}
        className={className}
        height={height}
        videoSrc={videoSrc}
        videoStill={videoStill}
        width={width}
        ref={playerRef}
      />
    );
  }

  if (playerType === PlayerTypes.BrightCove) {
    return (
      <BrightCovePlayer
        analyticsData={analyticsData}
        aspectRatio={aspectRatio}
        autoPlay={autoPlay}
        className={className}
        height={height}
        videoId={videoId}
        videoStill={videoStill}
        width={width}
        ref={playerRef}
      />
    );
  }

  return null;
};

VideoPlayer.propTypes = {
  analyticsData: shape({}),
  aspectRatio: oneOf([16 / 9, 4 / 3]),
  autoPlay: bool,
  className: string,
  height: number,
  source: string,
  videoId: oneOfType([string, number]),
  videoSrc: string,
  videoStill: string,
  width: number
};

VideoPlayer.defaultProps = {
  analyticsData: null,
  aspectRatio: 16 / 9,
  autoPlay: false,
  className: null,
  height: null,
  source: null,
  videoId: null,
  videoSrc: null,
  videoStill: null,
  width: null
};