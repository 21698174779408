import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { QuantityShipping } from './QuantityShipping';
import { AvailableIcon, UnavailableIcon, InformationIcon } from '../../helpers/icon-utils';
import { getTooltipContent } from '../../helpers/tooltip';
import '../../helpers/tooltip.scss';
import { getB2CDeliveryMessage, isB2B, isFunction, isMobile } from '../../../components/helper/utils';
import { DELIVERY_TEMPLATES } from '../../templates/templatesConstant';
import {
  getDeliveryMessageToUse,
  productShape
} from '../../helpers/pod-fulfillment-utils';

export const DefaultShipping = ({
  applianceDeliveryDate,
  shippingMessageStyle,
  iconType,
  shippingMessage,
  displayQuantity,
  applianceQuantity,
  shippingThreshold,
  excludedState,
  customRender,
  product,
  deliveryMessage,
  hasSameDayFilter
}) => {
  const experienceContextData = useContext(ExperienceContext);
  const { channel, customer } = experienceContextData || {};
  const iconSelector = iconType === 'close' ? UnavailableIcon : AvailableIcon;
  const tooltipMsg = 'Looks like we can\'t deliver this product to your area. '
    + 'Try changing your delivery ZIP to an address in a different state.';

  const shippingCss = classNames(shippingMessageStyle + ' shipping__second-line');

  const excludedStateMwLinkCss = (excludedState && isMobile(channel)) ? ' shipping__mw-link' : '';
  const storeLinkCss = classNames('store__link u__default-link store__store-name' + excludedStateMwLinkCss);
  const isCustomerB2B = isB2B(customer);

  const deliveryMessageToUse = deliveryMessage ? (getDeliveryMessageToUse[deliveryMessage] || null) : null;

  const excludedStateTemplate = () => {
    return (
      <div className={shippingCss}>{'Not available in '}
        <Tooltip
          channel={channel}
          closeButton
          placement="top"
          content={getTooltipContent(tooltipMsg, channel)}
          interactive
          distance={16}
          skidding={-36}
          zIndex={3000000000}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className={storeLinkCss}>
            {excludedState}
            <span className="ess-info-icon">{InformationIcon}</span>
          </a>
        </Tooltip>
      </div>
    );
  };

  const deliveryTemplate = () => {
    let shippingMessageToUse = shippingMessage;
    if (!isCustomerB2B && shippingMessageToUse === 'Standard') {
      shippingMessageToUse = 'Available';
    }
    return displayQuantity > 0
      ? (
        <QuantityShipping
          displayQuantity={displayQuantity}
          shippingMessageStyle={shippingMessageStyle}
          shippingMessage={shippingMessageToUse}
          shippingThreshold={shippingThreshold}
          deliveryMessageToUse={deliveryMessageToUse}
        />
      ) : (
        <div>
          {!deliveryMessageToUse
            ? (<span className={shippingCss}>{getB2CDeliveryMessage(shippingMessageToUse, !isCustomerB2B)}</span>)
            : (
              <>
                {shippingMessageStyle === 'shipping__success'
                  ? <span className={shippingCss}>{`${shippingMessageToUse} ${deliveryMessageToUse}`}</span>
                  : <span className="shipping__primary">{deliveryMessageToUse}</span>}
              </>
            )}
          {applianceDeliveryDate
            && <>&nbsp;by&nbsp;<span className="appliance-delivery-date">{applianceDeliveryDate}</span></>}
        </div>
      );
  };

  if (isFunction(customRender)) {
    return customRender({
      deliveryTemplate: DELIVERY_TEMPLATES.DEFAULT_SHIPPING,
      icon: iconSelector,
      values: {
        excludedState,
        shippingThreshold,
        displayQuantity,
        shippingMessage,
        shippingCss,
        applianceQuantity,
        product,
        experienceContextData
      }
    });
  }

  // double delivery message element
  if (hasSameDayFilter) {
    return (
      <div className="shipping__message">
        <div className="shipping__icon" />
        <div className="shipping__text-box !sui-pt-0">
          {excludedState ? excludedStateTemplate() : deliveryTemplate()}
        </div>
      </div>
    );
  }

  return (
    <div className="shipping__message">
      <div className="shipping__icon">{iconSelector}</div>
      <div className="shipping__text-box">
        <div className="shipping__dark">Delivery</div>
        {excludedState ? excludedStateTemplate() : deliveryTemplate()}
      </div>
    </div>
  );
};

DefaultShipping.propTypes = {
  applianceDeliveryDate: PropTypes.string,
  shippingMessageStyle: PropTypes.string,
  shippingMessage: PropTypes.string,
  displayQuantity: PropTypes.number,
  applianceQuantity: PropTypes.number,
  iconType: PropTypes.string,
  shippingThreshold: PropTypes.number,
  excludedState: PropTypes.string,
  customRender: PropTypes.func,
  product: PropTypes.shape({ productShape }).isRequired,
  deliveryMessage: PropTypes.string,
  hasSameDayFilter: PropTypes.bool
};

DefaultShipping.defaultProps = {
  applianceDeliveryDate: null,
  shippingMessageStyle: 'shipping__primary',
  iconType: 'close',
  shippingMessage: 'Unavailable',
  displayQuantity: 0,
  applianceQuantity: 0,
  shippingThreshold: null,
  excludedState: null,
  customRender: null,
  deliveryMessage: null,
  hasSameDayFilter: false
};
