import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleSecondaryWarmOrange = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleSecondaryWarmOrange.palette['--sui-palette-background-primary'] = '249 116 48';

export { HouseStyleSecondaryWarmOrange };