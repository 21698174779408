import { useState, useEffect } from 'react';
import { getCoverage, getQuantity } from '../helpers/helpers';
import { getCalculatorLocalStorage } from '../helpers/localStorageUtil';

/* TODO: Look into the potential of lumping in a good bit of the localStorageUtil functions
        into this hook and cleaning up where those functions are exported from. */

export const useCalculatorLocalStorage = ({ uomObj, toggleOn = false, label, pipCalc }) => {

  const { sizeValue, caseUnitOfMeasure } = uomObj || {};
  const { publisher, display } = pipCalc || {};
  const [storageInfo, setStorageInfo] = useState({});

  useEffect(() => {
    if (label) {
      setStorageInfo(getCalculatorLocalStorage({
        l1Label: label
      }));

      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('calculator.localStorageUpdated', () => {
        setStorageInfo(getCalculatorLocalStorage({
          l1Label: label
        }));
      });
    }
  }, [label]);

  const { areas } = storageInfo?.storageObj || {};
  const areaLength = areas?.length || 0;
  const unitsPerCase = publisher || sizeValue;
  let coverage = 0;
  if (areaLength) {
    coverage = getCoverage({
      areas,
      calculatorType: 'Area',
    });
  }

  const uomCheck = (unitsPerCase && (caseUnitOfMeasure === 'sq. ft.' || display));
  const quantity = (!toggleOn || !uomCheck) ? null : getQuantity({ coverage, sizeValue: unitsPerCase });

  return { projectQuantity: quantity, coverage, calculatorData: storageInfo.storageObj };
};