import React from 'react';
import { node } from 'prop-types';

import { DrawerHeader } from '@one-thd/sui-atomic-components';

export const PromoDrawerHeader = ({ children, ...rest }) => {
  return (
    <>
      <div className="sui-top-0 sui-sticky sui-z-20">

        <DrawerHeader
          className="sui-flex sui-flex-col sui-pl-2 sui-pr-4 sui-pt-4 sui-pb-2 sui-bg-primary sui-shadow-md"
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...rest}
        >
          {children}
        </DrawerHeader>
      </div>
    </>
  );
};

PromoDrawerHeader.displayName = 'PromoDrawerHeader';

PromoDrawerHeader.propTypes = {
  children: node
};

PromoDrawerHeader.defaultProps = {
  children: null
};
