import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Apron = createSvgIcon(
  <>
    <path d="M9 0v5.25h6V0h2.25v5.25h1.5v1.125c0 2.223.658 4.396 1.891 6.246l1.109 1.663V24H19.5v-9.034l-.73-1.097A13.51 13.51 0 0 1 16.546 7.5H7.453a13.51 13.51 0 0 1-2.222 6.37L4.5 14.965V24H2.25v-9.716l1.109-1.663A11.26 11.26 0 0 0 5.25 6.375V5.25h1.5V0H9Z" />
    <path d="M7.5 24v-9h9v9h-9Zm2.25-6.75v4.5h4.5v-4.5h-4.5Z" />
  </>,
  'Apron'
);

export { Apron };