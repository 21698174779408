import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Upload = createSvgIcon(
  <>
    <path d="m12 .01 7.966 7.854-1.58 1.602-5.261-5.187V18.75h-2.25V4.279L5.614 9.466l-1.58-1.602L12 .01Z" />
    <path d="M2.25 21.75V18H0v6h24v-6h-2.25v3.75H2.25Z" />
  </>,
  'Upload'
);

export { Upload };