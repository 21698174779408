import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleGrass = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleGrass.palette['--sui-palette-background-primary'] = '105 153 75';

export { HouseStyleGrass };