import React, { useState, useEffect } from 'react';
import { func, string } from 'prop-types';
import { Select } from '@thd-olt-component-react/select';
import * as analytics from '../../../ratings-reviews-analytics';

export const Sort = ({ onChange, sortBy }) => {

  const sortOptions = [
    { name: 'Photo Reviews', value: 'photoreview' },
    { name: 'Most Helpful', value: 'mosthelpfull' },
    { name: 'Oldest', value: 'oldest' },
    { name: 'Newest', value: 'newest' },
    { name: 'Highest rating', value: 'highestrating' },
    { name: 'Lowest rating', value: 'lowestrating' }
  ];
  const [selected, setSelected] = useState(sortBy);
  useEffect(() => { setSelected(sortBy); }, [sortBy]);

  if (typeof onChange !== 'function') {
    return null;
  }

  const onSortChange = (event) => {
    let { value } = event.target;
    const selectedIndex = sortOptions.findIndex(
      (index) => index.value === value
    );
    setSelected(value);
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'sort',
      value: sortOptions[selectedIndex].name.toLowerCase(),
      eventType: 'click'
    });
    onChange(value);
  };

  return (
    <div className="ratings-and-reviews__sort">
      <Select
        selected={selected}
        options={sortOptions}
        onChange={onSortChange}
      />
    </div>
  );

};

Sort.displayName = 'RatingsAndReviewsSort';

Sort.propTypes = {
  onChange: func.isRequired,
  sortBy: string.isRequired
};
