import React, { useEffect } from 'react';
import { string, PropTypes, number } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { Button } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

export const InformationAndCtaPod = ({ service, id, position }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('personalized-home-services.ready');
  }, []);

  const { ref, clickID } = useImpression({
    data: {
      id,
      name: 'PersonalizedHomeServices',
      component: 'PersonalizedHomeServices',
      position,
      type: 'content',
    },
  });
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div ref={ref} clickid={clickID} className="sui-flex sui-flex-col">
      <Image
        className="sui-h-60 sui-object-cover"
        src={service?.service_img}
        alt=""
        aria-hidden="true"
      />
      <div data-testid="php-carousel-title" className="sui-text-brand sui-text-base sui-font-bold sui-pt-4">
        Leave Your Next Project to Us
      </div>
      <p className="sui-font-bold sui-text-xl sui-pb-4 sui-pt-2">{service?.title}</p>
      <div className="sui-text-base sui-pb-4">
        <div className="sui-pb-4">{service?.subText}</div>
        <ul className="sui-list-disc">
          {service?.bulletsPoints?.map((str, index) => {
            return (<li className="sui-ml-8" key={index}>{str}</li>);
          })}
        </ul>
      </div>
      <div className="sui-flex sui-flex-col sui-m-1 sui-mt-auto">
        <Button variant="secondary" href={service?.button_cta} target="_blank" fullWidth>
          {service?.id === 'Generic' ? 'Explore Our Services' : 'Learn More'}
        </Button>
      </div>
    </div>
  );
};

InformationAndCtaPod.propTypes = {
  service: PropTypes.shape({
    id: string,
    title: string,
    subText: string,
    service_img: string,
    button_cta: string,
    bulletsPoints: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  id: string,
  position: number,
};
InformationAndCtaPod.defaultProps = {
  id: '',
  position: 1
};
