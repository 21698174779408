import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Mic = createSvgIcon(
  <>
    <path d="M6.75 3.375A3.375 3.375 0 0 1 10.125 0h3.75a3.375 3.375 0 0 1 3.375 3.375v8.25A3.375 3.375 0 0 1 13.875 15h-3.75a3.375 3.375 0 0 1-3.375-3.375v-8.25Zm2.25 0V6h6V3.375c0-.621-.504-1.125-1.125-1.125h-3.75C9.504 2.25 9 2.754 9 3.375Zm6 4.875H9v3.375c0 .621.504 1.125 1.125 1.125h3.75c.621 0 1.125-.504 1.125-1.125V8.25Z" />
    <path d="M11.25 17.25a7.5 7.5 0 0 1-7.5-7.5H1.5c0 5.26 4.164 9.546 9.375 9.743V24h2.25v-4.507c5.211-.197 9.375-4.484 9.375-9.743h-2.25a7.5 7.5 0 0 1-7.5 7.5h-1.5Z" />
  </>,
  'Mic'
);

export { Mic };