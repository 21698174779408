/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { transformLegacyItem } from './itemTransformer';

const getBooleanFromString = (stringBool) => (stringBool === 'true' || stringBool === 'Y');

const getIntFromString = (intString) => {
  try {
    return (intString && (typeof intString === 'number') ? intString : parseInt(intString)) || 0;
  } catch (err) {
    console.error(err);
  }
  return 0;
};

const getFloatFromString = (floatString) => {
  try {
    return ((floatString && (typeof floatString === 'number') ? floatString : parseFloat(floatString)) || 0.0);
  } catch (err) {
    console.error(err);
  }
  return (0.0);
};

function setAdjustments(totals, cartModel) {

}

function getDiscountedTotal(cartModel) {
  let dTotal = 0.0;
  try {
    if (cartModel.totalDiscount) {
      dTotal = getFloatFromString(cartModel.cartTotal) - getFloatFromString(cartModel.totalDiscount);
    }
  } catch (err) {
    console.error(err);
  }
  return dTotal;
}

function getTotalDelivery(cartModel) {
  let dTotal = 0.0;
  try {
    dTotal = getFloatFromString(cartModel.totalDeliveryCharge) + getFloatFromString(cartModel.totalShippingCharge);
  } catch (err) {
    console.error(err);
  }
  return dTotal;
}

function setTotals(cartModel) {
  let totals = {};
  // const fieldMap = {
  //   cartTotal: 'cartTotal',
  //   discountedAmount: getDiscountendBigDecimal,
  //   deliveryCharge: BigDecimal,
  //   applianceDeliveryCharge: BigDecimal,
  //   // deliveryCharge: 'totalDeliveryCharge',
  //   // shippingCharge: 'totalShippingCharge',
  //   // itemTax: 'totalItemTax',
  //   // totalDiscount: 'totalDiscount',
  //   // totalItemTax: 'totalItemTax',
  // };
  try {
    totals.type = null;
    totals.discountedAmount = getDiscountedTotal(cartModel);
    totals.deliveryCharge = getTotalDelivery(cartModel);
    totals.applianceDeliveryCharge = 0.0;
    totals.plccEligibleTotal = 0.0;
    totals.adjustments = null;
    totals.totalDiscount = cartModel.totalDiscount || null;
    // if (cartModel.totalDiscount) {
    //   totals.adjustments = [];
    //   totals.adjustments.push({
    //     amount: getFloatFromString(cartModel.totalDiscount),
    //     type: 'Discount'
    //   });
    // }
    if (cartModel.totalItemTax) {
      totals.adjustments = totals.adjustments || [];
      totals.adjustments.push({
        amount: getFloatFromString(cartModel.totalItemTax),
        type: 'totalSalesTax'
      });
    }

    totals.total = getFloatFromString(cartModel.cartTotal);

    try {
      const { total, totalDiscount, salesTax, deliveryCharge } = totals;
      const twnd = (total + Math.abs(totalDiscount) - (cartModel.totalItemTax || 0) - deliveryCharge);
      totals.totalWithNoDiscount = parseFloat(twnd.toFixed('2'));
    } catch (err) {
      console.error(err);
      totals.totalWithNoDiscount = null;
    }
    // + getFloatFromString(totals.totalDiscount)
    // + getFloatFromString(totals.adjustments);

    // militaryDiscount:
    // {
    //   amount: 0.0
    // },
    // discount: {
    //   amount: getFloatFromString(cartModel.totalDiscount)
    // }
  } catch (err) {
    console.error(err);
  }
  return totals;
}

// function setContext(cart, cartModel) {
//   context = {
//     currency: cartModel.currency,
//     automatedOrderCompletion: getBooleanFromString(cartModel.automatedOrderCompletion),
//     paymentOnHold: false, // Checkout
//     applianceToCOM: true, // Checkout
//   };
//   return cart;
// }

function setQualifiers(cartModel) {
  let qualifiers = {
    cartType: 1,
    // paymentOnHold: false,
    // paypalExpress: false
  };
  return qualifiers;
}

function setPromos(cartModel) {
  let promos = null;
  try {
    if (cartModel.promotions && cartModel.promotions.promotion) {
      promos = [];
      cartModel.promotions.promotion.forEach((promo) => {
        const {
          desc,
          longDesc,
          type,
          tag,
          appliedDisc,
          lineItemPromotions
        } = promo;
        promos.push({
          desc,
          longDesc,
          type,
          tag,
          appliedDisc: getFloatFromString(appliedDisc)
        });
      });
    }
    // "promotions":
    //   {
    //       "promotion":
    //       [
    //           {
    //               "desc": "Save up to 25% on select blinds from Bali, Levolor, & Veneta. Now through 2/22!Save up to 20% on select blinds and shades from Bali and Veneta. Now through 2/22!\t\t\n",
    //               "longDesc": "Offer valid 2/9/23 through 2/22/23. Offer includes 35% off shades & 25% off blinds from Bali, Levolor, & Veneta. 20% off Bali Essentials & Veneta Shutters. Offer does not include custom order draperies. Online selections and prices will vary. Free shipping to all continental U.S. addresses. Promotion cannot be combined with any other offer and is valid on custom product only. License or registration numbers held on behalf of Home Depot, U.S.A. are available at homedepot.com/licensenumbers or at the Customer Notices Board in The Home Depot® store. Visit homedepot.com or ask an Associate for details. ©2021 Home Depot Product Authority, LLC. All rights reserved. State specific licensing information includes: AL 51289, 1924; AK 25084; AZ ROC252435, ROC092581; AR 0228160520; CA 602331; CT HIC.533772; DC 420214000109, 410517000372; FL CRC046858, CGC1514813; GA RBCO005730, GCCO005540; HI CT-22120; ID RCE-19683; IA C091302; LA 43960, 557308, 883162; MD 85434, 42144; MA 112785, CS-107774; MI 21\t\t\t",
    //               "type": "Order Level",
    //               "tag": "MSB",
    //               "appliedDisc": -19.32,
    //               "lineItemPromotions":
    //               {
    //                   "lineItemPromotion":
    //                   [
    //                       {
    //                           "appliedDisc": -19.32,
    //                           "appliedOn": "cd9aa771-ae1e-11ed-a973-89d152f20a68"
    //                       }
    //                   ]
    //               }
    //           },
    //           {
    //               "desc": "Coupon code_5% percent off_D23F JJ Roomvo Promo__191869",
    //               "longDes": "Coupon code_5% percent off_D23F JJ Roomvo Promo__191869",
    //               "type": "Item Level",
    //               "tag": "MSB",
    //               "appliedDisc": -28.13,
    //               "lineItemPromotions":
    //               {
    //                   "lineItemPromotion":
    //                   [
    //                       {
    //                           "appliedDisc": -28.13,
    //                           "appliedOn": "cd9aa771-ae1e-11ed-a973-89d152f20a68"
    //                       }
    //                   ]
    //               }
    //           }
    //       ]
    //   },
  } catch (err) {
    console.error(err);
  }
  return promos;
}

function setMessages(cartModel) {
  let messages = null;
  try {
    if (cartModel.messagesModel) {
      messages = [];
      cartModel.messagesModel.forEach((message) => {
        const {
          shortDescription,
          longDescription,
          name = null,
          correlationId = null,
          correlationType = null
        } = message;
        messages.push({
          shortDesc: shortDescription || null,
          longDesc: longDescription || null,
          type: 'message',
          correlationType: correlationType || null,
          correlationId: correlationId || name,
          messageCategoryType: null
        });
      });
    }
    if (cartModel.errorModel) {
      messages = messages || [];
      cartModel.errorModel.forEach((error) => {
        const {
          correlationType = null, // "cartLineItem",
          correlationId = null, // "e622d240-c903-11ed-997d-8191aea55cf0",
          errorCode, // "CART_ERR_303_4",
          description, // "This quantity is no longer available for the selected fulfillment. Reduce the quantity or change your fulfillment to proceed.",
          inventory // "62"
        } = error;
        messages.push({
          shortDesc: `${correlationType}-${errorCode}-${correlationId ? ('_' + correlationId) : ''}`,
          longDesc: description,
          type: 'error',
          correlationType,
          correlationId,
          messageCategoryType: null
        });
      });
    }

    // add item-level messages/alerts
    cartModel.itemModels.forEach((item) => {
      if (item.nonReturnable) {
        messages = messages || [];
        messages.push({
          shortDesc: '',
          longDesc: 'This item is non-returnable.',
          type: 'info',
          correlationType: 'cartLineItem',
          correlationId: item.lineItemId,
          messageCategoryType: null
        });
      }
      if (item.inStoreReturnEligibility) {
        messages = messages || [];
        messages.push({
          shortDesc: '',
          longDesc: 'This item is only returnable in store.',
          type: 'info',
          correlationType: 'cartLineItem',
          correlationId: item.lineItemId,
          messageCategoryType: null
        });
      }
      if (item.quantityLimit) {
        messages = messages || [];
        messages.push({
          shortDesc: '',
          longDesc: `Limit ${item.quantityLimit} per Order`,
          type: 'info',
          correlationType: 'cartLineItem',
          correlationId: item.lineItemId,
          messageCategoryType: null
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
  return messages;
}

function getDeliveryZipCode(items) {
  items.forEach((item) => {
    item.fulfillment = item.fulfillmentModel.reduce((fulfillment, ffm) => {
      fulfillment[ffm.fulfillmentMethod] = ffm;
      return fulfillment;
    }, {});
  });
  const BOPIS_FFM = 'BOPIS';
  const BOSS_FFM = 'ShipToStore';
  const STH_FFM = 'ShipToHome';
  const APPLIANCE_FFM = 'DirectDelivery';
  const BODFS_FFM = 'DeliverFromStore';
  let deliveryZipCode = null;
  try {
    const hasAppliance = items.some(
      (item) => ((item.fulfillment[APPLIANCE_FFM]) && (item.shipType === '29'))
    );
    const hasBodfs = items.some(
      (item) => item.fulfillment[BODFS_FFM]
    );
    const hasSth = items.some(
      (item) => (((item.fulfillment[APPLIANCE_FFM]) && (item.shipType !== '29'))
        || (item.fulfillment[STH_FFM]))
    );
    let ffmType = null;
    if (hasAppliance) {
      ffmType = APPLIANCE_FFM;
    } else if (hasBodfs) {
      ffmType = BODFS_FFM;
    } else if (hasSth) {
      ffmType = STH_FFM;
    }

    if (ffmType) {
      const filteredItems = items.filter(
        (item) => (!!item.fulfillment[ffmType])
      );
      // if (hasAppliance || hasBodfs) {
      //   deliveryZipCode = filteredItems[0].deliveryZipCode;
      // } if (hasSth) {
      //   deliveryZipCode = filteredItems[0]?.fulfillment?.zipCode;
      // }
      if (hasAppliance || hasBodfs || hasSth) {
        const item = filteredItems[0];
        if (item) {
          deliveryZipCode = item.deliveryZipCode || item.fulfillment[ffmType].deliveryZipCode || item.fulfillment[ffmType].zipCode;
        }

      }
    }
  } catch (err) {
    console.error(err);
  }

  return deliveryZipCode;
}

function getStateCode(cartModel) {
  let stateCode = null;
  try {
    cartModel.itemModels.forEach((item) => {
      item.fulfillmentModel.forEach((ffm) => {
        if (ffm.state) {
          if (stateCode && stateCode !== ffm.state) {
            console.warn('Multiple stateCodes in fulfillments');
          }
          stateCode = ffm.state;
        }
      });
    });
  } catch (err) {
    console.error(err);
  }
  return stateCode;
}

function setLocalization(cartModel) {
  let localization = {
    primaryStoreId: getIntFromString(cartModel.localStoreId),
    deliveryZip: getDeliveryZipCode(cartModel.itemModels),
    deliveryStateCode: getStateCode(cartModel)
  };
  return localization;
}

export const setItemGrouping = (cart) => {
  let itemGrouping = {
    byFulfillment: {
      pickup: [],
      delivery: {
        grouping: [{
          type: 'unscheduled',
          ids: []
        }, {
          type: 'scheduled',
          ids: []
        }]
      },
      appliance: [{
        type: 'appliance',
        zipCode: null,
        ids: []
      }],
    }
  };
  try {
    const _grouping = itemGrouping.byFulfillment;
    cart.items.forEach((item) => {
      if (item.__fulfillmentGroup === 'pickup') {
        const storeGroup = _grouping.pickup.find((group) => (group.location.storeName === item.__storeName));
        if (storeGroup) {
          storeGroup.ids.push(item.id);
        } else {
          _grouping.pickup.push({
            ids: [item.id],
            location: {
              storeName: item.__storeName,
              curbsidePickupFlag: item.__curbsidePickupEligible,
            }
          });
        }
      } else if (item.__fulfillmentGroup === 'appliance') {
        if (item.__deliveryZipCode) {
          _grouping.appliance[0].zipCode = item.__deliveryZipCode;
        }
        _grouping.appliance[0].ids.push(item.id);
      } else {
        _grouping.delivery.grouping[0].ids.push(item.id);
      }
    });
  } catch (err) {
    console.error(err);
  }
  return itemGrouping;
};

const EMPTY_CART = {
  cartId: 'empty',
  itemCount: null,
  customer: null,
  payments: null,
  // rentalEstimate: RentalEstimate,
  messages: [
    {
      type: 'info',
      correlationId: null,
      correlationType: 'generic',
      longDesc: null,
      shortDesc: 'Empty Shopping Cart',
      messageCategoryType: null
    }
  ],
  items: null,
  itemGrouping: null,
  descriptiveAttr: null,
  promos: null,
  localization: null,
  totals: null,
  rentalEstimate: null
};

const setDescriptiveAttr = (cartModel) => {
  let descriptiveAttr;
  try {
    if (cartModel) {
      const {
        paypalExpress = true,
        poJobName = null
      } = cartModel;

      descriptiveAttr = {
        paypalExpress,
        poJobName,
        cartType: '1',
        paymentOnHold: false,
        isIcEnabled: null,
        displayIcOption: null,
        hasSubscriptionItems: null,
        maxCartPriceContributor: null,
        uniqueItemCount: 0
      };
    }
  } catch (err) {
    console.log(err);
  }
  return descriptiveAttr;
};

export const transformLegacyCart = (cartModel) => {
  let cart = {};
  try {
    cartModel = cartModel.CartModel || cartModel;
    if (!cartModel.itemModels || !cartModel.itemModels.length) {
      return EMPTY_CART;
    }
    cart.cartId = cartModel.orderId;
    // cart.orderId = '';
    cart.itemCount = getIntFromString(cartModel.totalItemCount);
    cart.totals = setTotals(cartModel);
    cart.instantCheckoutModel = {
      displayOption: 'EDIT_IC',
      addressCity: 'SMYRNA',
      addressZipCode: '30080',
      xref: '2339',
      cardType: 'VISA',
      paymentId: 'P1349FD17007470940',
      cardBrand: 'VISA',
      addresses: {
        shippingAddress: {
          firstName: 'RAJESHVARAN',
          lastName: 'MOOKAIAH',
          state: 'GA',
          addressLine1: '1710 VININGS TRL SE',
          city: 'SMYRNA',
          zipCode: '30080'
        }
      }
    };
    cart.messages = setMessages(cartModel);
    cart.qualifiers = setQualifiers(cartModel);
    cart.localization = setLocalization(cartModel);
    cart.promos = setPromos(cartModel);
    cart.items = [];
    if (cartModel.itemModels) {
      const MAX = 98;
      const INDEX = null;
      cartModel.itemModels.forEach((itemModel, index) => {
        if ((INDEX !== null) ? (index === INDEX) : (cart.items.length < MAX)) { // throttle for development
          cart.items.push(transformLegacyItem(itemModel));
        }
      });
    }
    cart.itemGrouping = setItemGrouping(cart);
    cart.descriptiveAttr = setDescriptiveAttr(cartModel);

    cart.info = {
      returnable: null,
      quantityLimit: null,
      inStoreReturnEligibility: null
    };
    cart.customer = null;
    cart.payments = null;
    cart.rentalEstimate = null;
  } catch (err) {
    console.error('\n\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Error transforming mcc cart\n', err);
    return EMPTY_CART;
  }
  // console.log(cart);
  return cart;
};
