import React, { useCallback } from 'react';
import { func } from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardTitle,
  Typography
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { Clock } from '@one-thd/sui-icons';
import classnames from 'classnames';
import { useMessage } from '../../../../hooks/useMessage';
import { usePromoModel } from '../../../../hooks/usePromoModel';
import { usePromoPresentation } from '../../../../hooks/usePromoPresentation';
import { MESSAGE_KEY } from '../../../../utils/constants';
import { noop } from '../../../../utils/promo-utils';
import { CardSelectProductLoader } from '../../../PromotionProductsLoader/subcomponents/CardSelectProductLoader';
import { PromoPod } from '../../../PromoPod/PromoPod';
import { ProductImageList } from '../../../ProductImageList/ProductImageList';
import { CardCallToActionDataModel } from '../../../../models/CardCallToActionDataModel';
import { useNewRelicLogging } from '../../../../hooks/useNewRelicLogging';

export const CardCallToAction = ({ onLoad }) => {
  const {
    analyticsComponent,
    allListProductsAndAnchor,
    displayableFirstListItemIds,
    isExpiring,
    anchorItem
  } = usePromoModel();
  const {
    firstListPreviewImages, isTouchDevice, setIsDrawerOpen
  } = usePromoPresentation();
  const { sendBogoCardDataToNewRelic } = useNewRelicLogging();
  const cardCtaExpDate = useMessage(MESSAGE_KEY.cardCtaExpDate);
  const cardCtaHeaderText = useMessage(MESSAGE_KEY.cardCtaHeaderText);
  const cardCtaProductImageListText = useMessage(MESSAGE_KEY.cardCtaProductImageListText,
     displayableFirstListItemIds?.length);
  const cardCtaShopOfferButtonText = useMessage(MESSAGE_KEY.cardCtaShopOfferButton);
  const onClick = useCallback(() => {
    onLoad();
    setIsDrawerOpen(true);
  }, [onLoad, setIsDrawerOpen]);
  const handleClick = () => {
    onClick();
    const getFirstKeyInMap = (map) => Array.from(map)[0][0];
    const rewardItemId = getFirstKeyInMap(allListProductsAndAnchor);
    sendBogoCardDataToNewRelic({
      appName: 'nucleus.my-homepage',
      actionName: 'BOGO-PHP-DrawerClick',
      customerType: 'B2B',
      segment: 'Promotions',
      experience: 'BOGO',
      subExperience: 'BOGO/BOGO Percent Off/BOGO Dollar Off',
      anchorItemId: anchorItem.itemId,
      rewardItemId
    });
    // Analytics for drawer open
    window.LIFE_CYCLE_EVENT_BUS.trigger('promotion-products-cta-card.click', {
      component: analyticsComponent,
      section: 'bogo card cta',
      target: 'see eligible items',
    });
  };
  const onMouseEnter = !isTouchDevice ? onLoad : undefined;
  const anchorItemUrl = anchorItem?.identifiers?.canonicalUrl;
  const loadingPreviewImages = !firstListPreviewImages?.length;

  const cardTitle = (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a href={anchorItemUrl} style={{ textDecoration: 'none' }}>
      <CardTitle
        header={<Typography variant="h3">{cardCtaHeaderText}</Typography>}
        subheader={isExpiring
          && (
            <span className="sui-flex sui-font-bold sui-text-brand sui-pt-1">
              <Clock size="regular" />
              <span className="sui-ml-2">{cardCtaExpDate}</span>
            </span>
          )}
      />
    </a>
  );

  const anchorSection = (
    <PromoPod
      key={anchorItem.itemId}
      itemId={anchorItem.itemId}
      displayPosition={0}
      hideKpf
      hideAtc
      hideCta
      hideCaret
      hideBorder
      hideQuickview
      isInPromoList={false}
    />
  );

  const rewardSection = loadingPreviewImages ? <CardSelectProductLoader /> : (
    <>
      {/** Focus is handled by button */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        className="sui-pt-4"
      >
        <div className="sui-pb-4 sui-text-lg sui-font-regular sui-text-subtle">{cardCtaProductImageListText}</div>
        <ProductImageList
          images={firstListPreviewImages}
          size="33%"
          type="card"
        />
      </div>
    </>
  );

  const CardClasses = classnames(
    'sui-h-full sui-justify-between sui-grid',
  );

  const { ref, clickID } = useImpression({
    data: {
      id: anchorItem.itemId,
      name: 'ProductPod',
      component: 'ProductPod',
      type: 'Product',
      position: 1,
    },
  });

  return (
    <Card
      ref={ref}
      clickid={clickID}
      className={CardClasses}
      data-component="PromotionProductsCardCallToAction"
      data-testid="card-call-to-action"
    >
      {cardTitle}
      <div className="sui-content-center sui-grow">
        {anchorSection}
        {rewardSection}
      </div>
      <CardActions position="end">
        <Button
          fullWidth
          variant="secondary"
          onClick={handleClick}
          data-testid="promo-drawer--open"
        >
          {cardCtaShopOfferButtonText}
        </Button>
      </CardActions>
    </Card>
  );
};

CardCallToAction.displayName = 'CardCallToAction';

CardCallToAction.propTypes = {
  onLoad: func,
};

CardCallToAction.defaultProps = {
  onLoad: noop,
};

CardCallToAction.dataModel = CardCallToActionDataModel;
