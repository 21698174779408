import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableContext from '../private/components/table/TableContext';

/**
 * A table displays information in a tabular layout using rows and columns.
 * Tables are useful for comparing and analyzing data or displaying a directory of information.
 *
 * Use `Table` with the following sub-components:
 *
 * [TableHead](#tablehead), [TableBody](#tablebody), [TableFooter](#tablefooter), [TableRow](#tablerow),
 * [TableCell](#tablecell), [TableSortLabel](#tablesortlabel), [TableRowLabel](#tablerowlabel),
 * [TableFooterPagination](#tablefooterpagination), [TableFooterShowMore](#tablefootershowmore).
 *
 * Usage:
 *
 * ```jsx
 * import { Table } from '@one-thd/sui-atomic-components';
 * ```
 */
const Table = React.forwardRef((props, ref) => {
  const {
    children,
    stickyHeader,
    density,
    layout,
    unlined,
    ...other
  } = props;

  const tableContextValue = React.useMemo(
    () => {
      const childrenTypes = React.Children.map(children, (child) => child.type.displayName);
      const hasTableHead = childrenTypes.some((type) => type === 'TableHead');
      const hasTableFooter = childrenTypes.some((type) => type === 'TableFooter');
      return {
        density, stickyHeader, unlined, hasTableHead, hasTableFooter
      };
    },
    [density, stickyHeader, unlined, children],
  );

  const tableClasses = classNames('sui-w-full sui-border-separate sui-border-spacing-0', {
    'sui-table-auto': layout === 'auto',
    'sui-table-fixed': layout === 'fixed'
  });

  return (
    <TableContext.Provider value={tableContextValue}>
      <table
        className={tableClasses}
        ref={ref}
        {...other}
      >
        {children}
      </table>
    </TableContext.Provider>
  );
});

Table.displayName = 'Table';

Table.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Set the row density.
   * @default 'normal'
   */
  density: PropTypes.oneOf(['tight', 'normal', 'loose']),
  /**
   * Set the header sticky.
   * @default false
   */
  stickyHeader: PropTypes.bool,
  /**
   * Remove the border between table rows
   * @default false
   */
  unlined: PropTypes.bool,
  /**
   * Sets the table-layout CSS property.
   */
  layout: PropTypes.oneOf(['auto', 'fixed']),
};

export { Table };