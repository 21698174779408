import { htmlAttributes, htmlSelectors } from '../constants';
import { isBeaconSent, isVisible, triggerBeacon } from './checkVisibilityHelpers';

export const checkVisibility = (containerSelector) => {
  const container = document.querySelector(containerSelector);
  const onViewElements = (container || document)
    ?.querySelectorAll(`[${htmlAttributes.ONVIEW_BEACON_NUCLEUS}], [${htmlAttributes.DATA_ONVIEW_BEACON_NUCLEUS}]`);

  // add default banner as eligible for impression without onview beacon
  const defaultBanner = document.querySelector(htmlSelectors.DEFAULT_BANNER);
  const elements = defaultBanner ? [
    ...onViewElements,
    defaultBanner
  ] : [...onViewElements];

  elements.forEach((el) => {
    if (!isBeaconSent(el) && isVisible(window, el)) {
      setTimeout(() => {
        if (!isBeaconSent(el) && isVisible(window, el)) {
          triggerBeacon(el);
        }
      }, 1000);
    }
  });
};
