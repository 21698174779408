import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const RidingMowers = createSvgIcon(
  <>
    <path d="M2.262.103 4.217 6.75H9.75V9H2.533L.103.738 2.262.103Z" />
    <path d="M21.056 5.806 12 4.296v6.346l3.334 2.858H12.09l-3-3H0v11.25h2.094a3.001 3.001 0 0 0 5.811 0h8.19a3.001 3.001 0 0 0 5.81 0H24V13.5h-.75V8.396a2.625 2.625 0 0 0-2.194-2.59ZM16.401 19.5H7.6a2.999 2.999 0 0 0-5.198 0H2.25v-6.75h5.909l3 3H21.75v3.75h-.151a3 3 0 0 0-5.198 0Zm4.599-6h-2.209L14.25 9.608V6.953l6.437 1.073c.18.03.313.186.313.37V13.5Z" />
  </>,
  'RidingMowers'
);

export { RidingMowers };