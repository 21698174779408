import React from 'react';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '@thd-olt-component-react/loading-placeholder';
import Footer from './partials/Footer';
import './delivery-options.style.scss';

const DeliveryContentLoader = ({
  zipCode
}) => {
  return (
    <>
      <div className="delivery-options-content">
        <div className="delivery-options-inner">
          <div className="delivery-options-header">
            <div className="header-title">
              Delivery options for {zipCode}
            </div>
          </div>
          <div className="delivery-options-templates delivery-options-loading">
            <LoadingPlaceholder
              type="text"
              rows={3}
              color="#E0E0E0"
              showLoadingAnimation
              ready={false}
            >
              Delivery Container Loader
            </LoadingPlaceholder>
            <LoadingPlaceholder
              type="text"
              rows={3}
              color="#E0E0E0"
              showLoadingAnimation
              ready={false}
            >
              Delivery Container Loader
            </LoadingPlaceholder>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

DeliveryContentLoader.propTypes = {
  zipCode: PropTypes.string.isRequired,
};

DeliveryContentLoader.defaultProps = {};

export default DeliveryContentLoader;
