import React from 'react';
import classNames from 'classnames';
import { bool, string, func } from 'prop-types';
import './Voting.styles.scss';

const ReportReview = ({ submitReport, reported, reviewId, useText }) => {
  const reportClass = classNames({
    ratings_reviews__voting__activeReport: reported,
    ratings_reviews__voting__passiveReport: !reported
  });

  if (reported) {
    return (
      <p className="report-feedback__text">
        Thank you for submitting feedback for this answer!
      </p>
    );
  }

  if (useText) {
    return (
      <button
        className="report-review__text"
        onClick={submitReport}
        type="button"
      >
        Report Review
      </button>
    );
  }
  return (
    <button
      aria-label="Report"
      className={reportClass}
      id={`reportIssue_${reviewId}`}
      onClick={submitReport}
      title="Report"
      type="button"
    />
  );
};

ReportReview.propTypes = {
  submitReport: func.isRequired,
  reported: bool.isRequired,
  reviewId: string,
  useText: bool
};

ReportReview.defaultProps = {
  useText: false,
  reviewId: ''
};

export { ReportReview };
