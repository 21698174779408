import React, { Component } from 'react';
import axios from 'axios';
import * as analytics from '../../../ratings-reviews-analytics';

class VotingHelper extends Component {

  static notifyBV(hostName, reviewId, feedbackType, voteType, reasonText, apiKey, userId, setHelpfulResponse) {
    // eslint-disable-next-line max-len
    let url = `${hostName}/ReviewServices/reviewSubmit/v1/feedback?id=${reviewId}&key=${apiKey}&reasontext=${reasonText}&feedbacktype=${feedbackType}&contenttype=review&vote=${voteType}&userid=${userId}`;

    axios.post(url, {}).then((data) => {
      // console.log('Notified BV with ' + data.status + ' status');
      setHelpfulResponse();
    }).catch((exception) => {
      // console.log(JSON.stringify(exception));
    });

    if (voteType === 'Negative' || voteType === 'Positive') {
      const value = voteType === 'Positive' ? 'upvote' : 'downvote';
      analytics.track('event', { origin: 'ratings and reviews', location: 'helpful', value, eventType: 'click' });
    }
  }
}

export default VotingHelper;
