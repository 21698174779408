import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleLightYellow = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleLightYellow.palette['--sui-palette-background-primary'] = '255 230 158';

export { HouseStyleLightYellow };