const isAnchorLink = (link) => link.startsWith('#');
const isAbsoluteLink = (link) => /^https?:\/\//.test(link);
const isHomeDepotLink = (link) => {
  try {
    return new URL(link).hostname.includes('homedepot.com');
  } catch (error) {
    return false;
  }
};

export const getTarget = (link) => {
  if (!link) return '_blank';
  if (isAnchorLink(link)) return '_self';
  if (isAbsoluteLink(link)) {
    return isHomeDepotLink(link) ? '_self' : '_blank';
  }
  return '_self';
};