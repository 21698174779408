/* eslint-disable react/prop-types */
import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const Price = ({
  price
}) => {
  return (
    <div
      data-automation-id="atc-product-price"
      className="sui-flex sui-flex-col sui-pt-3 sui-pl-0 md:sui-pl-3 md:sui-pt-0"
    >
      <Typography variant="body-base" weight="bold">{`$${price}`}</Typography>
      <Typography variant="body-xs">Taxes are calculated during checkout.</Typography>
    </div>
  );
};

Price.propTypes = {
  price: string.isRequired
};

Price.displayName = 'Price';
