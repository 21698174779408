const sendToBus = (name, payload) => {
  window.LIFE_CYCLE_EVENT_BUS.trigger(name, payload);
};

const analyticsPrefixes = {
  welcomeCard: 'homepage: welcome: nav',
  pacDrawer: 'rewards hub',
};

export const analyticsClick = (prefix, message) => {
  sendToBus('loyalty-benefits.loyalty-button', {
    settings: `${analyticsPrefixes[prefix]}: ${message}`
  });
};

export const analyticsOverlay = (prefix, message) => {
  sendToBus('loyalty-benefits.loyalty-overlay', {
    settings: `${analyticsPrefixes[prefix]}: ${message}`
  });
};

export const analyticsStash = (prefix, message, link = '') => {
  const addLink = link ? `: ${link}` : '';
  sendToBus('loyalty-benefits.loyalty-stash', {
    settings: `${analyticsPrefixes[prefix]}: ${message}` + addLink,
  });
};
