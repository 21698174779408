import React, { useContext } from 'react';
import { bool, node, oneOf } from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';

/**
 * A configurable overflow container for handling when overflow content is out of view.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {'horizontal' | 'vertical'} [props.orientation]
 */
export const OverflowContainer = ({
  children,
  orientation,
  scroll
}) => {
  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  const isHorizontal = orientation === 'horizontal';
  const isVertical = orientation === 'vertical';

  const outerClasses = classNames(
    'sui-overflow-hidden',
    {
      'sui-invisible hover:sui-visible': !isMobile,
      'sui-overflow-x-auto': scroll && isHorizontal,
      'sui-overflow-y-auto': scroll && isVertical
    }
  );

  return (
    <div className={outerClasses}>
      <div className="sui-visible">
        {children}
      </div>
    </div>
  );
};

OverflowContainer.displayName = 'OverflowContainer';

OverflowContainer.propTypes = {
  children: node.isRequired,
  orientation: oneOf(['horizontal', 'vertical']),
  scroll: bool
};

OverflowContainer.defaultProps = {
  orientation: 'horizontal',
  scroll: true
};
