const walk = (object, filter) => {
  return Object.keys(object).map((key) => {
    if (typeof object[key] === 'object') {
      const shouldFilter = filter(object[key]);
      if (shouldFilter) {
        return {};
      }
      return { [key]: walk(object[key], filter) };
    }

    return { [key]: object[key] };
  }).reduce((acc, cur) => {
    return {
      ...acc,
      ...cur
    };
  }, {});
};

export const filterClient = (model) => {

  const omited = walk(model, (obj) => {
    if (obj._client) {
      return true;
    }
    return false;
  });

  return omited;
};
