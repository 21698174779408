import React from 'react';
import { bool, string } from 'prop-types';
import { Card, Typography, Button } from '@one-thd/sui-atomic-components';
import { Orders } from '@one-thd/sui-icons';
import { useImpression } from '@thd-olt-component-react/impression';
import { RecentPurchasesTableHeader, RecentPurchasesCardHeader } from '../subcomponents/cardHeader/RecentPurchasesHeader';
import { purchaseHistoryURL } from '../../../constants';

function RecentPurchasesFallbackTable({ isError, noData, componentId }) {
  let headerMessage;
  let subheaderMessage;
  let testIdType;

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'RecentPurchasesFallbackTable',
      component: 'RecentPurchasesFallbackTable',
      position: 1,
      type: 'content',
    },
  });

  if (isError) {
    testIdType = 'error';
    headerMessage = (
      <Typography variant="h3">We couldn&apos;t load your order</Typography>
    );
    subheaderMessage = (
      <Typography>Try refreshing the page or check back later.</Typography>
    );
  } else if (noData) {
    testIdType = 'fallback';
    headerMessage = <Typography variant="h3">No Recent Orders</Typography>;
    subheaderMessage = (
      <Typography>Visit your Order History to see older orders</Typography>
    );
  }

  return (
    <Card
      data-testid={`recentOrders-card-b2b-${testIdType}-table`}
      className="sui-h-full sui-w-full"
      data-component="RecentPurchasesFallbackTable"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <RecentPurchasesTableHeader />
      <div
        data-testid={`recentOrders-content-b2b-${testIdType}-table`}
        className="sui-flex sui-items-center sui-h-96 sui-w-full sui-flex-col sui-justify-center"
        data-type="container"
      >
        <Orders color="subtle" size="large" />
        <span className="sui-mt-8 sui-mb-4">{headerMessage}</span>
        <span className="sui-mb-8">{subheaderMessage}</span>
      </div>
      <Button fullWidth href={purchaseHistoryURL} target="_blank" variant="secondary">View Order History</Button>
    </Card>
  );
}

function RecentPurchasesFallbackCard({ isError, noData }) {
  let headerMessage;
  let subheaderMessage;
  let testIdType;

  if (isError) {
    testIdType = 'error';
    headerMessage = (
      <Typography variant="h3">We couldn&apos;t load your order</Typography>
    );
    subheaderMessage = (
      <Typography>Try refreshing the page or check back later.</Typography>
    );
  } else if (noData) {
    testIdType = 'fallback';
    headerMessage = <Typography variant="h3">No Recent Orders</Typography>;
    subheaderMessage = (
      <Typography>Visit your Order History to see older orders</Typography>
    );
  }

  return (
    <Card data-testid={`recentOrders-card-b2b-${testIdType}-carousel`} className="sui-h-full sui-w-full" data-component="RecentPurchasesFallbackCard">
      <RecentPurchasesCardHeader />
      <div
        data-testid={`recentOrders-content-b2b-${testIdType}-carousel`}
        className="sui-flex sui-items-center sui-h-96 sui-w-full sui-flex-col sui-justify-center"
        data-type="container"
      >
        <Orders color="subtle" size="large" />
        <span className="sui-mt-8 sui-mb-4">{headerMessage}</span>
        <span className="sui-mb-8">{subheaderMessage}</span>
      </div>
      <Button fullWidth href={purchaseHistoryURL} target="_blank" variant="secondary">View Order History</Button>
    </Card>
  );
}

RecentPurchasesFallbackTable.propTypes = {
  isError: bool,
  noData: bool,
  componentId: string,
};

RecentPurchasesFallbackTable.defaultProps = {
  isError: false,
  noData: false,
  componentId: ''
};

RecentPurchasesFallbackCard.propTypes = {
  isError: bool,
  noData: bool,
};

RecentPurchasesFallbackCard.defaultProps = {
  isError: false,
  noData: false,
};

export { RecentPurchasesFallbackTable, RecentPurchasesFallbackCard };
