import React from 'react';
import { useDataModel,
  params,
  shape,
  string as stringType, } from '@thd-nucleus/data-sources';
import { RatingsAndReviewsDefaultProps, RatingsAndReviewsPropTypes } from '../RatingsAndReviewsPropTypes';

export const ProductAttribution = ({ itemId }) => {
  const { data, loading } = useDataModel('product', {
    variables: {
      itemId
    },
  });

  if (loading || !data) {
    return '';
  }
  const { product } = data;
  const { identifiers } = product || {};

  return (
    <div className="sui-mt-4 sui-mb-4 sui-text-subtle">This review is from&nbsp;
      <a target="_blank" href={identifiers?.canonicalUrl} rel="noreferrer">{identifiers?.productLabel}</a>
    </div>
  );
};

ProductAttribution.propTypes = RatingsAndReviewsPropTypes;

ProductAttribution.defaultProps = RatingsAndReviewsDefaultProps;

ProductAttribution.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    identifiers: shape({
      itemId: stringType(),
      brandName: stringType(),
      productLabel: stringType(),
      canonicalUrl: stringType()
    }),
  })
};