import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStylePrimaryWarmOrange = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStylePrimaryWarmOrange.palette['--sui-palette-background-primary'] = '255 119 31';

export { HouseStylePrimaryWarmOrange };