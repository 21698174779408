import {
  params,
  shape as shapeType,
  string as stringType,
  number as numberType,
  bool as boolType,
  arrayOf as arrayOfType,
  extend
} from '@thd-nucleus/data-sources';
import { ListTile } from '@thd-olt-component-react/list-tile';

export const dataModel = extend({
  lists: params({ page: numberType(), pageSize: numberType() }).shape({
    pagination: shapeType({
      pageSize: numberType(),
      totalResults: numberType(),
      totalPages: numberType(),
      hasMore: boolType()
    }),
    results: arrayOfType({
      id: stringType(),
      name: stringType(),
      url: stringType(),
      itemCount: numberType(),
      listAccessType: stringType(),
      images: arrayOfType(shapeType({
        url: stringType()
      }))
    })
  })
}, ListTile);