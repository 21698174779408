import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import helpers from '../../../helpers/price-helper';

export const StrikeThrough = ({ price }) => {
  const formattedPrice = helpers.getUSDollarWithCommas(price);
  return (
    <Typography decoration="line-through">
      {formattedPrice}
    </Typography>
  );
};

StrikeThrough.propTypes = {
  price: PropTypes.number,
};

StrikeThrough.defaultProps = {
  price: 0
};

StrikeThrough.displayName = 'StrikeThrough';
