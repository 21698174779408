import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { arrayOf, shape, string, number } from 'prop-types';

import { InstoreOrderDetails } from '../orderDetails/inStoreOrderDetails';
import { OnlineOrderDetails } from '../orderDetails/onlineOrderDetails';
import { RecentPurchasesCardDate } from '../subcomponents/orderDate/RecentPurchasesDate';
import { RecentPurchasesCardJobName } from '../subcomponents/jobName/RecentPurchasesJobName';
import { RecentPurchasesCardOrigin } from '../subcomponents/orderOrigin/RecentPurchasesOrigin';
import { RecentPurchasesCardReceipt } from '../subcomponents/orderReceipt/RecentPurchasesReceipt';
import { RecentPurchasesCardTotal } from '../subcomponents/orderTotal/RecentPurchasesTotal';
import { RecentPurchasesCardButton } from '../subcomponents/ordersButton/RecentPurchasesButton';
import { OrderStatus, LazyOrderStatus } from '../subcomponents/orderStatus';
import { OrderItems, LazyOrderItems } from '../subcomponents/orderItems';
import {
  createOrderDetailsURL,
  getStoreNumber,
  isOnlineOrder,
} from '../helpers';

const LAZY_LOAD_INDEX_THRESHOLD = 2;

export function CardOrder({ customerInfo, index, order, windowWidth }) {
  const isOnline = order.orderNumbers && order.orderNumbers[0] ? isOnlineOrder(order.orderNumbers[0]) : false;
  const storeNumber = getStoreNumber(order);
  const orderDetailsUrl = createOrderDetailsURL({
    isOnline,
    order,
    customerInfo,
    storeNumber,
  });

  const OrderDetailsType = isOnline ? OnlineOrderDetails : InstoreOrderDetails;
  const OrderStatusComponent = index < LAZY_LOAD_INDEX_THRESHOLD ? OrderStatus : LazyOrderStatus;
  const OrderItemsComponent = index < LAZY_LOAD_INDEX_THRESHOLD ? OrderItems : LazyOrderItems;

  return (
    <QueryProvider cacheKey={order.receiptDetails}>
      <div data-testid={`recentOrders-contentCard-b2b${index}`} className="sui-w-full sui-flex sui-flex-col sui-border-1 sui-border-solid sui-border-primary sui-rounded-sm recentPurchasesCarouselContainer">
        <div className="sui-pt-4 sui-px-4 sui-pb-0">
          <OrderStatusComponent
            customerInfo={customerInfo}
            isOnline={isOnline}
            layout="card"
            order={order}
            OrderDetailsType={OrderDetailsType}
            index={index}
          />
          <RecentPurchasesCardDate orderDate={order.salesDate} />
          <RecentPurchasesCardReceipt
            orderReceiptDetails={
              isOnline ? order.orderNumber : order.receiptDetails
            }
          />
          <RecentPurchasesCardJobName POJobName={order.POJobName} />
          <RecentPurchasesCardOrigin orderOrigin={order.orderOrigin} />
          <RecentPurchasesCardTotal orderTotal={order.totalAmount} />
        </div>
        <hr className="sui-w-full sui-mt-4 sui-mb-3 sui-mx-0 sui-border-t-1 sui-border-solid sui-border-primary" />
        <div className="sui-py-0 sui-px-4">
          <OrderItemsComponent
            isOnline={isOnline}
            customerInfo={customerInfo}
            layout="card"
            order={order}
            OrderDetailsType={OrderDetailsType}
            windowWidth={windowWidth}
            index={index}
          />
        </div>
        <hr className="sui-w-full sui-mt-4 sui-mb-0 sui-mx-0 sui-border-t-1 sui-border-solid sui-border-primary" />
        <div className="sui-h-full sui-py-0 sui-px-4 sui-flex sui-justify-around sui-items-center">
          <RecentPurchasesCardButton href={orderDetailsUrl} />
        </div>
      </div>
    </QueryProvider>
  );
}

CardOrder.propTypes = {
  order: shape({
    POJobName: string,
    detailsSource: string,
    orderNumber: string,
    orderNumbers: arrayOf(string),
    orderOrigin: string,
    receiptDetails: string,
    registerNumber: number,
    salesDate: string,
    totalAmount: number,
    transactionId: number,
    transactionType: string,
  }),
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  windowWidth: number,
  index: number,
};

CardOrder.defaultProps = {
  order: {},
  customerInfo: {},
  windowWidth: undefined,
  index: undefined,
};
