import React, { useContext } from 'react';
import { bool, func, shape } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { productShape } from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { BopisOffIcon, LimitedStockIcon } from '../../helpers/icon-utils';
import { isMobile, isFunction, getPickupStoreName } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';

export const LimitedAvailability = ({
  product,
  onCheckNearbyStoresClick,
  customRender,
  bopisNotFulfillableOOS = false
}) => {
  const { channel } = useContext(ExperienceContext);
  const backupStoreName = useStore()?.storeName;
  const storeName = getPickupStoreName(product) || backupStoreName;

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({
        pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        values: { product, storeName }
      });
    }
    return (<div className="store__message" />);
  };

  if (!(product && storeName)) {
    return emptyComponent();
  }

  const storeNameLink = isMobile(channel)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span> : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
      />
    );

  if (isFunction(customRender)) {
    return customRender(
      {
        pickUpTemplate: PICKUP_TEMPLATES.LIMITED_AVAILABILITY,
        icon: LimitedStockIcon,
        values:
          { storeNameLink, storeName }
      });
  }

  return (
    <div className="store__message">
      <div className="store__icon">{bopisNotFulfillableOOS ? BopisOffIcon : LimitedStockIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">{bopisNotFulfillableOOS ? 'At Your Store' : 'Limited Stock'}</div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__dark">{'Visit '}</span>
          {storeNameLink}
          <span className="store__dark">{' to check availability '}</span>
        </div>
      </div>
    </div>
  );
};

LimitedAvailability.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func,
  bopisNotFulfillableOOS: bool
};

LimitedAvailability.defaultProps = {
  onCheckNearbyStoresClick: () => { },
  customRender: null,
  bopisNotFulfillableOOS: false
};
