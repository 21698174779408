import React from 'react';
import { number as numberType, string as stringType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { orderTotalFormatter } from '../../../../recentPurchases/components/helpers';

export const RecentOrdersCardTotal = ({ orderTotal, orderOrigin }) => {
  const orderTotalFormatted = orderTotalFormatter(orderTotal);
  const totalAmount = orderTotalFormatted || '';
  const origin = orderOrigin ? ` | ${orderOrigin}` : '';

  return (
    <div
      data-testid="totalAmount"
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden"
    >
      <span className="sui-mr-2">
        <Typography color="subtle" variant="body-base">
          Total:
        </Typography>
      </span>
      <Typography truncate color="subtle" variant="body-base">
        {totalAmount}{origin}
      </Typography>
    </div>
  );
};

RecentOrdersCardTotal.propTypes = {
  orderOrigin: stringType,
  orderTotal: numberType
};

RecentOrdersCardTotal.defaultProps = {
  orderOrigin: '',
  orderTotal: null
};
