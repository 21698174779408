import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Mobile = createSvgIcon(
  <>
    <path d="M9.75 17.25h4.5v2.25h-4.5v-2.25Z" />
    <path d="M4.5 1.119A1.125 1.125 0 0 1 5.625 0h12.75c.621 0 1.125.504 1.125 1.125v21.75c0 .621-.504 1.125-1.125 1.125H5.5a1.125 1.125 0 0 1-1.125-1.131L4.5 1.119ZM6.744 2.25l-.113 19.5H17.25V2.25h-1.93L14.195 4.5h-4.39L8.68 2.25H6.744Z" />
  </>,
  'Mobile'
);

export { Mobile };