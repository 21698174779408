import * as React from 'react';
import * as PropTypes from 'prop-types';
import { RectShape } from './RectShape';

const MediaGalleryPlaceholder = (props) => {
  const {
    className,
    style,
    color,
    rows,
    isMobile
  } = props;

  const classes = ['mediaGallery-block', className].filter((clss) => clss).join(' ');

  const GetThumbnails = () => {
    const range = new Array(5).fill(null);
    return range.map((item, index) => {
      return (
        <RectShape
          color={color}
          style={{
            minHeight: 88, width: 88, minWidth: 88, marginRight: 10, marginBottom: 10
          }}
          key={`rect-${index}`}
        />
      );
    });
  };

  const mobileStyles = {
    minHeight: 250,
    height: 250,
    width: 250,
    minWidth: 250,
    marginLeft: 10,
    marginBottom: 10
  };

  return (
    isMobile
      ? (
        <div className={classes} style={{ ...style }}>
          <RectShape
            color={color}
            style={mobileStyles}
            key="rect-0"
          />
          <RectShape
            color={color}
            style={mobileStyles}
            key="rect-1"
          />
        </div>
      )
      : (
        <div className={classes} style={{ ...style }}>
          <div className="thumb-nail_wrapper">
            <GetThumbnails />
          </div>

          <RectShape
            color={color}
            style={{
              minHeight: 350, height: 500, width: 500, minWidth: 350, marginLeft: 10, marginBottom: 10
            }}
          />
        </div>
      )
  );
};

MediaGalleryPlaceholder.propTypes = {
  rows: PropTypes
    .number.isRequired,
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  isMobile: PropTypes.bool
};

MediaGalleryPlaceholder.defaultProps = {
  style: {},
  className: '',
  isMobile: false
};

export { MediaGalleryPlaceholder };
