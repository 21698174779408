import * as React from 'react';
import { bool, string, number, object } from 'prop-types';
import { RectShape } from './RectShape';
import { TextBlock } from './TextBlock';

const ComponentPlaceholder = (props) => {
  const {
    className,
    color,
    rows,
    height,
    showLoadingAnimation
  } = props;

  const classes = showLoadingAnimation
    ? ['loading-placeholder', className].filter((clss) => clss).join(' ')
    : className;

  const componentStyle = {
    height,
    width: '100%',
    marginBottom: 10
  };

  return (
    <>
      <RectShape
        style={componentStyle}
        color={color}
        className={classes}
      />
      <TextBlock rows={rows} color={color} className={classes} />
    </>
  );
};

ComponentPlaceholder.propTypes = {
  rows: number,
  color: string.isRequired,
  height: number.isRequired,
  className: string,
  showLoadingAnimation: bool
};

ComponentPlaceholder.defaultProps = {
  className: '',
  rows: 0,
  showLoadingAnimation: false
};

export { ComponentPlaceholder };
