import React from 'react';
import PropTypes from 'prop-types';
import { StarFilled } from '@one-thd/sui-icons';
import classNames from 'classnames';
import { Typography } from '~/@one-thd/sui-atomic-components/dist/index';

export const Histogram = ({
  clickableFilters,
  onClick,
  onFilterChange,
  percentage,
  ratings,
}) => {

  const total = ratings.reduce((acc, rating) => acc + rating.count, 0);

  const getPercentageRating = (countOfStarReviews) => {
    return (parseInt(countOfStarReviews, 10) / parseInt(total, 10)) * 100;
  };

  const histogramBarClick = (ratingValue, count) => {
    if (clickableFilters && count > 0) {
      if (onFilterChange) {
        onFilterChange(ratingValue);
      }
      if (onClick) {
        onClick(ratingValue);
      }
    }
  };

  return (
    <div className="reviews-histogram" data-component="Histogram">
      {ratings
        .sort((i, n) => n.ratingValue - i.ratingValue)
        .map(({ count, ratingValue }) => {
          const percentageRating = getPercentageRating(count);
          const applyClickableClass = clickableFilters && count !== 0;
          const clickHandler = () => histogramBarClick(parseInt(ratingValue, 10), count);
          const countClass = classNames('sui-w-12', {
            'hover:sui-cursor-pointer hover:sui-underline sui-text-info': applyClickableClass
          });
          const btnWrapperClass = classNames('sui-h-7 sui-w-full sui-bg-subtle sui-rounded-md', {
            'hover:sui-cursor-pointer': applyClickableClass,
          });

          return (
            <div
              key={ratingValue}
              className="sui-flex sui-w-full sui-items-center sui-gap-x-2 sui-mt-2 first:sui-mt-0"
              onClick={clickHandler}
              role="presentation"
            >
              <span className="sui-w-3">
                <Typography variant="body-base">
                  {ratingValue}
                </Typography>
              </span>

              <span className="sui-flex sui-text-brand">
                <StarFilled size="small" color="current" />
              </span>

              <div className={btnWrapperClass}>
                <span
                  className="sui-h-7 sui-bg-brand sui-rounded-md sui-float-left"
                  style={{ width: `${percentageRating}%` }}
                />
              </div>

              <span className={countClass}>
                <Typography variant="body-base">
                  {percentage ? `${parseInt(percentageRating, 10)}%` : count}
                </Typography>
              </span>

            </div>
          );
        })}
    </div>
  );
};

Histogram.propTypes = {
  clickableFilters: PropTypes.bool,
  /** Function when bar is clicked */
  onClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  /** display percentage value instead of count */
  percentage: PropTypes.bool,
  /** Histogram bar label and number of star ratings to calculate % */
  ratings: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      ratingValue: PropTypes.number,
    })
  )
};

Histogram.defaultProps = {
  clickableFilters: false,
  onClick: null,
  onFilterChange: null,
  percentage: false,
  ratings: [],
};
