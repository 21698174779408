/* eslint-disable max-len */
export const phpAsset = {
  COUNTER_TOP: 'php_countertop.jpg',
  CABINET_MAKEOVER: 'php_cabinetMakeover.jpg',
  KITCHEN_DESIGN: 'php_kitchenDesign.jpg',
  BATHROOM: 'php_bathroomRemodeling.jpg',
  CARPET: 'php_carpetInstallation.jpg',
  HARDWOOD_FLOORING: 'php_hardwoodflooring.jpg',
  CUSTOM_BUILT: 'php_custom_built.jpg',
  BLIND: 'php_Blinds.jpg',
  CUSTOM_WINDOW: 'php_custom_window_treatment.jpg',
  WINDOW: 'php_windowInstallation.jpg',
  WATER_HEATER: 'php_waterheater.jpg',
  HEATING_COOLING: 'php_heating&cooling.jpg',
  HVAC: 'php_HVAC_Installation.jpg',
  FENCE: 'php_fence.jpg',
  Garage_DOOR: 'php_garageDoor.jpg',
  DEFAULT: 'php_professionalInstallation_services.jpg'

};