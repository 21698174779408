import {
  arrayOf as arrayOfType,
  params,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources';

export const QuickviewOverviewDrawerDataModel = {
  product: params({
    itemId: stringType().isRequired(),
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    details: shapeType({
      description: stringType(),
      descriptiveAttributes: arrayOfType(shapeType({
        value: stringType(),
      })),
    }),
  }),
};
