import React, { useEffect } from 'react';
import { node } from 'prop-types';
import { useHelmet, TitleTag, MetaTag, LinkTag } from '@thd-nucleus/thd-helmet';

const HD_URL = 'https://www.homedepot.com';

const metadataHandler = ({ props }) => {
  const tags = [];
  const { data, title = '', description = '' } = props;
  const dataKeys = data && Object.keys(data);

  if (title) {
    const propsTitle = (title.toLowerCase()).includes('the home depot') ? title : `${title} - The Home Depot`;
    tags.push(new TitleTag(propsTitle));
  }
  if (description) tags.push(new MetaTag({ name: 'description', content: description, id: 'prop-description' }));

  if (!dataKeys?.length) return tags;

  dataKeys.forEach((tag) => {
    const content = data[tag] || '';

    if (tag === '__typename') return;

    if (tag === 'title') {
      const pageTitle = content?.toLowerCase().includes('the home depot') ? content : `${content} - The Home Depot`;
      tags.push(new TitleTag(pageTitle));
    }

    if (tag === 'description') tags.push(new MetaTag({ name: 'description', content, id: 'data-description' }));
    if (tag === 'canonicalURL') {
      const url = `${HD_URL}${content}`;

      tags.push(new LinkTag({ rel: 'canonical', href: url, id: 'canonical' }));
      tags.push(new MetaTag({ property: 'og:url', content: url, id: 'og-url' }));
    }
    if (tag === 'siteName') tags.push(new MetaTag({ property: 'og:site_name', content, id: 'og-site-name' }));
    if (tag === 'ogDescription') {
      tags.push(new MetaTag({ property: 'og:description', content, id: 'og-description' }));
    }

    if (tag === 'ogType') tags.push(new MetaTag({ property: 'og:type', content, id: 'og-type' }));
    if (tag === 'ogTitle') tags.push(new MetaTag({ property: 'og:title', content, id: 'og-title' }));
    if (tag === 'facebookAdmins') tags.push(new MetaTag({ property: 'fb:admins', content, id: 'fb-admins' }));

    if (tag.includes('alternateUrl')) {
      const langSubString1 = tag.substr(12, 2).toLowerCase();
      const langSubString2 = tag.substr(14, 2).toLowerCase() ? `-${tag.substr(14, 2).toLowerCase()}` : '';
      const hrefLang = `${langSubString1}${langSubString2}`;

      tags.push(new LinkTag({ rel: 'alternate', href: content, hreflang: hrefLang, id: `alternate-${hrefLang}` }));
    }

    if (tag === 'robots') tags.push(new MetaTag({ name: 'robots', content, id: 'robots' }));
    if (tag === 'mobile') tags.push(new LinkTag({ rel: 'alternate', href: content, id: 'mobile' }));
  });

  return tags;
};

const Metadata = (props) => {
  const { children } = props;

  useHelmet('rootMetadataHandler', { props }, metadataHandler, [props]);
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('metadata.ready'); }, []);

  return (
    children || null
  );
};

Metadata.propTypes = {
  children: node,
};

Metadata.defaultProps = {
  children: null,
};

export { Metadata };
