/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-console */

let LOCAL_STORE_ID = 121;
export const setLocalStoreId = (storeId) => {
  try {
    if (typeof storeId === 'string') {
      LOCAL_STORE_ID = parseInt(storeId, 10);
    } else if (typeof storeId === 'number') {
      LOCAL_STORE_ID = storeId;
    }
  } catch (err) {
    // console.error(err);
  }
};

export const getLocalStoreId = () => {
  return LOCAL_STORE_ID;
};

const ffmTypeMap = {
  ShipToStore: 'boss',
  BOPIS: 'bopis',
  ShipToHome: 'sth',
  DeliverFromStore: 'express delivery',
  DirectDelivery: 'direct delivery',
  Appliance: 'appliance'
};

const pickupFfms = [
  'ShipToStore',
  'BOPIS',
  'boss',
  'bopis'
];

export const buildUpdateCartRequest = (updateItems = []) => {
  // backwards compatible for single item argument
  if (!Array.isArray(updateItems)) {
    updateItems = [updateItems];
  }
  let cartRequest = {};
  try {
    cartRequest = {
      localization: { primaryStoreId: LOCAL_STORE_ID },
      items: {}
    };

    updateItems.forEach((updateItem) => {
      const {
        lineItemId,
        itemId,
        quantity,
        fulfillmentMethod,
        fulfillmentLocation,
        storeId = '',
        addOns
      } = updateItem;

      const item = {
        id: lineItemId,
        itemId,
        type: ffmTypeMap[fulfillmentMethod] || fulfillmentMethod,
        quantity: quantity.toString(),
        location: fulfillmentLocation,
        store: storeId || LOCAL_STORE_ID?.toString() || '121'
      };
      if (addOns) {
        removeAddonDetails(addOns);
        item.addons = addOns;
      }

      if (pickupFfms.includes(fulfillmentMethod)) {
        cartRequest.items.pickup = cartRequest.items.pickup || [];
        cartRequest.items.pickup.push(item);
      } else {
        cartRequest.items.delivery = cartRequest.items.delivery || [];
        cartRequest.items.delivery.push(item);
      }
    });

  } catch (err) {
    console.error(err);
  }
  return cartRequest;
};

export const buildFulfillmentStoreUpdateRequest = (storeId) => {
  setLocalStoreId(storeId);
  const cartRequest = {
    localization: { primaryStoreId: LOCAL_STORE_ID },
    cartAttr: { updateFulfillmentStore: 'true' }
  };

  // {"cartRequest": {"localization": {"primaryStoreId": 208},
  // "cartAttr": {"updateFulfillmentStore": "true"}}}
  return cartRequest;
};

/**
 * Removes unwanted properties from the addon
 *
 * This is necessary because the updateCart schema does not allow other than the specified fields
 * @typedef {{configAttr: string, category: string, selected: boolean}} Addon
 * @param {Addon[]} addons
 */
export const removeAddonDetails = (addons) => {
  const validKeys = ['configAttr', 'category', 'selected'];
  // leaving as a for because nested loops is already On^2
  // so might as well use the more performant for instead of slower forEach
  let lgth = addons.length;
  for (let index = 0; index < lgth; index += 1) {
    const addon = addons[index];
    Object.keys(addon).forEach((key) => validKeys.includes(key) || delete addon[key]);
  }
};