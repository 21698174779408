import React from 'react';
import PropTypes from 'prop-types';
import {
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';

export const QuantityShipping = ({
  displayQuantity, shippingMessageStyle, shippingMessage, shippingThreshold, deliveryMessageToUse
}) => {

  let shippingType = 'delivery';
  let shipMessage = shippingMessage;
  const shippingCss = shippingMessageStyle || 'shipping__primary';

  const isStandardShipping = shippingMessage
    && typeof shippingMessage === 'string'
    && shippingMessage.toLowerCase() === 'standard';

  if (shippingThreshold) {
    shippingType = '';
    shipMessage = `free delivery with $${shippingThreshold} order`;
  }

  const formattedQty = formatSellableQuantity(displayQuantity);

  return (
    <div className={shippingCss}>
      <span className="shipping__success">{formattedQty} </span>
      <span className="shipping__primary">available</span>
      {(deliveryMessageToUse || !isStandardShipping) && (
        <span>
          <span className="shipping__primary"> for</span>
          {!deliveryMessageToUse
            ? (<span className={shippingCss}>{` ${shipMessage?.toLowerCase()} ${shippingType}`}</span>)
            : (
              <>
                {shippingMessageStyle === 'shipping__success'
                  && <span className={shippingCss}>{` ${shipMessage?.toLowerCase()}`}</span>}
                <span className={shippingCss}>{` ${deliveryMessageToUse?.toLowerCase()}`}</span>
              </>
            )}
        </span>
      )}
    </div>
  );
};

QuantityShipping.propTypes = {
  displayQuantity: PropTypes.number,
  shippingMessageStyle: PropTypes.string,
  shippingMessage: PropTypes.string,
  shippingThreshold: PropTypes.number,
  deliveryMessageToUse: PropTypes.string
};

QuantityShipping.defaultProps = {
  displayQuantity: 0,
  shippingMessageStyle: null,
  shippingMessage: null,
  shippingThreshold: 0,
  deliveryMessageToUse: null
};
