import React from 'react';
import PropTypes from 'prop-types';
import { ReactMobileOverlay } from './ReactMobileOverlay.component';
import { ReactTooltip } from './ReactTooltip.component';

const ReactTooltipManager = ({
  appendTo,
  channel,
  children,
  className,
  closeButton,
  content,
  disableTooltip,
  distance,
  flip,
  interactive,
  maxWidth,
  mobileHeader,
  onShow,
  placement,
  portal,
  showClose,
  skidding,
  visible,
  zIndex,
}) => {
  if (disableTooltip) {
    return children;
  }
  if (channel === 'mobile') {
    return (
      <ReactMobileOverlay content={content} portal={portal} mobileHeader={mobileHeader}>
        {children}
      </ReactMobileOverlay>
    );
  }
  return (
    <ReactTooltip
      appendTo={appendTo}
      className={className}
      closeButton={closeButton}
      content={content}
      distance={distance}
      flip={flip}
      interactive={interactive}
      maxWidth={maxWidth}
      onShow={onShow}
      placement={placement}
      showClose={showClose}
      visible={visible}
      zIndex={zIndex}
      skidding={skidding}
    >
      {children}
    </ReactTooltip>
  );
};

ReactTooltipManager.propTypes = {
  /**
   * [Desktop Only] The element to append the tooltip to.
   */
  appendTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   * Dictates whether a tooltip or an overlay is rendered
   */
  channel: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
  /**
   * Node that the tooltip wraps
   */
  children: PropTypes.node.isRequired,
  /**
   * [Desktop Only] Appends a class name to the parent of the "content" prop node
   */
  className: PropTypes.string,
  /**
   * [Desktop Only] Uses the CloseButtonTooltip.
   */
  closeButton: PropTypes.bool,
  /**
   * Content to display inside the tooltip
   */
  content: PropTypes.node.isRequired,
  /**
   * Prevents tooltip from showing
   */
  disableTooltip: PropTypes.bool,
  /**
   * [Desktop Only] Distance in px that the tooltip will appear from the reference element
   */
  distance: PropTypes.number,
  /**
   * [Desktop Only] Changes the placement of the tooltip if it will overflow the window
   */
  flip: PropTypes.bool,
  /**
   * [Desktop Only] Allows the tooltip to be hovered over and clicked on without hiding
   */
  interactive: PropTypes.bool,
  /**
   * [Desktop Only] Sets the maximum width for the tooltip
   */
  maxWidth: PropTypes.number,
  /**
   * Shows a header in the mobile overlay
   */
  mobileHeader: PropTypes.string,
  /**
   * [Desktop Only] Callback for when the tooltip beings to show
   */
  onShow: PropTypes.func,
  /**
   * [Desktop Only] The preferred placement of the tooltip.
   * Note that the flip prop can change this to the opposite placement if it has more space.
   */
  placement: PropTypes.oneOf(['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'auto', 'auto-start', 'auto-end']), // eslint-disable-line max-len
  /**
   * [Mobile Only] Appends the overlay to document.body
   */
  portal: PropTypes.bool,
  /**
   * [Desktop Only] Shows the "X" close button when used in conjunction with closeButton prop
   */
  showClose: PropTypes.bool,
  /**
   * [Desktop Only] Distance in px that the tooltip is offset from the reference element
   */
  skidding: PropTypes.number,
  /**
   * [Desktop Only] Shows tooltip by default when used in conjunction with closeButton prop
   */
  visible: PropTypes.bool,
  /**
   * [Desktop Only] Sets the z-index for the tooltip
   */
  zIndex: PropTypes.number,
};

ReactTooltipManager.defaultProps = {
  appendTo: () => document.body,
  className: '',
  closeButton: false,
  disableTooltip: false,
  distance: 25,
  flip: true,
  interactive: false,
  maxWidth: 500,
  mobileHeader: null,
  onShow: () => {},
  placement: 'top',
  portal: false,
  showClose: true,
  skidding: 0,
  visible: false,
  zIndex: 9999,
};

export { ReactTooltipManager };
