import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { func, oneOf, string, bool } from 'prop-types';
import styles from './close-button.module.scss';

const cx = classNames.bind(styles);

const CloseButton = (props) => {
  const {
    onClose,
    placement,
    header,
    positionedCloseButton
  } = props;
  const placementClasses = cx('overlay-close-button', {
    'overlay-close-button--right': placement === 'right',
    'overlay-close-button--left': placement === 'left',
    'overlay-close-button--visible': header
  });
  const positionedPlacementClasses = cx('overlay-close-button-positioned', {
    'overlay-close-button-positioned--right': placement === 'right',
    'overlay-close-button-positioned--left': placement === 'left'
  });
  const closeButtonIconClasses = cx('overlay-close-button__icon', {
    'overlay-close-button__icon--medium': header
  });
  const backButtonClasses = cx('overlay-back-button', {
    'overlay-back-button--hidden': header
  });

  const handleBackButtonClick = useCallback(() => {
    if (onClose) {
      onClose();
    }

    window.history.back();
  }, []);

  const getCloseButton = () => {
    return (positionedCloseButton ? (
      <span className={positionedPlacementClasses}>
        <button onClick={handleBackButtonClick} type="button">
          <img
            className={closeButtonIconClasses}
            src="https://assets.thdstatic.com/images/v1/close-symbol-orange.svg"
            alt="Close"
            width={16}
            height={16}
          />
        </button>
      </span>
    ) : (
      <div className={placementClasses}>
        <button onClick={handleBackButtonClick} type="button">
          <img
            className={closeButtonIconClasses}
            src="https://assets.thdstatic.com/images/v1/close-symbol-orange.svg"
            alt="Close"
            width={16}
            height={16}
          />
        </button>
      </div>
    ));
  };

  return (
    <>
      {getCloseButton()}
      <div className={backButtonClasses}>
        <button onClick={handleBackButtonClick} type="button">
          <img
            className={styles['overlay-back-button__icon']}
            src="https://assets.thdstatic.com/images/v1/back-arrow-orange.svg"
            alt="Back"
            width={16}
            height={16}
          />
          <span className={styles['overlay-back-button__text']}>Back</span>
        </button>
      </div>
    </>
  );
};

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
  onClose: func.isRequired,
  header: string,
  placement: oneOf(['right', 'left']),
  positionedCloseButton: bool
};

CloseButton.defaultProps = {
  header: null,
  placement: 'right',
  positionedCloseButton: false
};

export { CloseButton };
