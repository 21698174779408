/* eslint-disable max-len */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Drawer, DrawerHeader, DrawerBody, DrawerFooter, Typography,
} from '@one-thd/sui-atomic-components';
import { ConfirmedFilled } from '@one-thd/sui-icons';
import { ANALYTICS_SELECT_ACCOUNT, CARD_IMAGE, NON_CARD_HOLDER_VIEW } from '../utils/constants';
import { modalClickEvent, modalImpressionEvent } from '../utils/publisher';

const AccountSelectorDrawer = ({
  payAndManage,
  consumerLinked,
  proxLinked,
  crcLinked,
  closeDrawer,
  redirectToCiti,
}) => {

  let defaultSelectedCard = 'PLCN_HOMEDEPOT';
  if (consumerLinked) {
    defaultSelectedCard = !crcLinked ? 'PLCR_HOMEDEPOT' : 'PLNP_HOMEDEPOT';
  }
  const noAccountsLinked = !consumerLinked && !crcLinked && !proxLinked;

  useEffect(() => {
    if (!payAndManage) {
      modalImpressionEvent(NON_CARD_HOLDER_VIEW.MODAL_VIEW_LINK_ACCOUNT);
    }
  }, [payAndManage]);
  const [selectedCard, setSelectedCard] = useState(defaultSelectedCard);

  const switchCard = (event) => {
    setSelectedCard(event?.currentTarget?.id);
  };

  const redirectToCitiLocal = () => {
    if (!payAndManage) {
      modalClickEvent(ANALYTICS_SELECT_ACCOUNT[selectedCard]);
    }
    redirectToCiti(selectedCard);
  };

  const getSelectedClassName = () => {
    return 'sui-border-solid sui-border-2 sui-border-accent ';
  };

  const getPaddingClass = () => {
    return 'sui-py-4 sui-px-[0.3rem] sui-gap-4';
  };
  const getContainerClassName = () => {
    return 'sui-h-auto sui-w-full ' + getPaddingClass() + ' sui-flex sui-relative sui-flex-col sui-break-words sui-bg-primary sui-bg-clip-border sui-grow sui-items-center sui-justify-center sui-text-center';
  };
  const getUnSelectedClassName = () => {
    return 'sui-border-solid sui-border-2 sui-border-primary';
  };
  const getCardClassName = (cardType, disableCard) => {
    if (!disableCard && selectedCard === cardType) {
      return getContainerClassName() + ' ' + getSelectedClassName();
    }
    return getContainerClassName() + ' ' + getUnSelectedClassName();
  };

  const getTermsAndConditions = () => {
    let cardType = 'Home Depot Consumer Credit Card account';
    if (selectedCard === 'PLNP_HOMEDEPOT') {
      cardType = 'Commercial Account';
    } else if (selectedCard === 'PLCR_HOMEDEPOT') {
      cardType = 'Commercial Revolving Charge account';
    }
    return (
      <div className="sui-flex sui-flex-col sui-gap-1">
        <Typography variant="body-xs">Terms And Conditions</Typography>
        <Typography
          variant="body-xs"
          color="subtle"
          id="terms-conditions"
          data-testid="terms-conditions"
          data-automation-id="terms-conditions"
        >
          By clicking “Link This Account”, I acknowledge that I am linking my “My Account” login and
          password to my {cardType}. I understand this will allow anyone that has access to my “My
          Account” account information on homedepot.com complete access to my {cardType}. I understand
          that at any time, I can unlink these accounts by changing my settings in my “My Account”
          Account Profile on homedepot.com.
        </Typography>
      </div>
    );
  };

  const getButtonText = () => {
    if (consumerLinked && crcLinked && proxLinked) {
      return 'All Account Types Already Linked';
    }
    return payAndManage ? 'Pay & Manage Card' : 'Link This Account';
  };

  const allCardsLinked = consumerLinked && crcLinked && proxLinked;

  return (
    <Drawer open onClose={closeDrawer}>
      <DrawerHeader
        title={!payAndManage ? 'Select Card to Link' : 'Select Card to Pay & Manage'}
        onClose={closeDrawer}
      />
      <DrawerBody
        data-component="AccountSelectorDrawer"
        className="sui-flex sui-flex-col sui-gap-4 sui-p-4"
      >
        <div className="sui-flex-col sui-gap-4 sui-flex sui-pt-4">
          <div
            className={getCardClassName('PLCN_HOMEDEPOT', consumerLinked)}
            role="button"
            tabIndex={0}
            id="PLCN_HOMEDEPOT"
            data-testid="PLCN_HOMEDEPOT"
            data-automation-id="PLCN_HOMEDEPOT"
            onClick={!consumerLinked ? switchCard : () => {}}
            onKeyDown={!consumerLinked ? switchCard : () => {}}
          >
            {selectedCard === 'PLCN_HOMEDEPOT' && (
              <div className="sui-absolute sui-top-4 sui-right-4">
                <ConfirmedFilled color="brand" size="small" />
              </div>
            )}
            <div className="sui-m-auto sui-w-48">
              <img
                src={CARD_IMAGE.PLCN_HOMEDEPOT}
                alt="Apply for Home Depot Card"
                title="Apply for Home Depot Card"
                width="100%"
                height="auto"
                className={`${consumerLinked ? 'sui-opacity-50' : ''}`}
              />
            </div>
            <div className="sui-flex sui-flex-col sui-gap-2">
              <Typography variant="h5" weight="bold" color={consumerLinked ? 'inactive' : 'primary'}>
                Home Depot Consumer Card
              </Typography>
              {!noAccountsLinked && (consumerLinked ? <Typography color="inactive">Accounts Already Linked</Typography>
                : <Typography variant="body-base" color="subtle">No Account Linked</Typography>)}
            </div>
          </div>
          <div
            className={getCardClassName('PLCR_HOMEDEPOT', crcLinked)}
            role="button"
            tabIndex={0}
            data-testid="PLCR_HOMEDEPOT"
            data-automation-id="PLCR_HOMEDEPOT"
            id="PLCR_HOMEDEPOT"
            onClick={!crcLinked ? switchCard : () => {}}
            onKeyDown={!crcLinked ? switchCard : () => {}}
          >
            {selectedCard === 'PLCR_HOMEDEPOT' && (
              <div className="sui-absolute sui-top-4 sui-right-4">
                <ConfirmedFilled color="brand" size="small" />
              </div>
            )}
            <div className="sui-m-auto sui-w-48">
              <img
                src={CARD_IMAGE.PLCR_HOMEDEPOT}
                alt="Apply for Home Depot Card"
                title="Apply for Home Depot Card"
                width="100%"
                height="auto"
                className={`${crcLinked ? 'sui-opacity-50' : ''}`}
              />
            </div>
            <div className="sui-flex sui-flex-col sui-gap-2">
              <Typography variant="h5" weight="bold" color={crcLinked ? 'inactive' : 'primary'}>
                Pro Xtra Credit Card
              </Typography>
              {!noAccountsLinked && (crcLinked ? <Typography color="inactive">Accounts Already Linked</Typography>
                : <Typography variant="body-base" color="subtle">No Account Linked</Typography>)}
            </div>
          </div>
          <div
            className={getCardClassName('PLNP_HOMEDEPOT', proxLinked)}
            role="button"
            tabIndex={0}
            data-testid="PLNP_HOMEDEPOT"
            data-automation-id="PLNP_HOMEDEPOT"
            id="PLNP_HOMEDEPOT"
            onClick={!proxLinked ? switchCard : () => {}}
            onKeyDown={!proxLinked ? switchCard : () => {}}
          >
            {(selectedCard === 'PLNP_HOMEDEPOT' && !allCardsLinked) && (
              <div className="sui-absolute sui-top-4 sui-right-4">
                <ConfirmedFilled color="brand" size="small" />
              </div>
            )}
            <div className="sui-m-auto sui-w-48">
              <img
                src={CARD_IMAGE.PLNP_HOMEDEPOT}
                alt="Apply for Home Depot Card"
                title="Apply for Home Depot Card"
                width="100%"
                height="auto"
                className={`${proxLinked ? 'sui-opacity-50' : ''}`}
              />
            </div>
            <div className="sui-flex sui-flex-col sui-gap-2">
              <Typography variant="h5" weight="bold" color={proxLinked ? 'inactive' : 'primary'}>
                Commercial Account
              </Typography>
              {!noAccountsLinked && (proxLinked ? <Typography color="inactive">Accounts Already Linked</Typography>
                : <Typography variant="body-base" color="subtle">No Account Linked</Typography>)}
            </div>
          </div>
        </div>
        {!payAndManage && getTermsAndConditions()}
      </DrawerBody>
      <DrawerFooter position="end">
        <div className="sui-flex sui-gap-4 sui-flex-row sui-w-auto sui-flex-col-reverse sui-w-full">
          <div className="sui-shrink-0">
            <Button
              variant="secondary"
              disabled={false}
              fullWidth
              onClick={closeDrawer}
              id="drawer-cancel"
              data-testId="drawer-cancel"
              data-automation-id="drawer-cancel"
            >
              Cancel
            </Button>
          </div>
          <div className="sui-shrink-0">
            <Button
              variant="primary"
              id="apply-button"
              data-testid="apply-button"
              data-automation-id="apply-button"
              onClick={redirectToCitiLocal}
              fullWidth
              disabled={allCardsLinked}
            >
              {getButtonText()}
            </Button>
          </div>
        </div>
      </DrawerFooter>
    </Drawer>
  );
};

AccountSelectorDrawer.propTypes = {
  payAndManage: PropTypes.bool,
  consumerLinked: PropTypes.bool,
  proxLinked: PropTypes.bool,
  crcLinked: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  redirectToCiti: PropTypes.func.isRequired,
};

AccountSelectorDrawer.defaultProps = {
  payAndManage: false,
  consumerLinked: false,
  proxLinked: false,
  crcLinked: false,
};

export default AccountSelectorDrawer;
