/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect } from 'react';
import { ExperienceContext, useConfigService, useStore } from '@thd-nucleus/experience-context';
import { bool, string, object } from 'prop-types';
import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType,
  useDataModel, useLazyDataModel, params, client, extend
} from '@thd-nucleus/data-sources';
import {
  dataModel as deliveryDataModel,
  DeliveryOptions
} from '@thd-olt-component-react/delivery-options';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { FulfillmentPodPlaceholder } from './FulfillmentPodPlaceholder';
import { getShippingTemplate } from '../helpers/shipping-helper';
import {
  isBODFS,
  isBrioProduct,
  isCustomKitchenCabinetProduct,
  isPodShippingEqual,
  isSTH
} from '../helpers/pod-fulfillment-utils';
import { EmptyShippingMessage } from '../partials/shipping/EmptyShippingMessage';
import './fulfillment-pod-shipping.scss';
import {
  isMajorApplianceProductType,
  getDeliveryZip,
  isExchangeCustomerFallback
} from '../../components/helper/utils';
import { renderDeliveryTemplates } from '../templates/deliveryTemplate';

export const FulfillmentPodShipping = React.memo(({
  itemId, storeId, deliveryZip, useCustomTemplate, directData, hasSameDayFilter
}) => {
  const { storeId: useStoreId } = useStore();
  const { customer } = useContext(ExperienceContext);
  const [product, setProduct] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const enableApplianceDeliveryCharge = useConfigService('enableApplianceDeliveryCharge');
  const enableFreeDeliveryForExchange = useConfigService('enableFreeDeliveryForExchange');
  const enableDoubleDeliveryMessage = useConfigService('enableDoubleDeliveryMessage') || false;
  let { isExchangeCustomer } = useThdCustomer() || {};
  if (typeof isExchangeCustomer === 'undefined') isExchangeCustomer = isExchangeCustomerFallback();

  const opts = {
    variables: {
      itemId,
      storeId: storeId || useStoreId,
      zipCode: deliveryZip
    },
    skip: !itemId || !!directData,
    ssr: false
  };

  const checkAvailabilityOptions = {
    ssr: false,
    fetchPolicy: 'no-cache'
  };

  const { data, loading, error } = useDataModel('product', opts);
  const { product: productData } = data || { product: directData } || {};
  const [
    fetchCheckAvailability,
    { data: checkAvailabilityData, loading: isLoadingAvailability }
  ] = useLazyDataModel('checkAvailability', checkAvailabilityOptions);

  useEffect(() => {
    if (!checkAvailabilityData) return;

    const fulfillmentOptions = checkAvailabilityData?.checkAvailability?.fulfillmentOptions;
    const services = fulfillmentOptions?.[0]?.services;
    const locations = services?.[0]?.locations;

    let earliestAvailabilityDate = null;
    if (services?.[0]?.earliestAvailabilityDate) {
      earliestAvailabilityDate = new Date(services?.[0]?.earliestAvailabilityDate + ' ' + new Date().getFullYear());
    }

    const response = {
      DeliveryAvailabilityResponse: {
        deliveryAvailability: {
          zipCode: getDeliveryZip(),
          primaryStrNbr: locations?.[0]?.locationId,
          availability: services?.[0]?.deliveryAvailabilityDetail,
          earliestAvailabilityDate
        }
      }
    };

    setAvailabilityData(response);

  }, [checkAvailabilityData]);

  useEffect(() => {
    if (productData) {
      setProduct(productData);
      setAvailabilityData(null);
    }
  }, [productData]);

  const isHDQC = product?.info?.productSubType?.name === 'HDQC';
  const { scheduleAMeasure = false } = product?.installServices || {};
  const isScheduleAConsultationEligible = isCustomKitchenCabinetProduct(product);
  const isRequestAQuoteEligible = isBrioProduct(product);
  const { bundleFlag = false } = product || {};
  if (scheduleAMeasure
    || isScheduleAConsultationEligible
    || isRequestAQuoteEligible
    || bundleFlag
    || isHDQC) {
    return null;
  }

  const checkAvailabilityButton = (
    <button
      type="button"
      className="check-availability-button u__default-link"
      role="link"
      onClick={() => {
        fetchCheckAvailability({
          variables: {
            itemIds: [itemId],
            zipCode: getDeliveryZip(),
            localStoreId: storeId || useStoreId
          }
        });
      }}
    >
      Check Earliest Availability Date
    </button>
  );

  const ShippingComponent = getShippingTemplate(
    {
      availabilityData,
      product,
      customer,
      checkAvailabilityButton,
      customRender: useCustomTemplate ? renderDeliveryTemplates : null,
      enableApplianceDeliveryCharge,
      enableFreeDeliveryForExchange,
      isExchangeCustomer
    }
  );
  if (!ShippingComponent) { return EmptyShippingMessage; }

  let BodfsComponent = null;
  if (enableDoubleDeliveryMessage && hasSameDayFilter && isSTH(product) && isBODFS(product)) {
    BodfsComponent = getShippingTemplate(
      {
        availabilityData,
        product,
        customer,
        checkAvailabilityButton,
        customRender: useCustomTemplate ? renderDeliveryTemplates : null,
        enableApplianceDeliveryCharge,
        enableFreeDeliveryForExchange,
        isExchangeCustomer,
        hasSameDayFilter
      }
    );
  }

  if (loading || (
    typeof (data) === 'undefined' && !error && !product
  )) return <FulfillmentPodPlaceholder />;
  if (!product) return null;
  if (isLoadingAvailability) {
    return (
      <div className="loading-spinner" data-component="FulfillmentPodLoading">
        <span className="segment-spinner" />
      </div>
    );
  }

  // FEATURE SWITCH OVERRIDE
  // Show check-availbility button in place of fulfillment message
  // Hide on this page to avoid duplicate buttons
  const applianceFlatChargeApplies = enableApplianceDeliveryCharge && !isExchangeCustomer;

  const isMajorAppliance = isMajorApplianceProductType(data?.product?.identifiers?.productType);
  const showCheckAvailabilityButton = isMajorAppliance && !availabilityData && !applianceFlatChargeApplies;
  return (
    <span data-component="FulfillmentPodShipping">
      <ShippingComponent
        availabilityData={availabilityData}
        product={product}
        checkAvailabilityButton={checkAvailabilityButton}
        enableApplianceDeliveryCharge={enableApplianceDeliveryCharge}
        enableFreeDeliveryForExchange={enableFreeDeliveryForExchange}
        isExchangeCustomer={isExchangeCustomer}
      />
      {BodfsComponent && (
        <BodfsComponent
          availabilityData={availabilityData}
          product={product}
          checkAvailabilityButton={checkAvailabilityButton}
          enableApplianceDeliveryCharge={enableApplianceDeliveryCharge}
          enableFreeDeliveryForExchange={enableFreeDeliveryForExchange}
          isExchangeCustomer={isExchangeCustomer}
        />
      )}
      {showCheckAvailabilityButton && (
        checkAvailabilityButton
      )}
    </span>
  );

}, isPodShippingEqual);

FulfillmentPodShipping.propTypes = {
  itemId: string.isRequired,
  storeId: string,
  useCustomTemplate: bool,
  deliveryZip: string,
  // eslint-disable-next-line react/forbid-prop-types
  directData: object,
  hasSameDayFilter: bool
};

FulfillmentPodShipping.defaultProps = {
  useCustomTemplate: false,
  storeId: undefined,
  deliveryZip: undefined,
  directData: null,
  hasSameDayFilter: false
};

const installServices = params({ storeId: stringType(), zipCode: stringType() }).shape({
  scheduleAMeasure: boolType()
});
if (installServices.skip) {
  installServices.skip('skipInstallServices', true);
}

const Service = shapeType({
  deliveryTimeline: stringType(),
  deliveryDates: shapeType({
    startDate: stringType(),
    endDate: stringType()
  }),
  deliveryCharge: stringType(),
  dynamicEta: shapeType({
    hours: stringType(),
    minutes: stringType()
  }),
  hasFreeShipping: boolType(),
  deliveryMessage: stringType(),
  freeDeliveryThreshold: numberType({ float: true }),
  locations: arrayOfType(shapeType({
    curbsidePickupFlag: boolType(),
    isBuyInStoreCheckNearBy: boolType(),
    distance: numberType({ float: true }),
    inventory: shapeType({
      isOutOfStock: boolType(),
      isInStock: boolType(),
      isLimitedQuantity: boolType(),
      isUnavailable: boolType(),
      quantity: numberType(),
      maxAllowedBopisQty: numberType(),
      minAllowedBopisQty: numberType()
    }),
    isAnchor: boolType(),
    locationId: stringType(),
    state: stringType(),
    storeName: stringType(),
    storePhone: stringType(),
    type: stringType()
  })),
  type: stringType(),
  totalCharge: numberType({ float: true })
});

FulfillmentPodShipping.dataModel = extend({
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  })
    .shape({
      itemId: stringType(),
      bundleFlag: boolType(),
      dataSources: stringType(),
      identifiers: shapeType({
        productType: stringType()
      }),
      info: shapeType({
        classNumber: stringType(),
        hasSubscription: boolType(),
        isLiveGoodsProduct: boolType(),
        productDepartment: stringType(),
        productSubType: shapeType({
          name: stringType()
        }),
        globalCustomConfigurator: shapeType({
          customExperience: stringType()
        }),
        isBrioSku: boolType(),
        isCustomKitchenCabinet: boolType(),
        isGenericProduct: boolType()
      }),
      pricing: params({ storeId: stringType() })
        .shape({
          original: numberType({ float: true }),
          value: numberType({ float: true })
        }),
      availabilityType: shapeType({
        buyable: boolType(),
        discontinued: boolType(),
        status: boolType(),
        type: stringType()
      }),
      fulfillment: client(params({
        storeId: stringType(), zipCode: stringType(),
      }).shape({
        anchorStoreStatus: boolType(),
        anchorStoreStatusType: stringType(),
        backordered: boolType(),
        backorderedShipDate: stringType(),
        bossExcludedShipStates: stringType(),
        excludedShipStates: stringType(),
        seasonStatusEligible: boolType(),
        fulfillmentOptions: arrayOfType(shapeType({
          type: stringType(),
          fulfillable: boolType(),
          services: arrayOfType(Service)
        })),
        onlineStoreStatus: boolType(),
        onlineStoreStatusType: stringType()
      })),
      installServices
    }),
  checkAvailability: params({
    itemIds: arrayOfType(stringType().isRequired()).isRequired(),
    zipCode: stringType().isRequired(),
    localStoreId: stringType().isRequired()
  })
    .shape({
      fulfillmentOptions: arrayOfType(shapeType({
        fulfillable: boolType(),
        services: arrayOfType(
          shapeType({
            type: stringType(),
            isDefault: boolType(),
            earliestAvailabilityDate: stringType(),
            locations: arrayOfType(
              shapeType({
                locationId: stringType()
              })
            ),
            deliveryAvailabilityDetail: arrayOfType(shapeType({
              status: stringType(),
              itemId: stringType()
            }))
          })
        )
      }))
    }),
  shipping: deliveryDataModel.shipping
}, DeliveryOptions);

FulfillmentPodShipping.displayName = 'FilfillmentPodShipping';
