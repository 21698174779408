import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * A container for interactive elements, like `Button`s, usually located at the bottom of `Card`
 *
 * Related components: [Card](#card), [Button](#button)
 *
 * Usage:
 *
 * ```jsx
 * import { CardActions } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardActions = React.forwardRef((props, ref) => {

  const {
    padding = false,
    position = 'start',
    ...other
  } = props;

  const classes = classNames('sui-flex sui-align-center sui-grow-0 sui-shrink-0 sui-basis-auto', {
    'sui-justify-start': position === 'start',
    'sui-justify-end': position === 'end',
    'sui-justify-center': position === 'center',
    'sui-p-4': padding
  });

  return (
    <div
      className={classes}
      ref={ref}
      {...other}
    />
  );
});

CardActions.displayName = 'CardActions';

CardActions.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The position of the content
   * @default 'start'
   */
  position: PropTypes.oneOf(['start', 'end', 'center']),
  /**
   * If `true`, padding is added.
   * @default false
   */
  padding: PropTypes.bool
};

CardActions.defaultProps = {};

export { CardActions };