import React from 'react';
import { string } from 'prop-types';

import { CardCtaLoader } from './subcomponents/CardCtaLoader';
import { PodCtaLoader } from './subcomponents/PodCtaLoader';

export const PromotionProductsLoader = ({ experienceTag, subExperienceTag, type }) => {
  const isCard = type === 'card';

  return isCard ? (
    <CardCtaLoader />
  ) : (
    <PodCtaLoader experienceTag={experienceTag} subExperienceTag={subExperienceTag} />
  );
};

PromotionProductsLoader.displayName = 'PromotionProductsLoader';

PromotionProductsLoader.propTypes = {
  experienceTag: string,
  subExperienceTag: string,
  type: string.isRequired,
};

PromotionProductsLoader.defaultProps = {
  experienceTag: null,
  subExperienceTag: null,
};
