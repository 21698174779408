import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, shape, string } from 'prop-types';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { ReorderRecommendations } from '@thd-olt-component-react/thd-recs-containers';
import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function BuyItAgain({
  REQUEST_KEY, id, brandTitleMaxLine, slidesPer, storefront
}) {
  const { channel } = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);

  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant
      === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <ErrorBoundary name="reorder-recognized-homepage">
      <QueryProvider
        cacheKey="reorder-recognized-homepage"
        defaultVariables={newDefaultVariables}
      >
        <Card
          id={`buy-it-again-php-${id}`}
          className="sui-h-full"
          CardContentProps={{ className: 'sui-flex sui-flex-col sui-w-full sui-h-full sui-gap-4 sui-p-4' }}

        >
          <CardTitle
            header={
              <Typography variant="h3">Buy It Again</Typography>
            }
          />
          <ReorderRecommendations
            brandTitleMaxLine={brandTitleMaxLine}
            slidesPer={slidesPer}
            hideTitle
            noATCFulfillment
            requestKey={REQUEST_KEY}
            showFallback
            showLoading
            entryId={id}
            storefrontSvocId={storefront?.SVOCID}
          />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

BuyItAgain.propTypes = {
  REQUEST_KEY: string,
  id: string,
  brandTitleMaxLine: number,
  slidesPer: number,
  storefront: shape({
    SVOCID: string,
  }),
};

BuyItAgain.defaultProps = {
  REQUEST_KEY: undefined,
  id: undefined,
  brandTitleMaxLine: undefined,
  slidesPer: undefined,
  storefront: null,
};
