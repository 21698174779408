import thdCustomer from '@thd-olt-global/thd-customer';
import { v4 as uuidv4 } from 'uuid';
import { newRelic } from './new-relic';

function generateCustAcctHeaders(isMobile, urlTmxId) {
  const windowTmxId = window?.THD_ACCOUNT_LIB?.TMX ? window.THD_ACCOUNT_LIB.TMX.getTMXsessionId() : '';
  const tmxId = urlTmxId || windowTmxId;
  return {
    accept: 'application/json',
    'Content-type': 'application/json',
    channelId: isMobile ? '2' : '1',
    Authorization: window.encodeURIComponent(thdCustomer.token),
    TMXProfileID: tmxId
  };
}

const generateFetchHeaders = (isMobile, requestBody, urlTmxId) => {
  let body = JSON.stringify(requestBody) || null;
  let method = requestBody ? 'POST' : 'GET';

  return {
    headers: {
      ...generateCustAcctHeaders(isMobile, urlTmxId),
      'cache-control': 'no-cache',
      'cust-acct-client-delay-token-validation': '1000000',
      'x-trace-uuid': uuidv4()
    },
    body,
    method,
    credentials: 'include'
  };
};

export const fetchPlccData = (channel, storeId, urlTmxId) => {
  const generateFetchUrl = () => {
    // eslint-disable-next-line max-len
    return `${window.location.origin}/b2b/user/account/${thdCustomer.userID}/customer/${thdCustomer.svocID}/sso/account-snapshot`;
  };

  const channelType = (channel === 'mobile') ? 'mobileweb' : 'desktop';

  const requestBody = {
    SnapshotRequest: {
      source: 'PHP',
      storeId,
      deviceType: channelType
    }
  };

  const isMobile = channel === 'mobile';

  return fetch(generateFetchUrl(), generateFetchHeaders(isMobile, requestBody, urlTmxId));
};

export const getSsoUrl = (isMobile, siteId) => {
  const generateFetchUrl = () => {
    // eslint-disable-next-line max-len
    return window.location.origin
      + '/b2b/user/account/'
      + thdCustomer.userID
      + '/customer/'
      + thdCustomer.svocID
      + `/sso/getURL?siteId=${siteId}`;
  };

  return fetch(generateFetchUrl(), generateFetchHeaders(isMobile));
};

export const fetchDeclinePrequalOffer = (invitationNo, isMobile) => {
  const generateFetchUrl = () => {
    return window.location.origin
      + '/b2b/user/account/'
      + thdCustomer.userID
      + `/customer/${thdCustomer.svocID}/citi/prequalDeclineOffer`;
  };

  const requestBody = {
    PrequalDeclineRequest: {
      userId: thdCustomer.userID,
      svocId: thdCustomer.svocID,
      invitationNo,
    }
  };

  newRelic.logPlccApiCall(generateFetchUrl());
  return fetch(generateFetchUrl(), generateFetchHeaders(isMobile, requestBody));
};

export const getPrequalEligibility = (channel, userDetails) => {
  const generateFetchUrl = () => {
    return window.location.origin
      + '/b2b/user/account/'
      + thdCustomer.userID
      + `/customer/${thdCustomer.svocID}/citi/checkPrequalOffer`;
  };
  const isMobile = channel === 'mobile';
  const requestBody = {
    CheckPrequalRequest: {
      userId: thdCustomer.userID || '',
      svocId: thdCustomer.svocID,
      userDetails
    }
  };

  return fetch(generateFetchUrl(), generateFetchHeaders(isMobile, requestBody));
};