export const OPTION_TILES_CONSTANTS = {
  TILE_LINK: 'View Details',
  STYLES: {
    ICON: {
      MW: { LOCKED: '56', UNLOCKED: '48', }, DTW: { LOCKED: '72', UNLOCKED: '72', },
    },
    TITLE: {
      MW: { LOCKED: 'h4', UNLOCKED: 'body-base', }, DTW: { LOCKED: 'h3', UNLOCKED: 'h3', },
    },
    CONTAINER: {
      MW: { LOCKED: 'sui-gap-2 sui-p-4', UNLOCKED: 'sui-gap-2 sui-p-4 sui-pl-3', },
      DTW: { LOCKED: 'sui-gap-3 sui-p-4', UNLOCKED: 'sui-gap-3 sui-p-4', },
    },
    LINK: {
      MW: { LOCKED: '92', UNLOCKED: '124', }, DTW: { LOCKED: '108', UNLOCKED: '156', },
    }
  }
};

export const DRAWER_ANALYTICS = {
  perkSelectionDrawer: {
    locked: 'perk options',
    unlocked: 'new perk unlocked',
    viewDetailsButtonLocked: 'perk options overlay: view details link',
    viewDetailsButtonUnlocked: 'new perk unlocked overlay: view details link',
    choosePerkButtonFromSelection:
    (perkTitle) => `new perk unlocked overlay: ${perkTitle.toLowerCase()}: choose this perk button`,
  },
  perkDetailsDrawer: {
    viewDetailsLockedFromRH: (perkTitle) => `perk details overlay: locked: ${perkTitle.toLowerCase()}`,
    viewDetailsUnlockedFromRH: (perkTitle) => `perk details overlay: unlocked: ${perkTitle.toLowerCase()}`,
    choosePerkButtonFromDetails:
      (perkTitle) => `perk details overlay: ${perkTitle.toLowerCase()}: choose this perk button`,
  },
};

export const HEADER_TEXT = {
  OFFER: 'Offer Details',
  LOCKED_PERK_SELECTION: 'Perk Options',
  UNLOCKED_PERK_SELECTION: 'New Perk Unlocked',
  PERK_DETAILS: 'Pro Xtra Perk Details',
};

export const TITLE_TEXT = {
  LOCKED_PERK_SELECTION: (tierThreshold) => `$${tierThreshold} Spend Milestone`,
  PERK_OPTIONS: 'Congratulations!',
};

export const SUBTITLE_TEXT = {
  OFFER: 'Thank you for your loyalty',
  ACTIVE_PERK: 'Great choice! Enjoy your new Perk',
  UNCLAIMED_PERK: (tierThreshold) => `You\u2019ve reached $${tierThreshold} in spend`,
};

export const FOOTER_BUTTON_TEXT = {
  SELECTION: 'Choose This Perk',
  PXD: 'Go to Checkout',
};

export const BARCODE_TEXT = {
  barcodeInstructions: 'Scan Barcode at Checkout',
  inStoreInstructions: 'Show Code to In-Store Associate',
  onlineInstructions: 'Copy Code for Online Purchase',
  copyCodeButtonText: 'Copy Code',
  copyCodeButtonTextClicked: 'Copied to Clipboard',
  errorBarcodeMessage: 'An error has occured. Please tap refresh to view this code.',
  errorBarcodeRefresh: 'Refresh',
  inStoreAppDisclaimer:
    (rewardType) => `Don't forget to access your ${rewardType} using The Home Depot app `
    + 'or at homedepot.com on a mobile browser.',
};

export const SELECTION_CONSTANTS = {
  HEADER: {
    LOCKED: 'Perk Options',
    UNLOCKED: 'New Perk Unlocked',
  },
  CONTENT_HEADER: {
    LOCKED: 'Perk options once unlocked',
    UNLOCKED: 'Choose one of these Perks',
  },
  OFFER_ACTIVATION_ERROR: {
    HEADER: 'Offer Details',
    TITLE: 'Offer Activation Error',
    ICON_PATH: 'rewards-locked-dark.svg',
    MSG: 'An error occurred. Please refresh to view your Offer',
  },
  ERROR: {
    HEADER: 'Perk Options',
    TITLE: 'Spend Milestone',
    ICON_PATH: 'rewards-locked-dark.svg',
    MSG: 'An error occurred. Please refresh to view your Perk options.',
  },
};
