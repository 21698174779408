import React from 'react';
import PropTypes from 'prop-types';
import TableHeadContext from '../private/components/table/TableHeadContext';

/**
 * Contains a set of rows defining the head of the columns of the [`Table`](#table) component.
 *
 * Use `TableHead` with the following sub-components:
 *
 * [TableRow](#tablerow), [TableCell](#tablecell), [TableSortLabel](#tablesortlabel)
 *
 * All `TableCell`s provided as children will be rendered as cell headers (`th`) elements by default.
 *
 * Usage:
 *
 * ```jsx
 * import { TableHead } from '@one-thd/sui-atomic-components';
 * ```
 */
const TableHead = React.forwardRef((props, ref) => {
  const {
    children,
    density,
    ...other
  } = props;

  const tableHeadContextValue = {
    variant: 'head',
    density
  };

  return (
    <TableHeadContext.Provider value={tableHeadContextValue}>
      <thead
        className="sui-table-head"
        ref={ref}
        {...other}
      >
        {children}
      </thead>
    </TableHeadContext.Provider>
  );
});

TableHead.displayName = 'TableHead';

TableHead.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Set the row density.
   * @default 'normal'
   */
  density: PropTypes.oneOf(['tight', 'normal', 'loose']),

};

export { TableHead };