import { params, string, shape } from '@thd-nucleus/data-sources';

export const dataModel = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      specialOrderSku: string(),
      storeSkuNumber: string()
    })
  }),
};