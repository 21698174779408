import { useQuery as useApolloQuery } from '@apollo/client';
import { useRef } from 'react';

export const useQuery = (query, opts) => {
  const dataRef = useRef(null);
  // https://github.com/apollographql/react-apollo/issue  s/3492
  // if (opts.skip) {
  //   opts.fetchPolicy = 'cache-only';// eslint-disable-line
  // }

  const resp = useApolloQuery(query, opts);

  if (resp.data) {
    dataRef.current = resp.data;
  }

  if (resp.loading) {
    return {
      ...resp,
      data: dataRef.current
    };
  }

  return resp;
};
