import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Link, Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { ArticleGuides as ArticleGuidesComponent } from '@thd-olt-component-react/thd-recs-containers';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { getSvocId } from '../../utils';

export function ArticleGuides({ REQUEST_KEY, id, guestUserId, slidesPer }) {
  const { channel } = useContext(ExperienceContext);

  const customerID = getSvocId() || guestUserId;

  return (
    <ErrorBoundary name="article-guides">
      <QueryProvider cacheKey="article-guides">
        <Card
          id={`article-guides-php-${id}`}
          className="sui-h-full"
        >
          <CardTitle
            header={(
              <Typography variant="h3" weight="regular">
                DIY Guides For Your Projects
              </Typography>
            )}
            action={<Link href="/c/diy_projects_and_ideas">View All</Link>}
          />
          <ArticleGuidesComponent
            hideTitle
            requestKey={REQUEST_KEY}
            showLoading
            customerID={customerID}
            slidesPer={slidesPer}
            hideControls={channel === 'mobile'}
          />
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

ArticleGuides.propTypes = {
  REQUEST_KEY: string,
  guestUserId: string,
  slidesPer: number,
  id: string,
};

ArticleGuides.defaultProps = {
  REQUEST_KEY: undefined,
  guestUserId: undefined,
  slidesPer: undefined,
  id: undefined,
};
