import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Store = createSvgIcon(
  <>
    <path d="M15.75 9v2.25h-7.5V9h7.5Z" />
    <path d="M3.33 4.08C5.449 1.96 8.752.75 12 .75s6.551 1.21 8.67 3.33l.33.329V6.75h3V22.5H0V6.75h3V4.409l.33-.33Zm1.92 1.282V9h-3v11.25H9V15h6v5.25h6.75V9h-3V5.362C17.1 3.91 14.574 3 12 3s-5.1.91-6.75 2.362Z" />
  </>,
  'Store'
);

export { Store };