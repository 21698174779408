import React, { useContext } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { Button } from '@one-thd/sui-atomic-components';
import { getHostCURL } from '../../../common/utils/cardUtils';
import * as Constants from '../../../core/Constants';
import { analyticsStash } from '../../../common/utils/AnalyticsUtils';

const analyticsMessage = Constants.analytics.ANALYTICS_MESSAGE;
const analyticsLinks = Constants.analytics.ANALYTICS_LINKS;

export const WelcomeCardFooter = () => {
  const { channel } = useContext(ExperienceContext);
  const isNewRHEnabled = useConfigService('ENABLE_REWARDS_HUB_PAGE');
  const rhLink = isNewRHEnabled === 1 ? '/myaccount/proXtraRewards' : '/myaccount/proxtraprogramdetails';
  const learnMoreLink = getHostCURL(rhLink);
  const linkText = 'View All Rewards & Benefits';
  const isMobile = channel === 'mobile';
  const variant = !isMobile ? 'secondary' : 'text';
  const mobileClass = isMobile ? 'sui-w-full sui-flex sui-justify-center' : '';

  return (
    <div className={mobileClass}>
      <Button
        variant={variant}
        href={learnMoreLink}
        fullWidth
        onClick={() => analyticsStash('welcomeCard', analyticsMessage.BOTTOM_LINK, analyticsLinks.VIEW_BENEFITS)}
      >
        {linkText}
      </Button>
    </div>
  );
};
