import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { getMarks, generateKey, getChildrens } from './utils';
import { RenderHyperlink } from './RenderHyperlink';

const getTableNodeValue = (item) => {
  if (item) {
    if (getMarks(item.marks).isItalic) {
      if (getMarks(item.marks).isSub) {
        return <sub><i>{item.value}</i></sub>;
      }
      if (getMarks(item.marks).isSup) {
        return <sup><i>{item.value}</i></sup>;
      }
      return <i>{item.value}</i>;
    }
    if (getMarks(item.marks).isSub) {
      return <sub>{item.value}</sub>;
    }
    if (getMarks(item.marks).isSup) {
      return <sup>{item.value}</sup>;
    }
    return item.value;
  }
  return null;
};

const RenderTableNodes = ({ node }) => {
  const content = node?.content;
  return (
    <mark className="sui-flex sui-flex-wrap">
      {content.map((item, i) => {
        if (item.nodeType === 'hyperlink') {
          // contentful requires uri to nodeType hyperlink
          const uriString = item.data.uri.length;
          return (
            <Typography
              paragraph
              key={`${i}-body-base-table-hyperlink-${item.data.uri.substring(uriString - 11)}`}
              component="mark"
              variant="body-base"
            >
              <RenderHyperlink
                node={item}
              >
                {getChildrens(item)}
              </RenderHyperlink>
            </Typography>
          );
        }
        if (!item.nodeType.includes('embedded')) {
          return (
            <mark
              key={generateKey(item.value, i, 'body-base-table-mark')}
              className={getTableNodeValue(item) !== '' ? 'sui-mr-1' : ''}
              style={getMarks(item.marks).isCode ? { backgroundColor: '#E1E2E0' } : null}
            >
              <Typography
                paragraph
                key={generateKey(item.value, i, 'body-base-table')}
                component="mark"
                variant="body-base"
                weight={getMarks(item.marks).isBold ? 'bold' : 'regular'}
                {...(getMarks(item.marks).isUnderline ? { decoration: 'underline' } : {})} // eslint-disable-line
              >
                {getTableNodeValue(item)}
                {getTableNodeValue(item) === '' && <>&nbsp;</>}
              </Typography>
            </mark>
          );
        }
        return null;
      })}
    </mark>
  );
};

RenderTableNodes.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
    nodeType: PropTypes.string,
  }).isRequired
};

export { RenderTableNodes };
