import React from 'react';
import { string, shape, func, number } from 'prop-types';
import {
  Typography,
  Link,
  QuantityPicker
} from '@one-thd/sui-atomic-components';
import { Image } from './Image';
import { Price } from './Price';

export const Product = ({
  identifiers,
  itemId,
  product,
  updatedQuantity,
  setUpdatedQuantity,
  pricing
}) => {
  const { value: productPrice } = pricing || {};

  const totalPrice = (updatedQuantity * productPrice).toFixed(2);

  const onQuantityChange = (count) => {
    setUpdatedQuantity(count);
  };

  return (
    <div
      data-automation-id={`atc-product-${itemId}`}
      className="sui-grid sui-grid-cols-12 sui-mx-4 sui-pt-4"
    >
      <div
        data-automation-id={`atc-product-image-${itemId}`}
        className="sui-col-span-3 sm:sui-col-span-3"
      >
        <Link
          className="sui-no-underline"
          href={identifiers?.canonicalUrl}
          target="_top"
        >
          <Image itemId={itemId} product={product} />
        </Link>
      </div>
      <div
        data-automation-id={`atc-product-summary-${itemId}`}
        className="sui-col-span-9 sm:sui-col-span-9 sui-pl-4"
      >
        <div className="sui-flex sui-flex-col">
          <Link
            underline="none"
            href={identifiers?.canonicalUrl}
            target="_top"
          >
            <Typography variant="body-base" weight="bold">{identifiers?.brandName}</Typography>
            <Typography>{' ' + identifiers?.productLabel}</Typography>
          </Link>
          <div className="sui-flex sui-flex-row">
            <Typography variant="body-base" weight="bold">
              {`$${productPrice.toFixed(2)}`}
            </Typography>
            <Typography variant="body-base">/item</Typography>
          </div>
        </div>
        <div className="sui-flex sui-flex-col sui-items-start md:sui-items-center md:sui-flex-row ">
          <QuantityPicker
            value={parseInt(updatedQuantity, 10)}
            onChange={onQuantityChange}
            max={999}
            min={1}
            data-testid=""
          />
          <Price
            price={totalPrice}
            quantity={updatedQuantity}
          />
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  identifiers: shape({}).isRequired,
  itemId: string.isRequired,
  product: shape({}).isRequired,
  pricing: shape({}).isRequired,
  updatedQuantity: number.isRequired,
  setUpdatedQuantity: func.isRequired,
};
