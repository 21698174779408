import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleSecondaryNeutralGray = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleSecondaryNeutralGray.palette['--sui-palette-background-primary'] = '237 237 237';

export { HouseStyleSecondaryNeutralGray };