/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { useState } from 'react';
import { useStoreId } from '@thd-nucleus/experience-context';
import { shouldUseMcc, updateCart } from './useMccCart';
import { shouldUseAutomation, updateCartAutomation } from './useAutomationModel';

export const useCheckForAlternateProvider = (useUpdateCart, localStoreId) => {
  if (shouldUseMcc()) {
    useUpdateCart = () => {
      const [response, setResponse] = useState(null);
      const doUpdate = (params) => {
        console.log('shouldUseMcc() doUpdate', params);
        params.variables.localStoreId = localStoreId;
        updateCart(params, setResponse);
      };
      return [doUpdate, response];
    };
  }
  if (shouldUseAutomation()) {
    useUpdateCart = () => {
      const [response, setResponse] = useState(null);
      const doUpdate = (params) => {
        console.log('shouldUseAutomation() doUpdate', params);
        params.variables.localStoreId = localStoreId;
        updateCartAutomation(params, setResponse);
      };
      return [doUpdate, response];
    };
  }

  return useUpdateCart;
};