// DEPRECATED: Use the channel specific components. This is kept for backwards compatibility
import React, { cloneElement, isValidElement, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { AppContext } from '@thd-nucleus/app-render';
import {
  Card, CardTitle, CardContent, Overlay, Row, Col, Heading
} from '@thd-olt-component-react/core-ui';
import { dataModel } from '../dataModel';
import { getSortedValue } from '../helpers';
import './specifications.scss';

const GROUP_TYPES = {
  DIMENSIONS: 'Dimensions',
  DETAILS: 'Details',
  WARRANTY: 'Warranty / Certifications'
};

const Specifications = (props) => {

  const {
    itemId,
    hideMobileFade,
    children,
    oneColumn
  } = props;
  const { channel, isServer } = useContext(ExperienceContext);
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;

  const getAttrContent = (attributeGroupList) => {
    const attributeValues = getSortedValue({ attributeGroupList });
    return (
      <>
        <div className="specifications__wrapper">
          {(attributeValues || []).map((attr, i) => (
            <Col key={i} xs="12" sm="12" md={oneColumn ? '12' : '6'} className="specifications__row">
              <Col fallback="6" className="specifications__cell specifications__cell__label">
                {attr.specName}
              </Col>
              <Col fallback="6" className="specifications__cell">
                {attr.specValue}
              </Col>
            </Col>
          ))}
          {attributeValues && attributeValues.length % 2 === 1 && (
            <Col xs="12" sm="12" md={oneColumn ? '12' : '6'} className="specifications__row">
              <Col fallback="6" className="specifications__cell__empty specifications__cell__label" />
              <Col fallback="6" className="specifications__cell__empty" />
            </Col>
          )}
        </div>
      </>
    );
  };

  const getRenderingContent = (specificationGroup) => (
    <>
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.DIMENSIONS)
        .map((attrGroupList, i) => (
          <Row key={i}>
            <Col nopadding>
              <h4 className="specifications__title">{attrGroupList.specTitle}</h4>
              {getAttrContent(attrGroupList)}
            </Col>
          </Row>
        ))}
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.DETAILS)
        .map((attrGroupList, i) => (
          <Row key={i}>
            <Col nopadding>
              <h4 className="specifications__title specifications__title__paddingtop">{attrGroupList.specTitle}</h4>
              {getAttrContent(attrGroupList)}
            </Col>
          </Row>
        ))}
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.WARRANTY)
        .map((attrGroupList, i) => (
          <Row key={i}>
            <Col nopadding>
              <h4 className="specifications__title specifications__title__paddingtop">{attrGroupList.specTitle}</h4>
              {getAttrContent(attrGroupList)}
            </Col>
          </Row>
        ))}
      <Row>
        <Col nopadding>
          <div id="thdFeedbackPip" className="specifications__link" suppressHydrationWarning />
        </Col>
      </Row>
    </>
  );

  const renderDesktopSpecification = (renderedContent) => (
    <Row>
      <Heading title="Specifications" underline />
      {children && cloneElement(children, {
        itemId,
        maxfeatures: 8,
        minfeatures: 3,
        channel,
        displaySimilarLinks: true
      })}
      {renderedContent}
    </Row>
  );

  const renderMobileSpecification = (renderedContent) => {
    const maxHeight = isValidElement(children) || hideMobileFade ? 'none' : '219px';
    const contentClasses = classNames('content-card__summary', {
      'content-card__summary--fade': !isValidElement(children) && !hideMobileFade
    });
    return (
      <Card noHide>
        { !hideMobileFade && <CardTitle title="Specifications" /> }
        <CardContent className="specifications--mobile">
          <div className={contentClasses} style={{ maxHeight }}>
            {children
              ? cloneElement(children, {
                itemId,
                maxfeatures: 4,
                minfeatures: 4,
                channel
              })
              : renderedContent}
            <Overlay onClose={() => {}}>
              {children && cloneElement(children, {
                itemId,
                maxfeatures: 8,
                minfeatures: 4,
                channel,
                displaySimilarLinks: true
              })}
              {renderedContent}
            </Overlay>
            {(children && isServer && thdSeoBotDetected) && (
              <div className="u--hide">
                {renderedContent}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    );

  };

  const renderSpecs = (specificationGroup) => {
    const renderedContent = getRenderingContent(specificationGroup);
    return (
      <Row
        className="specifications"
        id="specifications"
        data-component="Specifications"
      >
        {channel !== 'mobile'
          ? renderDesktopSpecification(renderedContent)
          : renderMobileSpecification(renderedContent)}
      </Row>
    );
  };

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading && !data) {
    return null;
  }

  if (error && !data) {
    return null;
  }

  if (!data?.product?.specificationGroup) {
    return null;
  }

  const { specificationGroup } = data.product;

  if (!specificationGroup.length) {
    return null;
  }

  return renderSpecs(specificationGroup);
};

Specifications.displayName = 'Specifications';

Specifications.propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  /** makes only one column of specification KVPs regardless of screen size */
  oneColumn: PropTypes.bool,
  /** hides the fade for mobile specifications */
  hideMobileFade: PropTypes.bool,
  /** Can take an (optional) single child component of type KeyProductFeatures */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error;
    if (prop && (!isValidElement(prop) || prop.type.name !== 'KeyProductFeatures')) {
      error = new Error(`'${componentName}' only accepts a single child of type 'KeyProductFeatures'`);
    }
    return error;
  }
};

Specifications.defaultProps = {
  hideMobileFade: false,
  oneColumn: false,
  children: null,
};

Specifications.dataModel = dataModel;

export { Specifications };
