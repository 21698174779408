import React, { useState, useEffect, useContext } from 'react';
import {
  useDataModel,
  params,
  string,
  id,
  shape,
  number,
  bool,
  customType,
  arrayOf,
  extend,
} from '@thd-nucleus/data-sources';
import { func, string as stringType } from 'prop-types';

import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Card } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  RecentPurchasesTableHeader,
  RecentPurchasesCardHeader,
} from './components/subcomponents/cardHeader/RecentPurchasesHeader';
import { RecentPurchasesTable } from './components/recentPurchasesTable/RecentPurchasesTable';
import { RecentPurchasesCard } from './components/recentPurchasesCard/RecentPurchasesCard';
import { InstoreOrderDetails } from './components/orderDetails/inStoreOrderDetails';
import { OnlineOrderDetails } from './components/orderDetails/onlineOrderDetails';
import { getChannelId } from './components/helpers';
import { InstoreEditPOJobName } from './components/editPOJobName/instoreEditPOJobName';
import { OnlineEditPOJobName } from './components/editPOJobName/onlineEditPOJobName';
// eslint-disable-next-line
import { RecentPurchasesTablePlaceholder } from './components/placeholders/orderHistoryLoading/RecentPurchasesTablePlaceholder';
// eslint-disable-next-line
import { RecentPurchasesCardPlaceholder } from './components/placeholders/orderHistoryLoading/RecentPurchasesCardPlaceholder';
import { RecentPurchasesFallbackTable, RecentPurchasesFallbackCard } from './components/fallback/RecentPurchasesFallback';
import { useWindowWidth } from '../utils';

export function RecentPurchases({ onError, urlTmxId, componentId }) {
  const { channel } = useContext(ExperienceContext);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const channelId = getChannelId(channel);

  const thdCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : null;
  const userId = thdCustomer?.userID;
  const customerAccountId = thdCustomer?.svocID;
  function getThreatMetrixId() {
    if (typeof window === 'undefined') return '';

    return window.THD_ACCOUNT_LIB?.TMX?.sessionId;
  }
  const sessionId = urlTmxId || getThreatMetrixId();
  const mobileWidth = 640;
  const windowWidth = useWindowWidth();
  const isMobileLayout = windowWidth < mobileWidth;
  // Set end date for today and start date for 25 months prior
  // subtracting date by 25 months in milliseconds
  const startDate = new Date(new Date() - 25 * 30 * 24 * 3600 * 1000).toISOString().split('T')[0];
  const endDate = new Date().toISOString().split('T')[0];
  const orderHistoryVariableOptions = {
    searchType: 'ORDERS',
    pageSize: 20,
    pageNumber: 1,
    resultsFilter: 'myOrders',
    sortBy: 'salesDate',
    customerAccountId,
    sortOrder: 'DESC',
    startDate,
    endDate
  };

  const FallbackLayout = isMobileLayout
    ? RecentPurchasesFallbackCard
    : RecentPurchasesFallbackTable;

  const PlaceholderLayout = isMobileLayout
    ? RecentPurchasesCardPlaceholder
    : RecentPurchasesTablePlaceholder;

  const orderHistoryQueryOptions = {
    variables: {
      userId,
      request: orderHistoryVariableOptions,
      headers: {
        tmxProfileId: sessionId,
        channelId,
      },
    },
    skip: !userId,
    context: { withAuth: true },
  };

  const {
    data: orderHistoryData,
    loading: orderHistoryLoading,
    error: orderHistoryError,
  } = useDataModel('purchaseHistory', orderHistoryQueryOptions);

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'RecentPurchases',
      component: 'RecentPurchases',
      position: 1,
      type: 'content',
    },
  });

  if (orderHistoryLoading || !mounted) {
    return <PlaceholderLayout />;
  }

  if (!orderHistoryData?.purchaseHistory?.orders?.length && !orderHistoryLoading) {
    if (orderHistoryError) {
      if (typeof onError === 'function') onError(orderHistoryError);
      return <FallbackLayout isError />;
    }
    return <FallbackLayout noData componentId={componentId} />;
  }

  return (
    <Card
      data-testid="recentOrders-card-b2b"
      className="sui-h-full sui-w-full"
      data-component="RecentPurchases"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      {isMobileLayout ? (
        <>
          <RecentPurchasesCardHeader />
          <RecentPurchasesCard
            orderHistoryData={orderHistoryData}
            windowWidth={windowWidth}
            customerInfo={{
              userId,
              customerAccountId,
              sessionId,
              channelId,
            }}
          />
        </>
      ) : (
        <>
          <RecentPurchasesTableHeader />
          <RecentPurchasesTable
            orderHistoryData={orderHistoryData}
            orderHistoryLoading={orderHistoryLoading}
            orderHistoryError={orderHistoryError}
            isMounted={mounted}
            windowWidth={windowWidth}
            customerInfo={{
              userId,
              customerAccountId,
              sessionId,
              channelId,
            }}
            onError={onError}
          />
        </>
      )}
    </Card>
  );
}

RecentPurchases.propTypes = {
  onError: func,
  urlTmxId: stringType,
  componentId: stringType,
};

RecentPurchases.defaultProps = {
  onError() {},
  urlTmxId: null,
  componentId: '',
};

RecentPurchases.dataModel = extend({
  purchaseHistory: params({
    userId: id().isRequired(),
    headers: customType('HeaderExtras').shape({
      tmxProfileId: string(),
      channelId: string()
    }),
    request: customType('PurchaseHistoryRequest!').shape({
      searchType: string(),
      pageSize: number(),
      pageNumber: number(),
      resultsFilter: string(),
      sortBy: string(),
      customerAccountId: id().isRequired(),
      sortOrder: string(),
      startDate: string(),
      endDate: string(),
    }),
  }).shape({
    customerAccountId: id().isRequired(),
    showWarning: bool(),
    orderCount: number(),
    orders: arrayOf(
      shape({
        salesDate: string(),
        orderNumber: string(),
        orderNumbers: arrayOf(string()),
        totalAmount: number(),
        receiptDetails: string(),
        registerNumber: number(),
        orderOrigin: string(),
        storeNumber: number(),
        detailsSource: string(),
        transactionId: number(),
        POJobName: string(),
        transactionType: string(),
      })
    ),
  })
},
InstoreOrderDetails,
OnlineOrderDetails,
InstoreEditPOJobName,
OnlineEditPOJobName
);
