import React from 'react';
import './icon.scss';
import { Image } from '@thd-olt-component-react/core-ui';

export const AvailableIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/Available.svg"
    alt="Available Shipping"
    className="icon__size stretchy"
    height={20}
    width={20}
  />
);

export const BopisOffIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/BOPIS-Off.svg"
    alt="Buy in Store"
    className="icon__size stretchy"
    height={20}
    width={20}
  />
);

export const InStockIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/In-Stock.svg"
    alt="Available for pickup"
    className="icon__size stretchy"
    height={20}
    width={20}
  />
);

export const LimitedStockIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/Limited-Stock.svg"
    alt="Limited stock for pickup"
    className="icon__size stretchy"
    height={20}
    width={20}
  />
);

export const UnavailableIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/close-x-black.svg"
    alt="Limited stock for pickup"
    className="icon__unavailable stretchy"
    height={20}
    width={20}
  />
);

export const InformationIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/info.svg"
    alt="Infomation about this product's shipping availabilty"
    className="icon__information-icon"
    width={15}
    height={15}
  />
);

export const BundleFulfillmentMessageIcon = (
  <Image
    src="https://assets.thdstatic.com/images/v1/bundle_fulfillment_message.svg"
    alt="Bundle Fulfillment Message"
    className="icon__size stretchy"
    height={20}
    width={20}
  />
);
