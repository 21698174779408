import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import {
  Typography,
  CardTitle,
  Card,
  CardBody
} from '@one-thd/sui-atomic-components';
import { TopCategories } from '@thd-olt-component-react/thd-recs-containers';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { CUSTOMER_TYPES } from '../../utils';

export function TopCategoriesForYou({
  REQUEST_KEY,
  mcvisID,
  slidesPer,
  customerType,
  id,
}) {
  const { channel } = useContext(ExperienceContext);

  const parsedCustomerType = (
    customerType === CUSTOMER_TYPES.B2B ? customerType : CUSTOMER_TYPES.B2C
  ).toUpperCase();

  return (
    <ErrorBoundary name="pers_topCategories">
      <QueryProvider cacheKey="pers_topCategories">
        <Card
          id={`top-categories-for-you-php-${id}`}
          className="sui-h-full"
        >
          <CardTitle header={
            <Typography variant="h3">Top Categories For You</Typography>
          }
          />
          <CardBody>
            <TopCategories
              hideTitle
              entryId={id}
              requestKey={REQUEST_KEY}
              showLoading
              showFallback
              customerID={mcvisID}
              slidesPer={slidesPer}
              customerType={parsedCustomerType}
              hideControls={channel === 'mobile'}
            />
          </CardBody>
        </Card>
      </QueryProvider>
    </ErrorBoundary>
  );
}

TopCategoriesForYou.propTypes = {
  REQUEST_KEY: string,
  mcvisID: string,
  slidesPer: number,
  customerType: string,
  id: string,
};

TopCategoriesForYou.defaultProps = {
  REQUEST_KEY: undefined,
  mcvisID: undefined,
  slidesPer: undefined,
  customerType: undefined,
  id: undefined,
};
