import * as CardUtils from './cardUtils';
import { ENDPOINTS } from '../../core/Constants';
import * as MockUserData from '../../core/Data';

const defaultPropsDataSourceOpts = {
  skip: false,
};

export const useDataSourceOpts = (props) => {
  const { skip } = props || defaultPropsDataSourceOpts;
  const isMobileChannel = CardUtils.EXPERIENCE_CONTEXT?.channel === 'mobile';
  const opts = {
    variables: {
      customerAccountId: CardUtils.THD_CUSTOMER()?.svocID,
      custAccountId: CardUtils.THD_CUSTOMER()?.svocID,
      userId: CardUtils.THD_CUSTOMER()?.userID,
      channelId: isMobileChannel ? 2 : 1,
    },
    skip: !CardUtils.THD_CUSTOMER()?.svocID || skip,
    notifyOnNetworkStatusChange: true,
    context: {
      withAuth: true,
      headers: {
        channelId: isMobileChannel ? 2 : 1,
      },
    },
  };
  return { opts };
};

export const determineLoadingState = (res) => {
  const { loading, data, error } = res;
  const states = {
    isLoadingInitial: loading === false && !(data || error),
    isLoadingActual: loading === true,
    isLoadingDone: loading === false && (data || error),
  };
  return states.isLoadingInitial || states.isLoadingActual;
};

export const determineErrorState = (res) => {
  const data = res.data;
  const dataChild = data && data[Object.keys(data)[0]];
  const isGrandchildrenNull = dataChild && Object.keys(dataChild)
    ?.filter((key) => key !== '__typename')?.every((key) => !dataChild[key]);
  return !determineLoadingState(res) && (isGrandchildrenNull || res.error);
};

export const determineAuthenticated = ({ graphQLErrors }) => {
  if (!graphQLErrors) return false;
  const { extensions } = graphQLErrors?.[0] ?? {};
  const isAuthStatusCode = extensions?.id === 401;
  const isInvalidCookie = `${extensions?.message}`.includes('ERROR_INVALID_JWT_TOKEN');
  return isAuthStatusCode || isInvalidCookie;
};

export const GatherMockData = (endpoint) => {
  let mockData = {};
  if (endpoint === 'welcomeCard') {
    mockData = { [ENDPOINTS.HOMEPAGE]: MockUserData.DevUser379 };
  }
  if (endpoint === 'availablePerks') {
    mockData = { [ENDPOINTS.AVAILABLE_PERKS]: MockUserData.AvailablePerks };
  }
  return [() => {}, { loading: false, data: mockData, error: null, called: true }];
};

const findCurrentTier = (tiers) => tiers?.find((tier) => tier?.currentTier);

export const findTierByTierId = (tierId, tiers) => tiers?.find((tier) => tier?.tierId === tierId);

const findTierByPerkId = (perkId, tiers) => tiers?.find((tier) => tier?.tierPerks?.[0]?.perkId === perkId);

export const findPerkByPerkId = (perkId, items, offerType) => {
  let perkObj = {};
  if (offerType === 'PERK') perkObj = items?.find((tier) => tier?.tierPerks?.[0]?.perkId === perkId)?.tierPerks?.[0];
  if (offerType === 'OFFER') perkObj = items?.find((perk) => perk?.perkId === perkId);
  return perkObj;
};

export const getCurrentTierPerkOptions = (props) => {
  const { infoPerksData } = props ?? {};
  const list = infoPerksData?.program?.tiers ?? [];
  const currentTier = findCurrentTier(list) ?? {};
  const currentTierPerks = currentTier?.tierPerks?.[0] ?? {};
  const currentTierOptions = currentTierPerks?.options ?? [{}, {}];
  return { currentTier, currentTierPerks, currentTierOptions };
};

export const getFutureTierPerkOptions = (props) => {
  const { infoPerksData } = props ?? {};
  const list = infoPerksData?.program?.tiers ?? [];
  const currentTierIndex = findCurrentTier(list);
  // If currentIndex is not found or it is the last element, return an empty object
  if (currentTierIndex === -1 || currentTierIndex === list?.length - 1) {
    return {};
  }
  // Return the next tier in the list
  const futureTier = list[currentTierIndex + 1];
  const futureTierPerks = futureTier?.tierPerks?.[0] ?? {};
  const futureTierOptions = futureTierPerks?.options ?? [{}, {}];
  return { futureTier, futureTierPerks, futureTierOptions };
};

export const locateTierWithNewData = (props) => {
  const {
    tier, tierId, perkId, tierList, infoPerks
  } = { ...props };
  if (!tier && tierId && (tierList || !infoPerks?.isLoadingInfoPerks)) {
    return findTierByTierId(tierId, tierList ?? infoPerks?.infoPerksData?.program?.tiers);
  }

  if (!tier && perkId && (tierList || !infoPerks?.isLoadingInfoPerks)) {
    return findTierByPerkId(perkId, tierList ?? infoPerks?.infoPerksData?.program?.tiers);
  }
  return tier;
};

export const locatePerkWithNewData = (params) => {
  const {
    perkObjFromProps, perkObj, perkId, perkList, tierList, infoOffers, infoPerks
  } = params;

  let perk = perkObjFromProps ?? perkObj;
  if (!perk && perkId && (perkList || !infoOffers?.isLoadingInfoOffers)) {
    perk = findPerkByPerkId(perkId, perkList ?? infoOffers?.nonProgramPerks, 'OFFER');
  }
  if (!perk && perkId && (tierList || !infoOffers?.isLoadingInfoPerks)) {
    perk = findPerkByPerkId(perkId, tierList ?? infoPerks?.infoPerksData?.program?.tiers, 'PERK');
  }

  return perk;
};
