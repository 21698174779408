/* eslint-disable no-console */
// tell the mini-cart in the header to update its quantity
// Add a payload with source details so internal event handler
// can ignore this if necessary
export const updateMiniCartQuantity = () => {
  try {
    if (typeof window !== 'undefined' && typeof jQuery !== 'undefined') {
      /* eslint-disable no-undef */
      window.top.$(window.top.document).trigger({ type: 'cartUpdated' }, { source: 'cartMutation' });
    } else if (typeof window !== 'undefined') {
      window.top.LIFE_CYCLE_EVENT_BUS.trigger('add-to-cart.success', { source: 'cartMutation' });
    }
  } catch (err) { /* console.log(' Error on updateMiniCartQuantity:', err); */ }
};

// optimalFulfillment supported by updateCart, deleteItem, getCart/viewCart
export const OPTIMAL_OVERRIDE = 'optimal_override';
export const OPTIMAL_FULFILLMENT = 'optimal_fulfillment';

let optimalObjOnLoad;

export const optimalOverride = (mutationName) => {
  try {
    if (mutationName === 'getCart' && optimalObjOnLoad) {
      return optimalObjOnLoad;
    }
    // optimal fulfillment logic override
    if (typeof window !== 'undefined' && localStorage.getItem(OPTIMAL_OVERRIDE)) {
      // only getCart is optimal override
      // eslint-disable-next-line
      if (mutationName === 'getCart') {
        optimalObjOnLoad = { optimalFulfillment: false };
        return optimalObjOnLoad;
      }
      localStorage.removeItem(OPTIMAL_OVERRIDE);
    }
    return { optimalFulfillment: true };
  } catch (err) { /* console.log(' Error on setting optimalHeaders:', err); */ }
  return { optimalFulfillment: true };
};

export const getExchangeCartId = () => {
  if (typeof window !== 'undefined' && localStorage.getItem('exchangeCartId')) {
    return localStorage.getItem('exchangeCartId');
  }
  return null;
};

export const getUrlParam = (param) => {
  const searchParams = typeof window !== 'undefined' ? window?.top.location?.search : '';
  const urlParams = new URLSearchParams(searchParams);
  return urlParams.get(param);
};

export const getTmxId = () => {
  if (typeof window === 'undefined') return null;

  const nativeTMXID = window.__EXPERIENCE_CONTEXT__?.isConsumerApp
    ? getUrlParam('guid')
    : window.THD_ACCOUNT_LIB?.TMX?.getTMXsessionId();

  return nativeTMXID;
};