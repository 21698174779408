import React from 'react';
import { string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { PromotionProducts } from '@thd-olt-component-react/promotion-products';
import { useUdsCallResponse } from '../hooks/useUdsCallResponse';
import { PromotionCardUdsDataModel } from '../models/PromotionCardUdsDataModel';
import { PromotionCard } from './PromotionCard';

const PromotionCardContainer = ({
  componentId
}) => {
  const thdCustomer = typeof window !== 'undefined' && window.THDCustomer?.default;
  const customerId = thdCustomer?.mcvisID;
  const { data, loading } = useUdsCallResponse(customerId);
  const itemId = data?.uds?.products[0]?.product?.itemId;

  if (loading) {
    return <></>;
  }

  return (
    <PromotionCard
      componentId={componentId}
      loading={loading}
      itemId={itemId}
    />
  );
};

PromotionCardContainer.propTypes = {
  componentId: string.isRequired,
};

PromotionCardContainer.defaultProps = {};

PromotionCardContainer.displayName = 'PromotionCardContainer';

PromotionCardContainer.dataModel = extend(
  PromotionCardUdsDataModel,
  PromotionProducts
);

export { PromotionCardContainer };