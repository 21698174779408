import { useMemo, useRef, useCallback } from 'react';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import {
  determineLoadingState, determineErrorState, useDataSourceOpts, determineAuthenticated
} from '../common/utils/ContextUtils';
import { ENDPOINTS } from '../core/Constants';
import { gotoLogonModule } from '../common/utils/AccountUtils';

export const useFetchAvailablePerks = () => {
  const availablePerksDataRef = useRef({});
  const opts = useDataSourceOpts().opts;

  const perksResponse = useDataModel(ENDPOINTS.AVAILABLE_PERKS, opts);
  availablePerksDataRef.current = perksResponse?.data && perksResponse?.data[ENDPOINTS.AVAILABLE_PERKS];

  const availablePerksData = availablePerksDataRef.current;
  const isLoadingPerks = determineLoadingState(perksResponse);
  const availablePerksError = determineErrorState(perksResponse) || !availablePerksData;
  const refreshAvailablePerks = () => perksResponse.refetch();
  const refreshAvailableOffers = () => perksResponse.refetch();

  return useMemo(() => ({
    isLoadingPerks,
    availablePerksError,
    availablePerksData,
    refreshAvailablePerks,
    refreshAvailableOffers,
  }), [isLoadingPerks]);
};

const defaultPerkActivationRequest = {
  perkId: '',
  tierRewardId: '',
  programId: '',
  tierId: '',
};

export const useFetchActivatePerks = (props) => {
  const { perkActivationRequest } = props ?? {};
  const activatePerksDataRef = useRef({});
  const opts = useDataSourceOpts().opts;
  if (!opts.variables?.custAccountId) gotoLogonModule();
  if (opts && opts.variables) {
    opts.variables.perkActivationRequest = {
      ...(perkActivationRequest ?? defaultPerkActivationRequest),
      userId: opts.variables.userId,
    };
  }

  const [getActivateResponse, activateResponse] = useLazyDataModel(ENDPOINTS.ACTIVATE_PERKS, opts);
  activatePerksDataRef.current = activateResponse?.data && activateResponse?.data[ENDPOINTS.ACTIVATE_PERKS];

  const activatePerksData = activatePerksDataRef.current;
  const isLoadingActivatePerks = determineLoadingState(activateResponse);
  const activatePerksError = determineErrorState(activateResponse) || !activatePerksData;
  const refreshActivatePerks = () => activateResponse.refetch();
  if (!opts.variables?.custAccountId || determineAuthenticated(activatePerksError)) gotoLogonModule();

  return useMemo(() => ({
    isLoadingActivatePerks,
    activatePerksError,
    activatePerksData,
    refreshActivatePerks,
    getActivateResponse,
  }), [isLoadingActivatePerks]);
};

export const useFetchInfoPerks = () => {
  const infoPerksDataRef = useRef({});
  const { opts } = useDataSourceOpts();
  if (opts && opts.variables) opts.variables.offerType = 'perk';
  if (!opts.variables?.custAccountId) gotoLogonModule();
  const [getPerksResponse, perksResponse] = useLazyDataModel(ENDPOINTS.INFO_PERKS, opts);
  infoPerksDataRef.current = perksResponse?.data && perksResponse?.data[ENDPOINTS.INFO_PERKS];

  const infoPerksData = infoPerksDataRef.current;
  const isLoadingInfoPerks = determineLoadingState(perksResponse);
  const infoPerksError = determineErrorState(perksResponse) || (!isLoadingInfoPerks && !infoPerksData?.currentTierId);
  const refreshInfoPerks = () => perksResponse.refetch();
  if (!opts.variables?.custAccountId || determineAuthenticated(infoPerksError)) gotoLogonModule();

  return useMemo(() => ({
    isLoadingInfoPerks,
    infoPerksError,
    infoPerksData,
    refreshInfoPerks,
    getPerksResponse,
  }), [isLoadingInfoPerks]);
};

export const useFetchInfoOffers = () => {
  const infoOffersDataRef = useRef({});
  const opts = useDataSourceOpts().opts || { variables: {} };
  if (opts && opts.variables) opts.variables.offerType = 'offer';
  if (!opts.variables?.custAccountId) gotoLogonModule();
  const [getOffersResponse, offersResponse] = useLazyDataModel(ENDPOINTS.INFO_OFFERS, opts);
  infoOffersDataRef.current = offersResponse?.data && offersResponse?.data[ENDPOINTS.INFO_OFFERS];

  const infoOffersData = infoOffersDataRef.current;
  const isLoadingInfoOffers = determineLoadingState(offersResponse);
  const infoOffersError = determineErrorState(offersResponse)
    || (!isLoadingInfoOffers && !infoOffersData?.currentTierId);
  const refreshInfoOffers = () => offersResponse.refetch();
  if (!opts.variables?.custAccountId || determineAuthenticated(infoOffersError)) gotoLogonModule();

  return useMemo(() => ({
    isLoadingInfoOffers,
    infoOffersError,
    infoOffersData,
    refreshInfoOffers,
    getOffersResponse,
  }), [isLoadingInfoOffers]);
};

const defaultPerkRedemptionRequest = {
  perkId: '',
  perkType: '',
  paymentId: '',
  perkSourceUUID: '',
};

export const useFetchRedeemPerks = (props) => {
  const { perkRedemptionRequest } = props ?? {};
  const redeemPerksDataRef = useRef({});
  const opts = useDataSourceOpts().opts;
  if (!opts.variables?.custAccountId) gotoLogonModule();
  if (opts && opts.variables) {
    opts.variables.perkRedemptionRequest = perkRedemptionRequest ?? defaultPerkRedemptionRequest;
  }

  const [getRedeemResponse, redeemResponse] = useLazyDataModel(ENDPOINTS.REDEEM_PERKS, opts);
  redeemPerksDataRef.current = redeemResponse?.data && redeemResponse?.data[ENDPOINTS.REDEEM_PERKS];

  const redeemPerksData = redeemPerksDataRef.current;
  const isLoadingRedeemPerks = determineLoadingState(redeemResponse);
  const redeemPerksError = determineErrorState(redeemResponse) || !redeemPerksData;
  const refreshRedeemPerks = () => redeemResponse.refetch();
  if (!opts.variables?.custAccountId || determineAuthenticated(redeemPerksError)) gotoLogonModule();

  return useMemo(() => ({
    isLoadingRedeemPerks,
    redeemPerksError,
    redeemPerksData,
    refreshRedeemPerks,
    getRedeemResponse,
  }), [isLoadingRedeemPerks]);
};

export const useCheckAndFetchOffers = (params) => {
  const { infoOffers } = params;
  const checkAndFetchPerks = useCallback(() => {
    if (!infoOffers.infoOffersData) infoOffers.getOffersResponse();
  }, []);
  return { checkAndFetchPerks };
};
