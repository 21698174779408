export const configureContainerForWrap = (numberOfCards, extraCardOnLargeAndPlus = false) => {
  let cols;

  if (numberOfCards === 1) {
    cols = ['sui-grid-cols-1'];
  } else if (numberOfCards === 2) {
    cols = ['sui-grid-cols-1', 'md:sui-grid-cols-2'];
  } else if (numberOfCards >= 3) {
    cols = ['sui-grid-cols-1', 'md:sui-grid-cols-2', 'xl:sui-grid-cols-3'];
  }

  if (extraCardOnLargeAndPlus) {
    if (numberOfCards === 3) {
      cols = ['sui-grid-cols-1', 'md:sui-grid-cols-2', 'lg:sui-grid-cols-3'];
    } else if (numberOfCards >= 4) {
      cols = ['sui-grid-cols-1', 'md:sui-grid-cols-2', 'lg:sui-grid-cols-3', 'xl:sui-grid-cols-4'];
    }
  }

  return cols;
};

export const configureContainerForCarousel = (numberOfCards, extraCardOnLargeAndPlus = false) => {
  const breakpoints = {
    sm: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 16 },
    md: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 24 },
    lg: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 24 },
    xl: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 32 }
  };

  if (numberOfCards === 2) {
    breakpoints.md = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 24 };
    breakpoints.lg = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 24 };
    breakpoints.xl = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 32 };
  }

  if (numberOfCards === 3) {
    breakpoints.md = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 24 };
    breakpoints.lg = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 24 };
    breakpoints.xl = { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 32 };
    if (extraCardOnLargeAndPlus) {
      breakpoints.lg = { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 24 };
      breakpoints.xl = { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 32 };
    }
  }

  if (numberOfCards >= 4) {
    breakpoints.md = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 24 };
    breakpoints.lg = { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 24 };
    breakpoints.xl = { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 32 };
    if (extraCardOnLargeAndPlus) {
      breakpoints.lg = { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 24 };
      breakpoints.xl = { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 32 };
    }
  }

  return breakpoints;
};
