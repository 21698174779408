/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  extend
} from '@thd-nucleus/data-sources';
import { ListSettings } from '@thd-olt-component-react/list-forms';
import { useImpression } from '@thd-olt-component-react/impression';
import {
  func, shape, string, arrayOf, number
} from 'prop-types';
import { Thumbnails } from './Thumbnails';
import { ListTilePlaceholder } from './ListTilePlaceholder';
import {
  Card, CardBody, CardTitle, Tooltip, Link, Typography
} from '~/@one-thd/sui-atomic-components';

export const ListTile = ({
  listId, data, onDelete, position, iconSize,
}) => {

  const list = data;

  const [shortListName, setShortListName] = useState('');
  const listName = list?.name ?? '';
  const listNameIsLong = listName && listName.length > 30;

  const cutThreeTimesListName = listName && listName.length > 40;
  const firstPartListName = listName.slice(0, 20);
  const secondPartListName = listName.slice(20, 40);
  const thirdPartListName = listName.slice(40, listName.length);

  const listNameSpliced = cutThreeTimesListName
    ? `${firstPartListName}
    ${secondPartListName}
    ${thirdPartListName}
    `
    : `${firstPartListName}
    ${secondPartListName}
    `;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('list-tile.ready');
  }, []);

  useEffect(() => {
    if (listNameIsLong) {
      const listNameSubStr = list?.name.slice(0, 14);
      const newListNameWithEllipsis = listNameSubStr.concat('...');

      setShortListName(newListNameWithEllipsis);
    }
  }, [listNameIsLong]);

  const onDeleteConfirmation = () => {
    if (onDelete) {
      onDelete({ listId });
    }
  };

  const impressionContextData = useImpression({ data: {
    id: listId,
    name: 'ListTile',
    component: 'ListTile',
    position: position + 1,
    type: 'content'
  } });

  if (!list) return <ListTilePlaceholder />;

  if (!list || !list?.url) return null;

  const itemCountMessage = `${list?.itemCount || 0} ${list?.itemCount === 1 ? ' item' : ' items'}`;

  const listTileRef = impressionContextData.ref;
  const listTileClickId = impressionContextData.clickID;

  const headerWithoutTooltip = (
    <a
      href={list?.url}
    >
      <Typography
        variant="h3"
        weight="bold"
      >
        {listNameIsLong ? shortListName : list?.name}
      </Typography>
    </a>
  );

  const headerWithTooltip = (
    <Tooltip
      placement="top"
      title={listNameSpliced}
    >
      <a href={list?.url}>
        <Typography
          variant="h3"
          weight="bold"
        >
          {shortListName}
        </Typography>
      </a>
    </Tooltip>
  );
  const header = (listNameIsLong) ? headerWithTooltip : headerWithoutTooltip;

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div className="sui-w-full" ref={listTileRef} clickid={listTileClickId} data-component="ListTile">
      <Card className="sui-grid sui-rounded-md sui-shadow-base sui-h-full">
        <CardTitle
          header={(header)}
          subheader={<Link href={list?.url} className="sui-font-regular sui-text-base">{ itemCountMessage } </Link>}
          action={<ListSettings iconSize={iconSize} onClose={() => { }} list={list} onDelete={onDeleteConfirmation} />}
        />
        <CardBody>
          <Thumbnails url={list?.url} thumbnails={list?.images} />
        </CardBody>
      </Card>
    </div>
  );
};

ListTile.displayName = 'ListTile';

ListTile.defaultProps = {
  onDelete: null,
  data: null,
  position: 0,
  iconSize: 'small',
};

ListTile.propTypes = {
  listId: string.isRequired,
  onDelete: func,
  iconSize: string,
  data: shape({
    id: string,
    name: string,
    url: string,
    itemCount: number,
    images: arrayOf(shape({
      url: string
    }))
  }),
  position: number
};

ListTile.dataModel = extend(ListSettings);
