import React, { Component } from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  oneOfType,
  string
} from 'prop-types';

export const withErrorBoundary = (WrappedComponent) => {
  class ErrorBoundary extends Component {

    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    state = {}

    componentDidCatch(error, info) {
      console.log(error);// eslint-disable-line
      const { errorBoundary = true, id, name, onError } = this.props;
      const { componentStack } = info || {};
      if (!errorBoundary) {
        throw error;
      }
      if (onError) {
        onError({ error, id, info, name });
      }
      if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {

        window.LIFE_CYCLE_EVENT_BUS.trigger('error-boundary.error', {
          componentStack,
          error,
          id,
          name
        });
      }
      return null;
    }

    render() {
      const { children, errorBoundary = true } = this.props;
      const { hasError } = this.state;
      if (!errorBoundary) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <WrappedComponent {...this.props}>{ children }</WrappedComponent>;
      }
      if (hasError) {
        return null;
      }
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      return <WrappedComponent {...this.props} />;
    }
  }

  ErrorBoundary.propTypes = {
    children: oneOfType([
      arrayOf(node),
      node
    ]),
    errorBoundary: bool,
    id: string,
    name: string,
    onError: func
  };

  ErrorBoundary.defaultProps = {
    children: null,
    errorBoundary: true,
    id: null,
    name: null,
    onError: null
  };

  ErrorBoundary.displayName = WrappedComponent.displayName || 'ErrorBoundary';

  ErrorBoundary.wraps = {
    ...(WrappedComponent.wraps || {}),
    errorBoundary: true
  };
  if (WrappedComponent?.dataModel) {
    ErrorBoundary.dataModel = WrappedComponent.dataModel;
  }

  return ErrorBoundary;
};
