import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SelectionControlBase } from '../private/components/selection/SelectionControlBase';

/**
 * `Toggle` is a digital on/off switch that allows users to choose between two opposing states
 * and always have a default value.
 *
 * Related components: [FormGroup](#formgroup), [SelectionControlLabel](#selectioncontrollabel), [Checkbox](#checkbox),
 *
 * Usage:
 *
 * ```jsx
 * import { Toggle } from '@one-thd/sui-atomic-components';
 * ```
 */
const Toggle = React.forwardRef((props, ref) => {

  const {
    disabled,
    ...other
  } = props;

  const rootClasses = classNames(
    'sui-inline-flex sui-relative sui-m-1',
    {
      'sui-cursor-pointer sui-tap-highlight-transparent': !disabled,
      'sui-cursor-default sui-pointer-events-none sui-opacity-25': disabled,
    },
  );

  const inputClasses = 'sui-peer sui-absolute sui-cursor-inherit sui-opacity-0 sui-h-full sui-w-full sui-p-1 sui-z-10';
  const trackClasses = 'sui-h-6 sui-w-11 sui-rounded-full sui-transition-colors sui-bg-medium peer-checked:peer-focus-visible:sui-bg-inverse peer-checked:sui-bg-brand peer-hover:sui-ring-4 peer-hover:sui-ring-input-hover-accent peer-focus-visible:sui-ring-4 peer-focus-visible:sui-ring-input-focus peer-focus-visible:sui-bg-medium';

  const handleClasses = 'sui-absolute sui-w-5 sui-h-5 sui-m-2px sui-z-0 sui-bg-subtle sui-rounded-full sui-transition-transform peer-checked:sui-translate-x-5 peer-focus-visible:sui-ring-2 peer-focus-visible:sui-ring-input-active';

  const checkSpan = (
    <>
      <span className={handleClasses} />
      <span className={trackClasses} />
    </>
  );

  const classes = {
    span: rootClasses,
    input: inputClasses,
  };

  return (
    <SelectionControlBase
      type="checkbox"
      classes={classes}
      disabled={disabled}
      checkedIcon={checkSpan}
      uncheckedIcon={checkSpan}
      role="switch"
      ref={ref}
      {...other}
    />
  );
});

Toggle.displayName = 'Toggle';

Toggle.propTypes = {
  /**
   * If `true`, the component is checked or on.
   */
  checked: PropTypes.bool,
  /**
   * The default checked value for the underlying checkbox element.
   */
  defaultChecked: PropTypes.bool,
  /**
   * The onChange event handler that is added to the underlying checkbox element.
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The id attribute that is added to the underlying checkbox component
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Attributes that are added to the underlying checkbox element.
   */
  inputAttributes: PropTypes.object,
};

Toggle.defaultProps = {
  checked: undefined,
  defaultChecked: false,
  id: null,
  inputAttributes: null,
  disabled: null,
};

export { Toggle };
