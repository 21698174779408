import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Stove = createSvgIcon(
  <>
    <path d="M18 10.5H6v8.25h12V10.5Zm-9.75 6v-3.75h7.5v3.75h-7.5ZM6 7.5h3v-3H6v3Zm8.25 0h-3v-3h3v3Z" />
    <path d="M22.5 0h-21v24h21V0ZM3.75 21.75V2.25h16.5v19.5H3.75Z" />
  </>,
  'Stove'
);

export { Stove };