import { useMemo } from 'react';

const useProductPodRedirectUrl = (props) => {
  const {
    canonicalUrl
  } = props;

  // --
  const redirectUrl = useMemo(() => {
    // ---
    const protocol = window?.location?.protocol;
    const host = window?.location?.host;
    // ---

    return `${protocol}//${host}${canonicalUrl}`;
  }, [canonicalUrl]);

  return {
    redirectUrl,
  };
};

export { useProductPodRedirectUrl };
