import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Assemble = createSvgIcon(
  <>
    <path d="M13.59 2.25h5.91V0h-6.841l-5.16 5.159v5.987L.023 18.345l1.56 1.62 8.168-7.861V6.09l3.84-3.841ZM24 11.341V4.5h-2.25v5.909l-3.841 3.841h-6.013l-7.862 8.168 1.621 1.56 7.199-7.478h5.987L24 11.341Z" />
    <path d="M20.25 8.25a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-2.25 0a2.25 2.25 0 1 0-4.5 0 2.25 2.25 0 0 0 4.5 0Z" />
  </>,
  'Assemble'
);

export { Assemble };