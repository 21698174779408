/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useState, useEffect } from 'react';
import { string, oneOf } from 'prop-types';
import {
  Skeleton, SkeletonLine
} from '@one-thd/sui-atomic-components';
import { useDataModel, params, string as stringType, shape, } from '@thd-nucleus/data-sources';

export const ProductIdentifier = ({ itemId, type }) => {
  const [identifier, setIdentifier] = useState(null);
  const { data, loading, error } = useDataModel('product', {
    variables: {
      itemId
    }
  });

  const { identifiers } = data?.product || {};
  const { modelNumber, itemId: itemIdFromData, storeSkuNumber } = identifiers || {};

  const getIdentifierValue = (key) => {
    const identifierValues = {
      model: modelNumber,
      storeSku: storeSkuNumber,
      itemId: itemIdFromData
    };
    return identifierValues[key] || null;
  };
  const IDENTIFIER_LABEL = {
    model: 'Model#', storeSku: 'StoreSKU#', itemId: 'Internet#'
  };

  useEffect(() => {
    if (identifiers) {
      setIdentifier(getIdentifierValue(type));
    }
  }, [identifiers, type]);

  const value = getIdentifierValue(type) || identifier;
  if (loading && !data) {
    return (
      <Skeleton grow disablePadding className="sui-bg-transparent">
        <SkeletonLine className="sui-w-full [&_span]:sui-w-full sui-h-2 sui-mt-1" fullWidth variant="single" />
      </Skeleton>
    );
  }
  if ((error && !data) || !value) return null;

  const label = `${IDENTIFIER_LABEL[type]} ${value}`;
  return (
    <div className="sui-flex sui-text-xs sui-mb-1 sui-mr-1">{label}</div>
  );
};

ProductIdentifier.displayName = 'ProductIdentifier';

ProductIdentifier.propTypes = {
  itemId: string.isRequired,
  type: oneOf(['storeSku', 'model', 'itemId']).isRequired,
};

ProductIdentifier.dataModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shape({
      modelNumber: stringType(),
      storeSkuNumber: stringType(),
      itemId: stringType()
    })
  })
};
