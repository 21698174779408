import { useEffect, useState } from 'react';

const useLifeCycleEventBus = (eventName, isLifeCycle = false) => {

  const [eventPayload, setEventPayload] = useState({});

  useEffect(() => {
    const eventHandler = (payload) => {
      const { output } = payload;
      setEventPayload(output);
    };
    const EVENTBUS = isLifeCycle ? window.LIFE_CYCLE_EVENT_BUS?.lifeCycle : window.LIFE_CYCLE_EVENT_BUS;
    if (EVENTBUS) {
      EVENTBUS.on(eventName, eventHandler);
    }
  }, [eventName]);

  return eventPayload;
};

export { useLifeCycleEventBus };