import { useContext, useState, useEffect } from 'react';
import { CustomerContext } from '../components/CustomerContext';

export const useThdCustomer = () => {
  const { THDCustomer } = useContext(CustomerContext);

  if (!THDCustomer && !THDCustomer?.customerType) {
    return {
      THDCustomer: null
    };
  }

  return THDCustomer;
};
