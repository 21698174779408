class Model {
  constructor(props) {
    this._props = props || {};
  }

  get(key, defaultsTo) {
    if (!this._props[key]) return defaultsTo;

    return this._props[key];
  }

  getNestedProperties(elements, defaultsTo) {
    // eslint-disable-next-line id-length
    return elements.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : defaultsTo), this._props);
  }

  toJSON() {
    return this._props;
  }
}

export { Model };
