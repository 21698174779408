import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Location = createSvgIcon(
  <>
    <path d="M9 9a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z" />
    <path d="M21 8.993c0 3.157-.986 4.689-7.575 13.175L12 24l-1.425-1.832C3.986 13.682 3 12.15 3 8.993 3 3.541 7.5 0 12 0s9 3.541 9 8.993ZM12 2.25c-3.418 0-6.75 2.688-6.75 6.743 0 .706.058 1.204.207 1.708.154.522.437 1.159 1.008 2.09.993 1.621 2.668 3.844 5.535 7.543 2.867-3.699 4.541-5.921 5.535-7.542.57-.932.854-1.569 1.008-2.09.149-.505.207-1.003.207-1.709 0-4.055-3.332-6.743-6.75-6.743Z" />
  </>,
  'Location'
);

export { Location };