export const HeaderTitle = 'Pro Xtra';
// eslint-disable-next-line max-len
export const spendToolTipLabel = 'Pro Xtra Loyalty Spend Includes transactions made with Pro Xtra ID or registered tender.';
export const spendToolTipLabel1 = 'Excludes taxes, shipping fees, returns, and gift card purchases.';
export const spendToolTipLabel2 = 'Savings includes amount saved from discounts on full price product,';
export const spendToolTipLabel3 = 'other markdowns, bulk discounts and Pro Xtra exclusive savings.';
export const ENDPOINTS = {
  HOMEPAGE: 'homepageWelcomeCard',
  AVAILABLE_PERKS: 'availablePerksCount',
  INFO_PERKS: 'getPerksHomePageById',
  INFO_OFFERS: 'getPerksHomePageById',
  ACTIVATE_PERKS: 'activatePerk',
  REDEEM_PERKS: 'redeemPerk',
  CHALLENGES_INFO: 'offerChallengesInfo',
  ACCEPT_CHALLENGES: 'acceptOfferChallenge',
};

// Promotions
export const promotionText = {
  PreferredPricingPromo: 'Getting Closer to Preferred Pricing',
  VolumePricingPromo: 'Save with Volume Pricing',
  PlccAcquisitionPromo: 'The New Pro Xtra Credit Card',
  TextToConfirmPromo: 'Approve Purchases via Text',
  PaintProductsPromo: 'Save 20% on paint products',
  ProXtraGeneralKnowledgePromo: 'Get Rewarded with Pro Xtra',
  ManageProjectMore: 'Manage Projects more effectively',
  RegisteredTender: 'Easy Purchase Tracking',
  AppAwarenessPromo: 'Manage Member Benefits',
  ProOnlineExperiencePromo: 'Online Business Tools',
};

// Tile Constants
export const TILE_LIMIT = 4;
export const TILE_TYPE = { NOTIFICATION: 'NOTIFICATION', PROMOTION: 'PROMOTION' };

// Analytics
const analyticsPrefix = { welcomeCard: 'homepage: welcome:' };
export const analytics = {
  NOTIFICATION: {
    PREFIX: `${analyticsPrefix.welcomeCard} not:`,
    perkStatus: 'perks status: until your next perk',
    availablePerks: 'available perks: your available perks',
    pricingUnlocked: 'pricing unlocked: getting close to unlocking paint rewards',
  },
  TABLET: {
    PREFIX: `${analyticsPrefix.welcomeCard} tablet:`,
    easyPurchaseTracking: 'easy purchase tracking',
    earnProXtraDollars: 'earn pro xtra dollars',
  },
  FOOTER: {
    PREFIX: `${analyticsPrefix.welcomeCard} nav:`,
    Perks: 'perks',
    Pricing: 'pricing',
    Offer: 'offers',
  },
  ANALYTICS_MESSAGE: {
    // PERKS
    WELL_DONE: 'well done',
    Perks_Expiring_Soon: 'perk expiring',
    New_Perk_Unlocked: 'perk unlocked',
    Perks_Available: 'perk available',
    ProXtra_Credit_Card: 'perk plcc',
    Progress_Until_Next_Perk: 'perk progress',

    // OFFERS:
    Offers_Expiring_Soon: 'offer expiring',
    Offers_Available: 'offer available',
    Preferred_Pricing_Unlocked: 'pref pricing unlocked',
    Paint_Rewards: 'paint rewards',
    Elite_Support_Line: 'elite support line',

    // SKINNY BAR:
    Offer_Challenge: 'skinny challenge',
    Register_Tender: 'skinny register',
    BOTTOM_LINK: 'bottom',
  },
  ANALYTICS_LINKS: {
    PERKSLINK: 'PERKS AND OFFERS',
    VIEW_PERKS: 'view perks',
    CHOOSE_PERK: 'choose perk',
    VIEW_OFFERS: 'view offers',
    LEARN_MORE: 'learn more',
    VIEW_PAINT: 'view paint rewards',
    // SKINNY BAR:
    EARN_PROXTRA: 'earn pro xtra',
    REGISTER_CARD: 'register a card',
    // BOTTOM LINK:
    VIEW_BENEFITS: 'view all benefits',
    LEARN_PROXTRA: 'learn about pro xtra',
    // ELITE:
    CONTACT: 'contact',
  },
};
