import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Row = (props) => {
  const {
    children,
    className,
    forwardedRef,
    ...attributes
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={forwardedRef} className={classNames('grid', className)} {...attributes}>
      {children}
    </div>
  );
};

Row.propTypes = {
  /** Append additional classNames */
  className: PropTypes.string,
  /** Any HTML elements inside the column */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  forwardedRef: PropTypes.shape({})
};

Row.defaultProps = {
  className: null,
  children: null,
  forwardedRef: null
};

export { Row };
