import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Star } from '../private/icons/Star';
import { StarFilled } from '../private/icons/StarFilled';
import useControlled from '../private/hooks/useControlled';
import useForkRef from '../private/hooks/useForkRef';

function getDecimalPrecision(num) {
  const decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

function roundValueToPrecision(value, precision) {
  if (value == null) {
    return value;
  }

  const nearest = Math.round(value / precision) * precision;
  return Number(nearest.toFixed(getDecimalPrecision(precision)));
}

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other} />;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function RatingItem(props) {
  const {
    color,
    emptyIcon,
    filledIcon,
    itemValue,
    labelProps,
    ratingValue,
  } = props;

  const isFilled = itemValue <= ratingValue;

  return (
    <span {...labelProps}>
      <IconContainer
        value={itemValue}
        className={classNames('sui-flex sui-pointer-events-none', {
          'sui-text-inactive': !isFilled,
          'sui-text-success': isFilled && color === 'success',
          'sui-text-danger': isFilled && color === 'danger',
          'sui-text-brand': isFilled && color === 'brand'
        })}
      >
        {emptyIcon && !isFilled ? emptyIcon : filledIcon}
      </IconContainer>
    </span>
  );
}

RatingItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'brand', 'success', 'danger']),
  emptyIcon: PropTypes.node,
  filledIcon: PropTypes.node,
  itemValue: PropTypes.number.isRequired,
  labelProps: PropTypes.object,
  ratingValue: PropTypes.number
};

function defaultLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}`;
}

/**
 * The rating can display any float number with the value prop.
 * Use the `precision` prop to define the minimum increment `value` change allowed.
 *
 * Usage:
 *
 * ```jsx
 * import { Rating } from '@one-thd/sui-atomic-components';
 * ```
 *
 * Related components: [RatingMeter](#ratingmeter)
 */
const Rating = React.forwardRef((props, ref) => {

  const {
    color = 'primary',
    defaultValue = null,
    disabled = false,
    emptyIcon: emptyIconProp,
    getLabelText = defaultLabelText,
    filledIcon: filledIconProp,
    max = 5,
    precision = 1,
    size = 'medium',
    value: valueProp,
    ...other
  } = props;

  const [valueDerived, setValueState] = useControlled({
    controlled: valueProp,
    defaultValue
  });

  const valueRounded = roundValueToPrecision(valueDerived, precision);

  let value = valueRounded;

  const rootRef = React.useRef();
  const handleRef = useForkRef(rootRef, ref);

  const classes = {
    root: classNames('sui-inline-flex sui-gap-2px sui-relative sui-text-primary sui-cursor-pointer sui-pointer-events-none sui-text-left sui-tap-highlight-transparent', {
      'sui-opacity-40': disabled,
      'sui-text-base': size === 'small',
      'sui-text-3xl': size === 'medium',
      'sui-text-6xl': size === 'large'
    }),
    label: 'sui-cursor-[inherit]'
  };

  const filledIcon = filledIconProp && React.isValidElement(filledIconProp) ? (
    React.cloneElement(filledIconProp, {
      shrink: 0,
      size: 'inherit',
      color: 'current'
    })
  ) : (
    <StarFilled shrink={0} size="inherit" color="current" />
  );

  const emptyIcon = emptyIconProp && React.isValidElement(emptyIconProp) ? (
    React.cloneElement(emptyIconProp, {
      shrink: 0,
      size: 'inherit',
      color: 'current'
    })
  ) : (
    <Star shrink={0} size="inherit" color="current" />
  );

  return (
    <span
      ref={handleRef}
      className={classes.root}
      role="img"
      aria-label={getLabelText(value)}
      {...other}
    >
      {Array.from(new Array(max)).map((el, index) => {
        const itemValue = index + 1;

        const ratingItemProps = {
          color,
          emptyIcon,
          filledIcon,
          ratingValue: value,
        };

        if (precision < 1) {
          const items = Array.from(new Array(1 / precision));
          return (
            <span
              key={itemValue}
              className="sui-relative"
            >
              {items.map((el2, indexDecimal) => {
                const itemDecimalValue = roundValueToPrecision(
                  itemValue - 1 + (indexDecimal + 1) * precision,
                  precision,
                );
                return (
                  <RatingItem
                    key={itemDecimalValue}
                    {...ratingItemProps}
                    itemValue={itemDecimalValue}
                    labelProps={{
                      style:
                        items.length - 1 === indexDecimal
                          ? {}
                          : {
                            width: itemDecimalValue === value ? `${(indexDecimal + 1) * precision * 100}%` : '0%',
                            overflow: 'hidden',
                            position: 'absolute',
                          },
                    }}
                  />
                );
              })}
            </span>
          );
        }

        return (
          <RatingItem
            key={itemValue}
            {...ratingItemProps}
            itemValue={itemValue}
          />
        );
      })}
    </span>
  );
});

Rating.displayName = 'Rating';

Rating.propTypes = {
  /**
   * The color to be applied to filled rating icons.
   */
  color: PropTypes.oneOf(['primary', 'brand', 'success', 'danger']),
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * The default value. Use when the component is not controlled.
   * @default null
   */
  defaultValue: PropTypes.number,
  /**
   * The icon to display when empty.
   * @default <Star fontSize="inherit" />
   */
  emptyIcon: PropTypes.node,
  /**
   * Accepts a function which returns a string value that provides a name for the current value of the rating.
   */
  getLabelText: PropTypes.func,
  /**
   * The icon to display.
   * @default <StarFilled fontSize="inherit" />
   */
  filledIcon: PropTypes.node,
  /**
   * Maximum rating.
   * @default 5
   */
  max: PropTypes.number,
  /**
   * The minimum increment value change allowed.
   * @default 1
   */
  precision: PropTypes.number,
  /**
   * The size of the component.
   * @default 'small'
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * The rating value.
   */
  value: PropTypes.number,
};

Rating.defaultProps = {};

export { Rating };