import { getContentfulTargetingIds } from '@thd-olt-component-react/personalization-utils';

export const isReadyUtil = ({ contentfulComponents = {}, combinedLayoutData = {}, customerType }) => {
  const { mboxes, campaignIds, innervateTags } = getContentfulTargetingIds({
    content: contentfulComponents,
  });

  let isReady = combinedLayoutData
    ? mboxes.length > 0 || campaignIds.length > 0 || innervateTags.length > 0
    : false;

  if (customerType === 'b2c-guest') {
    isReady = contentfulComponents
      ? mboxes.length > 0 || campaignIds.length > 0 || innervateTags.length > 0
      : false;
  }

  return {
    mboxes,
    campaignIds,
    innervateTags,
    isReady,
  };
};
