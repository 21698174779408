import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ownerWindow from '../private/utils/ownerWindow';
import useForkRef from '../private/hooks/useForkRef';
import useEnhancedEffect from '../private/hooks/useEnhancedEffect';

function getStyleValue(value) {
  return parseInt(value, 10) || 0;
}

function isEmpty(obj) {
  return (
    obj === undefined
    || obj === null
    || Object.keys(obj).length === 0
    || (obj.outerHeightStyle === 0 && !obj.overflow)
  );
}

const TextArea = React.forwardRef((props, ref) => {
  const {
    className: classNameProp,
    disableResizePastMaxRow = false,
    minRows,
    maxRows,
    placeholder,
    onChange: onChangeProp,
    resize = 'both',
    style: styleProp,
    value,
    ...other
  } = props;

  const inputRef = React.useRef(null);
  const shadowRef = React.useRef(null);
  const handleRef = useForkRef(inputRef, ref);
  const renders = React.useRef(0);
  const [state, setState] = React.useState({
    outerHeightStyle: 0,
  });

  // Shadow and visible textareas need to have similar styles or new line insertion will desync.

  const inputClasses = classNames({
    'sui-resize': resize === 'both',
    'sui-resize-y': resize === 'vertical',
    'sui-resize-x': resize === 'horizontal',
    'sui-resize-none': resize === 'none'
  }, classNameProp);
  const shadowClasses = classNames('sui-invisible sui-absolute sui-h-0 sui-top-0 sui-left-0', inputClasses);

  const getUpdatedState = React.useCallback(() => {
    const input = inputRef.current;

    const containerWindow = ownerWindow(input);
    const computedStyle = containerWindow.getComputedStyle(input);

    if (computedStyle.width === '0px') {
      return {
        outerHeightStyle: 0,
      };
    }

    const inputShallow = shadowRef.current;

    inputShallow.style.width = computedStyle.width;
    inputShallow.value = input.value || placeholder || 'x';
    if (inputShallow.value.slice(-1) === '\n') {
      inputShallow.value += ' ';
    }

    const boxSizing = computedStyle.boxSizing;
    const padding = getStyleValue(computedStyle.paddingBottom) + getStyleValue(computedStyle.paddingTop);
    const border = getStyleValue(computedStyle.borderBottomWidth) + getStyleValue(computedStyle.borderTopWidth);

    // The height of the shadow input's inner content
    const innerHeight = inputShallow.scrollHeight;

    // This obtains the height of a single row
    inputShallow.value = 'x';
    const singleRowHeight = inputShallow.scrollHeight;

    let outerHeight = innerHeight;
    const borderBoxOffset = boxSizing === 'border-box' ? padding + border : 0;

    let minHeight;
    if (minRows) {
      outerHeight = Math.max(Number(minRows) * singleRowHeight, outerHeight);
      minHeight = minRows * singleRowHeight + borderBoxOffset;
    }
    let maxHeight;
    if (maxRows) {
      outerHeight = Math.min(Number(maxRows) * singleRowHeight, outerHeight);
      maxHeight = disableResizePastMaxRow ? (maxRows * singleRowHeight + borderBoxOffset) : undefined;
    }
    outerHeight = Math.max(outerHeight, singleRowHeight);

    // Final size that will be set to the visible text area
    const outerHeightStyle = outerHeight + borderBoxOffset;
    const overflow = Math.abs(outerHeight - innerHeight) <= 1;
    // const maxHeight = (maxRows * singleRowHeight) + (boxSizing === 'border-box' ? padding + border : 0);
    // const minHeight = minRows * singleRowHeight + (boxSizing === 'border-box' ? padding + border : 0);

    return { outerHeightStyle, overflow, maxHeight, minHeight };
  }, [maxRows, minRows, placeholder, disableResizePastMaxRow]);

  const updateState = (prevState, newState) => {
    const { outerHeightStyle, overflow, minHeight, maxHeight } = newState;

    if (
      renders.current < 20
      && ((outerHeightStyle > 0
        && Math.abs((prevState.outerHeightStyle || 0) - outerHeightStyle) > 1)
        || prevState.overflow !== overflow)
    ) {
      renders.current += 1;
      return {
        overflow,
        outerHeightStyle,
        minHeight,
        maxHeight
      };
    }
    // Prevents an infinite rendering loop
    if (renders.current === 20) {
      console.error(
        [
          'Too many re-renders.',
          'TextArea limits the number of renders to prevent an infinite loop.',
        ].join('\n'),
      );
    }
    return prevState;
  };

  const syncHeight = React.useCallback(() => {
    const newState = getUpdatedState();

    if (isEmpty(newState)) {
      return;
    }

    setState((prevState) => {
      return updateState(prevState, newState);
    });
  }, [getUpdatedState]);

  useEnhancedEffect(() => {
    syncHeight();
  });

  useEffect(() => {
    renders.current = 0;
  }, [value]);

  const handleChange = (event) => {
    renders.current = 0;
    syncHeight();

    if (onChangeProp) {
      onChangeProp(event);
    }
  };

  return (
    <>
      <textarea
        className={inputClasses}
        value={value}
        onChange={handleChange}
        ref={handleRef}
        rows={minRows}
        placeholder={placeholder}
        style={{
          height: state.outerHeightStyle,
          overflow: state.overflow ? 'hidden' : undefined,
          maxHeight: state.maxHeight,
          ...styleProp
        }}
        {...other}
      />
      <textarea
        ref={shadowRef}
        className={shadowClasses}
        readOnly
        tabIndex={-1}
        style={{ transform: 'translateZ(0)', overflow: 'hidden', padding: 0 }}
      />
    </>
  );
});

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
  /**
   * @ignore Mainly here to receive styles from a parent component (Such as InputBase).
   */
  className: PropTypes.string,
  /**
   * If `true`, the component's height will not be resizable pastit's maximum height as
   * defined by the `maxRows` prop.
   */
  disableResizePastMaxRow: PropTypes.bool,
  /**
   * Minimum number of rows
   */
  minRows: PropTypes.number,
  /**
   * Maximum number of rows
   */
  maxRows: PropTypes.number,
  /**
   * Adds placeholder into TextArea component.
   */
  placeholder: PropTypes.string,
  /**
   * Adds onChange into TextArea component.
   */
  onChange: PropTypes.func,
  /**
   * Determines which directions the component can be manually resized to.
   */
  resize: PropTypes.oneOf(['horizontal', 'vertical', 'both', 'none']),
  /**
   * Inline styles.
   */
  style: PropTypes.object,
  /**
   * The value of the TextArea component.
   */
  value: PropTypes.string,
};

export { TextArea };