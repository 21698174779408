import React, {
  createContext, useState, useRef, useEffect, useCallback
} from 'react';
import { oneOfType, arrayOf, node, bool } from 'prop-types';
import {
  playVideoNoRestart, pauseVideo, pauseAllVideos
} from '../helpers/videoPlayerHelpers';

export const VideoPlayerContext = createContext();

export const VideoPlayerProvider = ({
  children,
  autoPlayInViewport
}) => {
  const wrapperRef = useRef();
  const observedPlayerIds = useRef([]);

  const defaultObserverOptions = {
    root: wrapperRef.current,
    rootMargin: '0px'
  };

  const observerCallback = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        playVideoNoRestart({ frame: entry.target });
      } else {
        pauseVideo({ frame: entry.target });
      }
    });
  });

  const observerRef = useRef(typeof window !== 'undefined' ? new IntersectionObserver(
    observerCallback,
    {
      ...defaultObserverOptions,
      threshold: 0.95
    }
  ) : null);

  const observe = useCallback((target) => {
    if (!observedPlayerIds.current.includes(target?.id)) {
      observedPlayerIds.current.push(target?.id);
      observerRef.current.observe(target);
    }
  }, [observerRef.current]);

  const unobserve = useCallback((target) => {
    observerRef.current.unobserve(target);
    observedPlayerIds.current = observedPlayerIds.current.filter((playerId) => playerId === target?.id);
  }, [observerRef.current]);

  return (
    <VideoPlayerContext.Provider
      value={{
        observer: {
          observe,
          unobserve
        },
        autoPlayInViewport
      }}
    >
      <div ref={wrapperRef} className="video-player-wrapper">
        {children}
      </div>
    </VideoPlayerContext.Provider>
  );
};

VideoPlayerProvider.propTypes = {
  children: oneOfType([
    node,
    arrayOf(node)
  ]).isRequired,
  autoPlayInViewport: bool
};

VideoPlayerProvider.defaultProps = {
  autoPlayInViewport: false
};