import React from 'react';
import { CardTitle, Link, Typography, Button } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { publishAnalytics } from '../../../../utils';
import { purchaseHistoryURL } from '../../../../constants';

function publishViewAllOrdersClickEvent() {
  publishAnalytics('recentPurchases.view-all-orders-click');
}

export function RecentPurchasesTableHeader() {
  const handleAddReceiptClick = () => {
    publishAnalytics('recentPurchases.add-receipt-click');
  };

  return (
    <CardTitle
      data-testid="recentOrders-header-b2b-table"
      header={(
        <Typography variant="h3" weight="regular">Your Latest Orders</Typography>
      )}
      disableActionSpacing
      action={(
        <div className="sui-flex sui-gap-2">
          <Link
            href={purchaseHistoryURL}
            onClick={handleAddReceiptClick}
          >
            Add Receipt
          </Link>
          <Typography>|</Typography>
          <Link
            onClick={publishViewAllOrdersClickEvent}
            href={purchaseHistoryURL}
            data-testid="rp-view-all-orders"
          >
            View All Orders
          </Link>
        </div>
      )}
    />
  );
}

export const RecentPurchasesCardHeader = () => {
  return (
    <CardTitle
      data-testid="recentOrders-header-b2b-carousel"
      header={(
        <Typography variant="h3" weight="regular">Your Latest Orders</Typography>
      )}
      disableActionSpacing
      actionPosition="center"
      action={(
        <Button
          tabIndex={0}
          onClick={publishViewAllOrdersClickEvent}
          className="sui-flex"
          data-testid="rp-view-all-orders"
          variant="ghost"
          href={purchaseHistoryURL}
        >
          <ArrowForward
            iconSize="regular"
          />
        </Button>
      )}
    />
  );
};
