import * as React from 'react';
import { string, func, elementType } from 'prop-types';
import { PacDrawer } from './PacDrawer/PacDrawer';
import { getImageURL } from '../utils/cardUtils';
import { PacDrawerContext, PacDrawerProvider } from '../../contexts/PacDrawerContext';
import { SelectionDrawer, RewardBarcodeDrawer } from '../../components/LoyaltyDrawers';

export const usePacDrawer = (elementData) => { // TODO: Review at the end of 2024Q2 to see if this is still needed
  const {
    headerStr, titleStr, subTitleStr, iconStr, IconComponent, iconSvgUrl, ContentEle, FooterEle, onCloseFunc,
  } = elementData;
  const header = headerStr; // || 'New Perk Unlocked';
  const title = titleStr; // || 'Congratulations!';
  const subTitle = subTitleStr; // || 'You\'ve reached $20K in spend';
  const iconFullStr = iconStr && (iconStr.includes('http') ? iconStr : getImageURL(iconStr));
  const iconImgUrl = iconStr && iconFullStr; // || getImageURL('ProTiers/cardIcon-PreferredPricing.svg');
  const Content = ContentEle; // || () => <>Text content area</>;
  const Footer = FooterEle; // || () => <Button variant="primary" fullWidth>Close</Button>;

  const { setOpen, open } = React.useContext(PacDrawerContext);

  const onClose = React.useCallback(() => {
    if (setOpen) setOpen(false);
    if (onCloseFunc) onCloseFunc();
  }, []);

  const DrawerComponent = () => (
    <PacDrawer
      headerText={header}
      onClose={onClose}
      iconImgUrl={iconImgUrl}
      iconSvgUrl={iconSvgUrl}
      IconComponent={IconComponent}
      title={title}
      subTitle={subTitle}
      Content={Content}
      Footer={Footer}
    />
  );

  return React.useMemo(() => ({
    DrawerComponent, // full component
    PacDrawer, // reusable component // TODO: Remove after 2024Q2 MVP
    controls: { open, onClose, setOpen: () => setOpen(true) }, // temporary // TODO: Use event bus for prod instead
    context: { PacDrawerProvider, PacDrawerContext, },
    elements: { // individual component parts
      header, title, subTitle, iconImgUrl, Content, Footer,
    },
    atomicComponents: { SelectionDrawer, RewardBarcodeDrawer },
  }), [open]);

};

const elementDataType = {
  headerStr: string,
  titleStr: string,
  subTitleStr: string,
  iconStr: string,
  ContentEle: elementType,
  FooterEle: elementType,
  onCloseFunc: func,
};

usePacDrawer.propTypes = {
  elementData: elementDataType,
};
usePacDrawer.defaultProps = {
  elementData: {},
};
