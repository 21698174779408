import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../typography/Typography';
import useTile from '../private/components/tile/useTile';

/**
 * A wrapper around `Typography` that renders a line-through decoration on disabled tiles.
 *
 * All of the `Typography` props are also available.
 *
 * Related components: [Tile](#tile), [TileContent](#tilecontent), [TileMedia](#tilemedia), [Typography](#typography).
 *
 * Usage:
 *
 * ```jsx
 * import { TileText } from '@one-thd/sui-atomic-components';
 * ```
 *
 */
const TileText = React.forwardRef((props, ref) => {
  const {
    children,
    height = 'tight',
    decoration: decorationProp,
    ...other
  } = props;

  const { disabled } = useTile();
  const decoration = disabled ? 'line-through' : decorationProp;
  const color = disabled ? 'inactive' : 'primary';

  return (
    <Typography
      ref={ref}
      height={height}
      decoration={decoration}
      color={color}
      {...other}
    >
      {children}
    </Typography>
  );

});

TileText.displayName = 'TileText';

TileText.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Applies the line height styles.
   */
  height: PropTypes.oneOf([
    'none',
    'tight',
    'snug',
    'normal',
    'loose'
  ]),
  /**
   * Applies the text decoration styles.
   */
  decoration: PropTypes.oneOf([
    'line-through',
    'underline'
  ]),
};

export { TileText };
