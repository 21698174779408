import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import helpers from '../../../helpers/price-helper';

export const Basic = ({ price }) => {

  const formattedPrice = helpers.getUSDollarWithCommas(price);
  return (
    <Typography variant="body-lg" weight="bold" data-automation-id="pricingScenariosTotalPriceAddedText">
      {formattedPrice}
    </Typography>
  );
};

Basic.propTypes = {
  price: PropTypes.number,
};

Basic.defaultProps = {
  price: 0,
};

Basic.displayName = 'Basic';
