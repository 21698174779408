import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Thermostat = createSvgIcon(
  <>
    <path d="M17.41 1.286A11.95 11.95 0 0 0 12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12a11.95 11.95 0 0 0-1.286-5.41l-1.696 1.696A9.722 9.722 0 0 1 21.75 12c0 5.385-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25c1.315 0 2.57.26 3.714.732l1.697-1.696Z" />
    <path d="M12.498 8.25H6v2.25h3.252l-2.075 5.533 2.106.79 3.215-8.573ZM16.5 16.5V8.25h-2.25v8.25h2.25Zm2.625-10.034 4.591-4.591L22.125.284l-4.591 4.591 1.591 1.591Z" />
  </>,
  'Thermostat'
);

export { Thermostat };