/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
export const getCookie = (name) => {
  try {
    return decodeURIComponent(
      ('; ' + document.cookie)
        .split('; ' + name + '=')
        .pop()
        .split(';')
        .shift()
    );
  } catch (err) {
    console.error(err);
  }
  return '';
};

export const wait = (time = 100, message = '', preserve = true) => new Promise((resolve) => {
  if (!preserve) {
    time /= 10;
  }
  // console.log(`::: WAIT ::: ${message}`, time);
  setTimeout(resolve, time);
});

export function addNewRelicPageAction(actionName, actionObject) {
  try {
    if (window.newrelic && typeof window.newrelic.addPageAction === 'function') {
      window.newrelic.addPageAction(actionName, actionObject);
    }
  } catch (err) {
    console.log(err);
  }
}

export function getLegacyCartVersion(state) {
  let version = 'unknown';
  try {
    if (!state) {
      state = window?.cartStore.getState();
    }
    version = state.featureSwitch.MYCART_UI_VERSION || version;
  } catch (err) {
    console.error(err);
  }
  return version;
}

export function optionsOverride(options) {
  try {
    let _options = localStorage.getItem('__COMPARATOR_OPTION_OVERRIDE');
    if (_options) {
      _options = JSON.parse(_options);
      options = { ...options, ..._options };
    }
  } catch (err) {
    console.error(err);
  }
  return options;
}

export const waitForResult = (waitForFunc, intervalTime = 1000, intervalMessage = '') => {
  let handle = null;
  return new Promise((resolve) => {
    handle = setInterval(() => {
      try {
        let result = waitForFunc();
        if (result) {
          clearInterval(handle);
          resolve(result);
        } else if (intervalMessage) {
          console.log(intervalMessage);
        }
      } catch (err) {
        console.error(err);
        clearInterval(handle);
      }
    }, intervalTime);
  });
};