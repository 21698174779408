import React from 'react';
import {
  shape, string, arrayOf, func, bool
} from 'prop-types';
import classNames from 'classnames/bind';

import { Image } from '@thd-olt-component-react/core-ui';

import { noop } from '../../utils/utils';
import styles from './category-tab.module.scss';

const cNames = classNames.bind(styles);

const CategoryTab = ({ isSelected, category, onClick }) => {
  const categoryTabClassNames = cNames('category-tab', {
    'category-tab--selected': isSelected,
  });

  return (
    <button
      className={categoryTabClassNames}
      onClick={onClick}
      type="button"
    >
      <Image
        className={cNames('category-tab__image')}
        src={category.image.url}
        alt=""
      />
      <span className={cNames('category-tab__text')}>
        {category.name}&nbsp;
      </span>
      <span>({category.itemIds.length})</span>
    </button>
  );
};

CategoryTab.displayName = 'CategoryTab';

CategoryTab.propTypes = {
  isSelected: bool,
  category: shape({
    name: string.isRequired,
    itemIds: arrayOf(string.isRequired).isRequired,
    categoryId: string.isRequired,
    image: shape({
      url: string.isRequired
    }).isRequired
  }).isRequired,
  onClick: func,
};

CategoryTab.defaultProps = {
  isSelected: false,
  onClick: noop,
};

export { CategoryTab };
