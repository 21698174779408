import React from 'react';
import { SvgIcon } from '../private/SvgIcon';

function createSvgIcon(path, displayName) {
  const Component = (props, ref) => (
    <SvgIcon data-testid={`${displayName}Icon`} ref={ref} {...props}>
      {path}
    </SvgIcon>
  );

  Component.displayName = displayName;
  return React.forwardRef(Component);
}

export default createSvgIcon;