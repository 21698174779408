/* eslint-disable max-len */
import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import {
  any,
  bool as boolType,
  string as stringType
} from 'prop-types';
import VisualTabs from './VisualTabs';

const buildIRGTitle = (brandname, tntTitle) => {
  return tntTitle || ((brandname !== null) ? ('More from ' + brandname) : 'More from This Brand');
};

const buildAccessoriesTitle = (tntTitle) => {
  return tntTitle || 'You Might Also Need';
};

const IRGUtils = (props) => {
  const {
    products, categories, isCategoryTab, isIRG, pullFilteredData, tntTitle
  } = props;

  return (
    <>
      {isCategoryTab ? (
        <div className="sui-py-4">
          <Typography variant="h2">{buildIRGTitle(products[0]?.product?.identifiers?.brandName, tntTitle)}</Typography>
        </div>
      ) : (
        <div className="sui-py-4">
          <Typography variant="h2">{buildAccessoriesTitle(tntTitle)}</Typography>
        </div>
      )}
      {isCategoryTab && categories?.length > 1 && (
        <div className={classNames('sui-grid, sui-grid-cols-1, sui-pb-4')}>
          <VisualTabs products={products} isIRG={isIRG} categories={categories} filterItemIds={pullFilteredData} />
        </div>
      )}
    </>
  );
};

IRGUtils.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  products: any,
  // eslint-disable-next-line react/forbid-prop-types
  categories: any,
  isCategoryTab: boolType,
  isIRG: boolType,
  // eslint-disable-next-line react/forbid-prop-types
  pullFilteredData: any,
  tntTitle: stringType
};

IRGUtils.defaultProps = {
  products: [],
  categories: [],
  isCategoryTab: false,
  isIRG: true,
  pullFilteredData: '',
  tntTitle: ''
};

export { IRGUtils };
