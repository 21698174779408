import React from 'react';
import {
  arrayOf,
  bool,
  number,
  shape,
  string
} from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';

import { isOnlineOrder } from '../../../recentPurchases/components/helpers';
import { CardOrder } from './CardOrder';

export function RecentOrdersCard({
  orderHistoryData,
  customerInfo,
  windowWidth,
  hiddenOrders,
  removeInStoreOrders
}) {

  const filteredOrders = orderHistoryData?.purchaseHistory?.orders?.filter((order) => {
    if (order.orderNumbers) {
      if (removeInStoreOrders) {
        return !hiddenOrders.includes(order.orderNumbers[0])
          && isOnlineOrder(order.orderNumbers[0]);
      }
      return !hiddenOrders.includes(order.orderNumbers[0]);
    }
    return false;
  });

  const maxOrders = filteredOrders?.length > 20
    ? 20
    : filteredOrders?.length;
  const orders = filteredOrders?.slice(0, maxOrders);

  let cardContent = orders?.map((order, index) => (
    <QueryProvider key={order.receiptDetails} cacheKey={`carousel-card-${index}`}>
      <CardOrder
        customerInfo={customerInfo}
        index={index}
        order={order}
        windowWidth={windowWidth}
      />
    </QueryProvider>
  ));

  const breakpoints = {
    sm: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    md: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    lg: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    xl: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 32
    }
  };

  return (
    <div data-testid="recentOrders-content">
      <Carousel breakpoints={breakpoints} disableMargin disableShadow slidesPerView={1}>
        {cardContent}
      </Carousel>
    </div>
  );
}

RecentOrdersCard.propTypes = {
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  hiddenOrders: arrayOf(string),
  orderHistoryData: shape({
    purchaseHistory: shape({
      customerAccountId: string,
      orderCount: number,
      orders: arrayOf(
        shape({
          salesDate: string,
          orderNumbers: arrayOf(string),
          receiptDetails: string,
        })
      ),
    }),
  }),
  removeInStoreOrders: bool,
  windowWidth: number
};

RecentOrdersCard.defaultProps = {
  customerInfo: {},
  hiddenOrders: [],
  orderHistoryData: null,
  removeInStoreOrders: false,
  windowWidth: undefined
};
