import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import {
  getErrorMessage,
  getHeader,
  getPlaceholder,
  getValidator,
  STANDARD_TYPES
} from '../utils';
import './Input.style.scss';

class Input extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      validationClass: '',
      value
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value && value === '') {
      this.clearText();
    }
  }

  clearText = () => {
    this.setState({
      value: '',
      validationClass: ''
    });
  }

  performValidation = (inputText) => {
    const {
      inputType,
      validation,
      onValidate,
      onValidationError,
      required
    } = this.props;

    const validator = getValidator(inputType, validation);

    let inputClass = '';
    if (typeof validator === 'function') {
      if (validator(inputText)) {
        inputClass = 'form-input--success';
        if (onValidate) onValidate();
      } else {
        if (inputText || required) {
          inputClass = 'form-input--error';
        }
        if (onValidationError) onValidationError();
      }
    }

    this.setState({
      validationClass: inputClass
    });
  };

  onInputTextChange = (event) => {
    const { onChange, validateOnBlur } = this.props;
    const inputText = event.target.value;
    if (onChange) {
      onChange(inputText);
    } else {
      this.setState({ value: inputText });
    }

    if (!validateOnBlur) {
      this.performValidation(inputText);
    }
  };

  onInputTextBlur = (event) => {
    const { onBlur, validateOnBlur } = this.props;
    const inputText = event.target.value;
    if (onBlur) {
      onBlur(inputText);
    }

    if (validateOnBlur) {
      this.performValidation(inputText);
    }
  };

  onInputTextFocus = (event) => {
    const { onFocus, scrollOnFocus, validateOnBlur } = this.props;
    if (onFocus) {
      onFocus(event.target);
    }
    if (validateOnBlur) {
      this.setState({ validationClass: '' });
    }
    if (scrollOnFocus) {
      event.target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
      event.target.focus();
    }
  };

  render() {
    const {
      autoFocus,
      className,
      cols,
      defaultValue,
      disabled,
      errorText,
      header,
      inputType,
      maxLength,
      name,
      onChange,
      onKeyPress,
      pattern,
      placeholder,
      rows,
      type,
      mask,
      value: propValue,
      validationTextClassName
    } = this.props;

    const {
      validationClass,
      value: stateValue
    } = this.state;

    const errorMessage = getErrorMessage(inputType, errorText);
    const placeholderMessage = getPlaceholder(inputType, placeholder);
    const headerMessage = getHeader(inputType, header);

    const additionalProps = {};
    if (maxLength) additionalProps.maxLength = maxLength;
    if (pattern) additionalProps.pattern = pattern;
    if (disabled) additionalProps.disabled = 'disabled';

    const value = onChange ? propValue : stateValue;
    if (typeof defaultValue !== 'undefined') {
      additionalProps.defaultValue = defaultValue;
    } else { // value has an empty string default prop value
      additionalProps.value = value;
    }

    if (type === 'textarea') {
      additionalProps.rows = rows;
      additionalProps.cols = cols;
    }

    const Tag = type === 'textarea' ? 'textarea' : 'input';

    const formInputClasses = classNames(
      'form-input',
      validationClass
    );

    const inputFieldClasses = classNames(
      'form-input__field',
      className
    );

    const validationMessageClasses = classNames(
      'form-input-error__message',
      validationTextClassName
    );

    const formattedInput = () => (
      <Tag
        onKeyPress={onKeyPress}
        onChange={this.onInputTextChange}
        onFocus={this.onInputTextFocus}
        onBlur={this.onInputTextBlur}
        {...additionalProps} // eslint-disable-line
        className={inputFieldClasses}
        name={name}
        placeholder={placeholderMessage}
        type={type}
        autoFocus={autoFocus}
        mask={mask}
      />
    );

    const applyMask = () => (
      <InputMask
        mask={mask}
        maskChar={null}
        onChange={this.onInputTextChange}
        onFocus={this.onInputTextFocus}
        onBlur={this.onInputTextBlur}
        value={additionalProps.value}
      >
        {formattedInput}
      </InputMask>
    );

    return (
      <span className={formInputClasses}>
        <span className="form-input__label-content">{headerMessage}</span>
        {mask ? applyMask() : formattedInput()}
        <span className={validationMessageClasses}>
          {validationClass === 'form-input--error' ? errorMessage : ''}
        </span>
      </span>
    );
  }
}

Input.propTypes = {
  /** Additional classes to add */
  className: PropTypes.string,
  /** Error text displayed below input box */
  errorText: PropTypes.string,
  /** Header text displayed above input box */
  header: PropTypes.string,
  /** disables the input field */
  disabled: PropTypes.bool,
  /** Placeholder text for input box */
  placeholder: PropTypes.string,
  /** Text value for input box */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** React defaultValue prop. Sets the initial value only */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** maxlength for input box */
  maxLength: PropTypes.string,
  /** html name element */
  name: PropTypes.string,
  /** Validation function to determine success or error */
  validation: PropTypes.func,
  /** onChange function to return the input value */
  onChange: PropTypes.func,
  /** onFocus function to have focus set into the input */
  onFocus: PropTypes.func,
  /** onBlur function to have blur set into the input */
  onBlur: PropTypes.func,
  /**
   * onKeyPress function to have keyPress set into the input
   * WARNING: use onKeyPress only if onChange prop is not triggered for your keyPress requirement.
   * */
  onKeyPress: PropTypes.func,
  /** onValidate function to be called when validation is successful */
  onValidate: PropTypes.func,
  /** onValidate function to be called when validation fails */
  onValidationError: PropTypes.func,
  pattern: PropTypes.string,
  type: PropTypes.oneOf(['text', 'textarea']),
  rows: PropTypes.string,
  cols: PropTypes.string,
  mask: PropTypes.string,
  autoFocus: PropTypes.bool,
  scrollOnFocus: PropTypes.bool,
  inputType: PropTypes.oneOf(STANDARD_TYPES),
  validateOnBlur: PropTypes.bool,
  validationTextClassName: PropTypes.string,
  required: PropTypes.bool
};

Input.defaultProps = {
  className: null,
  errorText: null,
  header: null,
  disabled: false,
  placeholder: null,
  value: '',
  defaultValue: undefined,
  maxLength: null,
  name: '',
  validation: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyPress: null,
  onValidate: null,
  onValidationError: null,
  pattern: null,
  type: 'text',
  rows: '0',
  cols: '0',
  mask: undefined,
  autoFocus: false,
  scrollOnFocus: false,
  inputType: null,
  validateOnBlur: false,
  validationTextClassName: null,
  required: false
};

export default Input;
