import React from 'react';
import { arrayOf, func, string, shape } from 'prop-types';
import { CardMedia, Link } from '~/@one-thd/sui-atomic-components';

export const Thumbnails = ({ url, thumbnails }) => {

  const isEmptyList = thumbnails?.length === 0;

  const EmptyList = () => {
    return (
      [1, 2, 3].map((number, index) => {
        return (
          <div
            // eslint-disable-next-line tailwindcss/no-arbitrary-value
            className="sui-w-full"
            key={`list-tile-empty-thumbnail-${number}`}
          >
            <div className="sui-w-full sui-bg-strong sui-aspect-square" />
          </div>
        );
      })
    );
  };

  return (
    <Link
      className="sui-w-full sui-h-full"
      href={url}
      data-testid="image-list-link"
    >
      <div className="sui-grid sui-grid-cols-3 sui-gap-2 sui-h-full">
        {!isEmptyList ? (
          [1, 2, 3].map((thumbnailNumber, thumbnailIndex) => {
            const thumbnailUrl = thumbnails?.length >= thumbnailIndex && thumbnails?.[thumbnailIndex]?.url;
            return (
              <div key={`list-tile-thumbnail-${thumbnailNumber}`}>
                {thumbnailUrl ? (
                  <CardMedia className="sui-w-full sui-aspect-square" src={thumbnailUrl} alt="Product Image" />
                ) : (
                  <div className="sui-w-full sui-bg-strong sui-aspect-square" />
                )}
              </div>
            );
          })
        ) : (
          <EmptyList />
        )}
      </div>
    </Link>
  );
};

Thumbnails.displayName = 'ListTileThumbnails';

Thumbnails.propTypes = {
  url: string.isRequired,
  thumbnails: arrayOf(shape({
    url: string
  })),
};

Thumbnails.defaultProps = {
  thumbnails: []
};