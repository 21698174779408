import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControllerContext from './FormControllerContext';

/**
 * `FormController` component provides its children with context values for the following props:
 *
 * - required
 * - status
 * - disabled
 * - fullWidth
 *
 * Sub-components can access these values using the `useFormController` hook.
 *
 * Related components: [TextField](#textfield), [QuantityPicker](#quantitypicker), [DropdownField](#dropdownfield),
 * [FormHelpMessage](#formhelpmessage), [FormLabel](#formlabel), [FormStatusMessage](#formstatusmessage),
 * [Input](#input)
 *
 * Usage:
 *
 * ```jsx
 * import { FormController } from '@one-thd/sui-atomic-components';
 * ```
 */
const FormController = React.forwardRef((props, ref) => {

  const {
    children,
    component: FormControllerRoot = 'div',
    disabled,
    required,
    fullWidth,
    status,
    ...other
  } = props;

  const classes = classNames('sui-inline-flex sui-flex-col sui-relative sui-min-w-0 sui-p-0 sui-m-0 sui-align-top', {
    'sui-w-full': fullWidth
  });

  return (
    <FormControllerContext.Provider
      value={{
        required,
        status,
        disabled,
        fullWidth
      }}
    >
      <FormControllerRoot
        className={classes}
        ref={ref}
        {...other}
      >
        {children}
      </FormControllerRoot>
    </FormControllerContext.Provider>
  );
});

FormController.displayName = 'FormController';

FormController.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the form label, input, status, and helper components should be displayed in a disabled state.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the component will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * If `true`, the form label will indicate that the `input` is required.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * The status stats to display.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning'])
};

export { FormController };
