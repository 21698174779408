/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, shape, useDataModel
} from '@thd-nucleus/data-sources';

export const ProductInfo = ({ itemId }) => {
  const { data } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: !itemId
  });
  const { product } = data || {};
  if (!product) {
    return null;
  }

  const image = product?.media?.images?.find(
    (img) => img.type === 'IMAGE' && img.subType === 'PRIMARY'
  );

  return (
    <div className="sui-flex sui-flex-row">
      <img
        src={image?.url.replace('<SIZE>', '145')}
        alt={product?.identifiers?.productLabel}
        className="sui-aspect-square sui-max-w-[20%] sm:sui-max-w-[30%]"
        width="145px"
        height="145px"
      />
      <div className="sui-pl-6 sui-text-xl">
        <strong>{product?.identifiers?.brandName}&nbsp;</strong>{product?.identifiers?.productLabel}
      </div>
    </div>
  );
};

ProductInfo.propTypes = {
  itemId: PropTypes.string
};

ProductInfo.defaultProps = {
  itemId: null
};

ProductInfo.displayName = 'ProductInfo';

ProductInfo.dataModel = {
  product: params({ itemId: string().isRequired(), dataSource: string() }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      brandName: string(),
      productLabel: string()
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string()
      }))
    }),
  })
};
