import React, { useEffect, useState } from 'react';
import { func, number, shape, string } from 'prop-types';
import { Pagination } from '@thd-olt-component-react/pagination';
import {
  useDataModel, params, string as stringType, shape as shapeType, arrayOf
} from '@thd-nucleus/data-sources';
import * as analytics from '../../ratings-reviews-analytics';
import { usePresentation } from '../../context/PresentationProvider';

export const ReviewPager = ({
  itemId, onPageChanged, reviewsPageContext, storeId, category, totalReviewPages
}) => {
  const [pageContext, setPageContext] = useState(reviewsPageContext);
  const { useCondensedLayout } = usePresentation();
  let seoLink;
  const options = {
    variables: {
      storeSearchInput: storeId,
      pagesize: '1'
    },
    skip: category === 'product'
  };

  useEffect(() => {
    onPageChanged(pageContext);
  }, [pageContext]);

  let productResponse = useDataModel('product', { variables: { itemId }, skip: category === 'store' });
  let storeResponse = useDataModel('storeSearch', options);

  const isLoading = productResponse?.loading || storeResponse?.loading;

  if (isLoading) {
    return null;
  }

  let seoProductLink = category === 'product'
    && productResponse?.data?.product?.identifiers?.canonicalUrl?.replace('/p', '/p/reviews');

  let storeDetailsPageLink = storeResponse?.data?.storeSearch?.stores?.[0]?.storeDetailsPageLink;

  let seoStoreLink = category === 'store'
    && storeDetailsPageLink
    && storeDetailsPageLink + '/reviews';

  seoLink = seoProductLink || seoStoreLink;

  if (!seoLink) {
    return null;
  }

  const trackPaginationEvent = () => {
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'pagination',
      eventType: 'click'
    });
  };

  const { totalPages = 1, currentPage = 1, pageReviewLimit = 3 } = pageContext || {};
  const maxPages = 50;

  return (
    <Pagination
      channel={useCondensedLayout ? 'mobile' : 'desktop'}
      totalPages={totalPages > maxPages ? maxPages : totalPages}
      totalReviewPages={totalReviewPages}
      pageReviewLimit={pageReviewLimit}
      showArrows
      currentPage={currentPage}
      onPageChanged={(newPage) => {
        setPageContext({ ...pageContext, currentPage: newPage });
        trackPaginationEvent();
      }}
      href="?"
      noQueryParams
      queryHandler={(name, value) => {
        return {
          name: '',
          value: `${seoLink}/${value}`
        };
      }}
    />
  );
};

ReviewPager.displayNames = 'ReviewPager';

ReviewPager.propTypes = {
  itemId: string,
  storeId: string,
  onPageChanged: func.isRequired,
  category: string,
  totalReviewPages: number,
  reviewsPageContext: shape({
    totalPages: number,
    currentPage: number,
    pageNeighbours: number
  }).isRequired
};

ReviewPager.defaultProps = {
  itemId: null,
  category: '',
  storeId: null,
  totalReviewPages: null
};

ReviewPager.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    identifiers: shapeType({
      canonicalUrl: stringType()
    })
  }),
  storeSearch: params({
    storeSearchInput: stringType().isRequired(),
    pagesize: stringType().isRequired()
  }).shape({
    stores: arrayOf(shapeType({
      storeDetailsPageLink: stringType()
    }))
  })
};
