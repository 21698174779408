import styles from '../components/video-player.module.scss';

export const PlayerTypes = {
  S3: 's3',
  YouTube: 'youtube',
  BrightCove: 'brightcove'
};
// Youtube API Helpers
// Docs: https://developers.google.com/youtube/iframe_api_reference
export const youTubePlayers = {};
let equalizedVolume = 100;
let equalizedMute = true;

export const createYouTubePlayer = ({ id, analyticsData, muted }) => {
  let played = false;
  let completed = false;

  const player = new window.YT.Player(id, {
    events: {
      onReady: (event) => {
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('youTubePlayer.ready', { id, player: event.target });
        if (muted) {
          event.target.mute();
        }
        equalizedMute = muted;
      },
      onStateChange: (event) => {
        if (event.data > -1 && event.data < 3) { // ended, playing, paused
          if (equalizedVolume !== event.target.getVolume() || equalizedMute !== event.target.isMuted()) {
            equalizedVolume = event.target.getVolume();
            equalizedMute = event.target.isMuted();
            window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('video-player.equalize-volume', {
              id,
              volume: equalizedVolume,
              mute: equalizedMute
            });
          }

          const { title: videoName = '', video_id: videoId = '' } = event.target.playerInfo?.videoData || {};
          if (!played && event.data === 1) { // playing
            played = true;
            window.LIFE_CYCLE_EVENT_BUS.trigger('video-player.start', {
              ...(analyticsData || {}),
              videoName,
              videoId
            });
          } else if (!completed && event.data === 0) { // ended
            completed = true;
            window.LIFE_CYCLE_EVENT_BUS.trigger('video-player.complete', {
              ...(analyticsData || {}),
              videoName,
              videoId
            });
          }
        }
      },
    },
  });
  return player;
};

export const initializeYouTubePlayers = ({ analyticsData, muted, id }) => {
  if (!window.onYouTubeIframeAPIReady) {
    window.onYouTubeIframeAPIReady = function () {
      const frames = Array(...document.getElementsByTagName('iframe')).filter(
        (frame) => frame.id && frame.id.includes('thd-youtube-iframe')
      );

      frames.forEach((frame) => {
        if (!youTubePlayers[frame.id]) {
          youTubePlayers[frame.id] = createYouTubePlayer({ id: frame.id, analyticsData, muted });
        }
      });
    };
  }

  document.head.append(
    Object.assign(document.createElement('script'), {
      src: 'https://www.youtube.com/iframe_api',
      id: `${id}_script`,
      onload: /* istanbul ignore next */
      () => {
        const youTubeOnloadInterval = setInterval(() => {
          if (typeof window.YT?.Player !== 'undefined') {
            clearInterval(youTubeOnloadInterval);
            window.onYouTubeIframeAPIReady();
          }
        }, 10);
      }
    })
  );
};

export function destroyYouTubePlayer({ id }) {
  if (youTubePlayers[id]) {
    youTubePlayers[id].destroy();
    delete youTubePlayers[id];
    document.querySelector(`[id="${id}_script"]`).remove();
  }
}

/* Brightcove API Helpers - see the video-player README for any questions */
let brightCovePostMessageInitialized = false;
export function triggerBrightCoveLifeCycleEvents(evt, analyticsData) {
  if (evt.data && typeof evt.data === 'object') {
    if (evt.data.triggerName === 'brightCoveReady') {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('brightCovePlayer.ready', { id: evt.data.uniqueId });
    } else if (evt.data.triggerName === 'brightCoveVolumeChange') {
      if (equalizedVolume !== evt.data.volume || equalizedMute !== evt.data.mute) {
        equalizedVolume = evt.data.volume;
        equalizedMute = evt.data.mute;
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('video-player.equalize-volume', {
          id: evt.data.uniqueId,
          volume: equalizedVolume,
          mute: equalizedMute
        });
      }
    } else if (evt.data.triggerName === 'brightCovePlayed') {
      window.LIFE_CYCLE_EVENT_BUS.trigger('video-player.start', {
        ...(analyticsData || {}),
        videoName: evt.data.name,
        videoId: evt.data.id,
      });
    } else if (evt.data.triggerName === 'brightCoveCompleted') {
      window.LIFE_CYCLE_EVENT_BUS.trigger('video-player.complete', {
        ...(analyticsData || {}),
        videoName: evt.data.name,
        videoId: evt.data.id
      });
    }
  }
}

export const initializeBrightCovePlayer = ({ analyticsData, muted }) => {
  if (!brightCovePostMessageInitialized) {
    equalizedMute = muted;
    // Listen for the message, then call triggerBrightCoveLifeCycleEvents() method when received
    window.addEventListener('message', /* istanbul ignore next */(evt) => {
      triggerBrightCoveLifeCycleEvents(evt, analyticsData);
    });
    brightCovePostMessageInitialized = true;
  }
};

// Mixed Helpers
const performActionWhenPlayerIsReady = ({ frame, ytCallback, bcCallback, s3Callback = null }) => {
  if (frame) {
    if (frame.id.includes('youtube')) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('youTubePlayer.ready', ({ output }) => {
        if (output.id === frame.id) {
          ytCallback({ output });
        }
      });
    } else if (frame.id.includes('thd-s3-video-')) {
      if (s3Callback) {
        s3Callback();
      }
    } else {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('brightCovePlayer.ready', ({ output }) => {
        if (output.id === frame.id) {
          bcCallback();
        }
      });
    }
  }
};

const callBrightCoveFunction = ({ frame, props }) => {
  frame.contentWindow.postMessage(props, 'https://players.brightcove.net');
};

export const playVideoNoRestart = ({ frame }) => {
  performActionWhenPlayerIsReady({
    frame,
    ytCallback: ({ output }) => {
      if (output.player.getPlayerState() !== 0) { // 0 === ended
        output.player.playVideo();
      }
    },
    bcCallback: () => callBrightCoveFunction({ frame, props: { functionName: 'playVideoNoRestart' } }),
    s3Callback: () => {
      frame.play();
    }
  });
};

export const pauseVideo = ({ frame }) => {
  performActionWhenPlayerIsReady({
    frame,
    ytCallback: ({ output }) => { output.player.pauseVideo(); },
    bcCallback: () => callBrightCoveFunction({ frame, props: { functionName: 'pauseVideo' } }),
    s3Callback: () => {
      frame.pause();
    }
  });
};

export const toggleMuteOnVideo = ({ frame, mute }) => {
  performActionWhenPlayerIsReady({
    frame,
    mute,
    ytCallback: ({ output }) => {
      if (mute) {
        output.player.mute();
      } else {
        output.player.unMute();
      }
    },
    bcCallback: () => callBrightCoveFunction({ frame, props: { functionName: 'setMute', mute } }),
    s3Callback: () => {
      // eslint-disable-next-line no-param-reassign
      frame.muted = mute;
    }
  });
};

export const setVolume = ({ frame, volume }) => {
  performActionWhenPlayerIsReady({
    frame,
    ytCallback: ({ output }) => { output.player.setVolume(volume); },
    bcCallback: () => callBrightCoveFunction({ frame, props: { functionName: 'setVolume', volume } })
  });
};

// external helpers
export const pauseAllVideos = () => {
  /* eslint-disable-next-line */
  const videoPlayerIFrames = document.querySelectorAll('iframe[id^="thd-brightcove-iframe-"],iframe[id^="thd-youtube-iframe-"],video[id^="thd-s3-video-"]');
  videoPlayerIFrames.forEach((videoPlayerIFrame) => {
    pauseVideo({ frame: videoPlayerIFrame });
  });
};

export const getPlayerType = ({ videoSrc, source, videoId }) => {
  if (source === 'CONFIGURABLE_S3') {
    return PlayerTypes.S3;
  }

  if (videoSrc?.includes('youtube')) {
    return PlayerTypes.YouTube;
  }

  if (videoId) {
    return PlayerTypes.BrightCove;
  }
  return null;
};