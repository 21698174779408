/* eslint-disable no-console */

export const reloadPage = () => {
  try {
    // TODO: allow analytics (and newrelic) to be suppressed when running
    // automation, development, unit-tests etc.
    if (typeof window !== 'undefined') {
      if (window.top) {
        window.top.location.reload();
      } else {
        window.location.reload();
      }
    }
  } catch (err) {
    console.error(err);
  }
};
