import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CardNumber = createSvgIcon(
  <>
    <path d="M8.25 11.25V7.5H4.5v3.75h3.75Z" />
    <path d="M1.125 3C.504 3 0 3.504 0 4.125v15.75C0 20.496.504 21 1.125 21h21.75c.621 0 1.125-.504 1.125-1.125V4.125C24 3.504 23.496 3 22.875 3H1.125ZM2.25 18.75V5.25h19.5v13.5H2.25Z" />
  </>,
  'CardNumber'
);

export { CardNumber };