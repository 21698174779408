import React from 'react';
import { element, shape } from 'prop-types';
import { HomepageContext } from './HomepageContext';
import { useWindowWidth } from '../../hooks';

export function HomepageProvider({ value = {}, children }) {
  const windowWidth = useWindowWidth();

  return (
    <HomepageContext.Provider value={{ ...value, windowWidth }}>
      {children}
    </HomepageContext.Provider>
  );
}

HomepageProvider.propTypes = {
  value: shape({}),
  children: element,
};

HomepageProvider.defaultProps = {
  value: {},
  children: undefined,
};