import * as React from 'react';
import * as PropTypes from 'prop-types';

const TextRow = (props) => {
  const {
    className, maxHeight, color, lineSpacing, style
  } = props;

  const defaultStyles = {
    maxHeight,
    width: '100%',
    height: '1em',
    backgroundColor: color,
    marginTop: lineSpacing
  };

  const classes = ['text-row', className].filter((clss) => clss).join(' ');

  return (
    <div
      className={classes}
      style={{ ...defaultStyles, ...style }}
    />
  );
};

TextRow.propTypes = {
  maxHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  lineSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

TextRow.defaultProps = {
  lineSpacing: '0.7em',
  className: '',
  style: {},
  maxHeight: ''
};

export { TextRow };
