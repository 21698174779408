import { Model } from './Model';

class ReviewSentimentModel extends Model {

  get sentiments() {
    return this.getNestedProperties(['Results'], []);
  }

  get proSentiments() {
    return this.getNestedProperties(['Results'], []).filter((sentiment) => sentiment.SentimentType === 1);
  }

  get conSentiments() {
    return this.getNestedProperties(['Results'], []).filter((sentiment) => sentiment.SentimentType === 0);
  }

  getReviewIdsBySentimentFeature(sentimentFeature) {
    const filteredSentiment = this.getNestedProperties(['Results'], [])
      .filter((sentiment) => sentiment.Feature === sentimentFeature);
    return filteredSentiment.length > 0 ? filteredSentiment[0].ReviewIds || [] : [];
  }

}

export { ReviewSentimentModel };
