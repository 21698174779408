import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Basic } from './Basic';

export const BasicPrice = ({ totalPrice, align }) => {
  const basicClassName = classNames({ 'sui-text-right': align === 'end' });
  return (
    <div className={basicClassName} data-automation-id="pricingScenariosText">
      <Basic price={totalPrice} />
    </div>
  );
};

BasicPrice.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  align: PropTypes.string
};

BasicPrice.defaultProps = {
  align: ''
};

BasicPrice.displayName = 'BasicPrice';
