export function areEqualValues(first, second) {
  if (typeof second === 'object' && second !== null) {
    return first === second;
  }
  return String(first) === String(second);
}

export function createChainedFunction() {
  const args = [].slice.call(arguments, 0);
  if (args.length === 1) {
    return args[0];
  }
  return function chainedFunction() {
    for (let i = 0; i < args.length; i += 1) {
      if (args[i] && args[i].apply) {
        args[i].apply(this, arguments);
      }
    }
  };
}

export function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn?.(event);
      return event?.defaultPrevented;
    });
  };
}

export function getItemAriaLabel(type, page, selected) {
  // if (type === 'page') {
  //   return `${selected ? '' : 'Go to '}page ${page}`;
  // }
  switch (type) {
    case 'page':
      return `${selected ? '' : 'Go to '}Page ${page}`;
    case 'next':
      return 'Skip to Next Page';
    case 'previous':
      return 'Skip to Previous Page';
    case 'first':
      return 'Skip to First Page';
    default:
      return 'Skip to Last Page';
  }
}
