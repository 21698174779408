import React from 'react';
import { OrderDetailsPlaceholder } from '../orderHistoryLoading/OrderDetailsPlaceholder';
import { CardItemsPlaceholder, CardStatusPlaceholder } from '../orderDetailsLoading';
import { CurbsideButtonPlaceholder } from '../curbsideButton/CurbsideButtonPlaceholder';

export const OrderPlaceholder = () => {
  return (
    <>
      <OrderDetailsPlaceholder />
      <CardStatusPlaceholder />
      <CardItemsPlaceholder />
      <CurbsideButtonPlaceholder />
    </>
  );
};
