import classNames from 'classnames';

const helpers = {
  CSSTRANSLATE: (position) => 'translate3d(' + [position, 0, 0].join(',') + ')',
  DIRECTIONAL_MULTIPLIER: (direction) => {
    switch (direction) {
    case 'prev': return -1;
    case 'next': return 1;
    default: return 1;
    }
  },
  KEYCODE_DIRECTION: (keyCode) => {
    switch (keyCode) {
    case 39: return 'next';
    case 37: return 'prev';
    default: return null;
    }
  },
  CAROUSEL: (dotBelow) => classNames({
    carousel: true,
    'dot-pattern--below': dotBelow,
  }),
  SLIDER: (isSlider, isSwiping) => classNames({
    slider: isSlider,
    animated: !isSwiping
  }),
  ITEM: (isSlider, selected) => classNames({
    slide: isSlider,
    selected
  }),
  DOT: (selected) => classNames({
    dot: true,
    selected
  })
};

export { helpers };
