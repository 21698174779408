import React from 'react';
import classnames from 'classnames';
import { bool, node } from 'prop-types';

export const ProductImageBadge = ({ children, hasPadding }) => {
  // eslint-disable-next-line tailwindcss/no-arbitrary-value
  const classes = classnames(
    'sui-bg-medium sui-text-inverse sui-leading-none sui-rounded-md sui-absolute sui-top-[-4px] sui-right-[-4px]',
    'sui-flex sui-justify-center sui-items-center sui-min-w-[2rem] sui-h-5',
    {
      'sui-px-1': hasPadding
    }
  );
  return (
    <div
      aria-hidden="true"
      className={classes}
    >
      {children}
    </div>
  );
};

ProductImageBadge.displayName = 'ProductImageBadge';

ProductImageBadge.propTypes = {
  children: node,
  hasPadding: bool
};

ProductImageBadge.defaultProps = {
  children: null,
  hasPadding: false
};
