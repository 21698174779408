import { useContext, useEffect, useRef } from 'react';
import { HelmetContext } from '../components/HelmetContext';
import { Helmet } from '../Helmet';

export const useHelmet = (key, {
  data, props, error, context: componetContext, history
}, callback, deps) => {
  const { context, canUseDom } = useContext(HelmetContext);
  const idRef = useRef(Math.random().toString(36).substr(2, 5));
  if (!context) {
    throw new Error('useHelmet requires a context object on the HelmetProvider');
  }

  context[key] = {
    id: idRef.current,
    args: { data, props, error },
    callback
  };

  useEffect(() => {
    let tags = callback({ data, props, error, history });
    if (!Array.isArray(tags) && tags) {
      tags = [tags];
    }
    // as tags get added from clientside you need to update dom.
    if (canUseDom && tags) {
      tags.forEach((tag) => {
        tag.updateDom();
      });
    }
    return () => {
      // console.log('im unmounting');
      // @todo remove elements?;
      (tags || []).forEach((tag) => {
        tag.removeFromDom();
      });
    };
  }, deps);

  return idRef;
};
