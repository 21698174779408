class Shape {
  constructor(attributes) {
    this._className = 'shape';
    Object.keys(attributes).forEach((key) => {
      this[key] = attributes[key];
    });
  }

  skip(variableName, defaultValue) {
    this._skip = {
      type: 'Boolean',
      _value: variableName,
      _defaultValue: defaultValue,
    };
    return this;
  }

  include(variableName, defaultValue) {
    this._include = {
      type: 'Boolean',
      _value: variableName,
      _defaultValue: defaultValue,
    };
    return this;
  }
}

export {
  Shape
};
