import React, { useContext } from 'react';
import { Row, Image, Col } from '@thd-olt-component-react/core-ui';
import { useConfigService } from '@thd-nucleus/experience-context';
import { WelcomeCardContext } from '../../../contexts';
import { getImageURL, getHostCURL } from '../../../common/utils/cardUtils';
import './WelcomeCardHeader.scss';

export const WelcomeCardHeader = () => {
  const isNewRHEnabled = useConfigService('ENABLE_REWARDS_HUB_PAGE');
  const rhLink = isNewRHEnabled === 1 ? '/myaccount/proXtraRewards' : '/myaccount/proxtraprogramdetails';
  const year = new Date().getFullYear();
  const { userData, isAdmin } = useContext(WelcomeCardContext);
  const proTiers = userData && userData.proTiers;
  const currentTierName = proTiers?.currentTierName || 'MEMBER';
  let tierImage;
  if (currentTierName === 'MEMBER') {
    tierImage = 'Tier-Badge-X-Member.svg';
  } else if (currentTierName === 'VIP') {
    tierImage = 'Tier-Badge-X-VIP.svg';
  } else if (currentTierName === 'Elite') {
    tierImage = 'Tier-Badge-X-Elite.svg';
  } else {
    tierImage = 'Tier-Badge-X-Member.svg';
  }
  return (
    <Row>
      <a
        href={isAdmin ? getHostCURL(rhLink) : undefined}
        rel=" noreferrer"
        className="header-container"
      >
        <div className="header-badge">
          <Image
            className="header-badge-image"
            src={getImageURL(tierImage)}
            alt="header-icon"
            height="auto"
            width="auto"
          />
          <div className={`member-background member-background-color-${currentTierName}`}>
            <div>
              <Image
                src={getImageURL('ProTiers/Pro-Xtra-logo-text.svg')}
                alt="logo-text"
                height="12"
                width="57"
                className="proxtraLogo"
              />
            </div>
            <div className="badge-text-tier">{currentTierName.toUpperCase()}</div>
          </div>
        </div>
      </a>
    </Row>
  );
};
