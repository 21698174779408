export const TIMEOUT_MESSAGE = 'Async call timeout limit reached';

export const asyncCallWithTimeout = async (asyncPromise, timeLimit) => {
  let timeoutHandle;

  const timeoutPromise = new Promise((resolve, reject) => {
    timeoutHandle = setTimeout(
      () => {
        reject(new Error(TIMEOUT_MESSAGE));
      },
      timeLimit
    );
  });

  return Promise.race([asyncPromise, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};