const getPageType = (path) => {
  const page = path.split('/')[1];

  if (page === 'collection') {
    return 'COLLECTION';
  }

  if (page === 'p') {
    return 'PIP';
  }

  return '';
};

export const buildServicesLink = ({ itemId, leadGenUrl, path, consultationType }) => {
  const encodedConsultationType = consultationType ? encodeURIComponent(consultationType) : '';
  if (encodedConsultationType) {
  // eslint-disable-next-line max-len
    return `${leadGenUrl}/form?vfrom=iframe-lead&internet_id=${itemId}&pageType=${getPageType(path)}#&consultationType=${encodedConsultationType}`;
  }
  return `${leadGenUrl}/form?vfrom=iframe-lead&internet_id=${itemId}&pageType=${getPageType(path)}#`;

};

export const buildSamLink = (storeSku) => (
  `https://www.homedepot.com/services/i/schedule-a-measure/b76e9e302/form?sku=${storeSku}`
);
export const colPalyLink = 'https://hddchtml.clopay.com/#resDoorTypeV1';