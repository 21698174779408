import React, { useEffect, useRef, useState, useContext } from 'react';
import { oneOf, bool, string } from 'prop-types';
import classNames from 'classnames';

import { extend } from '@thd-nucleus/data-sources';
import { debounce } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';

import { SpecialBuyHeader } from './SpecialBuyHeader/SpecialBuyHeader';
import { useSearchProducts } from '../hooks/useSearchProducts';
import { SpecialBuyCarousel } from './SpecialBuyCarousel/SpecialBuyCarousel';
import { SpecialBuyCategories } from './SpecialBuyCategories/SpecialBuyCategories';
import { SpecialBuyNavButton } from './SpecialBuyNavButton/SpecialBuyNavButton';
import { SpecialBuyProvider } from '../context/SpecialBuyProvider';

// How large is the component when layouts shift.
const condensedLayoutBreakpoint = 768;

const SpecialBuyBanner = ({ specialBuyType, entryId, isForceCondensedLayout, isUppercaseHeader }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('special-buy-banner.ready'); }, []);
  const { channel } = useContext(ExperienceContext);
  const [isCondensedLayout, setIsCondensedLayout] = useState(isForceCondensedLayout || channel === 'mobile');
  const bannerRef = useRef(null);

  useEffect(() => {
    if (isForceCondensedLayout) {
      setIsCondensedLayout(true);
    } else if (bannerRef.current) {
      const calculateAndSetIsCondensedLayout = () => {
        const width = bannerRef.current.getBoundingClientRect()?.width;
        if (width && channel !== 'mobile') {
          setIsCondensedLayout(width < condensedLayoutBreakpoint);
        } else {
          setIsCondensedLayout(true);
        }
      };
      calculateAndSetIsCondensedLayout();
      const handleResize = debounce(50, calculateAndSetIsCondensedLayout);
      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
      };
    }
    return () => {};
  }, [channel, isForceCondensedLayout, setIsCondensedLayout]);

  let bannerId = '';
  if (specialBuyType === 'sbotd') bannerId = 'sbotdBanner';
  if (specialBuyType === 'pro') bannerId = 'sbotwProBanner';

  const style = {
    contentContainer: classNames(
      'sui-flex-grow',
      'sui-h-full',
      'sui-flex',
      'sui-flex-col',
      'sui-justify-between',)
  };

  return (
    // TODO: take isCondensedLayout out of the provider
    <SpecialBuyProvider specialBuyType={specialBuyType} isCondensedLayout={isCondensedLayout}>
      <article
        id={bannerId}
        ref={bannerRef}
        className="sui-h-full sui-flex sui-flex-col"
        data-component="SpecialBuyBanner"
      >
        <SpecialBuyHeader isUppercaseHeader={isUppercaseHeader} />
        <div className={style.contentContainer}>
          <SpecialBuyCategories entryId={entryId} specialBuyType={specialBuyType} />
          {!isCondensedLayout
            ? <SpecialBuyCarousel entryId={entryId} specialBuyType={specialBuyType} />
            : <div className="sui-mt-8"><SpecialBuyNavButton isFullWidth /></div>}
        </div>
      </article>
    </SpecialBuyProvider>
  );
};

SpecialBuyBanner.dataModel = extend({}, useSearchProducts, SpecialBuyProvider);

SpecialBuyBanner.displayName = 'SpecialBuyBanner';

SpecialBuyBanner.propTypes = {
  /**
   * Identifies the type of special buy for the banner. May be one of (sbotd', 'pro')
   */
  specialBuyType: oneOf(['sbotd', 'pro']),
  entryId: string,
  isForceCondensedLayout: bool,
  isUppercaseHeader: bool,
};

SpecialBuyBanner.defaultProps = {
  specialBuyType: 'sbotd',
  entryId: '',
  isForceCondensedLayout: false,
  isUppercaseHeader: false,
};

export { SpecialBuyBanner };