import React, { useMemo } from 'react';
import classNames from 'classnames';

function extractEventHandlers(
  object,
  excludeKeys,
) {
  if (object === undefined) {
    return {};
  }

  const result = {};

  Object.keys(object)
    .filter(
      (prop) => prop.match(/^on[A-Z]/) && typeof object[prop] === 'function' && !excludeKeys.includes(prop),
    )
    .forEach((prop) => {
      result[prop] = object[prop];
    });

  return result;
}

function omitEventHandlers(
  object,
) {
  if (object === undefined) {
    return {};
  }

  const result = {};

  Object.keys(object)
    .filter((prop) => !(prop.match(/^on[A-Z]/) && typeof object[prop] === 'function'))
    .forEach((prop) => {
      (result[prop]) = object[prop];
    });

  return result;
}

function mergeSlotProps(parameters,) {
  const {
    getSlotProps, additionalProps, externalSlotProps, externalForwardedProps, className
  } = parameters;

  if (!getSlotProps) {
    // The simpler case - getSlotProps is not defined, so no internal event handlers are defined,
    // so we can simply merge all the props without having to worry about extracting event handlers.
    const joinedClasses = classNames(
      externalForwardedProps?.className,
      externalSlotProps?.className,
      className,
      additionalProps?.className,
    );

    const mergedStyle = {
      ...additionalProps?.style,
      ...externalForwardedProps?.style,
      ...externalSlotProps?.style,
    };

    const props = {
      ...additionalProps,
      ...externalForwardedProps,
      ...externalSlotProps,
    };

    if (joinedClasses.length > 0) {
      props.className = joinedClasses;
    }

    if (Object.keys(mergedStyle).length > 0) {
      props.style = mergedStyle;
    }

    return {
      props,
      internalRef: undefined,
    };
  }

  // In this case, getSlotProps is responsible for calling the external event handlers.
  // We don't need to include them in the merged props because of this.

  const eventHandlers = extractEventHandlers({ ...externalForwardedProps, ...externalSlotProps });
  const componentsPropsWithoutEventHandlers = omitEventHandlers(externalSlotProps);
  const otherPropsWithoutEventHandlers = omitEventHandlers(externalForwardedProps);

  const internalSlotProps = getSlotProps(eventHandlers);

  // The order of classes is important here.
  // Emotion (that we use in libraries consuming MUI Base) depends on this order
  // to properly override style. It requires the most important classes to be last
  // (see https://github.com/mui/material-ui/pull/33205) for the related discussion.
  const joinedClasses = classNames(
    internalSlotProps?.className,
    additionalProps?.className,
    className,
    externalForwardedProps?.className,
    externalSlotProps?.className,
  );

  const mergedStyle = {
    ...internalSlotProps?.style,
    ...additionalProps?.style,
    ...externalForwardedProps?.style,
    ...externalSlotProps?.style,
  };

  const props = {
    ...internalSlotProps,
    ...additionalProps,
    ...otherPropsWithoutEventHandlers,
    ...componentsPropsWithoutEventHandlers,
  };

  if (joinedClasses.length > 0) {
    props.className = joinedClasses;
  }

  if (Object.keys(mergedStyle).length > 0) {
    props.style = mergedStyle;
  }

  return {
    props,
    internalRef: internalSlotProps.ref,
  };
}

function isHostComponent(element) {
  return typeof element === 'string';
}

function appendOwnerState(
  elementType,
  otherProps,
  ownerState,
) {
  if (elementType === undefined || isHostComponent(elementType)) {
    return otherProps;
  }

  return {
    ...otherProps,
    ownerState: { ...otherProps.ownerState, ...ownerState },
  };
}

function setRef(
  ref,
  value,
) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

function useForkRef(
  ...refs
) {
  /**
   * This will create a new function if the refs passed to this hook change and are all defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }

    return (instance) => {
      refs.forEach((ref) => {
        setRef(ref, instance);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}

function resolveComponentProps(
  componentProps,
  ownerState,
) {
  if (typeof componentProps === 'function') {
    return componentProps(ownerState);
  }

  return componentProps;
}

export default function useSlotProps(parameters) {
  const { elementType, externalSlotProps, ownerState, ...rest } = parameters;
  const resolvedComponentsProps = resolveComponentProps(externalSlotProps, ownerState);
  const { props: mergedProps, internalRef } = mergeSlotProps({
    ...rest,
    externalSlotProps: resolvedComponentsProps,
  });

  const ref = useForkRef(
    internalRef,
    resolvedComponentsProps?.ref,
    parameters.additionalProps?.ref,
  );

  const props = appendOwnerState(
    elementType,
    {
      ...mergedProps,
      ref,
    },
    ownerState,
  );

  return props;
}