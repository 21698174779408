import React from 'react';
import { func, number, oneOf, string } from 'prop-types';
import classnames from 'classnames';

import { usePromoCart } from '../../hooks/usePromoCart';
import { noop } from '../../utils/promo-utils';
import { AddToCartButton } from '../AddToCartButton/AddToCartButton';
import { PromoQuantity } from '../PromoQuantity/PromoQuantity';

export const AddToCartCta = ({
  align,
  displayPosition,
  itemId,
  setQuantityWarningText,
  variant
}) => {
  const { promoCartItemsObj } = usePromoCart();

  const promoItemModel = promoCartItemsObj[itemId];
  const productInCartQuantity = promoItemModel?.quantity || 0;

  // eslint-disable-next-line tailwindcss/no-custom-classname
  const classes = classnames(
    'sui-w-full sui-flex sui-items-center !sui-mb-0',
    {
      'sui-justify-center': align === 'center',
      'sui-justify-end': align === 'end',
    }
  );

  return (
    <div className={classes}>
      {productInCartQuantity > 0 ? (
        <PromoQuantity
          itemId={itemId}
          setWarningText={setQuantityWarningText}
        />
      ) : (
        <AddToCartButton displayPosition={displayPosition} itemId={itemId} variant={variant} />
      )}
    </div>
  );
};

AddToCartCta.displayName = 'AddToCartCta';

AddToCartCta.propTypes = {
  align: oneOf(['start', 'center', 'end']),
  displayPosition: number.isRequired,
  itemId: string.isRequired,
  setQuantityWarningText: func,
  variant: string
};

AddToCartCta.defaultProps = {
  align: 'start',
  setQuantityWarningText: noop,
  variant: null
};
