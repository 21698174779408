import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Tile = createSvgIcon(
  <>
    <path d="M0 1.5h9v6.75h6.75V15h7.5v9H0V1.5Zm9 15.75v4.5h4.5v-4.5H9ZM6.75 15v-4.5h-4.5V15h4.5Zm0-6.75v-4.5h-4.5v4.5h4.5ZM13.5 15v-4.5H9V15h4.5Zm-6.75 2.25h-4.5v4.5h4.5v-4.5Zm9.75 4.5H21v-4.5h-4.5v4.5Z" />
    <path d="M11.25 4.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm9-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-1.5 8.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
  </>,
  'Tile'
);

export { Tile };