import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { UserListsCarousel } from '@thd-olt-component-react/user-lists';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from './context';
import { CUSTOMER_TYPES } from '../utils';

export function UserList({ id }) {
  const { customerType } = useContext(HomepageContext);
  const { channel } = useContext(ExperienceContext);
  const title = customerType === CUSTOMER_TYPES.B2B ? 'My Product Lists' : 'My Lists';

  return (
    <ErrorBoundary name="user-lists">
      <QueryProvider key={id} cacheKey={id}>
        <div
          id={`user-list-php-${id}`}
          className="sui-h-full"
        >
          <UserListsCarousel
            title={title}
            entryId={id}
            hideCarouselArrows={channel === 'mobile'}
          />
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

UserList.propTypes = {
  id: string,
};

UserList.defaultProps = {
  id: undefined,
};
