import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadingIcon } from '../private/icons/LoadingIcon';
import { Close } from '../private/icons/Close';
import { ButtonBase } from '../private/components/button/ButtonBase';
import useForkRef from '../private/hooks/useForkRef';

function createComponent(defaultComponent, displayName) {

  const Comp = (props, ref) => {

    const {
      as,
      ...other
    } = props;

    const Component = as || defaultComponent;

    return (
      <Component ref={ref} {...other} />
    );
  };

  Comp.displayName = displayName;
  return React.forwardRef(Comp);
}

const ChipRoot = createComponent(ButtonBase, 'ChipRoot');
const ChipLabel = createComponent('span', 'ChipLabel');
const ChipTitle = createComponent('span', 'ChipTitle');

function isDeleteKeyboardEvent(keyboardEvent) {
  return keyboardEvent.key === 'Backspace' || keyboardEvent.key === 'Delete';
}

/**
 * `Chip`s are discreet textual containers that serve as secondary visual references
 * to users about the selected filtering parameters that customize their search experience.
 *
 * See available icons for the `deleteIcon` prop in the
 * [Icon section](https://brand.homedepot.com/site/knapsack-nucleus/latest/pattern/icons?subPageId=DESIGN)
 *
 * Related components: [Pill](#pill)
 *
 * Usage:
 *
 * ```jsx
 * import { Chip } from '@one-thd/sui-atomic-components';
 * ```
 */
const Chip = React.forwardRef((props, ref) => {

  const {
    clickable: clickableProp,
    component: ComponentProp,
    deleteIcon: deleteIconProp,
    disabled = false,
    disableDefaultClickIcon = false,
    icon: iconProp,
    endIcon: endIconProp,
    label,
    loading = false,
    onClick,
    onDelete,
    onKeyDown,
    onKeyUp,
    rounded = false,
    tabIndex,
    title,
    skipFocusWhenDisabled = false,
    ...other
  } = props;

  const chipRef = React.useRef(null);
  const handleRef = useForkRef(chipRef, ref);

  const hasTitle = !!title;
  const hasEndIcon = !!onDelete || (!!onClick);
  const hasIcon = !!iconProp;

  const handleDeleteIconClick = (event) => {
    // Stop the event from bubbling up to the `Chip`
    event.stopPropagation();
    if (onDelete) {
      onDelete(event);
    }
  };

  const handleKeyDown = (event) => {
    // Ignore events from children of `Chip`.
    if (event.currentTarget === event.target && isDeleteKeyboardEvent(event)) {
      // Will be handled in keyUp, otherwise some browsers
      // might init navigation
      event.preventDefault();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleKeyUp = (event) => {
    // Ignore events from children of `Chip`.
    if (event.currentTarget === event.target) {
      if (onDelete && isDeleteKeyboardEvent(event)) {
        onDelete(event);
      } else if (event.key === 'Escape' && chipRef.current) {
        chipRef.current.blur();
      }
    }

    if (onKeyUp) {
      onKeyUp(event);
    }
  };

  const clickable = clickableProp !== false && onClick ? true : clickableProp;

  const component = clickable || onDelete ? ButtonBase : ComponentProp || 'div';

  const classes = {
    root: classNames('sui-font-regular sui-text-base sui-inline-flex sui-items-center sui-justify-center sui-h-8 sui-whitespace-nowrap sui-p-0 sui-align-middle sui-box-border sui-border-1 sui-border-solid', {
      'sui-bg-button-secondary sui-border-button-inactive sui-text-inactive': disabled,
      'sui-bg-transparent sui-text-primary sui-border-strongest': !disabled,
      'focus-visible:sui-bg-button-focus focus-visible:sui-border-transparent': component === ButtonBase,
      'hover:sui-border-transparent hover:sui-bg-strong': clickable && !disabled,
      'sui-cursor-default sui-pointer-events-none': !clickable || loading,
      'sui-rounded-full': rounded,
      'sui-rounded-none': !rounded,
      'sui-chip-loading': loading
    }),
    title: classNames('sui-font-bold sui-overflow-hidden sui-text-ellipsis', {
      'sui-pl-2': hasIcon,
      'sui-pl-3': !hasIcon,
    }),
    label: classNames('sui-overflow-hidden sui-text-ellipsis', {
      'sui-pr-2': hasEndIcon && !disableDefaultClickIcon,
      'sui-pr-3': !hasEndIcon || disableDefaultClickIcon,
      'sui-pl-2': hasIcon || hasTitle,
      'sui-pl-3': !hasIcon && !hasTitle,
    }),
    deleteIcon: classNames('sui-inline-block sui-align-baseline sui-w-4 sui-h-4 sui-mr-2 sui-cursor-pointer sui-pointer-events-auto', {
      'sui-fill-current': loading,
      'hover:sui-fill-medium': !loading,
      'sui-fill-primary': !disabled && !loading,
      'sui-fill-subtle': disabled && !loading,
    }),
    loading: 'sui-absolute sui--translate-x-1/2 sui-left-1/2 sui-fill-primary'
  };

  const moreProps = component === ButtonBase
    ? {
      component: ComponentProp || 'div'
    } : {};

  let deleteIcon = null;
  if (onDelete) {
    deleteIcon = deleteIconProp && React.isValidElement(deleteIconProp) ? (
      React.cloneElement(deleteIconProp, {
        onClick: handleDeleteIconClick,
        className: classes.deleteIcon
      })
    ) : (
      <Close className={classes.deleteIcon} onClick={handleDeleteIconClick} />
    );
  }

  let icon = null;
  if (iconProp && React.isValidElement(iconProp)) {
    icon = React.cloneElement(iconProp, {
      size: 'small',
      style: { marginLeft: '8px' }
    });
  }

  let endIcon = null;
  if (onClick) {
    endIcon = endIconProp && React.isValidElement(endIconProp) ? (
      React.cloneElement(endIconProp, {
        size: 'small',
        style: { marginRight: '8px' }
      })
    ) : !disableDefaultClickIcon && (
      <Close size="small" style={{ marginRight: '8px' }} />
    );
  }

  return (
    <ChipRoot
      as={component}
      className={classNames(classes.root)}
      disabled={clickable && disabled ? true : undefined}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      ref={handleRef}
      tabIndex={skipFocusWhenDisabled && disabled ? -1 : tabIndex}
      {...moreProps}
      {...other}
    >
      {loading && (
        <span className={classes.loading}>
          <LoadingIcon color="inherit" />
        </span>
      )}
      {icon}
      {title ? (
        <ChipTitle
          className={classNames(classes.title)}
        >
          {title}:
        </ChipTitle>
      ) : null}
      <ChipLabel className={classNames(classes.label)}>
        {label}
      </ChipLabel>
      {deleteIcon || endIcon}
    </ChipRoot>
  );
});

Chip.displayName = 'Chip';

Chip.propTypes = {
  /**
   * If `true`, the chip will appear clickable, with hover and focus states,
   * even if the onClick or onDelete prop are not defined.
   * If `false`, the chip will not appear clickable, even if onClick prop is defined.
   * This can be used, for example,
   * along with the component prop to indicate an anchor Chip is clickable.
   * Note: this controls the UI and does not affect the onClick event.
   */
  clickable: PropTypes.bool,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Override the default delete icon element. Shown only if `onDelete` is set.
   */
  deleteIcon: PropTypes.element,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the close icon will not show when `onClick` is set.
   * @default false
   */
  disableDefaultClickIcon: PropTypes.bool,
  /**
   * An icon to display after the label.
   * When `onClick` is set, the close icon will be shown.
   * @default
   */
  endIcon: PropTypes.element,
  /**
   * leading icon element.
   */
  icon: PropTypes.element,
  /**
   * The content of the component.
   */
  label: PropTypes.node,
  /**
   * If true, the loading indicator is shown.
   * @default false
   */
  loading: PropTypes.bool,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   * Callback fired when the delete icon is clicked.
   * If set, the delete icon will override the close icon.
   * This prop will be `deprecated` in future releases, use it at your own risk.
   */
  onDelete: PropTypes.func,
  /**
   * @ignore
   */
  onKeyDown: PropTypes.func,
  /**
   * @ignore
   */
  onKeyUp: PropTypes.func,
  /**
   * If true, the Chip visually displays as a Pill.
   * @default false
   */
  rounded: PropTypes.bool,
  /**
   * If `true`, allows the disabled chip to escape focus.
   * If `false`, allows the disabled chip to receive focus.
   * @default false
   */
  skipFocusWhenDisabled: PropTypes.bool,
  /**
   * @ignore
   */
  tabIndex: PropTypes.number,
  /**
   * The chip title
   */
  title: PropTypes.string
};

export { Chip };
