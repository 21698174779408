import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Orders = createSvgIcon(
  <>
    <path d="M19.5 0H0v24l4.079-3.059L7.5 23.868l3.367-2.88 3.114 2.724 1.482-1.693-4.58-4.007L7.5 20.907l-3.329-2.848L2.25 19.5V2.25h15v3h2.25V0Z" />
    <path d="M21.75 9.75V7.5H19.5v2.316c-.6.105-1.197.34-1.706.721a3.166 3.166 0 0 0-1.294 2.588c0 1.41.747 2.287 1.532 2.811a6.2 6.2 0 0 0 1.059.548c.145.06.257.105.348.141.134.053.223.089.308.131.168.084.405.18.6.258.074.029.141.056.197.08.258.107.485.215.676.342.34.226.53.474.53.939 0 .322-.144.57-.429.777a1.932 1.932 0 0 1-1.071.348h-3v2.25h2.25V24h2.25v-2.541c.313-.123.616-.284.894-.486.747-.543 1.356-1.42 1.356-2.598 0-1.41-.747-2.287-1.532-2.811a6.179 6.179 0 0 0-1.059-.548c-.145-.06-.257-.105-.348-.141a4.075 4.075 0 0 1-.308-.131 8.786 8.786 0 0 0-.6-.258 7.012 7.012 0 0 1-.197-.08 3.985 3.985 0 0 1-.676-.342c-.34-.226-.53-.474-.53-.939a.92.92 0 0 1 .394-.787c.277-.208.686-.338 1.106-.338h3V9.75h-1.125v1.125V9.75h-.375Z" />
  </>,
  'Orders'
);

export { Orders };