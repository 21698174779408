import {
  params, string as stringType, arrayOf, shape
} from '@thd-nucleus/data-sources';

export const PromotionCardUdsDataModel = {
  uds: params({
    userId: stringType().isRequired(),
    apiName: stringType().isRequired(),
    key: stringType().isRequired(),
  }).shape({
    products: arrayOf(shape({
      product: shape({
        itemId: stringType(),
      })
    })),
  })
};