import React from 'react';
import PropTypes from 'prop-types';
import { Clock } from '@one-thd/sui-icons';
import classNames from 'classnames';

const TimerInline = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg,
  showMessageOnRight,
  showOrangeTimerSvg,
  showOrangeText,
  fontSize,
}) => {

  const style = {
    container: classNames(
      'sui-font-semibold',
      { 'sui-flex sui-items-center': shouldIncludeSvg },
      { 'sui-text-brand': showOrangeText },
      { 'sui-text-base': fontSize === 'base' },
      { 'sui-text-sm': fontSize === 'small' },
    )
  };
  const clockSvgColor = showOrangeTimerSvg ? 'brand' : 'primary';

  return (
    <div className={style.container} data-component="TimerInline">
      {shouldIncludeSvg && (
        <div className="sui-flex sui-mr-2 sui-items-center">
          <Clock size="regular" color={clockSvgColor} />
        </div>
      )}
      {(timeStringAndMessaging.messagingString && shouldUseMessagingString && !showMessageOnRight) && (
        timeStringAndMessaging.messagingString + ' '
      )}
      {timeStringAndMessaging.timeString && (
        timeStringAndMessaging.timeString
      )}
      {(timeStringAndMessaging.messagingString && shouldUseMessagingString && showMessageOnRight) && (
        ' ' + timeStringAndMessaging.messagingString
      )}
    </div>
  );
};

TimerInline.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show clock image
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show Message on right Side
   */
  showMessageOnRight: PropTypes.bool,
  /**
   * To show orage colour clock image
   */
  showOrangeTimerSvg: PropTypes.bool,
  /**
   * To show orage colour text
   */
  showOrangeText: PropTypes.bool,
  /**
   * To show 1.4em text
   */
  fontSize: PropTypes.string,
  /**
   * To show message and time
   */
  timeStringAndMessaging: PropTypes.oneOfType([
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.string
    }),
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.element
    })
  ])
};

TimerInline.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  showMessageOnRight: false,
  showOrangeTimerSvg: false,
  showOrangeText: false,
  fontSize: 'regular',
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerInline;
