import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '../button/IconButton';
import { Close } from '../private/icons/Close';

/**
 * `ModalBody` is the main container for `Modal`
 *
 * Related components: [Modal](#modal), [ModalHeader](#modalheader), [ModalFooter](#modalfooter)
 *
 * Usage:
 *
 * ```jsx
 * import { ModalBody } from '@one-thd/sui-atomic-components';
 * ```
 */
const ModalBody = React.forwardRef((props, ref) => {

  const { children, onClose, ...other } = props;

  const classes = 'sui-flex sui-grow sui-px-6 sui-pt-2 sui-overflow-x-hidden sui-overflow-y-auto';

  return (
    <div className={classes}>
      <div
        className="sui-flex-auto"
        ref={ref}
        {...other}
      >
        {children}
      </div>
      {onClose && (
        <div className="sui-flex sui-pl-4 sui-ml-auto sui--mr-2">
          <IconButton icon={Close} onClick={onClose} aria-label="Close" />
        </div>
      )}
    </div>
  );
});

ModalBody.displayName = 'ModalBody';

ModalBody.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Function to handle a close event for Modal when Icon Button is clicked.
   */
  onClose: PropTypes.func
};

export { ModalBody };
