import React from 'react';

/**
 * I don't think we actually use this component right now, so for the time being,
 * we won't worry about specifying prop types and figuring out what from the
 * spread of `...rest` is actually necessary.
 */
/* eslint-disable */
export function Image({ id, url, ...rest }) {
  return <img key={id} src={url} {...rest} />
}
