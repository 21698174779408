/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import classNames from 'classnames';

import { Skeleton } from '@one-thd/sui-atomic-components';

export const CardProductLoader = () => {
  const contentClasses = classNames(
    // arbitrary values are needed to match sui palette and behavior
    'motion-safe:sui-animate-pulse',
    'sui-bg-[rgba(0,0,0,0.11)]', // matches sui palette
    'sui-w-full',
    'sui-min-h-36',
    'sui-max-h-[300px]' // matches size of the loaded content
  );
  return (
    <div className="sui-pt-1 sui-w-full">
      <Skeleton disablePadding SkeletonContentProps={{ grow: true }}>
        <div className={contentClasses}>
          <div className="sui-w-[33%]">
            <div className="sui-w-full sui-pt-[100%]" />
          </div>
          <div className="sui-inline sui-w-[67%] sui-h-36" />
        </div>
      </Skeleton>
    </div>
  );
};

CardProductLoader.displayName = 'CardProductLoader';

CardProductLoader.propTypes = {};

CardProductLoader.defaultProps = {};
