import { useMemo, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import {
  determineLoadingState, determineErrorState, useDataSourceOpts
} from '../common/utils/ContextUtils';
import { ENDPOINTS } from '../core/Constants';

export const useFetchHomepage = () => {
  const userDataRef = useRef({});
  const opts = useDataSourceOpts().opts;

  const response = useDataModel(ENDPOINTS.HOMEPAGE, opts);
  userDataRef.current = response?.data && response?.data[ENDPOINTS.HOMEPAGE];

  const userData = userDataRef.current;
  const isLoading = determineLoadingState(response);
  const userDataError = determineErrorState(response) || !userData;
  const refreshData = () => response.refetch();

  return useMemo(() => ({
    isLoading,
    userDataError,
    userData,
    refreshData,
  }), [isLoading]);
};
