import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './paper.module.scss';

const cx = classNames.bind(styles);

const Paper = ({
  border,
  elevation,
  sharp,
  children,
  className
}) => {

  const paperClasses = cx('paper', {
    'paper--elevation-0': elevation === 0,
    'paper--elevation-2': elevation === 2,
    'paper--elevation-3': elevation === 3,
    'paper--elevation-4': elevation === 4,
    'paper--sharp': sharp,
    'paper--border': border
  }, className);

  return (
    <div className={paperClasses}>
      { children }
    </div>
  );
};

Paper.displayName = 'Paper';

Paper.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string,
  elevation: PropTypes.oneOf([0, 1, 2, 3, 4]),
  sharp: PropTypes.bool,
};

Paper.defaultProps = {
  border: false,
  children: null,
  className: null,
  elevation: 1,
  sharp: false,
};

export { Paper };
