import { bool, func, instanceOf, string } from 'prop-types';

export const OverviewPropTypes = {
  apiKey: string.isRequired,
  handleCardClick: func,
  isMobileOverlay: bool,
  reviewStatistics: instanceOf(Object),
  hideSecondaryRatings: bool,
  disableOverlayOnClick: bool
};

export const OverviewDefaultProps = {
  handleCardClick: () => {},
  isMobileOverlay: false,
  hideSecondaryRatings: false,
  reviewStatistics: {},
  disableOverlayOnClick: false
};
