import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import helpers from '../../helpers/price-helper';
import '../../styles/instant-rebates.style.scss';

class InstantRebates extends Component {

  getDetails = (storePromotion, price) => {
    const rebatePrice = helpers.toFixed(price.dollarOff, 2);
    const wasPrice = helpers.toFixed(price.wasPrice, 2);
    const nowPrice = helpers.toFixed(price.nowPrice, 2);
    const percentageOff = helpers.toFixed(price.percentageOff);
    return (
      <>
        <div className="instant-rebates__tooltip_header">
          <div className="instant-rebates__tooltip_title">Price Details</div>
        </div>
        <ul className="instant-rebates__list">
          <li className="instant-rebates__list-item instant-rebates__row">
            <span className="instant-rebates__list-item-left">Everyday Low Price</span>
            <span className="instant-rebates__price instant-rebates__list-item-right">${wasPrice}</span>
          </li>
          <li className="instant-rebates__list-item instant-rebates__row u__italic">
            <span className="instant-rebates__list-item-left instant-rebates__savings_color">
              {storePromotion.longDescription} Instant Rebate*
            </span>
            <span className="instant-rebates__price instant-rebates__list-item-right">-${rebatePrice}</span>
          </li>
        </ul>
        <div className="instant-rebates__total_savings">
          <div className="instant-rebates__save-row">
            <span className="instant-rebates__savings_color">Save ${rebatePrice} ({percentageOff}%)</span>
            <span>${nowPrice}</span>
          </div>
        </div>
        <div className="instant-rebates__tooltip_footer">
          <div className="instant-rebates__tooltip_footer_inner">
            <strong>${rebatePrice} Instant Rebate</strong>
            <ul>
              <li>Rebate amount applied instantly and reflected in price displayed</li>
              <li>Rebate availability is based on location</li>
              <li>Cannot be combined with another offer</li>
            </ul>
            <p className="instant-rebates__note-text">* Online rebate may be provided by Home Depot</p>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      channel, storePromotion, price, hasDetails
    } = this.props;
    const instantRebatesDetails = this.getDetails(storePromotion, price);
    const rebatePrice = helpers.toFixed(price.dollarOff, 2);
    const tooltipId = `instant-rebate-tooltip${price.itemId}`;
    return (
      <div>
        {hasDetails && (
          <>
            <div className="instant-rebates__message">
              <span className="u__text--success">After ${rebatePrice} Instant Rebate</span>
              <div className="instant-rebates__details">
                <Tooltip
                  channel={channel}
                  closeButton
                  content={instantRebatesDetails}
                  maxWidth={320}
                  placement="bottom"
                >
                  <button
                    type="button"
                    id={tooltipId}
                    className="u__default-link"
                    onClick={(event) => helpers.triggerAnalytics('instant-rebate')}
                  >
                    Details
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="instant-rebates__promo">
              No mail-in necessary. Store rebate provided by {storePromotion.longDescription}
            </div>
          </>
        )}
        {!hasDetails
        && <span className="u__text--success">After ${rebatePrice} Instant Rebate</span>}
      </div>
    );
  }
}

InstantRebates.propTypes = {
  /**
   * The type of channel ie; Desktop/Mobile
   */
  channel: PropTypes.string.isRequired,
  /**
   * To describe Store Promotion details
   */
  storePromotion: PropTypes.shape({
    longDescription: PropTypes.string,
    shortDescription: PropTypes.string
  }),
  /**
   * To describe the Price of the product
   */
  price: PropTypes.shape({
    itemId: PropTypes.string,
    wasPrice: PropTypes.number,
    dollarOff: PropTypes.number,
    percentageOff: PropTypes.number,
    nowPrice: PropTypes.number
  }),
  /**
   * To show details of the product
   */
  hasDetails: PropTypes.bool
};

InstantRebates.defaultProps = {
  storePromotion: {},
  price: {},
  hasDetails: false
};

export default InstantRebates;
