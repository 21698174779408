import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import styles from '../../styles/exchange-pricing.module.scss';

const cx = classNames.bind(styles);

export const ExchangePricingBadge = (props) => {

  const { padLeft, align } = props;

  const exchangePricingWrapperClasses = !padLeft && align === 'end'
    ? 'sui-flex sui-justify-end'
    : cx({
      'exchange-pricing__wrapper': padLeft, 
      'exchange-pricing__wrapper--side-padding': padLeft
    });

  return (
    <div className={exchangePricingWrapperClasses}>
      <Image
        className={cx('exchange-pricing__badge')}
        asset="ExchangePricing-badge.svg"
        alt="Exchange Pricing Badge"
        title="Exchange Pricing" 
      />
    </div>
  );
};

ExchangePricingBadge.propTypes = {
  padLeft: PropTypes.bool,
  align: PropTypes.string,
};

ExchangePricingBadge.defaultProps = {
  padLeft: false,
  align: 'start'
};