import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createBadge from '../utils/createBadge';
import usePreviousProps from '../private/hooks/usePreviousProps';
import useBadge from '../private/hooks/useBadge';

/**
 * The `BadgeNotification` component is used to generate a visual indicator anchored to its child component.
 *
 * Related components: [Badge](#badge), [Pill](#pill), [Chip](#chip)
 *
 * Usage:
 *
 * ```jsx
 * import { NotificationBadge } from '@one-thd/sui-atomic-components';
 * ```
 */
const NotificationBadge = React.forwardRef((props, ref) => {

  const {
    anchorOrigin: anchorOriginProp = {
      vertical: 'top',
      horizontal: 'right',
    },
    components = {},
    componentsProps = {},
    children,
    color: colorProp = 'danger',
    invisible: invisibleProp = false,
    max: maxProp = 99,
    badgeContent: badgeContentProp,
    variant: variantProp = 'strong',
    size = 'small',
    ...other
  } = props;

  const {
    badgeContent,
    invisible: invisibleFromHook,
    displayValue,
  } = useBadge({
    max: maxProp,
    invisible: invisibleProp,
    badgeContent: badgeContentProp,
  });

  const prevProps = usePreviousProps({
    anchorOrigin: anchorOriginProp,
    color: colorProp,
    variant: variantProp,

    badgeContent: badgeContentProp,
  });

  const invisible = invisibleFromHook || (badgeContent == null);

  const {
    color = colorProp,
    anchorOrigin = anchorOriginProp,
    variant = variantProp
  } = invisible ? prevProps : props;

  const { classes: baseBadgeClasses } = createBadge({
    color,
    variant,
    size
  });

  const navBadgeClasses = 'sui-flex sui-flex-row sui-flex-wrap sui-justify-center sui-items-center sui-absolute sui-box-border sui-min-w-[28px] sui-h-7 sui-px-2 sui-z-10';

  const placementClasses = classNames('sui-ease-in-out sui-duration-150', {
    'sui-top-0 sui-right-0 [transform:scale(1)_translate(50%,-50%)] sui-origin-top-right': !invisible && anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'right',
    'sui-top-0 sui-right-0 [transform:scale(0)_translate(50%,-50%)] sui-origin-top-right': invisible && anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'right',
    'sui-top-0 sui-left-0 [transform:scale(1)_translate(-50%,-50%)] sui-origin-top-left': !invisible && anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'left',
    'sui-top-0 sui-left-0 [transform:scale(0)_translate(-50%,-50%)] sui-origin-top-left': invisible && anchorOrigin.vertical === 'top' && anchorOrigin.horizontal === 'left',
    'sui-bottom-0 sui-right-0 [transform:scale(1)_translate(50%,50%)] sui-origin-bottom-right': !invisible && anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'right',
    'sui-bottom-0 sui-right-0 [transform:scale(0)_translate(50%,50%)] sui-origin-bottom-right': invisible && anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'right',
    'sui-bottom-0 sui-left-0 [transform:scale(1)_translate(-50%,50%)] sui-origin-bottom-left': !invisible && anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'left',
    'sui-bottom-0 sui-left-0 [transform:scale(0)_translate(-50%,50%)] sui-origin-bottom-left': invisible && anchorOrigin.vertical === 'bottom' && anchorOrigin.horizontal === 'left',
  });

  const badgeProps = {
    className: classNames(navBadgeClasses, placementClasses, baseBadgeClasses),
    ...componentsProps.Badge
  };

  const rootProps = {
    className: 'sui-relative sui-inline-flex sui-align-middle sui-shrink-0 sui-flex-row',
    ...componentsProps.Root,
    ...other
  };

  const RootComponent = components.Root || 'span';
  const BadgeComponent = components.Badge || 'span';

  return (
    <RootComponent
      ref={ref}
      {...rootProps}
    >
      {children}
      <BadgeComponent {...badgeProps}>{displayValue}</BadgeComponent>
    </RootComponent>
  );
});

NotificationBadge.displayName = 'NotificationBadge';

NotificationBadge.propTypes = {
  /**
   * Defines the anchor positioning of the badge.
   * @default { vertical: 'top', horizontal: 'right' }
   */
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['left', 'right']).isRequired,
    vertical: PropTypes.oneOf(['bottom', 'top']).isRequired,
  }),
  /**
   * The content rendered inside the badge.
   */
  badgeContent: PropTypes.node,
  /**
   * An object with the components used for each slot.
   */
  components: PropTypes.shape({
    Root: PropTypes.node,
    Badge: PropTypes.node
  }),
  /**
   * Props passed to the slot components.
   */
  componentsProps: PropTypes.PropTypes.shape({
    Root: PropTypes.object,
    Badge: PropTypes.object
  }),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The color of the component.
   * @default 'danger'
   */
  color: PropTypes.oneOf(['primary', 'inverse', 'brand', 'success', 'danger', 'medium', 'warning', 'info']),
  /**
   * If `true` the badge becomes invisible.
   * @default false
   */
  invisible: PropTypes.bool,
  /**
   * Max number allowed as badge content.
   * @default 99
   */
  max: PropTypes.number,
  /**
   * The variant to use.
   * @default 'strong'
   */
  variant: PropTypes.oneOf(['subtle', 'regular', 'strong']),
  /**
   * The size of the component. Also affects the font size.
   * @default 'small'
   */
  size: PropTypes.oneOf(['small', 'base', 'large']),
};

export { NotificationBadge };