import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Locker = createSvgIcon(
  <>
    <path d="M19.5 5.375h-3.75v2.25h3.75v-2.25ZM15.75 10.5h3.75v2.25h-3.75V10.5Zm-7.5-5.125H4.5v2.25h3.75v-2.25ZM4.5 10.5h3.75v2.25H4.5V10.5Z" />
    <path d="M0 0h24v24h-2.25v-2.25h-8.625V24h-2.25v-2.25H2.25V24H0V0Zm21.75 19.5V2.25h-8.625V19.5h8.625ZM2.25 2.25V19.5h8.625V2.25H2.25Z" />
  </>,
  'Locker'
);

export { Locker };