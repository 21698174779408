/* eslint-disable max-len */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Clock } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classNames from 'classnames';

const TimerEvent = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg,
  invertText,
  fontSize
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile'; 

  const style = {
    message: classNames(
      'sui-uppercase',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText },
      { 'sui-h1-display': fontSize === 'base' },
      { 'sui-h2-display': fontSize === 'small' },
    ),
    timer: classNames(
      'sui-flex',
      'sui-mr-3',
      'sui-items-center',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText }
    ),
    countdown: classNames(
      'sui-uppercase',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inactive': invertText },
      { 'sui-h2-display': fontSize === 'base' },
      { 'sui-h3-display': fontSize === 'small' },
    ),
    timerContainer: classNames(
      'sui-flex',
      { 'sui-pt-5': !fontSize === 'small' || !isMobile },
      { 'sui-pt-2': fontSize === 'small' && isMobile },
      'sui-justify-center',
    ),
    messageContainer: classNames(
      "sui-flex", 
      "sui-justify-center", 
      "sui-items-center",
      { 'sui-h1-display': fontSize === 'base' },
      { 'sui-h2-display': fontSize === 'small' }
    )
  };

  const { messagingString, timeString } = timeStringAndMessaging;
  const { days, hours, minutes, seconds } = timeString;
  const countdown = { days,
    hrs: hours,
    min: minutes,
    sec: seconds };

  return (
    <div data-component="TimerEvent">
      <div className={style.messageContainer}>
        {shouldIncludeSvg && (
          <span className={style.timer}>
            <Clock size={fontSize==='small'? "inherit" : "large"} />
          </span>
        )}
        <span className={style.message}>
          {(messagingString && shouldUseMessagingString) && (
            messagingString
          )}
        </span>
      </div>

      <div className={style.timerContainer}>
        {Object.entries(countdown).map(([key, value]) => {
          return (
            <span className={style.countdown} key={key}>
              <span className="sui-pr-1">{value}</span>
              <span className={key !== 'sec' && 'sui-pr-3'}>{key}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

TimerEvent.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show clock image
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show orage colour text
   */
  invertText: PropTypes.bool,
  /**
   * To show message and time
   */
  /**
   * To show 1.4em text
   */
  fontSize: PropTypes.string,
  timeStringAndMessaging: PropTypes.oneOfType([
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.string
    }),
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.element
    })
  ])
};

TimerEvent.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  invertText: false,
  fontSize: 'base',
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerEvent;
