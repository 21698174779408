import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const DeliveryUnavailable = createSvgIcon(
  <>
    <path d="M13.5 2.25H0V4.5h11.25v12H9.182A3.372 3.372 0 0 0 6.375 15c-1.17 0-2.201.596-2.807 1.5H0v2.25h3.02a3.376 3.376 0 0 0 6.71 0h4.54a3.376 3.376 0 0 0 6.71 0H24v-6.662c0-.71-.401-1.359-1.037-1.677l-3.622-1.81-.838-3.351H13.5v-3Zm6.932 14.25a3.372 3.372 0 0 0-2.807-1.5c-1.17 0-2.201.596-2.807 1.5H13.5v-9h3.247l.662 2.65 4.341 2.17v4.18h-1.318Z" />
    <path d="m6.841 9.75 1.875 1.875-1.591 1.591-1.875-1.875-1.875 1.875-1.591-1.591L3.659 9.75 1.784 7.875l1.591-1.591L5.25 8.159l1.875-1.875 1.591 1.591L6.841 9.75Z" />
  </>,
  'DeliveryUnavailable'
);

export { DeliveryUnavailable };