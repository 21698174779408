import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useForkRef from '../../hooks/useForkRef';
import useEventCallback from '../../hooks/useEventCallback';

const ButtonBase = React.forwardRef((props, ref) => {

  const {
    children,
    className,
    component = 'button',
    disabled = false,
    LinkComponent = 'a',
    onBlur,
    onClick,
    onContextMenu,
    onDragLeave,
    onFocus,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    tabIndex = 0,
    type,
    ...other
  } = props;

  const buttonRef = React.useRef(null);

  function useEventHandler(eventCallback) {
    return useEventCallback((event) => {
      if (eventCallback) {
        eventCallback(event);
      }
      return true;
    });
  }

  const handleMouseDown = useEventHandler(onMouseDown);
  const handleContextMenu = useEventHandler(onContextMenu);
  const handleDragLeave = useEventHandler(onDragLeave);
  const handleMouseUp = useEventHandler(onMouseUp);
  const handleMouseLeave = useEventHandler(onMouseLeave);
  const handleTouchStart = useEventHandler(onTouchStart);
  const handleTouchEnd = useEventHandler(onTouchEnd);
  const handleTouchMove = useEventHandler(onTouchMove);
  const handleBlur = useEventHandler(onBlur);
  const handleFocus = useEventHandler(onFocus);

  const isNonNativeButton = () => {
    const button = buttonRef.current;
    return component && component !== 'button' && !(button.tagName === 'A' && button.href);
  };

  const handleKeyDown = useEventCallback((event) => {

    if (event.target === event.currentTarget && isNonNativeButton() && event.key === ' ') {
      event.preventDefault();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }

    // Keyboard accessibility for non interactive elements
    if (
      event.target === event.currentTarget
      && isNonNativeButton()
      && event.key === 'Enter'
      && !disabled
    ) {
      event.preventDefault();
      if (onClick) {
        onClick(event);
      }
    }
  });

  const handleKeyUp = useEventCallback((event) => {

    if (onKeyUp) {
      onKeyUp(event);
    }

    // Keyboard accessibility for non interactive elements
    if (
      onClick
      && event.target === event.currentTarget
      && isNonNativeButton()
      && event.key === ' '
      && !event.defaultPrevented
    ) {
      onClick(event);
    }
  });

  let ComponentProp = component;

  if (ComponentProp === 'button' && (other.href || other.to)) {
    ComponentProp = LinkComponent;
  }

  const buttonProps = {};
  if (ComponentProp === 'button') {
    buttonProps.type = type === undefined ? 'button' : type;
    buttonProps.disabled = disabled;
  } else {
    if (!other.href && !other.to) {
      buttonProps.role = 'button';
    }
    if (disabled) {
      buttonProps['aria-disabled'] = disabled;
    }
  }

  const handleRef = useForkRef(ref, buttonRef);

  // const ownerState = {
  //   ...props,
  //   component,
  //   disabled,
  //   tabIndex,
  // };

  const classes = classNames('sui-lab-btn-base', {
    'sui-cursor-default sui-pointer-events-none': disabled
  }, className);

  return (
    <ComponentProp
      className={classes}
      // ownerState={ownerState}
      onBlur={handleBlur}
      onClick={onClick}
      onContextMenu={handleContextMenu}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onDragLeave={handleDragLeave}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      ref={handleRef}
      tabIndex={disabled ? -1 : tabIndex}
      type={type}
      {...buttonProps}
      {...other}
    >
      {children}
    </ComponentProp>
  );
});

ButtonBase.displayName = 'ButtonBase';

ButtonBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  LinkComponent: PropTypes.elementType,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDragLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
  onTouchStart: PropTypes.func,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOfType([PropTypes.oneOf(['button', 'reset', 'submit']), PropTypes.string]),
};

ButtonBase.defaultProps = {};

export { ButtonBase };