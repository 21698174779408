import React from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { useMessage } from '../../hooks/useMessage';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoModel } from '../../hooks/usePromoModel';
import { MESSAGE_KEY } from '../../utils/constants';

export const ShopAllButton = () => {
  const { refinedNvalue, refinedCategoriesNvalue } = usePromoModel();
  const { selectedItemsModel } = usePromoCart();
  const buttonText = useMessage(
    refinedCategoriesNvalue ? MESSAGE_KEY.shopAllButtonCategory : MESSAGE_KEY.shopAllButton,
    !refinedCategoriesNvalue && selectedItemsModel.length
  );
  const nValue = refinedCategoriesNvalue || refinedNvalue;

  return (
    <Button
      fullWidth
      href={`/b/${nValue}`}
      variant="secondary"
      data-testid="shop-all-button"
    >
      {buttonText}
    </Button>
  );
};

ShopAllButton.displayName = 'ShopAllButton';
