/* eslint-disable @mizdra/layout-shift/require-size-attributes */
import React, { useEffect, useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  Card, CardBody, Typography, Button, Link
} from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { CreateAList } from '@thd-olt-component-react/list-forms';
import { EmptyMessagingPhp } from './empty-messaging/EmptyMessagingPhp';
import { dataModel } from './dataModel';
import { useLists } from './useLists';
import { UserListsCarouselTile } from './UserListCarouselTile';

const thdCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : null;

const emptyMessagingButton = ({ onClick }) => (
  <Button onClick={onClick} fullWidth variant="secondary">Create New Product List</Button>
);

export const UserListsCarousel = ({ page, title, entryId }) => {
  const { lists, loading, error, refetchLists } = useLists({ page });
  const { channel } = useContext(ExperienceContext);
  const numberImages = channel === 'mobile' ? 3 : 4;

  const sortedList = [...lists]
    .sort((lista, listb) => listb?.itemCount - lista?.itemCount)
    .splice(0, 3)
    .map((list) => {
      if (list?.images) {
        const currentImages = list?.images;
        const imagesToShow = [...currentImages];
        return { ...list, imagesToShow: imagesToShow.slice(0, numberImages) };
      }
      return { ...list };
    });

  const impressionData = {
    id: entryId || '',
    component: 'UserListsCarousel',
    name: 'UserListsCarousel',
    type: 'content',
    position: '',
    category: ''
  };

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('user-lists-carousel.ready');
  }, []);

  const handleListCreation = () => {
    refetchLists({ page: 1 });
  };

  const triggerAnalytics = () => {
    const proCustomerSegment = thdCustomer?.segment || null;
    window.LIFE_CYCLE_EVENT_BUS.trigger('user-lists-carousel.viewAllLists', {
      widgetTemplates: proCustomerSegment,
      widgetCampaign: 'view all',
      widgetComponent: '0'
    });
  };

  return (
    <ImpressionProvider data={impressionData}>
      <>
        { (sortedList?.length > 0 && !loading) && (
          <Card className="sui-w-full sui-h-full" data-component="UserListsCarousel">
            <div className="sui-w-full sui-flex sui-justify-between">
              <Typography variant="h3">{title}</Typography>
              <Link
                href="/list/view/summary"
                onClick={triggerAnalytics}
              >
                View All
              </Link>
            </div>
            <CardBody>
              {
                (sortedList?.length > 0 && !loading) && (sortedList.map((list, i) => (
                  <div key={`key-${list.name}-tile-${i}`}>
                    <UserListsCarouselTile list={list} i={i} />
                  </div>
                )))
              }
            </CardBody>
          </Card>
        ) }
        { (!sortedList.length && !loading && !!error) && (
          <EmptyMessagingPhp error>
            <CreateAList
              CustomCreateListActionElement={emptyMessagingButton}
              onCreate={handleListCreation}
            />
          </EmptyMessagingPhp>
        ) }
        { (!sortedList.length && !loading && !error) && (
          <EmptyMessagingPhp>
            <CreateAList
              CustomCreateListActionElement={emptyMessagingButton}
              onCreate={handleListCreation}
            />
          </EmptyMessagingPhp>
        ) }
      </>
    </ImpressionProvider>
  );
};

UserListsCarousel.propTypes = {
  page: number,
  title: string,
  entryId: string
};

UserListsCarousel.defaultProps = {
  page: 1,
  title: 'My Lists',
  entryId: undefined
};

UserListsCarousel.displayName = 'UserListsCarousel';

UserListsCarousel.dataModel = extend(dataModel, CreateAList);
