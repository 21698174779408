import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonGroup from './ButtonGroup.component';

class Button extends Component {

  static Group = ButtonGroup;

  handleClick = (event) => {
    const { disabled, onClick } = this.props;

    if (disabled) {
      event.preventDefault();
      return;
    }
    if (onClick) {
      onClick(event);
    }
  }

  render() {
    const {
      children,
      className,
      cta,
      dark,
      disabled,
      filter,
      inline,
      link,
      nohover,
      moveto,
      onClick,
      outline,
      remove,
      small,
      specialty,
      tag,
      transparent,
      ...attributes
    } = this.props;

    const primary = !outline && !specialty && !cta && !link && !filter;
    const classes = classNames(className, {
      disabled,
      nohover,
      bttn: primary || specialty,
      'bttn--primary': primary,
      'bttn--small': small,
      'bttn--inline': filter || (inline && !cta),
      'cta--inline': inline && cta,
      'bttn--cta': !inline && cta,
      'bttn-outline': outline || link || filter,
      'bttn-outline--primary': outline,
      'bttn-outline--dark': dark && outline,
      'bttn--primary--specialty': specialty,
      'bttn--link': link && (!remove && !moveto),
      'bttn--remove': link && remove,
      'bttn--moveto': link && moveto,
      'bttn--filter': filter,
      'bttn--transparent': link && transparent
    });

    const ElementType = (tag === 'a') ? 'a' : 'button';

    return (
      small
        ? (
          <ElementType
            className="bttn__wrapper"
            onClick={this.handleClick}
            /* eslint-disable-next-line */
            {...attributes}
          >
            <div className={classes}>
              <span className="bttn__content">{ children }</span>
            </div>
          </ElementType>
        ) : (
          <ElementType
            className={classes}
            onClick={this.handleClick}
            /* eslint-disable-next-line */
            {...attributes}
          >
            <span className="bttn__content">{ children }</span>
          </ElementType>
        )
    );
  }
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /**  */
  className: PropTypes.string,
  /** Call to Action - adds orange caret pointing to right */
  cta: PropTypes.bool,
  /** Replaces Home Depot orange with dark gray */
  dark: PropTypes.bool,
  /** Disable button */
  disabled: PropTypes.bool,
  /** Filter - rounded pill shaped button with close */
  filter: PropTypes.bool,
  /** Inline - will grow to fit the content, with padding left and right */
  inline: PropTypes.bool,
  /** Links - Optional icon add-ons: moveto and remove */
  link: PropTypes.bool,
  /** Disable hover */
  nohover: PropTypes.bool,
  /** Add-on for Links. Adds green plus icon to left */
  moveto: PropTypes.bool,
  /** On click handler */
  onClick: PropTypes.func,
  /** Border Buttons */
  outline: PropTypes.bool,
  /** Add-on for Links. Adds red x icon to left */
  remove: PropTypes.bool,
  /** Reduces height from 37px to 17px */
  small: PropTypes.bool,
  /** Specialty - adds lock icon to left */
  specialty: PropTypes.bool,
  tag: PropTypes.string,
  /** Makes the link background transparent */
  transparent: PropTypes.bool
};

Button.defaultProps = {
  className: null,
  cta: false,
  dark: false,
  disabled: false,
  filter: false,
  inline: false,
  link: false,
  nohover: false,
  moveto: false,
  onClick: null,
  outline: false,
  remove: false,
  small: false,
  specialty: false,
  tag: '',
  transparent: false
};

export default Button;
