import React, { useEffect } from 'react';
import {
  bool, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import styles from '../video-player.module.scss';

export const S3Player = React.forwardRef((props, ref) => {
  const {
    className, videoSrc, height, width
  } = props;

  const videoId = `thd-s3-video-${videoSrc}`;

  useEffect(() => {
    function handleVolumeChange(event) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('video-player.equalize-volume', {
        id: videoId,
        volume: Math.floor(event.target.volume * 100),
        mute: event.target.muted
      });
    }

    ref.current.addEventListener('volumechange', handleVolumeChange);

    return () => {
      ref.current.removeEventListener('volumechange', handleVolumeChange);
    };
  }, []);

  const containerStyle = {
    width,
    maxWidth: width
  };

  return (
    <div
      className={styles['video-player__container']}
      style={containerStyle}
      data-component="S3Player"
    >
      <video
        controls
        muted
        ref={ref}
        id={videoId}
        className={className}
        title={videoSrc}
        height={height}
        width={width}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
});

S3Player.propTypes = {
  className: string,
  height: number,
  videoSrc: string,
  width: number
};

S3Player.defaultProps = {
  className: null,
  height: null,
  videoSrc: null,
  width: null
};