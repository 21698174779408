/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import classnames from 'classnames';
import {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string
} from 'prop-types';
import { ProductImage } from '../ProductImage/ProductImage';
import { ImagePlaceholder } from '../ImagePlaceholder/ImagePlaceholder';
import { usePromoModel } from '../../hooks/usePromoModel';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';

export const ProductImageList = ({
  className,
  images,
  onImageClick,
  size,
  textFactory,
  inDrawer1,
  inDrawer2,
  showLocks
}) => {
  const {
    displayableFirstListItemIds,
    src1EligibilityCriteria,
    isBogo
  } = usePromoModel();
  const { type } = usePromoPresentation();
  const { firstListCartQuantity } = usePromoCart();
  const hasQuantity = images.some(({ quantity }) => quantity !== null && quantity !== undefined);
  const underQualifyingThresholdMet = firstListCartQuantity < src1EligibilityCriteria?.minPurchaseQuantity;

  const classes = classnames(
    'sui-flex',
    {
      'sui-pb-4': !isBogo && (inDrawer1 || inDrawer2),
      'sui-gap-4': (hasQuantity && type === 'pod') || type === 'card',
      'sui-gap-1': !hasQuantity && type === 'pod'
    },
    className
  );

  const imageOfProduct = (image, index) => (
    <ProductImage
      alt="Promotion Image"
      onClick={onImageClick ? () => onImageClick({ itemId: image.itemId }) : null}
      key={`image-${index}`}
      preview={image.preview}
      quantity={image.quantity}
      size={size}
      src={image.url}
      isLocked={showLocks}
      inDrawer={inDrawer1 || inDrawer2}
    >
      {textFactory(image.text)}
    </ProductImage>
  );

  const inDrawer1Logic = inDrawer1 && underQualifyingThresholdMet;

  const imageSelector = (image, index) => (
    <ImagePlaceholder
      key={`image-placeholder-${index}`}
      size={size}
      showBorder={((image.imageType === 'reward' && inDrawer2) || inDrawer1Logic) && image.preview}
      showDashes={(inDrawer1Logic || (image.imageType === 'reward' && inDrawer2)) ? !image.preview : true}
    >
      {textFactory(image.text)}
    </ImagePlaceholder>
  );

  const imageSelectorBogoCard = (displayableRewards) => displayableFirstListItemIds.length > displayableRewards
    && (
      <div
        className="sui-w-[33%] sui-min-w-[8.7rem] sui-min-h-[8.7rem] sui-max-w-[13.1rem]
          sui-max-h-[13.1rem] sui-bg-subtle sui-rounded-md sui-text-lg sui-text-center
          sui-content-center"
        key={`image-placeholder-${displayableRewards + 1}`}
      >
        {`+${displayableFirstListItemIds.length - displayableRewards}`}
      </div>
    );

  const drawerHeaderImages = images.map((image, index) => (
    (!image.preview && image.url) ? imageOfProduct(image, index) : imageSelector(image, index))
  );

  const ctaPreviewImages = (
    <>
      {images.map((image, index) => (
        (!image.previewCTA && image.url) ? imageOfProduct(image, index) : imageSelector(image, index))
      )}
      {type === 'card' && displayableFirstListItemIds?.length > 3
      && imageSelectorBogoCard(images.length)}
    </>
  );

  return (
    <div className={classes}>
      {(inDrawer1 || inDrawer2) ? drawerHeaderImages : ctaPreviewImages}
    </div>
  );
};

ProductImageList.displayName = 'ProductImageList';

ProductImageList.propTypes = {
  className: string,
  images: arrayOf(shape({
    innerText: string,
    itemId: string,
    preview: bool,
    previewCTA: bool,
    quantity: number,
    text: string,
    url: string,
  })).isRequired,
  onImageClick: func,
  size: string,
  textFactory: func,
  inDrawer1: bool,
  inDrawer2: bool,
  showLocks: bool
};

ProductImageList.defaultProps = {
  className: null,
  onImageClick: null,
  size: '5rem',
  textFactory: (text) => text,
  inDrawer1: false,
  inDrawer2: false,
  showLocks: false
};
