import React, { useContext } from 'react';
import { element, shape } from 'prop-types';
import { ImpressionContext } from './ImpressionContext';

const ImpressionProvider = ({ children, data, additionalData }) => {
  // parentCtx below is going to either be the first provider in the tree
  // else this is not going to be the first provider
  const parentCtx = useContext(ImpressionContext); // <--Change name of parentCTX to something else
  const parent = parentCtx.data;

  const ctx = {
    ...parentCtx,
    parent,
    data,
    additionalData,
    clickID: `${data?.id}-${data?.name}`,
    attributes: { 'data-adobe': `${data?.id}-${data?.name}` },
  };

  return (
    <ImpressionContext.Provider value={ctx}>
      {children}
    </ImpressionContext.Provider>
  );
};

ImpressionProvider.displayName = 'ImpressionProvider';

ImpressionProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: shape({}),
  children: element,
  additionalData: shape({})
};

ImpressionProvider.defaultProps = {
  data: {},
  children: null,
  additionalData: {}
};

export { ImpressionProvider };
