import React from 'react';
import { func } from 'prop-types';
import { DrawerBody, Button, ButtonGroup, Typography } from '@one-thd/sui-atomic-components';

import { useMessage } from '../../../hooks/useMessage';
import { useSelectedItems } from '../../../hooks/useSelectedItems';
import { MESSAGE_KEY } from '../../../utils/constants';
import { AddMultipleToCartButton } from '../../AddMultipleToCartButton/AddMultipleToCartButton';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';

export const WarningDrawer = ({ onBack, onClose }) => {
  const title = useMessage(MESSAGE_KEY.title);
  const warningDrawerPrimaryMessage = useMessage(MESSAGE_KEY.warningDrawerPrimaryMessage);
  const warningDrawerSecondaryMessage = useMessage(MESSAGE_KEY.warningDrawerSecondaryMessage);
  const warningDrawerContinueWithoutButton = useMessage(MESSAGE_KEY.warningDrawerContinueWithoutButton);
  const { selectedItemIds } = useSelectedItems();

  return (
    <>
      <PromoDrawerHeader
        onBack={onBack}
        onClose={onClose}
        title={title}
      />
      <DrawerBody>
        <div className="sui-py-8 sui-flex sui-flex-col sui-gap-8">
          <div className="sui-flex sui-flex-col sui-gap-2">
            <div className="sui-text-8xl sui-text-center">
              {warningDrawerPrimaryMessage}
            </div>
            <Typography align="center" variant="body-lg">
              {warningDrawerSecondaryMessage}
            </Typography>
          </div>
          <ButtonGroup orientation="vertical">
            <AddMultipleToCartButton itemIds={selectedItemIds} onComplete={onClose} simpleATCMessage />
            <Button
              fullWidth
              onClick={onClose}
              variant="secondary"
            >
              {warningDrawerContinueWithoutButton}
            </Button>
          </ButtonGroup>
        </div>
      </DrawerBody>
    </>
  );
};

WarningDrawer.displayName = 'WarningDrawer';

WarningDrawer.propTypes = {
  onBack: func.isRequired,
  onClose: func.isRequired
};
