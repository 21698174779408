import { useState, useEffect } from 'react';
import { debounce } from '@thd-olt-functional/utils';

const getMonth = (monthIndex) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthIndex];
};

const getDay = (dayIndex) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return days[dayIndex];
};

const getTwoDigitDate = (date) => {
  return date < 10 ? date.toString().padStart(2, '0') : date;
};

function getFormattedOrderDate(dateObject) {
  const monthName = getMonth(dateObject.getUTCMonth());

  return `${monthName} ${dateObject.getUTCDate()}, ${dateObject.getUTCFullYear()}`;
}

function getThdCustomer() {
  return typeof window !== 'undefined' ? window.THDCustomer?.default : {};
}

function publishAnalytics(eventName) {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(eventName);
  }
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' && window.innerWidth,
  );

  const updateViewport = debounce(300, () => {
    setWindowWidth(typeof window !== 'undefined' && window.innerWidth);
  });

  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  }, []);

  return windowWidth;
}

export {
  getDay,
  getFormattedOrderDate,
  getMonth,
  getTwoDigitDate,
  getThdCustomer,
  publishAnalytics,
  useWindowWidth,
};
