import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import { QueryProvider, params, shape, string as stringType } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { HomeServicePhpCarousel } from './carousel/HomeServicePhpCarousel';

const PersonalizedHomeServices = ({ entryID, hideCarouselArrows }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('personalized-home-services.ready');
  }, []);

  return (
    <ImpressionProvider
      data={{
        id: entryID,
        component: 'PersonalizedHomeServices',
        name: 'PersonalizedHomeServices',
        type: 'content',
        position: 1,
      }}
    >
      <section className="sui-w-full sui-h-full" data-component="PersonalizedHomeServices">
        <div className="sui-h-full">
          <QueryProvider cacheKey="php-home-services">
            <HomeServicePhpCarousel hideCarouselArrows={hideCarouselArrows} />
          </QueryProvider>
        </div>
      </section>
    </ImpressionProvider>

  );
};

PersonalizedHomeServices.propTypes = {
  hideCarouselArrows: bool,
  entryID: string
};

PersonalizedHomeServices.defaultProps = {
  hideCarouselArrows: false,
  entryID: ''
};

PersonalizedHomeServices.displayName = 'PersonalizedHomeServices';

PersonalizedHomeServices.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSource: stringType(),
    identifiers: shape({
      productLabel: stringType(),
    }),
  }),
};

export { PersonalizedHomeServices };
