import React from 'react';
import { func } from 'prop-types';
import { isFunction } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';

export const NotLocalized = ({ customRender }) => {

  const openLocalizerPopup = (event) => {
    event.preventDefault();
    const myStoreButton = document.querySelector('#myStore .MyStore__button')
      // mw
      || document.querySelector('.MyStore__button.MyStore--inline__button');

    if (myStoreButton) {
      myStoreButton.click();
      if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  if (isFunction(customRender)) {
    return customRender({ pickUpTemplate: PICKUP_TEMPLATES.NO_STORE_OPTION, values: { } });
  }

  return (
    <div className="store__message">
      {/* eslint-disable-next-line */}
  <a onClick={openLocalizerPopup} href="#" className="u__default-link">Set your store</a>
      <span className="store__dark">to see local availability</span>
    </div>
  );

};

NotLocalized.propTypes = {
  customRender: func
};

NotLocalized.defaultProps = {
  customRender: null
};

NotLocalized.displayName = 'NotLocalized';
