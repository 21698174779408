import { useState, useRef } from 'react';

export default function useControlled({ controlled, defaultValue }) {
  const { current: isControlled } = useRef(controlled !== undefined);
  const [uncontrolled, setUncontrolled] = useState(defaultValue);
  const value = isControlled ? controlled : uncontrolled;

  const setValue = (newValue) => {
    if (!isControlled) {
      setUncontrolled(newValue);
    }
  };

  return [value, setValue];
}