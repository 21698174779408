import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { visNavDataModel } from './dataModel';
import { VisualNavigationRenderer } from './VisualNavigationRenderer';

const VisualNavigation = ({
  componentId,
  componentClass,
  componentPosition,
  isInSection,
  lazyLoad,
  experienceHasSideNav,
  enableTheme,
  fontWeight
}) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass: 'VisualNavigation'
    }
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <VisualNavigationRenderer
      componentId={componentId}
      componentPosition={componentPosition}
      componentClass={componentClass}
      isInSection={isInSection}
      lazyLoad={lazyLoad}
      experienceHasSideNav={experienceHasSideNav}
      dataComponent={data?.component}
      visNavList={data?.component?.visualNavigationList}
      displayName={VisualNavigation.displayName}
      enableTheme={enableTheme}
      fontWeight={fontWeight}
    />
  );
};
VisualNavigation.displayName = 'VisualNavigation';
VisualNavigation.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  isInSection: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  experienceHasSideNav: PropTypes.bool,
  enableTheme: PropTypes.bool,
  fontWeight: PropTypes.string
};

VisualNavigation.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  isInSection: false,
  lazyLoad: false,
  experienceHasSideNav: false,
  enableTheme: false,
  fontWeight: 'display'
};

VisualNavigation.dataModel = visNavDataModel;

export { VisualNavigation };
