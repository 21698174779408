import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Storage = createSvgIcon(
  <>
    <path d="M2.25 13.125V24H0V0h2.25v10.875h19.5V0H24v24h-2.25V13.125H2.25Z" />
    <path d="M4.5 0v2.25h1.723L7.723 9h8.554l1.5-6.75H19.5V0h-15Zm10.973 2.25-1 4.5H9.527l-1-4.5h6.946ZM4.5 15v2.25h1.723l1.5 6.75h8.554l1.5-6.75H19.5V15h-15Zm10.973 2.25-1 4.5H9.527l-1-4.5h6.946Z" />
  </>,
  'Storage'
);

export { Storage };