import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Patio = createSvgIcon(
  <>
    <path d="M24 11.25 12 0 0 11.25h10.875V24h2.25V11.25H24ZM18.31 9H5.69L12 3.084 18.31 9Z" />
    <path d="M24 13.5h-2.25v3.75H16.5V24h2.25v-4.5h3V24H24V13.5ZM2.25 17.25H7.5V24H5.25v-4.5h-3V24H0V13.5h2.25v3.75Z" />
  </>,
  'Patio'
);

export { Patio };