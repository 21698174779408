/* eslint-disable jsx-a11y/interactive-supports-focus */ // Valid per CON-1955
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import animate from '../private/utils/animate';
import debounce from '../private/utils/debounce';
import ownerWindow from '../private/utils/ownerWindow';
import ownerDocument from '../private/utils/ownerDocument';
import useEventCallback from '../private/hooks/useEventCallback';
import ScrollbarSize from '../private/components/tab/ScrollbarSize';
import { TabScrollButton } from '../private/components/tab/TabScrollButton';

const nextItem = (list, item) => {
  if (list === item) {
    return list.firstChild;
  }
  if (item && item.nextElementSibling) {
    return item.nextElementSibling;
  }
  return list.firstChild;
};

const previousItem = (list, item) => {
  if (list === item) {
    return list.lastChild;
  }
  if (item && item.previousElementSibling) {
    return item.previousElementSibling;
  }
  return list.lastChild;
};

const moveFocus = (list, currentFocus, traversalFunction) => {
  let wrappedOnce = false;
  let nextFocus = traversalFunction(list, currentFocus);

  while (nextFocus) {
    // Prevent infinite loop.
    if (nextFocus === list.firstChild) {
      if (wrappedOnce) {
        return;
      }
      wrappedOnce = true;
    }

    // Same logic as useAutocomplete.js
    const nextFocusDisabled = nextFocus.disabled || nextFocus.getAttribute('aria-disabled') === 'true';

    if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
      // Move to the next element.
      nextFocus = traversalFunction(list, nextFocus);
    } else {
      nextFocus.focus();
      return;
    }
  }
};

const defaultIndicatorStyle = {};

/**
 * `Tabs` is the main container used to organize and group different, but related content allowing users to navigate
 * views without leaving the page.
 *
 * Related components: [Tab](#tab), [TabController](#tabcontroller), [TabList](#tablist)
 *
 * Usage:
 *
 * ```jsx
 * import { Tabs } from '@one-thd/sui-atomic-components';
 * ```
 */
const Tabs = React.forwardRef((props, ref) => {

  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    action,
    centered = false,
    children: childrenProp,
    className,
    component: TabsRoot = 'div',
    allowScrollButtonsMobile = false,
    indicatorColor = 'primary',
    onChange,
    orientation = 'horizontal',
    scrollButtons = 'auto',
    selectionFollowsFocus,
    TabIndicatorProps = {},
    TabScrollButtonProps = {},
    textcolor = 'primary',
    value,
    variant = 'standard',
    visibleScrollbar = false,
    ...other
  } = props;

  const scrollable = variant === 'scrollable';
  const isFullWidth = variant === 'fullWidth';

  const scrollStart = 'scrollLeft';
  const start = 'left';
  const end = 'right';
  const clientSize = 'clientWidth';
  const size = 'width';

  const ownerState = {
    fixed: !scrollable,
    hideScrollbar: scrollable && !visibleScrollbar,
    scrollableX: scrollable,
    // scrollableY: scrollable && vertical,
    centered: centered && !scrollable,
    scrollButtonsHideMobile: !allowScrollButtonsMobile,
  };

  const [mounted, setMounted] = React.useState(false);
  const [indicatorStyle, setIndicatorStyle] = React.useState(defaultIndicatorStyle);
  const [displayScroll, setDisplayScroll] = React.useState({
    start: false,
    end: false,
  });

  const [scrollerStyle, setScrollerStyle] = React.useState({
    overflow: 'hidden',
    scrollbarWidth: 0,
  });

  const valueToIndex = new Map();
  const tabsRef = React.useRef(null);
  const tabListRef = React.useRef(null);

  const getTabsMeta = () => {
    const tabsNode = tabsRef.current;
    let tabsMeta;
    if (tabsNode) {
      const rect = tabsNode.getBoundingClientRect();
      // create a new object with ClientRect class props + scrollLeft
      tabsMeta = {
        clientWidth: tabsNode.clientWidth,
        scrollLeft: tabsNode.scrollLeft,
        scrollTop: tabsNode.scrollTop,
        scrollLeftNormalized: tabsNode.scrollLeft,
        scrollWidth: tabsNode.scrollWidth,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
      };
    }

    let tabMeta;
    if (tabsNode && value !== false) {
      const children = tabListRef.current.children;

      if (children.length > 0) {
        const tab = children[valueToIndex.get(value)];
        tabMeta = tab ? tab.getBoundingClientRect() : null;
      }
    }
    return { tabsMeta, tabMeta };
  };

  const updateIndicatorState = useEventCallback(() => {
    const { tabsMeta, tabMeta } = getTabsMeta();
    let startValue = 0;
    let startIndicator;

    startIndicator = 'left';
    if (tabMeta && tabsMeta) {
      const correction = tabsMeta.scrollLeft;
      startValue = (1) * (tabMeta[startIndicator] - tabsMeta[startIndicator] + correction);
    }

    const newIndicatorStyle = {
      [startIndicator]: startValue,
      [size]: tabMeta ? tabMeta[size] : 0,
    };

    if (isNaN(indicatorStyle[startIndicator]) || isNaN(indicatorStyle[size])) {
      setIndicatorStyle(newIndicatorStyle);
    } else {
      const dStart = Math.abs(indicatorStyle[startIndicator] - newIndicatorStyle[startIndicator]);
      const dSize = Math.abs(indicatorStyle[size] - newIndicatorStyle[size]);

      if (dStart >= 1 || dSize >= 1) {
        setIndicatorStyle(newIndicatorStyle);
      }
    }
  });

  const scroll = (scrollValue, { animation = true } = {}) => {
    if (animation) {
      animate(scrollStart, tabsRef.current, scrollValue, {});
    } else {
      tabsRef.current[scrollStart] = scrollValue;
    }
  };

  const moveTabsScroll = (delta) => {
    let scrollValue = tabsRef.current[scrollStart];

    scrollValue += delta * (1);
    scrollValue *= 1;

    scroll(scrollValue);
  };

  const getScrollSize = () => {
    const containerSize = tabsRef.current[clientSize];
    let totalSize = 0;
    const children = Array.from(tabListRef.current.children);

    for (let i = 0; i < children.length; i += 1) {
      const tab = children[i];
      if (totalSize + tab[clientSize] > containerSize) {
        if (i === 0) {
          totalSize = containerSize;
        }
        break;
      }
      totalSize += tab[clientSize];
    }

    return totalSize;
  };

  const handleStartScrollClick = () => {
    moveTabsScroll(-1 * getScrollSize());
  };

  const handleEndScrollClick = () => {
    moveTabsScroll(getScrollSize());
  };

  const handleScrollbarSizeChange = React.useCallback((scrollbarWidth) => {
    setScrollerStyle({
      overflow: null,
      scrollbarWidth,
    });
  }, []);

  const getConditionalElements = () => {
    const conditionalElements = {};

    conditionalElements.scrollbarSizeListener = scrollable ? (
      <ScrollbarSize
        onChange={handleScrollbarSizeChange}
        className="sui-overflow-x-auto sui-overflow-y-hidden sui-no-scrollbar"
      />
    ) : null;

    const scrollButtonsActive = displayScroll.start || displayScroll.end;
    const showScrollButtons = scrollable && ((scrollButtons === 'auto' && scrollButtonsActive) || scrollButtons === true);

    conditionalElements.scrollButtonStart = showScrollButtons ? (
      <TabScrollButton
        orientation={orientation}
        direction="left"
        onClick={handleStartScrollClick}
        disabled={!displayScroll.start}
        ownerState={ownerState}
        {...TabScrollButtonProps}
      />
    ) : null;

    conditionalElements.scrollButtonEnd = showScrollButtons ? (
      <TabScrollButton
        orientation={orientation}
        direction="right"
        onClick={handleEndScrollClick}
        disabled={!displayScroll.end}
        ownerState={ownerState}
        {...TabScrollButtonProps}
      />
    ) : null;

    return conditionalElements;
  };

  const scrollSelectedIntoView = useEventCallback((animation) => {
    const { tabsMeta, tabMeta } = getTabsMeta();

    if (!tabMeta || !tabsMeta) {
      return;
    }

    if (tabMeta[start] < tabsMeta[start]) {
      // left side of button is out of view
      const nextScrollStart = tabsMeta[scrollStart] + (tabMeta[start] - tabsMeta[start]);
      scroll(nextScrollStart, { animation });
    } else if (tabMeta[end] > tabsMeta[end]) {
      // right side of button is out of view
      const nextScrollStart = tabsMeta[scrollStart] + (tabMeta[end] - tabsMeta[end]);
      scroll(nextScrollStart, { animation });
    }
  });

  const updateScrollButtonState = useEventCallback(() => {
    if (scrollable && scrollButtons !== false) {
      const { clientWidth, scrollLeft, scrollWidth } = tabsRef.current;
      const showStartScroll = scrollLeft > 1;
      const showEndScroll = scrollLeft < scrollWidth - clientWidth - 1;

      if (showStartScroll !== displayScroll.start || showEndScroll !== displayScroll.end) {
        setDisplayScroll({ start: showStartScroll, end: showEndScroll });
      }
    }
  });

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (tabsRef.current) {
        updateIndicatorState();
        updateScrollButtonState();
      }
    });
    const win = ownerWindow(tabsRef.current);
    win.addEventListener('resize', handleResize);

    let resizeObserver;

    if (typeof ResizeObserver !== 'undefined') {
      resizeObserver = new ResizeObserver(handleResize);
      Array.from(tabListRef.current.children).forEach((child) => {
        resizeObserver.observe(child);
      });
    }

    return () => {
      handleResize.clear();
      win.removeEventListener('resize', handleResize);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [updateIndicatorState, updateScrollButtonState]);

  const handleTabsScroll = React.useMemo(
    () => debounce(() => {
      updateScrollButtonState();
    }),
    [updateScrollButtonState],
  );

  React.useEffect(() => {
    return () => {
      handleTabsScroll.clear();
    };
  }, [handleTabsScroll]);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    updateIndicatorState();
    updateScrollButtonState();
  });

  React.useEffect(() => {
    scrollSelectedIntoView(defaultIndicatorStyle !== indicatorStyle);
  }, [scrollSelectedIntoView, indicatorStyle]);

  React.useImperativeHandle(
    action,
    () => ({
      updateIndicator: updateIndicatorState,
      updateScrollButtons: updateScrollButtonState,
    }),
    [updateIndicatorState, updateScrollButtonState],
  );

  const TabsIndicatorClasses = classNames('sui-absolute sui-h-2px sui-bottom-0 sui-w-full sui-bg-brand');

  const indicator = (
    <span
      className={TabsIndicatorClasses}
      style={{
        ...indicatorStyle
      }}
      {...TabIndicatorProps}
    />
  );

  let childIndex = 0;
  const children = React.Children.map(childrenProp, (child) => {

    const childValue = child.props.value === undefined ? childIndex : child.props.value;
    valueToIndex.set(childValue, childIndex);
    const selected = childValue === value;

    childIndex += 1;
    return React.cloneElement(child, {
      fullWidth: isFullWidth,
      indicator: selected && !mounted && indicator,
      selected,
      selectionFollowsFocus,
      onChange,
      textcolor,
      value: childValue,
      ...(childIndex === 1 && value === false && !child.props.tabIndex ? { tabIndex: 0 } : {}),
    });
  });

  const handleKeyDown = (event) => {
    const list = tabListRef.current;
    const currentFocus = ownerDocument(list).activeElement;

    const role = currentFocus.getAttribute('role');
    if (role !== 'tab') {
      return;
    }

    const previousItemKey = 'ArrowLeft';
    const nextItemKey = 'ArrowRight';

    switch (event.key) {
      case previousItemKey:
        event.preventDefault();
        moveFocus(list, currentFocus, previousItem);
        break;
      case nextItemKey:
        event.preventDefault();
        moveFocus(list, currentFocus, nextItem);
        break;
      case 'Home':
        event.preventDefault();
        moveFocus(list, null, nextItem);
        break;
      case 'End':
        event.preventDefault();
        moveFocus(list, null, previousItem);
        break;
      default:
        break;
    }
  };

  const tabsRootClasses = classNames('sui-flex sui-overflow-hidden sui-min-h-[48px] sui-border-b-1 sui-border-solid sui-border-primary');

  const TabsScrollerClasses = classNames('sui-relative sui-inline-block sui-flex-auto sui-whitespace-nowrap', {
    'sui-w-full sui-overflow-x-hidden': ownerState.fixed,
    'sui-no-scrollbar': ownerState.hideScrollbar,
    'sui-overflow-x-auto sui-overflow-y-hidden': ownerState.scrollableX,
  });

  const tabListClasses = classNames('sui-flex sui-gap-10', {
    'sui-justify-center': ownerState.centered
  });

  const conditionalElements = getConditionalElements();

  return (
    <TabsRoot
      className={tabsRootClasses}
      ref={ref}
      {...other}
    >
      {conditionalElements.scrollbarSizeListener}
      <div
        className={TabsScrollerClasses}
        style={{
          overflow: scrollerStyle.overflow,
          marginBottom: -scrollerStyle.scrollbarWidth
        }}
        ref={tabsRef}
        onScroll={handleTabsScroll}
      >
        <div
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          className={tabListClasses}
          onKeyDown={handleKeyDown}
          ref={tabListRef}
          role="tablist"
        >
          {children}
        </div>
        {mounted && indicator}
      </div>
      {conditionalElements.scrollButtonStart}
      {conditionalElements.scrollButtonEnd}
    </TabsRoot>
  );
});

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  /**
   * The label for the Tabs as a string.
   */
  'aria-label': PropTypes.string,
  /**
   * A single id or list of ids that label the tabs component.
   */
  'aria-labelledby': PropTypes.string,
  /**
   * Callback fired when the component is mounted.
   */
  action: PropTypes.func,
  /**
   * If `true`, the tabs are centered. Will only work with the `standard` variant.
   */
  centered: PropTypes.bool,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Internally used to pass classes to the component.
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used as the root node.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the component will show scroll buttons on smaller viewports.
   * Will only work if variant is set to `scrollable`.
   */
  allowScrollButtonsMobile: PropTypes.bool,
  /**
   * Color used for the tabs underline while selected.
   * @ignore
   */
  indicatorColor: PropTypes.string,
  /**
   * Callback fired whenever the component's value changes.
   */
  onChange: PropTypes.func,
  /**
   * Defines the components orientation layout.
   * NOTE: Currently hidden since design specifications don't state a use case for these.
   * @ignore
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Determines the behaviour of the scroll buttons when tabs variant is set to scroll: 'auto' will only show buttons
   * when the content overflows, 'true' will always show them and 'false' will never show them.
   */
  scrollButtons: PropTypes.oneOf(['auto', true, false]),
  /**
   * If `true`, tabs will also be selected whenever they're focused.
   */
  selectionFollowsFocus: PropTypes.bool,
  /**
   * Props applied to the indicator underline.
   * @ignore
   */
  TabIndicatorProps: PropTypes.object,
  /**
   * Props applied to the scroll buttons.
   * @ignore
   */
  TabScrollButtonProps: PropTypes.object,
  /**
   * Determines the color of the `tab` component.
   * @ignore
   */
  textcolor: PropTypes.string,
  /**
   * The value of the currently selected `tab` component. If this value is set to `false`,
   * no tab will be initially selected.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Determines additional display behavior for tabs: If set to `scrollable`,
   * it will allow scrolling and allow the display of
   * scroll buttons. If set to `fullWidth`, it will cause the tabs to grow and use all the available space
   * inside the container.
   */
  variant: PropTypes.oneOf(['standard', 'scrollable', 'fullWidth']),
  /**
   * Whichever value is set, will force the scrollbar to be visible.
   */
  visibleScrollbar: PropTypes.bool,
};

Tabs.defaultProps = {
};

export { Tabs };