import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useDataModel, params, shape, string, extend, arrayOf
} from '@thd-nucleus/data-sources';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody
} from '@one-thd/sui-atomic-components';
import { Cart } from '@one-thd/sui-icons';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { Product } from './subComponents/Product';

export const VerifyQuantityDrawer = ({
  cartOptions,
  cartReqParams,
  disabled,
  atcLabel,
  projectQuantity,
  showIcon,
  variant
}) => {
  const { itemId } = cartReqParams;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [updatedQuantity, setUpdatedQuantity] = React.useState(projectQuantity);
  const { data, loading } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: !itemId
  });

  const { product } = data || {};
  const { identifiers, pricing } = product || {};

  const updatedCartReqParams = {
    ...cartReqParams,
    quantity: updatedQuantity
  };

  const onClose = () => setDrawerOpen(false);

  return (
    <div>
      <Button
        variant={variant}
        startIcon={showIcon ? Cart : undefined}
        onClick={() => setDrawerOpen(true)}
        fullWidth
      >
        Add to Cart
      </Button>
      {(drawerOpen && !loading) && (
        <Drawer open={drawerOpen} onClose={onClose} fixed>
          <DrawerHeader title="Verify Your Quantity" onClose={onClose} />
          <DrawerBody>
            <Product
              identifiers={identifiers}
              itemId={itemId}
              product={product}
              pricing={pricing}
              updatedQuantity={updatedQuantity}
              setUpdatedQuantity={setUpdatedQuantity}
            />

            <hr className="sui-mx-4 sui-my-4" />

            <div
              className="sui-flex sui-flex-col-reverse sui-w-full sui-gap-3
              md:sui-grid md:sui-grid-cols-2 md:sui-flex-row"
            >
              <Button variant="secondary" fullWidth onClick={onClose}>
                Cancel
              </Button>
              <AddToCart
                cartOptions={cartOptions}
                cartReqParams={updatedCartReqParams}
                variant="primary"
                disabled={disabled}
                fullWidth
                onClick={() => setDrawerOpen(false)}
              >
                {atcLabel}
              </AddToCart>
            </div>
          </DrawerBody>
        </Drawer>
      )}
    </div>
  );

};

VerifyQuantityDrawer.propTypes = {
  cartOptions: PropTypes.shape({}).isRequired,
  cartReqParams: PropTypes.shape({
    itemId: PropTypes.string.isRequired
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  atcLabel: PropTypes.string.isRequired,
  projectQuantity: PropTypes.number.isRequired,
  showIcon: PropTypes.bool,
  variant: PropTypes.string.isRequired
};

VerifyQuantityDrawer.defaultProps = {
  showIcon: false
};

VerifyQuantityDrawer.dataModel = extend({
  product: params({
    itemId: string().isRequired()
  }).shape({
    dataSource: string(),
    pricing: shape({
      value: string()
    }),
    identifiers: shape({
      canonicalUrl: string(),
      brandName: string(),
      productLabel: string()
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string()
      }))
    }),
  })
});
