/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Badge as SuiBadge } from '@one-thd/sui-atomic-components';

export const Badge = ({ text }) => {
  // note: background color could be replace later for the closest one in stencil(sui-bg-info-strong)
  // for now to get the same as in old badge.
  const isEventBadge = text === 'New This Season';
  const badgeColor = isEventBadge ? 'brand' : 'info';

  return (
    <div className="sui-pl-1 sui-pt-2">
      <SuiBadge variant="regular" color={badgeColor} size="small">{text}</SuiBadge>
    </div>

  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
};