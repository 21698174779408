export const convertToHours = (timeToExpiration) => {
  return Math.floor((timeToExpiration / (1000 * 60 * 60)) % 24);
};

export const convertToMinutes = (timeToExpiration) => {
  return Math.floor((timeToExpiration / 1000 / 60) % 60);
};

export const getCondensedTimer = (timerHours, timerMinutes) => {
  if (timerHours >= 1 && timerMinutes >= 1) {
    const hourSuffix = timerHours === 1 ? ' Hr' : ' Hrs';
    const minuteSuffix = ' Min';
    return `${timerHours}${hourSuffix} ${timerMinutes}${minuteSuffix}`;
  }

  if (timerHours >= 1) {
    const hourSuffix = timerHours === 1 ? ' Hr' : ' Hrs';
    return `${timerHours}${hourSuffix}`;
  }

  if (timerMinutes >= 1) {
    const minuteSuffix = ' Min';
    return `${timerMinutes}${minuteSuffix}`;
  }
  return '';
};