export const PICKUP_TEMPLATES = {
  IN_STOCK: 'IN_STOCK',
  IN_STOCK_NEARBY: 'IN_STOCK_NEARBY',
  IN_STOCK_CLEARANCE: 'IN_STOCK_CLEARANCE',
  LIMITED_STOCK_CLEARANCE: 'LIMITED_STOCK_CLEARANCE',
  BOSS: 'BOSS',
  NO_STORE_OPTION: 'NO_STORE_OPTION',
  LIMITED_AVAILABILITY_NEARBY: 'LIMITED_AVAILABILITY_NEARBY',
  LIMITED_AVAILABILITY: 'LIMITED_AVAILABILITY',
  BOPIS_OFF_MESSAGE: 'BOPIS_OFF_MESSAGE',
  EMPTY_STORE_MESSAGE: 'EMPTY_STORE_MESSAGE',
  LIVE_GOODS_POD: 'LIVE_GOODS_POD'
};

export const DELIVERY_TEMPLATES = {
  DEFAULT_SHIPPING: 'DEFAULT_SHIPPING',
  EMPTY_SHIPPING_MESSAGE: 'EMPTY_SHIPPING_MESSAGE'
};