export const staticMetadata = {
  title: 'The Home Depot',
  description:
    'Shop online for all your home improvement needs: appliances, bathroom decorating ideas, kitchen remodeling, patio furniture, power tools, bbq grills, carpeting, lumber, concrete, lighting, ceiling fans and more at The Home Depot.', // eslint-disable-line
  facebookAdmins: '100005416960152',
  siteName: 'The Home Depot',
  type: 'homepage',
  canonicalURL: '/',
  robots: 'noodp',
};
