import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createBadge from '../utils/createBadge';

/**
 * The `Badge` component serves as a visual indicator to highlight a small amount of content.
 * It comes with three variants: subtle, regular and strong.
 *
 * Related components: [NotificationBadge](#notificationbadge), [Pill](#pill), [Chip](#chip)
 *
 * Usage:
 *
 * ```jsx
 * import { Badge } from '@one-thd/sui-atomic-components';
 * ```
 */
const Badge = React.forwardRef((props, ref) => {

  const {
    children,
    endIcon: endIconProp,
    startIcon: startIconProp,
    color = 'primary',
    size = 'base',
    variant = 'strong',
    ...other
  } = props;

  const { classes } = createBadge({
    color,
    variant,
    size,
  });

  let startIcon = null;
  if (startIconProp && React.isValidElement(startIconProp)) {
    startIcon = React.cloneElement(startIconProp, {
      style: {
        marginLeft: variant === 'subtle' ? '7px' : '8px',
        marginRight: '-4px'
      },
      size: startIconProp.props.size === undefined
        ? 'small'
        : startIconProp.props.size
    });
  }

  let endIcon = null;
  if (endIconProp && React.isValidElement(endIconProp)) {
    endIcon = React.cloneElement(endIconProp, {
      style: {
        marginRight: variant === 'subtle' ? '7px' : '8px',
        marginLeft: '-4px'
      },
      size: endIconProp.props.size === undefined
        ? 'small'
        : endIconProp.props.size
    });
  }

  const rootClasses = classNames(classes, 'sui-max-w-full sui-inline-flex sui-items-center sui-justify-center sui-align-middle sui-box-border sui-whitespace-nowrap', {
    'sui-h-6': size === 'small',
    'sui-h-7': size === 'base',
    'sui-h-8': size === 'large'
  });

  const spanClasses = classNames('sui-overflow-hidden sui-text-ellipsis sui-pl-2 sui-pr-2 sui-whitespace-nowrap');

  return (
    <div
      className={rootClasses}
      ref={ref}
      {...other}
    >
      {startIcon}
      <span className={spanClasses}>
        {children}
      </span>
      {endIcon}
    </div>
  );
});

Badge.displayName = 'Badge';

Badge.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The color of the component.
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'inverse', 'brand', 'success', 'danger', 'medium', 'warning', 'info']),
  /**
   * An icon to display after the content inside the badge.
   * Needs to be a React element type (ie. MyIcon), not an instance (ie. <MyIcon />)
   */
  endIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  /**
   * The size of the component. Also affects the font size.
   * @default 'base'
   */
  size: PropTypes.oneOf(['small', 'base', 'large']),
  /**
   * An icon to display before the content inside the badge.
   * Needs to be a React element type (ie. MyIcon), not an instance (ie. <MyIcon />)
   */
  startIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  /**
   * The variant to use.
   * @default 'strong'
   */
  variant: PropTypes.oneOf(['subtle', 'regular', 'strong'])
};

export { Badge };
