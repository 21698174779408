import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Unlock = createSvgIcon(
  <>
    <path d="M16.125 12.75v4.5h-2.25v-4.5h2.25Z" />
    <path d="M2.25 5.625a3.375 3.375 0 0 1 6.75 0v2.258L6 8.44v13.042l9 2.485 9-2.485V8.44l-9-1.672-3.75.697v-1.84a5.625 5.625 0 0 0-11.25 0V8.25h2.25V5.625Zm6 4.685L15 9.057l6.75 1.253v9.459L15 21.633l-6.75-1.864V10.31Z" />
  </>,
  'Unlock'
);

export { Unlock };