import React, { useContext, useEffect, useState } from 'react';
import { string, func, number, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { Swatch } from '../swatch/swatch';
import { publish } from '../../publisher';
import {
  NUMBER_OF_MINI_SWATCHES_ON_MOBILE,
  NUMBER_OF_MINI_SWATCHES_ON_DESKTOP,
  SWATCH_SIZE
} from '../../statics';
import styles from './super-sku-mini-swatches.module.scss';

const cx = classNames.bind(styles);

export const SuperSkuMiniSwatches = (props) => {
  const {
    channel = 'desktop',
  } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const {
    itemId,
    onChange,
    onHover,
    numberOfMiniSwatches,
    center
  } = props;

  const [state, setState] = useState({
    swatches: [],
    parentId: null,
    shouldSkip: false
  });

  const opts = {
    variables: {
      itemId
    },
    fetchPolicy: 'cache-first'
  };
  const { data } = useDataModel('product', opts);

  const { parentId, canonicalUrl } = data?.product?.identifiers || {};
  const {
    swatches: swatchesData = [],
    totalNumberOfOptions: totalNumberOfOptionsAvailable = 0
  } = data?.product?.info || {};

  useEffect(() => {
    const {
      swatches = [],
      totalNumberOfOptions = 0
    } = data?.product?.info || {};
    if (swatches?.length && !state.shouldSkip) {
      setState((prevState) => ({
        ...prevState,
        swatches,
        shouldSkip: true,
        totalNumberOfOptions,
        parentId
      }));
    } else if (totalNumberOfOptions > 0) {
      setState((prevState) => ({
        ...prevState,
        totalNumberOfOptions
      }));
    }
  }, [data?.product?.info]);

  let numberOfMiniSwatchesToDisplay = isMobile ? NUMBER_OF_MINI_SWATCHES_ON_MOBILE
    : NUMBER_OF_MINI_SWATCHES_ON_DESKTOP;

  if (numberOfMiniSwatches) {
    numberOfMiniSwatchesToDisplay = numberOfMiniSwatches;
  }

  const handleAttributeValueClick = (event, clickedItemId) => {
    onChange({ itemId: clickedItemId });
    const newSwatches = state.swatches.map((swatch) => {
      return {
        ...swatch,
        isSelected: swatch?.itemId === clickedItemId
      };
    });
    setState((prevState) => ({
      ...prevState,
      swatches: newSwatches
    }));

    publish('click-swatch', {});
  };

  const handleAttributeValueHover = (event, hoveredItemId) => {
    onHover({
      itemId: hoveredItemId,
      isHovered: true
    });
  };

  const handleResetHover = () => {
    const selectedSwatch = state.swatches.find((swatch) => swatch.isSelected);
    onHover({
      itemId: selectedSwatch?.itemId || itemId,
      isHovered: false
    });
  };

  const {
    swatches = [],
    totalNumberOfOptions = 0
  } = state;
  const numberOfOptions = totalNumberOfOptionsAvailable || totalNumberOfOptions;
  const availableSwatches = swatches.length ? swatches : swatchesData;
  const toDisplay = (availableSwatches || []).slice(0, numberOfMiniSwatchesToDisplay);

  const moreOptionsClassNames = cx(
    'super-sku-mini-swatch__link',
    {
      'super-sku-mini-swatch__link--align-center': (isMobile || center),
      'super-sku-mini-swatch__link--blue': !isMobile,
    }
  );

  const sskuSwatchContainerClass = cx('super-sku-mini-swatch__container',
    {
      'super-sku-mini-swatch__container--align-center': center,
      'super-sku-mini-swatch__container--three-grid': isMobile
    }
  );
  const swatchSize = isMobile ? SWATCH_SIZE.MD : SWATCH_SIZE.LG;

  return (
    <div data-testid="ssku-min-swatch" className={cx('super-sku-mini-swatch')} data-component="SuperSkuMiniSwatches">
      <div className={sskuSwatchContainerClass}>
        {(toDisplay.length > 1) && toDisplay.map((swatch, index) => {
          const {
            isSelected,
            itemId: swatchItemId,
            label,
            swatchImgUrl
          } = swatch || {};
          if (!swatchImgUrl) return null;
          return (
            <Swatch
              key={`ssku-${swatchImgUrl}`}
              isSelected={isSelected}
              isAvailable
              value={label}
              swatchGuid={swatchImgUrl}
              onClick={(event) => handleAttributeValueClick(event, swatchItemId)}
              onMouseEnter={((event) => handleAttributeValueHover(event, swatchItemId))}
              onMouseLeave={handleResetHover}
              channel={channel}
              miniSwatch
              size={swatchSize}
            />
          );
        })}
      </div>
      {((numberOfOptions > numberOfMiniSwatchesToDisplay
        || availableSwatches?.length < numberOfOptions) && numberOfOptions > 1)
        && (
          <a
            href={canonicalUrl}
            className={moreOptionsClassNames}
          >
            More Options Available
          </a>
        )}
    </div>
  );
};

SuperSkuMiniSwatches.propTypes = {
  itemId: string.isRequired,
  onChange: func.isRequired,
  onHover: func.isRequired,
  numberOfMiniSwatches: number,
  center: bool
};

SuperSkuMiniSwatches.defaultProps = {
  numberOfMiniSwatches: null,
  center: false
};

SuperSkuMiniSwatches.dataModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      canonicalUrl: stringType(),
      parentId: stringType()
    }),
    info: shapeType({
      swatches: arrayOfType(shapeType({
        isSelected: boolType(),
        itemId: stringType(),
        label: stringType(),
        swatchImgUrl: stringType(),
        url: stringType(),
        value: stringType(),
      })),
      totalNumberOfOptions: numberType()
    })
  })
};
