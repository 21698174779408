import React, { useContext } from 'react';
import { shape, func, string, bool } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import { productShape } from '../../helpers/pod-fulfillment-utils';
import { UnavailableIcon } from '../../helpers/icon-utils';
import { isFunction, isMobile } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';

export const NoStoreOption = ({
  storeMessage,
  enableOverlayLink,
  product,
  onCheckNearbyStoresClick,
  excludedState,
  customRender
}) => {
  const { channel } = useContext(ExperienceContext);
  const { storeName } = useStore();

  const overlayLink = (isMobile(channel) && !excludedState)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
        excludedState={excludedState}
      />
    );

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.NO_STORE_OPTION,
        icon: UnavailableIcon,
        values:
        { message: storeMessage, overlayLink, enableOverlayLink, storeName } });
  }

  return (
    <div className="store__message">
      <div className="store__icon">{UnavailableIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">
          Pickup
        </div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__primary">
            {storeMessage}
          </span>
          {enableOverlayLink && overlayLink}
        </div>
      </div>
    </div>
  );
};

NoStoreOption.propTypes = {
  enableOverlayLink: bool,
  onCheckNearbyStoresClick: func,
  product: shape({ productShape }).isRequired,
  storeMessage: string,
  excludedState: string,
  customRender: func
};

NoStoreOption.defaultProps = {
  enableOverlayLink: true,
  onCheckNearbyStoresClick: () => {},
  storeMessage: 'Unavailable',
  excludedState: '',
  customRender: null
};

NoStoreOption.displayName = 'NoStoreOption';
