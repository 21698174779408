export const selectPreferredImageSrc = ({ damImage = {}, defaultImageUrl = '' }) => {
  if (damImage?.damContentSelector && damImage.damContentSelector?.assetData?.length >= 1) {
    return damImage.damContentSelector?.assetData?.[0]?.selectedImageUrl;
  }

  if (damImage?.damDownloadedContent) {
    return damImage?.damDownloadedContent.url;
  }

  return defaultImageUrl;
};