/* eslint-disable default-case, consistent-return, max-len */
import { PRO_HEADING, SBOTD_HEADING } from '../constants';

const noop = () => { };

// private methods for exported function isFulfillableProducts
const isFulfillableLocation = (location) => location?.inventory?.isInStock;
const isFulfillableService = (service) => service?.locations?.some(isFulfillableLocation);
const isFulfillableOption = (fulfillmentOption) => fulfillmentOption?.fulfillable && fulfillmentOption.services?.some(isFulfillableService);
const isFulfillableProduct = (product) => product?.fulfillment?.fulfillmentOptions?.some(isFulfillableOption);

/**
 *
 * @param {Array} firstProducts matching the product dataModel
 * @param {Array} remainingProducts matching the product dataModel
 * @returns {Array} only the fulfillable products
 */
const getFulfillableProducts = (firstProducts = [], remainingProducts = []) => {
  return [...firstProducts, ...remainingProducts].filter(isFulfillableProduct);
};

/**
 *
 * @param {Array} products matching the product dataModel
 * @returns {Array} unique string itemIds
 */
const getItemIds = (products) => [...new Set((products || []).map(({ itemId }) => itemId))];

/**
 * @param {string} promoType sbotd | pro
 * @returns {string} headingTitle custom heading for promo
 */
const getPromoHeading = (promoType) => {
  switch (promoType) {
  case 'sbotd': return SBOTD_HEADING;
  case 'pro': return PRO_HEADING;
  }
  return '';
};

export {
  noop,
  getFulfillableProducts,
  getItemIds,
  getPromoHeading
};
