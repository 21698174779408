/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

import { transformModel } from './FulfillmentCartAdapter';

const BOPIS_FFM = 'BOPIS';
const BOSS_FFM = 'ShipToStore';
const STH_FFM = 'ShipToHome';
const APPLIANCE_FFM = 'DirectDelivery';
const BODFS_FFM = 'DeliverFromStore';

const getIntFromString = (intString) => {
  try {
    return (parseInt(intString) || 0);
  } catch (err) {
    console.error(err);
  }
  return 0;
};

const getFloatFromString = (floatString) => {
  try {
    return (parseFloat(floatString) || 0.0);
  } catch (err) {
    console.error(err);
  }
  return 0.0;
};

const getStoreName = (storeName) => {
  try {
    storeName = storeName.substring(0, storeName.indexOf('#') - 1);
  } catch (err) {
    console.error(err);
  }
  return storeName;
};
const handleFulfillmentData = (item, itemModel) => {
  try {
    item.__availableFulfillments = [];
    item.__cartFulfillments = [];

    itemModel.fulfillmentModel.forEach((ffm) => {
      const { fulfillmentMethod, selected } = ffm;
      item.__availableFulfillments.push(fulfillmentMethod);
      if (selected) {
        item.__selectedFulfillment = fulfillmentMethod;
      }
      if (ffm.pickUpStore) {
        // eslint-disable-next-line prefer-template
        item.__storeId = (getIntFromString(ffm.pickUpStore) + '');
        item.__storeName = getStoreName(ffm.pickUpStoreAddress);
        item.__curbsidePickupEligible = ffm.curbsidePickupEligible || false;
      }
      if (fulfillmentMethod === STH_FFM
        || fulfillmentMethod === BODFS_FFM
        || fulfillmentMethod === APPLIANCE_FFM) {
        item.__deliveryZipCode = ffm.deliveryZipCode || ffm.zipCode;
      }
      item.__cartFulfillments.push(ffm);
    });
    delete item.fulfillmentModel;

    item.selectedFulfillment = item.__selectedFulfillment || null;
    item.availableFulfillments = item.__availableFulfillments || null;

    const selectedFfm = item.__selectedFulfillment;
    if (selectedFfm === BOSS_FFM || selectedFfm === BOPIS_FFM) {
      item.__fulfillmentGroup = 'pickup';
    } else if (selectedFfm === STH_FFM || selectedFfm === BODFS_FFM) {
      item.__fulfillmentGroup = 'delivery';
    } else if (selectedFfm === APPLIANCE_FFM) {
      item.__fulfillmentGroup = 'appliance';
    }
  } catch (err) {
    console.error(err);
  }
  return item;
};

const getWarrantyAddOn = (itemModel) => {
  let addOn = null;
  try {
    const entry = itemModel.warrantyLineItems.lineItem[0];
    const {
      lineItemId = null, // "51909b20-b95a-11ed-8482-ff0253108d48",
      itemId = null, // "315157800",
      unitPrice = null, // "12.00",
      quantity = null, // "2",
      totalItemPrice = null, // "24.00",
      // lineItemType, // "Merchandize",
      storeSKU = null, // "1005899128",
      description = null, // "2-Year Indoor Lawn and Garden Protection Plan $50-$99.99",
      // brandName, // "The Home Depot Protection Plan by Allstate",
      duration = null, // "2-year",
      // moreDetailsUrl, // https://www.homedepot.com/c/Home_Depot_protectionPlan_Coverage_Details
    } = entry;
    addOn = {
      storeSKU,
      type: 'protectionPlan',
      // protectionPlanParentId: '315147749', // itemId,
      // protectionPlanParentId: 'need this from item.product?',
      price: unitPrice,
      // price: totalItemPrice,
      // termLength: duration.replace('-year', ''),
      // category: '', // e.g. Indoor Garden - missing from cart info
      // detailsUrl: '', // e.g. www.protection-plans.com/content/thd/en/IndoorGarden/Index"... not moreDetailsUrl
      // added to existing schema
      quantity: parseInt(quantity),
      totalPrice: totalItemPrice || null,
      itemId,
      id: lineItemId,
      description,
      // storeId: item.storeId, // this must be set previously in handle fulfillments
      // itemPrice: item.unitPrice,
      selected: (!!lineItemId),
      protectionPlanParentId: storeSKU,
      storeId: null,
      // termLength: duration ? duration.replace('-year', '') : null,
      termLength: duration || null,
      detailsUrl: null,
      // appliance fields
      installation: null,
      configAttr: itemId,
      category: null,
      brandName: null,
      descriptiveAttributes: null
    };

  } catch (err) {
    console.error(err);
  }
  return addOn;
};
const getPartsAndServicesAddOns = (itemModel) => {
  let addOns = [];
  try {
    let addOn = {
      storeSKU: null, // String,
      installation: null, // Boolean,
      configAttr: null, // String,
      termLength: null, // String,
      type: null, // String,
      price: null, // BigDecimal,
      quantity: null, // Int,
      itemId: null, // String,
      id: null, // String,
      category: null, // String,
      description: null, // String,
      detailsUrl: null, // String,
      message: null, // Message,
      selected: null, // Boolean,
      brandName: null,
      descriptiveAttributes: null
    };
    addOns.push(addOn);
  } catch (err) {
    console.error(err);
  }
  return addOns;
};

const getServiceLineItemAddOns = (itemModel) => {
//   "serviceLineItems": {
//     "lineItem": [
//         {
//             "unitPrice": "119.0",
//             "quantity": "0",
//             "totalItemPrice": "119.0",
//             "storeSKU": "1004637080",
//             "description": "SERVICE",
//             "serviceType": "Assembly"
//         }
//     ]
// },
  let addOns = [];

  try {
    const entry = itemModel.serviceLineItems.lineItem[0];
    const {
      lineItemId = null, // "51909b20-b95a-11ed-8482-ff0253108d48",
      unitPrice = null, // "12.00",
      quantity = null, // "2",
      totalItemPrice = null, // "24.00",
      storeSKU = null, // "1005899128",
      description = null, // "2-Year Indoor Lawn and Garden Protection Plan $50-$99.99",
    } = entry;

    let addOn = {
      installation: null, // Boolean,
      configAttr: storeSKU,
      termLength: null, // String,
      type: 'SERVICE', // String,
      price: unitPrice, // BigDecimal,
      totalPrice: totalItemPrice,
      quantity,
      itemId: storeSKU,
      id: lineItemId,
      category: 'optional', // String,
      description,
      detailsUrl: null, // String,
      selected: (quantity !== '0'), // Boolean,
      brandName: null,
      descriptiveAttributes: null
    };
    addOns.push(addOn);
  } catch (err) {
    console.error(err);
  }
  return addOns;
};

const setAddOns = (itemModel) => {
  let addOns = null;
  try {
    if (itemModel.warrantyLineItems && itemModel.warrantyLineItems.lineItem) {
      const addOn = getWarrantyAddOn(itemModel);
      if (addOn) {
        addOns = [];
        addOns.push(addOn);
      }
    }
    if (itemModel.optionalLineItems || itemModel.requiredLineItems) {
      const psAddOns = getPartsAndServicesAddOns(itemModel);
      if (psAddOns && psAddOns.length) {
        addOns = addOns || [];
        addOns = addOns.concat(psAddOns);
      }
    }
    if (itemModel.serviceLineItems) {
      const svcAddOns = getServiceLineItemAddOns(itemModel);
      if (svcAddOns && svcAddOns.length) {
        addOns = addOns || [];
        addOns = addOns.concat(svcAddOns);
      }
    }

    // handle other addons
    // parts and services
    // "type": "haulAway|required"
    // installation? assembly?

    if (addOns) {
      addOns.forEach((addOn) => {
        addOn.totalPrice = addOn.totalPrice || null;
        addOn.detailsUrl = addOn.detailsUrl || null;
        addOn.storeId = addOn.storeId || null;
        addOn.protectionPlanParentId = addOn.protectionPlanParentId || null;
      });
    }

  } catch (err) {
    console.error(err);
  }
  return addOns;
};

const setKeyProductFeatures = (itemModel) => {
  let kpf = null;
  try {
    // get kpf
    // name: String,
    // refinementId: String,
    // refinementUrl: String,
    // value: String
  } catch (err) {
    console.error(err);
  }
  return kpf;
};

const setInfo = (itemModel) => {
  let info = null;
  try {
    // info = {};

    // hidePrice: Boolean,
    // ecoRebate: Boolean,
    // quantityLimit: Int,
    // sskMin: String,
    // sskMax: String,
    // unitOfMeasureCoverage: String,
    // wasMaxPriceRange: String,
    // wasMinPriceRange: String,
    // fiscalYear: String,
    // productDepartment: String,
    // classNumber: String,
    // replacementOMSID: String,
    // hasSubscription: Boolean,
    // isBuryProduct: String,
    // isSponsored: String,
    // isGenericProduct: String,
    // isLiveGoodsProduct: Boolean,
    // sponsoredBeacon: String,
    // sponsoredMetadata: String,
    // globalCustomConfigurator: String,
    // returnable: String,
    // productSubType: String,
    // categoryHierarchy: String,
    // samplesAvailable: Boolean,
    // customerSignal: String,
    // productDepartmentId: String,
    // augmentedReality: Boolean,
    // swatches: String,
    // totalNumberOfOptions: String,
    // paintBrand: String,
    // dotComColorEligible: Boolean,
    // label: String,
    // prop65Warning: Boolean,
    // displayWhatWeOffer: Boolean,
    // recommendationFlags: RecommendationFlags
  } catch (err) {
    console.error(err);
  }
  return info;
};

const setSpecificationGroup = (itemModel) => {
  let specs = null;
  try {
    if (itemModel.superSkuModel) {
      try {
        specs = specs || [{ specifications: [], specTitle: 'SuperSku' }];
        Object.keys(itemModel.superSkuModel).forEach((key) => {
          specs[0].specifications.push({
            specName: key,
            specValue: itemModel.superSkuModel[key]
          });
        });
        // type Specifications { specName: String specValue: String }
        // type SpecificationGroup {
        //   specTitle: String
        //   specifications: [Specifications ] }
      } catch (err) {
        console.error(err);
      }
    }
  } catch (err) {
    console.error(err);
  }
  return specs;
};

const getDiscount = (itemModel) => {
  let discount = null;
  try {
    discount = {
      dollarOff: null,
      percentOff: itemModel.percentOff || null,
    // type Promotion { type: String
    //   description: String
    //   dollarOff: BigDecimal
    //   percentageOff: BigDecimal
    //   savingsCenter: String
    //   savingsCenterPromos: String
    //   specialBuySavings: String
    //   specialBuyDollarOff: String
    //   specialBuyPercentageOff: String
    //   experienceTag: String
    //   subExperienceTag: String
    //   itemList: String
    //   reward: String
    //   nvalues: String
    //   brandRefinementId: String
    //   __typename: String
    //   dates: Dates
    };
  } catch (err) {
    console.error(err);
  }
  return discount;
};

const setPricing = (itemModel) => {
  let pricing = null;
  try {
    pricing = {
      value: (getFloatFromString(itemModel.specialOfferPrice) || getFloatFromString(itemModel.unitPrice)),
      alternatePriceDisplay: false, // !!itemModel.specialOfferPrice, // ? when is this true?
      original: getFloatFromString(itemModel.unitPrice),
      mapAboveOriginalPrice: null, // String,
      message: null, // String,
      preferredPriceFlag: null, // Boolean,
      specialBuy: null, // String,
      unitOfMeasure: null, // String,
      conditionalPromotions: null, // arrayOf(String),
      alternate: null, // Alternate,
      // promotion: getPricingPromotion(itemModel)
      discount: getDiscount(itemModel),
      total: (
        (getFloatFromString(itemModel.specialOfferPrice)
        || getFloatFromString(itemModel.unitPrice)) * itemModel.quantity
      ),
      totalWithNoDiscount: (itemModel.totalItemPrice * itemModel.quantity),
      type: null,
      valueStartDate: null,
      valueEndDate: null,
    };
  } catch (err) {
    console.error(err);
  }
  return pricing;
};

const setMedia = (itemModel) => {
  let media = {
    images: []
  };
  try {
    media.images.push({
      url: itemModel.image,
      type: 'IMAGE',
      subType: 'PRIMARY',
      sizes: ['400'],
      hotspots: null,
      altText: itemModel.description,
    });
  } catch (err) {
    console.error(err);
  }
  return media;
};

const setAvailabilityType = (itemModel) => {
  let avail = null;
  try {
    avail = {
      discontinued: null, // Boolean,
      status: null, // Boolean,
      type: null, // String,
      buyable: null, // Boolean
    };
  } catch (err) {
    console.error(err);
  }
  return avail;
};

const getProductType = (itemModel) => {
  let type = itemModel.lineItemType;
  if (type === 'Appliance' && itemModel.shipType === '29') {
    type = 'MAJOR_APPLIANCE';
  }
  return type;
};

const setIdentifiers = (itemModel) => {
  let info = null;
  try {
    info = {
      canonicalUrl: itemModel.canonicalUrl,
      brandName: itemModel.brandName || null,
      itemId: itemModel.itemId,
      modelNumber: itemModel.modelNumber,
      productLabel: itemModel.productLabel,
      storeSkuNumber: itemModel.storeSku,
      upcGtin13: null, // String,
      skuClassification: null, // String,
      specialOrderSku: null, // String,
      // toolRentalSkuNumber: String,
      // rentalCategory: String,
      // rentalSubCategory: String,
      upc: null, // String,
      productType: getProductType(itemModel),
      isSuperSku: !!itemModel.superSkuModel,
      parentId: itemModel.parentId || null,
      shipType: itemModel.shipType || null,
      configId: null,
      editUrl: null,
      copyUrl: null,
      productCategory: null,
      leadTime: null,
      partNumber: null,
    };
  } catch (err) {
    console.error(err);
  }
  return info;
};

const setFulfillment = (itemModel) => {
  let ffm = null;
  try {
    let { fulfillment } = transformModel(itemModel);
    ffm = fulfillment;
  } catch (err) {
    console.error(err);
  }
  return ffm || null;
};

const setProduct = (itemModel) => {
  let product = {
    itemId: itemModel.itemId,
    // subscription: String,
    addons: setAddOns(itemModel),
    keyProductFeatures: setKeyProductFeatures(itemModel),
    info: setInfo(itemModel),
    specificationGroup: setSpecificationGroup(itemModel),
    pricing: setPricing(itemModel),
    media: setMedia(itemModel),
    availabilityType: setAvailabilityType(itemModel),
    identifiers: setIdentifiers(itemModel),
    fulfillment: setFulfillment(itemModel),
    dataSources: null,
    attributes: null,
    essentialAccessories: false
  };
  // handleFulfillmentData(product, itemModel);
  return product;
};

export const transformLegacyItem = (itemModel) => {
  let item = {};
  try {
    item.id = itemModel.lineItemId;
    item.quantity = getIntFromString(itemModel.quantity);
    item.product = setProduct(itemModel);

    // item.unitPrice = getFloatFromString(itemModel.unitPrice); // "2999.00",
    // item.totalItemPrice = getFloatFromString(itemModel.totalItemPrice); // "2999.00",
    // item.specialOfferPrice = getFloatFromString(itemModel.specialOfferPrice); // "2148.00",
    // item.offerPriceStartDate = itemModel.offerPriceStartDate; // "2023-02-16",
    // item.offerPriceEndDate = itemModel.offerPriceEndDate; // "2023-03-01",
    // item.percentOff = getIntFromString(itemModel.percentOff); // "28",

    // item = handleFulfillmentData(item, itemModel);
    // item.warrantyLineItems = itemModel.warrantyLineItems;
    handleFulfillmentData(item, itemModel);

  } catch (err) {
    console.log('\n\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.error(err);
  }
  return item;
};
