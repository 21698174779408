import { asyncCallWithTimeout, TIMEOUT_MESSAGE } from './asyncTimeout';
import getUserDetails from './getUserDetails';
import { getStorageObject, saveStorageObject } from './localStorage';

/**
 * @function getVariationsFromDE
 * @param {{
 * campaignIds: [{campaignId}],
 * additionalDetails: {},
 * channel: {channel: String},
 * apiEndpoint: String
 * localStorageOpts: {expiration: Number}
 * }} config
 * @returns {Promise<[{response_details: [{campaign_name: String, decision: String}]}]>}
 */
export const getVariationsFromDE = async ({
  campaignIds,
  additionalDetails,
  apiEndpoint,
  channel,
  localStorageOpts
}) => {

  window.LIFE_CYCLE_EVENT_BUS.trigger('decision-engine.api-call', { loading: true });
  const timestamp = new Date(Math.round(Date.now() / 1000)).getTime();

  const uniqueCampaignIds = campaignIds.reduce((acc, campaignObj) => {
    if (!acc.find((cObj) => cObj.campaignId === campaignObj.campaignId)) {
      acc.push(campaignObj);
    }

    return acc;
  }, []);

  const campaignDTLS = uniqueCampaignIds.map(({ campaignId: campaignName }) => ({
    campaign_name: campaignName,
    additional_dtls: {
      // channel: channel || '',
      ...additionalDetails,
    },
  }));

  let deResponseFromLS = null;

  if (localStorageOpts) {
    const { response } = getStorageObject({
      key: `decisionEngine.${campaignDTLS?.[0]?.campaign_name}`
    }) || {};

    deResponseFromLS = response;
  }

  const init = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user_dtls: getUserDetails(),
      tracking_dtls: {
        timestamp,
        source_system: 'personalization-util',
      },
      campaign_dtls: campaignDTLS,
      debug: window.isDebugMode,
    }),
  };

  try {
    let req = {};
    if (!deResponseFromLS) {
      req = await asyncCallWithTimeout(
        fetch(`${apiEndpoint}/decision-engine-conductor/v1/experience`, init),
        5000
      );
    }

    const response = deResponseFromLS || await req.json();

    if (response?.errorResponse?.errorMessage) {
      throw new Error(response.errorResponse.errorMessage);
    }
    // eslint-disable-next-line camelcase
    let { post_de } = response?.response_details?.[0] || {};

    if (localStorageOpts && !deResponseFromLS) {
      saveStorageObject({
        key: `decisionEngine.${campaignDTLS?.[0]?.campaign_name}`,
        response,
        expiration: localStorageOpts?.expiration
      });
    }

    if (deResponseFromLS) {
      const postDEArr = post_de.split('|');
      postDEArr[3] = timestamp;
      // eslint-disable-next-line camelcase
      post_de = postDEArr.join('|');
    }

    window.LIFE_CYCLE_EVENT_BUS.trigger('decision-engine.api-call', {
      post_de,
      loading: false
    });
    return [response];
  } catch (error) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('decision-engine.api-call', { loading: false, error });
    if (error.message === TIMEOUT_MESSAGE) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.timeout', {
        source: 'Decision Engine',
        error: error.message,
        payload: { body: init.body, ids: uniqueCampaignIds },
      });
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.deError', {
        error: { status: error?.errorResponse?.status, message: error?.errorMessage },
      });
    }
    /* eslint-disable-next-line no-console, no-unused-expressions */
    window.isDebugMode && console.error(error);
    return [];
  }
};
