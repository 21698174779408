import { createTheme } from '@one-thd/sui-atomic-components';

const ProBlack = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

ProBlack.palette['--sui-palette-background-primary'] = '27 32 48';

export { ProBlack };