import {
  shape, string, bool, number, arrayOf
} from '@thd-nucleus/data-sources';
import { COMMON_CHALLENGE_MODELS } from './OfferChallengesInfoModel';

const { taskRewards, challengeTasks, challenges } = COMMON_CHALLENGE_MODELS;

const challengeSteps = shape({
  customerTaskId: string(),
  name: string(),
  description: string(),
  url: string(),
  sequenceNumber: number(),
  completed: bool(),
});

const acceptChallengeTasks = shape({
  ...challengeTasks, // TODO: Double-check if this is allowed
  evalParamName: string(),
  evalParamValue: string(),
  targetParamValue: string(),
});

export const acceptChallengeModel = {
  challenges: arrayOf(shape({
    ...challenges, // TODO: Double-check if this is allowed
    challengeStepsCompleted: number(),
    challengeStepsTotal: number(),
    tasks: arrayOf(acceptChallengeTasks),
    challengeSteps: arrayOf(challengeSteps),
    rewards: taskRewards,
  })),
};
