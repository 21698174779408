const badgeDefinitions = [
  {
    name: '#1 Contributor',
    definition: '',
    iconClass: 'top1',
  },
  {
    name: 'Top 10 Contributor',
    definition: '',
    iconClass: 'top10',
  },
  {
    name: 'Top 25 Contributor',
    definition: '',
    iconClass: 'top25',
  },
  {
    name: 'Top 50 Contributor',
    definition: '',
    iconClass: 'top50',
  },
  {
    name: 'Top 100 Contributor',
    definition: '',
    iconClass: 'top100',
  },
  {
    name: 'Top 250 Contributor',
    definition: '',
    iconClass: 'top250',
  },
  {
    name: 'Top 500 Contributor',
    definition: '',
    iconClass: 'top500',
  },
  {
    name: 'Top 1000 Contributor',
    definition: '',
    iconClass: 'top1k',
  },
  {
    name: 'DIY',
    definition: 'This customer is an at home, do-it-your-selfer.',
    iconClass: 'diy',
  },
  {
    name: 'Expert',
    definition: 'We\'ve noticed this contributor is usually detailed and informative.',
    iconClass: 'expert',
  },
  {
    name: 'Pro',
    definition: 'This customer is a professional in the field.',
    iconClass: 'pro',
  },
  {
    name: 'The Home Depot Reviewer Program',
    definition: 'This review was collected as part of a promotion.',
    iconClass: 'reviewer-program',
  },
  {
    name: 'Seed Member',
    definition: 'This customer is a member of The Home Depot Seeds Program.',
    iconClass: 'seed',
  },
  {
    name: 'Home Depot Associate',
    definition: 'This contributor is a member of the Home Depot team.',
    iconClass: 'associate',
  },
  {
    name: 'Recommended Products',
    definition: '',
    iconClass: 'recommended',
  },
  {
    name: 'Verified Purchase',
    definition: 'This review is from a customer who purchased a product.',
    iconClass: 'verified',
  },
];

export default badgeDefinitions;