import React, { useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import { Printer } from '@one-thd/sui-icons';
import { useStore } from '@thd-nucleus/experience-context';

export const PrintList = ({ listId, isSharedList }) => {

  const [printUrl, setPrintUrl] = useState('');
  const store = useStore();
  const { storeId } = store;
  let baseUrl = '';

  if (typeof window !== 'undefined') {
    baseUrl = window.origin;
  }

  useEffect(() => {
    if (isSharedList) {
      const newPrintUrl = `${baseUrl}/customer/list/v1/sharelist/pdf/${listId}`;
      setPrintUrl(newPrintUrl);
    } else {
      const newPrintUrl = `${baseUrl}/customer/list/v1/listdetailsPdf/`
      + `${listId}/storeId/${storeId}/ownerName/self`;
      setPrintUrl(newPrintUrl);
    }
  }, [storeId, isSharedList]);

  const handlePrintClick = () => {
    LIFE_CYCLE_EVENT_BUS.trigger('product-list.print-list', {});
  };

  return (
    <Button
      variant="ghost"
      startIcon={Printer}
      onClick={handlePrintClick}
      href={printUrl}
      target="_blank"
      component="a"
      data-testid="print-list-button"
      type="button"
    >
      Print List
    </Button>
  );
};

PrintList.displayName = 'PrintList';

PrintList.propTypes = {
  listId: string.isRequired,
  isSharedList: bool
};

PrintList.defaultProps = {
  isSharedList: false,
};