import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Propane = createSvgIcon(
  <>
    <path d="M15 15.95c0 1.778-1.714 2.8-3 2.8s-3-1-3-3.111c0-2.389 1-4.389 4-5.889-1.5 2.4-.25 5.8.875 3.4.115.243.26.438.408.637.352.474.717.965.717 2.163Z" />
    <path d="M5.25 0h13.5v2.25H16.5v3h3.375c.621 0 1.125.504 1.125 1.125v4.875h3v2.25h-3v9.375c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 22.875V13.5H0v-2.25h3V6.375A1.125 1.125 0 0 1 4.125 5.25H7.5v-3H5.25V0Zm9 5.25v-3h-4.5v3h4.5Zm4.5 2.25H5.25v14.25h13.5V7.5Z" />
  </>,
  'Propane'
);

export { Propane };