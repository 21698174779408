import {
  useDataModel,
} from '../../index';

export const useProductDeprecated = (props, queryName = 'product') => {

  const {
    itemId,
    itemIds,
    storeId,
    zipCode,
    skip,
    ssr,
    lazy,
    skipInstallServices,
    skipSpecificationGroup,
    fetchPolicy
  } = props;

  const opts = {
    variables: {}
  };
  if (itemIds) {
    opts.variables.itemIds = itemIds;
  } else {
    opts.variables.itemId = itemId;
  }

  if (typeof storeId !== 'undefined') {
    opts.variables.storeId = storeId;
  }
  if (typeof zipCode !== 'undefined') {
    opts.variables.zipCode = zipCode;
  }

  if (typeof skip !== 'undefined') {
    opts.skip = skip;
  }

  if (typeof ssr !== 'undefined') {
    opts.ssr = ssr;
  }
  //
  if (typeof skipInstallServices !== 'undefined') {
    opts.variables.skipInstallServices = skipInstallServices;
  }

  if (typeof skipSpecificationGroup !== 'undefined') {
    opts.variables.skipSpecificationGroup = skipSpecificationGroup;
  }

  if (fetchPolicy) {
    opts.fetchPolicy = fetchPolicy;
  }

  return useDataModel(queryName, opts, lazy);
};

export const useProductDeprecatedClient = (params) => {
  return useProductDeprecated(params, 'clientOnlyProduct');
};

export const useSearchDeprecated = (props, queryName = 'searchModel') => {

  const {
    additionalSearchParams,
    startIndex,
    keyword,
    navParam,
    storeId,
    pageSize,
    filter,
    storefilter,
    orderBy,
    itemIds,
    skip,
    channel,
    ssr,
    lazy,
  } = props;

  const opts = {
    variables: {}
  };

  if (keyword) {
    opts.variables.keyword = keyword;
  }

  if (navParam) {
    opts.variables.navParam = navParam;
  }

  if (storeId) {
    opts.variables.storeId = storeId;
  }

  if (pageSize) {
    opts.variables.pageSize = pageSize;
  }

  if (itemIds) {
    opts.variables.itemIds = itemIds;
  }

  if (channel) {
    opts.variables.channel = channel;
  }

  if (startIndex) {
    opts.variables.startIndex = startIndex;
  }

  if (additionalSearchParams) {
    opts.variables.additionalSearchParams = additionalSearchParams;
  }

  if (orderBy) {
    opts.variables.orderBy = orderBy;
  }

  if (filter && Object.keys(filter).length) {
    opts.variables.filter = filter;
  }

  if (storefilter) {
    opts.variables.storefilter = storefilter;
  }

  if (typeof skip !== 'undefined') {
    opts.skip = skip;
  }

  if (typeof ssr !== 'undefined') {
    opts.ssr = ssr;
  }

  if (itemIds && itemIds.length === 0) {
    opts.skip = true;
  }

  return useDataModel(queryName, opts, lazy);
};

export const useReviewsDeprecated = ({ itemId, variables, ssr = true, skip }) => {
  const opts = {
    ssr,
    skip,
    variables: {
      ...variables,
      itemId
    }
  };
  return useDataModel('reviews', opts);
  // return useReviews(...args);
};

export const useReviewSentimentsDeprecated = ({ itemId, ssr = false }) => {
  const opts = {
    ssr,
    variables: {
      itemId
    }
  };
  return useDataModel('reviewSentiments', opts);
};

export const useReviewPhotosDeprecated = ({ itemId, ssr = false }) => {
  const opts = {
    ssr,
    variables: {
      itemId
    }
  };
  return useDataModel('reviewPhotos', opts);
  // return useReviewPhotos(...args);
};

export const useQuestionsAnswersDeprecated = ({ itemId }) => {
  const opts = {
    variables: {
      itemId
    }
  };
  return useDataModel('questionsAnswers', opts);
};

export const useShippingDeprecated = ({ lazy = true } = {}) => {
  const opts = {};

  return useDataModel('shipping', opts, lazy);
};

export const useDynamicRecsDeprecated = ({ itemId, storeId, ssr = true, skip }) => {
  const opts = {
    variables: {
      itemId,
      storeId,
      // @todo needs to be aligned with fbt
      skipSpecificationGroup: true
    },
    ssr,
    skip
  };

  return useDataModel('dynamicRecs', opts);
};

export const useAisleBayDeprecated = ({ lazy, storeId, storeSkuIds, }) => {
  const opts = {
    variables: {
      storeId,
      storeSkuIds
    }
  };
  return useDataModel('aislebay', opts, lazy);
};

export const useAisleBayTextMeDeprecated = (props) => {
  const {
    itemId,
    storeId,
    aisleBayText,
    phoneNumber,
    isSavingsSubscribed,
  } = props;
  const opts = {
    variables: {
      itemId,
      storeId,
      aisleBayText,
      phoneNumber,
      isSavingsSubscribed,
    }
  };

  return useDataModel('aisleBayTextMe', opts);
};

export const useProtectionPlanDeprecated = ({
  storeId, protectionPlanParentId, price, skip, ssr = false
}) => {
  const opts = {
    skip,
    variables: {
      protectionPlanParentId,
      storeId,
      price,
    }
  };
  return useDataModel('protectionPlan', opts);
};

export const useFBTDeprecated = ({ itemId, storeId }) => {
  const opts = {
    variables: {
      itemId,
      storeId,
    }
  };
  return useDataModel('bundle', opts);
};

export const useIRGDeprecated = (params) => {
  const {
    itemId,
    storeId,
    maxItems,
    domains,
    ssr = true
  } = params;

  const opts = {
    ssr,
    variables: {
      itemId,
      storeId,
      skipSpecificationGroup: true
    }
  };
  if (maxItems) {
    opts.variables.maxItems = maxItems;
  }
  return useDataModel('dynamicRecs', opts);
};

export const useMediaPriceInventoryDeprecated = (props) => {
  const {
    itemIds,
    storeId,
    includeInventory,
    ssr = false,
    lazy = true
  } = props;
  const opts = {
    variables: {
      itemIds,
      storeId,
      excludeInventory: !includeInventory
    }
  };
  return useDataModel('mediaPriceInventory', opts, lazy);
};

export const useMetadataDeprecated = ({ ssr = false, skip, parentId, lazy }) => {
  const opts = {
    ssr,
    skip,
    lazy,
    variables: {
      parentId
    }
  };
  return useDataModel('metadata', opts, lazy);
};

export const useServiceAddOnsDeprecated = ({ itemId, storeId, skip, ssr = false }) => {
  const opts = {
    skip,
    ssr,
    variables: {
      itemId,
      storeId
    }
  };
  return useDataModel('serviceAddOns', opts);
};

export const useCollectionDetailDeprecated = (props, queryName = 'collection') => {
  const {
    collectionId,
    storeId,
    ssr = false,
    skip = false
  } = props;
  const opts = {
    ssr,
    skip,
    variables: {
      collectionId,
      storeId
    }
  };
  return useDataModel(queryName, opts);
};

export const useSEOLinksDeprecated = ({ path, skip = false }) => {
  const opts = {
    skip,
    variables: {
      path
    }
  };
  return useDataModel('seoLinks', opts);
};

export const useSEOGeneratedContentDeprecated = ({ nvalue, skip }) => {
  const opts = {
    skip,
    variables: {
      nvalue
    }
  };
  return useDataModel('seoGeneratedContent', opts);
};

export const useStoreSearchDeprecated = (props, queryName = 'storeSearch') => {
  const {
    zipCode,
    radius,
    limit,
    lazy
  } = props;

  const opts = {
    variables: {
      zipCode,
      radius
    }
  };

  if (limit) {
    opts.variables.limit = limit;
  }

  return useDataModel(queryName, opts, lazy);
};

export const useStoreDetailsDeprecated = (props, queryName = 'storeDetails') => {
  const {
    storeId,
    lazy
  } = props;

  const opts = {
    variables: {}
  };

  if (storeId) {
    opts.variables.storeId = storeId;
  }

  return useDataModel(queryName, opts, lazy);
};

export const useRelatedSearchNavDeprecated = (props) => {
  const {
    navParam,
    storeId,
    itemId,
    skip,
    ssr,
  } = props;
  const opts = {
    skip,
    variables: {}
  };

  if (navParam) {
    opts.variables.navParam = navParam;
  }
  if (storeId) {
    opts.variables.storeId = storeId;
  }

  if (itemId) {
    opts.variables.itemId = itemId;
  }

  return useDataModel('relatedSearchModel', opts);
};

export const useMylistDeprecated = (props) => {

  const {
    customerAccountID,
    userId,
    customerType,
    skip
  } = props;
  const opts = {
    ssr: false,
    skip,
    variables: {
      customerAccountID,
      userId,
      customerType
    }
  };
  return useDataModel('listDetails', opts);
};

export const useFBRDeprecated = ({ itemId, storeId }) => {

  const opts = {
    variables: {
      itemId,
      storeId
    }
  };

  return useDataModel('fbr', opts);
};

export const useLoyaltyMembershipDeprecated = ({ ssr = true, lazy = true }) => {

  const opts = {
    ssr,
    lazy
  };

  return useDataModel('loyaltyMembership', opts, lazy);
};

export const usePackageDeprecated = (props) => {
  const {
    itemId,
    limit = 1,
    packageId,
    storeId,
    ssr = true,
    lazy = false,
  } = props;

  const opts = {
    ssr,
    variables: {
      itemId,
      limit,
      packageId,
      storeId,
    }
  };
  return useDataModel('packages', opts, lazy);
};
