import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@one-thd/sui-atomic-components';
import { generateNestedKey, getHeaders, getRows } from './utils';
import { RenderTableNodes } from './RenderTableNodes';

const RenderTable = ({ node }) => {
  const headers = getHeaders(node);
  const rows = getRows(node);
  return (
    <Table density="loose">
      <TableHead>
        <TableRow>
          {headers.length > 0 && headers.map((header, i) => (
            <TableCell key={generateNestedKey(header, 'table-header', i)}>
              <RenderTableNodes node={header} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length > 0 && rows.map((row, i) => (
          <TableRow key={generateNestedKey(row, 'table-row', i)}>
            {row.map((cell, jIndex) => {
              if (!cell.nodeType) {
                return (
                  <TableCell key={generateNestedKey(cell, 'table-cell-nodes', `${jIndex}-${i}`)}>
                    {cell.map((nodeText) => {
                      return (
                        <RenderTableNodes
                          node={nodeText}
                          key={generateNestedKey(nodeText, 'table-node', `${jIndex}-${i}`)}
                        />
                      );
                    })}
                  </TableCell>
                );
              }
              return (
                <TableCell key={generateNestedKey(cell, 'table-cell', `${jIndex}-${i}`)}>
                  <RenderTableNodes node={cell} />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

RenderTable.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export { RenderTable };
