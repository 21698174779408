import React, { useContext } from 'react';
import { CustomerWelcomeMessage } from '@thd-olt-component-react/customer-welcome-message';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { AUTHORIZATION_STATUS } from '../utils';
import { HomepageContext } from './context/HomepageContext';

export function WelcomeMessage() {
  const { userAuthStatus } = useContext(HomepageContext);
  return (
    <ErrorBoundary name="welcome-message">
      <CustomerWelcomeMessage
        isCustomerAuthenticated={
          userAuthStatus === AUTHORIZATION_STATUS.AUTHENTICATED
        }
      />
    </ErrorBoundary>
  );
}
