import React from 'react';
import {
  bool,
  shape,
  string,
  node
} from 'prop-types';
import classNames from 'classnames/bind';
import styles from './card.module.scss';
import { Heading } from '../Heading';

const cx = classNames.bind(styles);

const CardTitle = ({
  centered,
  title,
  noPadding,
  action,
  subtext,
  className
}) => {

  const cardTitleClasses = cx('card-title', {
    'card-title--no-padding': noPadding,
    'card-title--no-decoration': subtext && !subtext.href
  }, className);

  const cardActionClasses = cx('card-title--clickable', 'card-title--action');

  const cardSubtextClasses = cx({
    'card--link': subtext && subtext.href,
  });

  const ElementType = action && action.href ? 'a' : 'div';
  const SubtextElementType = subtext && subtext.href ? 'a' : 'div';

  return (
    <div className={cardTitleClasses}>
      <div className={styles['card-title--heading']}>
        <Heading
          centered={centered}
          title={title}
          noMargin
          fontNext
          tabIndex={0}
        />
        {subtext
        && (
          <SubtextElementType className={cardSubtextClasses} href={subtext && subtext.href}>
            {subtext.text}
          </SubtextElementType>
        )}
      </div>
      <ElementType href={action && action.href} className={cardActionClasses}>
        {action
        && action.content}
      </ElementType>
    </div>
  );
};

CardTitle.displayName = 'Card Title';

CardTitle.propTypes = {
  action: shape({
    content: node,
    href: string,
  }),
  noPadding: bool,
  subtext: shape({
    text: string,
    href: string,
  }),
  centered: bool,
  title: string,
  className: string,
};

CardTitle.defaultProps = {
  action: null,
  subtext: null,
  noPadding: false,
  centered: false,
  title: null,
  className: null,
};

export { CardTitle };
