import { useMemo } from 'react';

import { usePromoCart } from './usePromoCart';
import { usePromoModel } from './usePromoModel';
import { MULTI_SELECT_QUANTITY } from '../utils/constants';
import { getProductImage } from '../utils/promo-utils';

export const useCartImages = () => {
  const {
    isBmsm,
    isMsb,
  } = usePromoModel();
  const {
    isCartLoading,
    promoCartItemsObj,
    selectedItemsModel
  } = usePromoCart();

  return useMemo(() => {
    const cartImages = [];
    const promoCartItems = Object.values(promoCartItemsObj || {});

    if (isMsb || isBmsm) {
      promoCartItems.forEach(({ itemId, image, quantity }) => {
        cartImages.push({
          url: image,
          itemId,
          quantity
        });
      });
      selectedItemsModel.forEach(((selectedItem) => {
        cartImages.push({
          url: getProductImage(selectedItem),
          itemId: selectedItem.itemId,
          quantity: MULTI_SELECT_QUANTITY
        });
      }));

      if (!cartImages.length) {
        cartImages.push({
          quantity: 0
        });
      }
    }

    return {
      cartImages,
      cartImagesLoading: isCartLoading
    };
  }, [
    isBmsm,
    isCartLoading,
    isMsb,
    promoCartItemsObj,
    selectedItemsModel
  ]);
};
