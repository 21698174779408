import { shape, string } from '@thd-nucleus/data-sources';

export const activatePerksModel = {
  paymentId: string(),
  perkId: string(),
  tierRewardId: string(),
  activationTime: string(),
  expirationTime: string(),
  status: string(),
};
