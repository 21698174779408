import React, { PureComponent, Fragment } from 'react';
import {
  arrayOf, node, oneOf, oneOfType, string
} from 'prop-types';
import { UUID } from './utils';
import AccordionItem from './AccordionItem';

export const addTypeAndName = (children, type, name, classes) => {
  return children.map((child, i) => {
    return {
      ...child,
      props: {
        ...child.props,
        className: classes,
        key: child.props.wrapperKey || `${name}-${i}`,
        id: child.props.id || `${name}-${i}`,
        type,
        name
      }
    };
  });
};

/**
 * THD Accordion component. */
class Accordion extends PureComponent {
  static Item = AccordionItem;

  render() {
    const {
      className,
      children,
      type,
      name
    } = this.props;
    return (
      <>
        {
          children
          && addTypeAndName(
            children.length ? children : [children],
            type,
            name || UUID(),
            className
          )
        }
      </>
    );
  }
}

Accordion.propTypes = {
  className: string,
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  /** If name is not provided, a UUID will be generated */
  name: string,
  type: oneOf(['checkbox', 'radio'])
};

Accordion.defaultProps = {
  className: null,
  name: null,
  type: 'checkbox'
};

export default Accordion;
