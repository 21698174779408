import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const InStorePickup = createSvgIcon(
  <>
    <path d="M5.25 4.921V8.25h-3V19.5H9v2.25H0V6h3V4.093l.26-.313C5.384 1.233 8.349 0 12 0s6.617 1.233 8.74 3.78l.26.313V6h3v2.25h-5.25V4.921C17.142 3.165 14.936 2.25 12 2.25s-5.142.914-6.75 2.671Z" />
    <path d="M24 11.25H11.25V24H24V11.25Zm-5.25 2.25h3v8.25H13.5V13.5h3v3.75h2.25V13.5Z" />
  </>,
  'InStorePickup'
);

export { InStorePickup };