import React, { useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { CtaSection } from '../common/CtaSection';
import { buildSamLink } from '../../utils/home-services-cta-utils';
import { dataModel } from './dataModel';

const GccCarpetSamCta = ({
  itemId, onClick, target, hideLabel, isFooter
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('schedule-a-measure.ready'); }, []);

  const { data } = useDataModel('product', {
    skip: !itemId,
    ssr: true,
    variables: {
      itemId
    }
  });

  if (!itemId) {
    return null;
  }

  const {
    product: {
      identifiers: {
        specialOrderSku,
        storeSkuNumber
      } = {}
    } = {}
  } = data || {};

  return (
    <CtaSection
      buttonText="Get Estimate"
      buttonLink={`https://custom.homedepot.com/flooring/${itemId}`}
      isFooter={isFooter}
      openContentInDrawer={false}
      onClick={onClick}
      label="Schedule a Measure"
      labelLink={buildSamLink(specialOrderSku || storeSkuNumber)}
      hideLabel={hideLabel}
      hideContactInfo
      target={target}
      dataComponent="GccCarpetSamCta"
    />
  );
};

GccCarpetSamCta.displayName = 'GccCarpetSamCta';

GccCarpetSamCta.dataModel = dataModel;

GccCarpetSamCta.propTypes = {
  hideLabel: bool,
  isFooter: bool,
  itemId: string.isRequired,
  onClick: func,
  target: string
};

GccCarpetSamCta.defaultProps = {
  hideLabel: false,
  isFooter: false,
  onClick: () => {},
  target: '_blank'
};

export { GccCarpetSamCta };
