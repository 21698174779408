/* eslint-disable react/prop-types */
import React from 'react';
import { POD_IMAGE_SIZE } from '../../../constants';

export const Image = ({ product }) => {
  const {
    media = {},
  } = product || {};

  let imageSource = 'PRIMARY';
  const imageUrl = (media?.images?.find((img) => {
    return (img?.subType === imageSource);
  }) || {}).url?.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString() || '');

  return (
    <img
      data-automation-id="product-image"
      src={imageUrl}
      height={POD_IMAGE_SIZE}
      width="100%"
      alt="product"
    />
  );
};

Image.displayName = 'Image';
