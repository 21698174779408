/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

export const callService = (url, method = 'GET', body = '') => {
  if (body && (typeof body !== 'string')) {
    body = JSON.stringify(body);
  }

  const config = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      cartId: '',
      channelId: window.isMobile ? '2' : '1',
      twoTile: true
    }
  };
  if (method === 'POST') {
    config.body = body;
  }
  return fetch(url, config)
    .then((response) => (response.json().then((resp) => {
      // recordApiEvent({ method, url, resp });
      return resp;
    })));
};