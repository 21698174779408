import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { VisualNavigation as VisualNavigationComponent } from '@thd-olt-component-react/visual-navigation';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function VisualNavigation({ id }) {
  return (
    <ErrorBoundary name="visual-navigation">
      <QueryProvider key={id} cacheKey={id}>
        <div
          id={`visual-navigation-php-${id}`}
          className="vis-nav-wrapper sui-mt-4 sui-h-full "
        >
          <VisualNavigationComponent
            componentId={id}
            componentClass="VisualNavigation"
            enableTheme
            lazyLoad
            fontWeight="bold"
          />
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

VisualNavigation.propTypes = {
  id: string,
};

VisualNavigation.defaultProps = {
  id: undefined,
};
