/**
 * @function createComponentProps
 * @description chooses between the variation (if it exist) and the default to create the relevant slot
 * and component props for the ComponentSelector to render.
 * @param {Object} contentfulComponentsObject object with defaultComponent and variation {defaultComponent, variation}.
 * Both are potential component objects from Contentful
 * @returns {Object} object with the slot and component props as keys {slot, props}
 */
export const createComponentProps = ({ defaultComponent, variation, customPropMap = {} }) => {
  const defaultIsASlot = defaultComponent && defaultComponent.slotNumber;
  const variationIsSlot = variation && variation.slotNumber;

  const defaultSlot = defaultIsASlot ? { ...defaultComponent } : {};
  const variationSlot = variationIsSlot ? { ...variation } : {};

  const {
    id: slotID,
    name: slotName,
    slotNumber,
    __typename: slotTypename,
    content: slotContent,
    ...otherProps
    /* eslint-disable-next-line no-nested-ternary */
  } = variation ? variationSlot : defaultComponent ? defaultSlot : {};

  const slot = {
    slotID,
    slotName,
    slotNumber,
    ...otherProps,
  };

  const componentToUse = (variationIsSlot ? slotContent : variation)
    || (defaultIsASlot ? slotContent : defaultComponent)
    || {};

  const props = {
    ...componentToUse,
    ...slot,
    ...customPropMap[componentToUse.__typename],
    componentId: componentToUse.id,
    componentClass: componentToUse.__typename,
  };

  return { slot, props };
};
