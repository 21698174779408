import React from 'react';
import { SkeletonBlock } from '@one-thd/sui-atomic-components';

export function CardItemsPlaceholder() {
  const imagePlaceholder = [...Array(5)].map((key, index) => (
    <React.Fragment key={`cardItemsImagePlaceholder${index}`}>
      <span className="sui-mr-2">
        <SkeletonBlock aspect="wide" height={16} width={16} />
      </span>
    </React.Fragment>
  ));

  return <div className="sui-flex">{imagePlaceholder}</div>;
}
