import { useDataModel } from '@thd-nucleus/data-sources';
import { useNewRelicLogging } from './useNewRelicLogging';
import { PromotionCardUdsDataModel } from '../models/PromotionCardUdsDataModel';

export const useUdsCallResponse = (customerId) => {
  const { sendBogoCardErrorsToNewRelic, sendBogoCardDataToNewRelic } = useNewRelicLogging();
  const logUdsResponses = (data, error) => {
    if (data) {
      const anchorItemId = data?.uds?.products[0]?.product?.itemId;
      if (anchorItemId) {
        sendBogoCardDataToNewRelic({
          appName: 'nucleus.my-homepage',
          actionName: 'BOGO-PHP-UDS-Success',
          customerType: 'B2B',
          segment: 'Promotions',
          experience: 'BOGO',
          subExperience: 'BOGO/BOGO Percent Off/BOGO Dollar Off',
          anchorItemId,
        });
      } else if (anchorItemId === null || anchorItemId === undefined) {
        sendBogoCardErrorsToNewRelic({
          errorMessage: 'itemId from UDS was returned as null',
          appName: 'nucleus.my-homepage',
          actionName: 'BOGO-PHP-Fallback-UDS',
          customerType: 'B2B',
          segment: 'Promotions',
          experience: 'BOGO',
        });
      }

    }
    if (error) {
      sendBogoCardErrorsToNewRelic({
        errorMessage: error.message,
        appName: 'nucleus.my-homepage',
        actionName: 'BOGO-PHP-Fallback-UDS',
        customerType: 'B2B',
        segment: 'Promotions',
        experience: 'BOGO',
      });
    }
  };
  const udsQueryOptions = {
    variables: {
      apiName: 'bogo',
      // This api key is temporary api key taken from sample in the UDS contract
      key: '2zSR74pj5Rq6zDtHENHkAScT4RWsl0pg',
      userId: customerId,
    },
    ssr: false,
    skip: !customerId,
    dataModel: PromotionCardUdsDataModel,
  };
  const { data, error, loading } = useDataModel('uds', udsQueryOptions);
  logUdsResponses(data, error);
  return {
    data,
    loading,
    error,
  };
};
