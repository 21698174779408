import React from 'react';
import { string, shape } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';
import { publish } from '../../../utils/publisher';
import styles from './ArticleGuidesTile.module.scss';

const cx = classNames.bind(styles);

const ArticleGuidesTile = (props) => {
  const { article, pageType } = props;

  const handleClickedArticle = () => {
    // Format article type names to match analytics spec
    let articleTypeFormatted = article?.articleType?.toLowerCase();
    if (!articleTypeFormatted?.includes('guide')) {
      articleTypeFormatted = `${articleTypeFormatted} guide`;
    }

    const articleData = {
      articleRecommendationType: articleTypeFormatted,
      guid: article?.guid,
      pageType,
    };

    publish({
      componentName: 'article-guide',
      data: articleData,
      eventName: 'click',
    });
  };

  return (
    <div
      className={cx('article-containers',
        'sui-relative', 'sui-text-inverse', 'sui-max-w-[403px]')}
      data-type="product"
    >
      <meta data-prop="productID" content={article?.guid} />
      <a href={article?.guideUrl} onClick={() => handleClickedArticle()}>
        <Image
          src={article?.imageUrl}
          alt={article?.title}
          stretchy
          className={cx('sui-aspect-square', 'sui-h-full')}
        />
        <span className={cx('article-background-gradient')} />
        <span className={cx('sui-absolute', 'sui-left-4', 'sui-bottom-5', 'sui-text-left',
          'sui-text-ellipsis', 'sui-text-5xl', 'sui-max-w-[50%]', 'sui-line-clamp-3',
          'sui-leading-none', 'sui-flex-1', 'sui-tracking-normal', 'hover:sui-underline', 'sui-font-bold')}
        >{article?.title}
        </span>
        <div className={cx('sui-right-4', 'sui-bottom-5', 'sui-absolute', 'sui-text-right',
          'sui-text-xs', 'sui-leading-normal', 'sui-font-display')}
        >
          <p className={cx('sui-uppercase', 'sui-tracking-wider')}>{article?.articleType}</p>
          <p title={article?.difficulty}>{article?.difficulty}</p>
        </div>
      </a>
    </div>
  );
};

ArticleGuidesTile.displayName = 'ArticleGuidesTile';

ArticleGuidesTile.propTypes = {
  article: shape({
    imageUrl: string,
    title: string,
    difficulty: string,
    articleType: string,
    guid: string,
    guideUrl: string,
  }).isRequired,
  pageType: string,
};

ArticleGuidesTile.defaultProps = {
  pageType: null,
};

export { ArticleGuidesTile };
