import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';

import { params, shape, string as stringType } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { StoreWorkshopsComponent } from './StoreWorkshopComponent';

const StoreWorkshops = ({ entryID, isCard }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('store-workshops.ready');
  }, []);

  return (
    <ImpressionProvider
      data={{
        id: entryID,
        component: 'StoreWorkshops',
        name: 'StoreWorkshops',
        type: 'content',
        position: 1,
      }}
    >
      <StoreWorkshopsComponent entryID={entryID} isCard={isCard} />
    </ImpressionProvider>
  );
};

StoreWorkshops.displayName = 'StoreWorkshops';

StoreWorkshops.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSource: stringType(),
    identifiers: shape({
      productLabel: stringType(),
    }),
  }),
};

export { StoreWorkshops };

StoreWorkshops.propTypes = {
  entryID: string,
  isCard: bool,
};

StoreWorkshops.defaultProps = {
  entryID: '',
  isCard: undefined,
};
