import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AuthorizeCard = createSvgIcon(
  <>
    <path d="M0 1.875C0 1.254.504.75 1.125.75h21.75C23.496.75 24 1.254 24 1.875v9.375h-2.25V3H2.25v12.75h7.5V18H1.125A1.125 1.125 0 0 1 0 16.875v-15Zm23.977 13.161-7.42 8.175-5.022-4.8 1.554-1.627 3.354 3.205 5.867-6.466 1.667 1.513Z" />
    <path d="M8.25 9V5.25H4.5V9h3.75Z" />
  </>,
  'AuthorizeCard'
);

export { AuthorizeCard };