import React from 'react';
import { PiccolaPencil as PiccolaPencilComponent } from '@thd-olt-component-react/internal-marketing-banner';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { string } from 'prop-types';

export function PiccolaPencil({ id }) {

  return (
    <ErrorBoundary name="internal-marketing-banner-piccola-pencil">
      <QueryProvider key={id} cacheKey={id}>
        <PiccolaPencilComponent componentId={id} componentClass="PiccolaPencil" />
      </QueryProvider>
    </ErrorBoundary>
  );
}

PiccolaPencil.propTypes = {
  id: string.isRequired
};

PiccolaPencil.defaultProps = {};
