import {
  arrayOf as arrayOfType,
  shape as shapeType,
  string as stringType,
  bool as boolType,
  number as numberType,
  params,
  extend,
  customType
} from '@thd-nucleus/data-sources';

import { CartResponse } from '@thd-olt-component-react/cart-common-data';

const ProductParms = {
  promotionProducts: params({
    itemId: stringType().isRequired(),
    pageSize: numberType(),
    cartLineItems: arrayOfType(customType('CartLineItemInput!').shape({
      itemId: stringType(),
      quantity: numberType(),
      price: numberType({ float: true }),
    })),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: stringType(),
      programTiers: arrayOfType(
        shapeType({
          tier: stringType(),
          program: stringType(),
        }),
      ),
    }),
  }).shape({
    promotions: arrayOfType(shapeType({
      anchorItemGroups: arrayOfType(stringType()),
      anchorItemId: stringType(),
      anchorProductGroups: arrayOfType(shapeType({
        itemGroup: stringType(),
        categoryId: stringType(),
      })),
      experienceTag: stringType(),
      nvalues: arrayOfType(stringType()),
      promotionId: stringType(),
      subExperienceTag: stringType(),
      eligibilityCriteria: arrayOfType(shapeType({
        itemGroup: stringType(),
        categories: arrayOfType(shapeType({
          categoryId: stringType(),
          name: stringType(),
          maxPurchaseQuantity: numberType(),
          nvalues: arrayOfType(stringType()),
          itemIds: arrayOfType(stringType()),
        })),
        itemIds: arrayOfType(stringType()),
        minPurchaseAmount: numberType({ float: true }),
        minPurchaseQuantity: numberType({ float: true }),
        searchReport: shapeType({
          pageSize: numberType(),
          startIndex: numberType(),
          totalProducts: numberType(),
        }),
      })),
      dates: shapeType({ end: stringType(), start: stringType() }),
      description: shapeType({
        longDesc: stringType(),
        shortDesc: stringType(),
      }),
      reward: shapeType({
        tiers: arrayOfType(shapeType({
          maxAllowedRewardAmount: numberType({ float: true }),
          maxPurchaseQuantity: numberType({ float: true }),
          minPurchaseAmount: numberType({ float: true }),
          minPurchaseQuantity: numberType({ float: true }),
          rewardAmountPerItem: numberType({ float: true }),
          rewardAmountPerOrder: numberType({ float: true }),
          rewardFixedPrice: numberType({ float: true }),
          rewardPercent: numberType({ float: true }),
        })),
      }),
      progress: shapeType({
        applicable: arrayOfType(shapeType({
          group: shapeType({ itemGroup: stringType(), categoryId: stringType() }),
          itemId: stringType(),
          quantity: numberType(),
          price: numberType({ float: true })
        })),
        current: shapeType({
          amount: numberType({ float: true }),
          quantity: numberType()
        }),
        next: shapeType({
          amount: numberType({ float: true }),
          quantity: numberType()
        }),
        percent: numberType(),
        save: numberType(),
        tier: numberType(),
        type: stringType()
      }),
    })),
  }),
};

const itemPromotionValidtyParms = {
  itemPromotionValidity: params({
    navParam: stringType().isRequired(),
    storeSkuIds: arrayOfType(stringType().isRequired()).isRequired(),
  }).arrayOf(shapeType({
    storeSkuId: stringType(),
    eligible: boolType(),
  }))
};

export const PromoCartProviderDataModel = extend(
  itemPromotionValidtyParms,
  ProductParms,
  CartResponse
);
