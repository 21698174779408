import { useLocation } from '@thd-olt-component-react/router';

export function usePreviewByID() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const idFromQueryParams = searchParams.get('previewByID');
  const debugTrue = searchParams.get('debug');
  if (debugTrue === 'true' && idFromQueryParams) return idFromQueryParams;
  return null;
}
