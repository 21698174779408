import React from 'react';
import { bool, number as numberType, string as stringType } from 'prop-types';
import { ImpressionProvider } from '@thd-olt-component-react/impression';

/*
 * This is a Higher Order Component (HOC) that returns the component wrapped in an ImpressionProvider.
 */
const withImpressions = (Component) => {
  const Sub = ({ componentClass, componentId, componentPosition, lazyLoad }) => {
    return (
      <ImpressionProvider
        data={{
          id: componentId,
          name: 'InternalMarketingBanner',
          component: 'InternalMarketingBanner',
          type: 'content'
        }}
      >
        <Component
          componentClass={componentClass}
          componentId={componentId}
          componentPosition={componentPosition}
          lazyLoad={lazyLoad}
        />
      </ImpressionProvider>
    );
  };

  Sub.propTypes = {
    componentClass: stringType,
    componentId: stringType.isRequired,
    componentPosition: numberType,
    lazyLoad: bool
  };

  Sub.defaultProps = {
    componentClass: '',
    componentPosition: 1,
    lazyLoad: false
  };

  return Sub;
};

export { withImpressions };
