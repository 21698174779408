import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleTertiaryLightOrange = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleTertiaryLightOrange.palette['--sui-palette-background-primary'] = '254 135 25';

export { HouseStyleTertiaryLightOrange };