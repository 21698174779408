import React, { useContext } from 'react';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { RecentPurchases as RecentPurchasesComponent } from '@thd-olt-component-react/recent-order-details';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useNavigate } from '@thd-olt-component-react/router';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Hydrator } from '@thd-olt-component-react/hydrator';
import { HomepageContext } from './context/HomepageContext';
import { getHydrationOptions } from '../utils/constants';

export function RecentPurchases({ id }) {
  const { isConsumerApp, channel } = useContext(ExperienceContext);
  const { urlTmxId } = useContext(HomepageContext);
  const navigate = useNavigate();

  const data = {
    id,
    name: 'RecentPurchases',
    component: 'RecentPurchases',
    type: 'content',
    position: 1,
  };

  const onError = (error = {}) => {
    if (!isConsumerApp) return;

    const isSignInPath = window.location?.pathname?.includes('/auth/view/signin');
    const isUnauthorized = error?.graphQLErrors?.some((gqlError) => gqlError?.extensions['X-Http-Status-Code'] === 401);
    if (isUnauthorized && !isSignInPath) navigate('/auth/view/signin?redirect=/');
  };
  return (
    <ErrorBoundary name="recent-purchases">
      <QueryProvider cachekey="recent-purchases">
        <ImpressionProvider data={data}>
          <Hydrator
            placeholder={getHydrationOptions(channel, isConsumerApp).PLACEHOLDER_SECTION}
            delay={isConsumerApp && 5000}
            id="recent-purchases-hydrator"
          >
            <RecentPurchasesComponent onError={onError} urlTmxId={urlTmxId} componentId={id} />
          </Hydrator>
        </ImpressionProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

RecentPurchases.propTypes = {
  id: string,
};

RecentPurchases.defaultProps = {
  id: undefined,
};