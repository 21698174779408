export const htmlAttributes = {
  DATA_CAMPAIGN_ID: 'data-campaignid',
  DATA_PLACEMENT_ID: 'data-placementid',
  DATA_SLOT_ID: 'data-slotid',
  DATA_FLIGHT_ID: 'data-flightid',
  DATA_CREATIVE_ID: 'data-creativeid',
  DATA_H1_BEACON: 'data-hl-beacon',
  DATA_ONVIEW_BEACON_NUCLEUS: 'data-onview-beacon-nucleus',
  ONVIEW_BEACON_NUCLEUS: 'onview-beacon-nucleus',
  DATA_TEST_ID: 'data-testid',
  DATA_AD_LOAD_TIMESTAMP: 'data-ad-load-timestamp',
  DATA_INV_SCRIPT_TIMESTAMP: 'data-inv-script-timestamp',
  DATA_BANNER_INIT_TIMESTAMP: 'data-banner-init-timestamp',
  DATA_BEACON_ADD_TIME: 'data-beacon-add-time',
  DATA_TELEMETRY_STR: 'data-telemetry-str',
  DATA_ADSERVER: 'data-adserver',
  DATA_ON_VIEW_CONVERSION_BEACON: 'data-onview-conversion-beacon',
  DATA_MCVISID: 'data-mcvisid'
};
