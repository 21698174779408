import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { helpers } from './Helpers';
import { Swipe } from './Swipe';
import { ArrowButtons } from './ArrowButtons';
import './Carousel.style.scss';

class Carousel extends Component {

  constructor(props) {
    super(props);
    this.carouselWrapperRef = React.createRef();
    this.scrollDom = React.createRef();
    const {
      autoplay, fixedItem, itemWidthDesktop, itemWidthPixelsDesktop, screenBreakpoint, selectedItem
    } = props;
    this.state = {
      autoplay,
      browserWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      isMobile: typeof window !== 'undefined' ? window.innerWidth < screenBreakpoint : false,
      isMouseEntered: false,
      itemWidth: fixedItem ? itemWidthPixelsDesktop : itemWidthDesktop,
      mounted: false,
      selectedItem: selectedItem || 0,
      swipeDistance: 0,
    };
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateSizes);
    window.addEventListener('DOMContentLoaded', this.updateSizes);
    document.addEventListener('keydown', this.navigateWithKeyboard);
    if (this.scrollDom && this.scrollDom.current) {
      this.scrollDom.current.addEventListener('scroll', this.onScroll);
    }
    this.updateSizes();
    this.setState({ mounted: true });
  };

  componentDidUpdate = (prevState) => {
    const { swiping, selectedItem } = this.state;
    if (prevState.swiping && !swiping) {
      this.setPosition(`${this.getPosition(selectedItem)}%`);
    }
  };

  // TODO: Update me! https://www.pivotaltracker.com/story/show/172456018
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = (nextProps) => {
    const { selectedItem, autoplay } = this.state;
    if ([selectedItem, null].indexOf(nextProps.selectedItem) === -1) {
      this.setState({ selectedItem: nextProps.selectedItem });
    }
    if (nextProps.autoplay === autoplay) {
      this.setState({ autoplay: nextProps.autoplay }, () => {
        if (autoplay) {
          this.setupAutoplay();
        } else {
          this.destroyAutoplay();
        }
      });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateSizes);
    window.removeEventListener('DOMContentLoaded', this.updateSizes);
    document.removeEventListener('keydown', this.navigateWithKeyboard);
    if (this.scrollDom && this.scrollDom.current) {
      this.scrollDom.current.removeEventListener('scroll', this.onScroll);
    }
  };

  setupAutoplay = () => {
    this.autoplay();
    const { stopOnHover } = this.props;
    const carouselWrapper = this.carouselWrapperRef.current;
    if (stopOnHover && carouselWrapper) {
      carouselWrapper.addEventListener('mouseenter', this.stopOnHover);
      carouselWrapper.addEventListener('mouseleave', this.startOnLeave);
    }
  };

  destroyAutoplay = () => {
    this.clearAutoplay();
    const { stopOnHover } = this.props;
    const carouselWrapper = this.carouselWrapperRef.current;
    if (stopOnHover && carouselWrapper) {
      carouselWrapper.removeEventListener('mouseenter', this.stopOnHover);
      carouselWrapper.removeEventListener('mouseleave', this.startOnLeave);
    }
  };

  autoplay = () => {
    const { autoplay, numberOfSkips } = this.state;
    const { interval } = this.props;
    if (!autoplay || this.getItemCount() <= 1) {
      return;
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.moveTo({
        itemspershift: numberOfSkips,
        direction: 'next'
      });
    }, Number(interval));
  };

  clearAutoplay = () => {
    const { autoplay } = this.state;
    if (!autoplay) {
      return;
    }
    clearTimeout(this.timer);
  };

  resetAutoplay = () => {
    this.clearAutoplay();
    this.autoplay();
  };

  stopOnHover = () => {
    this.setState({ isMouseEntered: true });
    this.clearAutoplay();
  };

  startOnLeave = () => {
    this.setState({ isMouseEntered: false });
    this.autoplay();
  };

  navigateWithKeyboard = (keyEvent) => {
    if (helpers.KEYCODE_DIRECTION(keyEvent.keyCode)) {
      this.moveTo({ direction: helpers.KEYCODE_DIRECTION(keyEvent.keyCode) });
    }
  };

  updateSizes = () => {
    let skipCount = 1;
    const singleItemTransitionTime = 350;
    const multiItemCount = 200;
    const {
      itemWidthMobile, itemWidthDesktop, screenBreakpoint, screenMaxSize, multiItem, fixedItem
    } = this.props;
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    const browserWidth = innerWidth > screenMaxSize ? screenMaxSize : innerWidth;
    const isMobile = browserWidth < screenBreakpoint;
    let itemWidth = isMobile ? parseInt(itemWidthMobile, 0) : parseInt(itemWidthDesktop, 0);
    if (multiItem) {
      skipCount = itemWidth !== 0 ? parseInt(100 / itemWidth, 10) : 1;
    }
    if (fixedItem) {
      const productPodWidth = this.getItemWidth();
      skipCount = productPodWidth !== 0 ? parseInt(browserWidth / productPodWidth, 10) : 1;
    }
    const numberOfSkips = (multiItem || fixedItem) && skipCount > 1 ? skipCount - 1 : 1;
    // calculating the dynamic speed for scroll based on the number of visible items, as per the conversation with business (Amit and Sara) they agree with the value 200 multiply with the number of skips
    const transitionTime = skipCount <= 2 ? singleItemTransitionTime : multiItemCount * numberOfSkips;
    if (itemWidthMobile === '40%' && itemWidthDesktop === '20%') {
      if (skipCount > 2) {
        itemWidth = 100 / skipCount;
      }
    }
    this.setState({
      itemWidth,
      isMobile,
      browserWidth,
      numberOfSkips,
      transitionTime
    });
  };

  handleClickItem = (index, item) => {
    const { onClickItem } = this.props;
    onClickItem(index, item);
  };

  onSwipeStart = () => {
    this.setState({ swiping: true });
    this.clearAutoplay();
  };

  onSwipeEnd = () => {
    this.setState({
      swiping: false,
      swipeDistance: 0
    });
    this.autoplay();
  };

  onSwipeMove = ({ deltaX }) => {
    const { multiItem, fixedItem } = this.props;
    const { selectedItem } = this.state;
    const itemCount = this.getItemCount();
    const currentPosition = this.getPosition(selectedItem);
    const finalBoundary = this.getPosition(itemCount - 1);
    let handledDelta = deltaX;

    if (currentPosition === 0 && deltaX > 0) {
      handledDelta = 0;
    }
    if (currentPosition === finalBoundary && deltaX < 0) {
      handledDelta = 0;
    }

    if (multiItem) {
      let position = currentPosition + handledDelta;
      if (position < finalBoundary) {
        position = finalBoundary;
      }
      this.setPosition(position + '%');
      return true;
    }

    if (fixedItem) {
      const lastPod = (itemCount - Math.ceil(this.getItemsPerScreen())) + 2;
      const currentPositionPixels = -(selectedItem * this.getItemWidth());
      const maxSwipe = -(lastPod * this.getItemWidth());
      let positionPixels = currentPositionPixels + 2 * handledDelta;

      if (positionPixels < maxSwipe) {
        positionPixels = maxSwipe;
      }

      this.setPosition(positionPixels + 'px');
      return true;
    }
    this.setState({
      swipeDistance: deltaX
    });
    return false;
  };

  getItemCount = () => {
    const { children = [], totalItems } = this.props;
    return totalItems || children.length;
  };

  getItemWidth = () => {
    const { itemWidthPixelsMobile, itemWidthPixelsDesktop } = this.props;
    const { isMobile } = this.state;
    return isMobile ? itemWidthPixelsMobile : itemWidthPixelsDesktop;
  };

  getItemsPerScreen = () => {
    const { multiItem } = this.props;
    const { browserWidth, itemWidth } = this.state;
    let itemsPerScreen = browserWidth / this.getItemWidth();
    if (multiItem) {
      itemsPerScreen = 100 / parseInt(itemWidth, 0);
    }
    return itemsPerScreen;
  };

  getPosition = (index) => {
    const { multiItem, fixedItem } = this.props;
    const { itemWidth } = this.state;
    const itemCount = this.getItemCount();
    const itemsPerScreen = this.getItemsPerScreen();

    if (multiItem) {
      if (itemCount < itemsPerScreen) {
        return 0;
      }
      if (index > itemCount - itemsPerScreen) {
        return -((itemCount - itemsPerScreen) * itemWidth);
      }
      return -index * itemWidth;
    }
    if (fixedItem) {
      if (itemCount < itemsPerScreen) {
        return 0;
      }
      return -index * this.getItemWidth();
    }
    return -index * 100;
  };

  /**
   * This function will return the distance to move along with direction
   * for the bounce animation when the swipe reaches the end/start of carousel
   */
  getBounceDistance = () => {
    const { selectedItem, swipeDistance } = this.state;
    const { tolerancex } = this.props;

    if (swipeDistance === 0 || !this.carouselWrapperRef || !this.carouselWrapperRef.current) {
      return 0;
    }
    const carouselWrapperWidth = this.carouselWrapperRef.current.clientWidth;
    let bounceDistance = 0;
    // Direction of swiping
    // -1 => swiping left
    //  1 => Swiping right
    const swipeDirection = (Math.abs(swipeDistance) / swipeDistance);

    // Do not show bounce animation when gallery images are in zoom state
    if (carouselWrapperWidth > tolerancex) {
      const itemCount = this.getItemCount();
      const lastIndex = itemCount - 1;
      // if swiping on carousel start/end section
      if (selectedItem === 0 || selectedItem === lastIndex) {
        // Distance to move on single swipe. default is 100%
        const singleSwipeDistance = Math.abs(this.getPosition(1)) || 100;
        // swipeDistance is in pixel. converting it to percentage
        const swipeDistancePercentage = 100 * (Math.abs(swipeDistance) / carouselWrapperWidth);
        const BOUNCE_LIMITING_FACTOR = 0.25; // Maximum bounce distance is 25% of single swipe movement.
        const distanceToMove = Math.min(swipeDistancePercentage, (singleSwipeDistance * BOUNCE_LIMITING_FACTOR));
        if (selectedItem === lastIndex && swipeDirection === -1) {
          const endPosition = Math.abs(this.getPosition(lastIndex));
          bounceDistance = endPosition + distanceToMove;
        } else if (selectedItem === 0 && swipeDirection === 1) {
          bounceDistance = distanceToMove;
        }
      }
    }
    return bounceDistance * swipeDirection;
  };

  setPosition = (position) => {
    this.setState({ position });
  };

  moveTo = ({ itemspershift, direction = 'prev', swipe = true }) => {
    const {
      children, onChange, onPrevClick, onNextClick, continuousLoop
    } = this.props;
    const {
      selectedItem, isMouseEntered, autoplay, numberOfSkips, position, itemWidth
    } = this.state;
    itemspershift = itemspershift || numberOfSkips;
    const itemCount = this.getItemCount();
    const lastPosition = itemCount - 1;
    let nextPosition = selectedItem + (itemspershift * helpers.DIRECTIONAL_MULTIPLIER(direction));

    if (position && swipe) {
      if (position.indexOf('px') > 0) {
        const positionPX = parseInt(position, 0);
        const lastPod = (itemCount - Math.ceil(this.getItemsPerScreen())) + 2;
        nextPosition = -(Math.round(positionPX / this.getItemWidth()));
        if (positionPX < -(lastPod * this.getItemWidth())) {
          nextPosition = lastPod;
        }
      }
      if (position.indexOf('%') > 0) {
        const positionPercentages = parseInt(position, 0);
        nextPosition = -Math.round(positionPercentages / itemWidth);
      }
    }

    if (nextPosition < 0 && continuousLoop && autoplay) {
      nextPosition = lastPosition;
    } else if (nextPosition > lastPosition && continuousLoop && autoplay) {
      nextPosition = 0;
    }
    if (nextPosition < 0) {
      nextPosition = 0;
    }
    if ((!position || !swipe) && nextPosition > lastPosition) {
      nextPosition = lastPosition;
    }
    if (direction === 'prev' && onPrevClick) {
      onPrevClick(itemspershift, nextPosition);
    }
    if (direction === 'next' && onNextClick) {
      onNextClick(itemspershift, nextPosition);
    }
    this.setState({ selectedItem: nextPosition }, () => {
      if (itemCount <= 1) {
        return;
      }
      onChange(nextPosition, children[nextPosition]);
    });

    if (autoplay && isMouseEntered === false) {
      this.resetAutoplay();
    }
  };

  changeItem = (event) => {
    const { selectedItem } = this.state;
    const newIndex = event.target.value;
    let itemspershift;
    let direction = 'next';
    if (selectedItem < newIndex) {
      itemspershift = newIndex - selectedItem;
    } else {
      itemspershift = selectedItem - newIndex;
      direction = 'prev';
    }
    this.moveTo({
      itemspershift,
      direction
    });
  };

  renderItems = () => {
    const {
      children = [], itemClass, multiItem, fixedItem, transparent
    } = this.props;
    const { selectedItem, itemWidth } = this.state;

    return children.map((item, index) => {
      let slideProps = {
        key: 'item-key-' + index,
        className: classNames(
          helpers.ITEM(true, index === selectedItem), {
            'slide--transparent': transparent
          },
          itemClass
        ),
        onClick: this.handleClickItem.bind(this, index, item)
      };
      if (multiItem && !fixedItem && !isNaN(itemWidth)) {
        slideProps.style = {
          minWidth: itemWidth + '%',
          paddingRight: '10px'
        };
      }
      return (
        <li
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...slideProps}
        >{item}
        </li>
      );
    });
  };

  showPrevArrow = () => {
    const { showArrows, continuousLoop, autoplay } = this.props;
    const { selectedItem } = this.state;
    let showPrevArrow = selectedItem > 0;

    if (continuousLoop && autoplay) {
      showPrevArrow = true;
    }
    if (!showArrows) {
      showPrevArrow = false;
    }
    return showPrevArrow;
  };

  showNextArrow = () => {
    const {
      children, multiItem, onLoad, fixedItem, showArrows, continuousLoop, autoplay
    } = this.props;
    const { selectedItem } = this.state;
    const itemCount = this.getItemCount();
    const itemsPerScreen = this.getItemsPerScreen();
    let showNextArrow = false;

    if (multiItem) {
      showNextArrow = selectedItem + itemsPerScreen < itemCount;
    } else if (fixedItem) {
      showNextArrow = selectedItem + Math.round(itemsPerScreen) < itemCount + 1;
    } else {
      showNextArrow = selectedItem < itemCount - 1;
    }
    if (!showNextArrow) {
      onLoad(selectedItem + itemsPerScreen - 1, children[selectedItem]);
    }
    if (continuousLoop && autoplay) {
      showNextArrow = true;
    }
    if (!showArrows) {
      showNextArrow = false;
    }
    return showNextArrow;
  };

  swipeProps = () => {
    const {
      fixedItem, multiItem, transitionTime, disableSwipeNavigation, tolerancex
    } = this.props;
    const { selectedItem, swiping, numberOfSkips } = this.state;
    let currentPosition = this.getPosition(selectedItem);
    const bounceDistance = this.getBounceDistance();
    if (bounceDistance !== 0) {
      currentPosition = bounceDistance;
    }
    let itemListStyles = {
      transform: helpers.CSSTRANSLATE(currentPosition + (fixedItem ? 'px' : '%'))
    };

    if (!swiping) {
      itemListStyles = {
        ...itemListStyles, // eslint-disable-line
        transitionDuration: transitionTime + 'ms'
      };
    }

    return {
      className: helpers.SLIDER(true, swiping),
      onSwipeMove: this.onSwipeMove,
      onSwipeStart: this.onSwipeStart,
      onSwipeEnd: this.onSwipeEnd,
      style: itemListStyles,
      onSwipeLeft: this.moveTo,
      onSwipeRight: this.moveTo,
      itemspershift: numberOfSkips,
      multiItem,
      fixedItem,
      disableSwipeNavigation,
      tolerancex
    };
  };

  renderControls = () => {
    const { children = [], showDots } = this.props;
    const { selectedItem } = this.state;
    if (!showDots) {
      return;
    }
    return (
      <ul className="control-dots">
        {children.map((item, index) => {
          return (
            <li
              key={'dots-key-' + index.toString()}
              id={'dots-key-' + index.toString()}
              className={helpers.DOT(index === selectedItem)}
              onClick={this.changeItem}
              value={index}
            />
          );
        })}
      </ul>
    );
  };

  onScroll = (event) => {
    const { onScroll } = this.props;
    const { target = {} } = event;
    if (onScroll) {
      onScroll(target);
    }
  }

  render = () => {
    const { disableSwipeNavigation, dotBelow, fade } = this.props;
    const { mounted } = this.state;
    const className = classNames(
      'axis-horizontal',
      'slider-wrapper',
      { 'scroll-wrapper': disableSwipeNavigation }
    );
    return (
      <div ref={this.carouselWrapperRef} className={helpers.CAROUSEL(dotBelow)}>
        <div className="carousel-left-arrow">
          {this.showPrevArrow() && fade && <span className="carousel-left-gradient" />}
          {this.showPrevArrow() && (
            <ArrowButtons
              direction="left"
              onClick={() => this.moveTo({
                direction: 'prev',
                swipe: false
              })}
            />
          )}
        </div>
        <div ref={this.scrollDom} className={className}>
          <Swipe
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.swipeProps()}
          >
            {this.renderItems()}
          </Swipe>
        </div>
        <div className="carousel-right-arrow">
          {mounted && this.showNextArrow() && fade && <span className="carousel-right-gradient" />}
          {mounted && this.showNextArrow() && (
            <ArrowButtons
              direction="right"
              onClick={() => this.moveTo({
                direction: 'next',
                swipe: false
              })}
            />
          )}
        </div>
        {this.renderControls()}
      </div>
    );
  };
}

Carousel.propTypes = {
  /** Automatic carousel swipe */
  autoplay: PropTypes.bool,
  carouselParentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  /** Array of items for carousel */
  children: PropTypes.array,
  /** CSS class for carousel */
  className: PropTypes.string,
  /** Adds infinite loop to autoplay */
  continuousLoop: PropTypes.bool,
  /** disable javascript */
  disableSwipeNavigation: PropTypes.bool,
  /** Show indicators or dots below carousel */
  dotBelow: PropTypes.bool,
  /** Adds fade to arrows */
  fade: PropTypes.bool,
  /** Width pixel fixed items */
  fixedItem: PropTypes.bool,
  /** Time in seconds for automatic carousel swipe */
  interval: PropTypes.string,
  /** CSS class for items */
  itemClass: PropTypes.string,
  /** Children Item width in % for Desktop */
  itemWidthDesktop: PropTypes.string,
  /** Children Item width in % for Mobile */
  itemWidthMobile: PropTypes.string,
  /** Children Item width in pixels for Desktop */
  itemWidthPixelsDesktop: PropTypes.number,
  /** Children Item width in pixels for Mobile */
  itemWidthPixelsMobile: PropTypes.number,
  /** Single or multi items carousel */
  multiItem: PropTypes.bool,
  /** Called when item change position */
  onChange: PropTypes.func,
  /** Called when item is click */
  onClickItem: PropTypes.func,
  /** Called when the last items is render */
  onLoad: PropTypes.func,
  /** Called when the right arrow is clicked */
  onNextClick: PropTypes.func,
  /** Called when the left arrow is clicked */
  onPrevClick: PropTypes.func,
  /** Called when horizontal scrolling */
  onScroll: PropTypes.func,
  /** Screen Breakpoint in pixels */
  screenBreakpoint: PropTypes.number,
  /** Screen Max Size in pixels */
  screenMaxSize: PropTypes.number,
  scrollNavigation: PropTypes.bool,
  /** Current index number in items array */
  selectedItem: PropTypes.number,
  /** Hide/Show arrows */
  showArrows: PropTypes.bool,
  /** Hide/Show indicators or dots */
  showDots: PropTypes.bool,
  /** Stop automatic swipe when is hover */
  stopOnHover: PropTypes.bool,
  /** X-Threshold to be met to go next swipe item */
  tolerancex: PropTypes.number,
  /** Total children count on asynchronous call scenario to display next arrow */
  totalItems: PropTypes.number,
  /** Transition Time effect carousel swipe */
  transitionTime: PropTypes.number,
  /** Removes white background from carousel */
  transparent: PropTypes.bool,
};

Carousel.defaultProps = {
  autoplay: false,
  carouselParentRef: null,
  children: [],
  className: null,
  continuousLoop: false,
  disableSwipeNavigation: false,
  dotBelow: false,
  fade: false,
  fixedItem: false,
  interval: '3000',
  itemClass: null,
  itemWidthDesktop: '20%',
  itemWidthMobile: '40%',
  itemWidthPixelsDesktop: 220,
  itemWidthPixelsMobile: 150,
  multiItem: true,
  onClickItem: () => {
  },
  onChange: () => {
  },
  onLoad: () => {
  },
  onNextClick: () => {
  },
  onPrevClick: () => {
  },
  onScroll: null,
  screenBreakpoint: 1025,
  screenMaxSize: 1440,
  scrollNavigation: false,
  selectedItem: null,
  showArrows: true,
  showDots: false,
  stopOnHover: true,
  tolerancex: 5,
  totalItems: null,
  transitionTime: 350,
  transparent: false,
};

export { Carousel };
