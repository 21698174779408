import React from 'react';
import {
  Button,
} from '@one-thd/sui-atomic-components';
import { func, string, bool } from 'prop-types';
import { Duplicate } from '@one-thd/sui-icons';

export const CopyList = ({ listId, setCopyLinkDrawer, setMenuName, disabled }) => {

  const shareURL = typeof window !== 'undefined'
    && `https://${window.location.hostname}/list/view/details/shared/${listId}`;

  const handleCopyURL = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(shareURL).then(
      () => {
        setCopyLinkDrawer(true);
        setMenuName('Copy Link');
      }
    );
  };

  return (
    <Button
      variant="ghost"
      startIcon={Duplicate}
      onClick={handleCopyURL}
      disabled={disabled}
      data-testid="copy-link-button"
    >
      Copy Link
    </Button>
  );
};

CopyList.propTypes = {
  listId: string.isRequired,
  setCopyLinkDrawer: func.isRequired,
  setMenuName: func.isRequired,
  disabled: bool.isRequired
};