import React from 'react';
import { PxccMultiplierIcon } from './SvgComponent';
import { floorNumberWithCommas } from './CurrencyUtils';
import { HEADER_TEXT, SUBTITLE_TEXT } from '../../core/DrawerConstants';

const windowExists = () => typeof window !== 'undefined';

const documentExists = () => typeof document !== 'undefined';

export const getRewardAttributes = (currentRewardDetails) => {
  const {
    activationType,
    giftCard,
    availability,
    offerType,
    perkStatus,
  } = currentRewardDetails ?? {};

  // reward types
  const isPerk = offerType === 'PERK';
  const isOffer = offerType === 'OFFER';
  // availability types
  const isInStore = availability === 'IN_STORE';
  const isOnline = availability === 'ONLINE';
  const isOmni = availability === 'OMNI_CHANNEL';
  // hybrid availability types
  const isInStoreOrOmni = isInStore || isOmni;
  const isOnlineOrOmni = isOnline || isOmni;
  // activation types
  const isPricingEngine = activationType === 'PRICING_ENGINE';
  const isCms = activationType === 'CMS';
  const isPayments = activationType === 'PAYMENTS';
  // misc.
  const isGiftCard = giftCard;
  // statuses
  const isLocked = !currentRewardDetails?.perkStatus;
  const isUnclaimed = perkStatus === 'UNCLAIMED';
  const isActive = perkStatus === 'ACTIVE';
  const isRedeemed = perkStatus === 'REDEEMED';
  const isInactive = perkStatus === 'INACTIVE';
  // reward types
  const isToolRentalPerk = isPricingEngine && !isGiftCard && isInStore;
  const isOffersOnline = isPricingEngine && !isGiftCard && isOnline;
  const isCmsOffer = isCms && !isGiftCard && isInStore;
  const isCmsPerkB = isCms && isPerk;
  const isPxdReward = isPayments && isGiftCard && isOmni;

  return ({
    isPerk,
    isOffer,
    isInStore,
    isOnline,
    isOmni,
    isInStoreOrOmni,
    isOnlineOrOmni,
    isPricingEngine,
    isCms,
    isPayments,
    isGiftCard,
    isToolRentalPerk,
    isOffersOnline,
    isCmsOffer,
    isCmsPerkB,
    isPxdReward,
    isLocked,
    isUnclaimed,
    isActive,
    isRedeemed,
    isInactive,
  });
};

export const getDaysUntil = (dateString) => {
  if (!dateString) return 0;
  const currentDate = new Date();
  const date = new Date(dateString);
  return Math.ceil(
    (date.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
  );
};

export const getHostURL = (pathname) => {
  let hostName = 'www.homedepot.com';
  if (windowExists()) {
    hostName = window?.THD_GLOBAL?.secureHostName || hostName;
  }
  return `https://${hostName}/${pathname}`;
};

export const getCookie = (name) => {
  if (!documentExists()) return ('');
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name + '=') === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return '';
};

export const getCookieProperty = (cookieName, propertyName) => {
  const cookieValue = getCookie(cookieName);
  if (cookieValue) {
    try {
      const decodedUrl = decodeURIComponent(cookieValue);

      const parts = decodedUrl.split(':;');
      const thdPersistCookie = {};

      parts.forEach((part) => {
        const [key, value] = part.split('=');
        try {
          thdPersistCookie[key] = JSON.parse(value);
        } catch (err) {
          thdPersistCookie[key] = value;
        }
      });
      return parseInt(thdPersistCookie[propertyName]?.I1, 10);
    } catch (err) {
      console.error('Error parsing cookie URL', err);
    }
  }
  return null;
};

export const formatRewardText = (reward) => {
  if (!reward) {
    return {
      header: null,
      title: null,
      subtitle: null,
    };
  }

  const { isPerk, isCms, isPricingEngine, isOnline } = getRewardAttributes(reward);
  const displayAmount = isCms || (isOnline && isPricingEngine) ? '' : `$${reward?.amount} `;

  return {
    header: isPerk ? HEADER_TEXT.PERK_DETAILS : HEADER_TEXT.OFFER,
    title: displayAmount + reward?.perkTitle,
    subtitle: isPerk ? SUBTITLE_TEXT.ACTIVE_PERK : SUBTITLE_TEXT.OFFER,
  };
};

const Badge4x = ({ activePxccFactor }) => (
  <>
    with <PxccMultiplierIcon activePxccFactor={activePxccFactor} />
  </>
);

export const formatSpendUntilThisPerkSubtitle = (props) => {
  const { tierObj, accrualData, pxccUserCategory, activePxccFactor } = props;
  const isPxccEnrolled = pxccUserCategory === 'Enrolled';
  let untilSpend = 0;
  if (accrualData && pxccUserCategory) {
    const accrualYear = accrualData?.[0] ?? {};
    untilSpend = isPxccEnrolled ? accrualYear.spendForNextPerkWithPxcc : accrualYear.spendForNextPerk;
  } else if (tierObj) {
    untilSpend = tierObj?.earned ? 0 : tierObj?.spendForNextTier;
  }
  const formattedUntilSpend = floorNumberWithCommas(untilSpend ?? 0, 0);
  return (
    <>
      <span className="sui-mr-1">${formattedUntilSpend} until this Perk</span>
      {isPxccEnrolled && <Badge4x activePxccFactor={activePxccFactor} />}
    </>
  );
};

export const editLiveChatZIndex = () => {
  if (!windowExists()) return;
  const changeZIndex = (it) => {
    if (it?.parentElement?.classList) {
      it.parentElement.classList.add('rh-z-index-floor');
    }
  };
  document.querySelectorAll('img[alt="Live Chat"]')
    .forEach((it) => changeZIndex(it));
  document.querySelectorAll('img[alt="Feedback Link"]')
    .forEach((it) => changeZIndex(it));
};

export const incrementPerkCounter = () => {
  const currentCount = Number(sessionStorage.getItem('perkCounter'));
  sessionStorage.setItem('perkCounter', (currentCount + 1).toString());
};

export const capitalizeFirstLetter = (string) => {
  const lowerCasedString = string.toLowerCase();
  return lowerCasedString.charAt(0).toUpperCase() + lowerCasedString.slice(1);
};
