import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CardCvv = createSvgIcon(
  <>
    <path d="M19.5 14.25H15v2.25h4.5v-2.25Z" />
    <path d="M0 4.125C0 3.504.504 3 1.125 3h21.75C23.496 3 24 3.504 24 4.125v15.75c0 .621-.504 1.125-1.125 1.125H1.125A1.125 1.125 0 0 1 0 19.875V4.125ZM21.75 5.25H2.25V7.5h19.5V5.25Zm-19.5 13.5h19.5v-9H2.25v9Z" />
  </>,
  'CardCvv'
);

export { CardCvv };