import React from 'react';
import { bool, shape, number } from 'prop-types';
import classNames from 'classnames';
import ReviewHelper from '../Review/helpers/ReviewHelper';
import { pipReviewsPerPage, seoReviewsPerPage } from '../../constants';
import './pager-display-text.style.scss';

export const PagerDisplayText = ({
  topPager, pageContext, loading, statistics, showReviewsText, seoPageNumber
}) => {
  const pageSummaryReviewText = classNames(
    'pager__review-text', {
      'pager__review-text--bottom': !topPager
    }
  );

  const totalReviews = statistics?.totalResults;
  const reviewsDisplayPerPage = seoPageNumber ? seoReviewsPerPage : pipReviewsPerPage;
  const noResults = !totalReviews && topPager && !loading;
  if (noResults) {
    return <span className="pager__no-results">No results found</span>;
  } if (!totalReviews) {
    return null;
  }
  const start = (pageContext?.currentPage - 1) * reviewsDisplayPerPage + 1;
  const end = ((start + reviewsDisplayPerPage - 1) > totalReviews) ? totalReviews : start + reviewsDisplayPerPage - 1;
  const totalWithCommas = ReviewHelper.numberWithCommas(totalReviews);

  return (
    <div className="pager__summary">
      {'Showing '}
      <span className="pager__summary--bold">{start}</span>
      -
      <span className="pager__summary--bold">{end}</span>
      {' of '}
      <span className="pager__summary--bold">{totalWithCommas}</span>
      <span className={pageSummaryReviewText}>
        {showReviewsText || !topPager ? ' reviews' : ''}
      </span>
    </div>
  );
};

PagerDisplayText.displayName = 'PagerDisplayText';

PagerDisplayText.propTypes = {
  topPager: bool,
  pageContext: shape({
    currentPage: number
  }),
  loading: bool,
  showReviewsText: bool,
  statistics: shape({
    totalReviewCount: number
  }),
  seoPageNumber: number
};

PagerDisplayText.defaultProps = {
  topPager: false,
  pageContext: null,
  loading: false,
  showReviewsText: false,
  statistics: null,
  seoPageNumber: null
};
