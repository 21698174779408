import { createTheme } from '@one-thd/sui-atomic-components';

const PrimaryGreyTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

PrimaryGreyTheme.palette['--sui-palette-background-primary'] = '73 73 73';

export { PrimaryGreyTheme };