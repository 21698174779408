import React, { useReducer, useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import {
  extend,
  useLazyDataModel,
  string as stringType,
  params,
  customType,
} from '@thd-nucleus/data-sources';
import {
  DrawerHeader, DrawerFooter, ButtonGroup, Button, TextField, DrawerBody
} from '@one-thd/sui-atomic-components';
import { validateListName, LIST_SHAPE } from '../../helpers';
import { LIST_ALREADY_EXISTS_ERROR } from '../../constants';

export const RenameListDrawerBody = ({
  onClose,
  menuName,
  setMenuName,
  setMainMenuDrawer,
  setRenameListDrawer,
  list,
  onSave,
}) => {
  const {
    id: listId,
    name: initialName,
    listAccessType: initialListAccessType
  } = list || {};

  const [disableSave, setDisableSave] = useState(true);
  const [currentSavedName, setCurrentSavedName] = useState(initialName);
  const disableSaveButton = () => setDisableSave(true);

  const initialValues = {
    listName: currentSavedName,
    listNameError: false,
    listNameValid: false,
    listNameErrorMessage: null,
  };

  const [updateListName, updateListNameResponse] = useLazyDataModel('updateList', {
    variables: {
      id: listId
    },
    context: { withAuth: true }
  });

  const [formValues, setFormValues] = useReducer(
    (currentFormValues, newFormValues) => ({ ...currentFormValues, ...newFormValues }), initialValues
  );

  useEffect(() => {
    if (updateListNameResponse?.data?.updateList && updateListNameResponse?.data?.updateList?.name) {
      setCurrentSavedName(updateListNameResponse?.data?.updateList?.name);
      const resetValues = false;
      onClose(resetValues);
    } else if (updateListNameResponse?.error) {
      const gqlErrors = updateListNameResponse?.error?.graphQLErrors;
      let errorMessage = '';
      gqlErrors.forEach(function (err, index) {
        if (err?.message === LIST_ALREADY_EXISTS_ERROR) {
          errorMessage = LIST_ALREADY_EXISTS_ERROR;
        }
      });
      setFormValues({
        listNameValid: false,
        listNameError: true,
        listNameErrorMessage: errorMessage
      });
    }
  }, [updateListNameResponse?.data, updateListNameResponse?.error]);

  const onChangeListName = (event) => {
    const { id: currentId, value } = event.target;
    const { isError, errorMessage } = validateListName(value);

    if (value === currentSavedName) {
      setFormValues({
        [currentId]: value,
        listNameValid: false,
        listNameError: false,
        listNameErrorMessage: null
      });
      return;
    }

    const truncated = value.slice(0, 50);
    setFormValues(
      {
        [currentId]: truncated,
        listNameValid: !isError,
        listNameError: isError,
        listNameErrorMessage: errorMessage
      }
    );
  };

  const {
    listName,
    listNameError,
    listNameValid,
    listNameErrorMessage,
  } = formValues;

  useEffect(() => {
    setDisableSave(!listNameValid);
  }, [listNameValid]);

  const handleFormSubmit = async (submitEvent) => {
    submitEvent.preventDefault();
    disableSaveButton();
    const variables = { name: listName };

    if (listNameValid) {
      await updateListName({
        variables,
        refetchQueries: [
          'lists'
        ]
      });
    }
    onSave();
  };

  const PreviousDrawer = () => {
    setRenameListDrawer(false);
    setMainMenuDrawer(true);
    setMenuName('List Settings');
  };

  return (
    <>
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        <DrawerHeader title={menuName} onClose={onClose} onBack={PreviousDrawer} />
        <DrawerBody>
          <form
            className="sui-grid sui-gap-6"
            autoComplete="off"
            noValidate
            onSubmit={handleFormSubmit}
          >
            <TextField
              required
              id="listName"
              label="List Name"
              type="text"
              value={listName}
              fullWidth
              status={listNameError ? 'error' : null}
              statusMessage={listNameErrorMessage ?? null}
              onChange={onChangeListName}
            />
          </form>
        </DrawerBody>
        <DrawerFooter>
          <ButtonGroup orientation="vertical">
            <Button
              fullWidth
              variant="primary"
              onClick={handleFormSubmit}
              data-testid="edit-list-name"
              disabled={disableSave}
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-testid="cancel-rename-list"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </div>
    </>
  );
};

RenameListDrawerBody.displayName = 'RenameListDrawerBody';

RenameListDrawerBody.propTypes = {
  onClose: func.isRequired,
  setMainMenuDrawer: func.isRequired,
  setRenameListDrawer: func.isRequired,
  menuName: string.isRequired,
  setMenuName: func.isRequired,
  list: shape({
    id: string,
    name: string,
    public: bool
  }).isRequired,
  onSave: func,
};

RenameListDrawerBody.defaultProps = {
  onSave: () => { },
};

RenameListDrawerBody.dataModel = extend({
  updateList: params({
    id: stringType().isRequired(),
    name: stringType(),
    listAccessType: customType('AccessTypes').enum(['Private', 'Public', 'Shared'])
  }).mutation().shape(LIST_SHAPE),
});
