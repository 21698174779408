/* eslint-disable @mizdra/layout-shift/require-size-attributes */
import React, { useEffect, useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Typography, IconButton, Button, CardBody
} from '@one-thd/sui-atomic-components';
import { extend } from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { number, string, shape, arrayOf } from 'prop-types';
import { ArrowForward } from '@one-thd/sui-icons';
import { dataModel } from './dataModel';

export const UserListsCarouselTile = ({ list, i }) => {
  const { channel } = useContext(ExperienceContext);

  const thdCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : null;

  const remainingNumberProducts = channel === 'mobile' ? 3 : 4;

  const handleOnClick = (listUrl, listIndex) => {
    const componentNumber = listIndex + 1;
    const strComponentNumber = componentNumber.toString();
    const proCustomerSegment = thdCustomer?.segment || null;
    window.LIFE_CYCLE_EVENT_BUS.trigger('user-lists-carousel.viewSelectedLists', {
      widgetTemplates: proCustomerSegment,
      widgetCampaign: 'list arrow',
      widgetComponent: strComponentNumber,
    });
    window.location.href = window.origin + listUrl;
  };

  const { ref, clickID } = useImpression({
    data: {
      id: list.id,
      name: 'UserListsCarouselTile',
      component: 'UserListsCarouselTile',
      position: i + 1,
      type: 'content',
    },
  });

  return (
    <div
      key={`key-${list.id}-${i}`}
      className={`sui-border-b-1 sui-border-b-primary sui-border-solid sui-pb-4 sui-mb-1
    sui-grid ${list.itemCount > 0 && 'sui-grid-rows-2 '}`}
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      {
        (list.itemCount === 0)
          ? (
            <div className="sui-flex sui-justify-between sui-items-center sui-overflow-hidden">
              <Typography truncate variant="body-base" weight="bold">{list.name}</Typography>
              <IconButton
                icon={ArrowForward}
                onClick={() => handleOnClick(list.url, i)}
              />
            </div>
          )
          : (
            <div className="sui-row-start-1 sui-self-center sui-overflow-hidden">
              <Typography truncate variant="body-base" weight="bold">{list.name}</Typography>
            </div>
          )
      }
      { (list.itemCount > 0) && (
        <>
          <div data-testid="product-images-container" className="sui-flex sui-gap-4">
            {
              (list.imagesToShow.map((image, index) => (
                <img
                  key={`key-${list.name}-thumbnail-${index}`}
                  className="sui-w-12"
                  src={image.url}
                  alt={`${list.name}-thumbnail-${index}`}
                />
              )))
            }
            { (list.itemCount > remainingNumberProducts) && (
              <div className="sui-w-12 sui-h-12 sui-grid sui-place-items-center ">
                <Typography>+{list.itemCount - remainingNumberProducts}</Typography>
              </div>
            ) }
          </div>

          <div className="sui-row-start-1 sui-row-span-2 sui-self-center sui-justify-self-end">
            <IconButton
              icon={ArrowForward}
              alt={list.name}
              onClick={() => handleOnClick(list.url, i)}
            />
          </div>
        </>
      ) }
    </div>
  );

};

UserListsCarouselTile.propTypes = {
  i: number,
  id: string,
  list: shape({
    id: string,
    url: string,
    itemCount: number,
    name: string,
    imagesToShow: arrayOf(shape({
      url: string
    }))
  })
};

UserListsCarouselTile.defaultProps = {
  i: 1,
  id: undefined,
  list: {}
};

UserListsCarouselTile.displayName = 'UserListsCarouselTile';

UserListsCarouselTile.dataModel = extend(dataModel);