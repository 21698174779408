import React from 'react';

const CreateAccountCardContent = () => {
  return (
    <>
      <div className="sui-mb-4">
        <ul className="sui-list-inside sui-list-disc sui-text-base">
          <li>Save more with exclusive offers</li>
          <li>Track orders, save receipts</li>
          <li>Get 5% off subscription orders</li>
        </ul>
      </div>
    </>
  );
};

export { CreateAccountCardContent };
