import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { SpotlightContainer as SpotlightContainerComponent } from '@thd-olt-component-react/spotlight';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function SpotlightContainer({
  id,
}) {
  return (
    <ErrorBoundary name="spotlight-container">
      <QueryProvider key={id} cacheKey={id}>
        <SpotlightContainerComponent
          componentId={id}
          componentClass="SpotlightContainer"
          fontWeight="bold"
        />
      </QueryProvider>
    </ErrorBoundary>
  );
}

SpotlightContainer.propTypes = {
  id: string,
};

SpotlightContainer.defaultProps = {
  id: undefined,
};
