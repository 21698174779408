import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import helpers from '../../../helpers/price-helper';

export const BasicPerItem = ({ price }) => {
  const formattedPrice = helpers.getUSDollarWithCommas(price) + '/item';
  return (
    <Typography>({formattedPrice})</Typography>
  );
};

BasicPerItem.propTypes = {
  price: PropTypes.number,
};

BasicPerItem.defaultProps = {
  price: 0,
};

BasicPerItem.displayName = 'BasicPerItem';
