import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Swipe extends Component {

  getPosition = (event) => {
    if ('touches' in event) {
      const { pageX, pageY } = event.touches[0];
      return { positionX: pageX, positionY: pageY };
    }
    const { screenX, screenY } = event;
    return { positionX: screenX, positionY: screenY };
  }

  preventTouch = (event) => {
    let touchThreshold = 10;
    if (this.props) {
      const { threshold } = this.props;
      touchThreshold = threshold;
    }
    this.clientX = event.touches[0].clientX - this.firstClientX;
    this.clientY = event.touches[0].clientY - this.firstClientY;

    if (Math.abs(this.clientX) > touchThreshold && (typeof event.cancelable !== 'boolean' || event.cancelable)) {
      event.preventDefault();
      event.returnValue = false;
      return false;
    }

    return true;
  }

  handleSwipeStart = (event) => {
    const { positionX, positionY } = this.getPosition(event);
    this.moveStart = { positionX, positionY };
    const { onSwipeStart } = this.props;
    if (onSwipeStart) {
      onSwipeStart(event);
    }
  }

  handleSwipeMove = (event) => {
    if (!this.moveStart) {
      return;
    }
    const validTouch = this.preventTouch(event);
    if (!validTouch) {
      return validTouch;
    }
    const { positionX, positionY } = this.getPosition(event);
    const deltaX = positionX - this.moveStart.positionX;
    const deltaY = positionY - this.moveStart.positionY;
    const { onSwipeMove } = this.props;
    const shouldPreventDefault = onSwipeMove({ deltaX, deltaY }, event);
    if (shouldPreventDefault && (typeof event.cancelable !== 'boolean' || event.cancelable)) {
      event.preventDefault();
    }
    this.moving = true;
    this.movePosition = { deltaX, deltaY };
  }

  handleSwipeEnd = (event) => {
    const {
      onSwipeEnd, onSwipeLeft, onSwipeRight, itemspershift, tolerancex, tolerancey, multiItem, fixedItem
    } = this.props;
    if (onSwipeEnd) {
      onSwipeEnd(event);
    }
    if (this.moving && this.movePosition && onSwipeLeft && onSwipeRight) {
      const { deltaX, deltaY } = this.movePosition;
      if (multiItem || fixedItem) {
        if (deltaX < -tolerancex) {
          onSwipeLeft({ itemspershift, direction: 'next' });
        } else if (deltaX > tolerancex) {
          onSwipeRight({ itemspershift, direction: 'prev' });
        }
      } else {
        const deltaYAbs = Math.abs(deltaY);
        if (deltaYAbs < tolerancey) {
          if (deltaX < -tolerancex) {
            onSwipeLeft({ itemspershift, direction: 'next' });
          } else if (deltaX > tolerancex) {
            onSwipeRight({ itemspershift, direction: 'prev' });
          }
        }
      }
    }
    this.moveStart = null;
    this.moving = false;
    this.movePosition = null;
  }

  render() {
    const {
      className, style, children, disableSwipeNavigation
    } = this.props;
    const props = {
      onTouchStart: this.handleSwipeStart,
      onTouchMove: this.handleSwipeMove,
      onTouchEnd: this.handleSwipeEnd,
      className,
      style
    };

    if (disableSwipeNavigation) {
      return (
        <div className={className}>
          { children }
        </div>
      );
    }

    return (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        { children }
      </div>
    );
  }
}

Swipe.defaultProps = {
  onSwipeLeft: () => {},
  onSwipeRight: () => {},
  onSwipeStart: () => {},
  onSwipeMove: () => {},
  onSwipeEnd: () => {},
  disableSwipeNavigation: false,
  tolerancex: 5,
  tolerancey: 30,
  threshold: 10,
  itemspershift: 1,
  multiItem: true,
  fixedItem: false
};

Swipe.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  disableSwipeNavigation: PropTypes.bool,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  onSwipeStart: PropTypes.func,
  onSwipeMove: PropTypes.func,
  onSwipeEnd: PropTypes.func,
  tolerancex: PropTypes.number,
  tolerancey: PropTypes.number,
  threshold: PropTypes.number,
  itemspershift: PropTypes.number,
  multiItem: PropTypes.bool,
  fixedItem: PropTypes.bool
};

export { Swipe };
