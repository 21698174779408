import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const TextToConfirm = createSvgIcon(
  <>
    <path d="m10.875 13.966 6.354-6.353-1.591-1.591-4.763 4.762-2.512-2.513-1.591 1.591 4.103 4.104Z" />
    <path d="M21.75 0H2.25v19.5H6v4.462l7.437-4.462h8.313V0ZM4.5 17.25v-15h15v15h-6.687L8.25 19.988V17.25H4.5Z" />
  </>,
  'TextToConfirm'
);

export { TextToConfirm };