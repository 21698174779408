import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleCargo = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleCargo.palette['--sui-palette-background-primary'] = '157 153 120';

export { HouseStyleCargo };