import React from 'react';
import PropTypes from 'prop-types';
import { TimerNumAndText } from './TimerNumAndText';
import helpers from '../../helpers/countdownHelper';

const TimerNumsOverText = ({ timeStringAndMessaging }) => {
  const {
    timerDays,
    timerHours,
    timerMinutes,
    timerSeconds,
    daysText,
    hoursText,
    minuteText,
    secondsText,
  } = helpers.getTextBelow(timeStringAndMessaging.timeString);

  return (
    <div className="sui-flex sui-justify-center" data-component="TimerNumsOverText">
      {timerDays && (
        <>
          <TimerNumAndText timerNums={timerDays} timerText={daysText} />
          <div className="sui-flex-col sui-text-2xl sui-font-bold">:</div>
        </>
      )}
      <TimerNumAndText timerNums={timerHours} timerText={hoursText} />
      <div className="sui-flex-col sui-text-2xl sui-font-bold">:</div>
      <TimerNumAndText timerNums={timerMinutes} timerText={minuteText} />
      {!timerDays && (
        <>
          <div className="sui-flex-col sui-text-2xl sui-font-bold">:</div>
          <TimerNumAndText timerNums={timerSeconds} timerText={secondsText} />
        </>
      )}
    </div>
  );
};

TimerNumsOverText.displayName = 'TimerNumsOverText';

TimerNumsOverText.propTypes = {
  /**
   * To show message and time
   */
  timeStringAndMessaging: PropTypes.shape({
    messagingString: PropTypes.string,
    /* eslint-disable-next-line react/forbid-prop-types */
    timeString: PropTypes.any,
  }).isRequired,
};

export default TimerNumsOverText;
