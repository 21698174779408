import { node, string } from 'prop-types';
import React, {
  useContext
} from 'react';
import classNames from 'classnames';
import { DrawerContext } from '../Drawer';
import styles from './drawer-nav.module.scss';

/**
 * Navigation in Drawer
 * This is has a general close button
 * @param {*} param0
 */
const DrawerNav = ({
  children,
  backLinkTxt,
}) => {
  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    console.warn('DrawerNav is deprecated', 'Please migrate to DrawerHeader instead');
  }

  const { closeDrawer, setPreviousDrawer, currentDrawer, isFlex } = useContext(DrawerContext);

  /**
   * Back Label is constructed from the DrawerItem name, when using the
   * default nav
   */
  const createBackLabel = () => {
    const label = currentDrawer.replace(/-/g, ' ');
    return `Back to ${(label.split(' '))
      .reduce(
        (finalLabel, value) => `${finalLabel}${value[0].toUpperCase()}${value.substring(1)} `,
        ''
      )}`;
  };

  /**
   * Helps to navigate to the previous drawer
   * @param {*} event
   */
  const goBack = (event) => {
    event.preventDefault();
    setPreviousDrawer();
  };

  const navClasses = classNames(
    styles['drawer-nav'],
    styles['sticky-nav'],
    styles['sticky-nav--top'], {
      [styles['default-nav']]: !children,
      [styles['sticky-nav--flex']]: isFlex
    });

  return (
    <nav className={navClasses}>
      {children || (
        <a
          href="/"
          onClick={goBack}
          className={styles['drawer-nav-back']}
          data-testid="drawer-nav-back"
        >
          <img width="1" height="1" src="https://assets.homedepot-static.com/images/v1/caret-orange.svg" alt="" />
          {backLinkTxt || createBackLabel()}
        </a>
      )}
      <a
        href="/"
        onClick={(event) => closeDrawer(event, true)}
        className={styles.close}
        data-testid="drawer-nav-close"
      >
        <svg viewBox="0 0 32 32">
          <title>close</title>
          <path d={`M27.229 8.391l-3.385-3.386-7.843 7.838-7.84-7.84-3.386
            3.385 7.609 7.608-7.613 7.612 3.385 3.386 7.843-7.838 7.84 7.841
            3.387-3.386-7.61-7.608z`}
          />
        </svg>
      </a>
    </nav>
  );
};

DrawerNav.displayName = 'DrawerNav';

export default DrawerNav;

DrawerNav.propTypes = {
  children: node,
  backLinkTxt: string,
};

DrawerNav.defaultProps = {
  children: null,
  backLinkTxt: null,
};