import React from 'react';
import PropTypes from 'prop-types';
import { FormController } from '../form/FormController';
import { FormLabel } from '../form/FormLabel';
import { FormHelpMessage } from '../form/FormHelpMessage';
import { FormStatusMessage } from '../form/FormStatusMessage';
import { Input } from '../input/Input';
import useId from '../private/hooks/useId';

/**
 * The `TextField` wrapper component is a complete form control including a form label, text input, help text
 *  and status messaging.
 *
 * Related components: [Input](#input), [FormLabel](#formlabel), [FormController](#formcontroller),
 * [FormHelpMessage](#formhelpmessage), [FormStatusMessage](#formstatusmessage).
 *
 * Usage:
 *
 * ```jsx
 * import { TextField } from '@one-thd/sui-atomic-components';
 * ```
 */
const TextField = React.forwardRef((props, ref) => {

  const {
    autoComplete,
    autoFocus = false,
    defaultValue,
    disabled = false,
    FormHelpMessageProps,
    FormLabelProps,
    FormStatusMessageProps,
    fullWidth = false,
    id: idProp,
    InputProps = {},
    label,
    helpMessage,
    maxRows,
    minRows,
    multiline = false,
    name,
    optional,
    onChange,
    placeholder,
    required = false,
    statusMessage,
    status,
    type,
    value,
    ...other
  } = props;

  const id = useId(idProp);
  const formLabelId = label && id ? `${id}-label` : undefined;
  const helpMessageId = helpMessage && id ? `${id}-help-message` : undefined;
  const statusMessageId = statusMessage && id ? `${id}-status-message` : undefined;
  const ariaDescribedby = [];
  if (statusMessageId) ariaDescribedby.push(statusMessageId);
  if (helpMessageId) ariaDescribedby.push(helpMessageId);
  if (multiline) {
    InputProps.inputProps = {
      resize: (maxRows && minRows) ? 'vertical' : 'none',
      disableResizePastMaxRow: true,
      ...InputProps.inputProps
    };
  }
  return (
    <FormController
      disabled={disabled}
      ref={ref}
      status={status}
      fullWidth={fullWidth}
      {...other}
    >
      <FormLabel
        htmlFor={id}
        id={formLabelId}
        optional={optional}
        required={required}
        {...FormLabelProps}
      >
        {label}
      </FormLabel>
      <Input
        aria-describedby={ariaDescribedby.length ? ariaDescribedby.join(' ') : undefined}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        id={id}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        {...InputProps}
      />
      {statusMessage && (
        <FormStatusMessage id={statusMessageId} {...FormStatusMessageProps}>
          {statusMessage}
        </FormStatusMessage>
      )}
      {helpMessage && (
        <FormHelpMessage id={helpMessageId} {...FormHelpMessageProps}>
          {helpMessage}
        </FormHelpMessage>
      )}
    </FormController>
  );
});

TextField.displayName = 'TextField';

TextField.propTypes = {
  /**
   * If "on", when a user starts to type in a field, the browser should display options to fill
   * in the field, based on earlier typed values.
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element is focused during the first mount.
   * @default false
   */
  autoFocus: PropTypes.bool,
  /**
   * The default value for the input element.
   */
  defaultValue: PropTypes.string,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * Props applied to the [`FormHelpMessage`](#formhelpmessage) component.
   */
  FormHelpMessageProps: PropTypes.object,
  /**
   * Props applied to the [`FormLabel`](#formlabel) component.
   */
  FormLabelProps: PropTypes.object,
  /**
   * Props applied to the [`FormStatusMessage`](#formstatusmessage) component.
   */
  FormStatusMessageProps: PropTypes.object,
  /**
   * If `true`, width is 100%.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * Adds an id attribute to the Input component
   * and a htmlFor attribute to the FormLabel component.
   */
  id: PropTypes.string,
  /**
   * Props applied to the Input component.
   */
  InputProps: PropTypes.object,
  /**
   * The text for the FormLabel component.
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The help message content.
   */
  helpMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * If `true`, a `textarea` element will render instead of an input.
   */
  multiline: PropTypes.bool,
  /**
   * If `multiline` is set to `true`, this will determine maximum number of rows.
   */
  maxRows: PropTypes.number,
  /**
   * If `multiline` is set to `true`, this will determine minimum number of rows.
   */
  minRows: PropTypes.number,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * If true, adds "(optional)" to the FormLabel component. Optional has a lower priority over required.
   */
  optional: PropTypes.bool,
  /**
   * Adds onChange into Input component.
   */
  onChange: PropTypes.func,
  /**
   * Adds placeholder into Input component.
   */
  placeholder: PropTypes.string,
  /**
   * If true, adds a "*" to the FormLabel component. Required has a higher priority over optional.
   * @default false
   */
  required: PropTypes.bool,
  /**
   * The states of validation for the TextField component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The status message content.
   */
  statusMessage: PropTypes.string,
  /**
   * Type of the `input` element. It should be a valid HTML5 input type.
   */
  type: PropTypes.string,
  /**
   * The value for the Input component.
   */
  value: PropTypes.string
};

export { TextField };
