import React from 'react';
import {
  bool,
  string,
  shape,
  number,
  oneOfType
} from 'prop-types';
import classNames from 'classnames';
import { Badge } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import PriceFormat from './PriceFormat';
import InstantRebates from './InstantRebates';
import helpers, { PRICE_TYPES } from '../../helpers/price-helper';
import '../../styles/price-detailed.style.scss';
import { LAYOUT_TYPES } from '../../utils/utils';

export const PriceDetailed = ({
  channel,
  disableRangePricing,
  displayEachUom,
  hideBadge,
  hideBulkPrice,
  hideLimitPerOrder,
  hideRebates,
  hideSavingsText,
  omitDetails,
  skuModel,
  zipCode,
  showStartingAt,
  disableOuterSpacing,
  isClearance
}) => {
  const { pricing } = skuModel;

  // BRIO Skus
  const handleBRIOSku = () => (
    <div className="brio__wrapper">
      <div className="brio__quote-message-wrapper">
        <span className="brio__quote-message">Get a quote during a FREE in-home consultation</span>
      </div>
      <div className="brio__how-to-buy">How to Buy</div>
      <div className="brio__how-to-buy-details">
        {`During your free, in-home consultation one of our professional contractors will assess your home, discuss
          product and extended service options that best fit your needs and budget. Our expert will review financing
          options and provide a total project cost estimate. Your quote will include product, labor, and any
          additional parts or extended service options. Product availability may be limited.`}
      </div>
      <span className="brio__icon schedule_furnish_install_logo">Schedule Furnish Install Logo</span>
    </div>
  );

  // HIDE_PRICE/LIVE_GOODS
  const handleHidePrice = () => <div className="price-detailed__contact-message">Contact your local store for price</div>;

  // Limit per order with strike through
  const handleLimitPerOrder = ({
    price, pricingValues, priceBadge, uom, save, hasEcoRebates,
    bulkPrice, bulkPriceThresholdQty
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    if (!!pricing.quantityLimit) {
      return (
        <div>
          <div className="price-detailed__qty-limit-wrapper">
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
              disableOuterSpacing={disableOuterSpacing}
            />
            <div>
              {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'price-detailed__qty-limit')}
              <div className="price-detailed__was-price">
                <div>
                  {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
                  {helpers.getWasPrice(pricingValues)}
                </div>
                {helpers.getSavingsText(false, hideSavingsText, save)}
                {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
              </div>
            </div>
          </div>
          {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
        </div>
      );
    }
    return '';
  }

  // Unit and case price: regular,
  // with strike through, with bulk price, with bulk price and strike through
  const handleUnitAndCasePrice = ({
    price, alternatePrice, alternateUom, uom, pricingValues,
    save, bulkPrice, bulkPriceThresholdQty, priceBadge,
    unitPricingValues, hasEcoRebates
  }) => {
    const priceTemplateFormat = () => (
      <PriceFormat
          price={price}
          isSmallBadge={false}
          isSmallSize={false}
          disableOuterSpacing={disableOuterSpacing}
          isBulkPrice
        />
    );

    const altPriceTemplateFormat = () => (
      <PriceFormat
          price={alternatePrice}
          isSmallBadge={false}
          isSmallSize={false}
          disableOuterSpacing={disableOuterSpacing}
          isBulkPrice
        />
    );

    const coveragePriceTemplate = helpers.handlePriceParsing(price, true);
    const altPrice = alternatePrice.toString();
    const altUom = (alternateUom && alternateUom !== 'each') ? alternateUom : uom;
    const { unitWasPrice, unitNowPrice } = pricing;
    const pricePerUnit = unitWasPrice || unitNowPrice;
    const rightPriceClass = classNames({
      'price-detailed__right-price': bulkPrice && !hideBadge
    });
    const rightPriceRowClass = classNames({
      'price-detailed__right-price-row': bulkPrice && !hideBulkPrice
    });
    const leftPriceRowClass = classNames({
      'price-detailed__left-price-row': !bulkPrice
    });
    const rightPriceClassForIncludes = classNames({
      'price-detailed__right-price': priceBadge
    });
    const savePriceClass = classNames({
      'price-detailed__save': bulkPrice && !hideBulkPrice
    });
    if (pricePerUnit) {
      if (uom !== 'sq. ft.') {
        return (
          <div className="unit-case-price-includes">
            {pricing.unitsPerCase > 1 && <div className="price-detailed__unit-cover">{pricing.unitOfMeasureCoverage} ({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})</div>}
            {!bulkPrice &&
              <div className="price-detailed__right-price-wrapper">
                {priceBadge && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                <div className={rightPriceClassForIncludes}>
                  <span className="price-detailed__unit-price">
                    <PriceFormat
                    price={alternatePrice}
                    isSmallBadge={false}
                    isSmallSize={false}
                    hideBadge={hideBadge}
                    disableOuterSpacing={disableOuterSpacing}
                    isBulkPrice
                  />
                  </span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                  {helpers.getWasPrice(pricingValues)}
                    {helpers.getSavingsText(false, hideSavingsText, save)}
                  </div>
                </div>
              }
            {(bulkPrice && !hideBulkPrice) &&
                <div className="price-detailed__right-price-wrapper">
                  {priceBadge && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                  <div className="price-detailed__right-price">
                    <div>
                      <div className="price-detailed__unit-price price-detailed__alt-price" data-testid="altPriceTemplateFormat">{altPriceTemplateFormat()}</div>
                      {!pricingValues &&
                        <span> {alternateUom &&
                          helpers.parseUom(alternateUom, displayEachUom)}
                        </span>
                      }
                      {pricingValues &&
                        <div className="price-detailed__unit-bulk-price">
                          <span>{alternateUom &&
                            helpers.parseUom(alternateUom, displayEachUom)}
                          </span>
                          {helpers.getWasPrice(pricingValues)}
                          {helpers.getSavingsText(false, hideSavingsText, save)}
                        </div>
                      }
                    </div>
                    {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty)}
                  </div>
                </div>
              }
            {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
          </div>
        );
      } else if (uom === 'sq. ft.') {
        return (
          <>
            <div className="price-detailed__unit-cover">{pricing.unitOfMeasureCoverage}</div>
            <div className="price-detailed__unit-case-price">
              <div className={`price-detailed__left-price-wrapper no-margin-top ${leftPriceRowClass}`} data-testid="leftPriceWrapper">
                {(!bulkPrice && priceBadge && !hideBadge) && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                <div>
                <>
                  {(bulkPrice && priceBadge && !hideBadge) && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge-bulk`} />}
                  <div className={`flex-container ${rightPriceClass}`}>
                    <div className="price-detailed__unit-price" data-testid="altPriceTemplateFormat">{altPriceTemplateFormat()}</div>
                    <div className="was-price-details">
                      <div>
                        <span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
                        {` `}{helpers.getWasPrice(pricing)}
                      </div>
                      <div>
                        {helpers.getSavingsText(true, hideSavingsText, save, false, savePriceClass)}
                      </div>
                    </div>
                  </div>
                </>
                </div>
              </div>
              <div className={`price-detailed__right-price-wrapper no-margin-top ${rightPriceRowClass}`} data-testid="rightPriceWrapper">
                <div className="flex-container">
                  {price && <span className="price-detailed__unit-price" data-testid="priceTemplateFormat">{priceTemplateFormat()}</span>}
                    <div className="was-price-details">
                      <div>
                        <span> {uom && helpers.parseUom(uom, displayEachUom)} </span>
                        {helpers.getWasPrice(unitPricingValues)}
                      </div>
                      <div>
                        {helpers.getSavingsText(true, hideSavingsText, save, false, savePriceClass)}
                      </div>
                    </div>
                  </div>

              </div>
            </div>
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
            {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
          </>
        );
      }
    }
    return (
      <div id="unit-price">
        <PriceFormat
          price={altPrice}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        {!pricingValues &&
          <div className="price-detailed__was-price">
            <span className="priceFormat__unit-alignment">{altUom && helpers.parseUom(altUom, displayEachUom)} </span>
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
          </div>
        }
        {pricingValues &&
          <div className="price-detailed__was-price">
            <span className="priceFormat__unit-alignment">{altUom && helpers.parseUom(altUom, displayEachUom)} </span>
            {helpers.getWasPrice(pricingValues)}
            {helpers.getSavingsText(true, hideSavingsText, save)}
            {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
          </div>
        }
        {helpers.getLimitPerOrder(hideLimitPerOrder, pricing.quantityLimit, 'price-detailed__qty-limit')}
        {helpers.getRebates(hideRebates, hasEcoRebates, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
      </div>
    );
  }

  // Was price
  const handleWasPrice = ({
    pricingValues, save, priceBadge, uom
  }) => {
    return (
      <div id="was-price">
        <PriceFormat
          price={pricingValues.nowPrice}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price-detailed__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(false, hideSavingsText, save)}
        </div>
      </div>
    );
  }

  // Starting At price
  const handleStartingAt = ({
    pricingValues, save, price, priceBadge, uom
  }) => {
    const startingAtClass = classNames({
      'price--hidden': !showStartingAt
    });
    return (
      <div id="start-at" className={startingAtClass}>
        <div className="price-detailed__start-at">Starting at</div>
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          hideBadge={hideBadge}
          isSmallBadge={false}
          isSmallSize={false}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price-detailed__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(false, hideSavingsText, save)}
        </div>
      </div>
  )};

  // Map price
  const handleMapPrice = ({
    mapPrice, pricingValues, uom, priceBadge, pricingMessage
  }) => {
    const tooltipContent = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';
    const hasLowerPrice = /See Low Price in Cart|See Lower Price in Cart/.test(pricingMessage);

    let priceToDisplay = '';
    if (mapPrice) {
      priceToDisplay = mapPrice.wasPrice;
    } else if (pricingValues) {
      priceToDisplay = pricingValues.wasPrice;
    }
    const tooltipId = `price-tooltip-${pricing.itemId}`;
    const priceMsgClass = classNames('u__default-link');
    const lowPriceType = channel.toLowerCase() === 'mobile' ? 'lower-price-small' : 'lower-price';
    return (
      <div className="price-detailed__map-price price-detailed__map-price--block">
        {(mapPrice || pricingValues) && (
          <div>
            <span className="price-detailed__message">
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                isSmallBadge={false}
                isSmallSize={false}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
                hasStrikeThrough
              />
            </span>
            {uom && <span className="price-detailed__uom">{helpers.parseUom(uom, displayEachUom)} </span>}
          </div>
        )}
        {(!hasLowerPrice && !hideBadge) && <Badge type={lowPriceType} className="price-detailed__lower-price" />}
        <Tooltip
          channel={channel}
          closeButton
          content={tooltipContent}
          maxWidth={300}
          placement="bottom"
        >
          {/* eslint-disable-next-line */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              helpers.triggerAnalytics('map-link');
            }}
            id={tooltipId}
            className={priceMsgClass}
          >
            {pricingMessage}
          </a>
        </Tooltip>
      </div>
    );
  }

  // Bulk price
  const handleBulkPrice = ({
    alternateUom, price, pricingValues, bulkPrice, save, uom,
    bulkPriceThresholdQty, priceBadge
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="bulk-price">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <div className="price-detailed__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {helpers.getWasPrice(pricingValues)}
          {helpers.getSavingsText(true, hideSavingsText, save)}
          {helpers.getBulkPrice(hideBulkPrice, bulkPrice, bulkPriceThresholdQty, alternateUom, displayEachUom)}
        </div>
      </div>
    );
  }

  // Instant Rebate
  const handleInstantRebate = ({
    price, pricingValues, priceBadge, uom
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <>
        <div id="instant-rebate" className="price-detailed__instant-rebates">
          <PriceFormat
            price={priceToDisplay}
            priceBadge={priceBadge}
            isSmallBadge={false}
            isSmallSize={false}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <div className="price-detailed__was-price price-detailed__ir-was">
            <div>
              {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
              {helpers.getWasPrice(pricingValues)}
            </div>
          </div>
        </div>
        {!hideRebates && (
          <InstantRebates
            storePromotion={pricing.storePromotion}
            price={pricing}
            channel={channel}
            hasDetails
          />
        )}
      </>
    );
  }

  // Eco Rebate
  const handleEcoRebates = ({
    price, priceBadge, uom, pricingValues, save,
    mapPrice, pricingMessage, bulkPrice, bulkPriceThresholdQty
  }) => {
    const priceToDisplay = pricingValues ? pricingValues.nowPrice : price;
    return (
      <div id="eco-rebate-price">
        {(bulkPrice && !hideBulkPrice) && handleBulkPrice({
          price, priceBadge, uom, pricingValues, save, bulkPrice, bulkPriceThresholdQty
        })}
        {(mapPrice || pricingMessage) && handleMapPrice({
          mapPrice, price, priceBadge, uom, pricingMessage
        })}
        {(!mapPrice && !pricingMessage && !bulkPrice) && (
          <>
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
              disableOuterSpacing={disableOuterSpacing}
            />
            <div className="price-detailed__was-price">
              {uom &&
                <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
              }
              {helpers.getWasPrice(pricingValues)}
              {helpers.getSavingsText(false, hideSavingsText, save)}
            </div>
          </>
        )}
        {helpers.getRebates(hideRebates, true, pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
      </div>
    );
  }

  // No details
  const handleNoDetailsPrice = ({
    price, uom, pricingValues, save, priceBadge
  }) => (
    <div id="no-details-price">
      {price && (
        <>
          <PriceFormat
            price={price}
            priceBadge={priceBadge}
            isSmallBadge={false}
            isSmallSize={false}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <div className="price-detailed__was-price">
            {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
            {helpers.getWasPrice(pricingValues)}
            {helpers.getSavingsText(false, hideSavingsText, save)}
          </div>
        </>
      )}
    </div>
  );

  // Standard price
  const handleStandardPrice = ({
    price, priceBadge, uom
  }) => (
    <div id="standard-price">
      <PriceFormat
        price={price}
        priceBadge={priceBadge}
        isSmallBadge={false}
        isSmallSize={false}
        hideBadge={hideBadge}
        disableOuterSpacing={disableOuterSpacing}
      />
      {uom && <span> {helpers.parseUom(uom, displayEachUom)}</span>}
    </div>
  );

  // Range Price
  const handleRangePrice = ({
    minPrice, maxPrice, wasMinPrice, wasMaxPrice, save
  }) => (
    <div id="range-price">
      <div>
        <PriceFormat
          price={minPrice}
          isSmallSize={false}
          isSmallBadge={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
        <span className="u__bold price__range"> - </span>
        <PriceFormat
          price={maxPrice}
          isSmallSize={false}
          isSmallBadge={false}
          hideBadge={hideBadge}
          disableOuterSpacing={disableOuterSpacing}
        />
      </div>
      <div>
        {helpers.getWasPriceRange(wasMinPrice, wasMaxPrice)}
        {helpers.getSavingsText(true, hideSavingsText, save, false)}
      </div>
    </div>
  );

  const renderByType = ({ _price, type }) => {
    switch (type) {
      case PRICE_TYPES.STANDARD:
        return handleStandardPrice(_price);
      case PRICE_TYPES.STRIKE_THROUGH:
        return handleWasPrice(_price);
      case PRICE_TYPES.STARTING_AT:
        return handleStartingAt(_price);
      case PRICE_TYPES.MAP_PRICE:
        return handleMapPrice(_price);
      case PRICE_TYPES.ECO_REBATE:
        return handleEcoRebates(_price);
      case PRICE_TYPES.INSTANT_REBATE:
        return handleInstantRebate(_price);
      case PRICE_TYPES.BULK_PRICE:
        return handleBulkPrice(_price);
      case PRICE_TYPES.UNIT_AND_CASE:
        return handleUnitAndCasePrice(_price);
      case PRICE_TYPES.LIMIT_PER_ORDER:
        return handleLimitPerOrder(_price);
      case PRICE_TYPES.BRIO:
        return handleBRIOSku();
      case PRICE_TYPES.LIVE_GOODS:
        return handleHidePrice();
      case PRICE_TYPES.NO_DETAILS:
        return handleNoDetailsPrice(_price);
      case PRICE_TYPES.RANGE_PRICE:
        return handleRangePrice(_price);
      default: return null;
    }
  };

  const wrapperClass = classNames('price-detailed__wrapper', {
    'price-detailed__wrapper__no-padding': disableOuterSpacing,
    '!sui-pt-0': isClearance // remove 5px pd top seen when Online Price is displayed
  });

  return (
    <div className={wrapperClass} data-testid="price-detailed">
      {renderByType(helpers.getPricingObject({
        skuModel, displayEachUom, omitDetails, basic: false, disableRangePricing
      }))}
    </div>
  );
}

PriceDetailed.propTypes = {
  channel: string.isRequired,
  disableRangePricing: bool,
  displayEachUom: bool,
  hideBadge: bool,
  hideBulkPrice: bool,
  hideLimitPerOrder: bool,
  hideRebates: bool,
  hideSavingsText: bool,
  omitDetails: bool,
  skuModel: shape({
    pricing: shape({
      uom: string,
      unitsPerCase: number,
      wasPrice: oneOfType([number, string]),
      nowPrice: oneOfType([number, string]),
      specialBuyPrice: oneOfType([number, string]),
      dollarOff: oneOfType([number, string]),
      percentageOff: oneOfType([number, string]),
      specialBuySavings: oneOfType([number, string]),
      specialBuyDollarOff: oneOfType([number, string]),
      alternatePriceDisplay: bool,
      quantityLimit: oneOfType([number, string]),
      itemId: oneOfType([number, string]),
      unitWasPrice: oneOfType([number, string]),
      unitNowPrice: oneOfType([number, string]),
      storePromotion: shape(),
      unitOfMeasureCoverage: string
    }).isRequired
  }).isRequired,
  zipCode: string,
  showStartingAt: bool,
  disableOuterSpacing: bool,
  isClearance: bool
};

PriceDetailed.defaultProps = {
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hideRebates: false,
  hideSavingsText: false,
  omitDetails: false,
  zipCode: null,
  showStartingAt: false,
  disableOuterSpacing: false,
  isClearance: false
};

