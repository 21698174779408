import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './placeholder.module.scss';

const cx = classNames.bind(styles);

const Placeholder = ({ className, height, type, width }) => {

  return (
    <div
      className={cx('placeholder', type, className)}
      style={{ height, width }}
    />
  );
};

Placeholder.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.oneOf(['rect', 'round', 'text']),
  width: PropTypes.string
};

Placeholder.defaultProps = {
  className: null,
  height: null,
  type: 'rect',
  width: null
};

export { Placeholder };