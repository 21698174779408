import {
  arrayOf as arrayOfType,
  extend,
  params,
  shape as shapeType,
  string as stringType,
} from '@thd-nucleus/data-sources';

import { Price } from '@thd-olt-component-react/price';

export const QuickviewDrawerDataModel = extend(
  {
    product: params({
      itemId: stringType().isRequired(),
    }).shape({
      itemId: stringType(),
      dataSources: stringType(),
      identifiers: shapeType({
        brandName: stringType(),
        productLabel: stringType(),
      }),
      media: shapeType({
        images: arrayOfType(shapeType({
          url: stringType(),
        })),
      }),
      reviews: shapeType({
        ratingsReviews: shapeType({
          averageRating: stringType(),
          totalReviews: stringType()
        })
      })
    })
  },
  Price
);
