import merge from 'deepmerge';

export const extend = (...components) => {
  const models = components.map((component) => {
    if (!component) throw new Error('Component can not be extended because its undefined');
    if (component.dataModel) {
      return component.dataModel;
    }
    //
    if (typeof component === 'function') {
      throw new Error(`The component ${component.displayName || component.name} does not have a data model to extend!`);
    }

    return component;
  });

  return models.reduce((acc, cur) => {
    return merge(acc, cur);
  }, {});
};
