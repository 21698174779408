import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { ReactTooltipContent } from './ReactTooltipContent.component';

const CloseButtonTooltip = ({
  appendTo,
  children,
  className,
  content,
  distance,
  forwardedRef,
  maxWidth,
  onShow,
  placement,
  showClose,
  visible,
  zIndex,
  skidding
}) => {
  const { onClick, onClose } = children.props;
  const [isVisible, setIsVisible] = useState(visible);
  const onHidden = (event) => {
    if (isVisible) {
      if (onClose) onClose(event);
      setIsVisible(false);
    }
  };
  const onOpen = (event) => {
    if (onClick) onClick(event);
    // Clicking the same element should toggle visibility
    setIsVisible(!isVisible);
  };
  return (
    <Tippy
      appendTo={appendTo}
      arrow
      className="thd-tooltip"
      content={(
        <ReactTooltipContent
          className={className}
          onClose={onHidden}
          ref={forwardedRef}
          showClose={showClose}
        >
          {content}
        </ReactTooltipContent>
      )}
      interactive
      maxWidth={maxWidth}
      offset={[skidding, distance]}
      onClickOutside={onHidden}
      onHidden={onHidden}
      onShow={onShow}
      placement={placement}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [{
          name: 'preventOverflow',
          options: {
            // equivalent to boundary: 'window' in v1, usually NOT necessary in v2
            rootBoundary: 'document',
          },
        }, {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom']
          }
        }]
      }}
      visible={isVisible}
      zIndex={zIndex}
    >
      {React.cloneElement(children, {
        onClick: onOpen,
      })}
    </Tippy>
  );
};

CloseButtonTooltip.propTypes = {
  appendTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  distance: PropTypes.number,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  maxWidth: PropTypes.number,
  onShow: PropTypes.func,
  placement: PropTypes.string,
  showClose: PropTypes.bool,
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
  skidding: PropTypes.number
};

CloseButtonTooltip.defaultProps = {
  appendTo: () => document.body,
  className: '',
  distance: 25,
  forwardedRef: null,
  maxWidth: 500,
  onShow: () => {},
  placement: 'top',
  showClose: true,
  visible: false,
  zIndex: 9999,
  skidding: 0
};

export { CloseButtonTooltip };
