/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */

const STH = 'ShipToHome';
const BOPIS = 'BOPIS';
const BOSS = 'ShipToStore';
const BODFS = 'DeliverFromStore';
const APPLIANCE = 'DirectDelivery';
const NOT_AVAILABLE = ['UAVL', 'NAVL'];

const getAsNumber = (num = '0') => {
  if (num) {
    return parseInt(num, 10);
  }
  return 0;
};

const getStoreNameRemoveStoreNumber = (storeNameAndNumber = '') => {
  return storeNameAndNumber ? storeNameAndNumber.substring(0, storeNameAndNumber.indexOf('#') - 1) : null;
};

const getInventory = (ffm) => {
  const availQuantity = getAsNumber(ffm.availableQuantity);
  return {
    isOutOfStock: (ffm.selected ? false : !!ffm.isOutOfStock),
    quantity: availQuantity,
    isInStock: (ffm.selected ? true : availQuantity > 0),
    isLimitedQuantity: (ffm.selected ? false : ffm.inventoryStatus === 'LQTY'),
    isUnavailable: (ffm.selected ? false : NOT_AVAILABLE.includes(ffm.inventoryStatus)),
    backordered: ffm.backordered || null,
    maxAllowedBopisQty: null,
    minAllowedBopisQty: null,
  };
};

const getLocation = (ffm, isAnchor) => {
  return {
    curbsidePickupFlag: ffm.curbsidePickupEligible || null,
    isBuyInStoreCheckNearBy: false,
    distance: ffm.distance || null,
    inventory: getInventory(ffm),
    isAnchor,
    locationId: ffm.pickUpStore || null,
    state: ffm.state || null,
    city: ffm.city || null,
    zipCode: ffm.zipCode || null,
    storeName: getStoreNameRemoveStoreNumber(ffm.pickUpStoreAddress),
    storePhone: null,
    storeHours: null,
    type: (ffm.fulfillmentMethod === BOPIS || ffm.fulfillmentMethod === BODFS) ? 'store' : 'online'
  };
};

const invariantViolations = {
  expectedArrival: null,
  optimalFulfillment: null,
  nearbyStores: null,
  storeHours: null,
  dynamicEta: null,
  deliveryTimeline: null,
};

const getPickupService = (ffm, isBlindsItem) => {
  const pickupService = {
    ...invariantViolations,
    isBlindsItem,
    deliveryCharge: null,
    freeDeliveryThreshold: null,
    hasFreeShipping: ffm.freeShipping || false,
    totalCharge: null,
    selected: ffm.selected || false,
    locations: [],
    type: ffm.fulfillmentMethod === BOPIS ? 'bopis' : 'boss',
    nearbyStores: ffm.nearbyStoreModel || null,
    storeHours: ffm.storeHoursModel || null,
    deliveryDates: {
      startDate: ffm.estStartDeliveryDate || null,
      endDate: ffm.estEndDeliveryDate || null
    },
    estimatedDelivery: ffm.estimatedDelivery || null,
  };
  pickupService.locations.push(getLocation(ffm, true));
  // for store nearby model
  if (ffm.nearbyStoreModel) {
    pickupService.locations.push(getLocation(ffm.nearbyStoreModel, false));
  }
  return pickupService;
};

const getDeliveryService = (ffm, consolidatedDeliveryCharge) => {
  let shipCharge;
  if (ffm.fulfillmentMethod === BODFS) {
    shipCharge = ffm.estDeliveryCharge || ffm.totalDeliveryCharge;
  } else {
    shipCharge = ffm.totalShippingCharge;
  }

  let deliveryService = {
    ...invariantViolations,
    selected: ffm.selected || false,
    deliveryTimeline: (ffm && ffm.deliveryDateAvailability) ? ffm.deliveryDateAvailability.toLowerCase() : null,
    deliveryDates: ffm ? {
      startDate: ffm.estStartDeliveryDate || null,
      endDate: ffm.estEndDeliveryDate || ffm.estStartDeliveryDate || null
    } : null,
    deliveryCharge: shipCharge ? Number(shipCharge) : null,
    hasFreeShipping: ffm.freeShipping,
    freeDeliveryThreshold: ffm.freeShippingIfThresholdMet ? 45 : null,
    // dynamic ETA
    dynamicEta: (ffm.timeLeftHrs || ffm.timeLeftMins) ? {
      hours: ffm.timeLeftHrs,
      minutes: ffm.timeLeftMins
    } : null,
    locations: [getLocation(ffm, true)],
    type: ffm.fulfillmentMethod === BODFS ? 'express delivery' : 'sth',
    totalCharge: shipCharge ? Number(shipCharge) : null,
    maxCartPriceContributor: ffm.maxCartPriceContributor,
    estimatedDelivery: ffm.estimatedDelivery || null,
  };
  deliveryService.consolidatedDeliveryCharge = (consolidatedDeliveryCharge || deliveryService.totalCharge);
  if (deliveryService.consolidatedDeliveryCharge && deliveryService.consolidatedDeliveryCharge.toString) {
    deliveryService.consolidatedDeliveryCharge = deliveryService.consolidatedDeliveryCharge.toString();
  }
  return deliveryService;
};

const getApplianceDeliveryService = (ffm, item) => {
  const avail = getApplianceDeliveryData(ffm, item);
  // zipCode: ffm.deliveryZipCode,
  // primaryStrNbr: item.applianceDeliveryStore,
  // availability: [{
  //   itemId: item.itemId,
  //   modelNbr: item.modelNumber || '',
  //   status: availableStatus[ffm.inventoryStatus]
  // }],
  // earliestAvailabilityDate: item.expectedArrival,
  // hasFreeShipping: !item.applianceDeliveryCharge
  const deliveryService = {
    type: 'direct delivery',
    expectedArrival: avail.earliestAvailabilityDate,
    hasFreeShipping: avail.hasFreeShipping,
    estimatedDelivery: null,
    freeDeliveryThreshold: false,
    deliveryCharge: null,
    selected: true,
    optimalFulfillment: false,
    dynamicEta: null,
    deliveryDates: null,
    totalCharge: null,
    deliveryTimeline: null,
    locations:
            [
              {
                isAnchor: null,
                locationId: null,
                zipCode: avail.zipCode,
                curbsidePickupFlag: null,
                isBuyInStoreCheckNearBy: null,
                distance: null,
                storeName: null,
                city: null,
                state: null,
                storePhone: null,
                type: null,
                inventory:
                    {
                      isOutOfStock: (avail.availability.status !== 'AVAILABLE'),
                      quantity: null,
                      isInStock: (avail.availability.status === 'AVAILABLE'),
                      isUnavailable: false,
                      isLimitedQuantity: null,
                      backordered: (avail.availability.status === 'BACK_ORDERED'),
                      maxAllowedBopisQty: null,
                      minAllowedBopisQty: null
                    },
                storeHours: null
              }
            ],
  };

  return deliveryService;
};

const getApplianceDeliveryData = (ffm, item) => {
  if (ffm.fulfillmentMethod !== APPLIANCE) {
    return null;
  }
  const availableStatus = {
    AVL: 'AVAILABLE',
    BACK_ORDERED: 'BACK_ORDERED'
  };
  const deliveryAvailability = {
    zipCode: ffm.deliveryZipCode,
    primaryStrNbr: item.applianceDeliveryStore,
    availability: [{
      itemId: item.itemId,
      modelNbr: item.modelNumber || '',
      status: availableStatus[ffm.inventoryStatus]
    }],
    earliestAvailabilityDate: item.expectedArrival,
    hasFreeShipping: !item.applianceDeliveryCharge
  };
  return deliveryAvailability;
};

export const transformModel = (item, consolidatedDeliveryCharge) => {
  try {
    let model = {
      anchorStoreStatus: true,
      anchorStoreStatusType: 'ACTIVE',
      backordered: null,
      backorderedShipDate: null,
      bossExcludedShipStates: null,
      excludedShipStates: null,
      seasonStatusEligible: null,
      inStoreAssemblyEligible: item.freeAssemblyEligible || false,
      fulfillmentOptions: [{
        type: 'pickup',
        services: [],
        addressId: null,
      },
      {
        type: 'delivery',
        services: [],
        addressId: null,
      }],
      onlineStoreStatus: true,
      onlineStoreStatusType: 'ACTIVE',
      bossExcludedShipState: null,
      sthExcludedShipState: null,
    };
    let fulfillable = true;
    let ffmBuyInStore = false;
    const pickupSvc = model.fulfillmentOptions[0].services;
    const deliverySvc = model.fulfillmentOptions[1].services;
    const isBlindsItem = !!item.configurationId;
    // const cartFulfillmentKeys = Object.keys(item.cartFulfillments || {});
    item.fulfillmentModel.forEach((ffm) => {
      // const ffm = item.cartFulfillments[key];
      if (ffm.fulfillmentMethod) {
        // for backordered item
        if (ffm.fulfillmentMethod === BOSS || ffm.fulfillmentMethod === STH) {
          model.backordered = ffm.inventoryStatus === 'BO';
        }
        if (ffm.fulfillmentMethod === BOPIS || ffm.fulfillmentMethod === BOSS) {
          pickupSvc.push(getPickupService(ffm, isBlindsItem));
          // In Store Purchase Only
          if (ffm.buyInStore) {
            ffmBuyInStore = true;
          }
        } else if (ffm.fulfillmentMethod === STH || ffm.fulfillmentMethod === BODFS) {
          deliverySvc.push(getDeliveryService(ffm, consolidatedDeliveryCharge));
        } else if (ffm.fulfillmentMethod === APPLIANCE) {
          deliverySvc.push(getApplianceDeliveryService(ffm, item));
        } else {
          console.error('Unhandled fulfillment method', ffm.fulfillmentMethod, ffm);
        }
      }

      model.deliveryAvailability = getApplianceDeliveryData(ffm, item);

    });
    // for pickup service
    model.fulfillmentOptions[0].fulfillable = (
      (pickupSvc.length > 0) && (ffmBuyInStore ? !ffmBuyInStore : fulfillable)
    );
    // for delivery service
    model.fulfillmentOptions[1].fulfillable = (
      (deliverySvc.length > 0) && fulfillable
    );

    if (pickupSvc.length === 0 && deliverySvc.length === 0) {
      model.fulfillmentOptions = null;
    }

    // set address to prevent invariant violation
    if (model.fulfillmentOptions) {
      model.fulfillmentOptions.forEach((ffm) => {
        ffm.address = null;
      });
    }

    return {
      fulfillment: model,
    };
  } catch (err) {
    console.error('fulfillmentCartAdapter: transformModel: ', err);
  }
  return null;
};
