import React, { memo } from 'react';
import { Button } from '@one-thd/sui-atomic-components';

import { useMessage } from '../../hooks/useMessage';
import { MESSAGE_KEY } from '../../utils/constants';

export const ViewCartButton = memo(() => {
  const viewCartButtonText = useMessage(MESSAGE_KEY.viewCartButton);

  return (
    <Button fullWidth href="/cart">
      {viewCartButtonText}
    </Button>
  );
});

ViewCartButton.displayName = 'ViewCartButton';
