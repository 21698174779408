import React from 'react';
import PropTypes from 'prop-types';
import { InputBase } from './InputBase';

/**
 * Base component for creating text fields with adornments and status validation styling.
 *
 * Related components: [FormController](#formcontroller), [TextField](#textfield), [InputAdornment](#inputadornment)
 *
 * Usage:
 *
 * ```jsx
 * import { Input } from '@one-thd/sui-atomic-components';
 * ```
 */
const Input = React.forwardRef((props, ref) => {
  const {
    components = {},
    fullWidth = false,
    multiline = false,
    type = 'text',
    ...other
  } = props;

  // restrict class overrides
  const classes = {};

  return (
    <InputBase
      components={{ Root: 'div', Input: 'input', ...components }}
      fullWidth={fullWidth}
      multiline={multiline}
      ref={ref}
      type={type}
      {...other}
      classes={classes}
    />
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  /**
   * If "on", when a user starts to type in a field, the browser should display options to fill
   * in the field, based on earlier typed values.
   */
  autoComplete: PropTypes.string,
  /**
   * If `true`, the `input` element is focused during the first mount.
   */
  autoFocus: PropTypes.bool,
  /**
   * The components used for the root and input slot inside.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
  /**
   * The default value for the input element.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the status icons are disabled.
   */
  disableStatus: PropTypes.bool,
  /**
   * End `InputAdornment` for component.
   */
  endAdornment: PropTypes.node,
  /**
   * If `true`, the `input` will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If `true`, the `input` component will transform into a textarea.
   * @default false
   */
  multiline: PropTypes.bool,
  /**
   * The id attribute that is added to the input element
   */
  id: PropTypes.string,
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes)
   * applied to the `input` element.
   */
  inputAttributes: PropTypes.object,
  /**
   * Props applied to the `input` element
   */
  inputProps: PropTypes.object,
  /**
   * Pass a ref to the `input` element.
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * The name attribute that is added to the input element.
   */
  name: PropTypes.string,
  /**
   * The onChange attribute that is added to the input element.
   */
  onChange: PropTypes.func,
  /**
   * The placeholder attribute that is added to the input element.
   */
  placeholder: PropTypes.string,
  /**
   * Prevents the user from changing the value of the input
   * (not from interacting with the field).
   */
  readOnly: PropTypes.bool,
  /**
   * Start `InputAdornment` for component.
   */
  startAdornment: PropTypes.node,
  /**
   * The states of validation for the Input component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The type attribute that is added to the input element.
   * @default 'text'
   */
  type: PropTypes.string,
  /**
   * The value attribute that is added to the input element.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { Input };
