import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '../typography/Typography';

/**
 * `DrawerTitle` is used, inside `DrawerHeader`, on stacked nested `Drawer`s.
 *
 * Related components: [Drawer](#drawer), [DrawerHeader](#drawerheader)
 *
 * See available icons in the
 * [Icon section](https://brand.homedepot.com/site/knapsack-nucleus/latest/pattern/icons?subPageId=DESIGN)
 *
 * Usage:
 *
 * ```jsx
 * import { DrawerTitle } from '@one-thd/sui-atomic-components';
 * ```
 */
const DrawerTitle = (props) => {

  const {
    children: childrenProp,
    disableGutters = false,
    ornament: ornamentProp
  } = props;

  let children = childrenProp;
  if (children != null && children.type !== Typography) {
    children = (
      <Typography component="span" variant="h4">
        {children}
      </Typography>
    );
  }

  let ornament = ornamentProp;
  if (ornament != null) {
    ornament = (
      <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto sui-mr-2">
        {ornament}
      </div>
    );
  }

  const classes = classNames('sui-flex sui-items-center', {
    'sui-ml-2': !disableGutters
  });

  return (
    <div className={classes}>
      {ornament}
      {children}
    </div>
  );
};

DrawerTitle.displayName = 'DrawerTitle';

DrawerTitle.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, the left padding is removed.
   * @default false
   */
  disableGutters: PropTypes.bool,
  /**
   * The icon that goes before the title.
   */
  ornament: PropTypes.node
};

DrawerTitle.defaultProps = {
};

export { DrawerTitle };