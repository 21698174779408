import React from 'react';
import { func } from 'prop-types';

import { CardCallToAction } from './subcomponents/CardCallToAction/CardCallToAction';
import { PodCallToAction } from './subcomponents/PodCallToAction/PodCallToAction';
import { usePromoPresentation } from '../../hooks/usePromoPresentation';
import { CallToActionDataModel } from '../../models/CallToActionDataModel';

export const CallToAction = ({ onLoad }) => {
  const { type } = usePromoPresentation();

  switch (type) {
  case 'card':
    return <CardCallToAction onLoad={onLoad} />;
  default:
    return <PodCallToAction onLoad={onLoad} />;
  }
};

CallToAction.propTypes = {
  onLoad: func.isRequired,
};

CallToAction.dataModel = CallToActionDataModel;
