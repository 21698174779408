import React, { useState, useEffect } from 'react';
import { string, bool } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { PromotionProducts } from '@thd-olt-component-react/promotion-products';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { PromotionFallback } from './subcomponents/PromotionFallback';
import { useNewRelicLogging } from '../hooks/useNewRelicLogging';

const PromotionCard = ({
  componentId, loading, itemId
}) => {
  const [isFallbackUI, setIsFallbackUI] = useState(false);
  const failState = !loading && (isFallbackUI || !itemId);
  const { sendBogoCardDataToNewRelic } = useNewRelicLogging();
  const ImpressionData = (type) => ({
    id: componentId,
    name: 'PromotionCard',
    component: 'PromotionCard',
    type,
    position: '',
    category: '',
  });

  useEffect(() => {
    /*
    since we want this code to only run after the component renders
    we use useEffect. Also since we dont want the
    code to run in the fallback scenario, we only run it if the
    failState is false.
    */

    if (!failState && itemId) {
      sendBogoCardDataToNewRelic({
        appName: 'nucleus.my-homepage',
        actionName: 'BOGO-PHP-Success',
        customerType: 'B2B',
        segment: 'Promotions',
        experience: 'BOGO',
        subExperience: 'BOGO/BOGO Percent Off/BOGO',
        anchorItemId: itemId
      });
    }

  }, [itemId, failState]);

  if (failState) {
    return (
      <ImpressionProvider data={ImpressionData('Content')}>
        <PromotionFallback componentId={componentId} />
      </ImpressionProvider>
    );
  }
  return (
    <ImpressionProvider data={ImpressionData('Product')}>
      <PromotionProducts onFail={setIsFallbackUI} type="card" anchorItem={{ itemId }} />
    </ImpressionProvider>
  );
};

PromotionCard.propTypes = {
  componentId: string.isRequired,
  itemId: string.isRequired,
  loading: bool.isRequired,

};

PromotionCard.defaultProps = {};

PromotionCard.displayName = 'PromotionCard';

PromotionCard.dataModel = extend(
  PromotionProducts
);

export { PromotionCard };
