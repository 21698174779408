import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Locked = createSvgIcon(
  <>
    <path d="M13.125 17.25v-4.5h-2.25v4.5h2.25Z" />
    <path d="M6 6v1.864l-3 .557v13.042l9 2.486 9-2.486V8.421l-3-.557V6A6 6 0 0 0 6 6Zm6-3.75A3.75 3.75 0 0 1 15.75 6v1.446L12 6.75l-3.75.696V6A3.75 3.75 0 0 1 12 2.25Zm-3.75 7.5v-.015L12 9.038l3.75.697v.015h.081l2.919.542v9.458L12 21.615 5.25 19.75v-9.458l2.919-.542h.081Z" />
  </>,
  'Locked'
);

export { Locked };