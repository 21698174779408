/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import { useLazyDataModel } from '@thd-nucleus/data-sources';
import { getDataModel } from '../cartMutationDataModel';
import {
  getRawCartResp,
  updateErrors,
  resetPatched
} from '../useCartDataModel';
import {
  logAnalytics,
  logInvalidSessionPageReload
} from '../../util/AnalyticsHelper';
import { updateMiniCartQuantity } from '../../util/utils';
import { captureRequestEvent } from '../../automation-lib/AutomationUtil';
import { reloadPage } from '../../util/Navigation';
import { extractCartErrors } from '../../util/service-utils';

/**
 * Semaphore to prevent concurrent cart updates.
 * This is shared among all hook invocations
 */
let INFLIGHT = false;

export const useCartMutation = (mutationName, refetchedQueries = {}, dataModelOverride = null) => {
  return useLazyDataModel(mutationName, /* e.g. 'updateCart' */
    {
      errorPolicy: 'all',
      dataModel: { [mutationName]: (dataModelOverride || getDataModel(mutationName)) },
      /**
       * Update gets called twice if there is an optimistic response set.
       */
      update(cache, response) {
        const { data, errors } = response || {};
        // if (!data || !data[mutationName]) {
        //   console.error('Update response is invalid - refetch?', data, errors);
        // }
        cache.modify({
          fields: {
            cartInfo(existingCartInfo) {
              if (data[mutationName]?.optimistic) {
                delete data[mutationName].optimistic;
              } else {
                // errors are not preserved between updats/fetches,
                // so if update results in error, we need to keep track
                updateErrors(errors ? { graphQLErrors: errors } : null);
              }
              // data will need to be re-patched when served from useCartDataModel
              resetPatched();

              if (errors?.length && !data[mutationName]) {
                checkForInvalidSessionError(errors);
                data[mutationName] = setErrorsOnPreviousResp(
                  existingCartInfo,
                  errors
                );
              }

              return data[mutationName] || existingCartInfo;
            }
          }
        });
      },
      ...refetchedQueries
    }
  );
};

// TODO: can rawResp be retrieved from cache.readData(existingCartInfo)?
// existingCartInfo is sometimes a cache ref and sometimes an object, so
// using the "rawResp" stored in useCartDataModel to trigger an update so
// errors can get displayed
const setErrorsOnPreviousResp = (existingCartInfo, errors) => {
  try {
    let rawResp = getRawCartResp().cartInfo;
    rawResp.messages = convertErrorsToMessages(rawResp.messages, errors);
    return rawResp;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const convertErrorsToMessages = (messages, errors) => {
  try {
    messages = messages || [];
    // For now, put placeholder in messages to trigger
    // rerender, pull out in patchData Model.
    // Later, we can move the error logic out of patch
    // and manage here.
    if (errors?.length) {
    // errors.forEach((error, index) => {
      messages.push({
        type: null,
        messageCategoryType: null,
        correlationId: null,
        correlationType: 'triggerRender',
        longDesc: null,
        shortDesc: null
      });
    }
    // });

  } catch (err) {
    console.error(err);
  }
  return messages;
};

// Invalid auth user session is indicated in mutation response
// by this error:
//   description: "User Type is missing in request headers"
//   errorCode: "CART_ERR_3009"
const checkForInvalidSessionError = (errors) => {
  try {
    const cartErrors = extractCartErrors(errors) || [];
    const sessionError = cartErrors.find((error) => (
      error.description === 'User Type is missing in request headers'
      || error.errorCode === 'CART_ERR_3009'
    ));
    if (sessionError) {
      // log to newRelic
      logInvalidSessionPageReload();
      reloadPage();
    }
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const handleUpdateCall = ({
  mutationName,
  mutator,
  variables,
  optimisticResponse = null,
  updateType,
  onComplete
}) => {
  return new Promise((resolve, reject) => {
    if (!INFLIGHT) {
      INFLIGHT = true;

      const mutationRequest = {
        variables,
        optimisticResponse
      };
      if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
        LIFE_CYCLE_EVENT_BUS.trigger('cart.dismissAlertsAndMessages');
      }

      mutator(mutationRequest).then(afterUpdateCart.bind(this, {
        mutationName,
        variables,
        updateType,
        resolve,
        reject,
        onComplete
      })).catch((error) => {
        INFLIGHT = false;
        reject(error);
      });
    } else {
      // log to new relic
      console.warn(`Prevented ${mutationName} while cart update in progress`, variables);
    }
  });
};

/**
 * Handle operations after update complete, including:
 *  - logging/analytics/capture
 *  - trigger header update
 *  - run callers onComplete
 *
 * @param {*} props
 * @param {*} resp
 */
const afterUpdateCart = (props, resp) => {
  INFLIGHT = false;
  try {
    const {
      mutationName,
      variables,
      updateType,
      resolve,
      reject,
      onComplete
    } = props || {};

    updateMiniCartQuantity();

    logAnalytics({
      mutationName,
      variables,
      updateType,
      resp
    });

    captureRequestEvent(mutationName, variables, resp, updateType);

    if (typeof onComplete === 'function') {
      onComplete(resp);
    }

    if (resp.errors && (!resp.data || !resp.data[mutationName])) {
      reject(resp);
    }
  } catch (err) {
    console.errors(err);
    props.reject(err);
  }
  props.resolve(resp);
};