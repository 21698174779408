import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { Accordion } from '@thd-olt-component-react/accordion';
import { DeliverFromStore } from './DeliveryFromStore.component';
import { isBulletPoints } from '../../helpers/utils';

class NonParcelLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCountDownTimer: true
    };
  }

  render() {
    const {
      shipModes,
      arrivalDate,
      dynamicETA,
    } = this.props;

    const { displayCountDownTimer } = this.state;

    const hideCountdownTimer = () => {
      this.setState({
        displayCountDownTimer: false
      });
    };

    const getAccordionTitle = ({
      description,
      isFreeShipping,
      shipCharge,
      isFreeShippingEligible,
      freeShipEligibleMsg
    }) => (
      <>
        <span className="left dots delivery-type">{description}</span>
        <span className="right price">
          {isFreeShipping
            && (
              <span className="free_shipping">FREE</span>
            )}
          {!isFreeShipping
            && (
              <span>{shipCharge}</span>
            )}
        </span>
        {!isFreeShipping
          && isFreeShippingEligible
          && (
            <div className="freeWithMessage">
              (Or {freeShipEligibleMsg})
            </div>
          )}
      </>
    );

    return (
      <>
        <div className="serviceLevel_B non-parcel-template shippingTooltip bodfsAsServiceLevel">
          <div className="columnHeaders">
            <h3 className="left">Estimated Arrival</h3>
            <h3 className="right">Estimated Cost</h3>
          </div>
          <div className="datePriceRow">
            <div className="date">{arrivalDate}</div>
            {dynamicETA.hasCountdownTimer
              && displayCountDownTimer
              && (
                <div className="orderByMessage countDownTimer-wrapper">
                  <span className="delivery-options-countdown">If ordered within </span>
                  <CountdownTimer
                    classes="countdown-timer__wrapper--modifier"
                    hours={dynamicETA.totalHours} // eslint-disable-line react/prop-types
                    minutes={dynamicETA.totalMinutes} // eslint-disable-line react/prop-types
                    onRemove={hideCountdownTimer}
                  />
                </div>
              )}
            {shipModes
              && shipModes.length > 0
              && shipModes.map((shipMode, idx) => {
                if (!shipMode.hasBulletPoints && shipMode?.description && !shipMode.isBodfsShipMode) {
                  return (
                    <div className="nonBulletedPoints" key={idx}>
                      <span>
                        <span className="left dots delivery-type">{shipMode.description}</span>
                        <span className="right price">
                          {shipMode.isFreeShipping
                            && (
                              <span className="free_shipping">FREE</span>
                            )}
                          {!shipMode.isFreeShipping
                            && (
                              <span>{shipMode.shipCharge}</span>
                            )}
                        </span>
                        {!shipMode.isFreeShipping
                        && shipMode.isFreeShippingEligible
                        && (
                          <div className="freeWithMessage">
                            (Or {shipMode.freeShipEligibleMsg})
                          </div>
                        )}
                      </span>
                    </div>
                  );
                }

                const classes = classNames({
                  shipModeRow_B: true,
                  tooltip_accordion_header: shipMode.hasBulletPoints
                });
                return (
                  <Accordion
                    className="delivery-options-acc"
                    type="checkbox"
                    name="bulletedPointsAccordion"
                    key={idx}
                  >
                    { !shipMode.isBodfsShipMode ? (
                      <Accordion.Item
                        title={getAccordionTitle({
                          description: shipMode.description,
                          isFreeShipping: shipMode.isFreeShipping,
                          shipCharge: shipMode.shipCharge,
                          isFreeShippingEligible: shipMode.isFreeShippingEligible,
                          freeShipEligibleMsg: shipMode.freeShipEligibleMsg
                        })}
                        key={idx}
                        className="accordion-item"
                      >
                        <div className={classes} key={idx}>
                          <div className="dots">
                            {isBulletPoints(shipMode)
                                  && (
                                    <ul>
                                      {
                                        shipMode.bulletPoints.map((bulletPoints, bulletPointsIndex) => (
                                          <li key={bulletPointsIndex}>{bulletPoints}</li>
                                        ))
                                      }
                                    </ul>
                                  )}
                          </div>
                        </div>
                      </Accordion.Item>
                    ) : (<span />)}
                  </Accordion>
                );
              })}
          </div>
          {(shipModes || []).map((shipMode, index) => {
            if (shipMode.isBodfsShipMode) {
              return (
                <DeliverFromStore
                  isDeliveryTimelineTodayOrTomorrow={shipMode.isDeliveryTimelineTodayOrTomorrow}
                  shipCharge={shipMode.shipCharge}
                  deliveryDateAvailability={shipMode.deliveryDateAvailability}
                  key={index}
                />
              );
            }
            return null;
          })}
          <div className="select_in_checkout">
            Finalize your delivery options in checkout
          </div>
        </div>
      </>
    );
  }
}

NonParcelLayout.propTypes = {
  arrivalDate: PropTypes.string.isRequired,
  dynamicETA: PropTypes.shape({
    hasCountdownTimer: PropTypes.bool
  }).isRequired,
  shipModes: PropTypes.instanceOf(Array).isRequired,
};

export default NonParcelLayout;
