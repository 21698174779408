import React from 'react';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';

import { Button, Typography } from '@one-thd/sui-atomic-components';
import { Checkmark } from '@one-thd/sui-icons';

import { useMessage } from '../../hooks/useMessage';
import { usePromoCart } from '../../hooks/usePromoCart';
import { usePromoModel } from '../../hooks/usePromoModel';
import { MESSAGE_KEY } from '../../utils/constants';
import { noop } from '../../utils/promo-utils';

export const PromoSelectCta = ({
  isSoldOut,
  itemId,
  onSelect,
  selected,
  variant
}) => {
  const { isMultiSelect } = usePromoModel();
  const { bogoItemInCart, isCartLoading, promoCartItemsObj } = usePromoCart();
  const removeFromCartButtonText = useMessage(MESSAGE_KEY.removeFromCartButton);
  const selectButtonText = useMessage(MESSAGE_KEY.selectButton);
  const selectedItemInCartMessage = useMessage(MESSAGE_KEY.selectedItemInCartMessage);
  const selectThisInsteadButtonText = useMessage(MESSAGE_KEY.selectThisInsteadButton);
  const soldOutButtonText = useMessage(MESSAGE_KEY.soldOutButton);
  const unselectButtonText = useMessage(MESSAGE_KEY.unselectButton);

  const itemIsInCart = !!promoCartItemsObj[itemId];

  let unselectedMessaging;
  if (isSoldOut) {
    unselectedMessaging = soldOutButtonText;
  } else {
    unselectedMessaging = bogoItemInCart ? selectThisInsteadButtonText : selectButtonText;
  }

  const selectedMessaging = unselectButtonText;

  let selectCtaButtonText;
  if (selected) {
    if (itemIsInCart) {
      selectCtaButtonText = removeFromCartButtonText;
    } else {
      selectCtaButtonText = selectedMessaging;
    }
  } else {
    selectCtaButtonText = unselectedMessaging;
  }

  const showInCartMessage = !isMultiSelect && bogoItemInCart && selected;

  const inCartTextClassName = classnames(
    'sui-py-2 sui-w-full sui-flex sui-justify-center',
    'sui-items-center sui-gap-1 sui-fill-success-strong'
  );

  return (
    showInCartMessage ? (
      <p className={inCartTextClassName}>
        <Checkmark color="inherit" size="small" />
        <Typography color="success" component="span" variant="body-base" weight="bold">
          {selectedItemInCartMessage}
        </Typography>
      </p>
    ) : (
      <Button
        disabled={isCartLoading || isSoldOut}
        fullWidth
        onClick={onSelect}
        variant={variant}
      >
        {selectCtaButtonText}
      </Button>
    )
  );
};

PromoSelectCta.displayName = 'PromoSelectCta';

PromoSelectCta.propTypes = {
  isSoldOut: bool,
  itemId: string.isRequired,
  onSelect: func,
  selected: bool,
  variant: string
};

PromoSelectCta.defaultProps = {
  isSoldOut: false,
  onSelect: noop,
  selected: false,
  variant: 'primary'
};
