import * as React from 'react';
import Markdown from 'react-markdown';
import './DetailsMarkdown.scss';

export const DetailsMarkdown = ({ markdownText }) => {

  return (
    <Markdown
      className="drawer-details-markdown"
      // eslint-disable-next-line react/no-children-prop
      children={markdownText}
      renderers={{
        link: (props) => (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        ),
      }}
    />
  );
};
