// PIPCalculator
export const PIP_CALCULATOR = 'pipCalculator';

export const toggles = {
  TWO_POINT: '2_Point',
  THREE_POINT: '3_Point',
  FIXED_WIDTH: 'Fixed_Width'
};

export const lineItemNames = {
  PANEL: 'panel',
  BLOCK: 'block',
  SHEET: 'sheet',
  BAG: 'bag'
};

// MulchCalculator
export const MULCH_DESCRIPTION = 'Use this calculator to figure out how much product you need to complete your project';

export const OUTDOORS_L1_TAXONOMY = {
  breadCrumbs: [{
    label: 'Outdoors'
  }]
};

// MulchOptions
export const mulchTypes = [
  'Wood',
  'Top Soil',
  'Rubber',
];

export const wood = [
  '1.5',
  '2'
];

export const topSoil = [
  '0.75',
  '1',
  '1.5'
];

export const rubber = [
  '0.8'
];

// useCalculator
export const MIN_AREA_NUMBER = 1;
export const INSTALLED_CARPET_OVERAGE_MULTIPLIER = 1.15;
export const INSTALLED_CARPET_SEGMENT = 'installed-carpet';
export const DEFAULT_ROLL_WIDTH = 12;
export const MIN_PAD_PRICE = 0.99;

// CalculatorInputElement
export const LOWER_CASE_DESCRIPTION = 'description';
export const LOWER_CASE_SQUARE_FOOTAGE = 'square footage';
export const LOWER_CASE_LENGTH = 'length';
export const LOWER_CASE_WIDTH = 'width';
export const LOWER_CASE_DEPTH = 'depth';

// helpers
export const DEFAULT_ADDITIONAL_COVERAGE = 10;

// Calculator
export const DEFAULT_CALC_CLASSES = 'sui-bg-transparent sui-mt-3 sui-mb-5';
export const DEFAULT_DESCRIPTION = 'Please note: calculations are estimates only';
export const AREA = 'Area';
export const VOLUME = 'Volume';
export const FIXED_WIDTH = 'Fixed Width';

// Flooring Calculator
export const FLOORING_CALC_DISCLAIMER = 'Project calculator will estimate your flooring material based on unit size '
+ 'only, including rounding. Your install cost may vary depending on tools, materials, and labor.'
+ ' Schedule a measure to learn more.';