import React from 'react';

import { Skeleton, SkeletonBlock, SkeletonContent, SkeletonLine } from '@one-thd/sui-atomic-components';

export const PromoPodPlaceholder = () => {
  return (
    <Skeleton disablePadding SkeletonContentProps={{ grow: true, orientation: 'horizontal' }}>
      <SkeletonContent density="normal" disablePadding>
        <SkeletonBlock aspect="square" />
      </SkeletonContent>
      <SkeletonContent density="normal" disablePadding grow>
        <SkeletonLine numberOfLines={3} />
      </SkeletonContent>
    </Skeleton>
  );
};

PromoPodPlaceholder.displayName = 'PromoPodPlaceholder';

PromoPodPlaceholder.propTypes = {};

PromoPodPlaceholder.defaultProps = {};
