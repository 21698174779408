import { useRef, useState, useEffect } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';

export const useLists = ({ page, pageSize = 12 }) => {
  // @TODO remove page number state (solve delete scenario when user already navigated multiple pages)
  const [pageNumbers, setPageNumber] = useState([]);
  const {
    data, error, loading, refetch, client
  } = useDataModel('lists', {
    variables: {
      page: page || 1,
      pageSize
    },
    context: { withAuth: true }
  });

  useEffect(() => {
    if (!pageNumbers.includes(page)) {
      setPageNumber([...pageNumbers, page]);
    }
  }, [page]);

  const { lists } = data || {};
  const { results, pagination } = lists || {};

  const refetchLists = async ({ page: pageNo }) => {
    const loadedPages = pageNumbers.filter((pg) => pg >= pageNo && pg !== page);
    // TODO: remove id in list response so that lists queries are not cached.
    const clearPromises = loadedPages.map((pgNo) => {
      return client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'lists',
        args: { page: pgNo, pageSize }
      });
    });
    await Promise.all(clearPromises);
    await client.cache.gc();
    refetch({ page });
  };

  return {
    pagination,
    lists: results || [],
    error,
    loading,
    refetchLists
  };
};
