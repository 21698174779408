import React, { useState } from 'react';
import { string, bool, arrayOf, oneOfType } from 'prop-types';
import { Button } from '@thd-olt-component-react/button';

const KeyProductFeatureValue = (props) => {
  const [hideMore, setHideMore] = useState(true);
  const maxLimit = 3;
  const { value, simple } = props;
  const isValueString = typeof value === 'string';

  if (isValueString) {
    return <span>{value}</span>;
  }

  const shownLabel = hideMore ? value.slice(0, maxLimit) : value;

  const labels = () => {
    return simple ? shownLabel[0] : (shownLabel.map((label) => (
      <span key={label}>{label}</span>
    )));
  };

  return (
    <>
      {
        shownLabel && labels()
      }
      {
        value.length > maxLimit && !simple && (
          <>
            {
              !hideMore
                ? (
                  <Button onClick={() => setHideMore(true)} link>
                    <span className="kpf__moreless kpf__showless"> Show Less </span>
                  </Button>
                )
                : (
                  <Button onClick={() => setHideMore(false)} link>
                    <span className="kpf__moreless"> Show More </span>
                  </Button>
                )
            }
          </>
        )
      }
    </>
  );

};

KeyProductFeatureValue.propTypes = {
  value: oneOfType([string, arrayOf(string)]),
  simple: bool
};

KeyProductFeatureValue.defaultProps = {
  value: null,
  simple: false
};

export { KeyProductFeatureValue };
