import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '../chip/Chip';

/**
 * Pills are custom-content containers that present users with tailored suggestions relevant to their anticipated
 * needs/interests.
 *
 * `Pill` uses internally the `Chip` component so all of its props are also available.
 *
 * Related components: [Chip](#chip).
 *
 * Usage:
 *
 * ```jsx
 * import { Pill } from '@one-thd/sui-atomic-components';
 * ```
 */
const Pill = React.forwardRef((props, ref) => {

  const {
    ...other
  } = props;

  return (
    <Chip
      rounded
      ref={ref}
      disableDefaultClickIcon
      {...other}
    />
  );
});

Pill.displayName = 'Pill';

Pill.propTypes = {
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * Icon element.
   */
  icon: PropTypes.element,
  /**
   * The content of the pill.
   */
  label: PropTypes.node,
};

export { Pill };