import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Cancel = createSvgIcon(
  <>
    <path d="m13.591 12 3.375 3.375-1.591 1.591L12 13.591l-3.375 3.375-1.591-1.591L10.409 12 7.034 8.625l1.591-1.591L12 10.409l3.375-3.375 1.591 1.591L13.591 12Z" />
    <path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm12-9.75c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Z" />
  </>,
  'Cancel'
);

export { Cancel };
