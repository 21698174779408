import React from 'react';
import { string } from 'prop-types';
import { SignIn as SignInComponent } from '@thd-olt-component-react/sign-in';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function SignIn({ id }) {
  const customerName = typeof window === 'undefined' ? null : window?.THDCustomer?.default?.username;
  const customerEmail = typeof window === 'undefined' ? null : window?.THDCustomer?.default?.email;

  const data = {
    id,
    name: 'SignIn',
    component: 'SignIn',
    type: 'content',
    position: 1,
  };

  return (
    <ErrorBoundary name="sign-in">
      <QueryProvider cacheKey="sign-in">
        <div
          id="signIn-php"
          className="sui-h-full"
        >
          <ImpressionProvider data={data}>
            <SignInComponent
              customerName={customerName}
              customerEmail={customerEmail}
              schema="pers_signInCard"
              componentId={id}
            />
          </ImpressionProvider>
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

SignIn.propTypes = {
  id: string,
};

SignIn.defaultProps = {
  id: undefined,
};