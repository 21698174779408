import React, { useContext } from 'react';
import {
  arrayOf, bool, shape, string, number
} from 'prop-types';
import { Skeleton, SkeletonContent, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { CarouselHeader } from '../../core/CarouselHeader';
import { CategoryTile } from './CategoryTile';
import { ArticleGuidesTile } from '../article-guides/ArticleGuidesTile/ArticleGuidesTile';

const CategoryCarousel = (props) => {
  const {
    breakpoints,
    categories,
    componentName,
    entryId,
    hideTextPlaceholder,
    hideTitle,
    loading,
    pageType,
    placeholderSize,
    showLoading,
    title,
    slidesPerView,
    slidesPerGroup,
    hideControls
  } = props;
  const { channel } = useContext(ExperienceContext);
  const NUM_OF_PLACEHOLDERS = channel === 'mobile' ? 2 : 6;
  const isLoading = showLoading && loading;
  /**
   * Note: The isArticleGuide variable is responsible for
   * conditional logic that will break if the value doesn't
   * exactly match the componentName prop value from ArticleGuides.js
   */
  const isArticleGuide = componentName === 'article-guide';

  const isMobile = channel === 'mobile';

  return (
    <>
      {!hideTitle && <CarouselHeader title={title} />}
      <div>
        {isLoading && (
          <div className="sui-flex sui-overflow-hidden sui-gap-4">
            {[...Array(NUM_OF_PLACEHOLDERS)].map((__, index) => (
              <div className="sui-flex sui-overflow-hidden">
                <Skeleton
                  disablePadding
                  disableGutters
                  key={index}
                >
                  <SkeletonContent>
                    <SkeletonBlock aspect="square" height={placeholderSize} width={placeholderSize} />
                    {!hideTextPlaceholder && <SkeletonLine variant="single" fullWidth />}
                  </SkeletonContent>
                </Skeleton>
              </div>
            ))}
          </div>
        )}
        <Carousel
          slidesPerView={slidesPerView}
          slidesPerGroup={slidesPerGroup}
          breakpoints={breakpoints}
          hideControls={hideControls}
          disableMargin
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
        >
          {categories.map((tile, index) => {
            if (isArticleGuide) {
              return <ArticleGuidesTile article={tile} pageType={pageType} key={tile?.guid} />;
            }

            return (
              <CategoryTile
                category={tile}
                componentName={componentName}
                entryId={entryId}
                key={tile?.id}
                podPosition={index + 1}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

CategoryCarousel.displayName = 'CategoryCarousel';

CategoryCarousel.propTypes = {
  breakpoints: shape({
    sm: shape(),
    md: shape(),
    lg: shape(),
    xl: shape()
  }),
  categories: arrayOf(shape()),
  componentName: string,
  entryId: string,
  hideTextPlaceholder: bool,
  hideTitle: bool,
  loading: bool,
  pageType: string,
  placeholderSize: number,
  showLoading: bool,
  title: string,
  slidesPerView: number,
  slidesPerGroup: number,
  hideControls: bool
};

CategoryCarousel.defaultProps = {
  breakpoints: undefined,
  categories: [],
  componentName: '',
  entryId: '',
  hideTextPlaceholder: false,
  hideTitle: false,
  loading: false,
  pageType: null,
  placeholderSize: 48,
  showLoading: false,
  title: '',
  slidesPerView: 3,
  slidesPerGroup: 3,
  hideControls: false
};

export { CategoryCarousel };
