import { phpAsset } from './constants';

export const phpTitle = 'Leave Your Project to Us';
export const phpBulletsPoints = ['Expert Installers at Your Fingertips', 'Support Every Step of the Way'];
export const genericBulletPoint = ['Trusted pros at your Fingertips', 'Support every step of the way'];
export const kitchenBulletPoint = ['No project too big or too small', 'Support every step of the way'];

export const iconUrls = {
  checkMarkIcon: 'https://assets.homedepot-static.com/images/v1/check.svg',
  brandLogo: 'https://assets.homedepot-static.com/images/v1/home-services-icon.svg',
};
export const nationalServiceURL = 'https://www.homedepot.com/services/';
export const dataStore = {
  KITCHEN_INST_SCR_SVCS: {
    title: 'Kitchen Design Services',
    subText: `Working with a kitchen designer is completely free,
       from your initial consultation to the final reveal of full-color 3D renderings`,
    service_img:
     `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.KITCHEN_DESIGN}`,
    button_cta: 'https://www.homedepot.com/services/c/kitchen-design/01575162c',
    bulletsPoints: kitchenBulletPoint,
  },
  BATH_INSTALL_SCR_SVCS: {
    title: 'Bathroom Remodeling',
    subText:
      'Get a free in-home consultation and let our experts bring your dream bathroom to life',
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.BATHROOM}`,
    button_cta: 'https://www.homedepot.com/services/c/bathroom-remodel/d9843b7cb',
    bulletsPoints: phpBulletsPoints,
  },
  CARPET_INSTALL_SCR_SVCS: {
    title: 'Carpet Installation',
    subText: `Get free carpet installation on qualifying purchase and enjoy a 
      lifetime labor warranty, including seams, restretches and stairwork`,
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.CARPET}`,
    button_cta: 'https://www.homedepot.com/services/c/carpet-installation/b76e9e302',
    bulletsPoints: phpBulletsPoints,
  },
  HARDWOOD_INST_SCR_SVCS: {
    title: 'Hardwood Flooring Installation',
    subText: `Whether solid wood or engineered hardwood, 
      get the flooring look you've always wanted with professional installation`,
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.HARDWOOD_FLOORING}`,
    button_cta: 'https://www.homedepot.com/services/c/hardwood-flooring-installation/0bea4470c',
    bulletsPoints: phpBulletsPoints,
  },
  M_I_WINDOW_TREATMENT_SCR_SVCS: {
    title: 'Blinds Installation',
    subText: 'Professional installation on your new blinds or shades includes a 1-year service guarantee',
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.BLIND}`,
    button_cta: 'https://www.homedepot.com/services/c/blinds-installation/c36a8785f',
    bulletsPoints: phpBulletsPoints,
  },
  WIN_INST_SCR_SVCS: {
    title: 'Window installation',
    subText: `Need new windows? Get a free consultation, 
      including professional measurement and an on-the-spot quote`,
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.CARPET}`,
    button_cta: 'https://www.homedepot.com/services/c/window-installation/e57e696e1',
    bulletsPoints: phpBulletsPoints,
  },
  SF_I_WINDOW_TREATMENT_SCR_SVCS: {
    title: 'Custom Window Treatments',
    subText: `Get a free in-home consultation,
            including meeting with a designer,
            professional measurement, and
            design samples brought right to you`,
    service_img: `
       https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.CUSTOM_WINDOW}`,
    button_cta: 'https://www.homedepot.com/services/c/kirsch-custom-window-treatments/51e8e1b61',
    bulletsPoints: phpBulletsPoints,
  },
  HVAC_2019_SCR_SVCS: {
    title: 'HVAC Installation',
    subText: `Get a free in-home consultation, and we'll 
              help determine the right  HVAC system for your home and budget`,
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.HVAC}`,
    button_cta: 'https://www.homedepot.com/services/c/hvac-installation/c16fbb4b7',
    bulletsPoints: phpBulletsPoints,
  },
  HVAC_RPR_AND_MNTNC_SCR_SVCS: {
    title: 'Heating & Cooling Services',
    subText: `We offer professional HVAC installation,repair and maintenance services to keep
               your home comfortable all year`,
    service_img: `
     https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.HEATING_COOLING}`,
    button_cta: 'https://www.homedepot.com/services/h/hvac-services ',
    bulletsPoints: genericBulletPoint,
  },
  WATER_HEATERS_SCR_SVCS: {
    title: 'Water Heater Installation',
    subText: `Get a free in-home consultation and project quote for professional water
              heater installation`,
    service_img: `
    https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.WATER_HEATER}`,
    button_cta: 'https://www.homedepot.com/services/c/water-heater-installation/9058c024e',
    bulletsPoints: phpBulletsPoints
  },
  CABINET_MAKEOVER_SCR_SVCS: {
    title: 'Cabinet Makeover',
    subText: `Have your cabinets professionally refaced in as little as 3-5 days—just a fraction
              of the time of a full remodel
           `,
    service_img: `
      https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.CABINET_MAKEOVER}`,
    button_cta: 'https://www.homedepot.com/services/c/cabinet-refacing/bf24dfc4c',
    bulletsPoints: phpBulletsPoints,
  },
  STORAGE_SCR_SVCS: {
    title: 'Custom Built Home Organization',
    subText: `Get a free consultation on professionally installed storage solutions for your closet
              , garage, pantry, home office, laundry room and more`,
    service_img: `
        https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.CUSTOM_BUILT}`,
    button_cta: 'https://www.homedepot.com/services/c/home-organization/22ef3e97b',
    bulletsPoints: phpBulletsPoints
  },
  SPCL_ORD_CNTRTPS_SCR_SVCS: {
    title: 'Countertop Installation',
    subText: `Get a free consultation, and we'll help you choose the best
             countertop for your style and budget`,
    service_img: `
     https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.COUNTER_TOP}`,
    button_cta: 'https://www.homedepot.com/services/c/countertop-installation/6228e49a9',
    bulletsPoints: phpBulletsPoints,
  },
  FENCING_SCR_SVCS: {
    title: 'Fence Installation',
    subText: `Need a new fence? Get a free consultation, and our local 
      installers can help design and build the right fence for your yard`,
    service_img:
     `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.FENCE}`,
    button_cta: 'https://www.homedepot.com/services/c/fence-installation/8fa995a0c',
    bulletsPoints: phpBulletsPoints,
  },
  GDOOR_INSTALL_SCR_SVCS: {
    title: 'Garage Door Installation',
    subText:
      'Create and estimate your new garage door and opener using our free online design tool',
    service_img:
    `https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.Garage_DOOR}`,
    button_cta: 'https://www.homedepot.com/services/c/garage-door-opener-installation/685fddfb6',
    bulletsPoints: phpBulletsPoints,
  },
  GENERIC: {
    title: 'Professional Installation & Services',
    subText: `Get the job done right—The Home Depot adds peace of mind
    to all your projects`,
    service_img: `
      https://assets.thdstatic.com/images/v1/home-services/personalized-home-services/${phpAsset.DEFAULT}`,
    button_cta: 'https://www.homedepot.com/services/',
    bulletsPoints: genericBulletPoint,
  },
};