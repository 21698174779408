import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Basic } from './Basic';
import { BasicPerItem } from './BasicPerItem';

export const BasicPerItemPrice = ({ totalPrice, unitPrice, align }) => {
  const className = classNames({ 'sui-text-right': align === 'end' });
  return (
    <div className={className} data-automation-id="pricingScenariosText">
      <Basic price={totalPrice} />
      <BasicPerItem price={unitPrice} />
    </div>
  );
};

BasicPerItemPrice.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  unitPrice: PropTypes.number.isRequired,
  align: PropTypes.string
};

BasicPerItemPrice.defaultProps = {
  align: ''
};

BasicPerItemPrice.displayName = 'BasicPerItemPrice';
