import React from 'react';
import classNames from 'classnames/bind';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { ProductPodPlaceholder } from './ProductPodPlaceholder';
import styles from './product-row-placeholder.module.scss';

const cNames = classNames.bind(styles);

export const ProductRowPlaceholder = () => (
  <Row className={cNames('product-row-placeholder')} data-component="ProductRowPlaceholder">
    <Col xs="3">
      <ProductPodPlaceholder />
    </Col>
    <Col xs="3">
      <ProductPodPlaceholder />
    </Col>
    <Col xs="3">
      <ProductPodPlaceholder />
    </Col>
    <Col xs="3">
      <ProductPodPlaceholder />
    </Col>
  </Row>
);
