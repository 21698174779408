import { extend } from '@thd-nucleus/data-sources';

import { PromoList1DrawerDataModel } from './PromoList1DrawerDataModel';
import { PromoList2DrawerDataModel } from './PromoList2DrawerDataModel';

export const PromoDrawerDataModel = extend(
  PromoList1DrawerDataModel,
  PromoList2DrawerDataModel,
  // Not extended because it is caught by the internal QueryProvider that wraps it
  // CatalogHoistingWrapper,
);
