import { useContext } from 'react';
import { useMembershipInformation } from '@thd-olt-functional/customer-information';

import ExperienceContext from '../components/ExperienceContext';

export const useStore = ({ varnish = true, online = true } = {}) => {
  const { store, clientStore, customer } = useContext(ExperienceContext);
  const cachedStoreId = online ? '8119' : '121';

  // eslint-disable-next-line no-nested-ternary
  let defaultStore = varnish
    ? { storeId: cachedStoreId, isLocalized: false }
    : store ? { ...store, isLocalized: false } : { storeId: cachedStoreId, isLocalized: false };

  const {
    apiResponse,
    customerType,
    isExchangeCustomer
  } = useMembershipInformation({ customerType: customer?.type?.toUpperCase() });

  if ((customerType === 'B2B' || isExchangeCustomer) && clientStore) {
    if (apiResponse?.data || apiResponse?.error) {
      defaultStore = {
        ...defaultStore,
        ...clientStore,
        isLocalized: true,
        membershipInformation: { ...apiResponse },
      };
    }
  } else if (clientStore) {
    defaultStore = {
      ...defaultStore,
      ...clientStore,
      isLocalized: true
    };
  }

  return defaultStore;
};
