import React from 'react';
import classNames from 'classnames';
import { instanceOf, string, bool } from 'prop-types';
import { Col, Row, Heading } from '@thd-olt-component-react/core-ui';
import { WriteAReview } from '../WriteAReview/WriteAReview.component';
import { usePresentation } from '../../context/PresentationProvider';
import './FirstReview.style.scss';

export const FirstReview = ({
  apiKey,
  className,
  itemId,
  reviewStatistics,
  disableOverlayOnClick,
  showHeading
}) => {
  const CONTACT_US_LINK = 'https://www.homedepot.com/c/customer_service';
  const { useCondensedLayout } = usePresentation();
  const textClasses = classNames({
    'first-review__text--tight': useCondensedLayout
  });
  const headerClasses = classNames({
    'first-review__mobile-text': useCondensedLayout
  });
  return (
    <div className="first-review" data-component="FirstReview">
      <Row>
        { showHeading
          && (
            <Col flatten>
              <Heading title="Customer Reviews" className={headerClasses} underline />
            </Col>
          )}
        <Col xs="12" className={textClasses}>
          Do you own this product? Be the first to rate it.
          Your feedback will help users like you to make informed decisions
          and will help us to improve our product offerings!
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          {reviewStatistics
            && (
              <WriteAReview
                apiKey={apiKey}
                className={className}
                itemId={itemId}
                reviewStatistics={reviewStatistics}
                disableOverlayOnClick={disableOverlayOnClick}
              />
            )}
        </Col>
      </Row>
      <Row>
        <Col xs="12" className={textClasses}>
          <span>
            If you have questions or comments
            regarding the customer service you received
            please <a className="first-review__contact-us" href={CONTACT_US_LINK}>contact us.</a>
          </span>
        </Col>
      </Row>
    </div>
  );
};

FirstReview.displayName = 'FirstReview';

FirstReview.propTypes = {
  apiKey: string.isRequired,
  reviewStatistics: instanceOf(Object),
  itemId: string.isRequired,
  className: string,
  disableOverlayOnClick: bool,
  showHeading: bool
};

FirstReview.defaultProps = {
  reviewStatistics: null,
  className: null,
  disableOverlayOnClick: false,
  showHeading: true
};
