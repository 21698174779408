import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.style.scss';

const ButtonGroup = (props) => {
  const {
    className,
    children
  } = props;

  return (
    <div className={classNames('bttn--group', className)}>
      {children}
    </div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /** Extend Styles. */
  className: PropTypes.string
};

ButtonGroup.defaultProps = {
  className: null
};

export default ButtonGroup;
