import React, { useState } from 'react';
import useEnhancedEffect from './useEnhancedEffect';
import debounce from '../utils/debounce';

const useWindowSize = function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEnhancedEffect(() => {
    const updateSize = debounce(() => {
      setSize([window.innerWidth, window.innerHeight]);
    }, 200);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      updateSize.clear();
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  return size;
};

export default useWindowSize;