import React, { useEffect, useState, useContext } from 'react';
import { node, string, element, bool } from 'prop-types';
import { AppContext } from '../Providers/AppContext';

const DynamicComponent = ({
  children, pageType = '', bypassFeatureSwitch = false, disabled = false, placeholder = null
}) => {
  const { instance } = useContext(AppContext);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (disabled) {
    return children;
  }

  // dynamic rendering is turned off
  if (!instance?.opts?.dynamicRendering) {
    return children;
  }

  if (!instance.opts?.dynamicRendering?.[pageType]) {
    return children;
  }

  if (!instance.featureSwitches.dynamicBotRendering && !bypassFeatureSwitch) {
    return children;
  }

  if (instance.thdSEOBotDetection && !mounted) {
    return children;
  }

  if (mounted) {
    return children;
  }

  return placeholder || null;
};

DynamicComponent.displayName = 'DynamicComponent';
DynamicComponent.propTypes = {
  children: node.isRequired,
  pageType: string,
  bypassFeatureSwitch: bool,
  disabled: bool,
  placeholder: element
};

export { DynamicComponent };