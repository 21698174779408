import React from 'react';
import { number, shape, string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { StoreWorkshops } from '@thd-olt-component-react/store-workshops';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function OnlineWorkshops({ id, renderWidths: { minWidthLarge } }) {
  const isCard = minWidthLarge < 5;

  return (
    <ErrorBoundary name="online-workshops">
      <QueryProvider cacheKey="asd">
        <div
          id={`online-workshops-php-${id}`}
          className="sui-h-full"
        >
          <StoreWorkshops entryID={id} isCard={isCard} />
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

OnlineWorkshops.propTypes = {
  id: string,
  renderWidths: shape({
    minWidthLarge: number,
  }),
};

OnlineWorkshops.defaultProps = {
  id: undefined,
  renderWidths: {
    minWidthLarge: undefined,
  },
};
