import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Dishwasher = createSvgIcon(
  <>
    <path d="M22.5 0H0v24h14.25v-2.25h-12V2.25h18v6h2.25V0Z" />
    <path d="M24 10.5h-8.25v4.125a4.127 4.127 0 0 0 3 3.97v3.155H16.5V24h6.75v-2.25H21v-3.155c1.731-.49 3-2.082 3-3.97V10.5Zm-4.106 6h-.038A1.875 1.875 0 0 1 18 14.625V12.75h3.75v1.875c0 1.03-.83 1.865-1.856 1.875ZM16.5 5.25H6V7.5h10.5V5.25Z" />
  </>,
  'Dishwasher'
);

export { Dishwasher };