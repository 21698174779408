import React, { useContext } from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { WelcomeCardContext } from '../../../contexts';

export const UserProfileSummary = () => {
  const { userData, isAdmin } = useContext(WelcomeCardContext);
  const companyInfo = userData?.customerInfo?.companyName;
  const userName = `${userData?.userProfile?.name?.firstName} ${userData?.userProfile?.name?.lastName}`;
  return (
    <div className="sui-flex sui-flex-col sui-justify-center sui-pb-2">
      <Typography truncate variant="body-base" weight="bold" height="tight">
        Welcome, {userName}
      </Typography>
      <Typography truncate variant="body-base" weight="regular" height="snug">
        {isAdmin ? '' : 'Purchaser, ' }{companyInfo}
      </Typography>
    </div>
  );
};
