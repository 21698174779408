import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PlayMedia = createSvgIcon(
  <>
    <path d="M9.75 12 15 15.375 9.75 18.75V12Z" />
    <path d="M.75 0h22.5v24H.75V0Zm13.5 2.25h-4.5v4.5h4.5v-4.5Zm2.25 4.5H21v-4.5h-4.5v4.5ZM3 9v12.75h18V9H3Zm0-6.75v4.5h4.5v-4.5H3Z"/>
  </>,
  'PlayMedia'
);

export { PlayMedia };