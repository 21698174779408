/* eslint-disable max-len */
// imported by src/tests/LoyaltyDrawers/loyalty-barcode.spec.js (updated 12-Jul-2024)
// imported by src/common/contexts/PacDrawerContext.js (updated 12-Jul-2024)
// perksRedeem ---> redeemPerksData (updated 17-Jul-2024)
// getRedeemResponse() added to mockRedeemResponse object (updated 12-Jul-2024)
export const testRewards = {
  omni: { // changed activation to from 'PAYMENTS' to 'PRICING_ENGINE'
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PRICING_ENGINE',
      adminIssued: false,
      amount: 25,
      availability: 'OMNI_CHANNEL',
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 112,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: {
        decodedBarcode: '98081080108596649226178',
        decodedGiftCardPin: '8493',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          barCode: 'OTgwODEwODAxMDg1OTY2NDkyMjYxNzg=',
          giftCardBalanceAmount: 25,
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          pin: 'ODQ5Mw=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  instore: { // <--- identical to omni, except for 'availability'
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PRICING_ENGINE',
      adminIssued: false,
      amount: 25,
      availability: 'IN_STORE', // ***
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 112,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: {
        decodedBarcode: '98081080108596649226178',
        decodedGiftCardPin: '8493',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          barCode: 'OTgwODEwODAxMDg1OTY2NDkyMjYxNzg=',
          giftCardBalanceAmount: 25,
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          pin: 'ODQ5Mw=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  online: { // <--- identical to omni, except for 'availability'
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PRICING_ENGINE',
      adminIssued: false,
      amount: 25,
      availability: 'ONLINE', // ***
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 112,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: {
        decodedBarcode: '98081080108596649226178',
        decodedGiftCardPin: '8493',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          barCode: 'OTgwODEwODAxMDg1OTY2NDkyMjYxNzg=',
          giftCardBalanceAmount: 25,
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          pin: 'ODQ5Mw=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  error: { // <--- identical to omni, except for 'hasPerksRedeemError'
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PRICING_ENGINE',
      adminIssued: false,
      amount: 25,
      availability: 'OMNI_CHANNEL',
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 112,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: {
        decodedBarcode: '98081080108596649226178',
        decodedGiftCardPin: '8493',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        redeemPerksError: true, // ***
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          barCode: 'OTgwODEwODAxMDg1OTY2NDkyMjYxNzg=',
          giftCardBalanceAmount: 25,
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          pin: 'ODQ5Mw=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  loading: { // <--- identical to omni, except for 'isLoadingRedeemPerks'
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PRICING_ENGINE',
      adminIssued: false,
      amount: 25,
      availability: 'OMNI_CHANNEL',
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 112,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: {
        decodedBarcode: '98081080108596649226178',
        decodedGiftCardPin: '8493',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: true, // ***
        redeemPerksData: {
          barCode: 'OTgwODEwODAxMDg1OTY2NDkyMjYxNzg=',
          giftCardBalanceAmount: 25,
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          pin: 'ODQ5Mw=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  freeSnackOffer: { // CMS, instore, not giftcard
    perk: {
      activationTime: '06/14/2024',
      activationType: 'CMS',
      adminIssued: true,
      amount: 3,
      availability: 'IN_STORE',
      availableBalance: 0,
      bonusPerk: true,
      bulkIssued: false,
      creationTime: '06/14/2024',
      daysLeft: 198,
      exchanged: false,
      expirationTime: '01/01/2025',
      giftCard: false,
      offerType: 'OFFER', // <--- manually changed, FOLLOW-UP: verify with team
      options: [],
      paymentId: 'P12542502C21A43680',
      perkDescription: 'Redeem this Bonus Perk with your next in-store purchase. Important Details: This coupon applies to single-serving snacks of $3.50 value or less at U.S. The Home Depot stores. Valid with any purchase. Single-use only. In-store redemption only, offer not available for on-line purchases. Excludes METRX BIG 100 Super Cookie Crunch, Darrell Lea Licorice, 8oz Lay\'s Classic, 7.75oz Lay\'s BBQ, 7.75oz Lay\'s Limon Chips, 8oz Lay\'s Wavy, Klon Mrs Fields Cookie Sandwich, Magnum Double Choc Van IC Bar, Magnum Double Caramel IC Bar, Magnum Almond IC Bar, and BJ SLIC Choc Chip Cookie Dough. Coupon cannot be combined with other offers. Limit one per customer. Maximum discount $3.50. Cash value 1/20th of 1 cent. Void if sold or copied and where prohibited. Home Depot Product Authority, LLC. All rights reserved. Cashier: After scanning all products into the cart, scan the barcode. If the barcode does not scan, enter the 26 digit promotion code.',
      perkDisplaySeqNumber: 12,
      perkId: '2aebb014-d321-4428-8ccd-51017a3464d3',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-stage.gcp.homedepot.com/RewardImages/active_Free%20Snack_85ec7eb5-9251-4c92-a75c-2af9eac4113c',
      perkSourceUUID: 'f037977e-3787-4b0c-84dc-29e58eb2814e',
      perkStatus: 'ACTIVE',
      perkTitle: 'Free Snack',
      perkType: 'THD_COUPONS_CMS',
      programId: 1, // <--- added for offer drawer rtl
      renewed: false,
      rewardId: '87bdd711-4f79-4ada-b5cd-9b6947c8cfc0',
      shortDescription: 'Up to $3.50 Value',
      shortName: 'Enjoy a Free Snack!',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2024,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98154000053999805264575605',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: 'f037977e-3787-4b0c-84dc-29e58eb2814e',
          barCode: 'OTgxNTQwMDAwNTM5OTk4MDUyNjQ1NzU2MDU='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  freeDrinkOffer: { // CMS, instore, not giftcard
    perk: {
      activationTime: '06/14/2024',
      activationType: 'CMS',
      adminIssued: true,
      amount: 3,
      availability: 'IN_STORE',
      availableBalance: 0,
      bonusPerk: true,
      bulkIssued: false,
      creationTime: '06/14/2024',
      daysLeft: 198,
      exchanged: false,
      expirationTime: '01/01/2025',
      giftCard: false,
      offerType: 'OFFER', // <--- manually changed, FOLLOW-UP: verify with team
      options: [],
      paymentId: 'P12542502477F43680',
      perkDescription: 'Redeem this Bonus Perk with your next in-store purchase. Important Details: This coupon applies to single-serving beverages of $3.50 value or less with any purchase at U.S. The Home Depot stores. Valid with any purchase. Single-use only. In-store redemption only, offer not available for on-line purchases. Excludes beverage multi-packs, Monster 24 oz, 5-Hour Energy and Red Bull 20 oz and 16 oz cans. Coupon cannot be combined with other offers. Limit one per customer. Maximum discount $3.50. Cash value 1/20th of 1 cent. Void if sold or copied and where prohibited. Home Depot Product Authority, LLC. All rights reserved. Cashier: After scanning all products into the cart, scan the barcode. If the barcode does not scan, enter the 26 digit promotion code.',
      perkDisplaySeqNumber: 13,
      perkId: '30311bc4-3900-4968-9449-0dc87d974c59',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-stage.gcp.homedepot.com/RewardImages/active_Free%20Drink_21c4b093-cae2-436e-8424-3dcb8c6da7f4',
      perkSourceUUID: '966b570c-58f8-4f02-9541-2e7cc6bb2827',
      perkStatus: 'ACTIVE',
      perkTitle: 'Free Drink', // <--- manually added space, FOLLOW-UP: verify with team
      perkType: 'THD_COUPONS_CMS',
      renewed: false,
      rewardId: '1fea0ad6-383b-49b9-bc30-5c959797b4bc',
      tierPerks: [{}], // <--- not included in mock data
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2024,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98154000683999705491808209',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '966b570c-58f8-4f02-9541-2e7cc6bb2827',
          barCode: 'OTgxNTQwMDA2ODM5OTk3MDU0OTE4MDgyMDk='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  toolRentalPerk: { // Pricing Engine, instore, not giftcard
    perk: {
      activationTime: '06/17/2024',
      activationType: 'PRICING_ENGINE',
      adminIssued: false,
      amount: 75,
      availability: 'IN_STORE',
      availableBalance: 0,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '06/14/2024',
      daysLeft: 379,
      earned: true,
      earnedAtSpend: 10000,
      exchanged: false,
      expirationTime: '07/01/2025',
      giftCard: false,
      offerType: 'PERK',
      options: [],
      paymentId: 'P1354343791E3624A0',
      perkDescription: 'Important details about your Tool Rental Perk: Redeemable for rental charges for eligible tools and equipment at The Home Depot Rental Centers in the U.S. Expires 12 months after selection. To redeem, present valid Pro Xtra ID number and Perk barcode from The Home Depot mobile app. One Perk per rental contract. A Perk may be used once, not redeemable for cash or credit. See Pro Xtra Terms & Conditions for exclusions and additional information.',
      perkDisplaySeqNumber: 3,
      perkId: 'ca4fc2d5-b9d0-433b-9429-3b9d228a9d8c',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-dev.gcp.homedepot.com/RewardImages/active_Tool%20Rental%20Perk_59667be8-e367-4a47-b5f1-fdd237b18180',
      perkSourceUUID: 'f5480c0e-ac4f-46b6-85d1-c39e6dd44e04',
      perkStatus: 'ACTIVE',
      perkTitle: 'Tool Rental Perk',
      perkType: 'TOOL_RENTAL',
      renewed: false,
      rewardId: '60bc0647-1e59-498f-9c24-24a53a327c5c',
      shortName: 'Tool Rental Perk',
      tierRewardId: '7f9024d7-e003-4444-a3f4-24eca41c9348',
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2024,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: 'TRC00R1D0SG4',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: 'f5480c0e-ac4f-46b6-85d1-c39e6dd44e04',
          barCode: 'VFJDMDBSMUQwU0c0'
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  pxdOffer: { // Payments, omni, giftcard
    perk: {
      activationTime: '02/15/2024',
      activationType: 'PAYMENTS',
      adminIssued: true,
      amount: 1,
      availability: 'OMNI_CHANNEL',
      availableBalance: 1,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '02/15/2024',
      daysLeft: 256,
      exchanged: false,
      expirationTime: '03/01/2025',
      giftCard: true,
      offerType: 'OFFER',
      options: [],
      paymentId: 'P1351BB79BCDF4DD20',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 1,
      perkId: 'e0016987-3deb-40fe-a298-7900f705a6c5',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: 'b9588185-dc78-4ab3-8457-142c2b86e180',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2024,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98081080132821737984318',
        decodedGiftCardPin: '8666',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: { // barcode testing update 17-Jul-2024
          perkSourceUUID: 'b9588185-dc78-4ab3-8457-142c2b86e180',
          giftCardBalanceAmount: 1,
          barCode: 'OTgwODEwODAxMzI4MjE3Mzc5ODQzMTg=',
          pin: 'ODY2Ng=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  pxdPerk: { // Payments, omni, giftcard
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PAYMENTS',
      adminIssued: false,
      amount: 25,
      availability: 'OMNI_CHANNEL',
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 105,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}],
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98081080108596640977530',
        decodedGiftCardPin: '6654',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          giftCardBalanceAmount: 25,
          barCode: 'OTgwODEwODAxMDg1OTY2NDA5Nzc1MzA=',
          pin: 'NjY1NA=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  inStoreCoupon: { // CMS, instore, not giftcard
    perk: {
      activationTime: '08/31/2023',
      activationType: 'CMS',
      adminIssued: true,
      amount: 1,
      availability: 'IN_STORE',
      availableBalance: 0,
      bonusPerk: true,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 76,
      earned: true,
      exchanged: false,
      expirationTime: '09/01/2024',
      giftCard: false,
      maxThreshold: 0,
      minThreshold: 0,
      offerType: 'PERK',
      options: [],
      paymentId: 'P134E592C4EE8D5A20',
      perkDescription: 'NEW: Save 10% on your next purchase – up to $100 off. Important details about your 10% Off Coupon: Expires 12 months after selection. Offer valid and redeemable for a discount of 10% off your purchase ($100 Max off). Redeemable in store only. One-time use only. Exclusions apply - Offer cannot be combined with any other discount, offer, promotion or special incentive program and not valid on services, gift cards, Penske truck rentals, prior purchases, select smart home products (including, but not limited to, Amazon Echo, Ring, Google Nest products), select power tool sets, or purchases from any The Home Depot affiliate store. See Pro Xtra Terms & Conditions for exclusions and additional information.',
      perkDisplaySeqNumber: 1,
      perkId: '350e32b2-556e-49a4-9b2d-ccfbdd00707c',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_10%25%20Off%20Coupon_ae42fac6-8937-41b3-a8a7-89c06e57cbe5',
      perkSourceUUID: '7c0a9fef-7d59-4b00-8abf-9a77d94d2eac',
      perkStatus: 'ACTIVE',
      perkTitle: '10% Off Coupon',
      perkType: 'THD_COUPONS_CMS',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 0,
      renewed: false,
      rewardId: '9386a6ae-f437-49cc-9ea4-bb11aff10494',
      tierPerks: [/* not included in mock data */],
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2023,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98154000004791977833956768',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '7c0a9fef-7d59-4b00-8abf-9a77d94d2eac',
          barCode: 'OTgxNTQwMDAwMDQ3OTE5Nzc4MzM5NTY3Njg='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  onlineCoupon: { // CMS, online, not giftcard (copy of inStoreCoupon except for 'availability')
    perk: {
      activationTime: '08/31/2023',
      activationType: 'CMS',
      adminIssued: true,
      amount: 1,
      availability: 'ONLINE',
      availableBalance: 0,
      bonusPerk: true,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 76,
      earned: true,
      exchanged: false,
      expirationTime: '09/01/2024',
      giftCard: false,
      maxThreshold: 0,
      minThreshold: 0,
      offerType: 'PERK',
      options: [],
      paymentId: 'P134E592C4EE8D5A20',
      perkDescription: 'NEW: Save 10% on your next purchase – up to $100 off. Important details about your 10% Off Coupon: Expires 12 months after selection. Offer valid and redeemable for a discount of 10% off your purchase ($100 Max off). Redeemable in store only. One-time use only. Exclusions apply - Offer cannot be combined with any other discount, offer, promotion or special incentive program and not valid on services, gift cards, Penske truck rentals, prior purchases, select smart home products (including, but not limited to, Amazon Echo, Ring, Google Nest products), select power tool sets, or purchases from any The Home Depot affiliate store. See Pro Xtra Terms & Conditions for exclusions and additional information.',
      perkDisplaySeqNumber: 1,
      perkId: '350e32b2-556e-49a4-9b2d-ccfbdd00707c',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_10%25%20Off%20Coupon_ae42fac6-8937-41b3-a8a7-89c06e57cbe5',
      perkSourceUUID: '7c0a9fef-7d59-4b00-8abf-9a77d94d2eac',
      perkStatus: 'ACTIVE',
      perkTitle: '10% Off Coupon',
      perkType: 'THD_COUPONS_CMS',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 0,
      renewed: false,
      rewardId: '9386a6ae-f437-49cc-9ea4-bb11aff10494',
      tierPerks: [/* not included in mock data */],
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2023,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98154000004791977833956768',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '7c0a9fef-7d59-4b00-8abf-9a77d94d2eac',
          barCode: 'OTgxNTQwMDAwMDQ3OTE5Nzc4MzM5NTY3Njg='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  omniCoupon: { // CMS, omni, not giftcard (copy of inStoreCoupon except for 'availability')
    perk: {
      activationTime: '08/31/2023',
      activationType: 'CMS',
      adminIssued: true,
      amount: 1,
      availability: 'OMNI_CHANNEL',
      availableBalance: 0,
      bonusPerk: true,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 76,
      earned: true,
      exchanged: false,
      expirationTime: '09/01/2024',
      giftCard: false,
      maxThreshold: 0,
      minThreshold: 0,
      offerType: 'PERK',
      options: [],
      paymentId: 'P134E592C4EE8D5A20',
      perkDescription: 'NEW: Save 10% on your next purchase – up to $100 off. Important details about your 10% Off Coupon: Expires 12 months after selection. Offer valid and redeemable for a discount of 10% off your purchase ($100 Max off). Redeemable in store only. One-time use only. Exclusions apply - Offer cannot be combined with any other discount, offer, promotion or special incentive program and not valid on services, gift cards, Penske truck rentals, prior purchases, select smart home products (including, but not limited to, Amazon Echo, Ring, Google Nest products), select power tool sets, or purchases from any The Home Depot affiliate store. See Pro Xtra Terms & Conditions for exclusions and additional information.',
      perkDisplaySeqNumber: 1,
      perkId: '350e32b2-556e-49a4-9b2d-ccfbdd00707c',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_10%25%20Off%20Coupon_ae42fac6-8937-41b3-a8a7-89c06e57cbe5',
      perkSourceUUID: '7c0a9fef-7d59-4b00-8abf-9a77d94d2eac',
      perkStatus: 'ACTIVE',
      perkTitle: '10% Off Coupon',
      perkType: 'THD_COUPONS_CMS',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 0,
      renewed: false,
      rewardId: '9386a6ae-f437-49cc-9ea4-bb11aff10494',
      tierPerks: [/* not included in mock data */],
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2023,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98154000004791977833956768',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '7c0a9fef-7d59-4b00-8abf-9a77d94d2eac',
          barCode: 'OTgxNTQwMDAwMDQ3OTE5Nzc4MzM5NTY3Njg='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  exxonPerk: { // CMS, instore, not giftcard
    perk: {
      activationTime: '04/15/2024',
      activationType: 'CMS',
      adminIssued: true,
      amount: 5,
      availability: 'IN_STORE',
      availableBalance: 0,
      bonusPerk: true,
      bulkIssued: false,
      creationTime: '04/15/2024',
      daysLeft: 316,
      exchanged: false,
      expirationTime: '05/01/2025',
      giftCard: false,
      offerType: 'PERK',
      options: [],
      paymentId: 'P1352F01D4645485A0',
      perkDescription: 'By selecting this Perk, you will receive an extra $5 Bonus Coupon to use at Home Depot. Scan the above barcode to redeem at Home Depot. Do not scan barcode at Exxon or Mobil stations.\n\n\n3,000 Exxon Mobil Rewards+ points — a $30 value for fuel and more. Redeem this Perk when you make a fuel purchase at participating Exxon and Mobil service stations. \n\n**After Perk Selection**\n* You will receive an email with a unique Promo Code and a [link to instructions](http://www.homedepot.com/c/exxonmobil-perk) on how to redeem your Perk\n* You must create an Exxon Mobil Rewards+ account or log into an existing one\n* Enter your Promo Code when prompted and your points will be deposited into your Exxon Mobil Rewards+ account\n\n**Perk Details**\n* You have 90 days to select. Available while supplies last\n* Your Exxon Mobil Rewards+ points expire on 12/31/25\n\n© 2024 ExxonMobil',
      perkDisplaySeqNumber: 2,
      perkId: '77fcd8ba-49ac-4016-8800-2a1a6b800c7a',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_$30%20Exxon%20Mobil%20Perk_79cc78f8-ba92-4425-bcd7-1795760cca99',
      perkSourceUUID: '670278c1-f13e-4628-b292-cd02469ae2da',
      perkStatus: 'ACTIVE',
      perkTitle: '$30 Perk ExxonMobil',
      perkType: 'THD_COUPONS_CMS',
      renewed: false,
      rewardId: '53c5ef5a-ec36-4736-b92d-59ce3a1b4e22',
      shortDescription: '',
      shortName: '',
      tierPerks: [/* not included in mock data */],
      triggerType: 'MANUAL_ADMIN_ISSUED',
      yearEarned: 2024,
      mockRedeemResponse: {
        decodedBarcode: '98154000004902203990198243',
        decodedGiftCardPin: '',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '670278c1-f13e-4628-b292-cd02469ae2da',
          barCode: 'OTgxNTQwMDAwMDQ5MDIyMDM5OTAxOTgyNDM='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
  pxdPerkLocked: { // <--- identical to pxdPerk, except is locked
    perk: {
      activationTime: '09/30/2023',
      activationType: 'PAYMENTS',
      adminIssued: false,
      amount: 25,
      availability: 'OMNI_CHANNEL',
      availableBalance: 25,
      bonusPerk: false,
      bulkIssued: false,
      creationTime: '08/31/2023',
      daysLeft: 105,
      earned: true,
      exchanged: false,
      expirationTime: '10/01/2024',
      giftCard: true,
      maxThreshold: 2500,
      maxThresholdDisplay: '2.5K',
      minThreshold: 0,
      minThresholdDisplay: '0',
      offerType: 'PERK',
      options: [],
      paymentId: 'P134EF2B02577598E0',
      perkDescription: '**Important details about your Pro Xtra Dollars:**\n* Pro Xtra Dollars are Home Depot Digital Reward Cards.\n* Expires 12 months after selection.\n* May be used to purchase merchandise/services at Home Depot stores in U.S. and Canada and online at HomeDepot.com.\n* Can be redeemed in-store or online during checkout.\n* Not redeemable for cash or credit unless required by law.\n* See [Pro Xtra Terms and Conditions](https://www.homedepot.com/c/ProXtra_TermsandConditions) for additional details.',
      perkDisplaySeqNumber: 3,
      perkId: '91d05f5d-b266-4139-a4e7-d45dd1e73975',
      perkImageUrl: 'https://storage.googleapis.com/storage.hd-loyalty-prod.gcp.homedepot.com/RewardImages/active_Legacy-PXDollars_653ca682-5b46-4b2d-8b19-24d397a363de.png',
      perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
      // perkStatus: 'ACTIVE',
      perkTitle: 'Pro Xtra Dollars',
      perkType: 'GIFT_CARD',
      programId: '9d784dbe-0a31-40ea-ba06-497e0333f3be',
      progressPercentage: 100,
      renewed: false,
      rewardId: '1e18a0df-dbc8-4cfe-93f2-84c046a003fd',
      shortName: 'Pro Xtra Dollars',
      tierId: 'f0b47339-c205-4117-aa42-aee127834001',
      tierName: '0-2.5K',
      tierPerks: [{}],
      triggerType: 'ACCRUAL_RETIER',
      yearEarned: 2023,
      mockRedeemResponse: { // <--- mock redeem response
        decodedBarcode: '98081080108596640977530',
        decodedGiftCardPin: '6654',
        getPerksRedeemData(requestData) {}, // <--- not included in mock data
        getRedeemResponse: () => {},
        hasPerksRedeemError: false,
        isLoadingRedeemPerks: false,
        redeemPerksData: {
          perkSourceUUID: '921fb0dd-f40f-446a-aa4a-4e98062d4ca0',
          giftCardBalanceAmount: 25,
          barCode: 'OTgwODEwODAxMDg1OTY2NDA5Nzc1MzA=',
          pin: 'NjY1NA=='
        },
        setHasPerksRedeemError() {}, // <--- not included in mock data
      },
    },
  },
};