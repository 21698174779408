import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Door = createSvgIcon(
  <>
    <path d="M16.5 15.75V10.5h-2.25v5.25h2.25Z" />
    <path d="M3 0h18v21.75h3V24H0v-2.25h3V0Zm15.75 2.25H5.25v19.5h13.5V2.25Z" />
  </>,
  'Door'
);

export { Door };