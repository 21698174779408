import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  params, string, bool, shape, client, arrayOf, number, useDataModel
} from '@thd-nucleus/data-sources';

import {
  isBrioProduct,
  isCustomKitchenCabinetProduct,
  checkScheduleAMeasureEligibility,
} from '../../productPodUtils';
import { Badge } from './Badge';

export const ProductBadge = ({ itemId, storeId }) => {

  const [badgesInState, setBadges] = useState([]);

  const { data } = useDataModel('product', {
    variables: {
      itemId,
      storeId,
    }
  });

  const { product } = data || {};

  useEffect(() => {
    // product?.badges can be null from api response, so checking if itemId exist in response
    if (product?.itemId) {
      setBadges(product?.badges);
    }
  }, [product?.badges]);

  let badges = product?.badges || badgesInState;
  const isScheduleAMeasureEligible = checkScheduleAMeasureEligibility(product);

  if (isScheduleAMeasureEligible || isBrioProduct(product)) {
    const installedBadge = { name: 'installed' };
    badges = [...badges, installedBadge];
  }

  if (isCustomKitchenCabinetProduct(product)) {
    const customBadge = { name: 'custom' };
    badges = [...badges, customBadge];
  }

  if (!badges?.length || !badges[0]) return null;

  const badgeModels = {
    new: 'New',
    seasonal: 'New This Season',
    exclusive: 'Exclusive',
    trending: 'Trending',
    bestseller: 'Best Seller',
    'top rated': 'Top Rated',
    installed: 'Installed',
    custom: 'Custom',
    cpo: 'Custom Catalog',
  };

  const displayableBadge = Object.keys(badgeModels);
  const itemBadge = badges
    .filter((badge) => displayableBadge?.indexOf(badge.name) > -1)
    .map((badge) => badgeModels[badge.name]);
  const badge = itemBadge.length ? itemBadge[0] : null;

  if (!badge) {
    return null;
  }

  return (
    <>
      <Badge text={badge} />
    </>
  );
};

ProductBadge.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string
};

ProductBadge.defaultProps = {
  storeId: null
};

const installServices = params({ storeId: string(), zipCode: string() }).shape({
  scheduleAMeasure: bool(),
});

if (installServices.skip) {
  installServices.skip('skipInstallServices', true);
}

ProductBadge.dataModel = {
  product: params({
    itemId: string().isRequired()
  }).shape({
    itemId: string(),
    dataSources: string(),
    installServices,
    info: shape({
      isLiveGoodsProduct: bool(),
      isCustomKitchenCabinet: bool().client(),
      isBrioSku: bool().client(),
    }),
    availabilityType: shape({
      type: string()
    }),
    fulfillment: client(params({ storeId: string(), zipCode: string() }).shape({
      fulfillmentOptions: arrayOf(shape({
        services: arrayOf(shape({
          type: string(),
          locations: arrayOf(shape({
            isAnchor: bool(),
            inventory: shape({
              quantity: number()
            })
          })),
        }))
      }))
    })),
    badges: params({ storeId: string() }).arrayOf(shape({
      name: string(),
      label: string(),
    })),
  })
};

ProductBadge.displayName = 'ProductBadge';
