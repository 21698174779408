import { useEffect, useState, useRef } from 'react';
import { buildPath } from '../domPath/getDomPath';

const useDomPath = ({ attributes } = {}) => {
  const ref = useRef(null);
  const [path, setPath] = useState([]);
  useEffect(() => {
    const { current } = ref;
    if (current && current._reactInternalFiber) {
      // @todo should it use .return initially?
      const _path = buildPath(current._reactInternalFiber.return);
      if (_path.join('') !== path.join('')) {
        setPath(_path);
      }
    } else if (current) {
      const _key = Object.keys(current).find((key) => /^__reactInternalInstance\$/.test(key));
      if (_key) {
        const _path = buildPath(current[_key], [], attributes);
        setPath(_path);
      }
    }
  }, [ref.current]);

  return [path, ref];
};

export { useDomPath };
