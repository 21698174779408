/* eslint-disable */
import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import classnames from 'classnames/bind';

export function FallbackSection({ children, id, title }) {
  const isB2BVariant = children?.[0]?.props?.customRenderId === 'B2B Marketing Gallery';

  const gridColumns = isB2BVariant
  ? 'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-3'
  : classnames({
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-4':
      children.length >= 4,
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-3':
      children.length === 3,
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-2':
      children.length === 2,
    'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-1 md:sui-grid-cols-1':
      children.length <= 1,
  });
  const skipTitle = children?.[0]?.props?.customRenderId === "PromoVisualNavigation";
  return (
    <div
      id={`section-php-${id}`}
      className="sui-h-full"
    >
      <ImpressionProvider
        data={{
          id,
          name: 'Section',
          component: 'Section',
          type: 'content',
        }}
      >
        <>
          {!skipTitle && title && (
            <div>
              <Typography
                variant="h2"
                height="snug"
                color="primary"
                uppercase
              >
                {title}
              </Typography>
            </div>
          )}
          <div className={gridColumns}>
            {children.map((child, index) => <React.Fragment key={index}>{child}</React.Fragment>)}
          </div>
        </>
      </ImpressionProvider>
    </div>
  );
}
