import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './card.module.scss';
import { Paper } from '../Paper';

const cx = classNames.bind(styles);

const Card = ({ sharp, children, className }) => {

  const cardClasses = cx('card', {}, className);

  return (
    <Paper className={cardClasses} sharp={sharp}>
      { children }
    </Paper>
  );
};

Card.displayName = 'Card';

Card.propTypes = {
  sharp: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string
};

Card.defaultProps = {
  className: null,
  sharp: false,
  children: null,
};

export { Card };
