import React, { useEffect, useState, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import classNames from 'classnames';
import {
  arrayOf, bool, func, node, string, oneOfType
} from 'prop-types';
import './overlay.style.scss';
import CloseButton from './components/CloseButton/CloseButton';

const ESCAPE_KEYCODE = 27;

export const Overlay = ({
  children,
  className,
  closeButton,
  el = 'div',
  fit,
  header,
  medium = false,
  modalClass,
  onClose,
  navigateBackOnClose,
  open,
  placement,
  positionedCloseButton = false,
  scrollable
}) => {

  const {
    isServer,
    channel
  } = useContext(ExperienceContext);

  if (isServer) {
    return null;
  }

  const [container] = useState(document.createElement(el));
  const [componentDidMount, setComponentDidMount] = useState(false);

  const handleEscape = useCallback((keyEvent) => {
    const escapeClick = (keyEvent && keyEvent.keyCode === ESCAPE_KEYCODE);
    if (escapeClick && onClose) {
      onClose();
      window.history.back();
    }
  }, []);

  const enableNoScroll = useCallback((isOpen) => {
    if (isOpen) {
      document.body.classList.add('overlay--noscroll');
    }
  }, []);

  useEffect(() => { setComponentDidMount(true); }, []);

  useEffect(() => {
    if (open) {
      window.history.pushState({}, 'Overlay', '#overlay');
      document.addEventListener('keydown', handleEscape, false);
    }
    document.body.appendChild(container);
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', onClose, false);
    }
    enableNoScroll(open);
    return () => {
      document.body.removeChild(container);
      document.removeEventListener('keydown', handleEscape, false);
      window.removeEventListener('popstate', onClose, false);
      document.body.classList.remove('overlay--noscroll');
    };
  }, [open]);

  const handleBackdropClick = useCallback((clickEvent) => {
    const backdropClick = (
      clickEvent
      && clickEvent.target
      && clickEvent.target.classList
      && clickEvent.target.classList.contains('overlay')
    );
    if (backdropClick && onClose) {
      onClose();
      window.history.back();
    }
  }, []);

  const overlayClasses = classNames('overlay', className, {
    'overlay--open': open,
    'overlay--closed': !open
  });

  const modalClasses = classNames('modal', {
    modal__desktop: channel === 'desktop' || channel === 'tablet',
    'modal__desktop--scrollable': scrollable && (channel === 'desktop' || channel === 'tablet'),
    'modal--fullscreen': channel === 'mobile',
    'modal--medium': medium && channel !== 'mobile',
    'modal--fit': fit && channel !== 'mobile'
  }, modalClass);

  const modalHeaderClasses = classNames({
    modal__header: header
  });

  return componentDidMount && ReactDOM.createPortal(
    // needed for backdrop click
    // eslint-disable-next-line
    <div className={overlayClasses} onClick={(event) => handleBackdropClick(event)} role="main">
      <div className={modalClasses}>
        {!positionedCloseButton && (
          <div className={modalHeaderClasses}>
            {header && <h2 className="modal__header--title">{header}</h2>}
            {closeButton
            && (
              <CloseButton
                channel={channel}
                onClose={onClose}
                header={header}
                navigateBackOnClose={navigateBackOnClose}
                placement={placement}
              />
            )}
          </div>
        )}
        {closeButton && positionedCloseButton
        && (
          <CloseButton
            channel={channel}
            onClose={onClose}
            navigateBackOnClose={navigateBackOnClose}
            header={header}
            placement={placement}
            positionedCloseButton
          />
        )}
        {children}
      </div>
    </div>, container);
};

Overlay.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  className: string,
  closeButton: bool,
  navigateBackOnClose: bool,
  fit: bool,
  header: string,
  medium: bool,
  modalClass: string,
  onClose: func.isRequired,
  open: bool,
  scrollable: bool
};

Overlay.defaultProps = {
  children: null,
  className: null,
  closeButton: false,
  navigateBackOnClose: true,
  fit: false,
  header: null,
  medium: false,
  modalClass: null,
  open: false,
  scrollable: true
};
