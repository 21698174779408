import React, { useContext } from 'react';
import { shape, func, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  getNearbyStoreWithLimitedStock, formatDistance, productShape
} from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { LimitedStockIcon } from '../../helpers/icon-utils';
import { isMobile, isFunction } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';

export const LimitedAvailabilityNearby = ({
  product,
  onCheckNearbyStoresClick,
  customRender
}) => {
  const { channel } = useContext(ExperienceContext);
  const nearbyStore = getNearbyStoreWithLimitedStock(product);

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({
        pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        value: {}
      });
    }
    return (<div className="store__message" />);
  };

  if (!(product && nearbyStore)) {
    return emptyComponent();
  }

  const { storeName, distance } = nearbyStore;

  if (!(storeName && distance)) {
    return emptyComponent();
  }

  const storeNameLink = isMobile(channel)
    ? <span className="store__primary store__store-name store__text-adjustment">{storeName}</span>
    : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
        nearbyStoreName={storeName}
      />
    );

  if (isFunction(customRender)) {
    return customRender(
      {
        pickUpTemplate: PICKUP_TEMPLATES.LIMITED_AVAILABILITY_NEARBY,
        icon: LimitedStockIcon,
        values:
          { storeNameLink, storeName, distance: formatDistance(distance) }
      });
  }

  return (
    <div className="store__message">
      <div className="store__icon">{LimitedStockIcon}</div>
      <div className="store__text-box">
        <div className="store__dark">Pickup Nearby</div>
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__dark">{'Limited stock at '}</span>
          {storeNameLink}
        </div>
      </div>
    </div>
  );
};

LimitedAvailabilityNearby.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func
};

LimitedAvailabilityNearby.defaultProps = {
  onCheckNearbyStoresClick: () => { },
  customRender: null
};
