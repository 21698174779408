/* eslint-disable no-use-before-define */
/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import queryString from 'query-string';

let SCRIPT_LOADED = false;
const HREF = (typeof window !== 'undefined') ? window.location.href : '';
const queryParams = (typeof window !== 'undefined') ? queryString.parse(window.location.search) : {};
// const initEventRecorder = window.initEventRecorder;
// const addComparatorHeader = window.addComparatorHeader;
// const captureRequest = window.captureRequest;
const getCookie = (name) => {
  try {
    return decodeURIComponent(
      ('; ' + document.cookie)
        .split('; ' + name + '=')
        .pop()
        .split(';')
        .shift()
    );
  } catch (err) {
    console.error(err);
  }
  return '';
};

const getParam = (name) => {
  try {
    return queryParams[name] || '';
  } catch (err) {
    console.error(err);
  }
  return '';
};

const readFeatureSwitch = (key) => {
  try {
    if (window.THD_GLOBAL && window.THD_GLOBAL.THD_CART && window.THD_GLOBAL.THD_CART.FEATURES) {
      return window.THD_GLOBAL.THD_CART.FEATURES[key];
    }
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const isAutomation = () => {
  try {
    if (
      HREF.includes('=automation')
      || HREF.includes('isAutomation=true')
      || (getCookie('isAutomation') === 'true')
    ) {
      window.isAutomation = true;
      return true;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

export const isRecord = () => {
  try {
    if (
      HREF.includes('=record')
      || HREF.includes('isRecord=true')
      || (getCookie('isRecord') === 'true')
    ) {
      window.isRecord = true;
      return true;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

export const loadAutomationLibs = ({
  dataReadyResolver,
  loadedAutoLibs,
  addNewRelicPageAction
}) => {
  try {
    if (!isRecord() && !isAutomation()) {
      return;
    }
    const key = 'AUTOMATION_LIBS_VERSION';
    const version = getParam(key) || getCookie(key) || readFeatureSwitch(key) || '';
    if (version && !SCRIPT_LOADED) {
      SCRIPT_LOADED = true;
      const url = `https://assets.homedepot-static.com/react-components/cart-common-core/${version}/automation-libs-wrapper.js`;
      // const url = '/assets/automation-libs-wrapper.js';
      const script = document.createElement('script');
      const firstScriptOnPage = document.getElementsByTagName('script')[0];
      script.type = 'text/javascript';
      script.async = true;
      firstScriptOnPage.parentNode.insertBefore(script, firstScriptOnPage);
      script.onload = () => {
        try {
          if (window.automationLibs) {
            const {
              loadEventRecorder,
              captureRequest,
              wrappedFetch,
              wrappedResp
            } = window.automationLibs;

            loadedAutoLibs.captureRequest = captureRequest;
            loadedAutoLibs.wrappedFetch = wrappedFetch;
            loadedAutoLibs.wrappedResp = wrappedResp;
            if (isRecord) {
              loadEventRecorder(addNewRelicPageAction);
            }
          }
        } catch (err) {
          console.error(err);
        }
        dataReadyResolver();
      };
      script.onerror = (error = '') => {
        console.error('Error loading automation-libs', error);
        dataReadyResolver();
      };
      script.src = url;
    }
  } catch (error) {
    console.error(error);
  }
};