/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Children, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDomPath } from '@thd-olt-functional/utils';
import { UUID, publisher, determineLineLeftAndWidthForActiveTab } from '../../utils';
import styles from './tab.module.scss';

const cx = classNames.bind(styles);
const id = UUID();
const SCROLL_MARGIN = 30;

const Tabs = ({
  children: childrenProp,
  className,
  gridStyle,
  onChange,
  value
}) => {
  const tabLineRef = createRef();
  const leftScrollRef = createRef();
  const rightScrollRef = createRef();
  const tabRefs = [];
  const [path, tabsWrapperRef] = useDomPath();

  const updateTabsLine = ({ activeTab, expand }) => {
    const { lineWidth, lineLeft } = determineLineLeftAndWidthForActiveTab({ expand, activeTab, tabRefs });
    const line = tabLineRef.current;
    line.style.width = `${lineWidth}px`;
    line.style.left = `${lineLeft}px`;
  };

  const onClickTab = (event, onClick) => {
    // onClick comes from Tab
    if (typeof onClick === 'function') {
      onClick(event);
    }
    const { currentTarget } = event;
    const activeTabIndex = parseInt(currentTarget.value, 10);
    let text = '';
    if (tabRefs.length > 0) {
      text = tabRefs[activeTabIndex].innerText;
    }

    publisher('tabs.click', { activeTabIndex, text, path, id });
    // onChange comes from Tabs
    if (onChange) {
      onChange({ index: activeTabIndex });
    }
  };

  const expandToggle = (event, expand) => {
    const { currentTarget } = event;
    if (value === parseInt(currentTarget.value, 10)) {
      updateTabsLine({ activeTab: currentTarget, expand });
    }
  };

  const handleScroll = (scrollOffset) => {
    tabsWrapperRef.current.scrollLeft += scrollOffset;
    const tabScrollWidth = tabsWrapperRef.current.scrollWidth;
    const tabScrollOffset = tabsWrapperRef.current.offsetWidth;
    const menuPosition = tabsWrapperRef.current.scrollLeft + scrollOffset;
    const menuEndOffset = tabScrollWidth - tabScrollOffset - SCROLL_MARGIN;
    if (menuPosition <= SCROLL_MARGIN) {
      leftScrollRef.current.hidden = true;
      rightScrollRef.current.hidden = false;
    } else if (menuPosition < menuEndOffset) {
      leftScrollRef.current.hidden = false;
      rightScrollRef.current.hidden = false;
    } else {
      leftScrollRef.current.hidden = false;
      rightScrollRef.current.hidden = true;
    }
  };

  const children = (Children.map(childrenProp, (tab, index) => {
    const titleId = `${id}-${index + 1}`;
    let cloneElement;
    if (tab) {
      cloneElement = React.cloneElement(tab, {
        ariaControls: `tabpanel-${index}`,
        captureRef: (node) => {
          tabRefs[index] = node;
        },
        selected: value === index,
        key: `key-${titleId}`,
        onClick: (event) => { onClickTab(event, tab.props.onClick); },
        onMouseEnterTab: (event) => expandToggle(event, true),
        onMouseLeaveTab: (event) => expandToggle(event, false),
        value: index
      });
    }
    return cloneElement;
  }));

  useEffect(() => {
    if (!gridStyle && tabRefs.length > 0) {
      updateTabsLine({ activeTab: tabRefs[value], expand: true });
    }
    publisher('tabs.update', { activeTabIndex: value, id });
  }, [value]);

  useEffect(() => {
    if (rightScrollRef.current) {
      rightScrollRef.current.hidden = !!(tabsWrapperRef.current.scrollWidth === tabsWrapperRef.current.offsetWidth);
    }
  }, [rightScrollRef.current]);

  const tabsWrapperClasses = cx('tabs', {
    'tabs--grid': gridStyle
  }, className);

  const leftScrollClasses = cx(
    'scroll--left',
    'scroll'
  );
  const rightScrollClasses = cx(
    'scroll--right',
    'scroll'
  );

  return (
    <div ref={tabsWrapperRef} className={tabsWrapperClasses} data-testid="tabs-wrapper">
      {gridStyle && (
        <div className={cx('scrolls')}>
          <button
            className={leftScrollClasses}
            data-testid="left-scroll"
            hidden
            onClick={() => handleScroll(-400)}
            ref={leftScrollRef}
            type="button"
          >
            <img src="https://assets.thdstatic.com/images/v1/back-arrow-orange.svg" alt="Back Arrow" />
          </button>
          <button
            className={rightScrollClasses}
            data-testid="right-scroll"
            onClick={() => handleScroll(400)}
            ref={rightScrollRef}
            type="button"
          >
            <img src="https://assets.thdstatic.com/images/v1/next-arrow-orange.svg" alt="Next Arrow" />
          </button>
        </div>
      )}
      {children}
      <div ref={tabLineRef} className={cx('tabs__line')} data-testid="tab-line" />
    </div>
  );
};

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  className: PropTypes.string,
  gridStyle: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number
};

Tabs.defaultProps = {
  className: null,
  gridStyle: false,
  onChange: () => {},
  value: 0
};

export { Tabs };
