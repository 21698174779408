import * as React from 'react';
import { Alert } from '@one-thd/sui-atomic-components';
import { getDaysUntil } from '../utils/DrawerUtils';

export const ExpireLine = ({ expiration }) => {

  let status = '';
  let expireMessage = '';
  const diff = getDaysUntil(expiration);

  if (diff === 0) {
    expireMessage = 'Expires Today';
    status = 'warning';
  } else if (diff > 0 && diff <= 14) {
    expireMessage = `Expires in ${diff} day${diff < 2 ? '' : 's'}`;
    status = 'warning';
  } else if (diff > 14) {
    expireMessage = `Expires ${expiration}`;
    status = 'info';
  }

  return (
    <div className="sui-w-full sui-text-base">
      <Alert status={status}>
        {expireMessage}
      </Alert>
    </div>
  );
};
