const webPExtension = (url) => {
  let paramChar = '?';

  if (url.indexOf('?') !== -1) {
    paramChar = '&';
  }

  return url + paramChar + 'fm=webp';
};

export function selectPreferredImage({ damImage }) {
  let preferredImage;

  if (damImage?.damContentSelector) {
    preferredImage = damImage.damContentSelector.assetData?.[0]?.selectedImageUrl;
    return preferredImage;
  }

  if (damImage?.damDownloadedContent) {
    preferredImage = webPExtension(damImage.damDownloadedContent.url);
    return preferredImage;
  }

  return preferredImage;
}

export function getHeroImageType(url) {
  if (url.includes('.mp4')) return 'mp4';
  if (url.includes('.gif')) return 'gif';
  return null;
}
