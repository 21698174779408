import React, { useCallback } from 'react';
import classNames from 'classnames';
import { func, oneOf, string, bool } from 'prop-types';
import './close-button.style.scss';

const CloseButton = (props) => {
  const {
    channel,
    onClose,
    placement,
    header,
    positionedCloseButton,
    navigateBackOnClose,
  } = props;

  const isMobile = channel === 'mobile';
  const placementClasses = classNames('overlay-close-button', {
    'overlay-close-button--right': placement === 'right',
    'overlay-close-button--left': placement === 'left'
  });
  const positionedPlacementClasses = classNames('overlay-close-button-positioned', {
    'overlay-close-button-positioned--right': placement === 'right',
    'overlay-close-button-positioned--left': placement === 'left'
  });
  const closeButtonIconClasses = classNames('overlay-close-button__icon', {
    'overlay-close-button__icon--medium': isMobile && header
  });

  const handleBackButtonClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
    if (navigateBackOnClose) {
      window.history.back();
    }
  }, [navigateBackOnClose, onClose]);

  const getCloseButton = () => {
    return (positionedCloseButton ? (
      <span className={positionedPlacementClasses}>
        <button onClick={handleBackButtonClick} type="button">
          <img
            className={closeButtonIconClasses}
            src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
            alt="Close"
          />
        </button>
      </span>
    ) : (
      <div className={placementClasses}>
        <button onClick={handleBackButtonClick} type="button">
          <img
            className={closeButtonIconClasses}
            src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
            alt="Close"
          />
        </button>
      </div>
    ));
  };

  return (
    <>
      {!isMobile || header
        ? (getCloseButton())
        : (
          <div className="overlay-back-button">
            <button onClick={handleBackButtonClick} type="button">
              <img
                className="overlay-back-button__icon"
                src="https://assets.homedepot-static.com/images/v1/back-arrow-orange.svg"
                alt="Back"
              />
              <span className="overlay-back-button__text">Back</span>
            </button>
          </div>
        )}
    </>
  );
};

CloseButton.propTypes = {
  channel: oneOf(['desktop', 'mobile']),
  onClose: func.isRequired,
  header: string,
  navigateBackOnClose: bool,
  placement: oneOf(['right', 'left']),
  positionedCloseButton: bool
};

CloseButton.defaultProps = {
  channel: 'desktop',
  header: null,
  navigateBackOnClose: true,
  placement: 'right',
  positionedCloseButton: false,
};

export default CloseButton;
