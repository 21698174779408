import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import setRef from '../../utils/setRef';
import HTMLElementType from '../../utils/HTMLElementType';
import useEnhancedEffect from '../../hooks/useEnhancedEffect';
import useForkRef from '../../hooks/useForkRef';

function getContainer(container) {
  return typeof container === 'function' ? container() : container;
}

const Portal = React.forwardRef((props, ref) => {
  const { children, container, disablePortal = false } = props;
  const [mountNode, setMountNode] = useState(null);
  const handleRef = useForkRef(React.isValidElement(children) ? children.ref : null, ref);

  useEnhancedEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(container) || document.body);
    }
  }, [container, disablePortal]);

  useEnhancedEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode);
      return () => {
        setRef(ref, null);
      };
    }

    return undefined;
  }, [ref, mountNode, disablePortal]);

  if (disablePortal) {
    if (React.isValidElement(children)) {
      const newProps = {
        ref: handleRef,
      };
      return React.cloneElement(children, newProps);
    }
    return <>{children}</>;
  }

  return mountNode ? createPortal(children, mountNode) : mountNode;
});

Portal.displayName = 'Portal';

Portal.propTypes = {
  /**
   * The children to render into the `container`.
   */
  children: PropTypes.node,
  /**
   * An HTML element or function that returns one.
   * The `container` will have the portal children appended to it.
   */
  container: PropTypes.oneOfType([
    HTMLElementType,
    PropTypes.func,
  ]),
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: PropTypes.bool
};

Portal.defaultProps = {};

export { Portal };