import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Link
} from '@one-thd/sui-atomic-components';
import { CARD_IMAGE } from '../utils/constants';

const ProXtraDrawer = ({ applyNowUrl, closeDrawer, open, hideApplynow }) => {

  const onClickApplyNow = () => {
    if (applyNowUrl) window.open(applyNowUrl, '_blank').focus();
  };

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      position="right"
      id="drawer-terms-conditions"
      data-testid="drawer-terms-conditions"
    >
      <DrawerHeader title="Pro Xtra Credit Card" onClose={closeDrawer} data-testid="drawer-close-button" />
      <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
        <DrawerBody>
          <div className="sui-flex sui-flex-col sui-gap-4">
            <div className="sui-m-auto sui-px-6 sui-max-w-xs">
              <img
                src={CARD_IMAGE.PLCR_HOMEDEPOT}
                alt="Credit Card Icon"
                width="185"
                height="103"
              />
            </div>
            <Typography weight="bold" variant="body-lg" align="center">
              Earn Perks 4X Faster*
            </Typography>
            <Typography variant="body-base" align="center">
              <span className="sui-text-subtle sui-text-center">
                The Pro Xtra Credit Card earns 4X every dollar spent at The Home Depot towards Perks
                when you {hideApplynow ? '' : ' join ProXtra, register and '} use your card.
              </span>
            </Typography>

            <div>
              <Typography weight="bold">
                Additional Pro Xtra Credit Card Benefits
              </Typography>

              <ul className="sui-pl-6 sui-list-disc">
                <li>
                  Payment flexibility: Make low monthly payments or pay in full
                </li>
                <li>
                  Authorized-user cards available
                </li>
                <li>
                  Track purchases by PO or job name
                </li>
                <li>
                  Itemized statements
                </li>
                <li>
                  One-year return policy
                </li>
              </ul>
            </div>

            <Typography variant="body-xs" align="center">
              *4X faster does not apply to The Home Depot Commercial Account.
              4X faster applies only to the Perks benefit of the Pro Xtra Credit Card.
              $1 spent on your Pro Xtra Credit Card counts as $4 for purposes of earning Perks.
              There are minimum spend thresholds and
              limits to the amount of Perks that you may earn.
            </Typography>

            <p className="sui-text-center sui-m-3">
              <Link href="https://www.homedepot.com/c/ProXtra_TermsandConditions">
                ProXtra Program Terms & Conditions
              </Link>
            </p>
          </div>
        </DrawerBody>
        <DrawerFooter>
          {!hideApplynow ? (
            <Button
              variant="primary"
              data-testid="drawer-apply-now"
              onClick={onClickApplyNow}
              fullWidth
            >
              Apply Now
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={closeDrawer}
              fullWidth
            >
              Close
            </Button>
          )}
        </DrawerFooter>
      </div>
    </Drawer>
  );
};

ProXtraDrawer.propTypes = {
  applyNowUrl: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
  hideApplynow: PropTypes.bool
};

ProXtraDrawer.defaultProps = {
  open: true,
  hideApplynow: false,
};

export default ProXtraDrawer;
