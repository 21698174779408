import React from 'react';
import {
  arrayOf, bool, number, shape, string
} from 'prop-types';
import { publishAnalytics } from '../../../../utils';

function countAndRemoveDuplicateItems(items) {
  const newItems = [];
  const quantities = {};
  items.forEach((item) => {
    if (quantities[item.skuNumber]) {
      quantities[item.skuNumber] += 1;
    } else {
      quantities[item.skuNumber] = item.quantity;
      newItems.push({ ...item });
    }
  });

  for (let i = 0; i < newItems.length; i += 1) {
    newItems[i].quantity = quantities[newItems[i].skuNumber];
  }
  return newItems;
}

function publishImageClickEvent() {
  publishAnalytics('recentOrders.image-click');
}

export function CardOrderItemThumbnails({ hasCurbsideButton, href, items, windowWidth }) {
  const getMaxDisplayNumber = () => {
    if (windowWidth > 500) return 5;
    if (windowWidth > 420) return 4;
    if (windowWidth > 330) return 3;
    return 2;
  };

  let maxDisplayNumber = getMaxDisplayNumber();
  let itemOverflowCount = 0;
  let displayedItems = countAndRemoveDuplicateItems(items);
  if (displayedItems.length > maxDisplayNumber) {
    itemOverflowCount = displayedItems.length - maxDisplayNumber + 1;
    displayedItems = displayedItems.slice(0, maxDisplayNumber - 1);
  }

  const NO_IMAGE_URL = 'https://assets.thdstatic.com/images/v1/no-img.svg';

  const imageSize = hasCurbsideButton ? 56 : 80;
  const linkSizeClasses = hasCurbsideButton ? 'sui-h-14 sui-w-14' : 'sui-h-20 sui-w-20';

  let itemImages = displayedItems.map((item, idx) => (
    <a
      href={href}
      key={`displayed-items-${idx}`}
      onClick={publishImageClickEvent}
      className={`sui-block sui-relative sui-mr-3 ${linkSizeClasses}`}
    >
      <img
        src={item.imageUrl || NO_IMAGE_URL}
        alt={item.itemDescFromCatalog}
        key={`image-${idx}`}
        title={item.itemDescFromCatalog}
        width={imageSize}
        height={imageSize}
      />
      {item.quantity > 1 && (
        <div className="sui-flex sui-absolute sui-justify-center sui-items-center sui-min-w-min sui-h-4 sui-w-4 sui-right-0 sui-top-0 sui-bg-medium sui-text-inverse">
          {item.quantity}
        </div>
      )}
    </a>
  ));
  return (
    <div
      className="sui-flex sui-items-center"
      data-testid="recent-order-items"
    >
      {itemImages}
      {itemOverflowCount > 0 && (
        <a
          href={href}
          className="sui-h-12 sui-w-12 sui-flex sui-items-center sui-justify-center"
        >
          {`+${itemOverflowCount}`}
        </a>
      )}
    </div>
  );
}

CardOrderItemThumbnails.propTypes = {
  hasCurbsideButton: bool,
  href: string,
  items: arrayOf(
    shape({
      itemDescFromCatalog: string,
      quantity: number,
      imageUrl: string,
    })
  ),
  windowWidth: number,
};

CardOrderItemThumbnails.defaultProps = {
  hasCurbsideButton: true,
  href: undefined,
  items: [],
  windowWidth: undefined,
};
