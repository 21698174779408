import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Drawer } from '@one-thd/sui-atomic-components';
import { MainMenuDrawerBody } from './drawerBodies/MainMenuDrawerBody';
import { DeleteListDrawerBody } from './drawerBodies/DeleteListDrawerBody';
import { ShareListDrawerBody } from './drawerBodies/ShareListDrawerBody';
import { RenameListDrawerBody } from './drawerBodies/RenameListDrawerBody';

export const ListSettingsDrawer = ({
  open,
  setOpen,
  list,
  onDelete,
  onSave,
  isSharedList
}) => {
  const [menuName, setMenuName] = useState(null);
  const [mainMenuDrawer, setMainMenuDrawer] = useState(true);
  const [renameListDrawer, setRenameListDrawer] = useState(false);
  const [shareListDrawer, setShareListDrawer] = useState(false);
  const [deleteListDrawer, setdeleteListDrawer] = useState(false);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('ListSettingsDrawer.ready', {});
  }, []);

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={closeDrawer}>
      <div data-component="ListSettingsDrawer">
        {mainMenuDrawer && (
          <MainMenuDrawerBody
            open={open}
            list={list}
            onClose={closeDrawer}
            menuName={menuName}
            setMenuName={setMenuName}
            setMainMenuDrawer={setMainMenuDrawer}
            setShareListDrawer={setShareListDrawer}
            setRenameListDrawer={setRenameListDrawer}
            setDeleteListDrawer={setdeleteListDrawer}
            isSharedList={isSharedList}
          />
        )}
        {shareListDrawer && (
          <ShareListDrawerBody
            open={open}
            list={list}
            onClose={closeDrawer}
            onSave={onSave}
            menuName={menuName}
            setMenuName={setMenuName}
            setMainMenuDrawer={setMainMenuDrawer}
            setShareListDrawer={setShareListDrawer}
          />
        )}
        {deleteListDrawer && (
          <DeleteListDrawerBody
            list={list}
            onDelete={onDelete}
            onClose={closeDrawer}
            menuName={menuName}
            setMenuName={setMenuName}
            setMainMenuDrawer={setMainMenuDrawer}
            setdeleteListDrawer={setdeleteListDrawer}
          />
        )}
        {renameListDrawer && (
          <RenameListDrawerBody
            list={list}
            onClose={closeDrawer}
            setMainMenuDrawer={setMainMenuDrawer}
            menuName={menuName}
            setMenuName={setMenuName}
            setRenameListDrawer={setRenameListDrawer}
            onSave={onSave}
          />
        )}
      </div>
    </Drawer>
  );
};

ListSettingsDrawer.displayName = 'ListSettingsDrawer';

ListSettingsDrawer.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  onDelete: func.isRequired,
  list: shape({
    id: string,
    name: string,
    public: bool
  }).isRequired,
  onSave: func,
  isSharedList: bool
};

ListSettingsDrawer.defaultProps = {
  onSave: () => { },
  isSharedList: false
};

ListSettingsDrawer.dataModel = extend(
  MainMenuDrawerBody, // may be able to remove, review after list name change is added
  DeleteListDrawerBody,
  ShareListDrawerBody
);
