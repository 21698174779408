const getDeliveryDetails = () => {
  if (typeof window !== 'undefined') {
    return {
      store: window.THD_LOCALIZER_AUTO_INIT.Localizer.getLocalizedStore().storeId,
      zipcode: window.THD_LOCALIZER_AUTO_INIT.Localizer.getDeliveryZipcodeDetails().zipcode,
    };
  }
  // Need this to just get a default store response if it is an Innervate store campaign
  return { store: '8119', zipcode: undefined };
};

export default getDeliveryDetails;
