import { createTheme } from '@one-thd/sui-atomic-components';

const PrimaryHolidayRed = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

PrimaryHolidayRed.palette['--sui-palette-background-primary'] = '152 5 11';

export { PrimaryHolidayRed };