export default function HTMLElementType(
  props,
  propName,
  componentName,
  location,
  propFullName,
) {
  // if (process.env.NODE_ENV === 'production') {
  //   return null;
  // }

  const { [propName]: propValue } = props;
  const safePropName = propFullName || propName;

  if (propValue == null) {
    return null;
  }

  if (propValue && (propValue).nodeType !== 1) {
    return new Error(
      `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. Expected an HTMLElement.`,
    );
  }

  return null;
}
