import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleWood = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleWood.palette['--sui-palette-background-primary'] = '216 182 147';

export { HouseStyleWood };