import React from 'react';
import {
  Alert, Button, DrawerBody, DrawerFooter, Link
} from '@one-thd/sui-atomic-components';
import { string, func } from 'prop-types';

const AddToListSuccess = ({ listName, onClose, listId }) => {
  return (
    <>
      <DrawerBody>
        <Alert status="success">
          Your item has been added to your list:&nbsp;
          <Link title={listName} href={`/list/view/details/${listId}`}>
            {listName}
          </Link>
        </Alert>
      </DrawerBody>
      <DrawerFooter>
        <Button
          fullWidth
          variant="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </DrawerFooter>
    </>
  );
};

AddToListSuccess.propTypes = {
  listId: string.isRequired,
  listName: string.isRequired,
  onClose: func.isRequired
};

AddToListSuccess.displayName = 'AddToListSuccess';

export { AddToListSuccess };
