import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useFormController from '../hooks/useFormController';
import formControllerState from '../utils/formControllerState';

/**
 * A utility component to help render the status message related to a form input.
 *
 * Related components: [TextField](#textfield), [QuantityPicker](#quantitypicker), [DropdownField](#dropdownfield),
 * [FormController](#formcontroller)
 *
 * Usage:
 *
 * ```jsx
 * import { FormStatusMessage } from '@one-thd/sui-atomic-components';
 * ```
 */
const FormStatusMessage = React.forwardRef((props, ref) => {
  const {
    children,
    component: FormStatusMessageRoot = 'p',
    disabled,
    status,
    ...other
  } = props;

  const formControl = useFormController();
  const fcs = formControllerState({
    props,
    formControl,
    states: ['status', 'disabled']
  });

  const classes = classNames('sui-font-regular sui-mt-1 sui-text-xs sui-leading-none sui-text-left', {
    'sui-text-inactive': fcs.disabled,
    'sui-text-danger': fcs.status === 'error' && !fcs.disabled,
    'sui-text-success': fcs.status === 'success' && !fcs.disabled,
    'sui-text-primary': fcs.status === 'warning' && !fcs.disabled
  });

  return (
    <FormStatusMessageRoot
      className={classes}
      ref={ref}
      {...other}
    >
      {children}
    </FormStatusMessageRoot>
  );
});

FormStatusMessage.displayName = 'FormStatusMessage';

FormStatusMessage.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the help messaging should be displayed in a disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * The states of validation for the component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning'])
};

FormStatusMessage.defaultProps = {};

export { FormStatusMessage };
