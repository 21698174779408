import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '../private/components/paper/Paper';
import { SkeletonContent } from './SkeletonContent';

function createComponent(Component, displayName) {

  const Comp = (props, ref) => {

    const {
      as,
      ...other
    } = props;

    return (
      <Component component={as} ref={ref} {...other} />
    );
  };

  Comp.displayName = displayName;
  return React.forwardRef(Comp);
}

const SkeletonRoot = createComponent(Paper, 'SkeletonRoot');

/**
 * Skeleton is a temporary placeholder to display while your content loads that
 * helps reduce load-time inconveniences.
 *
 * Use `Skeleton` with the following sub-components:
 * [SkeletonBlock](#skeletonblock), [SkeletonLine](#skeletonline), [SkeletonContent](#skeletoncontent)
 *
 * Usage:
 *
 * ```jsx
 * import { Skeleton } from '@one-thd/sui-atomic-components';
 * ```
 */
const Skeleton = React.forwardRef((props, ref) => {

  const {
    SkeletonContentProps,
    component = 'div',
    children,
    disableGutters = false,
    disablePadding = false,
    disableShrink = false,
    density = 'loose',
    grow = false,
    initialSize,
    orientation = 'vertical',
    PaperProps: PaperPropsProp = { variant: 'shadow', shadow: 'none' },
    ...other
  } = props;

  const { variant = 'outlined', ...rest } = PaperPropsProp;

  const PaperProps = {
    variant,
    ...rest,
    ...other
  };

  return (
    <SkeletonRoot
      as={component}
      ref={ref}
      className="sui-flex"
      {...PaperProps}
    >
      <SkeletonContent
        orientation={orientation}
        disableGutters={disableGutters}
        disablePadding={disablePadding}
        disableShrink={disableShrink}
        density={density}
        grow={grow}
        initialSize={initialSize}
        {...SkeletonContentProps}
      >
        {children}
      </SkeletonContent>
    </SkeletonRoot>
  );
});

Skeleton.displayName = 'Skeleton';

Skeleton.propTypes = {
  /**
   * Props applied to the CardContent element.
   */
  SkeletonContentProps: PropTypes.object,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Depending on option will reduce or increase the amount of padding.
   */
  density: PropTypes.oneOf(['normal', 'loose']),
  /**
   * @ignore
   */
  disableGutters: PropTypes.bool,
  /**
   * @ignore
   */
  disablePadding: PropTypes.oneOf([true, 'x', 'y', false]),
  /**
   * @ignore
   */
  disableShrink: PropTypes.bool,
  /**
   * Grow flex items to fill the available space in a container.
   */
  grow: PropTypes.bool,
  /**
   * @ignore
   */
  href: PropTypes.string,
  /**
   * @ignore
   */
  initialSize: PropTypes.bool,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Props applied to the `Paper` component.
   */
  PaperProps: PropTypes.object
};

Skeleton.defaultProps = {};

export { Skeleton };
