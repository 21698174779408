import React from 'react';

// const defaultContextValue = {
//   name: undefined,
//   onChange: () => {},
//   value: null
// };

const RadioGroupContext = React.createContext();

export default RadioGroupContext;