import {
  string as stringType
} from '@thd-nucleus/data-sources';
import { EMPTY_LIST_MESSAGE, INVALID_LIST_NAME_MESSAGE } from './constants';

export const LIST_SHAPE = {
  id: stringType(),
  name: stringType(),
  listAccessType: stringType()
};

export const triggerAnalytics = (event, payload) => {
  LIFE_CYCLE_EVENT_BUS.trigger(event, payload);
};

export const validateListName = (value) => {
  const validCharacters = /^[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
  const blank = !(value || '').replace(/\s/g, '');
  let isValid = validCharacters.test(value);
  let errorMessage = '';
  if (!isValid) {
    if (blank) {
      errorMessage = EMPTY_LIST_MESSAGE;
    } else {
      errorMessage = INVALID_LIST_NAME_MESSAGE;
    }
  }
  return { isError: !isValid, errorMessage };
};
