import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Savings = createSvgIcon(
  <>
    <path d="m8.625 16.216 6.841-6.841-1.591-1.591-6.841 6.841 1.591 1.591ZM7.5 11.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm9 3.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
    <path d="M18 3.554V.289l-1.497.524-.004.002-.009.003-.032.011a20.39 20.39 0 0 0-.541.2c-.352.135-.834.325-1.352.55-.516.225-1.08.49-1.593.772-.345.19-.705.41-1.019.652-1.87.03-4.66.28-7.079 1.529-1.322.683-2.556 1.676-3.454 3.109C.52 9.076 0 10.883 0 13.125c0 2.618.606 4.484 1.253 5.724a7.687 7.687 0 0 0 .997 1.483V24h5.57l1.125-2.25h3.86L13.93 24h5.57v-3.74a6.94 6.94 0 0 0 .726-.877c.474-.674.98-1.624 1.309-2.883H24V8.25h-1.874c-.369-.768-.916-1.602-1.552-2.37-.712-.86-1.596-1.711-2.574-2.326Zm-1.125-1.679L16.503.813l.372 1.062Zm-1.413 1.768c.097-.043.194-.084.288-.123v1.383l.621.311c.848.425 1.727 1.204 2.47 2.1.746.902 1.266 1.813 1.467 2.416l.256.77h1.186v3.75h-2.078l-.157.94c-.228 1.374-.72 2.315-1.13 2.9-.206.293-.394.5-.522.625a2.548 2.548 0 0 1-.164.15l-.449.33v2.555h-1.93l-1.125-2.25h-6.64L6.43 21.75H4.5v-2.402l-.396-.33a2.908 2.908 0 0 1-.218-.235 5.447 5.447 0 0 1-.639-.975c-.478-.917-.997-2.426-.997-4.683 0-1.883.433-3.263 1.076-4.29.645-1.028 1.544-1.769 2.58-2.304 2.112-1.09 4.698-1.281 6.469-1.281h.466l.33-.33c.143-.143.44-.352.888-.599.428-.236.923-.47 1.403-.678Z" />
  </>,
  'Savings'
);

export { Savings };