import { createTheme } from '@one-thd/sui-atomic-components';

const PrimaryHolidayLightRed = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

PrimaryHolidayLightRed.palette['--sui-palette-background-primary'] = '216 19 29';

export { PrimaryHolidayLightRed };