import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  SkeletonBlock,
  Card,
  Typography
} from '@one-thd/sui-atomic-components';
import { RecentPurchasesTableHeader } from '../../subcomponents/cardHeader/RecentPurchasesHeader';

export function RecentPurchasesTablePlaceholder() {
  const totalOrders = 6;
  const imagePlaceholder = [...Array(3)].map((key, index) => (
    <React.Fragment key={`recent-purchases-table-image-placeholder-${index}`}>
      <span className="sui-mr-2">
        <SkeletonBlock aspect="wide" height={16} width={16} />
      </span>
    </React.Fragment>
  ));

  let tableRows = [...Array(totalOrders)].map((key, index) => (
    <TableRow key={`recent-purchases-table-row-${index}`} className="sui-table sui-w-full sui-table-fixed sui-border-b-2 sui-border-solid sui-border-stronger">
      <TableCell className="sui-w-28 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
      <TableCell className="sui-w-28 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
      <TableCell className="sui-w-52 md:sui-table-cell sui-p-1">
        <div className="sui-flex sui-overflow-hidden">
          {imagePlaceholder}
        </div>
      </TableCell>
      <TableCell className="sui-w-32 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
      <TableCell className="sui-w-32 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
      <TableCell className="sui-w-28 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
      <TableCell className="sui-w-24 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
      <TableCell className="sui-w-16 sui-p-4">
        <SkeletonBlock aspect="wide" height={8} />
      </TableCell>
    </TableRow>
  ));

  return (
    <Card
      className="sui-h-full sui-w-full placeholderTableContainer"
      data-component="RecentPurchasesTablePlaceholder"
    >
      <RecentPurchasesTableHeader />
      {/* eslint-disable tailwindcss/no-arbitrary-value */}
      <div className="sui-h-[430px] sui-mx-2.5 sui-my-0 sui-overflow-auto">
        <Table>
          <TableHead className="sui-bg-primary sui-z-10 sui-sticky sui-top-0">
            <TableRow className="sui-table sui-w-full sui-table-fixed sui-border-b-2 sui-border-solid sui-border-stronger">
              <TableCell className="sui-w-28 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">Date</Typography>
              </TableCell>
              <TableCell className="sui-w-28 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">Status</Typography>
              </TableCell>
              <TableCell className="sui-w-52 sui-font-normal sui-hidden md:sui-table-cell sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">Items</Typography>
              </TableCell>
              <TableCell className="sui-w-32 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">Order/Receipt #</Typography>
              </TableCell>
              <TableCell className="sui-w-32 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">PO/Job Name</Typography>
              </TableCell>
              <TableCell className="sui-w-28 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">Order Origin</Typography>
              </TableCell>
              <TableCell className="sui-w-24 sui-font-normal sui-px-2 sui-py-3 sui-text-left">
                <Typography weight="bold">Total</Typography>
              </TableCell>
              <TableCell aria-label="Placeholder" className="sui-w-16 sui-px-2 sui-py-3 sui-text-left" />
            </TableRow>
          </TableHead>
          <TableBody className="sui-block">{tableRows}</TableBody>
        </Table>
      </div>
    </Card>
  );
}
