import React from 'react';

import { arrayOf, node, oneOfType, bool } from 'prop-types';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import {
  Typography, Button, Card, CardTitle, CardContent, CardActions
} from '@one-thd/sui-atomic-components';
import { ListView } from '@one-thd/sui-icons';

export const EmptyMessagingPhp = (props) => {

  const { children, error } = props;
  const theme = useTheme(EmptyMessagingPhp, props);
  const { showListIcon } = theme.props;

  const subheader = error
    ? 'Unable to Access Your Lists'
    : 'You Don’t Have Any Lists Yet';

  const emptyMessage = showListIcon
    // B2B
    ? 'Create multiple product lists to help you stay organized and prepare bids'
    // B2C
    // eslint-disable-next-line max-len
    : 'Add products to lists to keep organized, save products to purchase later, or reorder frequently purchased products';
  const message = error
    ? 'Something went wrong, please try refreshing your page or signing in to view your Lists'
    : emptyMessage;

  const actionButton = error
    ? (
      <Button
        variant="secondary"
        href="/auth/view/signin?redirect=/&ref=null"
        fullWidth
      >
        Sign In
      </Button>
    ) : children;

  return (
    <Card
      className="sui-w-full sui-h-full"
      CardContentProps={
        { className: 'sui-flex sui-flex-col sui-h-full sui-justify-between sui-gap-4 sui-p-4 sui-items-center' }
      }
    >
      <div className="sui-self-start">
        <Typography variant="h3">My Lists</Typography>
      </div>
      <CardContent className="sui-flex sui-flex-col sui-items-center sui-gap-4">
        <ListView color="medium" />
        <Typography variant="h5">{subheader}</Typography>
        <Typography align="center">
          {message}
        </Typography>
      </CardContent>
      <CardActions className="sui-w-full">
        {actionButton}
      </CardActions>
    </Card>
  );
};

EmptyMessagingPhp.displayName = 'UserListsEmptyMessagingPhp';

EmptyMessagingPhp.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  error: bool
};

EmptyMessagingPhp.defaultProps = {
  children: null,
  error: false
};

EmptyMessagingPhp.themeProps = {
  showListIcon: bool
};

EmptyMessagingPhp.defaultThemeProps = {
  showListIcon: false
};
