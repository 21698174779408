import React from 'react';
import { Card, SkeletonContent, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { RecentOrdersCardHeader } from '../../subcomponents/cardHeader/RecentOrdersHeader';
import { CardItemsPlaceholder } from '../orderDetailsLoading/CardItemsPlaceholder';

export function RecentOrdersCardPlaceholder() {

  return (
    <Card
      id="recent-orders-placeholder"
      data-testid="recent-orders-placeholder"
      className="sui-h-full sui-w-full"
      data-component="RecentOrdersPlaceholder"
    >
      <RecentOrdersCardHeader />
      <div
        role="group"
        data-testid="placeholderCardWrapper"
        className="sui-border-1 sui-border-solid sui-border-primary sui-rounded-sm sui-p-4"
      >
        <div className="sui-flex sui-flex-col sui-gap-4">
          <div className="sui-flex sui-justify-between">
            <div className="sui-flex sui-flex-col sui-gap-1 sui-overflow-hidden">
              <SkeletonBlock height={7} width={44} />
              <SkeletonBlock height={6} width={24} />
              <SkeletonBlock height={6} width={52} />
            </div>
            <SkeletonBlock height={6} width={6} />
          </div>

          <div className="sui-flex sui-flex-col sui-gap-1 sui-overflow-hidden">
            <SkeletonBlock height={6} width={24} />
            <SkeletonBlock height={6} width={24} />
            <SkeletonBlock height={6} width={52} />
          </div>

          <div className="sui-flex sui-flex-col sui-gap-3 sui-w-full sui-justify-end">
            <CardItemsPlaceholder />
            <SkeletonBlock height={11} />
          </div>
        </div>
      </div>
      <SkeletonBlock height={10} />
    </Card>
  );
}
