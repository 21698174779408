import React from 'react';
import { READY } from '../utils/constants';
import { createClickId } from '../utils/index';

export const impressionGlobalData = {
  observer: null,
  impressionMap: new Map(),
  impressed: {},
  impressionRefArray: [],
};

// re-observe items for impressions on visibility change
if (typeof window !== 'undefined') {
  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('page.visibilitychange', () => {
    if (!document.hidden && window?.__EXPERIENCE_CONTEXT__?.isConsumerApp) {
      impressionGlobalData.impressed = {};
      if (impressionGlobalData.impressionRefArray?.length > 0) {
        for (
          let i = 0;
          i < impressionGlobalData.impressionRefArray.length;
          i += 1
        ) {
          const entry = impressionGlobalData.impressionMap.get(
            impressionGlobalData.impressionRefArray[i]
          );
          // Reset the visibility timer to zero and re-observe each entry
          const clickID = createClickId({
            data: entry.impression,
            parent: entry.container,
          });
          // Why is this NOT an empty string?
          impressionGlobalData.impressed[clickID] = READY;
          entry.visibleSince = 0;
          clearTimeout(entry.timeout);
          impressionGlobalData.observer.observe(entry.ref);
        }
      }
    }
  });
}

// on-event driven reset of impression provider
if (typeof window !== 'undefined') {
  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('resetImpressionProvider', () => {
    impressionGlobalData.impressed = {};
    if (impressionGlobalData.impressionRefArray?.length > 0) {
      for (
        let i = 0;
        i < impressionGlobalData.impressionRefArray.length;
        i += 1
      ) {
        const entry = impressionGlobalData.impressionMap.get(
          impressionGlobalData.impressionRefArray[i]
        );
        const clickID = createClickId({
          data: entry.impression,
          parent: entry.container,
        });
        // Why is this an empty string?
        impressionGlobalData.impressed[clickID] = '';
        entry.visibleSince = 0;
        clearTimeout(entry.timeout);
        impressionGlobalData.observer.observe(entry.ref);
      }
    }
  });
}

const defaultContextValue = {
  ref: null,
  clickID: null,
  parent: {
    id: '', // *** required prop ***
    component: '', // *** required prop *** ==> component public export name
    name: 'Page', // *** required prop *** descriptive titles or component name
    variation: '', // cta style values
    position: '',
    type: '',
    category: '',
  },
  data: {
    id: '', // *** required prop ***
    component: '', // *** required prop *** ==> component public export name
    name: 'component', // *** required prop *** ==> descriptive titles or component name
    variation: 'component', // cta style values
    position: '',
    type: '',
    category: '',
  },
  additionalData: {},
};

export const ImpressionContext = React.createContext(defaultContextValue);
