import React from 'react';
import classnames from 'classnames';
import { node, string, bool } from 'prop-types';

export const ImagePlaceholder = ({
  children, size, showDashes, showBorder
}) => {
  const classes = classnames(
    'sui-w-full sui-mx-1 sui-rounded',
    {
      'sui-border-primary sui-border-dashed sui-border-1': showDashes,
      'sui-border-solid sui-border-2': showBorder
    }
  );

  return (
    <div className="sui-flex sui-flex-col sui-gap-1">
      <div
        className={classes}
        style={{ height: size, width: size }}
      />
      {children && <div className="sui-w-full sui-text-center sui-text-xs">{children}</div>}
    </div>
  );
};

ImagePlaceholder.displayName = 'ImagePlaceholder';

ImagePlaceholder.propTypes = {
  children: node,
  showDashes: bool,
  showBorder: bool,
  size: string.isRequired
};

ImagePlaceholder.defaultProps = {
  children: null,
  showDashes: false,
  showBorder: false,
};
