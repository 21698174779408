import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  state = {};

  componentDidCatch = (error, info) => {
    console.log(error);// eslint-disable-line
    const { id, name, onError } = this.props;
    const { componentStack } = info || {};
    if (onError) {
      onError({ error, id, info, name });
    }
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {

      window.LIFE_CYCLE_EVENT_BUS.trigger('error-boundary.error', {
        componentStack,
        error,
        id,
        name
      });
    }
    return null;
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return null;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  onError: PropTypes.func
};

ErrorBoundary.defaultProps = {
  id: null,
  name: null,
  onError: null
};

ErrorBoundary.displayName = 'ErrorBoundary';

export { ErrorBoundary };
