import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './badge.module.scss';

const cx = classNames.bind(styles);

const Badge = ({
  children,
  className,
  color,
  text,
  type
}) => {

  const prefix = 'value-price-badge';

  let badgeClasses;

  if (/text/.test(type)) {
    badgeClasses = cx('badge-container', 'u__bold', className);
  } else {
    badgeClasses = cx({
      [`${prefix}__bulk-price`]: /bulk-price/.test(type),
      [`${prefix}__new-lower-price`]: /lower-price/.test(type),
      [`${prefix}__special-buy`]: /value-price/.test(type),
      [`${prefix}__over-stock`]: /overstock/.test(type),
      [`${prefix}__clearance`]: /clearance/.test(type),
      'small-badge': /small/.test(type)
    }, className);
  }

  return (
    <span
      className={badgeClasses}
      title={text}
      style={{ backgroundColor: color }}
    >
      {text || children}
    </span>
  );
};

Badge.displayName = 'Badge';

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  className: PropTypes.string,
  /** CSS Valid color value, used to set the background color of the text badge. */
  color: PropTypes.string,
  /** Text badge text. */
  text: PropTypes.string,
  /** Badge to display. Options:
   * 'bulk-price',
   'bulk-price-small',
   'lower-price',
   'lower-price-small',
   'value-price',
   'value-price-small',
   'overstock',
   'overstock-small',
   'text' */
  type: PropTypes.oneOf([
    'clearance',
    'clearance-small',
    'bulk-price',
    'bulk-price-small',
    'lower-price',
    'lower-price-small',
    'value-price',
    'value-price-small',
    'overstock',
    'overstock-small',
    'text'
  ])
};

Badge.defaultProps = {
  children: null,
  className: null,
  color: null,
  text: null,
  type: 'text'
};

export { Badge };