import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import {
  arrayOf,
  bool,
  extend,
  params,
  shape,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { Link } from '@one-thd/sui-atomic-components';

import {
  PodFooter,
  PodSection,
  PodSpacer,
  PodSticker,
  ProductATC,
  ProductBadge,
  ProductImage,
  ProductPod,
  ProductRating,
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';

export const SpecialBuyBannerProductPod = ({
  itemId,
  position,
  shouldHideSeeMoreOptions
}) => {
  const { storeId } = useStore();

  const { data } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  const canonicalUrl = data?.product?.identifiers?.canonicalUrl;

  const seeMoreOptionsClassName = classNames(
    'sui-flex sui-center-items sui-justify-center sui-w-full',
    {
      'sui-invisible': shouldHideSeeMoreOptions,
    },
  );

  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{ parent: 'special-buy-banner-product-pod', position }}
      className="sui-h-full"
      render={(pod) => (
        <>
          <PodSticker position="top-left">
            <ProductBadge itemId={pod.itemId} storeId={pod.storeId} />
          </PodSticker>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
          />

          <PodSpacer padding={['BOTTOM', 'TOP']}>
            <Link className={seeMoreOptionsClassName} href={canonicalUrl}>
              See More Options
            </Link>
          </PodSpacer>

          <PodSection>
            <ProductHeader
              brand="above"
              itemId={pod.itemId}
              titleMaxLine={2}
              brandMaxLine={1}
              disableShopThisCollection
            />
          </PodSection>

          <PodSection columnAlign>
            <ProductRating
              itemId={pod.itemId}
            />
            <Price
              itemId={pod.itemId}
              large={false}
              storeId={pod.storeId}
              onSavingsCenterToggle={pod.onSavingsCenterToggle}
              hideBadge
              hidePromotions
            />
          </PodSection>

          <PodFooter>
            <PodSpacer>
              <ProductATC
                doNotShowChooseYourOptions
                outline
                itemId={pod.itemId}
                storeId={pod.storeId}
              />
            </PodSpacer>
          </PodFooter>
        </>
      )}
    />
  );
};

SpecialBuyBannerProductPod.displayName = 'SpecialBuyBannerProductPod';

SpecialBuyBannerProductPod.dataModel = extend(
  {
    product: params({
      itemId: string().isRequired(),
    }).shape({
      itemId: string(),
      dataSources: string(),
      identifiers: shape({
        canonicalUrl: string(),
      }),
      info: shape({
        swatches: arrayOf(shape({
          isSelected: bool(),
          itemId: string(),
          label: string(),
          swatchImgUrl: string(),
          url: string(),
          value: string(),
        })),
      })
    })
  },
  Price,
  ProductATC,
  ProductBadge,
  ProductHeader,
  ProductImage,
  ProductPod,
  ProductRating,
);

SpecialBuyBannerProductPod.propTypes = {
  itemId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  shouldHideSeeMoreOptions: PropTypes.bool.isRequired,
};
