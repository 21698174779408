import React, { createRef, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  number, string, oneOf, bool, arrayOf
} from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import {
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType,
  params,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Col, Row } from '@thd-olt-component-react/grid';
import { KeyProductFeatureValue } from './KeyProductFeatureValue';
import './key-product-features.scss';

const KeyProductFeatures = (props) => {

  const {
    hideImage,
    hideForProductTypes,
    itemId,
    minfeatures,
    maxfeatures,
    displaySimilarLinks,
    twoColumnGrid,
    oneColumnGrid,
    simple
  } = props;
  const { channel } = useContext(ExperienceContext);

  const getSortedValue = ({ attrGroupList = {} }) => {
    return (attrGroupList.specifications || [])
      .filter((attr) => attr.specName)
      .sort((entryName, compName) => {
        let nameval1 = entryName.specName.toUpperCase();
        let nameval2 = compName.specName.toUpperCase();
        if (nameval1 < nameval2) {
          return -1;
        }
        if (nameval1 > nameval2) {
          return 1;
        }
        return 0;
      });
  };

  const getKpfFromSpecifications = (product) => {
    const { specificationGroup = [] } = product || {};
    const kpfGroups = (specificationGroup || []).filter((group) => [
      'Dimensions',
      'Details'
    ].indexOf(group.specTitle) > -1)
      .map((attrGroupList) => {
        return getSortedValue({ attrGroupList });
      });
    if (!kpfGroups.length) {
      return [];
    }
    return kpfGroups.reduce(function (prev, curr) {
      return prev.concat(curr);
    });
  };

  const displaySimilarItemsLink = (similarRef) => {
    if (channel !== 'mobile' && similarRef) {
      similarRef.classList.add('kpf__specblock--similar-items--visible');
    }
  };

  const hideSimilarItemsLink = (similarRef) => {
    if (channel !== 'mobile' && similarRef) {
      similarRef.classList.remove('kpf__specblock--similar-items--visible');
    }
  };

  const specBlockClasses = classNames('kpf__specblock', {
    'kpf__specblock--simple': simple,
    'kpf__specblock--one-column': oneColumnGrid
  });
  const underlineClasses = classNames('kpf__underline', {
    'kpf__underline--simple': simple
  });
  const nameClasses = classNames('kpf__name', {
    'kpf__name--simple': simple,
    'kpf__name--one-column': simple && oneColumnGrid
  });
  const valueClasses = classNames('kpf__value', {
    'kpf__value--simple': simple,
    'kpf__value--one-column': simple && oneColumnGrid
  });

  const specBlock = (feature) => (
    <div className={specBlockClasses}>
      <div className={underlineClasses} />
      <div className={nameClasses}>{feature.name}</div>
      <div className={valueClasses}>
        <KeyProductFeatureValue value={feature.value} simple={simple} />
      </div>
    </div>
  );

  const similarItemBlock = (feature, ref) => {
    const similarItemsClassName = classNames(
      { 'kpf__specblock--similar-items--visible': displaySimilarLinks && channel === 'mobile' },
      { 'kpf__specblock--similar-items': channel !== 'mobile' }
    );
    return (
      <>
        {specBlock(feature)}
        <div
          className={similarItemsClassName}
          ref={ref}
        >
          <span className="kpf__specblock--links kpf__specblock--decoration">See Similar Items</span>
        </div>
      </>
    );
  };

  const keyProductFeatureSpecs = (featuresAttr) => (

    featuresAttr
      .slice(0, maxfeatures)
      .map((feature, index) => {
        const kpfSimilarItemsRef = createRef();
        const otherGridStyles = twoColumnGrid ? { fallback: '6' } : { fallback: '12', xs: '4', md: '3', sm: '3' };

        return (
          <Col
            key={index}
            className={specBlockClasses}
            flatten={simple}
            onMouseEnter={() => { displaySimilarItemsLink(kpfSimilarItemsRef.current); }}
            onMouseLeave={() => { hideSimilarItemsLink(kpfSimilarItemsRef.current); }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(oneColumnGrid ? { xs: '12' } : otherGridStyles)}
          >
            {feature.refinementUrl && feature.value.length === 1 && displaySimilarLinks ? (
              <a
                className="kpf__specblock--no-decoration"
                href={feature.refinementUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {similarItemBlock(feature, kpfSimilarItemsRef)}
              </a>
            ) : (
              <div className="kpf__specblock--fullwidth">
                {specBlock(feature)}
              </div>
            )}
          </Col>
        );
      })
  );

  /* eslint-disable-next-line react/prop-types */
  const keyProductFeatureContents = ({ url, featuresAttr }) => {
    const specClasses = classNames('kpf__specs', {
      'kpf__specs--simple': simple,
      'kpf__specs--one-column': oneColumnGrid
    });

    const containerClasses = classNames('kpf__container', {
      'kpf__container--simple': simple
    });

    return (
      <Row data-component="KeyProductFeatures">
        <div className={containerClasses}>
          {url && (
            <div className="kpf__image">
              <Image
                src={(url || '').replace('1000', '300')}
                className="kpf__image--size"
                alt="product"
                height="1"
                width="1"
                stretchy
              />
            </div>
          )}
          <div className={specClasses}>
            { keyProductFeatureSpecs(featuresAttr) }
          </div>
        </div>
      </Row>
    );
  };

  const filterAttributeDuplicates = ({ features, product }) => {
    const specAttributes = getKpfFromSpecifications(product)
      .map((spec) => ({
        name: spec.specName,
        value: spec.specValue
      }));
    const result = specAttributes.filter(
      (specNames) => features.find((specName) => specNames.name.includes(specName.name)));
    const specKpfValue = result.filter((specValues) => specValues.value.includes(','));
    return Object.values(features.reduce((filteredAttr, {
      name,
      refinementId,
      refinementUrl,
      value
    }) => {
      let filteredAttributes = filteredAttr;
      if (!filteredAttributes[name]?.modified) {
        filteredAttributes[name] = {
          name,
          refinementId,
          refinementUrl,
          value: [],
          modified: true
        };
      }
      filteredAttributes[name].value.push(value);
      specKpfValue.map((specs) => {
        if (specs.name === filteredAttributes[name].name) {
          filteredAttributes[name].value = specs.value && specs.value.split(',');
        }
        return filteredAttributes[name].value;
      });
      return filteredAttributes;
    }, {}));
  };

  const [kpfData, setKpfData] = useState({
    keyProductFeaturesItems: []
  });

  const { data } = useDataModel('product', {
    variables: { itemId }
  });

  const { product } = data || {};

  const { keyProductFeatures } = product || {};

  useEffect(() => {
    if (keyProductFeatures) {
      setKpfData({
        keyProductFeaturesItems: keyProductFeatures?.keyProductFeaturesItems
      });
    }
  }, [itemId, keyProductFeatures]);

  if (!keyProductFeatures?.keyProductFeaturesItems?.length && !kpfData.keyProductFeaturesItems.length) {
    return null;
  }
  const shouldHide = hideForProductTypes?.includes(product?.info?.productSubType?.name);

  if (shouldHide) {
    return null;
  }

  const keyProductFeaturesItems = keyProductFeatures?.keyProductFeaturesItems || kpfData.keyProductFeaturesItems;
  const features = keyProductFeaturesItems?.[0]?.features || [];

  let featuresAttr = filterAttributeDuplicates({ features, product });

  if (featuresAttr?.length < minfeatures && channel === 'mobile') {
    featuresAttr = getKpfFromSpecifications(product)
      .map((spec) => ({
        name: spec.specName,
        value: spec.specValue
      }));
  }

  if (featuresAttr?.length < minfeatures) {
    return null;
  }

  const url = hideImage ? null : product.media?.image?.url;
  return keyProductFeatureContents({ url, featuresAttr });
};

KeyProductFeatures.displayName = 'KeyProductFeatures';

const specs = arrayType(shapeType({
  specifications: arrayType(shapeType({
    specName: stringType(),
    specValue: stringType()
  })),
  specTitle: stringType()
}));

if (specs.skip) {
  specs.skip('skipSpecificationGroup', false);
}

const keyProductFeaturesModel = shapeType({
  keyProductFeaturesItems: arrayType(shapeType({
    features: arrayType(shapeType({
      name: stringType(),
      refinementId: stringType(),
      refinementUrl: stringType(),
      value: stringType()
    }))
  }))
});

if (keyProductFeaturesModel.skip) {
  keyProductFeaturesModel.skip('skipKPF', false);
}

KeyProductFeatures.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    keyProductFeatures: keyProductFeaturesModel,
    info: shapeType({
      productSubType: shapeType({
        name: stringType()
      })
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(shapeType({
        url: stringType(),
        type: stringType(),
        subType: stringType(),
        sizes: arrayType(stringType())
      }))
    }),
    specificationGroup: specs
  })
};

KeyProductFeatures.propTypes = {
  storeId: string,
  /**
   * Display similar links
   */
  displaySimilarLinks: bool,
  // hide the kpf product image
  hideImage: bool,
  // hide kpf for these Product Types
  hideForProductTypes: arrayOf(string),
  /**
   */
  itemId: string.isRequired,
  /**
   * Max number of features to be displayed (between 1 and 8)
   */
  maxfeatures: oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  /**
   * If API returns less than minfeatures, component should not render
   */
  minfeatures: number,
  /**
   * Show two column version of component
   */
  twoColumnGrid: bool,
  /**
   * Show one column version of component
   */
  oneColumnGrid: bool,
  /**
   * Show simplified and smaller features
   */
  simple: bool
};

KeyProductFeatures.defaultProps = {
  displaySimilarLinks: false,
  hideImage: false,
  hideForProductTypes: [],
  maxfeatures: 8,
  minfeatures: 3,
  twoColumnGrid: false,
  oneColumnGrid: false,
  simple: false,
  storeId: null
};

export { KeyProductFeatures };
