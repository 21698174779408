import React from 'react';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';

export const CurbsideButtonPlaceholder = () => {
  return (
    <div className="sui-mt-2">
      <Skeleton disablePadding>
        <SkeletonBlock height={11} width={60} aspect="wide" />
      </Skeleton>
    </div>
  );
};
