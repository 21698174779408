import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Contains a media (img or picture) element with `square` or `video`
 * [aspect ratio](https://tailwindcss.com/docs/aspect-ratio) to be placed inside a `Card` component.
 *
 * Related components: [Card](#card)
 *
 * Usage:
 *
 * ```jsx
 * import { CardMedia } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardMedia = React.forwardRef((props, ref) => {

  const {
    children,
    component: Component = 'img',
    padding = false,
    src,
    aspect,
    width = 'full',
    ...other
  } = props;

  const classes = classNames('sui-object-cover', {
    'sui-aspect-square': aspect === 'square',
    'sui-aspect-video': aspect === 'wide',
    'sui-p-4': padding,
    'sui-w-min': width === 'min',
    'sui-w-max': width === 'max',
    'sui-w-fit': width === 'fit',
    'sui-w-full': width === 'full'
  });

  return (
    <Component
      className={classes}
      src={src}
      ref={ref}
      {...other}
    >
      {children}
    </Component>
  );
});

CardMedia.displayName = 'CardMedia';

CardMedia.propTypes = {
  /**
   * The aspect ratio to use.
   */
  aspect: PropTypes.oneOf(['square', 'wide']),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element like 'img' or 'picture', or an external custom component.
   * @default 'img'
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the padding is added.
   * @default false
   */
  padding: PropTypes.bool,
  /**
   * The source path for the image if your are using an `img` component
   */
  src: PropTypes.string,
  /**
   * The width based on size of content.
   */
  width: PropTypes.oneOf(['full', 'min', 'max', 'fit'])
};

CardMedia.defaultProps = {
};

export { CardMedia };
