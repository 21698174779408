import {
  arrayOf as arrayOfType,
  client,
  number as numberType,
  shape as shapeType,
  string as stringType,
  bool as boolType,
  params,
} from '@thd-nucleus/data-sources';

export const MessagingContainerDataModel = {
  product: params({
    itemId: stringType().isRequired(),
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    fulfillment: client(params({ storeId: stringType() }).shape({
      fulfillmentOptions: arrayOfType(shapeType({
        fulfillable: boolType(),
        type: stringType(),
        services: arrayOfType(shapeType({
          type: stringType(),
          locations: arrayOfType(shapeType({
            isAnchor: boolType(),
            locationId: stringType(),
            inventory: shapeType({
              isOutOfStock: boolType(),
              quantity: numberType(),
            }),
          })),
        })),
      })),
    })),
  }),
};
