export const buildPath = (node = {}, result = [], attributes = []) => {
  const type = node.type || {};
  const name = type.displayName || type.name || '';
  const pendingProps = node.pendingProps || {};

  let details = attributes.map((key) => {
    if (!pendingProps[key]) return null;
    return `${key}=${pendingProps[key]}`;
  })
    .filter((val) => val)
    .join('&');

  if (details) {
    details = '#' + details;
  }
  if (typeof type === 'string') {
    // real dom element
    result.unshift(`${type}[${node.index}]${details}`);
  } else if (name) {
    result.unshift(`${name}[${node.index}]${details}`);
  }
  if (node.return) {
    return buildPath(node.return, result, attributes);
  }

  return result;
};

export const getDomPath = (ref, { attributes } = {}) => {
  const { current } = ref;
  let path = [];
  if (current && current._reactInternalFiber) {
    // @todo should it use .return initially?
    path = buildPath(current._reactInternalFiber.return);
  } else if (current) {
    const _key = Object.keys(current).find((key) => /^__reactInternalInstance\$/.test(key));
    if (_key) {
      path = buildPath(current[_key], [], attributes);
    }
  }
  return path;
};
