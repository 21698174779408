import React, { useContext } from 'react';
import { PromotionCardContainer as PromotionCardComponent } from '@thd-olt-component-react/promotion-card';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { string } from 'prop-types';

export function PromotionCard({ id }) {
  const { defaultVariables } = useContext(QueryContext);

  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant
      === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <ErrorBoundary name="promotion-card">
      <QueryProvider
        key={id}
        cacheKey="promotion-card"
        defaultVariables={newDefaultVariables}
      >
        <PromotionCardComponent componentId={id} />
      </QueryProvider>
    </ErrorBoundary>
  );
}

PromotionCard.propTypes = {
  id: string.isRequired
};

PromotionCard.defaultProps = {};
