import fetchIdsFromSource from './fetchIdsFromSource';
import { getVariationsFromAdobe } from './getVariationsFromAdobe';
import { getVariationsFromDE } from './getVariationsFromDE';
import { getVariationsFromInnervate } from './getVariationsFromInnervate';
import { getVariationsFromURLParams } from './getVariationsFromURLParams';

const handleVariations = async ({ channel, apiEndpoint, props }) => {
  const {
    mboxes = [],
    campaignIds = [],
    innervateTags = [],
    slug,
    isDebug = false,
    additionalDetails = {},
    localStorageOpts = null
  } = props;

  const hasQueryParams = window.location.search.indexOf('personalizations') !== -1;
  const hasMboxes = mboxes.length > 0;
  const hasCampaignIds = campaignIds.length > 0;
  const hasInnervateTags = innervateTags.length > 0;

  const sources = {
    adobe: {
      handler: getVariationsFromAdobe,
      shouldMakeRequest: hasMboxes,
      data: { mboxes },
      name: 'adobeVariationIds',
    },
    decisionEngine: {
      handler: getVariationsFromDE,
      shouldMakeRequest: hasCampaignIds,
      data: {
        channel,
        apiEndpoint,
        additionalDetails,
        campaignIds,
        localStorageOpts
      },
      name: 'decisionEngineVariationIds',
    },
    innervate: {
      handler: getVariationsFromInnervate,
      shouldMakeRequest: hasInnervateTags,
      data: { innervateTags, isDebug, slug },
      name: 'innervateVariationIds',
    },
    url: {
      handler: getVariationsFromURLParams,
      shouldMakeRequest: hasQueryParams,
      data: {},
      name: 'urlVariationIds'
    }
  };

  const promises = Object.keys(sources).map((key) => fetchIdsFromSource(sources[key]));

  const allVariationResponses = await Promise.allSettled(promises);

  const variationIdsBySource = allVariationResponses
    .filter(({ status }) => status === 'fulfilled')
    .filter(({ value }) => !!value)
    .map(({ value }) => value)
    .reduce((acc, { name, res }) => {
      acc[name] = res;
      return acc;
    }, {});

  return variationIdsBySource;
};

export default handleVariations;
