function cleanStringProperty(obj, property) {
  if (obj[property]) {
    // eslint-disable-next-line no-param-reassign
    obj[property] = obj[property].replace(/\W+/g, '');
  }
}

export function impressionStringRefinement(data, parent) {
  // start of impression checks
  ['name', 'component', 'category'].forEach((property) => cleanStringProperty(data, property));

  // start of container checks
  ['name', 'component', 'category'].forEach((property) => cleanStringProperty(parent, property));

  return { data, parent };
}