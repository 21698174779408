import React from 'react';
import { string as stringType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { AuthorizeCard, BoxTruck, InStorePickup, ShipToHome } from '@one-thd/sui-icons';

export function CardStatusMessage({ orderStatus }) {
  const status = orderStatus || 'Processing';

  const statusIcon = (size = 'small') => {
    const formattedOrderStatus = status.toLowerCase();
    const icons = {
      processing: <AuthorizeCard size={size} />,
      delivered: <ShipToHome size={size} />,
      shipped: <BoxTruck size={size} />,
      'order received': <InStorePickup size={size} />,
    };

    if (icons[formattedOrderStatus]) {
      return icons[formattedOrderStatus];
    }

    return <AuthorizeCard size={size} />;
  };

  return (
    <div className="sui-flex" data-testid="orderStatus">
      <span className="sui-pr-2 sui-flex sui-items-center">
        {statusIcon()}
      </span>
      <Typography variant="body-base">{status}</Typography>
    </div>
  );
}

CardStatusMessage.propTypes = {
  orderStatus: stringType,
};

CardStatusMessage.defaultProps = {
  orderStatus: null,
};
