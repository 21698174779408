import React from 'react';
import { createPortal } from 'react-dom';
import { number, bool } from 'prop-types';
import styles from '../drawer.module.scss';

export const Mask = ({ zIndex, hideMask, openedDrawer }) => {

  if (hideMask || !openedDrawer) return null;

  return createPortal(
    <div
      className={styles['drawer-mask']}
      data-testid="drawer-mask"
      style={{ zIndex: zIndex - 1 }}
      onClick={() => {
        window.LIFE_CYCLE_EVENT_BUS.trigger('drawer.close');
      }}
      role="button"
      aria-label="close the drawer"
      tabIndex={0}
    />,
    document.body
  );
};

Mask.propTypes = {
  zIndex: number.isRequired,
  hideMask: bool.isRequired,
  openedDrawer: bool.isRequired
};
