import * as React from 'react';
import './ProgressTracker.scss';

let progressIconCircle1 = `rotate(${68}deg)`;

export const ProgressTracker = ({ percentage = 60 }) => {
  let progress = 300 - (percentage / 100) * 229;
  let value = 2.8 * percentage - 214;
  progressIconCircle1 = `rotate(${value}deg)`;

  const ActiveCircleProgress = () => {
    return (
      <circle
        className="ProgressPerk__circleProgress circle-animate"
        style={{ strokeDashoffset: progress }}
        cx="57"
        cy="57"
        r="48"
        stroke="url(#red)"
      />
    );
  };

  const ActiveCircleProgressWhite = () => {
    return (
      <circle
        className="circle_border circle-animate"
        style={{ strokeDashoffset: progress }}
        cx="57"
        cy="57"
        r="48"
        stroke="#FFFFFF"
      />
    );
  };

  const ProgressPerk = () => {
    return (
      <div className="ProgressPerk__progress_Main">
        <svg viewBox="0 0 115 115" className="ProgressPerk__svg">
          <circle className="ProgressPerk__circle" cx="57" cy="57" r="48" />
          <circle className="ProgressPerk__circle11" cx="11" cy="46" r="9" />
          <circle className="circleSmallDot" cx="13" cy="38" r="11" />
          <defs>
            <linearGradient id="red" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="100%" stopColor="#f96302" stopOpacity="0.9" />
            </linearGradient>
          </defs>
          {percentage > 0 && <><ActiveCircleProgressWhite /><ActiveCircleProgress /></>}
        </svg>
      </div>
    );
  };

  return <ProgressPerk />;
};
