import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const StockIndicator = createSvgIcon(
  <>
    <path d="M5.25 8.25V4.757C7.523 2.845 9.753 2.25 12 2.25c2.245 0 4.478.595 6.75 2.404V8.25h3v3H24V6h-3V3.608l-.393-.337C17.765.834 14.875 0 12 0 9.12 0 6.22.838 3.371 3.41L3 3.745V6H0v15.75h10.5V19.5H2.25V8.25h3Z" />
    <path d="M15.75 10.5V8.25h-7.5v2.25h7.5Z" />
    <circle data-color="secondary" cx="18.375" cy="18.375" r="5.625" />
  </>,
  'StockIndicator'
);

export { StockIndicator };