import React, { useContext } from 'react';
import { string, bool } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Loader } from '@thd-olt-component-react/core-ui';
import { Carousel } from '@one-thd/sui-carousel';
import { phpDataModel } from '../dataModel';
import { InformationAndCtaPod } from '../information-and-cta-pod/InformationAndCtaPod';
import { dataStore } from '../../php-card-matrix';

export const HomeServicePhpCarousel = () => {
  // for development
  // gets the user cookie
  const THDCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : {};
  const userId = THDCustomer?.svocID || THDCustomer?.userID || THDCustomer?.guestUserID;
  const { channel } = useContext(ExperienceContext) || {};
  const udsQuery = {
    variables: {
      userId,
      appId: channel === 'mobile' ? 'mobileweb' : 'desktop',
      apiName: 'homeservices',
      key: 'aGAQFG4j6QtVTSWqujfFYWeIU6BR5Mee',
    },
    ssr: false,
  };
  const { loading, data } = useDataModel('uds', udsQuery);
  if (loading) {
    return (
      <div>
        <Loader className="sui-top-36 sui-left-36" active />
      </div>
    );
  }
  const carouselContent = data?.uds?.services?.map((service, index) => (
    <div key={service.id}>
      <InformationAndCtaPod service={dataStore[service.id]} id={service.id} position={index + 1} />
    </div>
  ));

  const carouselPreview = {
    slidesPerView: 1,
    slidesPerGroup: 1,
  };

  return (
    <div>
      {carouselContent && carouselContent.length > 0 ? (
        <Carousel
          breakpoints={{
            sm: carouselPreview,
            md: carouselPreview,
            lg: carouselPreview,
            xl: carouselPreview,
          }}
          slidesPerGroup={1}
          slidesPerView={1}
          disableShadow
          disableMargin
        >
          {carouselContent}
        </Carousel>
      ) : (
        <div>
          <InformationAndCtaPod service={dataStore.GENERIC} id="GENERIC" />
        </div>
      )}
    </div>
  );
};
HomeServicePhpCarousel.dataModel = phpDataModel;

HomeServicePhpCarousel.propTypes = {
};

HomeServicePhpCarousel.defaultProps = {
};

HomeServicePhpCarousel.displayName = 'HomeServicePhpCarousel';
