import React, { useContext } from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { useConfigService } from '@thd-nucleus/experience-context';
import { WelcomeCardContext } from '../../../contexts/WelcomeCardContext';
import * as CardUtils from '../../../common/utils/cardUtils';
import { WelcomeCardHeader } from '../WelcomeCardHeader/WelcomeCardHeader';
import { NotificationSection } from '../NotificationSection';
import { WelcomeCardErrorPage } from '../WelcomeCardErrorPage/WelcomeCardErrorPage';
import { getHostCURL } from '../../../common/utils/cardUtils';
import { PromotionSection } from '../PromotionSection';
import * as Constants from '../../../core/Constants';
import { analyticsStash } from '../../../common/utils/AnalyticsUtils';

const analyticsMessage = Constants.analytics.ANALYTICS_MESSAGE;
const analyticsLinks = Constants.analytics.ANALYTICS_LINKS;
const exportsForTesting = {};

const TabletWelcomeCard = (props) => {
  const { isLoading, tiles, userDataError } = useContext(WelcomeCardContext);
  const { handleOnClick } = props;
  const isNewRHEnabled = useConfigService('ENABLE_REWARDS_HUB_PAGE');
  const rhLink = isNewRHEnabled === 1 ? '/myaccount/proXtraRewards' : '/myaccount/proxtraprogramdetails';
  const isAdmin = CardUtils.getUserRole() === 'ADMIN';
  const learnMoreLink = getHostCURL(rhLink);
  const linkText = 'View All Rewards & Benefits';
  const Card = () => {
    return (
      <div className="loyalty-welcome">
        <div className="header-main">
          <div>
            <WelcomeCardHeader />
          </div>
          <div className="header-label">
            <Button
              variant="text"
              href={learnMoreLink}
              fullWidth
              onClick={() => analyticsStash('welcomeCard', analyticsMessage.BOTTOM_LINK, analyticsLinks.VIEW_BENEFITS)}
            >
              {linkText}
            </Button>
          </div>
        </div>
        <div className="notifcation-main">
          {isAdmin ? (
            <NotificationSection tiles={tiles.TABLET} />
          ) : (
            <PromotionSection tiles={tiles.TABLET} />
          )}
        </div>
      </div>
    );
  };

  const RenderTabletWelcomeCard = () => {
    return (
      <div data-component="LoyaltyBenefits">
        {!isLoading && !userDataError ? (
          <Card />
        ) : (
          <WelcomeCardErrorPage handleOnClick={handleOnClick} cardLoaded={!isLoading} />
        )}
      </div>
    );
  };

  exportsForTesting.handleOnClick = handleOnClick;
  exportsForTesting.Card = Card;
  exportsForTesting.RenderTabletWelcomeCard = RenderTabletWelcomeCard;

  return <RenderTabletWelcomeCard />;
};

export { TabletWelcomeCard, exportsForTesting };
