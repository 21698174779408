import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleSecondaryGray = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleSecondaryGray.palette['--sui-palette-background-primary'] = '231 231 231';

export { HouseStyleSecondaryGray };