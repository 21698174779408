import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ButtonBase } from '../button/ButtonBase';
import createTypography from '../utils/createTypography';

/**
 * `Tab` is the interactive element, containing the title or label, that the user clicks or taps
 * to navigate between panels or views.
 *
 * Related components: [Tabs](#tabs), [TabList](#tablist), [TabController](#tabcontroller)
 *
 * Usage:
 *
 * ```jsx
 * import { Tab } from '@one-thd/sui-atomic-components';
 * ```
 */
const Tab = React.forwardRef((props, ref) => {

  const {
    disabled = false,
    fullWidth,
    indicator,
    label,
    onChange,
    onClick,
    onFocus,
    selected,
    selectionFollowsFocus,
    value,
    ...other
  } = props;

  const handleClick = (event) => {
    if (!selected && onChange) {
      onChange(event, value);
    }

    if (onClick) {
      onClick(event);
    }
  };

  const handleFocus = (event) => {
    if (selectionFollowsFocus && !selected && onChange) {
      onChange(event, value);
    }

    if (onFocus) {
      onFocus(event);
    }
  };

  const classes = classNames(
    'sui-tab-base',
    'focus-visible:sui-bg-focus focus-visible:sui-text-primary',
    'enabled:hover:sui-bg-subtle enabled:hover:sui-text-primary', {
      'sui-shrink sui-grow sui-basis-0 sui-max-w-none': fullWidth,
      'sui-text-inactive': disabled
    }
  );

  const typography = createTypography({
    variant: 'body-lg',
    color: selected ? 'primary' : 'subtle',
    weight: selected ? 'bold' : 'regular',
    height: 'tight',
  });

  const tabClasses = classNames(`${classes} ${typography.classes}`);

  return (
    <ButtonBase
      unstyled
      className={tabClasses}
      ref={ref}
      role="tab"
      aria-selected={selected}
      disabled={disabled}
      onClick={handleClick}
      onFocus={handleFocus}
      tabIndex={selected ? 0 : -1}
      {...other}
    >
      {label}
      {indicator}
    </ButtonBase>
  );
});

Tab.displayName = 'Tab';

Tab.propTypes = {
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the tabs will take up the whole width of the container.
   */
  fullWidth: PropTypes.bool,
  /**
   * Used internally to mount the underline indicator when the tabs component is unmounted.
   * @ignore
   */
  indicator: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.element]),
  /**
   * The text that goes inside the tab.
   */
  label: PropTypes.string,
  /**
   * Callback fired when a change happens in the component.
   */
  onChange: PropTypes.func,
  /**
   * Callback fired when the component is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Callback fired when the component receives focus
   */
  onFocus: PropTypes.func,
  /**
   * Used internally to signal the selected state to the tab component.
   * @ignore
   */
  selected: PropTypes.bool,
  /**
   * Used internally to indicate if the onChange function should be called on focus as well.
   * @ignore
   */
  selectionFollowsFocus: PropTypes.bool,
  /**
   * The value assigned to this tab.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tab.defaultProps = {
};

export { Tab };