import React, { useCallback, useState, useContext } from 'react';
import PropTypes, {
  arrayOf,
  bool,
  func,
  oneOf,
  shape,
  string,
} from 'prop-types';
import { extend, QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { PromoMessageProvider } from '../contexts/PromoMessageProvider';
import { PromoModelProvider } from '../contexts/PromoModelProvider';
import { PromoCartProvider } from '../contexts/PromoCartProvider';
import { PromoPresentationProvider } from '../contexts/PromoPresentationProvider';
import { CallToAction } from './CallToAction/CallToAction';
import { PromoDrawer } from './PromoDrawer/PromoDrawer';
import { PromotionProductsDataModel } from '../models/PromotionProductsDataModel';
import './PromotionProducts.scss';

const HoistingWrapper = ({ children }) => <>{children}</>;
HoistingWrapper.propTypes = { children: PropTypes.node.isRequired };
HoistingWrapper.dataModel = PromotionProductsDataModel;

export const PromotionProducts = ({
  anchorItem,
  experienceTag,
  subExperienceTag,
  isMultiSelect,
  maAvailability,
  type,
  onFail
}) => {
  const storeId = useStoreId();
  const [renderPromoDrawer, setRenderPromoDrawer] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSummaryDrawerOpen, setIsSummaryDrawerOpen] = useState(false);
  const [isSummaryPopoverOpen, setIsSummaryPopoverOpen] = useState(false);
  const { defaultVariables } = useContext(QueryContext);

  const loadRewardDrawer = useCallback(() => {
    if (
      !hasUserInteracted
      || (isSummaryPopoverOpen ? (isSummaryDrawerOpen && !renderPromoDrawer) : !renderPromoDrawer)
    ) {
      setHasUserInteracted(true);
      setRenderPromoDrawer(true);
    }
  }, [hasUserInteracted, renderPromoDrawer, isSummaryDrawerOpen, isSummaryPopoverOpen]);

  const pricingVariables = {
    dataSource: 'searchNav',
    storeId,
  };
  // for map policy compliance
  if (typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean') {
    pricingVariables.isBrandPricingPolicyCompliant = defaultVariables?.current?.isBrandPricingPolicyCompliant;
  }

  return (
    <QueryProvider
      cacheKey="promotion-products"
      defaultVariables={pricingVariables}
    >
      <HoistingWrapper>
        <PromoModelProvider
          anchorItem={anchorItem}
          hasUserInteracted={hasUserInteracted}
          experienceTag={experienceTag}
          subExperienceTag={subExperienceTag}
          isMultiSelect={isMultiSelect}
          maAvailability={maAvailability}
          type={type}
          onFail={onFail}
        >
          <PromoCartProvider
            maAvailability={maAvailability}
            hasUserInteracted={hasUserInteracted}
            isDrawerOpen={isDrawerOpen}
            setHasUserInteracted={setHasUserInteracted}
            type={type}
          >
            <PromoPresentationProvider
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              isSummaryDrawerOpen={isSummaryDrawerOpen}
              setIsSummaryDrawerOpen={setIsSummaryDrawerOpen}
              type={type}
              isSummaryPopoverOpen={isSummaryPopoverOpen}
              setIsSummaryPopoverOpen={setIsSummaryPopoverOpen}
            >
              <PromoMessageProvider>
                <CallToAction onLoad={loadRewardDrawer} type={type} />
                {renderPromoDrawer && <PromoDrawer />}
              </PromoMessageProvider>
            </PromoPresentationProvider>
          </PromoCartProvider>
        </PromoModelProvider>
      </HoistingWrapper>
    </QueryProvider>
  );
};

PromotionProducts.displayName = 'PromotionProducts';

PromotionProducts.propTypes = {
  anchorItem: shape({
    itemId: string.isRequired,
    identifiers: shape({
      productType: string, // to identify MAJOR_APPLIANCE anchor
      canonicalUrl: string,
    }),
    pricing: shape({
      conditionalPromotions: arrayOf(shape({
        brandRefinementId: string, // for nvalue promos
      })),
    }),
  }),
  experienceTag: string,
  subExperienceTag: string,
  isMultiSelect: bool,
  maAvailability: shape({
    isAvailable: bool.isRequired,
    isLoading: bool.isRequired,
    applianceDeliveryStore: string,
  }),
  /** Decides the type of UI to render */
  type: oneOf(['card', 'pod']),
  /** callback invoked if data is not displayable */
  onFail: func,
};

PromotionProducts.defaultProps = {
  anchorItem: {
    itemId: null,
  },
  experienceTag: null,
  subExperienceTag: null,
  isMultiSelect: false,
  maAvailability: null,
  type: 'pod',
  onFail: null,
};

PromotionProducts.dataModel = extend(HoistingWrapper);
