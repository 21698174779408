import React from 'react';
import { bool } from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import { useSpecialBuy } from '../../context/SpecialBuyProvider';

const SpecialBuyNavButton = ({ isFullWidth }) => {
  const { specialBuyType, isFallbackBanner, specialBuyEndpoint } = useSpecialBuy();

  return (
    <div>
      <Button
        variant="primary"
        href={specialBuyEndpoint}
        fullWidth={isFullWidth}
      >
        {isFallbackBanner && <>Shop&nbsp;All&nbsp;Savings</>}
        {!isFallbackBanner && (
          <>
            {(specialBuyType === 'sbotd') && <>Shop&nbsp;Today&apos;s&nbsp;Specials</>}
            {(specialBuyType === 'decor') && <>Shop&nbsp;Decor&nbsp;Specials</>}
            {(specialBuyType === 'pro') && <>Shop&nbsp;Pro&nbsp;Specials</>}
          </>
        )}
      </Button>
    </div>
  );
};

SpecialBuyNavButton.displayName = 'SpecialBuyNavButton';

SpecialBuyNavButton.propTypes = {
  isFullWidth: bool,
};

SpecialBuyNavButton.defaultProps = {
  isFullWidth: false,
};

export { SpecialBuyNavButton };