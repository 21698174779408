const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len

const KNOWN_TYPES = Object.freeze({
  email: {
    validator: (email) => emailRE.test(String(email)),
    header: 'Enter your email:',
    errorMessage: 'Please format your email as yourname@email.com',
    placeholder: 'yourname@email.com'
  }
});

const getDefaultValue = (inputType, propertyType) => (
  KNOWN_TYPES[inputType]?.[propertyType] || null
);

const getValue = (propertyType, inputType, propValue) => (
  ['string', 'function'].includes(typeof propValue)
    ? propValue
    : getDefaultValue(inputType, propertyType)
);

export const STANDARD_TYPES = Object.keys(KNOWN_TYPES);
export const getValidator = (type, value) => getValue('validator', type, value);
export const getErrorMessage = (type, value) => getValue('errorMessage', type, value);
export const getHeader = (type, value) => getValue('header', type, value);
export const getPlaceholder = (type, value) => getValue('placeholder', type, value);
