import React, { useEffect, useState, useCallback } from 'react';
import {
  bool, oneOf, oneOfType, node, element, number, string, object, arrayOf
} from 'prop-types';

import * as placeholders from './placeholders';
import './loading-placeholder.scss';

export const LoadingPlaceholder = ({
  children,
  delay,
  ready,
  firstLaunchOnly,
  type,
  rows,
  color,
  showLoadingAnimation,
  customPlaceholder,
  className,
  style,
  isMobile,
  widths,
  lineSpacing,
  maxHeight,
}) => {
  let timeout = null;
  const [isReady, setIsReady] = useState(ready);

  const setReady = () => {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    setIsReady(true);
  };

  const setNotReady = () => {
    if (firstLaunchOnly) return;

    if (typeof delay === 'number' && delay > 0) {
      timeout = window.setTimeout(() => {
        setIsReady(false);
      }, delay);
    } else {
      setIsReady(false);
    }
  };

  const getFiller = useCallback(() => {
    const classes = showLoadingAnimation
      ? ['loading-placeholder', className].filter((clss) => clss).join(' ')
      : className;

    if (customPlaceholder && React.isValidElement(customPlaceholder)) {
      const mergedCustomClasses = [
        // eslint-disable-next-line react/prop-types
        customPlaceholder.props.className,
        classes
      ].filter((clss) => clss).join(' ');
      return React.cloneElement(customPlaceholder, { className: mergedCustomClasses });
    }

    if (customPlaceholder) {
      return customPlaceholder;
    }

    const Placeholder = placeholders[type];

    return (
      <Placeholder
        rows={rows}
        color={color}
        className={classes}
        style={style}
        isMobile={isMobile}
        widths={widths}
        lineSpacing={lineSpacing}
        maxHeight={maxHeight}
      />
    );
  }, [showLoadingAnimation, className, customPlaceholder]);

  useEffect(() => {
    if (ready) setReady();
    else setNotReady();
  }, [ready]);

  return isReady ? children : getFiller();
};

LoadingPlaceholder.propTypes = {
  children: oneOfType([node, element]).isRequired,
  /** delay in millis to wait when passing from ready to NOT ready */
  delay: number,
  /** pass `true` when the content is ready and `false` when it's loading */
  ready: bool.isRequired,
  firstLaunchOnly: bool,
  /** type of placeholder to use */
  type: oneOf(['text', 'media', 'mediaGallery', 'textRow', 'rect', 'round']),
  /** number of rows displayed in 'media' and 'text' placeholders */
  rows: number,
  /** color of the placeholder */
  color: string,
  /** pass true to show a nice loading animation on the placeholder */
  showLoadingAnimation: bool,
  /** pass any renderable content to be used as placeholder instead of the built-in ones */
  customPlaceholder: oneOfType([node, element]),
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  isMobile: bool,
  widths: arrayOf(number),
  lineSpacing: oneOfType([
    string,
    number
  ]),
  maxHeight: oneOfType([
    string,
    number
  ]),
};

LoadingPlaceholder.defaultProps = {
  delay: 0,
  type: 'text',
  color: '#CDCDCD',
  rows: 1,
  firstLaunchOnly: false,
  showLoadingAnimation: false,
  customPlaceholder: null,
  className: '',
  style: {},
  isMobile: false,
  widths: [97, 100, 94, 90, 98, 95, 98, 40],
  lineSpacing: '.7em',
  maxHeight: '',
};
