import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { DeliverFromStore } from './DeliveryFromStore.component';

class ParcelLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCountDownTimer: true
    };
  }

  render() {
    const {
      shipModes,
      dynamicETA
    } = this.props;

    const { displayCountDownTimer } = this.state;

    const hideCountdownTimer = () => {
      this.setState({
        displayCountDownTimer: false
      });
    };

    return (
      <>
        <div className="serviceLevel_A shippingTooltip bodfsAsServiceLevel">
          <div className="columnHeaders">
            <h3 className="left">Estimated Arrival</h3>
            <h3 className="right">Estimated Cost</h3>
          </div>

          {shipModes.length > 0
            && (
              shipModes.map((shipMode, index) => {
                const datePriceClass = classNames({
                  datePriceRow: true,
                  dynamicEta: shipMode.orderByMessage
                });
                const shipModeClass = classNames({
                  shipModeRow: true,
                  dynamicEta: shipMode.orderByMessage
                });
                if (shipMode.isBodfsShipMode) {
                  return (
                    <DeliverFromStore
                      key={index}
                      isDeliveryTimelineTodayOrTomorrow={shipMode.isDeliveryTimelineTodayOrTomorrow}
                      shipCharge={shipMode.shipCharge}
                      deliveryDateAvailability={shipMode.deliveryDateAvailability}
                    />
                  );
                }
                return (
                  <div key={index}>
                    <div className={datePriceClass}>
                      <span className="left date dots">{shipMode.arrivalDate}</span>
                      <span className="right price">
                        {shipMode.isFreeShipping
                          && (
                            <span className="free_shipping">FREE</span>
                          )}
                        {!shipMode.isFreeShipping
                          && (
                            <span>{shipMode.shipCharge}</span>
                          )}
                      </span>
                    </div>
                    {shipMode.bbOrderByMessage
                      && (
                        <div className="hide bbOrderByMessage">
                          {shipMode.bbOrderByMessage}
                        </div>
                      )}
                    <div className={shipModeClass}>
                      <div className="shipmentModeDescription">{shipMode.description}</div>
                      {!shipMode.isFreeShipping
                        && shipMode.isFreeShippingEligible
                        && (
                          <div className="freeWithMessage">(Or {shipMode.freeShipEligibleMsg})</div>
                        )}
                      {dynamicETA.hasCountdownTimer
                        && displayCountDownTimer
                        && (
                          <div className="orderByMessage countDownTimer-wrapper">
                            <span className="delivery-options-countdown">If ordered within </span>
                            <CountdownTimer
                              classes="countdown-timer__wrapper--modifier"
                              hours={dynamicETA.totalHours} // eslint-disable-line react/prop-types
                              minutes={dynamicETA.totalMinutes} // eslint-disable-line react/prop-types
                              onRemove={hideCountdownTimer}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                );
              })
            )}
          <div className="select_in_checkout">
            Finalize your delivery options in checkout
          </div>
        </div>
      </>
    );
  }
}

ParcelLayout.propTypes = {
  dynamicETA: PropTypes.shape({
    hasCountdownTimer: PropTypes.bool
  }).isRequired,
  shipModes: PropTypes.instanceOf(Array).isRequired,
};

export default ParcelLayout;
