import React from 'react';
import { string, bool, shape } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { SponsoredBanner as SponsoredBannerComponent } from '@thd-olt-component-react/sponsored-content';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

export function SponsoredBanner(props) {
  const {
    id,
    schema,
    clickthruUrl,
    imgDesktop,
    imgMobile,
    specialDefaultBanner,
  } = props;

  const thdCustomer = useThdCustomer();
  const contentfulDefaultBanner = {
    clickthruUrl,
    imgDesktop,
    imgMobile,
    specialDefaultBanner
  };

  return (
    <ErrorBoundary name="sponsored-banner">
      <QueryProvider cacheKey={id}>
        {/**
         * @TODO
         * Refactor `app.scss` to use CSS Modules.
         */}

        <div className="sui-w-full">
          <SponsoredBannerComponent
            browserId={thdCustomer?.mcvisID || ''}
            schema={schema}
            contentfulDefaultBanner={contentfulDefaultBanner}
          />
        </div>

      </QueryProvider>
    </ErrorBoundary>
  );
}

SponsoredBanner.propTypes = {
  id: string,
  schema: string,
  clickthruUrl: string,
  imgDesktop: string,
  imgMobile: string,
  specialDefaultBanner: shape({
    clickthruUrl: string,
    imgDesktop: string,
    imgMobile: string,
    useSpecialBanner: bool,
  })
};

SponsoredBanner.defaultProps = {
  id: undefined,
  schema: undefined,
  clickthruUrl: undefined,
  imgDesktop: undefined,
  imgMobile: undefined,
  specialDefaultBanner: {},
};
