import { createTheme } from '@one-thd/sui-atomic-components';

const PrimaryDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

PrimaryDarkTheme.palette['--sui-palette-background-primary'] = '20 20 20';

export { PrimaryDarkTheme };