import {
  getIframeAnchorTags,
  getUrlParams,
  onClickMetricsHandler,
  onTriggerNewRelicAdBlocked,
  triggerNewRelic,
  triggerNewRelicTimeLog
} from './helpers';
import { htmlAttributes, newRelicConstants } from '../constants';
import { initHeroImpressionWatchers } from './initImpressionWatchers';
import { adServerClients } from '../constants/constants';
import { determineAdServer } from './utils';

const defaultRevJetBannerConfig = {
  leaderboard: {
    tag: 'tag233656',
    key: '924'
  },
  skyscraper: {
    tag: 'tag170885',
    key: 'aec'
  },
  middle_desktop: {
    tag: 'tag179445',
    key: '6ec'
  },
  middle_mobile: {
    tag: 'tag179446',
    key: 'd0e'
  },
  ingrid_desktop: {
    tag: 'tag98547',
    key: '82a'
  },
  ingrid_mobile: {
    tag: 'tag98548',
    key: 'dce'
  },
  ingrid_mobile_app: {
    tag: 'tag98549',
    key: 'deb'
  },
  desktop: {
    tag: 'tag98547',
    key: '82a'
  },
  mobile: {
    tag: 'tag98548',
    key: 'dce'
  },
  hero_desktop: {
    tag: 'tag138278',
    key: 'cd8'
  },
  hero_mobile: {
    tag: 'tag154612',
    key: '82e'
  },
  hero_pro_desktop: {
    tag: 'tag222327',
    key: '2e2'
  },
  hero_pro_mobile: {
    tag: 'tag222328',
    key: '740'
  },
};

const bannersWithMigratedImpressionLogic = ['skyscraper', 'standard', 'hero', 'middle', 'ingrid', 'leaderboard'];

function getRevJetConfig(requestConfig) {
  let revJetBannerConfig = defaultRevJetBannerConfig;
  switch (requestConfig.bannerType) {
  case 'leaderboard':
    return revJetBannerConfig.leaderboard;
  case 'skyscraper':
    return revJetBannerConfig.skyscraper;
  case 'middle':
    if (requestConfig.experienceContext.channel === 'desktop') {
      return revJetBannerConfig.middle_desktop;
    }
    return revJetBannerConfig.middle_mobile;
  case 'ingrid':
    if (requestConfig.experienceContext.channel === 'desktop') {
      return revJetBannerConfig.ingrid_desktop;
    } if (requestConfig.experienceContext.isConsumerApp) {
      return revJetBannerConfig.ingrid_mobile_app;
    }
    return revJetBannerConfig.ingrid_mobile;
  case 'hero':
    if (requestConfig.experienceContext.customer.type === 'b2b') {
      if (requestConfig.experienceContext.channel === 'desktop' || window.innerWidth > 639) {
        return revJetBannerConfig.hero_pro_desktop;
      }
      return revJetBannerConfig.hero_pro_mobile;
    }
    if (requestConfig.experienceContext.channel === 'desktop' || window.innerWidth > 767) {
      return revJetBannerConfig.hero_desktop;
    }
    return revJetBannerConfig.hero_mobile;
  default:
    return revJetBannerConfig[requestConfig.experienceContext.channel];
  }
}

function getPubRef(data) {
  const params = getUrlParams(data.banner.bannerURL);
  return decodeURIComponent(params.pub_ref);
}

function getCreativeId(bannerURL) {
  let creativeId = '';

  if (bannerURL && bannerURL.indexOf('cdn.revjet.com') === -1) {
    const anchorElement = document.createElement('a');
    anchorElement.href = bannerURL;
    creativeId = anchorElement.pathname.split('-')[1];
  }

  return creativeId;
}

function getItcParams({ banner = {} }) {

  return {
    campaignId: banner.campaignId ? banner.campaignId : '',
    // banner api maps kevel kevel slotId to placement id
    placementId: banner.placementId ? banner.placementId : '',
    // banner api maps kevel zone id to slot id
    slotId: banner.slotId ? banner.slotId : '',
    creativeId: getCreativeId(banner.bannerURL)
  };
}

function getTag({ channelConfig, data }) {
  const params = getUrlParams(window.location.href);
  let tag = '';

  if (params.mockSponsoredBannerId) {
    tag = channelConfig.tag + '-' + params.mockSponsoredBannerId;
  } else if (params.useSponsoredMock) {
    tag = channelConfig.tag + '-' + getCreativeId(data.banner.bannerURL);
  } else {
    tag = channelConfig.tag;
  }

  return tag;
}

function getFrameConfig({ data, requestConfig }) {

  const channelConfig = getRevJetConfig(requestConfig);
  const itcParams = getItcParams(data);
  // if disableSSR is true
  // channelConfig will be undefined
  if (!channelConfig?.key) {
    return null;
  }
  // returns true if flightId is present
  const isKevel = !!data?.banner?.flightId;

  return {
    thd_itc_suffix: true,
    _tag: getTag({ channelConfig, data, requestConfig }),
    _key: channelConfig.key,

    ct_url: data?.banner?.onClickBeacon,
    piq_slot_id: isKevel ? null : itcParams.slotId,
    piq_campaign_id: isKevel ? null : itcParams.campaignId,
    piq_placement_id: isKevel ? null : itcParams.placementId,
    // kevel specific params
    kevel_zone_id: isKevel ? itcParams.slotId : null,
    kevel_site_id: isKevel ? itcParams.placementId : null,
    kevel_campaign_id: isKevel ? itcParams.campaignId : null,
    kevel_flight_id: isKevel ? data?.banner?.flightId : null,
    _opts: {
      embd_tag_id: requestConfig.schemaId,
      autoscale: true,
      // remove b64 once we fully use vantage for creatives

      b64: getPubRef(data)
    }
  };
}

function isNormalBanner({ bannerType = 'standard' }) {
  return bannerType === 'standard';
}

function isSkyscraperBanner(requestConfig) {
  return requestConfig.bannerType === 'skyscraper';
}

function isLeaderboardBanner(requestConfig) {
  return requestConfig.bannerType === 'leaderboard';
}

function isPIPBanner(requestConfig) {
  return requestConfig.bannerType === 'middle';
}

function isInGridBanner(requestConfig) {
  return requestConfig.bannerType === 'ingrid';
}

function isBannerWithMigratedImpressionLogic(requestConfig) {
  return bannersWithMigratedImpressionLogic.includes(requestConfig.bannerType)
    || requestConfig.bannerType === undefined;
}

function frameLoad({ frameConfig, data, requestConfig, invScriptTime }) {
  const bannerDomElement = window.document.getElementById(frameConfig._opts.embd_tag_id);

  if (bannerDomElement) {
    let impressionBeaconAddTime;
    if (isNormalBanner(requestConfig)) {
      try {
        const carousel = bannerDomElement.closest('.carousel');
        const next = carousel.querySelector('.carousel__next');
        // next.click be gone once placeholder is implemented
        /* istanbul ignore next */
        next.click();
      } catch (error) { /* */ }
    }

    if (bannerDomElement.id === 'hp_sponsored__hero') {
      initHeroImpressionWatchers();
    }

    const itcParams = getItcParams(data);

    const adServer = determineAdServer(data);

    // Remove from previous banner
    bannerDomElement.removeAttribute('data-hl-beacon');
    bannerDomElement.setAttribute(htmlAttributes.DATA_CAMPAIGN_ID, itcParams.campaignId);
    bannerDomElement.setAttribute(htmlAttributes.DATA_PLACEMENT_ID, itcParams.placementId);
    bannerDomElement.setAttribute(htmlAttributes.DATA_SLOT_ID, itcParams.slotId);
    bannerDomElement.setAttribute(htmlAttributes.DATA_CREATIVE_ID, itcParams.creativeId);
    bannerDomElement.setAttribute('data-usenewimpressionlogic', true);
    bannerDomElement.setAttribute(htmlAttributes.DATA_ADSERVER, adServer);
    bannerDomElement.setAttribute(htmlAttributes.DATA_FLIGHT_ID, data?.banner?.flightId);
    if (adServer === adServerClients.KEVEL) {
      bannerDomElement.setAttribute(htmlAttributes.DATA_ON_VIEW_CONVERSION_BEACON, data.banner.viewConversionBeacon);
      bannerDomElement.setAttribute(htmlAttributes.DATA_MCVISID, requestConfig.mcvisID);

    }

    let viewBeacon = data.banner.onViewBeacon;
    viewBeacon = viewBeacon ? viewBeacon.replace('https:', '') : '';

    if (isBannerWithMigratedImpressionLogic(requestConfig)) {
      bannerDomElement.setAttribute('onview-beacon-nucleus', viewBeacon);
      impressionBeaconAddTime = Date.now();
    } else {
      bannerDomElement.setAttribute('onview-beacon', viewBeacon);
    }

    if (isSkyscraperBanner(requestConfig)) {
      triggerNewRelic(newRelicConstants.SKYSCRAPER_BANNER, newRelicConstants.LOAD);
      const skyscraperBannerLoadTime = Date.now();
      // SEND TIME FROM BANNER INITIATED TO AD LOAD
      triggerNewRelicTimeLog(newRelicConstants.SKYSCRAPER_BANNER_AD_LOAD, requestConfig.startTime, skyscraperBannerLoadTime, adServer);
      // SEND TIME FROM INNERVATE SCRIPT INJECTION
      triggerNewRelicTimeLog(newRelicConstants.SKYSCRAPER_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD, invScriptTime,
        skyscraperBannerLoadTime, adServer);

      // SET BANNER INIT TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP, requestConfig.startTime);
      // SET AD LOAD TIMESTAMP ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP, skyscraperBannerLoadTime);
      // SET INV SCRIPT INJECT TIME
      bannerDomElement.setAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP, invScriptTime);
      // SET BEACON ADD TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BEACON_ADD_TIME, impressionBeaconAddTime);
    }

    if (isLeaderboardBanner(requestConfig)) {
      triggerNewRelic(newRelicConstants.LEADERBOARD_BANNER, newRelicConstants.LOAD);
    }

    if (isPIPBanner(requestConfig)) {
      const pipBannerLoadTime = Date.now();
      triggerNewRelic(newRelicConstants.PIP_BANNER, newRelicConstants.LOAD);
      // SEND TIME FROM BANNER INITIATED TO AD LOAD
      triggerNewRelicTimeLog(newRelicConstants.PIP_BANNER_AD_LOAD, requestConfig.startTime, pipBannerLoadTime, adServer);
      // SEND TIME FROM INNERVATE SCRIPT INJECTION
      triggerNewRelicTimeLog(newRelicConstants.PIP_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD, invScriptTime,
        pipBannerLoadTime, adServer);

      // SET BANNER INIT TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP, requestConfig.startTime);
      // SET AD LOAD TIMESTAMP ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP, pipBannerLoadTime);
      // SET INV SCRIPT INJECT TIME
      bannerDomElement.setAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP, invScriptTime);
      // SET BEACON ADD TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BEACON_ADD_TIME, impressionBeaconAddTime);
    }

    if (isInGridBanner(requestConfig)) {
      const ingridBannerLoadTime = Date.now();
      triggerNewRelic(newRelicConstants.INGRID_BANNER, newRelicConstants.LOAD);
      // SEND TIME FROM BANNER INITIATED TO AD LOAD
      triggerNewRelicTimeLog(newRelicConstants.INGRID_BANNER_AD_LOAD, requestConfig.startTime, ingridBannerLoadTime, adServer);
      // SEND TIME FROM INNERVATE SCRIPT INJECTION
      triggerNewRelicTimeLog(newRelicConstants.INGRID_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD, invScriptTime,
        ingridBannerLoadTime, adServer);
      // SET BANNER INIT TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP, requestConfig.startTime);
      // SET AD LOAD TIMESTAMP ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP, ingridBannerLoadTime);
      // SET INV SCRIPT INJECT TIME
      bannerDomElement.setAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP, invScriptTime);
      // SET BEACON ADD TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BEACON_ADD_TIME, impressionBeaconAddTime);
    }

    // Wait until the iframe content is loaded to add the listeners
    setTimeout(() => {
      const skyscraperAnchorTags = getIframeAnchorTags('skyscraper');
      onClickMetricsHandler('skyscraper', skyscraperAnchorTags, {
        mcvisID: requestConfig.mcvisID,
        conversionBeacon: data.banner.clickConversionBeacon
      });

      const sponsoredBannerAnchorTags = getIframeAnchorTags('sponsored__banner');
      onClickMetricsHandler('sponsored__banner', sponsoredBannerAnchorTags, {
        mcvisID: requestConfig.mcvisID,
        conversionBeacon: data.banner.clickConversionBeacon
      });

      const leaderboardAnchorTags = getIframeAnchorTags('leaderboard');
      onClickMetricsHandler('leaderboard', leaderboardAnchorTags);

      const pipAnchorTags = getIframeAnchorTags('pip');
      onClickMetricsHandler('middle', pipAnchorTags, {
        mcvisID: requestConfig.mcvisID,
        conversionBeacon: data.banner.clickConversionBeacon
      });

      const ingridAnchorTags = getIframeAnchorTags('ingrid');
      onClickMetricsHandler('ingrid', ingridAnchorTags, {
        mcvisID: requestConfig.mcvisID,
        conversionBeacon: data.banner.clickConversionBeacon
      });

      const heroAnchorTags = getIframeAnchorTags('hero');
      onClickMetricsHandler('hero', heroAnchorTags);
    }, 500);

    if (isNormalBanner(requestConfig)) {
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scrollTo(window.scrollX, window.scrollY + 1);
      const standardBannerLoadTime = Date.now();
      // SEND TIME FROM BANNER INITIATED TO AD LOAD
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_LOAD, requestConfig.startTime, standardBannerLoadTime, adServer);
      // SEND TIME FROM INNERVATE SCRIPT INJECTION
      triggerNewRelicTimeLog(newRelicConstants.STANDARD_BANNER_AD_INNERVATE_SCRIPT_TO_LOAD, invScriptTime,
        standardBannerLoadTime, adServer);

      // SET BANNER INIT TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BANNER_INIT_TIMESTAMP, requestConfig.startTime);
      // SET AD LOAD TIMESTAMP ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_AD_LOAD_TIMESTAMP, standardBannerLoadTime);
      // SET INV SCRIPT INJECT TIME
      bannerDomElement.setAttribute(htmlAttributes.DATA_INV_SCRIPT_TIMESTAMP, invScriptTime);
      // SET BEACON ADD TIME ON ELEMENT
      bannerDomElement.setAttribute(htmlAttributes.DATA_BEACON_ADD_TIME, impressionBeaconAddTime);

      if (window.newrelic && !window.__SponsoredBannerLoaded__) {
        // Dont fire more than once
        window.__SponsoredBannerLoaded__ = true;
        triggerNewRelic(newRelicConstants.STANDARD_BANNER, newRelicConstants.LOAD);
      }
    }
  }
}

let heroTagRef;

function renderFrame({ data, requestConfig, invScriptTime }) {
  if (data) {
    const frameConfig = getFrameConfig({ data, requestConfig });
    // if we dont have frameConfig we dont have all the data to render revjet
    if (frameConfig) {
      frameConfig._opts.onload = () => frameLoad({ frameConfig, data, requestConfig, invScriptTime });
      if (requestConfig.bannerType === 'hero') {
        heroTagRef = window.REVJET_Tag(frameConfig);
      } else {
        window.REVJET_Tag(frameConfig);
      }
    }

  }
}

function injectRevJetScript({ data, requestConfig, adType }) {
  let invScriptTime = '';
  const revJetScript = document.createElement('script');
  revJetScript.onerror = () => onTriggerNewRelicAdBlocked(adType);
  revJetScript.onload = () => renderFrame({ data, requestConfig, invScriptTime });
  revJetScript.async = true;
  revJetScript.src = 'https://rma.homedepot.com/bg';

  const firstScript = document.getElementsByTagName('body')[0];
  firstScript.parentNode.insertBefore(revJetScript, firstScript);

  // INNERVATE SCRIPT INJECTION START TIME
  const innervateScriptAddedTime = new Date().getTime();
  invScriptTime = innervateScriptAddedTime;
}

function getMockResponse(requestConfig) {
  const { mockSponsoredBannerId } = getUrlParams(window.location.href);
  let revJetConfig = getRevJetConfig(requestConfig);
  let mockRevJetUrl = `http://ads.revjet.com/${revJetConfig.tag}-${mockSponsoredBannerId}`;
  mockRevJetUrl += `?adkey=${revJetConfig.key}&pub_ref=mock`;

  return {
    banner: {
      bannerURL: mockRevJetUrl,
      clickthruURL: 'https://www.homedepot.com/',
      /* eslint-disable-next-line */
      onViewBeacon: 'https://ad-thd-east.tagdelivery.com/impression?id=58c1e65f-1347-4a8e-974e-d1a38c2ff351&x=PQBSrMU8RPJZkBbOrjDSc18Q1ayQXTSmIeTxihTSx1y9TAuGAWAYT6d0LVZ7PodtSlSjEGL3wuSCkAhce75zYtd-2sqdeq-ApKqECLNtI40rBpnEOi566Og3XfUuZsHu0-15T3v3fSc-a7BXv68-EExUCyJFjDF7FlBAnzs1ztKoQWtu2VHTbbD6FKqRRGYRFSG7urhW1pbYdsJ-XPIdtg-L9I-O_rEEcfuW_cPu2-r7wbll5brpZCzuLnnSX5ssi-JC7-an2ySXhP26YTjmLySo4v5i7-KmTyDrmhWLWShNRqc3V43yMUQszMuiaffUgej1g-fhSG6wGdKRbQNexLFce3CmTg_wurbZat-PyhTk-i2D4AJc4qXbxoJvQO3LoIkN0kIMiQMFfuv2jn_7h3-GyF7knjxDEYzBAf6rNtc%3D',
      /* eslint-disable-next-line */
      onClickBeacon: 'https://ad-thd-east.tagdelivery.com/click?id=58c1e65f-1347-4a8e-974e-d1a38c2ff351&x=PQBSrMU8RPJZkBbOrjDSc18Q1ayQXTSmIeTxihTSx1y9TAuGAWAYT6d0LVZ7PodtSlSjEGL3wuSCkAhce75zYtd-2sqdeq-ApKqECLNtI40rBpnEOi566Og3XfUuZsHu0-15T3v3fSc-a7BXv68-EExUCyJFjDF7FlBAnzs1ztKoQWtu2VHTbbD6FKqRRGYRFSG7urhW1pbYdsJ-XPIdtg-L9I-O_rEEcfuW_cPu2-r7wbll5brpZCzuLnnSX5ssi-JC7-an2ySXhP26YTjmLySo4v5i7-KmTyDrmhWLWShNRqc3V43yMUQszMuiaffUgej1g-fhSG6wGdKRbQNexLFce3CmTg_wurbZat-PyhTk-i2D4AJc4qXbxoJvQO3LoIkN0kIMiQMFfuv2jn_7h3-GyF7knjxDEYzBAf6rNtc%3D&redirect=',
      slotId: '1123',
      campaignId: 'abc123',
      placementId: 'xyz123'
    }
  };
}

function buildRevJetBanner({ data, requestConfig, adType }) {

  if (window.REVJET_Tag) {
    renderFrame({ data, requestConfig });
  } else {
    injectRevJetScript({ data, requestConfig, adType });
  }
}

function buildRevJetBannerMock(requestConfig) {
  const data = getMockResponse(requestConfig);

  buildRevJetBanner({ data, requestConfig });
}

const dispatchMouseEvent = (windowObject, type, containerX, containerY) => {
  windowObject.document
    .elementFromPoint(containerX, containerY)
    .dispatchEvent(new windowObject.MouseEvent(type, {
      view: windowObject,
      bubbles: true,
      cancelable: true,
      clientX: containerX,
      clientY: containerY
    }));
};

/* istanbul ignore next */
const onAdTagContainerClick = (event) => {
  const rect = document.querySelector('#hp_sponsored__hero')
    .getBoundingClientRect();
  // Calculate click coordinates relative to the creative's container
  const containerX = event.clientX - rect.x;
  const containerY = event.clientY - rect.y;

  // Find the creative's iframe
  const frames = heroTagRef.frames();
  const contentWindow = frames[0].contentWindow;

  contentWindow.Pandora = {
    /**
       * If defined, will be called with correct landing page URL.
       */
    openLandingPage: (opts) => {
      // Open the landing page URL
      window.open(opts.pageURL, '_top');
    }
  };

  // In order to correctly build the creative's heatmap it is necessary here to
  // dispatch a 'mousedown' event before dispatching the 'click' event.
  dispatchMouseEvent(contentWindow, 'mousedown', containerX, containerY);
  // Dispatch the 'click' event to the creative.
  dispatchMouseEvent(contentWindow, 'click', containerX, containerY);
};

export {
  buildRevJetBanner, buildRevJetBannerMock, getFrameConfig, frameLoad, getRevJetConfig, onAdTagContainerClick, renderFrame, getPubRef, injectRevJetScript, getCreativeId
};
