import React from 'react';

export const GROUP_TYPES = {
  DIMENSIONS: 'Dimensions',
  DETAILS: 'Details',
  WARRANTY: 'Warranty / Certifications'
};

export const getCommaSpaceValue = (specValue) => {
  const specContainsComma = /[a-zA-Z0-9$](,)[a-zA-Z0-9$]/;
  const spacedSpecValue = !specContainsComma.test(specValue)
    ? specValue : specValue.replace(/,/g, ', ');
  return spacedSpecValue;
};

export const getSortedValue = ({ attributeGroupList }) => (
  (attributeGroupList.specifications || [])
    .map((attr) => {
      const newAttr = { ...attr };
      newAttr.specValue = getCommaSpaceValue(newAttr?.specValue);
      return newAttr;
    })
    .filter((attr) => attr.specName)
    .sort((entryName, compName) => {
      const nameval1 = entryName.specName.toUpperCase();
      const nameval2 = compName.specName.toUpperCase();
      if (nameval1 < nameval2) return -1;
      if (nameval1 > nameval2) return 1;
      return 0;
    }));

export const getRows = ({ attributeGroupList, rowSize = 2 }) => {
  const attributeValues = getSortedValue({ attributeGroupList });
  if (attributeValues.length > 0) {
    return attributeValues.reduce((values = [], item, index) => {
      const chunkIndex = Math.floor(index / rowSize);
      const newItem = { ...item };
      newItem.specValue = getCommaSpaceValue(newItem?.specValue);
      const rowArray = values;

      if (!values[chunkIndex]) {
        rowArray[chunkIndex] = [];
      }

      rowArray[chunkIndex].push(newItem);
      return rowArray;
    }, []);
  }
  return [];
};
