import React from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { getInventory } from '../helpers/pod-fulfillment-utils';
import { DELIVERY_TEMPLATES } from './templatesConstant';
import { isB2C } from '../../components/helper/utils';

const contactTemplate = (storeName) => {
  return (
    <div className="store__message">
      <div className="store__text-box">
        <div id="check-nearby-store" className="store__second-line">
          <span className="store__warning">Contact {storeName || 'your local store'}</span>
        </div>
      </div>
    </div>
  );
};

const emptyShippingTemplate = ({ values } = {}) => {
  if (values?.isGCC) {
    return contactTemplate();
  }
  return (
    <div className="shipping__message">
      <div className="shipping__text-box">
        <div className="store__primary shipping__second-line">Unavailable</div>
      </div>
    </div>
  );
};

export const getStockTemplate = (message) => {
  return (
    <div className="shipping__message">
      <div className="shipping__text-box">
        <div className="store__primary shipping__second-line">{message || 'Unavailable'}</div>
      </div>
    </div>
  );
};

const formatMessage = (rawMessage, shippingThreshold) => {
  const putAsterik = shippingThreshold > 0;
  const textClassName = rawMessage.includes('Free') ? 'shipping__success' : 'store__dark';
  const isStandard = rawMessage.includes('Standard');
  let message = rawMessage;

  if (isStandard) {
    return <span className="shipping__success"> Available</span>;
  }

  if (!rawMessage?.includes('No longer available') && !isStandard) {
    message = rawMessage ? rawMessage.split(' ')[0] : '';
    message += ' Delivery';
  }
  if (putAsterik) {
    return (<span className={textClassName}>{message}<sup>*</sup></span>);
  }
  return <span className={textClassName}>{message}</span>;
};

const defaultShippingTemplate = ({ values }) => {
  const isAppliance = values?.applianceQuantity > 0;
  const isStandard = values?.shippingMessage?.includes('Standard');
  const isQuantityGreaterThanZero = values?.displayQuantity > 0 || values?.applianceQuantity > 0;
  const isSeasonType = values?.product?.fulfillment?.seasonStatusEligible;
  const isB2CUser = isB2C(values?.experienceContextData?.customer);
  const isUnavailableMessage = values?.shippingMessage?.includes('Unavailable');

  if (isSeasonType && !isQuantityGreaterThanZero) {
    return getStockTemplate('In store only');
  }

  if ((!isQuantityGreaterThanZero && !isB2CUser) || isUnavailableMessage) {
    return emptyShippingTemplate();
  }

  return (
    <div className="shipping__message">
      <div className="shipping__text-box">

        <div className={isQuantityGreaterThanZero ? values?.shippingCss : ''}>
          {isQuantityGreaterThanZero && !isAppliance && !isB2CUser
            && (
              <>
                <span className="shipping__success">{values?.displayQuantity}</span>
                {!isStandard && <span className="store__dark"> | </span>}
              </>
            )}

          <span className={isQuantityGreaterThanZero ? values?.shippingCss : ''} />
          { formatMessage(values?.shippingMessage, values?.shippingThreshold) }
        </div>

      </div>

    </div>

  );
};

const renderTemplates = {
  [DELIVERY_TEMPLATES.DEFAULT_SHIPPING]: defaultShippingTemplate,
  [DELIVERY_TEMPLATES.EMPTY_SHIPPING_MESSAGE]: emptyShippingTemplate
};

export const renderDeliveryTemplates = ({ deliveryTemplate, icon, values }) => {
  return renderTemplates[deliveryTemplate] ? renderTemplates[deliveryTemplate](
    { deliveryTemplate, icon, values }) : contactTemplate();
};