import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStylePrimaryLightOrange = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStylePrimaryLightOrange.palette['--sui-palette-background-primary'] = '250 140 69';

export { HouseStylePrimaryLightOrange };