import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Select = (props) => {
  const {
    header,
    selected,
    options,
    onChange
  } = props;

  const mapOptions = (choices) => {
    return choices.map((choice) => <option key={choice.value} value={choice.value}>{choice.name}</option>);
  };

  return (
    <>
      {header && <span className="drop-down__label">{header}</span>}
      <form>
        <span className="drop-down__title drop-down__title--select">
          <select
            className="drop-down__select"
            onChange={onChange}
            value={selected}
          >
            {mapOptions(options)}
          </select>
        </span>
      </form>
    </>
  );
};

Select.propTypes = {
  /** Header text displayed above select drop down */
  header: PropTypes.string,
  /** Function which handles change in select drop down */
  onChange: PropTypes.func.isRequired,
  /** List of options that appear in drop down */
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  /** Selected option from the list */
  selected: PropTypes.string
};

Select.defaultProps = {
  header: null,
  selected: null
};

export default Select;
