import { createTheme } from '@one-thd/sui-atomic-components';

const SecondaryHolidayLightRed = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

SecondaryHolidayLightRed.palette['--sui-palette-background-primary'] = '216 19 29';

export { SecondaryHolidayLightRed };