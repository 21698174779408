import React from 'react';

function createTag(children, hydrator) {
  if (!hydrator.tag) return children;
  const { id, className, component = 'div', ...rest } = hydrator.tag;
  return React.createElement(
    component,
    {
      id,
      className,
      ...rest,
    },
    children
  );
}

export default createTag;
