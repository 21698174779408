export const floorNumberWithCommas = (num, decimalPostion) => {
  if (num === 0) {
    return '0';
  }

  if (!num || Number.isNaN(num)) {
    return '';
  }

  return Math.floor(Number(num))
    .toFixed(decimalPostion)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
