import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStylePrimaryNeutral = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

HouseStylePrimaryNeutral.palette['--sui-palette-background-primary'] = '255 248 240';

export { HouseStylePrimaryNeutral };