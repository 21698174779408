import {
  shape, number, string, bool, arrayOf
} from '@thd-nucleus/data-sources';

const options = arrayOf(shape({
  perkType: string(),
  activationType: string(),
  triggerType: string(),
  availability: string(),
  tierRewardId: string(),
  amount: number(),
  perkTitle: string(),
  perkDescription: string(),
  perkImageUrl: string(),
  shortDescription: string(),
  shortName: string(),
  sequenceNumber: number(),
  giftCard: bool(),
}));

const accrualData = arrayOf(shape({
  year: number(),
  currentYear: bool(),
  accrual: number(),
  acceleratedAccrual: number(),
  pxccAccrual: number(),
  pxccAcceleratedAccrual: number(),
  nonPxccAccrual: number(),
  spendForNextPerk: number(),
  spendForNextPerkWithPxcc: number(),
  spendForFutureTierPerk: number(),
  spendForFutureTierPerkWithPxcc: number(),
  finalTierPerkAchieved: bool(),
}));

const nonProgramPerks = arrayOf(shape({
  perkId: string(),
  perkName: string(),
  perkTitle: string(),
  perkDescription: string(),
  perkImageUrl: string(),
  shortDescription: string(),
  shortName: string(),
  perkStatus: string(),
  perkType: string(),
  activationType: string(),
  triggerType: string(),
  offerType: string(),
  availability: string(),
  creationTime: string(),
  activationTime: string(),
  redemptionTime: string(),
  expirationTime: string(),
  yearEarned: number(),
  daysLeft: number(),
  perkSourceUUID: string(),
  paymentId: string(),
  perkDisplaySeqNumber: number(),
  amount: number(),
  availableBalance: number(),
  renewed: bool(),
  exchanged: bool(),
  adminIssued: bool(),
  bulkIssued: bool(),
  bonusPerk: bool(),
  giftCard: bool(),
  rewardId: string(),

  // Shapes
  options,
}));

const previousYearPerks = arrayOf(shape({
  programId: string(),
  tierId: string(),
  tierName: string(),
  minThreshold: number(),
  maxThreshold: number(),
  minThresholdDisplay: string(),
  maxThresholdDisplay: string(),
  progressPercentage: number(),
  perkTitle: string(),
  perkDescription: string(),
  earned: bool(),
  perkId: string(),
  perkName: string(),
  perkImageUrl: string(),
  shortDescription: string(),
  shortName: string(),
  perkStatus: string(),
  perkType: string(),
  activationType: string(),
  triggerType: string(),
  offerType: string(),
  availability: string(),
  creationTime: string(),
  activationTime: string(),
  redemptionTime: string(),
  expirationTime: string(),
  yearEarned: number(),
  daysLeft: number(),
  perkSourceUUID: string(),
  paymentId: string(),
  perkDisplaySeqNumber: number(),
  amount: number(),
  availableBalance: number(),
  renewed: bool(),
  exchanged: bool(),
  adminIssued: bool(),
  bulkIssued: bool(),
  bonusPerk: bool(),
  giftCard: bool(),
  rewardId: string(),

  // Shapes
  options,
}));

export const infoOffersModel = {
  customerAccountId: string(),
  currentTierId: string(),
  currentYearSpend: number(),
  perkCounter: number(),
  recentlyAdded: bool(),
  programMembershipStartDate: string(),
  activePxccFactor: number(),
  pxccUserCategory: string(),

  // Shapes
  accrualData,
  nonProgramPerks,
  previousYearPerks,
};
