import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Washer = createSvgIcon(
  <>
    <path d="M7.5 14.25a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM12 12a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM9 4.5H6v3h3v-3Z" />
    <path d="M1.5 0h21v24h-21V0Zm2.25 2.25v19.5h16.5V2.25H3.75Z" />
  </>,
  'Washer'
);

export { Washer };