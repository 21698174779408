import React from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOf,
  oneOfType,
  shape,
  string
} from 'prop-types';
import classNames from 'classnames/bind';
import styles from './heading.module.scss';

const cx = classNames.bind(styles);

const Heading = ({
  centered,
  children,
  className,
  clickable,
  link,
  noborder,
  spaceBetween,
  noMargin,
  nopadding,
  title,
  underline,
  fontNext,
  level
}) => {
  const rowTitle = title || title === '';
  const header = rowTitle || children;
  if (!header) {
    return null;
  }

  const headerClasses = cx('section-heading', {
    'section-heading--nomargin': noMargin,
    'section-heading--noborder': noborder,
    'section-heading--nopadding': nopadding,
    'section-heading--space-between': clickable || spaceBetween,
    'section-heading--centered': centered
  });

  const headerTextClasses = cx('section-heading__text', {
    'section-heading__text--plain': !underline,
    'section-heading__text--font-next': fontNext,
  });

  const isLinkCTA = clickable && typeof clickable === 'object';
  const preText = clickable && typeof clickable === 'string';
  const Tag = `h${level}`;

  return (
    <div className={className}>
      <div className={headerClasses}>
        <div className={styles['section-heading__title']}>
          <Tag className={headerTextClasses}>{header}</Tag>
          {link && <a className={styles['section-heading__link']} href={link.href}>{link.title}</a>}
        </div>
        {clickable && !isLinkCTA
          && (
            <div className={styles['section-heading__cta']}>
              {
                preText
                && <span className={cx('section-heading__cta-text', 'u__default-link')}>{clickable}</span>
              }
              <span className={styles['section-heading__cta-icon']}>
                <img src="https://assets.thdstatic.com/images/v1/caret-orange.svg" alt="More" />
              </span>
            </div>
          )}
        {clickable && isLinkCTA
          && (
            <a href={clickable.href} className={styles['section-heading__cta']}>
              <span className={cx('section-heading__cta-link', 'u__default-link')}>{clickable.title}</span>
              <img src="https://assets.thdstatic.com/images/v1/caret-orange.svg" alt="More" />
            </a>
          )}
      </div>
    </div>
  );
};

Heading.displayName = 'Heading';

Heading.propTypes = {
  centered: bool,
  children: oneOfType([arrayOf(node), node, string]),
  className: string,
  /** Show the orange action button on the header. Can be a string to add a prefix,
   * or a link shape to behave like a link */
  clickable: oneOfType([bool, string, shape({})]),
  title: string,
  link: shape({
    title: string,
    href: string
  }),
  noborder: bool,
  noMargin: bool,
  nopadding: bool,
  spaceBetween: bool,
  /** Add the orange underline */
  underline: bool,
  // TODO: remove this temporary prop when <Typography /> is available
  fontNext: bool,
  level: oneOf(['1', '2', '3', '4', '5', '6'])
};

Heading.defaultProps = {
  centered: false,
  children: null,
  className: null,
  clickable: false,
  link: null,
  noMargin: false,
  nopadding: false,
  noborder: false,
  spaceBetween: false,
  title: null,
  underline: false,
  /** TEMPORARY FIX until the typography component is done */
  fontNext: false,
  level: '2'
};

export { Heading };
