export const removeFields = (obj, fieldNames) => {

  // eslint-disable-next-line no-unused-expressions
  obj && Object.keys(obj).forEach(function (prop) {
    switch (typeof obj[prop]) {
    case 'object':
      if (fieldNames.indexOf(prop) > -1) {
        // eslint-disable-next-line no-param-reassign
        delete obj[prop];
      } else {
        removeFields(obj[prop], fieldNames);
      }
      break;
    default:
      if (fieldNames.indexOf(prop) > -1) {
        // eslint-disable-next-line no-param-reassign
        delete obj[prop];
      }
      break;
    }
  });
  return obj;
};
