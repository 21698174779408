import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ShareIos = createSvgIcon(
  <>
    <path d="m8.155 6.007 2.72-2.193v10.811h2.25V3.815l2.72 2.192 1.412-1.752L12 .018 6.743 4.255l1.412 1.752Z" />
    <path d="M8.25 7.5h-4.5V24h16.5V7.5h-4.5v2.25H18v12H6v-12h2.25V7.5Z" />
  </>,
  'ShareIos'
);

export { ShareIos };