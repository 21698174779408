import React from 'react';
import PropTypes from 'prop-types';
import { getLongDescription, getShipModeDetails } from '../../helpers/utils';
import { BODFS_DESC, BUY_ONLINE_DELIVER_FROM_STORE, SHIP_TO_HOME } from '../../helpers/constants';

export const DeliveryOptionCard = ({
  deliveryType,
  deliveryOption,
  isDeliveryTimelineTodayOrTomorrow,
  deliveryDateAvailability,
  channel,
}) => (
  <div className={`DeliveryDrawer__card ${channel}`}>
    <div className={`DeliveryDrawer__nameAndDesc ${channel}`}>
      <div className={`DeliveryDrawer__name ${channel}`}>
        {deliveryType === BUY_ONLINE_DELIVER_FROM_STORE ? BODFS_DESC : deliveryOption.mode.desc}
      </div>

      {deliveryType === SHIP_TO_HOME && (
        <div className={`DeliveryDrawer__content ${channel}`}>
          {getLongDescription(deliveryOption.mode.longDesc)}
        </div>
      )}

      {deliveryType === BUY_ONLINE_DELIVER_FROM_STORE && (
        <div className={`DeliveryDrawer__content ${channel}`}>
          {getShipModeDetails()}
        </div>
      )}
    </div>
    <div className={`DeliveryDrawer__price ${channel}`}>
      {deliveryOption.totalCharge === 0
        ? <span className="free_shipping">FREE</span> : `$${deliveryOption.totalCharge}`}
    </div>
  </div>
);

DeliveryOptionCard.propTypes = {
  deliveryType: PropTypes.string.isRequired,
  deliveryOption: PropTypes.PropTypes.shape({
    mode: PropTypes.shape({
      desc: PropTypes.string,
      longDesc: PropTypes.string
    }),
    totalCharge: PropTypes.number,
  }),
  isDeliveryTimelineTodayOrTomorrow: PropTypes.bool,
  deliveryDateAvailability: PropTypes.string,
  channel: PropTypes.string,
};

DeliveryOptionCard.defaultProps = {
  deliveryOption: null,
  deliveryDateAvailability: null,
  isDeliveryTimelineTodayOrTomorrow: null,
  channel: null,
};
