import React from 'react';
import {
  arrayOf, func, number, shape, string
} from 'prop-types';
import { Button } from '@thd-olt-component-react/button';
import classNames from 'classnames';
import * as analytics from '../../../../ratings-reviews-analytics';
import { usePresentation } from '../../../../context/PresentationProvider';

export const Sentiment = ({ name, onClick, selectedSentiment, values }) => {
  const { useCondensedLayout } = usePresentation();
  const onSentimentFeatureClick = ({ sentiment }) => {
    const value = sentiment.SentimentType === 1 ? 'pro' : 'con';
    analytics.track('event', {
      origin: 'ratings and reviews',
      location: 'sentiment',
      value,
      eventType: 'click'
    });
    onClick(sentiment);
  };
  const sentimentHeading = classNames('sentiment-heading', {
    'sentiment-heading-condensed': useCondensedLayout
  });
  // TODO: Clean up the CSS and markup. Button shouldn't be wrapped in a span
  return (
    <>
      <div className={sentimentHeading}>{name}</div>
      <div className="sentiment-collection">
        {values.map((value, valueIndex) => {
          const { Feature, TotalResults } = value;
          const isSelectedSentiment = Feature === (selectedSentiment || {}).Feature;

          const sentimentBorder = classNames('sentiment-border', {
            'sentiment-border__selected': isSelectedSentiment
          });

          const sentimentClasses = classNames('select-sentiment', {
            'select-sentiment__selected': isSelectedSentiment
          });

          return (
            <div className="sentiment-container" key={valueIndex}>
              <span className={sentimentBorder}>
                <Button
                  onClick={() => onSentimentFeatureClick({ sentiment: value })}
                  className={sentimentClasses}
                  link
                  inline
                >
                  {Feature} ({TotalResults})
                </Button>
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

Sentiment.displayName = 'RatingsAndReviewsSentiment';

export const PropTypes = {
  name: string.isRequired,
  onClick: func.isRequired,
  selectedSentiment: shape({
    Feature: string,
    SentimentType: number,
    TotalResults: number
  }),
  values: arrayOf(shape({
    Feature: string,
    SentimentType: number,
    TotalResults: number
  })).isRequired
};

Sentiment.propTypes = PropTypes;

Sentiment.defaultProps = {
  selectedSentiment: {}
};
