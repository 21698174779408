import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const PercentWithMessage = ({ percent, message }) => {
  let msg = message.endsWith(' ') ? message : message + ' ';
  msg += percent + '%';
  return (
    <Typography color="success" data-automation-id="pricingScenariosPercentOffText">
      {msg}
    </Typography>
  );
};

PercentWithMessage.propTypes = {
  percent: PropTypes.string,
  message: PropTypes.string,
};

PercentWithMessage.defaultProps = {
  percent: '0',
  message: '',
};

PercentWithMessage.displayName = 'PercentWithMessage';
