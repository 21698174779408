import { createTheme } from '@one-thd/sui-atomic-components';

const HouseStyleSecondaryOrangeShadow = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

HouseStyleSecondaryOrangeShadow.palette['--sui-palette-background-primary'] = '185 67 14';

export { HouseStyleSecondaryOrangeShadow };