import { Model } from './Model';

class ReviewModel extends Model {

  get badges() {
    return this.get('Badges', {});
  }

  get badgesOrder() {
    return this.get('BadgesOrder', {});
  }

  get contextDataValues() {
    return this.get('ContextDataValues', {});
  }

  get verifiedRecommendedValues() {
    const verifiedPurchaser = this.getNestedProperties(['ContextDataValues', 'VerifiedPurchaser', 'Value'], 'False');
    const recommended = this.get('IsRecommended', false);

    const returnValue = [];

    if (recommended) {
      returnValue.push('Recommended');
    }

    if (verifiedPurchaser === 'True') {
      returnValue.push('Verified Purchase');
    }

    return returnValue.join(' & ');
  }

  get contextDataValuesOrder() {
    return this.get('ContextDataValuesOrder', {});
  }

  get userNickname() {
    return this.get('UserNickname', '');
  }

  get userLocation() {
    return this.get('UserLocation', '');
  }

  get profileBadges() {
    return this.get('profileBadges', []);
  }

  get title() {
    return this.get('Title', '');
  }

  get submissionTime() {
    return this.get('SubmissionTime', '');
  }

  get rating() {
    return this.get('Rating', '');
  }

  get ratingRange() {
    return this.get('RatingRange', '');
  }

  get reviewText() {
    return this.get('ReviewText', '');
  }

  get id() {
    return this.get('Id', '');
  }

  get productId() {
    return this.get('ProductId', '');
  }

  get secondaryRatings() {
    return this.get('SecondaryRatings', {});
  }

  get secondaryRatingsOrder() {
    return this.get('SecondaryRatingsOrder', {});
  }

  get secondaryRatingsExist() {
    return Object.values(this.secondaryRatingsOrder).length > 0;
  }

  get isSyndicated() {
    return this.get('IsSyndicated', false);
  }

  get isRecommended() {
    return this.get('IsRecommended', false);
  }

  get pros() {
    return this.getNestedProperties(['TagDimensions', 'Pro', 'Values'], [])
      .join(', ');
  }

  get cons() {
    return this.getNestedProperties(['TagDimensions', 'Con', 'Values'], [])
      .join(', ');
  }

  get photos() {
    return this.get('Photos', []);
  }

  get videos() {
    return this.get('Videos', []);
  }

  get totalNegativeFeedbackCount() {
    return this.get('TotalNegativeFeedbackCount', 0);
  }

  get totalPositiveFeedbackCount() {
    return this.get('TotalPositiveFeedbackCount', 0);
  }

  get clientResponseText() {
    return this.getNestedProperties(['ClientResponses', 0, 'Response'], null);
  }

  get clientResponseDate() {
    return this.getNestedProperties(['ClientResponses', 0, 'Date'], null);
  }

  get clientResponseName() {
    return this.getNestedProperties(['ClientResponses', 0, 'Department'], null);
  }

  get statsByReviewId() {
    return this.getNestedProperties(['Includes', 'Products', '', 'ReviewStatistics'], null);
  }

  get syndicationSource() {
    return this.get('SyndicationSource', {});
  }

  get seedAccessProgramView() {
    return this.get('CampaignId', '')
      .includes('bvsampling');
  }

  getPhotoIndexById(id) {
    return this.photos.findIndex((photo) => {
      return photo.Id === id;
    });
  }

  get name() {
    return this.get('firstName', '') + ' ' + this.get('lastName', '');
  }

  get authorId() {
    return this.get('AuthorId', '');
  }
}

export { ReviewModel };
