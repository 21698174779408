import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Defines a row of cells in a [`Table`](#table) component.
 *
 * Use `TableRow` with the following sub-components:
 *
 * [TableCell](#tablecell), [TableSortLabel](#tablesortlabel), [TableRowLabel](#tablerowlabel)
 *
 * Usage:
 *
 * ```jsx
 * import { TableRow } from '@one-thd/sui-atomic-components';
 * ```
 */
const TableRow = React.forwardRef((props, ref) => {
  const {
    children,
    collapsed = false,
    lastVisible,
    ...other
  } = props;

  const classes = classNames({
    'sui-table-last-row': lastVisible,
    'sui-table-collapsed-row': collapsed,
    'sui-cursor-pointer': props.onClick
  });

  return (
    <tr
      ref={ref}
      className={classes}
      {...other}
    >
      {children}
    </tr>
  );
});

TableRow.displayName = 'TableRow';

TableRow.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If set, the table row will be collapsed (no padding and no border)
   */
  collapsed: PropTypes.bool,
  /**
   * If true, it represents the last visible row (when working with collapsed rows in a table)
   * and rounds the corners of the first and last cells.
   */
  lastVisible: PropTypes.bool,
  /**
   * Callback fired when the component is clicked.
   */
  onClick: PropTypes.func
};

export { TableRow };