import { extend } from '@thd-nucleus/data-sources';

import { CallToActionDataModel } from './CallToActionDataModel';
import { PromoDrawerDataModel } from './PromoDrawerDataModel';
import { PromoCartProviderDataModel } from './PromoCartProviderDataModel';
import { PromoModelProviderDataModel } from './PromoModelProviderDataModel';

export const PromotionProductsDataModel = extend(
  PromoModelProviderDataModel,
  PromoCartProviderDataModel,
  PromoDrawerDataModel,
  CallToActionDataModel,
);
