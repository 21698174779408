import React, { useState } from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOf,
  oneOfType,
  string,
  func
} from 'prop-types';
import classNames from 'classnames';
import './AccordionItem.style.scss';

const NOOP = () => {};

const AccordionItem = (props) => {
  const {
    checkIndicator,
    children,
    className,
    contentClass,
    id,
    itemClass,
    inputClass,
    keyValue,
    labelClass,
    name,
    open,
    subTitle,
    title,
    type,
    heading,
    onChange = NOOP
  } = props;
  const [checked, setChecked] = useState(open);
  const contentClasses = classNames('accordion__content', contentClass, { 'accordion__content--heading': heading });
  const itemClasses = classNames('accordion__item', itemClass, {
    'accordion__item--heading': heading,
    'accordion__item--open': checked
  });
  const inputClasses = classNames('accordion__input', inputClass);
  const labelClasses = classNames('accordion__label', labelClass, { 'accordion__label--heading': heading });
  const titleClasses = classNames({ accordion__title: heading });
  const triggerClasses = classNames('accordion__trigger', { 'accordion__trigger--heading': heading });
  const wrapperClasses = classNames('accordion__wrapper', className, { 'accordion__wrapper--heading': heading });
  const iconClasses = classNames(checkIndicator ? 'mw-icon-checkmark mw-icon-checkmark--green' : 'accordion__plus');

  return (
    <div className={wrapperClasses} key={keyValue}>
      <div className={itemClasses}>
        <input
          className={inputClasses}
          id={id}
          name={name}
          type={type}
          checked={checked}
          onChange={() => onChange({ open: checked })}
        />
        { /* eslint-disable-next-line */ }
        <label className={labelClasses} htmlFor={id} onClick={() => setChecked(!checked)}>
          <div className={triggerClasses}>
            <div className={iconClasses} />
          </div>
          {title && <span className={titleClasses}>{title}</span>}
          {subTitle
          && <span className="accordion__sub-title">{subTitle}</span>}
        </label>
        <div className="accordion__content-wrapper">
          <div className={contentClasses}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  /** Adds checkmark to opened and selected accordion */
  checkIndicator: bool,
  /** Accordion Content */
  children: oneOfType([
    arrayOf(node),
    node
  ]),
  /** Wrapper classes, passed down from the Accordion Parent Element */
  className: string,
  /** Accordion Content classes */
  contentClass: string,
  /** Heading Accordion */
  heading: bool,
  /** Input ID */
  id: string,
  /** Accordion Item classes */
  itemClass: string,
  /** Accordion Input classes */
  inputClass: string,
  /** Wrapper Key */
  keyValue: string,
  /** Label classes */
  labelClass: string,
  /** Input name */
  name: string,
  subTitle: string,
  title: node.isRequired,
  /** Input Type */
  type: oneOf(['checkbox', 'radio']),
  /** Is the accordion open? */
  open: bool,
  /** on open/close of accordion allback */
  onChange: func
};

AccordionItem.defaultProps = {
  checkIndicator: false,
  children: null,
  className: null,
  contentClass: null,
  heading: false,
  id: null,
  itemClass: null,
  inputClass: null,
  keyValue: null,
  labelClass: null,
  name: null,
  open: false,
  onChange: NOOP,
  type: 'checkbox',
  subTitle: null
};

export default AccordionItem;
