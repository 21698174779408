import React from 'react';
import PropTypes from 'prop-types';
import useControlled from '../../hooks/useControlled';

const SelectionControlBase = React.forwardRef((props, ref) => {

  const {
    checked: checkedProp,
    classes,
    defaultChecked,
    disabled,
    id,
    checkedIcon,
    uncheckedIcon,
    inputAttributes,
    onBlur,
    onChange,
    onFocus,
    name,
    tabIndex,
    indeterminate,
    role,
    type,
    value,
    ...other
  } = props;

  const [checked, setCheckedState] = useControlled({
    controlled: checkedProp,
    defaultValue: Boolean(defaultChecked)
  });

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleInputChange = (event) => {
    if (event.nativeEvent.defaultPrevented) {
      return;
    }

    const newChecked = event.target.checked;

    setCheckedState(newChecked);
    if (onChange) {
      onChange(event);
    }
  };

  let ariaCheckedProp = {};
  if (indeterminate) {
    ariaCheckedProp = { 'aria-checked': 'mixed' };
  } else if (checked) {
    ariaCheckedProp = { 'aria-checked': 'true' };
  } else {
    ariaCheckedProp = { 'aria-checked': 'false' };
  }

  return (
    <span
      className={classes?.span}
      aria-disabled={disabled}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
      {...other}
    >
      <input
        checked={checked}
        className={classes?.input}
        disabled={disabled}
        id={id}
        name={name}
        role={role}
        onChange={handleInputChange}
        tabIndex={tabIndex}
        type={type}
        {...(type === 'checkbox' ? ariaCheckedProp : {})}
        {...(type === 'checkbox' && value === undefined ? {} : { value })}
        {...inputAttributes}
      />
      {checked ? checkedIcon : uncheckedIcon}
    </span>
  );
});

SelectionControlBase.displayName = 'SelectionControlBase';

SelectionControlBase.propTypes = {
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  checkedIcon: PropTypes.node.isRequired,
  uncheckedIcon: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * @ignore
   */
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  // icon: PropTypes.node.isRequired,
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputAttributes: PropTypes.object,
  /**
   * @ignore
   */
  name: PropTypes.string,
  /**
   * @ignore
   */
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onFocus: PropTypes.func,
  /**
   * @ignore
   */
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Indicate whether the checkbox is in an indeterminate state
   */
  indeterminate: PropTypes.bool,
  /**
   * The aria role for the input component
   */
  role: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
};

export { SelectionControlBase };