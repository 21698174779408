import React from 'react';
import {
  shape,
  bool,
  number,
  string,
  arrayOf
} from 'prop-types';
import { Carousel } from '@one-thd/sui-carousel';

import { CardOrder } from './CardOrder';

export function RecentPurchasesCard({
  orderHistoryData,
  customerInfo,
  windowWidth,
}) {
  const maxOrders = orderHistoryData?.purchaseHistory?.orders.length > 20
    ? 20
    : orderHistoryData?.purchaseHistory?.orders.length;

  const orders = orderHistoryData?.purchaseHistory?.orders.slice(0, maxOrders);

  let cardContent = orders?.map((order, index) => (
    <CardOrder
      customerInfo={customerInfo}
      index={index}
      key={order.receiptDetails}
      order={order}
      windowWidth={windowWidth}
    />
  ));

  return (
    <div data-testid="recentOrders-content-b2b-carousel">
      <Carousel disableShadow slidesPerView={1}>
        {cardContent}
      </Carousel>
    </div>
  );
}

RecentPurchasesCard.propTypes = {
  orderHistoryData: shape({
    purchaseHistory: shape({
      customerAccountId: string,
      orderCount: number,
      orders: arrayOf(
        shape({
          salesDate: string,
          orderNumber: string,
          totalAmount: number,
          receiptDetails: string,
          registerNumber: number,
          orderOrigin: string,
          detailsSource: string,
          transactionId: number,
          POJobName: string,
          transactionType: string,
        })
      ),
    }),
  }),
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  windowWidth: number,
};

RecentPurchasesCard.defaultProps = {
  orderHistoryData: null,
  customerInfo: {},
  windowWidth: undefined,
};
