export const loadEvent = (feature) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('card-messaging.ready', { feature });
  }
};

export const clickEvent = (feature, module) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('card-messaging.click', { feature, module });
  }
};

export const impressionEvent = (feature, module) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('card-messaging.impressionEvent', { feature, module });
  }
};

export const modalImpressionEvent = (feature) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('card-messaging.modalImpressionEvent', { feature });
  }
};

export const modalClickEvent = (feature, module) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('card-messaging.modalClickEvent', { feature, module });
  }
};

export const productClickEvent = () => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('card-messaging.productClick');
  }
};
