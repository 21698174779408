import React, { useContext } from 'react';
import { shape, string } from 'prop-types';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { Endcap } from '@thd-olt-component-react/endcap';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import classnames from 'classnames/bind';
import {
  CUSTOMER_TYPES,
  getPersonalizedDealsUserId,
  MARKETING_CLOUD_VISITOR_ID_DEFAULT,
} from '../../utils';
import { HomepageContext } from '../context/HomepageContext';

export function TopSavingsForYou({ store, id, theme }) {
  const { defaultVariables } = useContext(QueryContext);
  const { storeId } = store;
  const { customerType, thdSEOBotDetection } = useContext(HomepageContext);
  const personalizedUserId = thdSEOBotDetection
    ? MARKETING_CLOUD_VISITOR_ID_DEFAULT
    : getPersonalizedDealsUserId(customerType);

  const endcapWrapperClass = classnames(
    !theme ? 'sui-paper sui-paper-outlined sui-rounded-base sui-h-full sui-flex sui-flex-col sui-w-full sui-gap-4' : ''
  );

  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant
      === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <ErrorBoundary name="endcap">
      <QueryProvider
        cacheKey="endcap"
        defaultVariables={newDefaultVariables}
      >
        <div
          className={endcapWrapperClass}
        >
          <Endcap
            channel="DESKTOP"
            customerType={customerType === CUSTOMER_TYPES.B2B ? 'B2B' : 'B2C'}
            pageUrl="/"
            storeId={storeId}
            personalizedDealsUserid={personalizedUserId}
            showLoading
            entryId={id}
            isUppercase={false}
            headerWeight="bold"
            runQueryServerSide={!!thdSEOBotDetection}
            theme={theme}
          />
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

TopSavingsForYou.propTypes = {
  store: shape({
    storeId: string,
    storeZip: string,
  }),
  id: string,
  theme: string,
};

TopSavingsForYou.defaultProps = {
  store: {
    storeId: '121',
    storeZip: '30339',
  },
  id: undefined,
  theme: undefined
};
