import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { triggerNewRelic, mkTelemetryField } from '../helpers';
import { newRelicConstants } from '../../constants';

export const useSponsoredProductPodTelemetry = (props) => {
  const {
    productPodNode,
    itemId,
    trackSource,
    position,
    slotId,
    podKpiName,
  } = props;

  // --
  const baseTelemetryFields = useMemo(() => {
    // ---
    const fieldItemId = mkTelemetryField({ key: 'itemId', value: itemId });
    const fieldTrackSource = mkTelemetryField({ key: 'trackSource', value: trackSource });
    const fieldPosition = mkTelemetryField({ key: 'position', value: position });
    const fieldSlotId = mkTelemetryField({ key: 'slotId', value: slotId });
    // ---

    return `${fieldItemId}-${fieldTrackSource}-${fieldPosition}-${fieldSlotId}`;

  }, [itemId, trackSource, position, slotId]);

  /**
   * PLA_PRODUCT_POD_MOUNT
   */
  useEffect(() => {
    // check
    if (!productPodNode) return;

    const kpiName = `${podKpiName}-${baseTelemetryFields}`;
    const kpiValue = newRelicConstants.PLA_PRODUCT_POD_MOUNT;

    triggerNewRelic(kpiName, kpiValue);

  }, [productPodNode, baseTelemetryFields]);

  // ---- ----
  // Callbacks
  // ---- ----
  /**
   * 'CLICK'
   *
   * @param src should be one of:
   * - productShowNowButton
   * - productAtcButton
   * - productImage
   * - productHeader
   * - productRating
   */
  const triggerClick = useCallback((src) => {
    const srcField = mkTelemetryField({ key: 'src', value: src });
    const telemetryFields = `${baseTelemetryFields}-${srcField}`;

    const kpiName = `${podKpiName}-${telemetryFields}`;
    const kpiValue = newRelicConstants.CLICK;

    triggerNewRelic(kpiName, kpiValue);
  }, [baseTelemetryFields]);

  /**
   * 'CLICK' on ATC button
   */
  const triggerClickOnAtcButton = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_ATC_BUTTON);
  }, [triggerClick]);

  /**
   * 'CLICK' on Show Now button
   */

  const triggerClickOnShowNowButton = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_SHOP_NOW_BUTTON);
  }
  , [triggerClick]);

  /**
   * 'CLICK' on Image
   */
  const triggerClickOnImage = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_IMAGE);
  }, [triggerClick]);

  /**
   * 'CLICK' on Header
   */
  const triggerClickOnHeader = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_HEADER);
  }, [triggerClick]);

  /**
   * 'CLICK' on Rating
   */
  const triggerClickOnRating = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_RATING);
  }, [triggerClick]);

  // --- ---
  return {
    triggerClickOnShowNowButton,
    triggerClickOnAtcButton,
    triggerClickOnImage,
    triggerClickOnHeader,
    triggerClickOnRating,
  };
};
