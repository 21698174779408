import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Refrigerator = createSvgIcon(
  <>
    <path d="M6.75 4.5h3v3h-3v-3Zm3 4.5h-3v3h3V9Z" />
    <path d="M2.25 0h19.5v24H2.25V0ZM4.5 16.5v5.25h15V16.5h-15Zm15-2.25v-12h-5.25v12h5.25Zm-7.5 0v-12H4.5v12H12Z" />
  </>,
  'Refrigerator'
);

export { Refrigerator };