import { asyncCallWithTimeout, TIMEOUT_MESSAGE } from './asyncTimeout';
import { createInnervateURL } from './createInnervateURL';
/**
 * @function getVariationsFromInnervate
 * @param {{
 * innervateTags: [{ tagID: String, adkey: String, csId: String, campaignMetadata: String }],
 * slug: String,
 * isDebug: Boolean
 * }} config
 * @returns {Promise<[{ value: [any] }]>}
 */
export const getVariationsFromInnervate = async ({
  innervateTags,
  slug,
  isDebug = false,
}) => {
  const responses = await Promise.allSettled(
    innervateTags.map(async ({ tagID, adkey, csId, campaignMetadata }) => {
      const req = await asyncCallWithTimeout(
        fetch(
          createInnervateURL({
            tagID,
            slug,
            adkey,
            csId,
            campaignMetadata,
            isDebug,
          })
        ),
        5000
      );

      const res = await req.json();

      return res;
    })
  );

  const rejected = responses
    .filter(({ status }) => status === 'rejected')
    .map(({ reason }) => reason);

  if (rejected.length > 0) {
    rejected.forEach((error) => {
      if (error.message === TIMEOUT_MESSAGE) {
        window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.timeout', {
          source: 'Innervate',
          error: error.message,
          payload: { ids: innervateTags },
        });
      } else {
        window.LIFE_CYCLE_EVENT_BUS.trigger('personalization-utils.innervateError', {
          error: { status: error?.errorResponse?.status, message: error?.errorMessage },
        });
      }
    });
  }

  return responses
    .filter(({ status }) => status === 'fulfilled')
    .filter(({ value }) => !!value)
    .map(({ value }) => value)
    .flat();
};
