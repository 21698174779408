import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ButtonGroup } from './ButtonGroup';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

const Button = ({
  children,
  className,
  contentClass,
  cta,
  dark,
  disabled,
  filter,
  inline,
  link,
  nohover,
  moveto,
  onClick,
  outline,
  remove,
  small,
  specialty,
  tag,
  transparent,
  ...attributes
}) => {

  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    if (onClick) {
      onClick(event);
    }
  };

  const primary = !outline && !specialty && !cta && !link && !filter;
  const classes = cx({
    disabled,
    nohover,
    bttn: primary || specialty,
    'bttn--primary': primary,
    'bttn--small': small,
    'bttn--inline': filter || (inline && !cta),
    'cta--inline': inline && cta,
    'bttn--cta': !inline && cta,
    'bttn-outline': outline || link || filter,
    'bttn-outline--primary': outline,
    'bttn-outline--dark': dark && outline,
    'bttn--primary--specialty': specialty,
    'bttn--link': link && (!remove && !moveto),
    'bttn--remove': link && remove,
    'bttn--moveto': link && moveto,
    'bttn--filter': filter,
    'bttn--transparent': link && transparent
  }, className);

  const contentClasses = cx('bttn__content', contentClass);

  const ElementType = (tag === 'a') ? 'a' : 'button';

  return (
    small
      ? (
        <ElementType
          className={styles.bttn__wrapper}
          onClick={handleClick}
          /* eslint-disable-next-line */
          {...attributes}
        >
          <div className={classes}>
            <span className={contentClasses}>{ children }</span>
          </div>
        </ElementType>
      ) : (
        <ElementType
          className={classes}
          onClick={handleClick}
          /* eslint-disable-next-line */
          {...attributes}
        >
          <span className={contentClasses}>{ children }</span>
        </ElementType>
      )
  );
};

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  /**  */
  className: PropTypes.string,
  /** Extend bttn_content class */
  contentClass: PropTypes.string,
  /** Call to Action - adds orange caret pointing to right */
  cta: PropTypes.bool,
  /** Replaces Home Depot orange with dark gray */
  dark: PropTypes.bool,
  /** Disable button */
  disabled: PropTypes.bool,
  /** Filter - rounded pill shaped button with close */
  filter: PropTypes.bool,
  /** Inline - will grow to fit the content, with padding left and right */
  inline: PropTypes.bool,
  /** Links - Optional icon add-ons: moveto and remove */
  link: PropTypes.bool,
  /** Disable hover */
  nohover: PropTypes.bool,
  /** Add-on for Links. Adds green plus icon to left */
  moveto: PropTypes.bool,
  /** On click handler */
  onClick: PropTypes.func,
  /** Border Buttons */
  outline: PropTypes.bool,
  /** Add-on for Links. Adds red x icon to left */
  remove: PropTypes.bool,
  /** Reduces height from 37px to 17px */
  small: PropTypes.bool,
  /** Specialty - adds lock icon to left */
  specialty: PropTypes.bool,
  tag: PropTypes.string,
  /** Makes the link background transparent */
  transparent: PropTypes.bool
};

Button.defaultProps = {
  className: null,
  contentClass: null,
  cta: false,
  dark: false,
  disabled: false,
  filter: false,
  inline: false,
  link: false,
  nohover: false,
  moveto: false,
  onClick: null,
  outline: false,
  remove: false,
  small: false,
  specialty: false,
  tag: '',
  transparent: false
};

const DeprecatedButtonGroup = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonGroup {...props} />
  );
};

Button.Group = DeprecatedButtonGroup;

export { Button };
