import React from 'react';
import {
  Card,
  CardTitle,
  CardActions,
  Typography,
  Button,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { string } from 'prop-types';

const PromotionFallback = ({ componentId }) => {
  const generatePromoLink = ({ link, img, text }) => {
    return (
      <span>
        <a href={link} className="sui-flex sui-flex-row sui-gap-2">
          <img src={img} height="24" width="24" alt="Promo Icon" />
          <Typography weight="regular">{text}</Typography>
        </a>
      </span>
    );
  };

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'PromotionCardFallback',
      component: 'PromotionCard',
      type: 'Content',
      position: 1,
    },
  });

  return (
    <Card
      ref={ref}
      clickid={clickID}
      className="sui-flex sui-h-full"
      CardContentProps={{
        className: 'sui-flex sui-flex-col sui-w-full sui-gap-4 sui-p-4'
      }}
    >
      <CardTitle header={
        <Typography variant="h3">Specials & Offers</Typography>
      }
      />
      <div className="sui-flex sui-flex-col sui-gap-2 sui-h-full sui-w-full">
        {generatePromoLink({
          link: 'https://www.homedepot.com/SpecialBuy/SpecialBuyOfTheDay',
          img: 'https://assets.homedepot-static.com/images/v1/Value-Pricing-Special-Buy.svg',
          text: 'Special Buy of the Day'
        })}
        {generatePromoLink({
          link: 'https://www.homedepot.com/SpecialBuy/ProSpecialBuyOfTheWeek',
          img: 'https://assets.homedepot-static.com/images/v1/Value-Pricing-Special-Buy.svg',
          text: 'Pro Special Buy of the Week'
        })}
        {generatePromoLink({
          link: 'https://www.homedepot.com/b/New-Lower-Prices/N-5yc1vZ1z11adg',
          img: 'https://assets.homedepot-static.com/images/v1/Value-Pricing-New-Lower-Price.svg',
          text: 'New Lower Price'
        })}
        {generatePromoLink({
          link: 'https://www.homedepot.com/c/bulk_pricing',
          img: 'https://assets.homedepot-static.com/images/v1/Value-Pricing-Bulk-Price.svg',
          text: 'Bulk Price Savings'
        })}
      </div>
      <CardActions position="end">
        <Button href="https://www.homedepot.com/c/Savings_Center" variant="secondary" fullWidth>
          Shop All Savings
        </Button>
      </CardActions>
    </Card>
  );
};

PromotionFallback.propTypes = {
  componentId: string.isRequired,
};

PromotionFallback.defaultProps = {};

PromotionFallback.displayName = 'PromotionFallback';

export { PromotionFallback };
