import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonShapes } from '../private/components/skeleton/SkeletonShapes';
import { Typography } from '../typography/Typography';

/**
 * SkeletonLine is a shape sub component of the [Skeleton](#skeleton) component.
 *
 * Usage:
 *
 * ```jsx
 * import { SkeletonLine } from '@one-thd/sui-atomic-components';
 * ```
 */
const SkeletonLine = React.forwardRef((props, ref) => {

  const {
    fullWidth = false,
    TypographyProps = {},
    numberOfLines: numberOfLinesProps = 2,
    variant = 'multi',
    ...other
  } = props;

  if (variant === 'heading') {
    return (
      <Typography
        component="div"
        variant="h1"
        height="none"
        ref={ref}
        {...TypographyProps}
        {...other}
      >
        <SkeletonShapes width={fullWidth ? '100%' : '80%'} />
      </Typography>
    );
  }

  const numberOfLines = variant === 'single' ? 1 : Math.min(Math.max(parseInt(numberOfLinesProps, 10), 2), 12);

  const widths = ['80%', '100%', '60%'];

  return (
    <div
      className="sui-flex sui-flex-col sui-gap-6"
      ref={ref}
      {...other}
    >
      {
        Array.from({ length: numberOfLines }, (x, index) => {
          return (
            <SkeletonShapes
              variant="text"
              fontSize="3xl"
              width={widths[index % 3]}
              key={index}
            />
          );
        })
      }
    </div>
  );
});

SkeletonLine.displayName = 'SkeletonLine';

SkeletonLine.propTypes = {
  /**
   * If true, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * Amount of lines the skeleton will be equal to. Has to be greater than 1 but no more than 12.
   * Only applied for `variant` 'multi'
   */
  numberOfLines: PropTypes.oneOf([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /**
   * Props applied to the [`Typography`](#typography) component.
   * Only applies when variant === `heading`.
   */
  TypographyProps: PropTypes.object,
  /**
   * The type of line that will be rendered.
   * Will infer the height if variant === `heading` from [`Tyopgraphy`](#tyopgraphy) as `h1` default.
   * Use TyopgraphyProps to modify.
   * @default 'multi'
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['heading', 'multi', 'single']),
    PropTypes.string,
  ]),
};

SkeletonLine.defaultProps = {};

export { SkeletonLine };