import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const Calendar = createSvgIcon(
  <>
    <path d="M7.5 12.75h-3v3h3v-3Z" />
    <path d="M7.5 0v2.25h9V0h2.25v2.25H24V24H0V2.25h5.25V0H7.5Zm9 4.5h-9V6H5.25V4.5h-3v3.875h19.5V4.5h-3V6H16.5V4.5ZM2.25 10.625V21.75h19.5V10.625H2.25Z" />
  </>,
  'Calendar'
);

export { Calendar };