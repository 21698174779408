import React from 'react';
import { string, number } from 'prop-types';
import { Hero as HeroComponent } from '@thd-olt-component-react/hero';
import { QueryProvider } from '@thd-nucleus/data-sources';

export function Hero({ id, layoutRendererPrerenderedChildIndex }) {
  return (
    <QueryProvider cacheKey="hero">
      <HeroComponent
        componentId={id}
        componentClass="Hero"
        componentPosition={layoutRendererPrerenderedChildIndex + 1}
      />
    </QueryProvider>
  );
}

Hero.propTypes = {
  id: string,
  layoutRendererPrerenderedChildIndex: number
};

Hero.defaultProps = {
  id: undefined,
  layoutRendererPrerenderedChildIndex: undefined
};
