import React from 'react';
import PropTypes from 'prop-types';

export const TimerNumAndText = ({ timerNums, timerText }) => (
  <div className="sui-flex sui-flex-col sui-text-3xl sui-font-bold">
    {timerNums}
    <span className="sui-text-xs sui-text-center sui-font-light">
      {timerText}
    </span>
  </div>
);

TimerNumAndText.displayName = 'TimerNumAndText';

TimerNumAndText.propTypes = {
  /**
   * To show the time number
   */
  timerNums: PropTypes.string.isRequired,
  /**
   * To show the time text
   */
  timerText: PropTypes.string.isRequired
};
