import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function isValueSelected(value, candidate) {
  if (candidate === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(candidate)) {
    return candidate.indexOf(value) >= 0;
  }

  return value === candidate;
}

/**
 * The `TileGroup` component can be used to group related tiles.
 *
 * Related components: [Tile](#tile), [TileMedia](#tilemedia), [TileText](#tiletext).
 *
 * Usage:
 *
 * ```jsx
 * import { TileGroup } from '@one-thd/sui-atomic-components';
 * ```
 */
const TileGroup = React.forwardRef((props, ref) => {

  const {
    children,
    disabled = false,
    disableExclusive = false,
    grow = false,
    loading = false,
    onChange,
    unavailable = false,
    value,
    ...other
  } = props;

  const handleChange = (event, buttonValue) => {
    if (!onChange) {
      return;
    }

    const index = value && value.indexOf(buttonValue);
    let newValue;

    if (value && index >= 0) {
      newValue = value.slice();
      newValue.splice(index, 1);
    } else {
      newValue = value ? value.concat(buttonValue) : [buttonValue];
    }

    onChange(event, newValue);
  };

  const handleExclusiveChange = (event, buttonValue) => {
    if (!onChange) {
      return;
    }

    onChange(event, value === buttonValue ? null : buttonValue);
  };

  const classes = classNames('sui-flex sui-gap-3 sui-flex-wrap', {
    'sui-w-full': grow
  });

  return (
    <div
      role="group"
      className={classes}
      ref={ref}
      {...other}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          onChange: disableExclusive ? handleChange : handleExclusiveChange,
          selected:
            child.props.selected === undefined
              ? isValueSelected(child.props.value, value)
              : child.props.selected,
          disabled: child.props.disabled || disabled,
          loading: child.props.loading || loading,
          grow: child.props.grow || grow,
          unavailable: child.props.unavailable || unavailable
        });
      })}
    </div>
  );
});

TileGroup.displayName = 'TileGroup';

TileGroup.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, the component is disabled. This implies that all ToggleButton children will be disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, exclusive selection is disabled to allow multiple selection.
   * @default false
   */
  disableExclusive: PropTypes.bool,
  /**
   * If `true`, every `Tile` inside will grow in the flex to fill the available space equally.
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * If `true`, the component is in the loading state. This implies that all Tile children will be loading.
   */
  loading: PropTypes.bool,
  /**
   * Callback fired when the value changes.
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the component is unavailable. This implies that all Tile children will be unavailable.
   */
  unavailable: PropTypes.bool,
  /**
   * The currently selected value within the group or an array of selected
   * values when `exclusive` is false.
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string)
  ]),
};

export { TileGroup };
