import React from 'react';
import { string as stringType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export function RecentPurchasesTableOrigin({ orderOrigin }) {
  const origin = orderOrigin || '';

  return (
    <div
      data-testid="orderOrigin"
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden sui-h-20"
    >
      <Typography lineClamp="2" variant="body-base">
        {origin}
      </Typography>
    </div>
  );
}

export function RecentPurchasesCardOrigin({ orderOrigin }) {
  const origin = orderOrigin || '';

  return (
    <div data-testid="orderOrigin" className="sui-flex sui-items-center">
      <span className="sui-mr-2">
        <Typography variant="body-base" weight="bold">
          Order Origin
        </Typography>
      </span>
      <span>
        <Typography variant="body-base">{origin}</Typography>
      </span>
    </div>
  );
}

RecentPurchasesTableOrigin.propTypes = {
  orderOrigin: stringType,
};

RecentPurchasesTableOrigin.defaultProps = {
  orderOrigin: null,
};

RecentPurchasesCardOrigin.propTypes = {
  orderOrigin: stringType,
};

RecentPurchasesCardOrigin.defaultProps = {
  orderOrigin: null,
};
