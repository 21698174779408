import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TextRow } from './TextRow';

const TextBlock = (props) => {
  const { style, className } = props;

  const classes = ['text-block', className].filter((clss) => clss).join(' ');

  const getRowStyle = (i) => {
    const { rows, widths } = props;

    return {
      maxHeight: `${(100 / (rows * 2 - 1))}%`,
      width: `${widths[(i + widths.length) % widths.length]}%`
    };
  };

  const getRows = () => {
    const { rows, lineSpacing, color } = props;
    const range = new Array(rows).fill(null);
    return range.map((value, i) => (
      <TextRow
        color={color}
        style={getRowStyle(i)}
        lineSpacing={i !== 0 ? lineSpacing : 0}
        key={i}
      />
    ));
  };

  return (
    <div className={classes} style={{ ...style, width: '100%' }}>
      {getRows()}
    </div>
  );
};

TextBlock.propTypes = {
  rows: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  lineSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  widths: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string
};

TextBlock.defaultProps = {
  widths: [97, 100, 94, 90, 98, 95, 98, 40],
  lineSpacing: '.7em',
  style: {},
  className: ''
};

export { TextBlock };
