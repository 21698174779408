import React, { useEffect, useState, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { IconButton } from '@one-thd/sui-atomic-components';
import { Settings } from '@one-thd/sui-icons';
import { ListSettingsDrawer } from './ListSettingsDrawer';
import { triggerAnalytics } from '../helpers';
import { LIST_SETTINGS_FORM_LOAD } from '../constants';

export const ListSettings = ({
  list,
  onDelete,
  onSave,
  isSharedList,
  iconSize
}) => {
  const [open, setOpen] = useState(false);
  const settingsRef = useRef(null);

  useEffect(() => {
    if (!open && settingsRef.current) {
      settingsRef.current.blur();
    }
  }, [open]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('ListSettings.ready', {});
  }, []);

  const openDrawer = () => {
    setOpen(true);
    triggerAnalytics(LIST_SETTINGS_FORM_LOAD, {});
  };

  return (
    <div data-component="ListSettings">
      <IconButton
        icon={Settings}
        iconSize={iconSize}
        alt="Settings"
        aria-label="Settings"
        onClick={openDrawer}
        ref={settingsRef}
      />
      {open && (
        <ListSettingsDrawer
          open={open}
          setOpen={setOpen}
          list={list}
          onDelete={onDelete}
          onSave={onSave}
          isSharedList={isSharedList}
        />
      )}
    </div>
  );
};

ListSettings.displayName = 'ListSettings';

ListSettings.propTypes = {
  onDelete: func.isRequired,
  list: shape({
    id: string,
    name: string,
    public: bool
  }).isRequired,
  onSave: func,
  isSharedList: bool,
  iconSize: string
};

ListSettings.defaultProps = {
  onSave: () => { },
  isSharedList: false,
  iconSize: 'regular'
};

ListSettings.dataModel = extend(
  ListSettingsDrawer
);
