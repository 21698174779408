import { MAJOR_APPLIANCE } from '../core/constants';

export const getProductPageStatus = (location) => {
  return (location.indexOf('/p/') > -1);
};

export const getSkippers = ({ skipSubscribeAndSave = true }) => {
  return {
    skipSpecificationGroup: true,
    skipKPF: true,
    skipInstallServices: true,
    skipSubscribeAndSave
  };
};

export const isAppliance = (product = {}) => {
  return !!(product?.identifiers?.productType?.toUpperCase() === MAJOR_APPLIANCE);
};

export const isLoading = (data, loading) => {
  if (loading || data === undefined || data === null) {
    return true;
  }

  return false;
};

export const isSearchLoading = (data, searchData, multiData, loading, multiLoading) => {
  if (loading || multiLoading || (!data && !!searchData?.recs)
    || (!data && !searchData?.recs && (!!multiData?.recs || multiData === undefined))
    || (!data && searchData === undefined && multiData === undefined)) {
    return true;
  }

  return false;
};

export const isRecsLoading = (dataState, data, fallBackRecsData, loading, fallBackRecsLoading, tntTest) => {
  if (tntTest) {
    if (loading || fallBackRecsLoading || (!dataState && !!data?.recs)
      || (!dataState && !data?.recs && (!!fallBackRecsData?.recs || fallBackRecsData === undefined))
    || (!dataState && data === undefined && fallBackRecsData === undefined)) {
      return true;
    }
  } else if (loading || (!dataState && !!data?.recs)) {
    return true;
  }

  return false;
};

export const getAppId = (device, app) => {
  let appId = '';
  if (app) {
    appId = 'consumerApp';
  } else if (device === 'mobile') {
    appId = 'mobileweb';
  } else {
    appId = 'desktop';
  }
  return appId;
};

export const impressionContainerNameParser = (title) => {
  if (title && typeof title === 'string') {
    const name = title.replace(/ /g, '');
    return name;
  }
  return '';
};

const _prependZero = (value) => {
  if (value && Number(value) <= 9) {
    return '0' + value;
  }
  return value;
};

export const getCustomerType = () => {
  let customer = {};
  if (typeof (window) !== 'undefined') {
    customer = window.top?.THDCustomer?.default || {};
  }
  return customer?.customerType;
};

export const getCurrentDate = () => {
  try {
    let currentDate = new Date();
    let formattedDate = _prependZero(currentDate.getFullYear()) + '-'
      // eslint-disable-next-line radix
      + _prependZero(parseInt(currentDate.getMonth() + 1)) + '-'
      + _prependZero(currentDate.getDate()) + ' '
      + _prependZero(currentDate.getHours()) + ':'
      + _prependZero(currentDate.getMinutes()) + ':'
      + _prependZero(currentDate.getSeconds());
    return formattedDate.toString();
  } catch (err) {
    console.error(err);
  }
  return null;
};